import { getRequest, postRequest, removeRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
  return await postRequest(
    endPoint.subscription.CREATE_SUBSCRIPTION,
    createData
  );
};
export const getAll = async (filterData) => {
  return await postRequest(endPoint.subscription.GET_ALL_SUBSCRIPTION, filterData);
};
export const remove = async (id) => {
  return await removeRequest(
    `${endPoint.subscription.REMOVE_SUBSCRIPTION}/${id}`
  );
};
