import { removeRequest, getRequest, postRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const selectedDateBeforeCreatedAndCompletedOrdersAttendeeDetails =
    async (filterData) => {
        return await postRequest(
            endPoint.reporting
                .SelectedDateBeforeCreatedAndCompletedOrdersAttendeeDetails,
            filterData
        );
    };
