import { getRequest, postRequest, putRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const create = async (createData) => {
    return await postRequest(
        endPoint.lead.Create,
        createData
    );
};
export const update = async (updateData) => {
    return await putRequest(endPoint.lead.Update, updateData);
};
export const getById = async (id) => {
    return await getRequest(`${endPoint.lead.GetById}/${id}`);
};
export const getAll = async (filterData) => {
    return await postRequest(endPoint.lead.GetAll, filterData);
};
