import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import Button from '../../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import WeeklyCalendar from '../../../components/Common/Calendar/WeeklyCalendar';
import { useState } from 'react';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import BasicTable from '../../../components/Common/Table/BasicTable';
import BasicPopUp from '../../../components/Common/Popup/BasicPopUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Modal from '../../../components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import ModalWithSearch from '../../../components/Common/Modal/ModalWithSearch';
import { ToastContainer, toast } from 'react-toastify';
import findMyPath from '../../../hooks/useFindMyPath';
import PhoneConfirmation from './PhoneConfirmation';
import { ModalTitles, storageKeys, storageValues } from '../../../utils/Enum';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import Toast from '../../../components/Common/Popup/Toast';

const Calendar = ({
  filterData = {},
  packageModel = {},
  selectLaterItems = {},
  stateFilter = {},
  fromMentorCalendarButton = false,
  createState = {},
  handleContinueParent,
  getMeSpeaker,
  searchFromChild,
  menuStatus,
  locationReset,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const userAreaEnum = useSelector((state) => state.onboard.enum);
  const userHasPhone = useSelector(
    (state) => state.user.getById.phoneIsVerified
  );
  const userPackage = useSelector((state) => state.userPackage);
  const onlyMentors = useSelector(
    (state) => state.calendar.getCalendarSpeakers
  );
  const calendarTable = useSelector((state) => state.order.calendarTable);
  const orderItems = useSelector((state) => state.order.basketItems);
  const basketStatus = useSelector((state) => state.order.isEmpty);
  const phoneConfirmationModal = useRef();
  const routingFrom = findMyPath('speakers');
  const orderRouting = findMyPath('order-basket');
  const freeSession = location.state?.freeSession;
  const fromNavigateWhere = location.state?.fromNavigateWhere;
  const isEarnFreeSessions = fromNavigateWhere === 'earnFreeSessions';
  const isRecompense = fromNavigateWhere === 'recompense';
  const checkHasItems = userPackage.isPackageComplete;
  const hasPackage = userPackage?.packageData?.productType;
  const goBackState = { state: { previousPath: routingFrom } };
  const noAction = currentUserClaim?.length === 0;
  const isSetDate = Object.keys(selectLaterItems)?.length > 0;
  const selectedSessions =
    orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
      ?.selectionOptions;
  const basketItemTimesperWeek =
    orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection?.packageOptions
      ?.timesPerWeek;
  const levelGroups = JSON.parse(
    sessionStorage.getItem(storageKeys.levelGroup)
  );
  const routingBasket =
    fromNavigateWhere !== undefined &&
    Object.keys(userPackage?.packageData).length > 0 &&
    userPackage?.packageData?.trialStatus === 2;
  const [reFilterData, setReFilterData] = useState([]);
  const [data, setData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [removedIndex, setRemovedIndex] = useState(null);
  const [addData, setAddData] = useState(false);
  const [request, setRequest] = useState(false);
  const [color, setColor] = useState(undefined);
  const [getCell, setGetCell] = useState('');
  const [orderWarning, setOrderWarning] = useState('');

  const getCellSuccess = useSelector((state) => state.calendar.isSuccess);
  const getCellMessage = useSelector(
    (state) => state.calendar.validationMessage
  );
  const orderItemIsSuccess = useSelector((state) => state.order.isSuccess);
  const orderItemValidationMessage = useSelector(
    (state) => state.order.validationMessage
  );

  function removeSelectedIndex(index) {
    if (index !== null) {
      const updatedData = data.filter((_, i) => i !== index);
      setChildData(updatedData);
      setRemovedIndex(index);
    }
  }

  function addSelected(value) {
    setChildData(value);
    setAddData(true);
  }

  useEffect(() => {
    if (addData) {
      setData(childData);
      setAddData(false);
      setRemovedIndex(null);
    }
    if (removedIndex !== null) {
      setData(childData);
      setRemovedIndex(null);
      setAddData(false);
    }
    if (childData?.length === 0) {
      setChildData(null);
    }
  }, [childData, addData, removedIndex]);

  useEffect(() => {
    if (orderItemIsSuccess && orderItemValidationMessage !== '' && !isSetDate) {
      setOrderWarning(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong> {orderItemValidationMessage}</strong>
        </span>
      );
    } else if (
      !orderItemIsSuccess &&
      orderItemValidationMessage !== '' &&
      !isSetDate
    ) {
      setOrderWarning(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong> {orderItemValidationMessage}</strong>
        </span>
      );
    }
  }, [isSetDate, orderItemIsSuccess, orderItemValidationMessage]);

  useEffect(() => {
    if (!getCellSuccess && getCellMessage !== '') {
      setGetCell(
        <div>
          {getCellMessage?.split('{br}')?.map((item, index) => (
            <span
              key={index}
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{item}</strong>
            </span>
          ))}
        </div>
      );
    }
  }, [getCellSuccess, getCellMessage]);

  useEffect(() => {
    if (getCell !== '') {
      toast.warning(getCell, {
        onClose: () => {
          setGetCell('');
          dispatch(Actions.calendarActions.handleValidationReducer(''));
        },
      });
    }
  }, [getCell]);
  useEffect(() => {
    if (orderWarning !== '') {
      toast.warning(orderWarning, {
        onClose: () => {
          dispatch(Actions.resetStoreAction.resetStore());
          setOrderWarning('');
          locationReset(true);
          setData([]);
          setPackageData({});
          sessionStorage.removeItem(storageKeys.landingRule);
          navigate(findMyPath('dashboard'));
        },
      });
    }
  }, [orderWarning]);

  function extractParameters(data) {
    return data?.map((item) => ({
      selectedCellIndex: item.selectedCellIndex,
      speakerId: item.speakerId,
      meetingSchemaId: item.meetingSchemaId,
      selectedDate: item.selectedDate,
      speakerName: item.speakerName,
      sessionContent: item.sessionContent,
      sessionDate: item.sessionDate,
      sessionCount: item.sessionCount,
    }));
  }
  useEffect(() => {
    if (Object?.keys(orderItems)?.length > 0 && !isSetDate) {
      function modifyStringDate(dateString) {
        const dateOnly = dateString.split('T')[0];
        const parsedTime = dateString.split('T')[1].split(':');
        const timeOnly = `${parsedTime[0]}:${parsedTime[1]}`;
        const viewString = addDay(
          timeOnly,
          dateOnly,
          false,
          false,
          false,
          false,
          true
        );
        return viewString;
      }
      const updatedData = selectedSessions.map((item) => ({
        ...item,
        sessionDate: modifyStringDate(item.selectedDate),
      }));

      const dataFromBasketItems = extractParameters(updatedData);
      setData(dataFromBasketItems);
    } else if (
      Object?.keys(orderItems)?.length === 0 &&
      calendarTable?.length === 0
    ) {
      setData([]);
    }
  }, [orderItems]);

  useEffect(() => {
    if (calendarTable?.length > 0) {
      function modifyStringDate(dateString) {
        const dateOnly = dateString.split('T')[0];
        const parsedTime = dateString.split('T')[1].split(':');
        const timeOnly = `${parsedTime[0]}:${parsedTime[1]}`;
        const viewString = addDay(
          timeOnly,
          dateOnly,
          false,
          false,
          false,
          false,
          true
        );
        return viewString;
      }
      const updatedData = calendarTable.map((item) => ({
        ...item,
        sessionDate: modifyStringDate(item.selectedDate),
      }));
      setData(updatedData);
    } else if (Object?.keys(orderItems)?.length === 0) {
      setData([]);
    }
  }, [calendarTable]);

  useEffect(() => {
    if (Object?.keys(filterData)?.length === 0 && isRecompense) {
      setReFilterData(location?.state?.filterData);
    } else if (isEarnFreeSessions) {
      setReFilterData(stateFilter?.filterData);
    } else if (
      Object?.keys(filterData)?.length !== 0 &&
      (isRecompense || isEarnFreeSessions)
    ) {
      setReFilterData([...location?.state?.filterData, ...filterData]);
    } else if (isSetDate) {
      const myType =
        selectLaterItems.productType === 'Private' ||
        selectLaterItems.productType === 1
          ? 0
          : 1;
      const newFilter = {
        ...filterData,
        TypeOfService: [myType],
        CompanyId: selectLaterItems?.companyId,
      };
      setReFilterData(newFilter);
    } else if (packageModel.productType !== undefined) {
      const myType =
        packageModel.productType === 1 || packageModel.productType === 'Private'
          ? 0
          : 1;
      const newFilter = {
        ...filterData,
        TypeOfService: [myType],
        CompanyId: selectLaterItems?.companyId,
      };
      setReFilterData(newFilter);
    } else {
      setReFilterData(filterData);
    }
    if (!isRecompense && !isSetDate) {
      dispatch(Actions.calendarActions.cleanState(true));
    }
  }, [
    filterData,
    isRecompense,
    isEarnFreeSessions,
    location?.state?.filterData,
    stateFilter.filterData,
  ]);

  useEffect(() => {
    if (
      reFilterData?.TypeOfService &&
      Array.isArray(reFilterData.TypeOfService) &&
      reFilterData?.TypeOfService.length > 0 &&
      !isSetDate &&
      !isRecompense
    ) {
      if (
        reFilterData.TypeOfService.includes(0) &&
        reFilterData.TypeOfService.includes(1)
      ) {
        setColor(undefined);
      } else if (reFilterData.TypeOfService.includes(0)) {
        setColor('Private');
      } else if (reFilterData.TypeOfService.includes(1)) {
        setColor('Group');
      } else {
        setColor(undefined);
      }
    } else if (reFilterData?.TypeOfService === null) {
      setColor(undefined);
    } else if (
      reFilterData?.UserArea === 1 ||
      hasPackage === 0 ||
      isEarnFreeSessions
    ) {
      setColor('Group');
    } else if (isSetDate) {
      setColor(selectLaterItems?.productType);
    } else if (isRecompense) {
      setColor(location?.state?.whichone);
    } else if (hasPackage === 1) {
      setColor('Private');
    }
  }, [reFilterData]);

  const [isOpen, setIsOpen] = useState(false);
  const [calendarDate, setCalendarDate] = useState({});
  const [packageData, setPackageData] = useState(
    Object.keys(packageModel).length !== 0
      ? packageModel
      : Object.keys(selectLaterItems).length !== 0
      ? selectLaterItems.options
      : createState?.directlyCalendarData
  );
  const [isOpenAsk, setIsOpenAsk] = useState(false);
  const [phoneConfirmationInfo, setPhoneConfirmationInfo] = useState('');

  useEffect(() => {
    if (
      userHasPhone !== undefined &&
      phoneConfirmationModal.current !== undefined &&
      phoneConfirmationModal.current?.check() === false
    ) {
      if (
        !userHasPhone &&
        auth !== '' &&
        userPackage?.packageData?.trialStatus === 0
      ) {
        phoneConfirmationModal.current.open();
      }
    }
  }, [phoneConfirmationModal.current]);

  useEffect(() => {
    const { strTime, endTime } = filterTimeSet();

    if (Object.keys(reFilterData).length > 0) {
      setCalendarDate({
        ...reFilterData,
        StartDateTime: strTime,
        EndDateTime: endTime,
      });
      setRequest(true);
    }
  }, [reFilterData]);

  useEffect(() => {
    if (auth === '' && !fromMentorCalendarButton) {
      dispatch(
        Actions.userPackageActions.createAction({
          ...packageData,
          UserArea: userAreaEnum,
        })
      );
    }
  }, [packageData]);

  useEffect(() => {
    const rule = sessionStorage.getItem(storageKeys.landingRule);
    if (
      auth !== '' &&
      !isSetDate &&
      !isRecompense &&
      Object.keys(userPackage?.packageData).length > 0 &&
      userInfo.id !== undefined &&
      Object.keys(userInfo).length > 0 &&
      rule !== storageValues.landingRule
    ) {
      if (userInfo.userArea !== userPackage.packageData.userArea) {
        // dispatch(
        //   Actions.onboardActions.onboardType(
        //     parseInt(userPackage.packageData.UserArea)
        //   )
        // );
        dispatch(
          Actions.userActions.selfUpdateAction(
            { userArea: parseInt(userPackage.packageData.userArea) },
            'UserArea',
            true
          )
        );
      }
      sessionStorage.setItem(
        storageKeys.landingRule,
        storageValues.landingRule
      );
    }
  }, [userInfo]);
  const [packageAreaInfo, setPackageAreaInfo] = useState('');

  useEffect(() => {
    if (packageAreaInfo !== '') {
      toast.info(packageAreaInfo, {
        onClose: () => {
          setPackageAreaInfo('');
          sessionStorage.removeItem(storageKeys.landingRule);
          dispatch(Actions.orderActions.selectSessionReducer([], true));
          setData([]);
          dispatch(Actions.userPackageActions.completeAction());
          navigate(findMyPath('dashboard'));
        },
      });
    }
  }, [packageAreaInfo]);
  const defaultLng =
    userPackage?.packageData?.userArea !== undefined
      ? userPackage?.packageData?.userArea !== 2
        ? `${levelGroups?.adult}.0`
        : `${levelGroups?.kids}.0`
      : userInfo?.userArea !== 2
      ? `${levelGroups?.adult}.0`
      : `${levelGroups?.kids}.0`;

  useEffect(() => {
    if (
      auth !== '' &&
      !isSetDate &&
      !isRecompense &&
      userPackage?.packageData &&
      userInfo.id !== undefined &&
      userInfo.userArea === userPackage.packageData.userArea &&
      Object.keys(userInfo).length > 0
    ) {
      if (userInfo.isChild !== null) {
        if (
          userInfo.isChild &&
          userInfo.userArea !== 2 &&
          userPackage.packageData.userArea !== 2
        ) {
          setPackageAreaInfo(
            <span
              className="d-flex position-relative mx-auto  justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{t('onlineGroup.kids_adult_warning')}</strong>
            </span>
          );
          return;
        }
        if (
          !userInfo.isChild &&
          userInfo.userArea === 2 &&
          userPackage.packageData.userArea === 2
        ) {
          setPackageAreaInfo(
            <span
              className="d-flex position-relative mx-auto  justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{t('onlineGroup.adult_kids_warning')}</strong>
            </span>
          );
          return;
        }
      }
      if (userInfo?.languageLevel !== '') {
        //TODO  uyarıda verebilriiz veya olması gereken durumle değiştirede biliriz.
        if (
          userPackage?.packageData?.languageLevel !== userInfo?.languageLevel
        ) {
          setPackageAreaInfo(
            <span
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{t('onlineGroup.levelIssue')}</strong>
            </span>
          );
          return;
        }
      }
      const isRegular = userPackage?.packageData?.trialStatus === 2;
      const myData = {
        AttendeeId: userInfo?.attendeeId,
        LanguageLevel:
          userInfo?.languageLevel === ''
            ? userPackage?.packageData?.languageLevel
            : userInfo?.languageLevel,
        LearningPurposes: userPackage?.packageData?.learningPurposes ?? '2',
        UserArea: userPackage?.packageData?.userArea || userInfo?.userArea,
        ProductType:
          userPackage?.packageData?.productType === 0 ||
          userPackage?.packageData?.productType === 'Group'
            ? 'Group'
            : 'Private',
        TrialStatus: userPackage?.packageData?.trialStatus,
        PackageOptions: userPackage?.packageData?.packageOptions,
        SelectionOptions: isRegular
          ? []
          : userPackage?.packageData?.selectionOptions,
      };
      dispatch(Actions.orderActions.selectSessionAction(myData)).then(() => {
        dispatch(Actions.orderActions.getBasketItemsAction()).then(() => {
          setFinishOrder(true);
        });
      });
    }
  }, [userInfo]);

  const [finishOrder, setFinishOrder] = useState(false);

  useEffect(() => {
    if (finishOrder) {
      if (
        auth !== '' &&
        Object.keys(userPackage?.packageData).length > 0 &&
        userPackage?.packageData?.trialStatus !== 0
      ) {
        dispatch(Actions.userPackageActions.completeAction());
        dispatch(Actions.orderActions.selectSessionReducer([], false));
        const hasSessions =
          orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
            ?.selectionOptions?.length > 0;
        if (hasSessions) {
          navigate(orderRouting, fromMentorCalendarButton ? -1 : goBackState);
        } else if (!hasSessions) {
          navigate(
            findMyPath('order/paymentdetail'),
            fromMentorCalendarButton ? -1 : goBackState
          );
        }
      } else if (
        auth !== '' &&
        Object.keys(userPackage?.packageData).length > 0 &&
        userPackage?.packageData?.trialStatus === 0 &&
        freeTrialAvaible
      ) {
        dispatch(Actions.userPackageActions.completeAction());
        const myData = {
          OrderItemId: orderItems?.unpayedOrderItemList?.[0]?.id,
          SelectionOptions:
            orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
              ?.selectionOptions,
          FromNavigateWhere: 'freeTrial',
        };
        dispatch(
          Actions.orderActions.orderSetDateAction(
            myData,
            userPackage?.packageData
          )
        );
      }
    }
  }, [finishOrder]);
  const [isMobile, setIsMobile] = useState(false);
  const [currentView, setCurrentView] = useState();

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(!isMobile);
    }
    if (isRecompense) {
      setCurrentView(1);
    } else if (filterData?.TrialStatus === 1 || filterData?.TrialStatus === 0) {
      setCurrentView(window.innerWidth > 767 ? 1 : 3);
    } else if (filterData?.TrialStatus !== 1 || filterData?.TrialStatus !== 0) {
      setCurrentView(1);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCurrentViewChange = (val) => {
    setCurrentView(val);
  };

  const headers = [
    {
      key: 'speakerName',
      label: t('calendar.table_mentor'),
    },
    {
      key: 'sessionDate',
      label: t('calendar.table_session_date'),
    },
    { key: 'sessionContent', label: t('calendar.table_session') },
    { key: 'sessionCount', label: t('calendar.table_session_count') },
    { key: '', label: '' },
  ];
  const handleClose = () => {
    setIsOpen(false);
  };
  const onDiscardItem = (e) => {
    e.preventDefault();
    setData([]);
    setIsOpen(false);
  };

  function extractSpeakerDetails(list) {
    return (
      list?.map((item) => ({
        SpeakerName: item.speakerName,
        SpeakerId: item.speakerId,
        SpeakerImage: item.speakerImage,
        SpeakerTitle: item.speakerTitle,
      })) || []
    );
  }

  useEffect(() => {
    extractSpeakerDetails(onlyMentors);
  }, [onlyMentors]);

  useEffect(() => {
    if (
      request &&
      !freeTrialDone &&
      Object.keys(calendarDate).length > 5 &&
      (userPackage?.packageData?.selectionOptions === undefined || auth === '')
    ) {
      getAllSpeakersWithFilter();
    }
  }, [calendarDate, request]);

  async function getAllSpeakersWithFilter() {
    const apiCallObject = { ...calendarDate };
    const myState = location?.state?.createData;

    if (
      (myState?.trialStatus === 0 || myState?.trialStatus === 1) &&
      myState?.productType === 0
    ) {
      apiCallObject.LearningPurpose = null;
    }
    if (isRecompense) {
      apiCallObject.IsRecompense = true;
    }
    if (!routingBasket) {
      await dispatch(Actions.calendarActions.getFilteredAction(apiCallObject));
      await dispatch(
        Actions.calendarActions.getFiltredSpeakersAction(apiCallObject)
      );
    }
  }

  const [isBasicOpen, setIsBasicOpen] = useState(false);
  const handleModalClose = () => {
    setIsBasicOpen(false);
    navigate(findMyPath('dashboard'));
  };

  const [selectedCell, setSelectedCell] = useState(null);
  const handleCellSelect = (cellValue) => {
    setSelectedCell(cellValue);
  };
  const [freeTrialAvaible, setFreeTrialAvaible] = useState(undefined);
  useEffect(() => {
    if (userInfo?.phoneNumberAvaibleForFreeTrial !== undefined) {
      setFreeTrialAvaible(userInfo?.phoneNumberAvaibleForFreeTrial);
    }
  }, [userInfo]);
  const [freeTrialDone, setFreeTrialDone] = useState(false);
  useEffect(() => {
    if (
      freeTrialAvaible &&
      basketStatus === '' &&
      orderItemIsSuccess &&
      userHasPhone &&
      calendarTable?.length === 0 &&
      !isRecompense &&
      !isSetDate &&
      !isEarnFreeSessions &&
      phoneConfirmationInfo === ''
    ) {
      dispatch(Actions.orderActions.getBasketItemsReducer([], true));
      navigate('/congratulations', {
        state: { packageData, createPackage: true, isFreeTrial: true },
      });
    }
  }, [orderItemIsSuccess, phoneConfirmationInfo]);

  useEffect(() => {
    if (
      userInfo?.phoneIsVerified &&
      selectedSessions?.length > 0 &&
      phoneConfirmationInfo !== '' &&
      packageData?.trialStatus === 0
    ) {
      if (userInfo?.phoneNumberAvaibleForFreeTrial) {
        setFreeTrialDone(true);
        const myData = {
          AttendeeId: userInfo?.attendeeId,
          UserArea: userInfo?.userArea,
          OrderItemId: orderItems?.unpayedOrderItemList?.[0]?.id,
          SelectionOptions:
            orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
              ?.selectionOptions,
          FromNavigateWhere: 'freeTrial',
        };
        dispatch(Actions.orderActions.orderSetDateAction(myData, packageData));
      }
    }
  }, [userInfo, phoneConfirmationInfo]);

  useEffect(() => {
    if (
      !orderItemIsSuccess &&
      orderItemValidationMessage !== '' &&
      !isSetDate &&
      !isRecompense &&
      userHasPhone &&
      packageData?.trialStatus !== 2
    ) {
      setOrderWarning(
        <div>
          {orderItemValidationMessage?.split('{br}')?.map((item, index) => (
            <span
              key={index}
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{item}</strong>
            </span>
          ))}
        </div>
      );
      dispatch(Actions.userPackageActions.completeAction());
      dispatch(Actions.orderActions.selectSessionReducer([], false));
    }
  }, [orderItemIsSuccess, orderItemValidationMessage]);

  const [handleFreeTrial, setHandleFreeTrial] = useState('');
  useEffect(() => {
    if (handleFreeTrial !== '') {
      toast.warning(handleFreeTrial, {
        onClose: () => {
          setHandleFreeTrial('');
          sessionStorage.removeItem(storageKeys.landingRule);
        },
      });
    }
  }, [handleFreeTrial]);

  function formatLearningPurposes(learningPurposes) {
    if (!learningPurposes || learningPurposes.length === 0) {
      return '2';
    }

    return learningPurposes.join(',');
  }

  const handleContinue = async () => {
    sessionStorage.setItem(storageKeys.landingRule, storageValues.landingRule);
    if (auth === '') {
      if (packageData?.timesPerWeek !== undefined) {
        if (
          data.length !== packageData?.timesPerWeek &&
          !fromMentorCalendarButton &&
          !isSetDate &&
          !isRecompense
        ) {
          const message = `${data.length}/${
            packageData?.timesPerWeek === undefined
              ? basketItemTimesperWeek
              : packageData?.timesPerWeek
          } ${t('button.select_session_to_continue')}`;
          setNotifyMesage(
            <div>
              <span
                className="d-flex position-relative mx-auto justify-content-center fs-6"
                style={{ maxWidth: '550px' }}
              >
                <strong>
                  {t('calendar.you_must_complete_your_selections')}
                </strong>
              </span>
              <span
                className="d-flex position-relative mx-auto justify-content-center fs-6"
                style={{ maxWidth: '550px' }}
              >
                <strong>{message}</strong>
              </span>
            </div>
          );
        }
        if (data.length === packageData?.timesPerWeek) {
          setIsOpenAsk(true);
        }
      } else {
        setIsOpenAsk(true);
      }
    } else if (checkHasItems && auth !== '' && !userHasPhone) {
      phoneConfirmationModal.current?.open();
    } else if (auth !== '' && !userHasPhone) {
      if (
        !fromMentorCalendarButton &&
        data.length !== packageData?.timesPerWeek &&
        packageData?.trialStatus === 2
      ) {
        const message = `${data.length}/${
          packageData?.timesPerWeek === undefined
            ? basketItemTimesperWeek
            : packageData?.timesPerWeek
        }} ${t('button.select_session_to_continue')}`;
        setNotifyMesage(
          <div>
            <span
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{t('calendar.you_must_complete_your_selections')}</strong>
            </span>
            <span
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{message}</strong>
            </span>
          </div>
        );
      } else {
        phoneConfirmationModal.current?.open();
      }
    } else if (selectLaterItems?.orderItemId !== undefined) {
      if (selectLaterItems.timesPerWeek !== data.length) {
        const message = `${data.length}/${selectLaterItems?.timesPerWeek} ${t(
          'button.select_session_to_continue'
        )}`;
        setNotifyMesage(
          <div>
            <span
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{t('calendar.you_must_complete_your_selections')}</strong>
            </span>
            <span
              className="d-flex position-relative mx-auto justify-content-center fs-6"
              style={{ maxWidth: '550px' }}
            >
              <strong>{message}</strong>
            </span>
          </div>
        );
      }
      if (selectLaterItems.undatedPackageCount === data.length) {
        const requestData = {
          SelectionOptions: data,
          OrderItemId: selectLaterItems?.orderItemId,
          FromNavigateWhere: 'freeTrial',
        };
        dispatch(
          Actions.orderActions.orderSetDateAction(requestData, packageData)
        );
      }
      if (selectLaterItems.timesPerWeek === data.length) {
        const requestData = {
          SelectionOptions: data,
          OrderItemId: selectLaterItems?.orderItemId,
          FromNavigateWhere: 'freeTrial',
        };
        dispatch(
          Actions.orderActions.orderSetDateAction(requestData, packageData)
        );
      }
    } else if (
      packageData?.trialStatus === 0 &&
      userHasPhone &&
      freeTrialAvaible
    ) {
      const myData = {
        OrderItemId: orderItems?.unpayedOrderItemList?.[0]?.id,
        SelectionOptions:
          orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
            ?.selectionOptions,
        FromNavigateWhere: 'freeTrial',
      };
      dispatch(Actions.orderActions.orderSetDateAction(myData, packageData));
    } else if (
      data.length !==
        (packageData?.timesPerWeek === undefined
          ? basketItemTimesperWeek
          : packageData?.timesPerWeek) &&
      !fromMentorCalendarButton &&
      !isSetDate &&
      !isRecompense
    ) {
      const message = `${data.length}/${packageData?.timesPerWeek} ${t(
        'button.select_session_to_continue'
      )}`;
      setNotifyMesage(
        <div>
          <span
            className="d-flex position-relative mx-auto justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>{t('calendar.you_must_complete_your_selections')}</strong>
          </span>
          <span
            className="d-flex position-relative mx-auto justify-content-center fs-6"
            style={{ maxWidth: '550px' }}
          >
            <strong>{message}</strong>
          </span>
        </div>
      );
    } else if (
      (packageData?.trialStatus !== 0 ||
        orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
          ?.trialStatus !== 0) &&
      userHasPhone
    ) {
      navigate(orderRouting, fromMentorCalendarButton ? -1 : goBackState);
    }
  };
  const [notifyMessage, setNotifyMesage] = useState('');
  useEffect(() => {
    if (notifyMessage !== '') {
      toast.warning(notifyMessage, {
        onClose: () => {
          setNotifyMesage('');
        },
      });
    }
  }, [notifyMessage]);
  const customToastStyle = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const [handleSelectLater, setHandleSelectLater] = useState(undefined);

  useEffect(() => {
    if (handleSelectLater) {
      sessionStorage.setItem(
        storageKeys.landingRule,
        storageValues.landingRule
      );
      data.length === 0
        ? handleContinueParent()
        : navigate(orderRouting, fromMentorCalendarButton ? -1 : goBackState);
    }
  }, [handleSelectLater]);

  useEffect(() => {
    return () => {
      dispatch(Actions.resetStoreAction.resetStore());
      if (
        sessionStorage.getItem(storageKeys.landingRule) !==
        storageValues.landingRule
      ) {
        dispatch(Actions.orderActions.selectSessionReducer([], ''));
        dispatch(Actions.userPackageActions.completeAction());
      }
      sessionStorage.removeItem(storageKeys.landingRule);
    };
  }, []);

  // const element = document.getElementById('weeklyCalendar');
  // useEffect(() => {
  //   if (element) {
  //     const topOffset = element.offsetTop;
  //     const middleScrollPosition = topOffset / 3;
  //     window.scrollTo({
  //       top: middleScrollPosition,
  //       behavior: 'smooth',
  //     });
  //   }
  // }, [element]);

  const content = (
    <div className="container-fluid mb-5">
      {!routingBasket && (
        <div className="row">
          <div className="col-12">
            <Card
              padding={0}
              body={
                <React.Fragment>
                  {!isMobile && (
                    <div className="d-flex justify-content-center mt-4">
                      <HeadTags
                        strong
                        hSize={2}
                        text={t('mentor.session_determine')}
                      />
                    </div>
                  )}
                  <div className="d-flex justify-content-end mb-2">
                    <div className="col-md-6 col-lg-6 col-12">
                      {data?.length !== 0 &&
                      data !== undefined &&
                      !isRecompense ? (
                        <Card
                          padding={0}
                          body={
                            <>
                              {freeSession === undefined ? (
                                <div className="row">
                                  <div className="col-12">
                                    <BasicTable
                                      data={data}
                                      headers={headers}
                                      onClickFuncSetDate={handleContinue}
                                      labelFunc={(val) =>
                                        removeSelectedIndex(val)
                                      }
                                      labelProp={''}
                                      oncalendar={true}
                                    />
                                    <div className="d-flex align-items-center">
                                      {(packageData?.trialStatus === 2 ||
                                        orderItems?.unpayedOrderItemList?.[0]
                                          ?.scheduleUserSelection
                                          ?.trialStatus === 2) && (
                                        <div className="d-flex p-2 me-auto justify-content-start">
                                          <span className="text-dark fw-bold">
                                            {t('calendar.table_package_info')}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {data?.length > 0 && (
                                      <div className="col-12 d-flex justify-content-md-end justify-content-center">
                                        <div className="col-12 col-md-3">
                                          <SubmitButton
                                            stateName={'order'}
                                            text={t('button.continue')}
                                            onClick={handleContinue}
                                            objectLengthRule={
                                              data?.length > 0 ? false : true
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <BasicPopUp
                                    isOpen={isOpen}
                                    onClose={handleClose}
                                    position={{
                                      top: 50,
                                      left: 100,
                                    }}
                                    text={t(
                                      'mentor.are_you_sure_to_discard_this_item'
                                    )}
                                    button={{
                                      text: t('button.yes'),
                                    }}
                                    buttonFunc={onDiscardItem}
                                    buttonType={ButtonType.Danger}
                                  />
                                </div>
                              ) : data.length === 0 &&
                                freeSession === undefined ? (
                                <div className="row">
                                  <p>
                                    {t('mentor.you_have_no_suspended_session')}
                                  </p>
                                  <div className="col d-flex justify-content-start">
                                    <Button
                                      bold
                                      type={ButtonType.Successbg}
                                      text={t('button.select_later')}
                                      textColor={TextColors.Light}
                                      onClick={() => setIsBasicOpen(true)}
                                    />
                                  </div>
                                  <Modal
                                    key={`recompense_modal`}
                                    isVisible={isBasicOpen}
                                    content={
                                      <p>
                                        {t(
                                          'mentor.we_are_currently_suspending'
                                        )}
                                      </p>
                                    }
                                    footer={
                                      <Button
                                        type={ButtonType.Successbg}
                                        bold
                                        buttonCol={6}
                                        text={t('button.close')}
                                        onClick={handleModalClose}
                                      />
                                    }
                                    onClose={handleModalClose}
                                  />
                                  {/* <div className='col d-flex justify-content-end'>
                          <Button
                            bold
                            type={ButtonType.Successbg}
                            text={"Askıya al"}
                            textColor={TextColors.Light}
                            onClick={() => alert("Oturum Askıya alındı")}
                          />
                        </div> */}
                                </div>
                              ) : data.length !== 0 ? (
                                <div className="col d-flex justify-content-end">
                                  <Button
                                    bold
                                    type={ButtonType.Successbg}
                                    text={t('button.continue')}
                                    textColor={TextColors.Light}
                                    buttonCol={4}
                                    onClick={() =>
                                      navigate(
                                        findMyPath('order/paymentdetail')
                                      )
                                    }
                                  />
                                </div>
                              ) : undefined}
                            </>
                          }
                        />
                      ) : undefined}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-1 mt-2">
                    <div className="col-lg-2 ms-2 col-md-4">
                      <ModalWithSearch
                        isSearchable
                        styleProps={{ maxWidth: 550 }}
                        setStateFromState={{
                          SpeakerId:
                            createState?.directlyCalendarData?.speakerId ??
                            packageData?.speakerId,
                          SpeakerName:
                            createState?.directlyCalendarData?.speakerName ??
                            packageData?.speakerName,
                          SpeakerImage:
                            createState?.directlyCalendarData?.speakerImage ??
                            packageData?.speakerImage,
                        }}
                        placeHolder={
                          <div>
                            {t('attendeeDashboard.modal_search_mentors')}
                            <FontAwesomeIcon className="ms-1" icon={faSearch} />
                          </div>
                        }
                        options={extractSpeakerDetails(onlyMentors)}
                        selectedProps={{
                          key: 'SpeakerName',
                          value: 'SpeakerId',
                        }}
                        onChange={(value) => {
                          if (menuStatus !== undefined) {
                            getMeSpeaker(
                              value,
                              calendarDate?.StartDateTime,
                              calendarDate?.EndDateTime
                            );
                          } else if (menuStatus === undefined) {
                            if (
                              calendarDate.SelectedIds === undefined ||
                              calendarDate.SelectedIds === null
                            ) {
                              setCalendarDate({
                                ...calendarDate,
                                SelectedIds: [value],
                              });
                            } else {
                              const isSelected =
                                calendarDate?.SelectedIds?.includes(value);
                              const updatedSelectedIds = isSelected
                                ? calendarDate?.SelectedIds?.filter(
                                    (id) => id !== value
                                  )
                                : [...calendarDate.SelectedIds, value];
                              if (updatedSelectedIds?.length === 0) {
                                setCalendarDate({
                                  ...calendarDate,
                                  SelectedIds: null,
                                });
                              } else {
                                setCalendarDate({
                                  ...calendarDate,
                                  SelectedIds: updatedSelectedIds,
                                });
                              }
                            }
                          }
                        }}
                        searchWithChild={(val) => searchFromChild(val)}
                        searchFromParent={reFilterData.FullName}
                        resetState={
                          reFilterData.SelectedIds === null ? true : false
                        }
                        isMulti
                        isCalendarLabel
                      />
                    </div>
                    {!isRecompense && (
                      <div
                        className="col align-self-center justify-content-end ms-1 me-1 text-nowrap"
                        style={{ maxWidth: '180px' }}
                      >
                        <div className="switch-selector-container text-nowrap me-auto">
                          <div
                            className={`option-container-calendar fw-bold ${
                              currentView === 3 ? 'active' : ''
                            }`}
                            onClick={() => handleCurrentViewChange(3)}
                            style={{ maxWidth: '90px' }}
                          >
                            {t('calendar.daily')}
                          </div>
                          <div
                            className={`option-container-calendar fw-bold  ${
                              currentView === 1 ? 'active' : ''
                            }`}
                            onClick={() => handleCurrentViewChange(1)}
                            style={{ maxWidth: '90px' }}
                          >
                            {t('calendar.weekly')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!isRecompense && !isSetDate && (
                    <div className="d-flex justify-content-center justify-content-md-end">
                      {filterData.UserArea !== 1 && (
                        <div className="align-self-center px-2">
                          <span>
                            <strong>{t('button.one_to_one')}</strong>
                          </span>
                          <Button
                            type={ButtonType.Success}
                            buttonCol={12}
                            style={{ width: '100%' }}
                            disableHover={true}
                          />
                        </div>
                      )}
                      <div className="align-self-center px-2">
                        <span>
                          <strong>{t('button.group')}</strong>
                        </span>
                        <Button
                          type={ButtonType.Warning}
                          buttonCol={12}
                          style={{ width: '100%' }}
                          disableHover={true}
                        />
                      </div>
                    </div>
                  )}
                  {!routingBasket && (
                    <div className="col-12 p-0">
                      <WeeklyCalendar
                        languageLevel={calendarDate?.LanguageLevels}
                        learningPurposes={calendarDate?.LearningPurpose}
                        modalData={calendarDate.SelectedIds}
                        color={color}
                        emptyData={data?.length === 0}
                        selectLaterState={selectLaterItems}
                        onCellSelect={handleCellSelect}
                        fromRecompense={isRecompense}
                        fromEarnFreeSession={isEarnFreeSessions}
                        fromSetDate={isSetDate}
                        recompenseDate={
                          location?.state?.filterData?.StartDateTime
                        }
                        removeAction={removedIndex}
                        removeWithSelect={(val) => {
                          removeSelectedIndex(val);
                        }}
                        getTableData={(val) => {
                          addSelected(val);
                        }}
                        recompenseOrderItemId={
                          selectLaterItems?.orderItemId ??
                          location?.state?.orderItemId
                        }
                        recompenseMeetingAttendeeId={
                          selectLaterItems?.meetingAttendeeId ??
                          location?.state?.meetingAttendeeId
                        }
                        packageModel={
                          fromMentorCalendarButton
                            ? packageData
                            : Object.keys(packageModel).length !== 0
                            ? packageModel
                            : createState?.directlyCalendarData
                        }
                        fromMentorCalendarButton={fromMentorCalendarButton}
                        getSelectedDate={(val) => {
                          if (val.popup) {
                            const date = addDay(val.time, val.filter, true);
                            const [year, month, day] = date
                              .split('T')[0]
                              .split('-')
                              .map(Number);
                            const nDate = `${year}-${month
                              .toString()
                              .padStart(2, '0')}-${day
                              .toString()
                              .padStart(2, '0')}T${date.split('T')[1]}`;
                            let changes = {
                              ...calendarDate,
                              StartDateTime: nDate,
                            };
                            if (isRecompense) {
                              changes.IsRecompense = true;
                            }
                            if (calendarDate.SelectedIds === null) {
                              const arr = [];
                              val.ids
                                .split(',')
                                .forEach((el) => arr.push(parseInt(el.trim())));
                              changes.SelectedIds = arr;
                            } else if (calendarDate.SelectedIds === undefined) {
                              const arr = [];
                              val.ids
                                .split(',')
                                .forEach((el) => arr.push(parseInt(el.trim())));
                              changes = {
                                ...changes,
                                SelectedIds: arr,
                              };
                            }

                            dispatch(
                              Actions.calendarActions.getCellDetailsAction(
                                changes
                              )
                            );
                          } else if (!val.popup) {
                            const { strTime, endTime } = filterTimeSet(
                              val.filter.split('T')[0]
                            );
                            const changes = {
                              ...calendarDate,
                              StartDateTime: strTime,
                              EndDateTime: endTime,
                            };
                            setCalendarDate(changes);
                          }
                        }}
                        currentView={currentView}
                        areaFromParent={
                          auth === '' ? calendarDate?.UserArea : undefined
                        }
                        area={calendarDate?.UserArea}
                        filter={calendarDate}
                      />
                    </div>
                  )}
                </React.Fragment>
              }
            />
          </div>
        </div>
      )}
      {!isSetDate && (
        <div
          style={{
            position: 'fixed',
            right: 0,
            bottom: 0,
            marginBottom: '2rem',
            marginRight: '2rem',
            zIndex: 50,
          }}
        >
          {!fromMentorCalendarButton && (
            <Button
              bold
              type={ButtonType.Successbg}
              outline
              hidden={packageData?.trialStatus !== 2 ? true : false}
              disabled={
                data?.length > 0 && packageData?.timesPerWeek > data?.length
              }
              text={
                data.length === 0
                  ? t('button.select_later')
                  : data.length === packageData?.timesPerWeek
                  ? t('button.continue')
                  : `${data.length}/${packageData?.timesPerWeek} ${t(
                      'button.select_session_to_continue'
                    )}`
              }
              textColor={TextColors.Light}
              onClick={() => {
                if (auth === '') {
                  setIsOpenAsk(true);
                  setHandleSelectLater(true);
                } else if (data.length === 0) {
                  setHandleSelectLater(true);
                } else {
                  navigate(
                    orderRouting,
                    fromMentorCalendarButton ? -1 : goBackState
                  );
                }
              }}
            />
          )}
        </div>
      )}

      <Modal
        key={`login_or_register_question`}
        isVisible={isOpenAsk}
        styleProps={{ maxWidth: 500 }}
        title={t('calendar.please_log_in_to_buy_sessions')}
        titleMS={0}
        content={
          <div className="d-flex justify-content-evenly">
            <div className=" ">
              <Button
                text={t('navbar.log_in')}
                type={ButtonType.Success}
                outline
                bold
                onClick={() => {
                  navigate('/account/login');
                  setIsOpenAsk(false);
                }}
                buttonCol={12}
              />
            </div>
            <div className="">
              <Button
                text={t('navbar.register')}
                type={ButtonType.Successbg}
                bold
                textColor={TextColors.Light}
                onClick={() => {
                  navigate('/account/register');
                  setIsOpenAsk(false);
                }}
                buttonCol={12}
              />
            </div>
          </div>
        }
        onClose={() => setIsOpenAsk(false)}
      />
      <ModalInsideComponent
        ref={phoneConfirmationModal}
        title={ModalTitles.phoneConfirmation}
        child={
          phoneConfirmationInfo === '' ? (
            <PhoneConfirmation
              phoneConfirmInfo={(val) => setPhoneConfirmationInfo(val)}
              trialStatus={packageData?.trialStatus}
            />
          ) : (
            phoneConfirmationInfo
          )
        }
        onClose={() => {
          if (
            phoneConfirmationInfo !== '' &&
            packageData?.trialStatus === 2 &&
            !basketStatus
          ) {
            navigate(orderRouting, fromMentorCalendarButton ? -1 : goBackState);
          } else if (
            freeTrialAvaible &&
            userHasPhone &&
            selectedSessions?.length > 0 &&
            !freeTrialDone &&
            packageData?.trialStatus === 0
          ) {
            const myData = {
              AttendeeId: userInfo?.attendeeId,
              UserArea: userInfo?.userArea,
              OrderItemId: orderItems?.unpayedOrderItemList?.[0]?.id,
              SelectionOptions:
                orderItems?.unpayedOrderItemList?.[0]?.scheduleUserSelection
                  ?.selectionOptions,
              FromNavigateWhere: 'freeTrial',
            };
            dispatch(
              Actions.orderActions.orderSetDateAction(myData, packageData)
            );
          } else if (
            !freeTrialAvaible &&
            userHasPhone &&
            selectedSessions?.length > 0 &&
            packageData?.trialStatus === 0
          ) {
            setHandleFreeTrial(
              <span
                className="d-flex position-relative mx-auto  justify-content-center fs-6"
                style={{ maxWidth: '550px' }}
              >
                <strong> {t('freeTrial.phone_already_used')}</strong>
              </span>
            );
            dispatch(Actions.orderActions.selectSessionAction({}));
          } else if (
            !fromMentorCalendarButton &&
            packageData?.trialStatus === 1 &&
            userHasPhone
          ) {
            const myData = {
              ProductType:
                userPackage?.packageData?.productType === 0 ||
                userPackage?.packageData?.productType === 'Group'
                  ? 'Group'
                  : 'Private',
              LanguageLevel:
                userPackage?.packageData?.languageLevel ?? defaultLng,
              LearningPurposes: formatLearningPurposes(
                userPackage?.packageData?.learningPurposes
              ),
              AttendeeId: userInfo?.attendeeId,
              TrialStatus: packageData?.trialStatus,
              UserArea: packageData?.userArea,
              PackageOptions: {
                Type: 1,
                Location: packageData?.location,
                MonthDuration: packageData?.monthDuration,
                TimesPerWeek: packageData?.timesPerWeek,
                SessionPackage: packageData?.sessionPackage,
                Currency: packageData?.currency,
              },
            };
            dispatch(Actions.orderActions.selectSessionAction(myData));
            navigate(orderRouting, fromMentorCalendarButton ? -1 : goBackState);
          }
          setPhoneConfirmationInfo('');
          sessionStorage.removeItem(storageKeys.landingRule);
          phoneConfirmationModal.current?.close();
        }}
      />
      {(notifyMessage !== '' ||
        orderWarning !== '' ||
        handleFreeTrial !== '' ||
        packageAreaInfo !== '' ||
        getCell !== '') && (
        <ToastContainer
          style={customToastStyle}
          autoClose={false}
          closeOnClick
          theme="light"
        />
      )}
      <Toast
        key={'setDateActionCalendar'}
        id={'setDateActionCalendar'}
        stateName={'order'}
        navigate={() => navigate(findMyPath('orders'))}
        onClose={() => {
          if (isEarnFreeSessions) {
            navigate('/gift-code', { state: { where: 2 } });
          } else {
            navigate(findMyPath('orders'));
          }
          dispatch(Actions.orderActions.cleanState());
        }}
      />
    </div>
  );
  return useLoader() ? <Loader /> : content;
};
export default Calendar;
