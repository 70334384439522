import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  ButtonSize,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';

import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Actions from '../../../store/redux/actions';
import addDay from '../../../hooks/useAddDay';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { Entities } from '../../../utils/Enum';

const BackOfficeSpeakerEffortReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const speakerEffort = useSelector((state) => state.speakerEffort.getAll);
  const locationList = useSelector((state) => state.location.getAll);
  const dropdownListSpeaker = useSelector((state) => state.speaker.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSpeaker =
    checkClaims(currentUserClaim, 'speakerAvailability.create') &&
    !checkClaims(currentUserClaim, 'order.get');

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        dispatch(Actions.speakerEffortActions.getAllAction(filterData));
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const getList = async () => {
    await dispatch(Actions.speakerActions.getAllAction('Dropdown'));
    await dispatch(Actions.locationActions.getAllAction());
  };

  const locationHeaders = [
    { key: 'location', label: t('speakerEffort.location') },
    { key: 'totalMinute', label: t('speakerEffort.total_minute') },
    { key: 'totalAmount', label: t('speakerEffort.total_price') },
  ];
  const areaHeaders = [
    { key: 'area', label: t('speakerEffort.area') },
    { key: 'totalMinute', label: t('speakerEffort.total_minute') },
    { key: 'totalAmount', label: t('speakerEffort.total_price') },
  ];
  const speakerHeaders = [
    { key: 'fullName', label: t('speakerEffort.full_name') },
    { key: 'phoneNumber', label: t('common.phone') },
    { key: 'totalMinute', label: t('speakerEffort.total_minute') },
    { key: 'totalAmount', label: t('speakerEffort.amount') },
    { key: 'isFullTimer', label: t('speakerEffort.full_timer') },
    { key: 'dateRange', label: t('speakerEffort.date_range') },
    { key: 'dayCount', label: t('speakerEffort.day_count') },
  ];

  const showDetailsAction = (id, filter) => {
    navigate('/speaker-effort-details', {
      state: { id: id, dateFilter: filter },
    });
  };

  const onSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(Actions.speakerEffortActions.getAllAction(filterData));
    setLoading(false);
  };
  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };
  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,

        [key]: value,
      }));
      setDateChanged(true);
    }
  }
  const handleDropdown = (propName, value) => {
    resetMe(
      propName,
      propName === 'speakerId' || propName === 'location' ? value : `${value}`
    );
  };

  useEffect(() => {
    if (
      Object.keys(filterData).length > 0 &&
      dateChanged === false &&
      isSpeaker
    ) {
      dispatch(Actions.speakerEffortActions.getAllAction(filterData));

      setDateChanged(false);
    }
  }, [filterData]);

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
    setDateChanged(true);
  };
  useEffect(() => {
    const currentDate = new Date();
    // 7 değeri  ilerde değişebilir. ayın gününü bulmak için verilen bir değerdir.
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      10
    );

    const { strTime, endTime, strView, endView } = filterTimeSet(
      startOfMonth,
      true,
      true
    );

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          {t('speakerEffort.speaker_effort')}
        </h1>
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <Button
                    text={t('cardHeaderFilter.search')}
                    icon={faSearch}
                    type={
                      Object.keys(filterData).length >= 1
                        ? ButtonType.Successbg
                        : ButtonType.Secondary
                    }
                    iconColor={TextColors.Light}
                    textColor={TextColors.Light}
                    iconSide
                    bold
                    size={ButtonSize.Small}
                    buttonCol={!mobileView ? 1 : 3}
                    onClick={onSearch}
                    disabled={
                      Object.keys(filterData).length >= 1 ? false : true
                    }
                  />
                </div>
                <div className="row my-2">
                  {isSpeaker ? null : (
                    <div className="col-lg-3">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_speaker')}
                        options={dropdownListSpeaker}
                        selectedProps={{
                          key: 'speakerName',
                          value: 'speakerId',
                        }}
                        onChange={(value) => {
                          handleDropdown('speakerId', value);
                        }}
                        onReset={() => handleResetDropdown('speakerId')}
                        isfilter
                      />
                    </div>
                  )}
                  <div className="col-lg-3 mt-lg-0 mt-1">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-lg-0 mt-1">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-lg-0 mt-3">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) => handleDropdown('location', value)}
                      onReset={() => handleResetDropdown('location')}
                      isfilter
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Card
            body={
              <div>
                {loading ? (
                  <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : (
                  <Table
                    key={'locations'}
                    data={speakerEffort?.locations ?? []}
                    headers={locationHeaders}
                    striped
                    bordered
                    page={Entities.speakerEffort}
                    currentUserClaim={currentUserClaim}
                    idSearchOnly={true}
                    exportName={
                      Entities.speakerEffort.exportName.effortByBranches
                    }
                  />
                )}
              </div>
            }
          ></Card>
        </div>
        <div className="col-lg-6">
          <Card
            body={
              <div>
                {loading ? (
                  <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : (
                  <Table
                    key={'areas'}
                    data={speakerEffort?.areas ?? []}
                    headers={areaHeaders}
                    striped
                    bordered
                    page={Entities.speakerEffort}
                    currentUserClaim={currentUserClaim}
                    idSearchOnly={true}
                    exportName={Entities.speakerEffort.exportName.effortByArea}
                  />
                )}
              </div>
            }
          ></Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                {loading ? (
                  <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : (
                  <Table
                    key={'speakers'}
                    data={speakerEffort?.speakers ?? []}
                    headers={speakerHeaders}
                    striped
                    bordered
                    page={Entities.speaker}
                    currentUserClaim={currentUserClaim}
                    idSearchOnly={true}
                    goDetails={(val) => {
                      showDetailsAction(val.speakerId, filterData);
                    }}
                    exportName={
                      Entities.speakerEffort.exportName.effortBySpeaker
                    }
                  />
                )}
              </div>
            }
          ></Card>
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.effort,
    content
  );
};
export default BackOfficeSpeakerEffortReport;
