import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../utils/ComponentEnums';
import { ToastContainer, toast } from 'react-toastify';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const orderData = useSelector((state) => state.order.order);
  const isSuccess = useSelector((state) => state.order.isSuccess);
  const validationMessage = useSelector(
    (state) => state.order.validationMessage
  );
  const staticDatas = useSelector((state) => state.staticData);
  const orderId = location?.state?.id;
  const redirect = '/order-list';
  const orderStatusList = staticDatas.OrderStatus;
  const paymentStatusList = staticDatas.PaymentStatus;
  const paymentTypeList = staticDatas.PaymentType;
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const [handleError, setHandleError] = useState('');
  const isFinanceManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.accountingDept
  );

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.error(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          setUpdateData({});
          setHandleError('');
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...orderData });
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(Actions.orderActions.updateOrderAction(updateData));
    setDataChanged(true);
  };

  const handleDropdown = (propName, value) => {
    const parsedValue = parseInt(value);
    setUpdateData({ ...updateData, [propName]: parsedValue });
    dispatch(
      Actions.orderActions.holdValue({
        ...updateData,
        [propName]: parsedValue,
      })
    );
    setDataChanged(false);
  };

  useEffect(() => {
    dispatch(Actions.orderActions.getByIdAction(orderId));
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(orderData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      if (isFinanceManager) {
        setUpdateData({ ...orderData, id: orderId });
      } else if (!isFinanceManager) {
        const { paymentType, ...rest } = orderData;
        setUpdateData({ ...rest, id: orderId });
      }
    }
  }, [orderData, isFinanceManager]);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: !isSuccess ? '500px' : '300px',
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('order.update_order_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'attendeeName'}
                      inputType={InputType.Text}
                      labelText={t('order.attendee_name')}
                      inputValue={updateData.attendeeName}
                      disabled={true}
                    />
                  </div>
                  <div className="col-6 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_order_status')}
                      options={orderStatusList}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                        searchKey: 'value',
                      }}
                      selectedValues={`${updateData.orderStatus}`}
                      onChange={(value) => handleDropdown('orderStatus', value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_payment_status')}
                      options={paymentStatusList}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                        searchKey: 'value',
                      }}
                      selectedValues={`${updateData.paymentStatus}`}
                      onChange={(value) =>
                        handleDropdown('paymentStatus', value)
                      }
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_payment_type')}
                      options={
                        isFinanceManager
                          ? paymentTypeList
                          : paymentTypeList.filter(
                              (item) => item.value !== '1' && item.value !== '2'
                            )
                      }
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                        searchKey: 'value',
                      }}
                      selectedValues={`${updateData.paymentType}`}
                      onChange={(value) => handleDropdown('paymentType', value)}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        updateData.paymentType === undefined ||
                        updateData.paymentType === null
                          ? ButtonType.Secondary
                          : dataChanged
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={
                        updateData.paymentType === undefined ||
                        updateData.paymentType === null
                          ? true
                          : dataChanged
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.update,
    content
  );
};
export default Update;
