import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const feedbackReducer = (state = initialState.feedback, action) => {
  switch (action.type) {
    case ActionTypes.feedback.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.feedback.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.feedback.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
