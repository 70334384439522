import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import Actions from '../../../store/redux/actions';
import addDay from '../../../hooks/useAddDay';
import checkClaims from '../../../hooks/useCheckClaims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const bannerId = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const banner = useSelector((state) => state.banner.getById);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const goBack = () => {
    navigate(-1);
    dispatch(Actions.bannerActions.cleanState());
  };
  const [imgDesktopSrc, setImgDesktopSrc] = useState('');
  const [imgMobileSrc, setImgMobileSrc] = useState('');
  const redirect = '/banner-list';

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...updateData, [id]: value });
    if (value) {
      setDataChanged(false);
    }
  };

  const onSave = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length > 0) {
      dispatch(Actions.bannerActions.updateAction(updateData, bannerId));
    }
  };
  function FetchById() {
    dispatch(Actions.bannerActions.getByIdAction(bannerId));
  }

  useEffect(() => {
    FetchById();
    return () => {
      dispatch(Actions.bannerActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (Object.keys(banner).length > 0) {
      setUpdateData({ ...banner });
    }
  }, [banner]);

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setUpdateData({
        ...updateData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
      setDataChanged(false);
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDataChanged(false);
    }
  };

  const convertToLocalDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date - userTimezoneOffset);
  };

  useEffect(() => {
    if (Object.keys(banner).length > 0) {
      const localStartDate = convertToLocalDate(banner.startDate);
      const yearStart = localStartDate.getFullYear();
      const monthStart = ('0' + (localStartDate.getMonth() + 1)).slice(-2);
      const dayStart = ('0' + localStartDate.getDate()).slice(-2);
      const startDateView = `${yearStart}-${monthStart}-${dayStart}`;

      const localEndDate = convertToLocalDate(banner.endDate);
      const yearEnd = localEndDate.getFullYear();
      const monthEnd = ('0' + (localEndDate.getMonth() + 1)).slice(-2);
      const dayEnd = ('0' + localEndDate.getDate()).slice(-2);
      const endDateView = `${yearEnd}-${monthEnd}-${dayEnd}`;

      setUpdateData({
        ...banner,
        startDateView: startDateView,
        endDateView: endDateView,
      });
    }
  }, [banner]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-12 col-md-1 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags hSize={5} strong text={t('banner.update_form')} />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={updateData.name}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-0 col-md-6"></div>
                  <div className="col-12 col-md-6 mt-2 mt-md-2">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={updateData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2 mt-md-2">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={updateData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={
                            imgDesktopSrc
                              ? imgDesktopSrc
                              : updateData.pictureWeb
                          }
                          alt={
                            imgDesktopSrc
                              ? imgDesktopSrc
                              : updateData.pictureWeb
                          }
                          height={90}
                          style={{ maxHeight: '90px' }}
                        />
                      }
                    </div>
                    <CustomFileInput
                      id={'picture_web'}
                      hidden={true}
                      buttonText={t('fileUpload.web')}
                      textColor={TextColors.Light}
                      fileType={'Image'}
                      buttonColor={ButtonType.Successbg}
                      func={(val) => {
                        if (!val) {
                          setUpdateData({});
                          setImgDesktopSrc('');
                          setDataChanged(false);
                        } else {
                          setUpdateData(
                            Object.assign({}, banner, {
                              pictureWeb: val.base64String,
                              extensionWeb: val.extension,
                            })
                          );
                          setImgDesktopSrc(val.imgSrc);
                          setDataChanged(false);
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={
                            imgMobileSrc
                              ? imgMobileSrc
                              : updateData.pictureMobile
                          }
                          alt={
                            imgMobileSrc
                              ? imgMobileSrc
                              : updateData.pictureMobile
                          }
                          height={90}
                          style={{ maxHeight: '90px' }}
                        />
                      }
                    </div>
                    <CustomFileInput
                      id={'picture_mobile'}
                      hidden={true}
                      buttonText={t('fileUpload.mobile')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      func={(val) => {
                        if (!val) {
                          setUpdateData({});
                          setImgMobileSrc('');
                          setDataChanged(false);
                        } else {
                          setUpdateData(
                            Object.assign({}, banner, {
                              pictureMobile: val.base64String,
                              extensionMobile: val.extension,
                            })
                          );
                          setImgMobileSrc(val.imgSrc);
                          setDataChanged(false);
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'banner'}
                        onClick={onSave}
                        objectLengthRule={dataChanged}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'banner'}
        onClose={() => {
          setImgDesktopSrc('');
          setImgMobileSrc('');
          setUpdateData({});
          dispatch(Actions.bannerActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.banner.update,
    content
  );
};
export default Update;
