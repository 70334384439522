import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { saveAs } from 'file-saver';
import Button from '../../../components/Common/Button/Button';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { faFaceGrinHearts, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { storageKeys, storageValues } from '../../../utils/Enum';
import findMyPath from '../../../hooks/useFindMyPath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Tiktok = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth);
  const userInfo = useSelector((state) => state.user.getById);
  const trialUsed = userInfo.freeTrialUsed;
  const [pages, setPages] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(1);
  const pdfModal = useRef();

  useEffect(() => {
    const fetchAndRenderPDF = async () => {
      try {
        const url = '/Tenses.pdf';
        const pdfDoc = await pdfjsLib.getDocument(url).promise;
        setPdf(pdfDoc);

        const numPages = pdfDoc.numPages;
        const pagesPromises = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          pagesPromises.push(pdfDoc.getPage(pageNum));
        }

        const pages = await Promise.all(pagesPromises);
        setPages(pages);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchAndRenderPDF();
  }, []);

  const openModal = useCallback((page, i) => {
    setSelectedPage(page);
    setSelectedNumber(i + 1);
    if (pdfModal.current) {
      pdfModal.current.open();
    }
  }, []);

  function editContent(
    text,
    bold = false,
    colorMe = undefined,
    href = undefined,
    boldWords = []
  ) {
    const regexPattern = /(.*?)\s*%([^%]+)%\s*(.*)/;
    const matchResult = text.match(regexPattern);

    if (colorMe !== undefined) {
      const regex = /%([^%]*)%/;
      const match = text.match(regex);
      const extractedText = match ? match[1] : '';
      return (
        <span>
          {matchResult?.[1]}
          <span className={`text-custom-${colorMe}`}>
            <strong
              role={href === undefined ? 'none' : 'button'}
              onClick={() => navigate(href)}
            >{` ${extractedText} `}</strong>
          </span>
          {matchResult?.[3]}
        </span>
      );
    }
    if (bold) {
      return (
        <span>
          <strong>{`${text}`}</strong>
        </span>
      );
    }
    const boldText = matchResult ? matchResult[2] : '';

    let additionalWord = '';
    if (boldWords.includes(boldText)) {
      additionalWord = boldText;
    }

    if (matchResult) {
      const beforeText = matchResult[1];
      const afterText = matchResult[3];

      return (
        <span>
          {beforeText}
          <strong className="fw-bold"> {additionalWord} </strong>
          {afterText}
        </span>
      );
    } else {
      return <span>{text}</span>;
    }
  }

  return (
    <div className="container-fluid mt-5">
      <div className="d-flex justify-content-center text-start">
        {auth === '' ? (
          <div className="col-4">
            <p>
              {editContent(t('tiktok.txt'), undefined, false, undefined, [
                t('tiktok.txtCompanies'),
              ])}
            </p>
            <p>{t('tiktok.txt1')}</p>
            <p>
              {editContent(
                t('tiktok.txt2'),
                undefined,
                'danger',
                '/account/login'
              )}
              {editContent(
                t('tiktok.txt3'),
                undefined,
                'danger',
                '/account/register'
              )}
            </p>
            <p>{editContent(t('tiktok.txt4'), true)}</p>
            <p>{editContent(t('tiktok.txt5'), true)}</p>
          </div>
        ) : (
          <div className="col-5">
            {
              <p>
                <strong>{t('tiktok.txt6')}</strong>
                <FontAwesomeIcon
                  icon={faFaceGrinHearts}
                  size="2x"
                  className="text-warning ms-2"
                />
              </p>
            }
            <p> {editContent(t('tiktok.txt7'))}</p>
            <p>
              {editContent(t('tiktok.txt8'), undefined, false, undefined, [
                t('tiktok.txtCompanies'),
              ])}
            </p>
            <p>{editContent(t('tiktok.txt9'), true)}</p>
            <p>
              {editContent(
                t('tiktok.txt10'),
                undefined,
                'danger',
                !trialUsed ? findMyPath('order/triallesson') : undefined
              )}
              {editContent(
                t('tiktok.txt11'),
                undefined,
                'danger',
                findMyPath('order/package')
              )}
            </p>
          </div>
        )}
      </div>
      {auth !== '' && (
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-between gap-5">
            {!trialUsed ? (
              <Button
                text={t('button.get_free_session')}
                type={ButtonType.CalendarSelectButton}
                textColor={TextColors.Light}
                size={ButtonSize.Small}
                onClick={() => navigate(findMyPath('order/triallesson'))}
              />
            ) : (
              <Button
                text={t('button.freeTrialAlreadyUsed')}
                type={ButtonType.Secondary}
                textColor={TextColors.Light}
                size={ButtonSize.Small}
                disabled={true}
              />
            )}
            <Button
              text={t('button.get_subsccription')}
              type={ButtonType.Successbg}
              textColor={TextColors.Light}
              size={ButtonSize.Small}
              onClick={() => navigate(findMyPath('order/package'))}
            />
          </div>
        </div>
      )}
      <div
        className="container-fluid"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        {pages.map((page, index) => (
          <PageCard
            key={index}
            page={page}
            onClick={() => openModal(page, index)}
            index={index}
          />
        ))}
      </div>
      <ModalInsideComponent
        ref={pdfModal}
        title={
          auth === ''
            ? t('tiktok.register')
            : t('tiktok.chapter', { No: selectedNumber })
        }
        titleCentered={true}
        child={
          <React.Fragment>
            {selectedPage && auth !== '' && (
              <PageCard page={selectedPage} scale={1.5} reset={true} />
            )}
            {selectedPage && auth === '' && (
              <div className="d-flex justify-content-evenly">
                <div className="col-4 me-2">
                  <Button
                    text={t('navbar.log_in')}
                    type={ButtonType.Success}
                    outline
                    bold
                    onClick={() => {
                      sessionStorage.setItem(
                        storageKeys.tiktok,
                        storageValues.tiktok
                      );
                      navigate('/account/login');
                      pdfModal.current.close();
                    }}
                  />
                </div>
                <div className="col-4 ms-2">
                  <Button
                    text={t('navbar.register')}
                    type={ButtonType.Successbg}
                    bold
                    textColor={TextColors.Light}
                    onClick={() => {
                      sessionStorage.setItem(
                        storageKeys.tiktok,
                        storageValues.tiktok
                      );
                      navigate('/account/register');
                      pdfModal.current.close();
                    }}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        }
        onClose={() => {
          setSelectedPage(null);
          if (pdfModal.current) {
            pdfModal.current.close();
          }
        }}
        maxWidth={800}
      />
    </div>
  );
};

const PageCard = ({ page, onClick, scale = 0.5, reset = false, index }) => {
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const downloadPDF = () => {
    const url = '/Tenses.pdf';

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, 'Tenses.pdf');
      })
      .catch((error) => console.error('Error downloading PDF:', error));
  };

  useEffect(() => {
    const renderPage = async () => {
      if (page) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise;
      }
    };

    renderPage();
  }, [page, scale]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
        margin: '10px',
      }}
    >
      <div
        onClick={onClick}
        style={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px',
          width: reset ? 'auto' : '320px',
          height: reset ? 'auto' : '200px',
          backgroundColor: '#fff',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </div>
      {!reset && (
        <HeadTags
          strong
          hSize={6}
          marginTop={3}
          text={t('tiktok.chapter', { No: index + 1 })}
        />
      )}
      {reset && (
        <Button
          onClick={downloadPDF}
          bold
          icon={faFilePdf}
          iconSide
          iconColor={TextColors.Light}
          type={ButtonType.Successbg}
          textColor={TextColors.Light}
          text={t('tiktok.download')}
        />
      )}
    </div>
  );
};

export default Tiktok;
