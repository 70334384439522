import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import Button from '../../../../components/Common/Button/Button';
import Card from '../../../../components/Common/Card/Card';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../../utils/ComponentEnums';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationData = useSelector((state) => state.location.location);
  const locationInfo = useSelector((state) => state.location.getById);
  const isSuccess = useSelector((state) => state.location.isSuccess);
  const goBack = () => navigate(-1);
  const locationId = location?.state?.id;
  const [updateData, setUpdateData] = useState({});
  const checkData = Object.keys(updateData).length === 0;
  const redirect = '/location-list';

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...locationData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...locationData });
    } else {
      dispatch(Actions.locationActions.holdValue({ ...updateData }));
    }
  };
  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...locationData });
    }
  };
  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.locationActions.updateAction(updateData, locationId)
    );
  };
  async function FetchById() {
    await dispatch(Actions.locationActions.getByIdAction(locationId));
  }

  useEffect(() => {
    FetchById();
    if (isSuccess) {
      navigate(redirect);
      setUpdateData({});
      dispatch(Actions.locationActions.handleError(''));
    }
  }, [isSuccess]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-5 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('location.update_location_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'locationName'}
                      inputType={InputType.Text}
                      labelText={t('location.location_name')}
                      inputValue={
                        checkData
                          ? locationInfo.locationName
                          : updateData.locationName
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'locationSessionValue'}
                      inputType={InputType.Number}
                      labelText={t('location.session_value')}
                      inputValue={
                        checkData
                          ? locationInfo.locationSessionValue
                          : updateData.locationSessionValue
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'locationMentorValue'}
                      inputType={InputType.Number}
                      labelText={t('location.mentor_value')}
                      inputValue={
                        checkData
                          ? locationInfo.locationMentorValue
                          : updateData.locationMentorValue
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(updateData).length <= 1
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={
                        Object.keys(updateData).length <= 1 ? true : false
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.location.update,
    content
  );
};
export default Update;
