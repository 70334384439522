import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../components/Common/Card/Card';
import Scheduler from '../../../components/Common/Calendar/Scheduler';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faClock,
  faEllipsis,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleUser,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Actions from '../../../store/redux/actions';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import calculateUTC from '../../../hooks/useCalculateUTC';
import addDay from '../../../hooks/useAddDay';
import getTimeZone from '../../../hooks/useGetTimeZone';

export default function Schedule({ attendeeId = undefined, getMeIndex = {} }) {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  const timeZone = getTimeZone();
  const [currentDate, setCurrentDate] = useState(moment().locale(locale));
  const [selectedDay, setSelectedDay] = useState(moment().locale(locale));

  const startOfMonth = moment(currentDate).startOf('month');
  const endOfMonth = moment(currentDate).endOf('month');
  const startDate = moment(startOfMonth).startOf('week');
  const endDate = moment(endOfMonth).endOf('week');

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const defaultView = screenWidth < 768 ? 2 : 1;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [currentView, setCurrentView] = useState(defaultView);
  const handleCurrentViewChange = (view) => {
    setCurrentView(view);
  };

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const weeks = Array.from(
    { length: endDate.diff(startDate, 'weeks') + 1 },
    (_, i) => moment(startDate).add(i, 'weeks')
  );

  const anotherDates = [];
  const reportDates = [];
  for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    const nextDate = moment(currentDate).add(dayOffset, 'days').toDate();
    reportDates.push(moment(nextDate));
    anotherDates.push(moment(nextDate));
    anotherDates.push(moment(...anotherDates, reportDates));
  }

  const anotherUpcomingDates = [];
  const reportUpcomingDates = [];
  for (let dayOffset = 0; dayOffset < 3; dayOffset++) {
    const nextDate = moment(currentDate).add(dayOffset, 'days').toDate();
    reportUpcomingDates.push(moment(nextDate));
    anotherUpcomingDates.push(moment(nextDate));
    anotherUpcomingDates.push(
      moment(...anotherUpcomingDates, reportUpcomingDates)
    );
  }
  const dispatch = useDispatch();
  const meetingSchedule = useSelector(
    (state) => state.meetingSchema.getAllSchedule
  );

  const [speakerDates, setSpeakerDates] = useState({
    groupedOnline: [],
    groupedCafe: [],
    groupedKids: [],
    groupedCompany: [],
  });

  async function GetLists() {
    await dispatch(
      Actions.meetingSchemaActions.getAllAttendeeScheduleAction(
        attendeeId !== undefined ? attendeeId : ''
      )
    );

    await dispatch(
      Actions.locationActions.getLocationsByUserAreaAction({
        userArea: 1,
      })
    );
  }

  useEffect(() => {
    GetLists();
  }, [dispatch]);
  useEffect(() => {
    if (meetingSchedule) {
      const groupedData = meetingSchedule.reduce((acc, curr) => {
        const type = curr.type;
        const group = acc[type] || [];
        const [year, month, day] = curr.date.split('-').map(Number);
        const date = addDay(curr.interval, `${day}-${month}-${year}`);

        const changeInterval = curr.interval.split('-');
        curr.interval = `${calculateUTC(changeInterval[0])}-${calculateUTC(
          changeInterval[1]
        )}`;
        curr.date = date;
        group.push(curr);
        acc[type] = group;
        return acc;
      }, {});

      setSpeakerDates({
        groupedOnline: groupedData['Online'] || [],
        groupedCafe: groupedData['Cafe'] || [],
        groupedKids: groupedData['Kids'] || [],
        groupedCompany: groupedData['Company'] || [],
      });
    }
  }, [meetingSchedule]);

  const borderColor = (type) => {
    switch (type) {
      case 'Online':
        return '10px solid #4CBC9A';
      case 'Cafe':
        return '10px solid #FFDB80';
      case 'Kids':
        return '10px solid #FF8F80';
      case 'Company':
        return '10px solid #3A3650';
      default:
        return '10px solid transparent';
    }
  };

  const handlePrevious = () => {
    switch (currentView) {
      case 1: // Monthly view
        setCurrentDate(moment(currentDate).subtract(1, 'month'));
        break;
      case 2: // Weekly view
        setCurrentDate(moment(currentDate).subtract(1, 'week'));
        break;
      case 3: // Upcoming 3 days view
        setCurrentDate(moment(currentDate).subtract(3, 'day'));
        break;
      case 4: // Today view
        const previousDay = moment(selectedDay).subtract(1, 'day');
        setSelectedDay(previousDay);
        setCurrentDate(previousDay);
        break;
      default:
        setCurrentDate(moment(currentDate).subtract(1, 'month'));
        break;
    }
  };

  const handleNext = () => {
    switch (currentView) {
      case 1: // Monthly view
        setCurrentDate(moment(currentDate).add(1, 'month'));
        break;
      case 2: // Weekly view
        setCurrentDate(moment(currentDate).add(1, 'week'));
        break;
      case 3: // Upcoming 3 days view
        setCurrentDate(moment(currentDate).add(3, 'day'));
        break;
      case 4: // Today view
        const nextDay = moment(selectedDay).add(1, 'day');
        setSelectedDay(nextDay);
        setCurrentDate(nextDay);
        break;
      default:
        setCurrentDate(moment(currentDate).add(1, 'month'));
        break;
    }
  };
  const getMainText = () => {
    switch (currentView) {
      case 1:
        return `${moment(currentDate).format('MMMM YYYY')}`;

      case 2:
        return `${moment(currentDate).format('D MMM')} - ${moment(currentDate)
          .add(6, 'days')
          .format('D MMM  ')}`;

      case 3:
        return `${moment(currentDate).format('D MMM')} - ${moment(currentDate)
          .add(2, 'days')
          .format('D MMM ')}`;
      case 4:
        return `${moment(selectedDay).format('D MMM')}`;

      default:
        return `${moment(currentDate).format('MMMM, YYYY')}`;
    }
  };
  const getPrevText = () => {
    switch (currentView) {
      case 1:
        return `${moment(currentDate)
          .subtract(1, 'month')
          .format('MMMM, YYYY')} `;

      case 2:
        return `${moment(currentDate)
          .subtract(7, 'days')
          .format('MMM D')} - ${moment(currentDate)
          .subtract(1, 'days')
          .format('MMM D, YYYY')}`;

      case 3:
        return `${moment(currentDate)
          .subtract(3, 'days')
          .format('D, MMM, YYYY')}`;
      case 4:
        return `${moment(selectedDay)
          .subtract(1, 'days')
          .format('D, MMM, YYYY')}`;

      default:
        return `${moment(currentDate)
          .subtract(1, 'month')
          .format('MMMM, YYYY')} `;
    }
  };
  const getNextText = () => {
    switch (currentView) {
      case 1:
        return `${moment(currentDate).add(1, 'month').format('MMMM, YYYY')} `;

      case 2:
        return `${moment(currentDate)
          .add(7, 'days')
          .format('MMM D')} - ${moment(currentDate)
          .add(13, 'days')
          .format('MMM D, YYYY')}`;

      case 3:
        return `${moment(currentDate).add(3, 'days').format('D, MMM, YYYY')}`;

      case 4:
        return `${moment(selectedDay).add(1, 'days').format('D, MMM, YYYY')}`;

      default:
        return `${moment(currentDate).add(1, 'month').format('MMMM, YYYY')} `;
    }
  };
  function handleDates(item) {
    const [year, month, day] = item?.split('T')[0].split('-').map(Number);
    const date = `${day}-${month}-${year}`;
    const timeArray = item?.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const time = `${h}:${m}`;
    const prosess = addDay(time, date);
    const fixedDate = `${prosess}T${time}:00.000Z`;

    const [xday, xmonth, xyear] = fixedDate
      ?.split('T')[0]
      .split('-')
      .map(Number);
    const fixDate = `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
      .toString()
      .padStart(2, '0')}T${calculateUTC(fixedDate?.split('T')[1])}`;
    return fixDate;
  }
  const [meetingInfo, setMeetingInfo] = useState('');

  function findSessionMinute(meeting) {
    const [parsedS, parsedE] = meeting?.interval.split('-').map(String);
    const [sH, sM] = parsedS?.split(':').map(Number);
    const [eH, eM] = parsedE?.split(':').map(Number);
    return eH * 60 + eM - (sH * 60 + sM) >= 90;
  }

  const findMeetingUrl = (item, data) => {
    let meetingUrl = null;
    const fifteenMinutesBeforeStart = new Date(handleDates(item[0]?.startDate));
    fifteenMinutesBeforeStart.setMinutes(
      fifteenMinutesBeforeStart.getMinutes() - 15
    );
    const clickAbleLastFiveMins = fifteenMinutesBeforeStart;
    clickAbleLastFiveMins.setMinutes(clickAbleLastFiveMins.getMinutes() + 10);

    const currentDateTime = new Date();
    const firstDate = new Date(handleDates(item[0]?.startDate));
    const min =
      clickAbleLastFiveMins.getMinutes() - currentDateTime.getMinutes();

    if (currentDateTime < clickAbleLastFiveMins) {
      return setMeetingInfo(
        <div>
          {t('mySessions.join_session_info', { min })
            ?.split('{br}')
            ?.map((item, index) => (
              <span
                key={index}
                className="d-flex position-relative justify-content-start"
                style={{ maxWidth: '550px' }}
              >
                <strong>{item}</strong>
              </span>
            ))}
        </div>
      );
    } else if (
      currentDateTime > fifteenMinutesBeforeStart &&
      currentDateTime < firstDate
    ) {
      meetingUrl = item[0]?.meetingUrl;
      setMeetingInfo('');
    }
    const sesMin = findSessionMinute(data);
    const rule = item?.length === 1 ? item?.length : item?.length - 1;
    for (let i = 0; i < rule; i++) {
      const meeting = item[i];
      const meetingDateTime = new Date(handleDates(meeting?.startDate));
      if (item.length === 1) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }
      const nextMeeting = item[i + 1];
      let nextMeetingDateTime;
      if (nextMeeting !== undefined && sesMin) {
        const secondLink = new Date(handleDates(nextMeeting?.startDate));
        nextMeetingDateTime = new Date(
          secondLink.setMinutes(secondLink.getMinutes() - 10)
        );
      } else {
        nextMeetingDateTime = new Date(handleDates(nextMeeting?.startDate));
      }
      if (
        i === 0 &&
        currentDateTime >= meetingDateTime &&
        currentDateTime <= nextMeetingDateTime
      ) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }

      if (currentDateTime > nextMeetingDateTime) {
        meetingUrl = nextMeeting?.meetingUrl;
        break;
      }
    }
    return meetingUrl;
  };
  const handleClick = (item, data) => {
    const meetingUrl = findMeetingUrl(item, data);
    if (meetingUrl) {
      window.open(meetingUrl, '_blank');
    } else {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    }
  };
  const handleJoinSession = (meeting) => {
    const currentDate = new Date();
    const forCheck = new Date();
    currentDate.setHours(currentDate.getHours() + getTimeZone(true) / 60);
    const [year, month, day] = currentDate
      .toISOString()
      .split('T')[0]
      .split('-')
      .map(Number);
    const currentDateString = `${day.toString().padStart(2, '0')}-${month
      .toString()
      .padStart(2, '0')}-${year}`;
    let availableMeetings;
    const getCurrent = calculateUTC(
      `${currentDate.getHours()}:${currentDate.getMinutes()}`,
      true
    );

    const [hx, mx] = getCurrent.split(':').map(Number);
    const asMinx = hx * 60 + mx;
    const [h, m] = meeting.interval.split('-')[0].split(':').map(Number);
    const asMin = h * 60 + m - 15;
    if (meeting.date === currentDateString && asMin <= asMinx) {
      meeting.meetingDetails.forEach((meetingDetail, index) => {
        const is90 = findSessionMinute(meeting);
        let addMin;
        switch (index) {
          case 0:
            addMin = is90 ? 40 * 60000 : 30 * 60000;
            break;
          case 1:
            addMin = is90 ? 40 * 60000 : 30 * 60000;
            break;
          default:
            addMin = 40 * 60000;
            break;
        }
        const fixDate = handleDates(meetingDetail.startDate);
        const xDate = new Date(fixDate).getTime() + addMin;
        const meetingDateTime = new Date(xDate);
        if (meetingDateTime >= forCheck && availableMeetings === undefined) {
          availableMeetings = meetingDetail;
        }
      });
    }
    return availableMeetings === undefined;
  };
  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  useEffect(() => {
    if (meetingInfo !== '') {
      toast.warn(meetingInfo, {
        onClose: () => {
          setMeetingInfo('');
        },
      });
    }
  }, [meetingInfo]);

  const locations = useSelector((state) => state.location.branches);
  const getLocationKey = (id) => {
    const location = locations.find((loc) => loc.value === id);
    return location ? location.key : null;
  };

  const IconDisplay = ({ iconCount, capacity }) => {
    const renderExtraIcon = iconCount > 3;
    return (
      <div className="icon-container">
        {Array.from({ length: Math.min(iconCount, 3) }).map((_, index) => (
          <FontAwesomeIcon
            key={`${index}_${iconCount + 1}`}
            icon={faCircleUser}
            size="2x"
            className={`icon ${index !== iconCount - 1 ? 'hidden' : ''}`}
            style={{ zIndex: index + 1 }}
          />
        ))}
        <div
          className={`icon-container position-relative`}
          style={{ left: '-1px' }}
        >
          {renderExtraIcon && (
            <>
              <FontAwesomeIcon
                className="text-secondary icon"
                icon={faCircle}
                size="2x"
                style={{ zIndex: 20 }}
              />
              <span
                className="position-absolute ms-2 top-50 start-90 translate-middle badge rounded-pill"
                style={{ zIndex: 20 }}
              >
                {renderExtraIcon && `+${iconCount - 3}`}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    }, 5 * 60 * 1000);
    return () => clearTimeout(timerId);
  }, [meetingSchedule]);

  return useLoader() ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`${
            attendeeId === undefined ? 'col-xl-9 p-3' : 'col-xl-12 p-3'
          }`}
        >
          <Card
            padding={0}
            header={
              <div className="d-flex justify-content-between align-items-center text-nowrap  p-0 m-0 ">
                <div
                  className=" d-flex justify-content-start mx-end align-items-center"
                  onClick={handlePrevious}
                  role={'button'}
                >
                  <div className="col-2">
                    {
                      <FontAwesomeIcon
                        className="border rounded-circle p-2"
                        icon={faChevronLeft}
                      />
                    }
                  </div>
                  <div className="col-10 text-start  align-middle ps-2 align-items-center d-md-block d-none">
                    <h6 className="text-muted fw-bold p-0 m-0">
                      {getPrevText()}
                    </h6>
                  </div>
                </div>
                <div className="text-center mx-auto align-middle align-items-center">
                  <h4 className="text-muted fw-bold ">{getMainText()}</h4>
                </div>
                <div
                  className="d-flex justify-content-end mx-start pe-2 align-items-center "
                  onClick={handleNext}
                  role={'button'}
                >
                  <div className="col-10 text-end align-middle  pe-2 align-items-center d-md-block d-none">
                    <h6 className="text-muted fw-bold p-0 m-0">
                      {getNextText()}
                    </h6>
                  </div>
                  <div className="col-2 me-2 align-middle">
                    <FontAwesomeIcon
                      className="border rounded-circle p-2"
                      icon={faChevronRight}
                    />
                  </div>
                </div>
              </div>
            }
            body={
              <React.Fragment>
                <Scheduler
                  currentDate={currentDate}
                  currentView={currentView}
                  onCurrentViewChange={handleCurrentViewChange}
                  weeks={weeks}
                  reportDates={reportDates}
                  reportUpcomingDates={reportUpcomingDates}
                  flatData={meetingSchedule}
                  onlineDatas={speakerDates.groupedOnline}
                  cafeDatas={speakerDates.groupedCafe}
                  kidsDatas={speakerDates.groupedKids}
                  companyDatas={speakerDates.groupedCompany}
                  selectedDay={selectedDay}
                  onSelectedDayChange={setSelectedDay}
                  attendeeId={attendeeId}
                  getMeIndex={(val) => getMeIndex(val)}
                />
              </React.Fragment>
            }
          />
        </div>
        {attendeeId === undefined && (
          <div className=" col-xl-3 p-1 my-2">
            <Card
              body={
                <>
                  <div className="card-header fs-6 fw-bold bg-transparent px-0">
                    <FontAwesomeIcon
                      className="text-t-gri"
                      icon={faCircleExclamation}
                    />{' '}
                    {t('schedule.reminder')}
                  </div>{' '}
                  <ul className="ps-1">
                    <li>{t('schedule.one_to_one_recompense_issue')}</li>
                    <li>{t('schedule.same_day_recompense_issue')}</li>
                    <li>{t('schedule.redated_session')}</li>
                  </ul>
                </>
              }
            />

            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className="fw-bold mb-3 mt-3">
                    {t('attendeeDashboard.Upcoming_Schedule')}
                  </h3>
                  <span>{currentDate.format('dddd, MMMM D, YYYY')}</span>
                  <br />
                  <span> {t('utc.calculation', { timeZone })}</span>
                </div>
              </div>
            </div>

            {meetingSchedule &&
              meetingSchedule
                .filter((item) => {
                  const currentDate = new Date();
                  const forCheck = new Date();
                  currentDate.setHours(
                    currentDate.getHours() + getTimeZone(true) / 60
                  );
                  const [day, month, year] = item.date.split('-');
                  const [parsedS, parsedE] = item.interval
                    .split('-')
                    .map(String);
                  const [sH, sM] = parsedS?.split(':').map(Number);
                  const [eH, eM] = parsedE?.split(':').map(Number);
                  const calculatedTotal = eH * 60 + eM - (sH * 60 + sM);
                  const itemDateTime = new Date(
                    `${year}-${month}-${day}T${
                      item.interval.split('-')[0]
                    }:00.000`
                  );
                  const eventEndTime =
                    itemDateTime.getHours() * 60 +
                    itemDateTime.getMinutes() +
                    calculatedTotal;
                  return `${year}-${month.toString().padStart(2, '0')}-${day
                    .toString()
                    .padStart(2, '0')}` ===
                    currentDate.toISOString().split('T')[0]
                    ? eventEndTime >
                        forCheck.getHours() * 60 + forCheck.getMinutes() &&
                        item.isActive &&
                        !item.recompensedSession
                    : itemDateTime >= forCheck &&
                        item.isActive &&
                        !item.recompensedSession;
                })
                .sort((a, b) => {
                  const [aday, amonth, ayear] = a.date.split('-');
                  const aDateTime = new Date(
                    `${ayear}-${amonth}-${aday}T${
                      a.interval.split('-')[0]
                    }:00.000Z`
                  );
                  const [bday, bmonth, byear] = b.date.split('-');
                  const bDateTime = new Date(
                    `${byear}-${bmonth}-${bday}T${
                      b.interval.split('-')[0]
                    }:00.000Z`
                  );
                  return aDateTime - bDateTime; // Ascending order
                })
                .slice(0, 5)
                .map((item, index) => (
                  <div key={index}>
                    <div
                      className="card mb-3 rounded-3"
                      style={{ borderLeft: borderColor(item.type) }}
                    >
                      <div className="card-body p-0">
                        {/* <a className="text-right position-absolute top-0 end-0 m-3 text-t-gri">
                      <FontAwesomeIcon icon={faEllipsis} />
                    </a> */}

                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center m-2">
                          <strong
                            className="py-1"
                            style={{
                              fontSize: '0.8rem',
                            }}
                          >
                            {item.speakerName}
                          </strong>
                          <div
                            className={`${
                              ButtonType[item.type]
                            } p-1 align-middle text-nowrap text-white fw-bold d-flex justify-content-between rounded mt-2 mt-md-0`}
                            style={{
                              flexWrap: 'wrap',
                            }}
                          >
                            <strong
                              style={{
                                fontSize: '0.7rem',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {getLocationKey(item?.locationId)} {item.type}{' '}
                              {item.trialStatus === 'FreeTrial'
                                ? t('schedule.session_free_trial')
                                : item.trialStatus === 'PaidTrial'
                                ? t('schedule.session_paid_trial')
                                : null}{' '}
                              {item.trialStatus === 'NonTrial' &&
                              item.category === 'OneToOne'
                                ? t('mentor.onetoone')
                                : t('mentor.group')}
                              {item?.category === 'Group' ? (
                                <div
                                  key={`pop_up_card_div_capacity_${index}_${item.date}_${item.speakerId}`}
                                  className="ps-1 text-capitalize"
                                >
                                  {t('onlineGroup.person', {
                                    person: item?.capacity,
                                  })}
                                </div>
                              ) : null}
                            </strong>
                          </div>
                        </div>

                        <div className=" d-flex justify-content-between align-items-center mx-1">
                          <div className="flex-shrink-0">
                            <img
                              src={item.speakerImage}
                              style={{
                                height: '90px',
                                width: '80px',
                                borderRadius: '0.5rem',
                              }}
                              className=" img-fluid rounded "
                              alt=" conttentImage"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/images/empty-profile.png';
                              }}
                            />
                          </div>
                          <div
                            className="align-self-center flex-grow-1  ps-2 "
                            style={{
                              fontSize: '0.8rem',
                            }}
                          >
                            <div className="text-nowrap text-t-gri px-auto">
                              <FontAwesomeIcon icon={faCalendar} /> {item.date}
                              <br />
                              <FontAwesomeIcon icon={faClock} /> {item.interval}
                            </div>
                            <div className="text-nowrap">
                              {item.category === 'Group' ? (
                                <div className="row d-flex justify-content-around align-items-center">
                                  <div className="text-nowrap px-auto">
                                    {t(`${item?.meetingLevel}`)}
                                  </div>
                                  <span className="py-1">
                                    <strong>
                                      <IconDisplay
                                        iconCount={item?.attendeeCount}
                                      />
                                    </strong>
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {item.isActive &&
                          item.type !== 'Cafe' &&
                          item.type !== 'Company' ? (
                            <div className=" flex-grow-0 m-1 ">
                              <Button
                                type={
                                  handleJoinSession(item)
                                    ? ButtonType.Secondary
                                    : ButtonType.Info
                                }
                                buttonCol={12}
                                size={ButtonSize.Small}
                                textColor={TextColors.Light}
                                text={t('mySessions.join_session')}
                                disabled={handleJoinSession(item)}
                                onClick={() =>
                                  handleClick(item?.meetingDetails, item)
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            {meetingInfo !== '' && (
              <ToastContainer
                key={'meetingInfo'}
                style={customToastStyle}
                limit={1}
                autoClose={1800}
                closeOnClick
                theme="light"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
