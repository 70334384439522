import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhone } from '../../../../hooks/useFormatPhone';
import Actions from '../../../../store/redux/actions';
import Card from '../../../../components/Common/Card/Card';
import Button from '../../../../components/Common/Button/Button';
import Label from '../../../../components/Common/TextLabel/Label';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import Dropdown from '../../../../components/Common/Dropdown/Dropdown';
import Input from '../../../../components/Common/Input/Input';
import Pagination from '../../../../components/Common/Paginate/Pagination';
import truncateString from '../../../../hooks/useTruncateString';
import {
  ButtonIcon,
  ButtonType,
  InputType,
  LabelFontSize,
  TextColors,
  ButtonSize,
  RegexType,
} from '../../../../utils/ComponentEnums';
import {
  faCalendar,
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleUser,
  faClock,
  faEllipsis,
  faGlobe,
  faListCheck,
  faPlayCircle,
  faSearch,
  faStar,
  faUser,
  faWindowMaximize,
  faWindowMinimize,
} from '@fortawesome/free-solid-svg-icons';
import { faIdeal } from '@fortawesome/free-brands-svg-icons';
import learningPurposeSVG from '../../../../assets/images/learning_purpose.svg';
import typeOfServiceSVG from '../../../../assets/images/typeofservice.svg';
import sessionLevelSVG from '../../../../assets/images/session_level.svg';
import dateSVG from '../../../../assets/images/date.svg';
import servicesSVG from '../../../../assets/images/services.svg';
import mentorLike from '../../../../assets/images/like_button.svg';
import CalendarDropdown from '../../../../components/Common/Dropdown/CalendarDropdown';
import Modal from '../../../../components/Common/Modal/Modal';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import regexValidator from '../../../../hooks/useRegexValidator';
import validationMessage from '../../../../hooks/useValidationMessage';
import Tooltip from '../../../../components/Common/ToolTip/Tooltip';
import VideoModal from '../../../../components/Common/Modal/VideoModal';
import { ToastContainer, toast } from 'react-toastify';
import { checkLogged } from '../../../../hooks/useCheckLogged';
import calculateUTC from '../../../../hooks/useCalculateUTC';
import { storageKeys } from '../../../../utils/Enum';
import findMyPath from '../../../../hooks/useFindMyPath';
import addDay from '../../../../hooks/useAddDay';
import getTimeZone from '../../../../hooks/useGetTimeZone';
import removeCookieItem from '../../../../hooks/useRemoveCookie.js';
import { Loader } from '../../../../components/Common/Loader/Loader.jsx';

const AttendeeDashboard = ({
  askLanguageLevel = false,
  askBirthDate = false,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const userId = useSelector((state) => state.auth.currentUser);
  const holdFilter = useSelector((state) => state.holdFilter.filter);
  const totalItem = useSelector((state) => state.speaker.pagination.TotalCount);
  const isSuccessAttendee = useSelector((state) => state.attendee.isSuccess);
  const isSuccessUser = useSelector((state) => state.user.isSuccess);
  const meetingSchedule = useSelector(
    (state) => state.meetingSchema.getAllSchedule
  );
  const locations = useSelector((state) => state.location.branches);
  const mySubsPeriod = useSelector((state) => state.order.mySubsPeriod);
  const getBoard = useSelector((state) => state.onboard.onboard);
  const speakers = useSelector((state) => state.speaker.filteredSpeakers);
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const staticDatas = useSelector((state) => state.staticData);
  const learningPurpose = staticDatas.LearningPurpose;
  const typeOfService = staticDatas.TypeOfService;
  const native = staticDatas.Native;
  const freeTrialAble =
    !userInfo?.freeTrialUsed || userInfo?.freeTrialUsed === undefined
      ? true
      : false;
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const pageSize = 6;
  const pageNumber = 1;
  const timeZone = getTimeZone();
  const isChild = userInfo?.isChild ?? null;
  const isOnline = userInfo?.userArea === 0;
  const isCafe = userInfo?.userArea === 1;
  const isKids = userInfo?.userArea === 2;

  const [filter, setFilter] = useState({});
  const [locale, setLocale] = useState(i18n.language);
  const [currentDate, setCurrentDate] = useState(moment().locale(locale));
  const [askLevel, setAskLevel] = useState(askLanguageLevel);
  const [askBirthDay, setAskBirthDay] = useState(askBirthDate);
  const [attendeeFirstLevel, setAttendeeFirstLevel] = useState({});
  const [birthDate, setBirthDate] = useState({});
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [currentSize, setCurrentSize] = useState(pageSize);
  const [showLoader, setShowLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (userInfo?.userArea !== undefined) {
      setFilter({
        LearningPurpose:
          holdFilter?.LearningPurpose?.map((item) => item) || null,
        LanguageLevels:
          isKids && !isChild && isChild !== null
            ? null
            : !isKids && isChild
            ? null
            : userInfo?.languageLevel !== '' &&
              userInfo?.languageLevel !== undefined
            ? userInfo?.languageLevel
            : holdFilter?.LanguageLevels || null,
        TypeOfService: isCafe
          ? [1]
          : holdFilter?.TypeOfService?.map((item) => item) || null,
        Native: holdFilter?.Native?.map((item) => item) || null,
        FullName: null,
        UserArea: userInfo?.userArea,
        Locations: isCafe
          ? holdFilter?.Locations?.map((item) => item) || null
          : [1],
        SelectedIds: null,
        PackageId: null,
        TrialStatus: 2,
        CompanyId: userInfo?.userArea === 3 ? userInfo?.companyId : 1,
        AgeId: !isKids ? 4 : holdFilter?.AgeId || null,
        DateDetails: {
          dayOfWeek: holdFilter?.DateDetails?.dayOfWeek || null,
          timeInterval: holdFilter?.DateDetails?.timeInterval || null,
          selectedDate: holdFilter?.DateDetails?.selectedDate || null,
          selectedTime: holdFilter?.DateDetails?.selectedTime || null,
        },
      });
    }
  }, [userInfo]);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(!isMobile);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelectChange = (target, isMulti = false, takeKey = false) => {
    setShowLoader(true);
    let name = target.name;
    let value = target.value;
    const optionValue = takeKey ? target.key : parseInt(value);

    if (isMulti) {
      const updatedArray = [...(filter[name] || [])];
      const valueIndex = updatedArray.indexOf(optionValue);

      if (valueIndex !== -1) {
        updatedArray.splice(valueIndex, 1);
      } else {
        updatedArray.push(optionValue);
      }
      setFilter({
        ...filter,
        [name]: updatedArray.length > 0 ? updatedArray : null,
      });

      dispatch(
        Actions.speakerActions.getFilteredAction(pageNumber, pageSize, {
          ...filter,
          [name]: updatedArray.length > 0 ? updatedArray : null,
        })
      ).then(() => {
        setShowLoader(false);
      });
    }
    if (!isMulti) {
      if (name === 'UserArea') {
        if (filter[name] === optionValue) {
          const { [name]: _, ...updatedBadgeCount } = filter;
          setFilter(updatedBadgeCount);
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
          });
          dispatch(
            Actions.speakerActions.getFilteredAction(pageNumber, pageSize, {
              ...filter,
              [name]: optionValue,
            })
          ).then(() => {
            setShowLoader(false);
          });
        }
      } else {
        if (filter[name] === optionValue) {
          setFilter({
            ...filter,
            [name]: null,
          });
          dispatch(
            Actions.speakerActions.getFilteredAction(pageNumber, pageSize, {
              ...filter,
              [name]: null,
            })
          ).then(() => {
            setShowLoader(false);
          });
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
          });
          dispatch(
            Actions.speakerActions.getFilteredAction(pageNumber, pageSize, {
              ...filter,
              [name]: optionValue,
            })
          ).then(() => {
            setShowLoader(false);
          });
        }
      }
    }

    setCurrentPage(pageNumber);
    setCurrentSize(pageSize);
  };

  async function getLists() {
    await dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    await dispatch(Actions.orderActions.getMySubscriptionPeriodAction());
    if (isCafe && locations.length === 0) {
      await dispatch(
        Actions.locationActions.getLocationsByUserAreaAction({
          userArea: 1,
        })
      );
    }
    if (isKids && ageInterval.length === 0) {
      await dispatch(Actions.ageIntervalActions.getAllAction(2));
    }
  }

  useEffect(() => {
    if (lengthCurrentUserClaim && userInfo?.id !== undefined) {
      getLists();
    }
  }, [userInfo]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      const { value } = e.target;
      const newValue = value.trim() === '' ? null : value;
      const updatedFilter = { ...filter, FullName: newValue };
      setFilter(updatedFilter);
      dispatch(
        Actions.speakerActions.getFilteredAction(
          pageNumber,
          pageSize,
          updatedFilter
        )
      ).then(() => setShowLoader(false));
    }
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const newValue = value.trim() === '' ? null : value;
    setFilter({ ...filter, FullName: newValue });
  };
  const onSearchClick = (e) => {
    setShowLoader(true);
    e.preventDefault();
    const inputValue = document.getElementById('FullName').value;
    const newValue = inputValue.trim() === '' ? null : inputValue;
    const updatedFilter = { ...filter, FullName: newValue };
    setFilter(updatedFilter);
    dispatch(
      Actions.speakerActions.getFilteredAction(
        pageNumber,
        pageSize,
        updatedFilter
      )
    ).then(() => setShowLoader(false));
  };

  const combinedArray = [
    ...native.map((item) => ({
      key: item.key,
      name: item.name,
      value: item.value,
      group: item.name,
    })),
    ...learningPurpose.map((item) => ({
      key: item.key,
      name: item.name,
      value: item.value,
      group: item.name,
    })),
  ];

  function handleNavigateCard(e, to, state) {
    e.preventDefault();
    dispatch(Actions.calendarActions.getFilteredReducer([]));
    return navigate(to, state);
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const borderColor = (type) => {
    switch (type) {
      case 'Online':
        return '10px solid #4CBC9A';
      case 'Cafe':
        return '10px solid #FFDB80';
      case 'Kids':
        return '10px solid #FF8F80';
      case 'Company':
        return '10px solid #3A3650';
      default:
        return '10px solid transparent';
    }
  };

  const getColorClass = (type) => {
    switch (type) {
      case 'Online':
        return 'bg-custom';
      case 'Cafe':
        return 'bg-t-sarı';
      case 'Kids':
        return 'bg-t-turuncu';
      case 'Company':
        return 'bg-t-calendar';
      default:
        return 'bg-light';
    }
  };

  const [selectedWeek, setSelectedWeek] = useState(currentDate);
  let currentWeek = [];
  for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    let nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
    currentWeek.push(moment(nextDate));
  }

  const previousWeek = () => {
    let prevDates = [];
    let prevWeek = [];
    for (let dayOffset = 7; dayOffset > 0; dayOffset--) {
      let prevDate = moment(selectedWeek).subtract(dayOffset, 'days').toDate();
      prevDates.push(moment(prevDate));
    }
    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      var a = moment(prevDates.at(0)).add(dayOffset, 'days').toDate();
      prevWeek.push(moment(a));
    }
    setSelectedWeek(() => moment(prevWeek.at(0)));
  };

  const nextWeek = () => {
    let nextDates = [];
    let nxtWeek = [];
    for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
      let nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
      nextDates.push(moment(nextDate));
    }
    for (let dayOffset = 1; dayOffset < 8; dayOffset++) {
      var a = moment(nextDates.at(6)).add(dayOffset, 'days').toDate();
      nxtWeek.push(moment(a));
    }
    setSelectedWeek(() => moment(nxtWeek.at(0)));
  };

  let mainText = `${moment(selectedWeek).format('D MMM YYYY')}`;
  const calendarRef = useRef(null);
  const anotherDates = [];
  const reportDates = [];
  for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    const nextDate = moment(selectedWeek).add(dayOffset, 'days').toDate();
    reportDates.push(moment(nextDate));
    anotherDates.push(moment(nextDate));
    anotherDates.push(moment(...anotherDates, reportDates));
  }
  const handleDateTime = (id, date) => {
    const updatedFilter = { ...filter };
    const today = new Date();
    const dateOnly = today.toISOString().split('T')[0];
    setShowLoader(true);
    if (id === 'dayOfWeek') {
      updatedFilter.DateDetails.selectedDate = null;
      if (updatedFilter.DateDetails.dayOfWeek === null) {
        if (updatedFilter.DateDetails.timeInterval !== null) {
          timeIntervalSet(date, true);
        }
        if (updatedFilter.DateDetails.selectedTime !== null) {
          selectedTimeSet(date, true);
        }
        updatedFilter.DateDetails.dayOfWeek = [`${date}T00:00:00.000Z`];
      } else {
        const selectedDate = `${date}T00:00:00.000Z`;
        const index = updatedFilter.DateDetails.dayOfWeek.indexOf(selectedDate);
        if (index === -1) {
          updatedFilter.DateDetails.dayOfWeek.push(`${date}T00:00:00.000Z`);
          if (updatedFilter.DateDetails.timeInterval !== null) {
            timeIntervalSet(date, false);
          }
          if (updatedFilter.DateDetails.selectedTime !== null) {
            selectedTimeSet(date, false);
          }
        } else {
          updatedFilter.DateDetails.dayOfWeek.splice(index, 1);
          if (updatedFilter.DateDetails.dayOfWeek.length === 0) {
            updatedFilter.DateDetails.dayOfWeek = null;
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(dateOnly, undefined, 'dayOfWeek');
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(dateOnly, undefined, 'dayOfWeek');
            }
          } else {
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(date, null);
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(date, null);
            }
          }
        }
      }
    }
    function timeIntervalSet(value, check) {
      updatedFilter.DateDetails.selectedTime = null;
      const dateTimePairs = updatedFilter.DateDetails.timeInterval.map(
        (interval) => {
          const [startDateTime, endDateTime] = interval.split('Z-');
          return [startDateTime, endDateTime];
        }
      );
      const updatedDateTimePairs = dateTimePairs.map((pair) => {
        const startHour = pair[0].split('T')[1].split(':')[0];
        const endHour = pair[1].split('T')[1].split(':')[0];
        const selectedDate = value;
        return `${selectedDate}T${startHour}:00:00.000Z-${selectedDate}T${endHour}:00:00.000Z`;
      });

      if (updatedFilter.DateDetails.timeInterval.length > 0) {
        const mergedTimeInterval = [
          ...updatedFilter.DateDetails.timeInterval,
          ...updatedDateTimePairs,
        ];
        let filteredDates = [];
        const checkStatus =
          (updatedFilter.DateDetails.dayOfWeek === null ||
            updatedFilter.DateDetails.selectedDate === null) &&
          !check;
        if (checkStatus) {
          filteredDates = updatedDateTimePairs.filter((date) =>
            date.includes(value)
          );
        }

        updatedFilter.DateDetails.timeInterval = check
          ? updatedDateTimePairs
          : checkStatus
          ? filteredDates
          : mergedTimeInterval;
      } else {
        updatedFilter.DateDetails.timeInterval = updatedDateTimePairs;
      }

      return updatedFilter.DateDetails.timeInterval;
    }
    function selectedTimeSet(value, check, parameter) {
      updatedFilter.DateDetails.timeInterval = null;
      const checkStatusEmpty =
        updatedFilter.DateDetails[parameter] === null && check === undefined;
      const checkStatusRemoved =
        updatedFilter.DateDetails[parameter] !== null && check === null;
      const hours = updatedFilter.DateDetails.selectedTime.map((interval) => {
        const [hour] = interval.split('T')[1].split(':');
        return [hour];
      });
      let updatedHours = [];
      if (!checkStatusRemoved) {
        updatedHours = hours.map((item) => {
          const selectedDate = value;
          return `${selectedDate}T${item}:00:00.000Z`;
        });
      }

      if (updatedFilter.DateDetails.selectedTime.length > 0) {
        const mergedTimeInterval = [
          ...new Set([
            ...updatedFilter.DateDetails.selectedTime,
            ...updatedHours,
          ]),
        ];
        let filteredDates = [];

        if (checkStatusEmpty) {
          filteredDates = updatedHours.filter((date) => date.includes(value));
        }
        if (checkStatusRemoved) {
          filteredDates = mergedTimeInterval.filter(
            (date) => !date.includes(value)
          );
        }

        updatedFilter.DateDetails.selectedTime = check
          ? updatedHours
          : checkStatusEmpty || checkStatusRemoved
          ? filteredDates
          : mergedTimeInterval;
      } else {
        updatedFilter.DateDetails.selectedTime = updatedHours;
      }

      return updatedFilter.DateDetails.selectedTime;
    }
    if (id === 'timeInterval') {
      updatedFilter.DateDetails.selectedTime = null;
      const cleanedDate = date.replace(/\s+/g, '');
      if (
        filter.DateDetails.dayOfWeek !== null ||
        filter.DateDetails.selectedDate !== null
      ) {
        updatedFilter.DateDetails.timeInterval =
          updatedFilter.DateDetails.timeInterval || [];

        const datesToProcess = [
          ...(filter.DateDetails.dayOfWeek || []),
          ...(filter.DateDetails.selectedDate || []),
        ];
        datesToProcess.forEach((dates) => {
          const selectedDate = dates.split('T')[0];
          updatedFilter.DateDetails.timeInterval.push(
            `${selectedDate}T${
              cleanedDate.split('-')[0]
            }:00:00.000Z-${selectedDate}T${
              cleanedDate.split('-')[1]
            }:00:00.000Z`
          );
        });
      } else if (filter.DateDetails.timeInterval === null) {
        updatedFilter.DateDetails.timeInterval = [
          `${dateOnly}T${cleanedDate.split('-')[0]}:00:00.000Z-${dateOnly}T${
            cleanedDate.split('-')[1]
          }:00:00.000Z`,
        ];
      } else if (filter.DateDetails.timeInterval !== null) {
        const newTimeSlot = `${dateOnly}T${
          cleanedDate.split('-')[0]
        }:00:00.000Z-${dateOnly}T${cleanedDate.split('-')[1]}:00:00.000Z`;
        const index =
          updatedFilter.DateDetails.timeInterval.indexOf(newTimeSlot);
        if (index === -1) {
          updatedFilter.DateDetails.timeInterval.push(newTimeSlot);
        } else {
          updatedFilter.DateDetails.timeInterval.splice(index, 1);
          if (updatedFilter.DateDetails.timeInterval.length === 0) {
            updatedFilter.DateDetails.timeInterval = null;
          }
        }
      }
    }
    if (id === 'selectedDate') {
      updatedFilter.DateDetails.dayOfWeek = null;
      if (updatedFilter.DateDetails.selectedDate === null) {
        if (updatedFilter.DateDetails.timeInterval !== null) {
          timeIntervalSet(date, true);
        }
        if (updatedFilter.DateDetails.selectedTime !== null) {
          selectedTimeSet(date, true);
        }
        updatedFilter.DateDetails.selectedDate = [`${date}T00:00:00.000Z`];
      } else {
        const selectedDate = `${date}T00:00:00.000Z`;
        const index =
          updatedFilter.DateDetails.selectedDate.indexOf(selectedDate);
        if (index === -1) {
          updatedFilter.DateDetails.selectedDate.push(`${date}T00:00:00.000Z`);
          if (updatedFilter.DateDetails.timeInterval !== null) {
            timeIntervalSet(date, false);
          }
          if (updatedFilter.DateDetails.selectedTime !== null) {
            selectedTimeSet(date, false);
          }
        } else {
          updatedFilter.DateDetails.selectedDate.splice(index, 1);
          if (updatedFilter.DateDetails.selectedDate.length === 0) {
            updatedFilter.DateDetails.selectedDate = null;
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(dateOnly, undefined, 'selectedDate');
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(dateOnly, undefined, 'selectedDate');
            }
          } else {
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(date, null);
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(date, null);
            }
          }
        }
      }
    }
    if (id === 'selectedTime') {
      updatedFilter.DateDetails.timeInterval = null;
      if (
        filter.DateDetails.dayOfWeek !== null ||
        filter.DateDetails.selectedDate !== null
      ) {
        updatedFilter.DateDetails.selectedTime =
          updatedFilter.DateDetails.selectedTime || [];

        const datesToProcess = [
          ...(filter.DateDetails.dayOfWeek || []),
          ...(filter.DateDetails.selectedDate || []),
        ];
        datesToProcess.forEach((dates) => {
          const selectedDate = dates.split('T')[0];
          updatedFilter.DateDetails.selectedTime.push(
            `${selectedDate}T${date}:00.000Z`
          );
        });
      } else if (filter.DateDetails.selectedTime === null) {
        updatedFilter.DateDetails.selectedTime = [
          `${dateOnly}T${date}:00.000Z`,
        ];
      } else if (filter.DateDetails.selectedTime !== null) {
        const newTimeSlot = `${dateOnly}T${date}:00.000Z`;
        const index =
          updatedFilter.DateDetails.selectedTime.indexOf(newTimeSlot);
        if (index === -1) {
          updatedFilter.DateDetails.selectedTime.push(
            `${dateOnly}T${date}:00.000Z`
          );
        } else {
          updatedFilter.DateDetails.selectedTime.splice(index, 1);
          if (updatedFilter.DateDetails.selectedTime.length === 0) {
            updatedFilter.DateDetails.selectedTime = null;
          }
        }
      }
    }
    setFilter(updatedFilter);
    dispatch(
      Actions.speakerActions.getFilteredAction(pageNumber, pageSize, {
        ...filter,
        DateDetails: updatedFilter.DateDetails,
      })
    ).then(() => {
      setShowLoader(false);
    });
  };

  function removeHTMLTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
  function resetCookie() {
    removeCookieItem([
      storageKeys.firstRegister,
      storageKeys.firstRegisterLanding,
    ]);
  }

  useEffect(() => {
    if (isSuccessAttendee) {
      setAskLevel(false);
      resetCookie();
      setAttendeeFirstLevel({});
    }
  }, [isSuccessAttendee]);

  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  function extractVideoId(url) {
    const patterns = [
      /(?:youtube\.com\/embed\/|youtu.be\/|youtube.com\/watch\?v=)([\w-]{11})/,
      /^([\w-]{11})$/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match) {
        return match[1];
      }
    }

    return null;
  }
  const stopPropagation = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageClick(value);
  };

  const handleImageClick = (url) => {
    url = extractVideoId(url);
    if (url) {
      setVideoModalVisible(true);
      setVideoUrl(url);
    }
  };
  const closeVideoModal = () => {
    setVideoModalVisible(false);
  };

  const handleBirthDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setBirthDate({ ...userData, [id]: value });
  };

  useEffect(() => {
    if (isSuccessUser && (askBirthDate || askLanguageLevel)) {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
      setAskBirthDay(false);
      resetCookie();
      setBirthDate({});
    }
  }, [isSuccessUser]);

  function handleDates(item) {
    const [year, month, day] = item?.split('T')[0].split('-').map(Number);
    const date = `${day}-${month}-${year}`;
    const timeArray = item?.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const time = `${h}:${m}`;
    const prosess = addDay(time, date);
    const fixedDate = `${prosess}T${time}:00.000Z`;

    const [xday, xmonth, xyear] = fixedDate
      ?.split('T')[0]
      .split('-')
      .map(Number);
    const fixDate = `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
      .toString()
      .padStart(2, '0')}T${calculateUTC(fixedDate?.split('T')[1])}`;
    return fixDate;
  }
  const [meetingInfo, setMeetingInfo] = useState('');
  const findMeetingUrl = (item, data) => {
    let meetingUrl = null;

    const fifteenMinutesBeforeStart = new Date(handleDates(item[0]?.startDate));
    fifteenMinutesBeforeStart.setMinutes(
      fifteenMinutesBeforeStart.getMinutes() - 15
    );
    const clickAbleLastFiveMins = fifteenMinutesBeforeStart;
    clickAbleLastFiveMins.setMinutes(clickAbleLastFiveMins.getMinutes() + 10);

    const currentDateTime = new Date();
    const firstDate = new Date(handleDates(item[0]?.startDate));
    const min =
      clickAbleLastFiveMins.getMinutes() - currentDateTime.getMinutes();

    if (currentDateTime < clickAbleLastFiveMins) {
      return setMeetingInfo(
        <div>
          {t('mySessions.join_session_info', { min })
            ?.split('{br}')
            ?.map((item, index) => (
              <span
                key={index}
                className="d-flex position-relative justify-content-start"
                style={{ maxWidth: '550px' }}
              >
                <strong>{item}</strong>
              </span>
            ))}
        </div>
      );
    } else if (
      currentDateTime > fifteenMinutesBeforeStart &&
      currentDateTime < firstDate
    ) {
      meetingUrl = item[0]?.meetingUrl;
      setMeetingInfo('');
    }
    const sesMin = findSessionMinute(data);
    const rule = item?.length === 1 ? item?.length : item?.length - 1;
    for (let i = 0; i < rule; i++) {
      const meeting = item[i];
      const meetingDateTime = new Date(handleDates(meeting?.startDate));
      if (item.length === 1) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }
      const nextMeeting = item[i + 1];
      let nextMeetingDateTime;
      if (nextMeeting !== undefined && sesMin) {
        const secondLink = new Date(handleDates(nextMeeting?.startDate));
        nextMeetingDateTime = new Date(
          secondLink.setMinutes(secondLink.getMinutes() - 10)
        );
      } else {
        nextMeetingDateTime = new Date(handleDates(nextMeeting?.startDate));
      }
      if (
        i === 0 &&
        currentDateTime >= meetingDateTime &&
        currentDateTime <= nextMeetingDateTime
      ) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }

      if (currentDateTime > nextMeetingDateTime) {
        meetingUrl = nextMeeting?.meetingUrl;
        break;
      }
    }
    return meetingUrl;
  };
  const handleClick = (item, data) => {
    const meetingUrl = findMeetingUrl(item, data);
    if (meetingUrl) {
      window.open(meetingUrl, '_blank');
    } else {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    }
  };

  function findSessionMinute(meeting) {
    const [parsedS, parsedE] = meeting?.interval.split('-').map(String);
    const [sH, sM] = parsedS?.split(':').map(Number);
    const [eH, eM] = parsedE?.split(':').map(Number);
    return eH * 60 + eM - (sH * 60 + sM) >= 90;
  }

  const handleJoinSession = (meeting) => {
    const currentTime = new Date();
    const utcTime = new Date(
      currentTime.getTime() + currentTime.getTimezoneOffset() * 60000
    );
    const date = utcTime.toISOString().substr(0, 10);
    let availableMeetings;
    const getCurrent = calculateUTC(
      `${utcTime.getHours()}:${utcTime.getMinutes()}`
    );
    const cTime = calculateUTC(
      `${meeting.interval.split('-')[0].split(':')[0]}:${
        meeting.interval.split('-')[0].split(':')[1]
      }`
    );
    const [hx, mx] = getCurrent.split(':').map(Number);
    const asMinx = hx * 60 + mx;
    const [h, m] = cTime.split(':').map(Number);
    const asMin = h * 60 + m - 15;
    if (meeting.date === date && asMin <= asMinx) {
      meeting.meetingDetails.forEach((meetingDetail, index) => {
        const is90 = findSessionMinute(meeting);
        let addMin;
        switch (index) {
          case 0:
            addMin = is90 ? 40 * 60000 : 30 * 60000;
            break;
          case 1:
            addMin = is90 ? 40 * 60000 : 30 * 60000;
            break;
          default:
            addMin = 40 * 60000;
            break;
        }
        const fixDate = handleDates(meetingDetail.startDate);
        const xDate = new Date(fixDate).getTime() + addMin;
        const meetingDateTime = new Date(xDate);
        if (meetingDateTime >= currentTime) {
          availableMeetings = meetingDetail;
        }
      });
    }
    return availableMeetings === undefined;
  };
  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    }, 5 * 60 * 1000);
    return () => clearTimeout(timerId);
  }, [meetingSchedule]);

  useEffect(() => {
    if (meetingInfo !== '') {
      toast.info(meetingInfo, {
        onClose: () => {
          setMeetingInfo('');
        },
      });
    }
  }, [meetingInfo]);

  const handleUISessionTypeClick = async (value) => {
    const createData = {
      productType: value,
      trialStatus: 2,
      userArea: filter.UserArea,
    };
    if (value === 0) {
      navigate(findMyPath('order/package/group'), {
        state: {
          createData,
          fromNavigateWhere: value !== undefined ? 'dashboard' : '',
        },
      });
    } else {
      navigate(findMyPath('order/package/one-to-one'), {
        state: {
          createData,
          fromNavigateWhere: value !== undefined ? 'dashboard' : '',
        },
      });
    }
  };
  const getLocationKey = (id) => {
    const location = locations.find((loc) => loc.value === id);
    return location ? location.key : null;
  };

  const IconDisplay = ({ iconCount, capacity }) => {
    const renderExtraIcon = iconCount > 3;
    return (
      <div className="icon-container">
        {Array.from({ length: Math.min(iconCount, 3) }).map((_, index) => (
          <FontAwesomeIcon
            key={`${index}_${iconCount + 1}`}
            icon={faCircleUser}
            size="2x"
            className={`icon ${index !== iconCount - 1 ? 'hidden' : ''}`}
            style={{ zIndex: index + 1 }}
          />
        ))}
        <div
          className={`icon-container position-relative`}
          style={{ left: '-1px' }}
        >
          {renderExtraIcon && (
            <>
              <FontAwesomeIcon
                className="text-secondary icon"
                icon={faCircle}
                size="2x"
                style={{ zIndex: 20 }}
              />
              <span
                className="position-absolute ms-2 top-50 start-90 translate-middle badge rounded-pill"
                style={{ zIndex: 20 }}
              >
                {renderExtraIcon && `+${iconCount - 3}`}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    if (filter?.UserArea === 2) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (filter?.UserArea !== 2 && filter?.UserArea !== undefined) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }

  const companyPriceRequest = useSelector(
    (state) => state.companyPriceRequest.companyPriceRequest
  );
  const companyIsSuccess = useSelector(
    (state) => state.companyPriceRequest.isSuccess
  );
  const companyMessage = useSelector(
    (state) => state.companyPriceRequest.validationMessage
  );
  const [createData, setCreateData] = useState(companyPriceRequest);
  const [companyError, setCompanyError] = useState(undefined);
  const [companyRequestMessage, setCompanyRequestMessage] = useState(undefined);
  const [error, setError] = useState({});

  useEffect(() => {
    if (createData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  }, [createData?.phone, error?.phone]);

  function findFirstNonUndefinedElement(obj) {
    for (const key in obj) {
      if (obj[key] !== undefined) {
        return obj[key];
      }
    }
    return null;
  }

  const onChangeText = (e) => {
    e.preventDefault();
    if (companyIsSuccess !== '') {
      dispatch(Actions.resetStoreAction.resetStore());
      setCompanyRequestMessage(undefined);
    }
    setCompanyError(undefined);
    const { id, value, type, required, name } = e.target;
    setError({ ...error, [id]: undefined });

    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    }
  };
  const onFocus = () => {
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.companyPriceRequestActions.holdValue({ ...createData }));
  };
  const onSave = async (e) => {
    e.preventDefault();
    const hasErrors = Object.values(error).some(
      (errorMessage) => errorMessage !== undefined
    );

    if (!hasErrors && Object.keys(createData).length >= 9) {
      await dispatch(
        Actions.companyPriceRequestActions.createAction(createData)
      );
    } else if (hasErrors) {
      const errorField = findFirstNonUndefinedElement(error);
      setCompanyError(
        <span className="text-danger">{errorField.props.children}</span>
      );
    } else {
      setCompanyError(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
  };

  useEffect(() => {
    if (!companyIsSuccess && companyMessage !== '') {
      setCompanyError(undefined);
      setCompanyRequestMessage(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong className="text-danger">{companyMessage}</strong>
        </span>
      );
    } else if (companyIsSuccess && companyMessage === '') {
      setCompanyError(undefined);
      setCompanyRequestMessage(
        <span
          className="d-flex position-relative mx-auto  justify-content-center fs-6"
          style={{ maxWidth: '550px' }}
        >
          <strong className="text-success">
            {t('companyPriceRequest.success_response')}
          </strong>
        </span>
      );
      setCreateData(companyPriceRequest);
    }
  }, [companyIsSuccess, companyMessage]);

  useEffect(() => {
    if (
      filter.TypeOfService !== undefined &&
      filter.Locations !== undefined &&
      filter.LearningPurpose !== undefined &&
      filter.Native !== undefined
    ) {
      const filterObject = {
        LearningPurpose: filter.LearningPurpose,
        LanguageLevels: filter.LanguageLevels,
        TypeOfService: filter.TypeOfService,
        Native: filter.Native,
        UserArea: filter.UserArea,
        TrialStatus: filter.TrialStatus,
        FullName: filter.FullName,
        Locations: filter.Locations,
        SelectedIds: filter.SelectedIds,
        PackageId: filter.PackageId,
        CompanyId: 1,
        AgeId: filter.AgeId,
        DateDetails: filter.DateDetails,
      };
      dispatch(Actions.filterActions.holdFilter(filterObject));
    }
  }, [filter]);

  useEffect(() => {
    if (filter?.UserArea !== undefined && filter.LanguageLevels !== undefined) {
      if (
        filter.UserArea !== undefined &&
        filter.LanguageLevels !== null &&
        filter.LanguageLevels !== undefined &&
        filter.LanguageLevels !== ''
      ) {
        setShowLoader(true);
        dispatch(
          Actions.speakerActions.getFilteredAction(pageNumber, pageSize, filter)
        ).then(() => {
          setShowLoader(false);
        });
      } else if (
        filter.LanguageLevels === null ||
        filter.LanguageLevels !== undefined
      ) {
        setShowLoader(true);
        dispatch(
          Actions.speakerActions.getFilteredAction(pageNumber, pageSize, filter)
        ).then(() => {
          setShowLoader(false);
        });
      }
    }
  }, [filter.UserArea]);

  const content = (
    <div className="container-fluid">
      <div className="row">
        {askLevel && (
          <Modal
            key={'modal_for_language_level'}
            isVisible={askLevel}
            styleProps={{ maxWidth: 550, height: 330 }}
            title={t('attendeeDashboard.first_register_modal')}
            content={
              <>
                <div className="d-none d-lg-flex flex-row align-items-center justify-content-center filter-section">
                  <Dropdown
                    key={`main_languageLevel`}
                    name={'LanguageLevels'}
                    options={selectedLanguageLevels}
                    title={t('attendeeDashboard.language_level')}
                    propNames={{
                      key: 'name',
                      value: 'value',
                      name: 'key',
                    }}
                    handleChange={(target) =>
                      setAttendeeFirstLevel({
                        languageLevels: target.value,
                      })
                    }
                    id={'languageLevels'}
                    arrowUp
                    svg={sessionLevelSVG}
                    isFilter
                  />
                </div>
                <div className="mt-5">{''}</div>
                <div className="d-flex justify-content-end mt-5">
                  <Button
                    key={'button_for_language_level'}
                    type={ButtonType.Successbg}
                    textColor={TextColors.Light}
                    bold
                    buttonCol={2}
                    text={t('button.submit')}
                    onClick={() =>
                      dispatch(
                        Actions.attendeeActions.updateAction(attendeeFirstLevel)
                      )
                    }
                  />
                </div>
              </>
            }
            onClose={() => {
              setAskLevel(false);
              resetCookie();
            }}
          />
        )}
        {askBirthDay && (
          <Modal
            key={'modal_for_birth_date'}
            isVisible={askBirthDay}
            styleProps={{ maxWidth: 450, height: 300 }}
            title={t('attendeeDashboard.birth_date')}
            content={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <div className="col">
                    <InputLabelOnBorder
                      id={'dateOfBirth'}
                      inputType={InputType.Date}
                      labelText={t('personalInformation.birth_date')}
                      inputValue={
                        moment(birthDate.dateOfBirth).format('yyyy-MM-DD') || ''
                      }
                      onChange={handleBirthDate}
                    />
                  </div>
                </div>
                <div className="d-flex col-4 ms-auto justify-content-end mt-5">
                  <Button
                    key={'button_for_birth_date'}
                    type={ButtonType.Successbg}
                    textColor={TextColors.Light}
                    bold
                    buttonCol={12}
                    text={t('button.submit')}
                    onClick={() =>
                      dispatch(
                        Actions.userActions.updateAction(birthDate, userId)
                      )
                    }
                  />
                </div>
              </>
            }
            onClose={() => {
              setAskBirthDay(false);
              resetCookie();
            }}
          />
        )}
        <div className="col-lg-8 ">
          <div
            style={{
              backgroundImage: 'linear-gradient(#A48EFF,#6244FF)',
              boxShadow: '1px 2px 9px',
              border: '15',
              marginBottom: 20,
              padding: isExpanded ? '10px' : '5px',
              transition: 'padding 0.3s ease',
            }}
          >
            <div className="p-sm-3 text-white">
              <h5>{t('attendeeDashboard.sign_up_now', { getBoard })}</h5>
              <h6>{t('attendeeDashboard.socializing_with_our_native')}</h6>
              {isExpanded && (
                <p className="p-0 m-0">
                  {t('attendeeDashboard.is_a_platform_where_you_can')}
                </p>
              )}
              <div className="d-flex justify-content-between">
                <div className="text-nowrap">
                  <Button
                    type={ButtonType.Light}
                    text={t(
                      freeTrialAble && !isKids
                        ? 'button.get_free_session'
                        : 'button.get_subsccription'
                    )}
                    buttonCol={12}
                    size={ButtonSize.Small}
                    onClick={(e) =>
                      handleNavigateCard(
                        e,
                        freeTrialAble
                          ? findMyPath('order/triallesson')
                          : findMyPath('order/package'),
                        isKids && freeTrialAble
                          ? {
                              state: {
                                fromNavigateWhere: 'paidTrial',
                                trialStatus: 1,
                              },
                            }
                          : freeTrialAble
                          ? {
                              state: {
                                fromNavigateWhere: 'freeTrial',
                                trialStatus: 0,
                              },
                            }
                          : {
                              state: {
                                fromNavigateWhere: 'nonTrial',
                                trialStatus: 2,
                              },
                            }
                      )
                    }
                  />
                </div>
                <button
                  className="btn btn-link d-block mt-1 text-decoration-none ms-auto text-white"
                  onClick={toggleExpand}
                >
                  {isExpanded ? (
                    <FontAwesomeIcon icon={faWindowMinimize}></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon icon={faWindowMaximize}></FontAwesomeIcon>
                  )}
                </button>
              </div>
            </div>
          </div>
          <h3 className="ms-1">
            {`${getBoard}`} {t('attendeeDashboard.Select_Mentor')}
          </h3>
          <Card
            padding={8}
            body={
              <div className="filter-container">
                {/* Desktop Filter Section */}
                {!isMobile && (
                  <div className="d-none d-lg-flex flex-row align-items-center filter-section">
                    <div className="flex-fill">
                      {userInfo?.languageLevel !== undefined &&
                      userInfo?.languageLevel !== null &&
                      userInfo?.languageLevel !== '' &&
                      ((isChild && isKids) || (!isChild && !isKids)) ? (
                        <div className="d-flex align-items-center justify-content-start">
                          <label className="text-nowrap">
                            <Tooltip
                              key={'main_mobile_languageLevel_tooltip'}
                              bottom={9}
                              id={'mentor_desmentor_mobile_filterktop_filter'}
                              text={
                                <span className="text-dark">
                                  {t('personalInformation.tooltip')}
                                </span>
                              }
                            >
                              <Dropdown
                                key={'attendeeDashboard_languageLevel'}
                                id={'languageLevels'}
                                title={t(`${filter?.LanguageLevels}`)}
                                rounded={2}
                                svgWidth={30}
                                svg={sessionLevelSVG}
                                noClickAble={true}
                              />
                            </Tooltip>
                          </label>
                        </div>
                      ) : (
                        <Dropdown
                          id={'languageLevels'}
                          key={'attendeeDashboard_languageLevel'}
                          title={t(
                            filter?.LanguageLevels === null ||
                              filter?.LanguageLevels === undefined
                              ? 'attendeeDashboard.language_level'
                              : filter?.LanguageLevels
                          )}
                          propNames={{
                            key: 'key',
                            value: 'value',
                            name: 'key',
                          }}
                          options={selectedLanguageLevels}
                          selectedOptions={selectedLanguageLevels?.filter(
                            (val) => filter?.LanguageLevels === val.key
                          )}
                          handleChange={(target) =>
                            handleSelectChange(target, false, true)
                          }
                          rounded={2}
                          svgWidth={30}
                          svg={sessionLevelSVG}
                          isFilter
                          addIndex
                          arrowUp={true}
                          resetState={filter.LanguageLevels === null}
                        />
                      )}
                    </div>
                    {!isCafe && (
                      <div className="flex-fill">
                        <Dropdown
                          key={`attendeeDashboard_typeOfService`}
                          id={'typeofService'}
                          title={t('attendeeDashboard.type_of_service')}
                          propNames={{
                            key: 'name',
                            value: 'value',
                            name: 'key',
                          }}
                          options={typeOfService}
                          selectedOptions={typeOfService.filter((service) =>
                            filter?.TypeOfService?.includes(
                              parseInt(service.value)
                            )
                          )}
                          handleChange={(target) =>
                            handleSelectChange(target, true)
                          }
                          rounded={2}
                          svgWidth={30}
                          svg={typeOfServiceSVG}
                          arrowUp
                          isFilter
                          isMulti
                        />
                      </div>
                    )}
                    {isCafe && (
                      <div className="flex-fill">
                        <Dropdown
                          key={`attendeeDashboard_locations`}
                          id={'locations'}
                          title={t('attendeeDashboard.locations')}
                          propNames={{
                            key: 'key',
                            value: 'value',
                            name: 'key',
                          }}
                          options={locations}
                          selectedOptions={locations.filter((service) =>
                            filter?.Locations?.includes(parseInt(service.value))
                          )}
                          handleChange={(target) =>
                            handleSelectChange(target, true)
                          }
                          rounded={2}
                          svgWidth={30}
                          svg={typeOfServiceSVG}
                          arrowUp
                          isFilter
                          isMulti
                        />
                      </div>
                    )}
                    {isKids && (
                      <div className="flex-fill">
                        <Dropdown
                          key={`attendeeDashboard_ageInterval`}
                          id={'ageInterval'}
                          title={
                            filter?.AgeId === null ||
                            filter?.AgeId === undefined ||
                            ageInterval?.filter(
                              (f) => f.value === filter?.AgeId
                            )?.[0]?.key === undefined
                              ? t('attendeeDashboard.ageInterval')
                              : `${
                                  ageInterval?.filter(
                                    (f) => f.value === filter?.AgeId
                                  )?.[0]?.key
                                } ${t('attendeeDashboard.age')}`
                          }
                          propNames={{
                            key: 'name',
                            value: 'value',
                            name: 'key',
                          }}
                          options={ageInterval}
                          selectedOptions={ageInterval?.filter(
                            (opt) => filter?.AgeId === opt.value
                          )}
                          handleChange={(target) => handleSelectChange(target)}
                          rounded={2}
                          svgWidth={30}
                          svg={typeOfServiceSVG}
                          arrowUp
                          isFilter
                        />
                      </div>
                    )}
                    {/* <div className="flex-fill">
                      <CalendarDropdown
                        title={t('attendeeDashboard.date_time')}
                        id={'dateTime'}
                        svg={dateSVG}
                        func={(val) => handleDateTime(val.id, val.date)}
                        selectedOptions={holdFilter?.DateDetails}
                      />
                    </div> */}
                    <div className="flex-fill">
                      <Dropdown
                        key={'attendeeDashboard_selectArea'}
                        id={'More'}
                        title={t('attendeeDashboard.more')}
                        propNames={{
                          key: 'name',
                          value: 'value',
                          name: 'key',
                        }}
                        options={combinedArray}
                        selectedOptions={combinedArray.filter(
                          (item) =>
                            (filter?.Native?.includes(parseInt(item.value)) &&
                              item.name === 'Native') ||
                            (filter?.LearningPurpose?.includes(
                              parseInt(item.value)
                            ) &&
                              item.name === 'LearningPurpose')
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, true)
                        }
                        rounded={2}
                        svgWidth={30}
                        svg={servicesSVG}
                        arrowUp
                        isFilter
                        isMulti
                        group
                      />
                    </div>
                    <div className="flex-fill my-2 me-1">
                      <div className="d-flex justify-content-evenly">
                        <Input
                          key={'attendeeDashboard_fullName'}
                          id="FullName"
                          className={
                            'col-12 me-1 bg-m2t border-0 medium border-bottom border-secondary'
                          }
                          type={InputType.Text}
                          value={filter.FullName || ''}
                          placeholder={t('attendeeDashboard.search_mentors')}
                          onKeyPress={onKeyPress}
                          onChange={onChangeSearch}
                          style={{ fontSize: '.86rem' }}
                        />
                        <Button
                          icon={ButtonIcon.Search}
                          borderless
                          iconColor={TextColors.Success}
                          onClick={onSearchClick}
                          buttonCol={1}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Mobile Filter Section */}
                {isMobile && (
                  <div className="d-lg-none flex-row align-items-center filter-section">
                    <div className="row d-flex align-items-center justify-content-center">
                      {!isCafe && (
                        <div className="col-4 flex-fill">
                          <Dropdown
                            key={`attendeeDashboard_mobile_typeOfService`}
                            id={'typeofService'}
                            title={t('attendeeDashboard.type_of_service')}
                            propNames={{
                              key: 'name',
                              value: 'value',
                              name: 'key',
                            }}
                            options={typeOfService}
                            selectedOptions={typeOfService.filter((service) =>
                              filter?.TypeOfService?.includes(
                                parseInt(service.value)
                              )
                            )}
                            handleChange={(target) =>
                              handleSelectChange(target, true)
                            }
                            rounded={2}
                            svgWidth={25}
                            svg={typeOfServiceSVG}
                            arrowUp
                            isFilter
                            isMulti
                          />
                        </div>
                      )}
                      {isCafe && (
                        <div className="col-4 flex-fill">
                          <Dropdown
                            key={`attendeeDashboard_mobile_locations`}
                            id={'locations'}
                            title={t('attendeeDashboard.locations')}
                            propNames={{
                              key: 'key',
                              value: 'value',
                              name: 'key',
                            }}
                            options={locations}
                            selectedOptions={locations.filter((service) =>
                              filter?.Locations?.includes(
                                parseInt(service.value)
                              )
                            )}
                            handleChange={(target) =>
                              handleSelectChange(target, true)
                            }
                            rounded={2}
                            svgWidth={25}
                            svg={typeOfServiceSVG}
                            arrowUp
                            isFilter
                            isMulti
                          />
                        </div>
                      )}
                      {isKids && (
                        <div className="col-4 flex-fill">
                          <Dropdown
                            key={`attendeeDashboard_mobile_ageInterval`}
                            id={'ageInterval'}
                            title={
                              filter?.AgeId === null ||
                              filter?.AgeId === undefined ||
                              ageInterval?.filter(
                                (f) => f.value === filter?.AgeId
                              )?.[0]?.key === undefined
                                ? t('attendeeDashboard.ageInterval')
                                : `${
                                    ageInterval?.filter(
                                      (f) => f.value === filter?.AgeId
                                    )?.[0]?.key
                                  } ${t('attendeeDashboard.age')}`
                            }
                            propNames={{
                              key: 'name',
                              value: 'value',
                              name: 'key',
                            }}
                            options={ageInterval}
                            selectedOptions={ageInterval?.filter(
                              (opt) => filter?.AgeId === opt.value
                            )}
                            handleChange={(target) =>
                              handleSelectChange(target)
                            }
                            rounded={2}
                            svgWidth={25}
                            svg={typeOfServiceSVG}
                            fontSize={9.5}
                            arrowUp
                            isFilter
                            resetState={filter?.AgeId === null}
                          />
                        </div>
                      )}
                      {!isKids && (
                        <div className="col-4 flex-fill">
                          {userInfo?.languageLevel !== undefined &&
                          userInfo?.languageLevel !== null &&
                          userInfo?.languageLevel !== '' &&
                          ((isChild && isKids) || (!isChild && !isKids)) ? (
                            <div className="d-flex align-items-center justify-content-start">
                              <label className="text-nowrap">
                                <Tooltip
                                  key={
                                    'attendeeDashboard_mobile_languageLevel_tooltip'
                                  }
                                  bottom={9}
                                  id={'languageLevel'}
                                  text={
                                    <span className="text-dark">
                                      {t('personalInformation.tooltip')}
                                    </span>
                                  }
                                >
                                  <Dropdown
                                    key={'main_mobile_languageLevel'}
                                    id={'languageLevels'}
                                    title={t(`${filter?.LanguageLevels}`)}
                                    rounded={2}
                                    svgWidth={25}
                                    svg={sessionLevelSVG}
                                    fontSize={9.5}
                                    noClickAble={true}
                                  />
                                </Tooltip>
                              </label>
                            </div>
                          ) : (
                            <Dropdown
                              id={'languageLevels'}
                              key={'attendeeDashboard_mobile_languageLevel'}
                              title={t(
                                filter?.LanguageLevels === null ||
                                  filter?.LanguageLevels === undefined
                                  ? 'attendeeDashboard.language_level'
                                  : filter?.LanguageLevels
                              )}
                              propNames={{
                                key: 'key',
                                value: 'value',
                                name: 'key',
                              }}
                              options={selectedLanguageLevels}
                              selectedOptions={selectedLanguageLevels?.filter(
                                (val) => filter?.LanguageLevels === val.key
                              )}
                              handleChange={(target) =>
                                handleSelectChange(target, false, true)
                              }
                              rounded={2}
                              svgWidth={25}
                              fontSize={9.5}
                              svg={sessionLevelSVG}
                              arrowUp={true}
                              isFilter
                              addIndex
                              resetState={filter?.LanguageLevels === null}
                            />
                          )}
                        </div>
                      )}
                      {/* <div className="col-4 ps-4 flex-fill">
                        <CalendarDropdown
                          title={t('attendeeDashboard.date_time')}
                          id={'dateTime'}
                          svg={dateSVG}
                          func={(val) => handleDateTime(val.id, val.date)}
                          selectedOptions={holdFilter?.DateDetails}
                        />
                      </div> */}
                      <div className="col-4 flex-fill">
                        <div
                          className="dropdown-header d-flex align-items-center justify-content-center"
                          role="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasFilter"
                          aria-controls="offcanvasFilter"
                          style={{ borderColor: '#00c905' }}
                        >
                          <div style={{ position: 'relative' }}>
                            <img
                              width={25}
                              src={learningPurposeSVG}
                              alt={learningPurposeSVG}
                            />
                          </div>
                          <span className="dropdown-title">
                            {t('landing.menu_filter')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="filter-dropdowns offcanvas offcanvas-end"
                      tabIndex="-1"
                      id="offcanvasFilter"
                      aria-labelledby="offcanvasFilterLabel"
                    >
                      <div className="offcanvas-header">
                        <h5
                          className="offcanvas-title"
                          id="offcanvasFilterLabel"
                        >
                          {t('landing.menu_mentor_filter')}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="offcanvas-body flex-row align-items-center justify-content-start py-2">
                        {isKids && (
                          <div className="flex-fill">
                            {userInfo?.languageLevel !== undefined &&
                            userInfo?.languageLevel !== null &&
                            userInfo?.languageLevel !== '' &&
                            ((isChild && isKids) || (!isChild && !isKids)) ? (
                              <div className="d-flex align-items-center justify-content-start">
                                <label className="text-nowrap">
                                  <Tooltip
                                    key={'attendeeDashboard_mobile_tooltip'}
                                    bottom={9}
                                    id={'languageLevel'}
                                    text={
                                      <span className="text-dark">
                                        {t('personalInformation.tooltip')}
                                      </span>
                                    }
                                  >
                                    <Dropdown
                                      key={'main_mobile_languageLevel'}
                                      id={'languageLevels'}
                                      title={t(`${filter?.LanguageLevels}`)}
                                      rounded={2}
                                      svgWidth={30}
                                      svg={sessionLevelSVG}
                                      noClickAble={true}
                                    />
                                  </Tooltip>
                                </label>
                              </div>
                            ) : (
                              <Dropdown
                                id={'languageLevels'}
                                key={'attendeeDashboard_mobile_languageLevel'}
                                title={t(
                                  filter?.LanguageLevels === null ||
                                    filter?.LanguageLevels === undefined
                                    ? 'attendeeDashboard.language_level'
                                    : filter?.LanguageLevels
                                )}
                                propNames={{
                                  key: 'key',
                                  value: 'value',
                                  name: 'key',
                                }}
                                options={selectedLanguageLevels}
                                selectedOptions={selectedLanguageLevels?.filter(
                                  (val) => filter?.LanguageLevels === val.key
                                )}
                                handleChange={(target) =>
                                  handleSelectChange(target, false, true)
                                }
                                rounded={2}
                                svgWidth={30}
                                svg={sessionLevelSVG}
                                arrowUp={true}
                                isFilter
                                addIndex
                                resetState={filter.LanguageLevels === null}
                              />
                            )}
                          </div>
                        )}
                        <div className="flex-fill">
                          <Dropdown
                            key={'attendeeDashboard_mobile_learningPurpose'}
                            id={'learningPurpose'}
                            title={t('attendeeDashboard.learning_purpose')}
                            propNames={{
                              key: 'name',
                              value: 'value',
                              name: 'key',
                            }}
                            options={learningPurpose}
                            selectedOptions={learningPurpose.filter((service) =>
                              filter?.LearningPurpose?.includes(
                                parseInt(service.value)
                              )
                            )}
                            handleChange={(target) =>
                              handleSelectChange(target, true)
                            }
                            rounded={2}
                            svgWidth={30}
                            svg={learningPurposeSVG}
                            arrowUp={true}
                            isMulti
                            isFilter
                          />
                        </div>
                        <div className="flex-fill">
                          <Dropdown
                            key={'attendeeDashboard_mobile_native'}
                            id={'native'}
                            title={t('attendeeDashboard.native')}
                            options={native}
                            propNames={{
                              key: 'name',
                              value: 'value',
                              name: 'key',
                            }}
                            selectedOptions={native.filter((service) =>
                              filter?.Native?.includes(parseInt(service.value))
                            )}
                            handleChange={(target) =>
                              handleSelectChange(target, true)
                            }
                            rounded={2}
                            svgWidth={30}
                            svg={servicesSVG}
                            arrowUp={true}
                            isFilter
                            isMulti
                          />
                        </div>
                        {/* <div className="flex-fill">
                          <CalendarDropdown
                            title={t('attendeeDashboard.date_time')}
                            id={'dateTime'}
                            svg={dateSVG}
                            func={(val) => handleDateTime(val.id, val.date)}
                            selectedOptions={holdFilter?.DateDetails}
                          />
                        </div> */}
                        <div className="flex-fill my-3 me-1">
                          <div className="d-flex justify-content-evenly">
                            <Input
                              key={`attendeeDashboard_mobile_fullName`}
                              id="FullName"
                              className={
                                'col-12 me-1 bg-m2t border-0 medium border-bottom border-secondary'
                              }
                              type={InputType.Text}
                              placeholder={t(
                                'attendeeDashboard.search_mentors'
                              )}
                              value={filter.FullName || ''}
                              onKeyPress={onKeyPress}
                              onChange={onChangeSearch}
                              style={{ fontSize: '.86rem' }}
                            />
                            <Button
                              icon={ButtonIcon.Search}
                              borderless
                              iconColor={TextColors.Success}
                              onClick={onSearchClick}
                              buttonCol={1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          />

          <div className="d-block content-wrapper">
            <div
              id="speakersContent"
              className={'row row-cols-1 row-cols-lg-2 row-cols-xl-3'}
            >
              {showLoader ? (
                <div className="d-flex align-items-center py-3 justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : speakers.length === 0 ? (
                <div className="mx-auto d-flex justify-content-center text-nowrap">
                  {t('attendeeDashboard.no_mentors_found_to_be_listed')}
                </div>
              ) : (
                speakers.map((speakerItem, index) => {
                  return (
                    <div
                      className="col-12"
                      key={`${speakerItem?.specialUrl}_${index}`}
                    >
                      <Link
                        className="text-reset text-decoration-none"
                        id={speakerItem?.id}
                        title={speakerItem?.specialUrl}
                        to={findMyPath(
                          `speakers/speaker-detail/${speakerItem?.id}`
                        )}
                      >
                        <Card
                          body={
                            <div
                              style={{
                                maxHeight: '400px',
                                height: '400px',
                              }}
                            >
                              <div style={{ position: 'relative' }}>
                                {speakerItem?.speakerImage ? (
                                  <>
                                    <img
                                      src={speakerItem?.speakerImage}
                                      className={`card-img-top img-fluid rounded-4`}
                                      alt={speakerItem?.speakerName}
                                      onClick={(e) =>
                                        stopPropagation(
                                          e,
                                          speakerItem.specialUrl
                                        )
                                      }
                                      style={{
                                        maxWidth: '100%',
                                        height: '200px',
                                        objectFit: 'cover', //cover, contain
                                      }}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          '/images/empty-profile.png';
                                      }}
                                    />
                                    {speakerItem.specialUrl && (
                                      <div className="video-icon-container">
                                        <FontAwesomeIcon
                                          className="video-player-icon"
                                          icon={faPlayCircle}
                                          onClick={(e) =>
                                            stopPropagation(
                                              e,
                                              speakerItem.specialUrl
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <img
                                    src="/images/empty-profile.png"
                                    className={`card-img-top img-fluid rounded-4`}
                                    alt={speakerItem?.speakerName}
                                    onClick={(e) =>
                                      stopPropagation(e, speakerItem.specialUrl)
                                    }
                                    style={{
                                      maxWidth: '100%',
                                      height: '200px',
                                      maxHeight: '50%',
                                      objectFit: 'cover', //cover, contain
                                    }}
                                  />
                                )}
                              </div>

                              <h6 className={`card-title pt-3 fw-bold`}>
                                {speakerItem?.speakerName}
                              </h6>
                              <div
                                className={`card-text d-inline-block`}
                                style={{
                                  overflowY: 'hidden',
                                  height: '110px',
                                  maxHeight: '110px',
                                }}
                              >
                                {removeHTMLTags(
                                  truncateString(speakerItem?.htmlContent, 350)
                                )}
                              </div>
                              {/* <div className="text-left">
                              <div>
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                  className="ms-1"
                                  icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="ms-1"
                                ></FontAwesomeIcon>
                                <span className="ps-2">3</span>
                              </div>
                            </div> */}
                              <div
                                className={`d-flex ${
                                  speakerItem.trialStatus !== 'NonTrial'
                                    ? 'justify-content-around '
                                    : 'justify-content-center'
                                } py-2 `}
                              >
                                {speakerItem.trialStatus !== 'NonTrial' && (
                                  <div className="col px-1 text-nowrap">
                                    <Button
                                      text={
                                        isKids ||
                                        speakerItem.trialStatus === 'PaidTrial'
                                          ? t('button.paid_trial')
                                          : t('button.free_trial')
                                      }
                                      type={ButtonType.Success}
                                      size={ButtonSize.Small}
                                      outline
                                      bold
                                      onClick={(e) =>
                                        speakerItem.trialStatus ===
                                          'FreeTrial' && !isKids
                                          ? handleNavigateCard(
                                              e,
                                              isOnline
                                                ? '/order/triallesson'
                                                : isCafe
                                                ? '/cafe/order/triallesson'
                                                : isKids
                                                ? '/kids/order/triallesson'
                                                : '/order/triallesson',
                                              {
                                                state: {
                                                  fromNavigateWhere:
                                                    'freeTrial',
                                                  trialStatus: 0,
                                                  userArea: filter.UserArea,
                                                  speakerId: speakerItem.id,
                                                  speakerImage:
                                                    speakerItem.speakerImage,
                                                  speakerName:
                                                    speakerItem.speakerName,
                                                },
                                              }
                                            )
                                          : speakerItem.trialStatus ===
                                            'PaidTrial'
                                          ? handleNavigateCard(
                                              e,
                                              isOnline
                                                ? '/order/triallesson'
                                                : isCafe
                                                ? '/cafe/order/triallesson'
                                                : isKids
                                                ? '/kids/order/triallesson'
                                                : '/order/triallesson',
                                              {
                                                state: {
                                                  fromNavigateWhere:
                                                    'paidTrial',
                                                  trialStatus: 1,
                                                  userArea: filter.UserArea,
                                                  speakerId: speakerItem.id,
                                                  speakerName:
                                                    speakerItem.speakerName,
                                                  speakerImage:
                                                    speakerItem.speakerImage,
                                                },
                                              }
                                            )
                                          : null
                                      }
                                      buttonCol={12}
                                    />
                                  </div>
                                )}
                                <div className="col px-1 text-nowrap">
                                  <Button
                                    text={t('button.buy')}
                                    type={ButtonType.Successbg}
                                    textColor={TextColors.Light}
                                    size={ButtonSize.Small}
                                    bold
                                    onClick={(e) =>
                                      handleNavigateCard(
                                        e,
                                        findMyPath('speakers'),
                                        {
                                          state: {
                                            directlyBuyCalendar: true,
                                            trialStatus: 2,
                                            userArea: filter.UserArea,
                                            speakerId: speakerItem.id,
                                            speakerName:
                                              speakerItem.speakerName,
                                            speakerImage:
                                              speakerItem.speakerImage,
                                          },
                                        }
                                      )
                                    }
                                    buttonCol={12}
                                  />
                                </div>
                              </div>
                            </div>
                          }
                        ></Card>
                      </Link>
                    </div>
                  );
                })
              )}
            </div>
            {isVideoModalVisible && (
              <VideoModal
                videoUrl={`https://www.youtube.com/embed/${videoUrl}`}
                styleProps={{ maxWidth: 550, height: 500 }}
                onClose={closeVideoModal}
              />
            )}
            <div className="d-flex justify-content-between align-items-center my-3">
              <div className="col-8 d-flex justify-content-start align-self-center align-items-center">
                {speakers.length > 0 && (
                  <Pagination
                    totalItems={totalItem}
                    itemsPerPage={pageSize}
                    contentId={'speakersContent'}
                    paginate={(numb) => {
                      dispatch(
                        Actions.speakerActions.getFilteredAction(
                          numb,
                          pageSize,
                          filter
                        )
                      ).then(() => {
                        setShowLoader(false);
                      });
                    }}
                  />
                )}
              </div>

              <div className="col-4 d-flex justify-content-end align-items-center text-nowrap">
                <Button
                  text={t('button.all_mentor')}
                  textColor="text-white"
                  type={ButtonType.Successbg}
                  size={ButtonSize.Small}
                  onClick={() => navigate(findMyPath('speakers'))}
                  buttonCol={12}
                />
              </div>
            </div>

            <div>
              <Card
                body={
                  <div className="row mt-3 d-flex align-items-center justify-content-around">
                    <h3 className="text-center">
                      <strong>{t('attendeeDashboard.Subscription')}</strong>
                    </h3>
                    <div className=" text-center">
                      <Label
                        fontSize={LabelFontSize.fs6}
                        value={t(
                          'subscription.now_that_you_understand_how_meet2talk_works'
                        )}
                      />
                    </div>
                    <div className="d-flex flex-wrap justify-content-between px-1">
                      {!isCafe && (
                        <div className="col-12 col-md-6 px-1 mt-2 mt-md-3">
                          <button
                            title={'One-to-one'}
                            value={1}
                            className="btn btn-default btn-sm border border-1 my-2 px-2 py-2 rounded-3 text-nowrap bg-success text-white fw-bold"
                            style={{ width: '100%' }}
                            onClick={() => handleUISessionTypeClick(1)}
                          >
                            {t('button.one_to_one')}
                          </button>
                        </div>
                      )}
                      <div
                        className={`col-12 col-md-6 px-1 mt-2 mt-md-3 ${
                          isCafe ? 'mx-auto' : ''
                        }`}
                      >
                        <button
                          title={'Group'}
                          value={0}
                          className="btn btn-default btn-sm border border-1 my-2 px-3 py-2 rounded-3 text-nowrap bg-warning text-white fw-bold"
                          style={{ width: '100%' }}
                          onClick={() => handleUISessionTypeClick(0)}
                        >
                          {t('button.group')}
                        </button>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className="d-lg-block col-lg-4">
          {
            <Card
              body={
                <div className="">
                  <HeadTags
                    text={t('attendeeDashboard.My_Subscription_Period')}
                    strong
                    hSize={4}
                    marginBottom={4}
                  />
                  {mySubsPeriod?.length === 0 && (
                    <span className="mb-3">
                      {t(
                        'attendeeDashboard.My_Subscription_Period_dated_package'
                      )}
                    </span>
                  )}
                  {mySubsPeriod?.map((value, index) => (
                    <React.Fragment key={`${value.orderItemId}_${index}`}>
                      {!isCafe &&
                        value?.userArea !== 'Cafe' &&
                        value?.packageCapacity === 1 && (
                          <div className="my-3">
                            <span className="text-success fw-bold">
                              <strong>
                                {t(
                                  'attendeeDashboard.My_Subscription_One_to_one_text',
                                  {
                                    value: {
                                      completedSession: value?.completedSession,
                                      totalSession: value?.totalSession,
                                      userArea: value?.userArea,
                                      packageMinutes: value?.packageMinutes,
                                    },
                                  }
                                )}
                              </strong>
                            </span>
                            <div
                              className="progress mb-2"
                              role="progressbar"
                              aria-label="Warning example"
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar text-bg-online-green"
                                style={{
                                  width: `${
                                    (value?.completedSession /
                                      value?.totalSession) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  ))}
                  {mySubsPeriod?.map((value, index) => (
                    <React.Fragment key={`${value.orderItemId}_${index}`}>
                      <div>
                        {value?.packageCapacity > 1 && (
                          <div className="my-3">
                            <span className="text-success my-5">
                              <strong>
                                {t(
                                  'attendeeDashboard.My_Subscription_Group_text',
                                  {
                                    value: {
                                      completedSession: value?.completedSession,
                                      totalSession: value?.totalSession,
                                      userArea: value?.userArea,
                                      sessionType:
                                        value.userArea !== 'Cafe'
                                          ? '- Group'
                                          : '',
                                      packageMinutes: value?.packageMinutes,
                                      packageCapacity: value?.packageCapacity,
                                    },
                                  }
                                )}
                              </strong>
                            </span>
                            <div
                              className="progress mb-2"
                              role="progressbar"
                              aria-label="Warning example"
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar text-bg-t-sarı"
                                style={{
                                  width: `${
                                    (value?.completedSession /
                                      value?.totalSession) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              }
            />
          }
          {/* <h3 className="fw-bold mb-3 ms-1"> Günün Programı</h3>

          <Card
            body={
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex small-2 medium-2 large-2 bg-t-mor columns text-center">
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="d-inline p-4 align-self-center"
                    ></FontAwesomeIcon>
                  </div>
                  <div className="col-8 ">
                    <h5 className="fw-bold text"> Learn to read Sanskrit</h5>
                    <p>09:00 - 11:00 With mr.ijam</p>
                  </div>
                  <div className="col-1 ">
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            }
          />

          <Card
            body={
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex small-2 medium-2 large-2 bg-t-turuncu columns text-center">
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="d-inline p-4 align-self-center"
                    ></FontAwesomeIcon>
                  </div>
                  <div className="col-8 ms-2 pt-2">
                    <h5 className="fw-bold text"> Learn to read Sanskrit</h5>
                    <p>09:00 - 11:00 With mr.ijam</p>
                  </div>
                  <div className="col-1 p-4">
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            }
          />

          <Card
            body={
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex small-2 medium-2 large-2 bg-t-sarı columns text-center">
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="d-inline p-4 align-self-center"
                    ></FontAwesomeIcon>
                  </div>
                  <div className="col-8 ms-2 pt-2">
                    <h5 className="fw-bold text"> Learn to read Sanskrit</h5>
                    <p>09:00 - 11:00 With mr.ijam</p>
                  </div>
                  <div className="col-1 p-4">
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            }
          /> */}

          <div className="bg-t-calendar rounded text-white mt-5 mb-5">
            <div className="container">
              <div className="row justify-content-between align-items-center m-2">
                <div
                  className="col-2 col-md-3 d-flex align-items-center justify-content-start text-center"
                  role="button"
                  onClick={
                    selectedWeek.date() !== currentDate.date()
                      ? previousWeek
                      : null
                  }
                >
                  <div className="my-1">
                    {selectedWeek.date() !== currentDate.date() ? (
                      <FontAwesomeIcon icon={faChevronLeft} />
                    ) : undefined}
                  </div>
                </div>
                <div className="col-8 col-md-6 text-center">
                  <h4>{mainText}</h4>
                </div>
                <div
                  className="col-2 col-md-3 d-flex align-items-center justify-content-end text-center"
                  role="button"
                  onClick={nextWeek}
                >
                  <div className="my-1">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 calendar-scroll"
              ref={calendarRef}
              style={{ width: '100%', overflowY: 'hidden' }}
            >
              <div className="d-flex align-items-center py-1 justify-content-between text-center">
                {reportDates?.map((day, index) => {
                  const matchingEvents = meetingSchedule?.filter((event) => {
                    const [year, month, nday] = event.date
                      .split('-')
                      .map(Number);
                    const date = addDay(
                      event.interval.split('-')[0],
                      `${nday}-${month}-${year}`
                    );
                    const [xday, xmonth, xyear] = date.split('-').map(Number);
                    const nDate = new Date(
                      `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
                        .toString()
                        .padStart(2, '0')}`
                    );

                    return (
                      nDate.toISOString().split('T')[0] ===
                        day.format('YYYY-MM-DD') && event.isActive
                    );
                  });
                  const modifiedMatchingEvents = matchingEvents?.map(
                    (event) => {
                      const [year, month, nday] = event.date
                        .split('-')
                        .map(Number);
                      const date = addDay(
                        event?.interval?.split('-')[0],
                        `${nday}-${month}-${year}`
                      );
                      const [xday, xmonth, xyear] = date.split('-').map(Number);
                      return {
                        ...event,
                        date: `${xyear}-${xmonth
                          .toString()
                          .padStart(2, '0')}-${xday
                          .toString()
                          .padStart(2, '0')}`,
                        interval: `${calculateUTC(
                          event.interval.split('-')[0]
                        )}-${calculateUTC(event.interval.split('-')[1])}`,
                      };
                    }
                  );

                  const colorClass =
                    modifiedMatchingEvents?.length > 0
                      ? getColorClass(modifiedMatchingEvents[0].type)
                      : 'bg-light';

                  return (
                    <button
                      key={`${day.format('YYYY-MM-DD')}-${index}`}
                      className={`btn btn-sm rounded-4 mx-1 p-0 d-inline-flex align-items-center justify-content-center flex-grow-1 ${colorClass}`}
                      onMouseOver={(e) => (e.target.style.color = 'black')}
                      style={{
                        flex: '1 0 auto',
                        minWidth: '34px',
                      }}
                    >
                      {day.format('ddd')}
                      <br />
                      {day.format('D')}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="fw-bold mb-3 mt-3">
                  {t('attendeeDashboard.Upcoming_Schedule')}
                </h3>
                <span>{currentDate.format('dddd, MMMM D, YYYY')}</span>
                <br />
                <span> {t('utc.calculation', { timeZone })}</span>
              </div>
              {/* <div className="align-self-center bg-white  p-3 rounded-3">
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ color: '#4CBC9A' }}
                ></FontAwesomeIcon>
              </div> */}
            </div>
          </div>
          {meetingSchedule?.length > 0 &&
            meetingSchedule
              ?.filter((item, i) => {
                const currentDate = new Date();
                const forCheck = new Date();
                currentDate.setHours(
                  currentDate.getHours() + getTimeZone(true) / 60
                );
                const [parsedS, parsedE] = item?.interval
                  .split('-')
                  .map(String);
                const [sH, sM] = parsedS?.split(':').map(Number);
                const [eH, eM] = parsedE?.split(':').map(Number);
                const calculatedTotal = eH * 60 + eM - (sH * 60 + sM);
                const [year, month, nday] = item?.date?.split('-').map(Number);
                const xInterval = calculateUTC(item?.interval.split('-')[0]);
                const eventDate = addDay(
                  item?.interval.split('-')[0],
                  `${nday}-${month}-${year}`
                );
                const [xday, xmonth, xyear] = eventDate.split('-').map(Number);
                const eventDateTime = new Date(
                  `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
                    .toString()
                    .padStart(2, '0')}T${xInterval}:00.000`
                );
                const eventStartTime =
                  eventDateTime.getHours() * 60 + eventDateTime.getMinutes();

                const eventEndTime =
                  eventDateTime.getHours() * 60 +
                  eventDateTime.getMinutes() +
                  calculatedTotal;

                return `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
                  .toString()
                  .padStart(2, '0')}` ===
                  currentDate.toISOString().split('T')[0]
                  ? eventEndTime >
                      forCheck.getHours() * 60 + forCheck.getMinutes() &&
                      item?.isActive &&
                      !item.recompensedSession
                  : eventDateTime >= forCheck &&
                      item?.isActive &&
                      !item.recompensedSession;
              })
              ?.sort((a, b) => {
                const [ayear, amonth, aday] = a.date.split('-').map(Number);
                const adate = addDay(
                  a?.interval.split('-')[0],
                  `${ayear}-${amonth}-${aday}`
                );
                const [xaday, xamonth, xayear] = adate.split('-').map(Number);
                const aDate = new Date(
                  `${xayear}-${xamonth.toString().padStart(2, '0')}-${xaday
                    .toString()
                    .padStart(2, '0')}T${a?.interval.split('-')[0]}:00.000Z`
                );

                const [byear, bmonth, bday] = b?.date.split('-').map(Number);
                const bdate = addDay(
                  b?.interval.split('-')[0],
                  `${bday}-${bmonth}-${byear}`
                );
                const [xbday, xbmonth, xbyear] = bdate.split('-').map(Number);
                const bDate = new Date(
                  `${xbyear}-${xbmonth.toString().padStart(2, '0')}-${xbday
                    .toString()
                    .padStart(2, '0')}T${b?.interval.split('-')[0]}:00.000Z`
                );
                return bDate - aDate;
              })
              .slice(0, 3)
              .map((item, index) => {
                const [year, month, nday] = item.date.split('-').map(Number);
                const date = addDay(
                  item.interval.split('-')[0],
                  `${nday}-${month}-${year}`
                );
                const [xday, xmonth, xyear] = date.split('-').map(Number);
                const nDate = `${xday.toString().padStart(2, '0')}-${xmonth
                  .toString()
                  .padStart(2, '0')}-${xyear}`;
                return (
                  <div key={`${index}_${item.date}`}>
                    <div
                      className="card mb-3 rounded-3"
                      style={{ borderLeft: borderColor(item.type) }}
                    >
                      <div className="card-body p-0">
                        {/* <a className="text-right position-absolute top-0 end-0 m-3 text-t-gri">
                  <FontAwesomeIcon icon={faEllipsis} />
                </a> */}
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center m-2">
                          <strong
                            className="py-1"
                            style={{
                              fontSize: '0.8rem',
                            }}
                          >
                            {item.speakerName}
                          </strong>
                          <div
                            className={`${
                              ButtonType[item.type]
                            } p-1 align-middle text-nowrap text-white fw-bold d-flex justify-content-between rounded mt-2 mt-md-0`}
                            style={{
                              flexWrap: 'wrap',
                            }}
                          >
                            <strong
                              style={{
                                fontSize: '0.7rem',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {getLocationKey(item?.locationId)} {item.type}{' '}
                              {item.trialStatus === 'FreeTrial'
                                ? t('schedule.session_free_trial')
                                : item.trialStatus === 'PaidTrial'
                                ? t('schedule.session_paid_trial')
                                : null}{' '}
                              {item.trialStatus === 'NonTrial' &&
                              item.category === 'OneToOne'
                                ? t('mentor.onetoone')
                                : t('mentor.group')}
                              {item?.category === 'Group' ? (
                                <div
                                  key={`pop_up_card_div_capacity_${index}_${item.date}_${item.speakerId}`}
                                  className="ps-1 text-capitalize"
                                >
                                  {t('onlineGroup.person', {
                                    person: item?.capacity,
                                  })}
                                </div>
                              ) : null}
                            </strong>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mx-1">
                          <div className="flex-shrink-0 pb-1">
                            <img
                              src={item?.speakerImage}
                              width="60rem"
                              className=" img-fluid rounded "
                              alt=" conttentImage"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/images/empty-profile.png';
                              }}
                            />
                          </div>
                          <div
                            className="align-self-center flex-grow-1 p-lg-2 ps-2 ms-lg-3"
                            style={{
                              fontSize: '0.8rem',
                            }}
                          >
                            <div className="text-nowrap text-t-gri px-auto">
                              <FontAwesomeIcon icon={faCalendar} /> {nDate}
                              <br />
                              <FontAwesomeIcon icon={faClock} />{' '}
                              {`${calculateUTC(
                                item?.interval.split('-')[0]
                              )} - ${calculateUTC(
                                item?.interval.split('-')[1]
                              )}`}
                            </div>
                            <div className="text-nowrap">
                              {item?.category === 'Group' ? (
                                <div className="row d-flex justify-content-around align-items-center">
                                  <div className="text-nowrap px-auto">
                                    {t(`${item?.meetingLevel}`)}
                                  </div>
                                  <span className="py-1">
                                    <strong>
                                      <IconDisplay
                                        iconCount={item?.attendeeCount}
                                      />
                                    </strong>
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {item?.isActive &&
                          item?.type !== 'Cafe' &&
                          item?.type !== 'Company' ? (
                            <div className=" flex-grow-0 m-1">
                              <Button
                                key={`${item?.date}-${item?.interval}`}
                                type={
                                  handleJoinSession(item)
                                    ? ButtonType.Secondary
                                    : ButtonType.Info
                                }
                                buttonCol={12}
                                size={ButtonSize.Small}
                                textColor={TextColors.Light}
                                text={t('mySessions.join_session')}
                                disabled={handleJoinSession(item)}
                                onClick={() =>
                                  handleClick(item?.meetingDetails, item)
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          <div className="text-center border rounded-4 p-3">
            <a
              href={findMyPath('schedule')}
              className="text-t-calendar text-decoration-none"
            >
              {t('attendeeDashboard.More_Schedule')}
            </a>
          </div>
        </div>
        {meetingInfo !== '' && (
          <ToastContainer
            style={customToastStyle}
            limit={1}
            autoClose={1800}
            closeOnClick
            theme="light"
          />
        )}
      </div>
    </div>
  );
  const companyContent = (
    <div className="container-fluid my-3">
      <div className="row">
        <HeadTags
          hSize={4}
          text={t('attendeeDashboardCompany.meet2talk_company')}
          strong
        />
      </div>
      <div className="row mt-4">
        <HeadTags
          hSize={6}
          text={t(
            'attendeeDashboardCompany.to_get_information_about_english_training_for'
          )}
          strong
        />
      </div>
      <div className="row mt-5">
        <div className="col-lg-8">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('attendeeDashboardCompany.application_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'firstname'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={createData.firstname || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.firstname}
                      required
                      regexType={RegexType.Name}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'lastName'}
                      inputType={InputType.Text}
                      labelText={t('common.lastName')}
                      inputValue={createData.lastName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.lastName}
                      required
                      regexType={RegexType.Name}
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Email}
                      labelText={t('companyPriceRequest.business_email')}
                      inputValue={createData.email || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.email}
                      required
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'companyName'}
                      inputType={InputType.Text}
                      labelText={t('companyPriceRequest.company_name')}
                      inputValue={createData.companyName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'positionInCompany'}
                      inputType={InputType.Text}
                      labelText={t('companyPriceRequest.position_in_company')}
                      inputValue={createData.positionInCompany || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'phone'}
                      inputType={InputType.Tel}
                      labelText={t('common.phone')}
                      inputValue={formatPhone(createData.phone) || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.phone}
                      required
                      onFocus={onFocus}
                      placeholder={'(5xx) xxx xx xx'}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'peopleRange'}
                      inputType={InputType.Number}
                      labelText={t('companyPriceRequest.number_of_employees ')}
                      inputValue={createData.peopleRange || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <InputLabelOnBorder
                      id={'message'}
                      inputType={InputType.Text}
                      labelText={t('companyPriceRequest.your_message')}
                      inputValue={createData.message || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      textArea
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="d-inline-flex justify-content-center mt-3">
                    {companyError || companyRequestMessage}
                  </div>
                  <div className="d-inline-flex justify-content-center">
                    <Button
                      type={ButtonType.Successbg}
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={4}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
        <div className="col-lg-4">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mt-4 mb-5">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t(
                      'attendeeDashboardCompany.how_does_meet2talk_company_works'
                    )}
                  />
                </div>
                <div className="d-flex">
                  <FontAwesomeIcon
                    className="fa-2xl text-info"
                    icon={faListCheck}
                  />
                  <span className="ms-3">
                    {t('attendeeDashboardCompany.fill_the_form')}
                  </span>
                </div>
                <div className="d-flex mt-5">
                  <FontAwesomeIcon
                    className="fa-2xl text-info"
                    icon={faIdeal}
                  />
                  <span className="ms-3">
                    {t(
                      'attendeeDashboardCompany.let_us_prepare_a_special_offer_for_you'
                    )}
                  </span>
                </div>
                <div className="d-flex mt-5">
                  <FontAwesomeIcon
                    className="fa-2xl text-info"
                    icon={faChartLine}
                  />
                  <span className="ms-3">
                    {t(
                      'attendeeDashboardCompany.see_how_we_work_in_the_trial_session'
                    )}
                  </span>
                </div>
                <div className="d-flex mt-5">
                  <FontAwesomeIcon
                    className="fa-2xl text-info"
                    icon={faGlobe}
                  />
                  <span className="ms-3">
                    {t('attendeeDashboardCompany.be_global')}
                  </span>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
  return userInfo.userArea === undefined ? (
    <Loader />
  ) : !isCafe && !isKids && !isOnline ? (
    companyContent
  ) : (
    content
  );
};
export default AttendeeDashboard;
