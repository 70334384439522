import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  RegexType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPhone } from '../../../hooks/useFormatPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { countryListAllIsoData } from '../../../utils/Enum';
import { getNames } from 'i18n-iso-countries';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Claims from '../../../utils/Claims';
import checkClaims from '../../../hooks/useCheckClaims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const attendeeId = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const lang = useSelector((state) => state.lang.language);
  const isSuccess = useSelector((state) => state.attendee.isSuccess);
  const validationMessageAttendee = useSelector(
    (state) => state.attendee.validationMessage
  );
  const attendeeData = useSelector((state) => state.attendee.attendee);
  const locations = useSelector((state) => state.location.getAll);
  const managerList = useSelector((state) => state.manager.getAll);
  const companyList = useSelector((state) => state.company.getAll);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const staticDatas = useSelector((state) => state.staticData);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const [imgSrc, setImgSrc] = useState('');
  const goBack = () => {
    navigate(-1);
    dispatch(Actions.attendeeActions.cleanState());
  };
  const redirect = '/attendee-list';
  const [handleError, setHandleError] = useState('');
  const [error, setError] = useState({});

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.attendeeActions.cleanState(true));
          setImgSrc('');
          setUpdateData({});
          setHandleError('');
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessageAttendee !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessageAttendee}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessageAttendee !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessageAttendee}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessageAttendee]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (value !== '') {
      setUpdateData({ ...updateData, [id]: value });
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (value) {
      setDataChanged(false);
    }
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...attendeeData });
    } else {
      dispatch(Actions.attendeeActions.holdValue({ ...updateData }));
    }
  };
  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...attendeeData });
    }
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phone);
      setUpdateData({
        ...updateData,
        phone: cleanValue,
      });
    }
  };
  useEffect(() => {
    if (updateData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phone);
      setUpdateData({
        ...updateData,
        phone: cleanValue,
      });
    }
  }, [updateData?.phone, error?.phone]);

  const onSave = async (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length > 0) {
      await dispatch(
        Actions.attendeeActions.updateAction(updateData, attendeeId)
      );
    }
  };

  async function FetchById() {
    await dispatch(Actions.attendeeActions.getByIdAction(attendeeId));
  }

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  useEffect(() => {
    FetchById();
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.managerActions.getAllAction('Dropdown'));
    dispatch(Actions.companyActions.dropdownListAction());
    return () => {
      dispatch(Actions.attendeeActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(attendeeData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      setUpdateData({ ...attendeeData });
    }
  }, [attendeeData]);

  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });
  useEffect(() => {
    setCountryList(countryNames);
  }, []);

  const selectedLanguageLevels = [];

  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const handleDropdown = (propName, value) => {
    setUpdateData({ ...updateData, [propName]: value });
    dispatch(
      Actions.attendeeActions.holdValue({
        ...updateData,
        [propName]: value,
      })
    );
    setDataChanged(false);
  };

  const handleSelectedData = (key, value) => {
    const dataArray = updateData?.[key]?.split(',');

    if (dataArray?.length === undefined) {
      const updatedData = { ...updateData, [key]: value };
      setUpdateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }

    if (dataArray?.includes(value)) {
      const index = dataArray.indexOf(value);
      if (index !== -1) {
        const newDataArray = dataArray.slice();
        newDataArray.splice(index, 1);
        const updatedData = {
          ...updateData,
          [key]: newDataArray.join(','),
        };
        setUpdateData(updatedData);
        if (newDataArray?.length === 0) {
          const newData = { ...updateData };
          delete newData[key];
          setUpdateData(newData);
          dispatch(Actions.attendeeActions.holdValue(newData));
        } else {
          dispatch(Actions.attendeeActions.holdValue(updatedData));
        }
      }
    }

    if (dataArray?.length !== undefined && !updateData[key]?.includes(value)) {
      const updatedData = {
        ...updateData,
        [key]: updateData[key] + ',' + value,
      };
      setUpdateData(updatedData);
      dispatch(Actions.attendeeActions.holdValue(updatedData));
    }

    setDataChanged(false);
  };

  const isUpdateAble =
    Object.values(error).every((e) => e !== undefined) &&
    Object.keys(error).length > 0;

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags hSize={5} strong text={t('attendee.update_form')} />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {updateData.image || imgSrc ? (
                        <img
                          className={'img-fluid rounded me-1'}
                          src={imgSrc ? imgSrc : updateData.image}
                          alt={updateData.image}
                          height={200}
                          style={{ maxHeight: '200px' }}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCircleUser} size="10x" />
                      )}
                    </div>
                    <CustomFileInput
                      id={'image'}
                      key={'image'}
                      hidden={true}
                      buttonText={t('personalInformation.upload_picture')}
                      textColor={TextColors.Light}
                      removeState={imgSrc === '' ? true : false}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      dataFrom={'Profile'}
                      func={(val) => {
                        if (!val) {
                          setUpdateData({});
                          setImgSrc('');
                          setDataChanged(false);
                          const { extension, ...userDataWithoutExtension } =
                            attendeeData;
                          dispatch(
                            Actions.attendeeActions.holdValue({
                              ...userDataWithoutExtension,
                              image: '',
                            })
                          );
                        } else {
                          setUpdateData(
                            Object.assign({}, attendeeData, {
                              image: val.base64String,
                              imgExtension: val.extension,
                            })
                          );
                          setImgSrc(val.imgSrc);
                          setDataChanged(false);
                          dispatch(
                            Actions.attendeeActions.holdValue(
                              Object.assign({}, attendeeData, {
                                image: val.base64String,
                                imgExtension: val.extension,
                              })
                            )
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <InputLabelOnBorder
                          id={'firstName'}
                          inputType={InputType.Text}
                          labelText={t('common.name')}
                          inputValue={updateData.firstName}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.firstName}
                          regexType={RegexType.Name}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputLabelOnBorder
                          id={'lastName'}
                          inputType={InputType.Text}
                          labelText={t('common.lastName')}
                          inputValue={updateData.lastName}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.lastName}
                          regexType={RegexType.Name}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          id={'email'}
                          inputType={InputType.Email}
                          labelText={t('common.email')}
                          inputValue={updateData.email}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('attendee.locations')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: locations.map((item) => ({
                              key: item.locationName,
                              value: item.id,
                              name: 'locations',
                            })),
                            selectedValue: updateData.locationId || '',
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              locationId: val.value,
                            });
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('becomeAMentor.country_code')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: countryList.map((item) => ({
                              key: `${item.name} (${item.phone})`,
                              value: `${item.phone} ${item.isoName}`,
                              name: 'countryCode',
                            })),
                            selectedValue: updateData.countryCode || '',
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              [val.name]: val.value,
                            });
                            dispatch(
                              Actions.attendeeActions.holdValue({
                                ...updateData,
                                [val.name]: val.value,
                              })
                            );
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          id={'phone'}
                          inputType={InputType.Tel}
                          labelText={t('common.phone')}
                          inputValue={formatPhone(updateData.phone)}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.phone}
                          placeholder={'(5xx) xxx xx xx'}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          id={'dateOfBirth'}
                          inputType={InputType.Date}
                          labelText={t('personalInformation.birth_date')}
                          inputValue={
                            moment(updateData.dateOfBirth).format(
                              'yyyy-MM-DD'
                            ) || ''
                          }
                          onChange={onChangeText}
                          onBlur={onBlur}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('becomeAMentor.country')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: countryList.map((item) => ({
                              key: item.name,
                              value: item.name,
                              name: 'country',
                            })),
                            selectedValue: updateData.country || '',
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              [val.name]: val.value,
                            });
                            dispatch(
                              Actions.attendeeActions.holdValue({
                                ...updateData,
                                [val.name]: val.value,
                              })
                            );
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_company')}
                          options={companyList}
                          selectedValues={updateData.companyId}
                          noTranslate={true}
                          selectedProps={{
                            key: 'companyName',
                            value: 'companyId',
                            searchKey: 'companyId',
                          }}
                          onChange={(value) =>
                            handleDropdown('companyId', value)
                          }
                        />
                      </div>
                      <div className="col-6 mt-4">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_manager')}
                          options={managerList}
                          selectedValues={updateData.managerId}
                          noTranslate={true}
                          selectedProps={{
                            key: 'managerName',
                            value: 'managerId',
                            searchKey: 'managerId',
                          }}
                          onChange={(value) =>
                            handleDropdown('managerId', value)
                          }
                        />
                      </div>
                      <div className="col-6 mt-4">
                        <DropdownWithSearch
                          isSearchable
                          onBlur={onBlur}
                          placeHolder={t('dropdownSelect.select_level')}
                          options={selectedLanguageLevels}
                          selectedValues={updateData.languageLevels}
                          selectedProps={{
                            key: 'key',
                            value: 'key',
                            searchKey: 'key',
                            search: 'search',
                          }}
                          onChange={(value) =>
                            handleDropdown('languageLevels', value)
                          }
                        />
                      </div>
                      <div className="col-6 mt-4">
                        <DropdownWithSearch
                          onBlur={onBlur}
                          isMulti
                          placeHolder={t('becomeAMentor.learningPurposes')}
                          options={staticDatas.LearningPurpose}
                          selectedProps={{
                            key: 'key',
                            value: 'value',
                            searchKey: 'value',
                          }}
                          selectedValues={
                            updateData?.learningPurpose !== undefined &&
                            updateData?.learningPurpose !== null
                              ? updateData?.learningPurpose
                              : ''
                          }
                          onChange={(value) =>
                            handleSelectedData('learningPurpose', value)
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        type={
                          dataChanged || isUpdateAble
                            ? ButtonType.Secondary
                            : ButtonType.Successbg
                        }
                        text={t('button.submit')}
                        textColor={TextColors.Light}
                        onClick={onSave}
                        buttonCol={3}
                        disabled={dataChanged || isUpdateAble ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.attendee.update,
    content
  );
};
export default Update;
