import Subscriptions from './SubscriptionPages/Subscriptions';
import AllNotifications from './AttendeePages/AllNotifications';
import Support from './CommonPages/Support';
import ProfileSettings from './UserProfilePages/ProfileSettings';
import Privacy from './CommonPages/Privacy';
import GiftCode from './UserProfilePages/GiftCode';
import PersonalInformation from './UserProfilePages/PersonalInformation';
import PrivatePackage from './SubscriptionPages/PrivatePackage';
import GroupPackage from './SubscriptionPages/GroupPackage';
import MySessionsHistory from './AttendeePages/MySessionsHistory';
import MySessions from './AttendeePages/MySessions';
import Dashboard from './Dashboard/Dashboard';
import MyOrders from './OrderPages/MyOrders';
import Calendar from './AttendeePages/Calendar';
import TermsOFUse from './CommonPages/TermsOFUse';
import Mentors from './MentorPages/Mentors';
import MentorDetails from './MentorPages/MentorDetails';
import MentorDetailCalendar from './MentorPages/MentorDetailCalendar';
import OrderBasket from './OrderPages/OrderBasket';
import Packages from './Packages';
import Buy from './OrderPages/Buy';
import ChangePassword from './UserProfilePages/ChangePassword';
import Schedule from './AttendeePages/Schedule';
import Congratulations from './AttendeePages/Congratulations';
import Result3D from './OrderPages/Payment3DResult';
import Callback from './OrderPages/Callback';
import PaymentPolicy from './CommonPages/PaymentPolicy';
import MembershipPolicy from './CommonPages/MembershipPolicy';
import CookiePolicy from './CommonPages/CookiePolicy';
import Tiktok from './CommonPages/Tiktok';

const Pages = {
  Subscriptions,
  AllNotifications,
  Support,
  ProfileSettings,
  Privacy,
  GiftCode,
  PersonalInformation,
  PrivatePackage,
  GroupPackage,
  MySessionsHistory,
  MySessions,
  Dashboard,
  MyOrders,
  Calendar,
  TermsOFUse,
  Mentors,
  MentorDetails,
  MentorDetailCalendar,
  OrderBasket,
  Packages,
  Buy,
  ChangePassword,
  Schedule,
  Congratulations,
  Result3D,
  Callback,
  PaymentPolicy,
  MembershipPolicy,
  CookiePolicy,
  Tiktok,
};

export default Pages;
