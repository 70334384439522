import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const reportingReducer = (state = initialState.reporting, action) => {
    switch (action.type) {
        case ActionTypes.reporting.GetAll:
            return {
                ...state,
                getAll: action.payload,
            };
        case ActionTypes.reporting.HandleError:
            return {
                ...state,
                isSuccess: action.payload,
            };
        case ActionTypes.reporting.HandleValidation:
            return {
                ...state,
                validationMessage: action.payload,
            };
        default:
            return state;
    }
};

export default reportingReducer;
