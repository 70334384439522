import Actions from '.';
import {
  getById,
  update,
  phoneConfirmation,
  confirmPhoneNumber,
  forgotPassword,
  createEmailToken,
  verifyEmail,
  getAll,
  selfUpdate,
  changePassword,
  getCurrentUserInfos,
  resetPassword,
  updatePreference,
  getCurrencyByIPAddress,
  setDefaultPassword,
} from '../../../services/apiServices/userServices';
import { reponseStatus, responseErrorStatus, storageKeys } from '../../../utils/Enum';
import ActionTypes from './actionTypes';
import config from '../../../config.json';

const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await update(updateData, id).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(selfUpdateReducer(false));
      }
      await dispatch(handleError(res?.data?.isSuccess));
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(updateReducer());
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });;
  };
};
const updateReducer = () => {
  return { type: ActionTypes.user.Update };
};
const selfUpdateAction = (updateData, requestType, fromCalendar = false) => {
  return async (dispatch) => {
    dispatch(selfUpdateReducer(false));
    await selfUpdate(updateData, requestType).then(async (res) => {
      if (fromCalendar) {
        sessionStorage.removeItem(storageKeys.landingRule);
        dispatch(selfUpdateReducer(true));
        dispatch(getByIdReducer([]));
      }
      if (!fromCalendar) {
        dispatch(selfUpdateReducer(''));
      }
    });
  };
};
const selfUpdateReducer = (data) => {
  return { type: ActionTypes.user.SelfUpdate, payload: data };
};
const getAllAction = () => {
  return async (dispatch) => {
    await getAll().then(async (res) => {
      let response = res.data.data;
      await dispatch(getAllReducer(response));
    });
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.user.GetAll, payload: { data } };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await dispatch(handleError(''));
    await dispatch(handleValidationReducer(''));
    await getCurrentUserInfos(id).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getByIdReducer(response));
      await dispatch(
        Actions.preferenceActions.setCurrencyAction(response?.currency, true)
      );
      dispatch(selfUpdateReducer(''));
    });
  };
};
const getByIdReducer = (data) => {
  return { type: ActionTypes.user.GetById, payload: { data } };
};

const forgotPasswordAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await forgotPassword(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const resetPasswordAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await resetPassword(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const createEmailTokenAction = (data) => {
  return async () => {
    await createEmailToken(data);
  };
};
const verifyEmailTokenAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await verifyEmail(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const changePasswordAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await changePassword(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const phoneConfirmationAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await phoneConfirmation(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const confirmPhoneNumberAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await confirmPhoneNumber(updateData, id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const updatePreferenceAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await updatePreference(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const getCurrenyByIPAddressAction = () => {
  return async (dispatch) => {
    await getCurrencyByIPAddress().then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(
          Actions.preferenceActions.setCurrencyAction(res?.data?.data, true)
        );
      }
    });
  };
};

const setDefaultPasswordAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await setDefaultPassword(id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};

const handleError = (data) => {
  return {
    type: ActionTypes.user.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.user.HandleValidation,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
      await dispatch(updateReducer());
    }
  };
};

const userActions = {
  updateAction,
  getByIdAction,
  getAllAction,
  phoneConfirmationAction,
  confirmPhoneNumberAction,
  forgotPasswordAction,
  createEmailTokenAction,
  verifyEmailTokenAction,
  selfUpdateAction,
  changePasswordAction,
  resetPasswordAction,
  updatePreferenceAction,
  getCurrenyByIPAddressAction,
  setDefaultPasswordAction,
  cleanState,
};
export default userActions;
