import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/Common/Loader/Loader';
import Actions from '../../../store/redux/actions';
import Modal from '../../../components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import findMyPath from '../../../hooks/useFindMyPath';
import useGTM from '../../../hooks/useGTM';

const Callback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSuccess = useSelector((state) => state.payment.isSuccess);
  const { sendEventToDataLayer } = useGTM();
  const [data, setData] = useState({});
  const [waiting, setWaiting] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleCloseModals = () => {
    if (showSuccessModal) {
      setShowSuccessModal(false);
      navigate(findMyPath('orders'));
    }
    if (showErrorModal) {
      setShowErrorModal(false);
      navigate(findMyPath('order-basket'));
    }
    dispatch(Actions.paymentActions.resetStateAction());
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const params = currentUrl.split('?')[1];
    const paymentInt = params.split('paymentInt=')[1].split('_')[0];
    const status = params.split('status=')[1].split('_')[0];
    const orderId = params.split('orderId=')[1];
    setData({ paymentInt: paymentInt, status: status, orderId: orderId });
  }, []);
  useEffect(() => {
    if (Object.keys(data).length > 2) {
      dispatch(Actions.paymentActions.paymentCallbackAction(data));
    }
  }, [data]);
  useEffect(() => {
    if (isSuccess && Object.keys(data).length !== 0) {
      setWaiting(false);
      setShowSuccessModal(true);
      sendEventToDataLayer({
        event: 'paymentType',
        paymentType: 'Credit Card',
      });
    } else if (!isSuccess && Object.keys(data).length !== 0) {
      setWaiting(false);
      setShowErrorModal(true);
    }
  }, [isSuccess]);

  const content = (
    <React.Fragment>
      <Modal
        isVisible={showSuccessModal}
        styleProps={{ maxWidth: 550 }}
        title={t('buy.credit_card')}
        onClose={handleCloseModals}
      />
      <Modal
        isVisible={showErrorModal}
        styleProps={{ maxWidth: 550 }}
        title={t('buy.an_error_occured')}
        onClose={handleCloseModals}
      />
    </React.Fragment>
  );
  return waiting ? <Loader /> : content;
};

export default Callback;
