import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import Button from '../../../../components/Common/Button/Button';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import { ButtonType, TextColors } from '../../../../utils/ComponentEnums';
import { Entities } from '../../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ageIntervalList = useSelector((state) => state.ageInterval.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();

  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.ageInterval.getAll)
    ) {
      await dispatch(Actions.ageIntervalActions.getAllAction());
    }
  };

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'minAge', label: t('ageInterval.minimum_age') },
    { key: 'maxAge', label: t('ageInterval.maximum_age') },
    { key: 'ageSessionValue', label: t('ageInterval.session_value') },
    { key: 'ageMentorValue', label: t('ageInterval.mentor_value') },
    { key: 'userArea', label: t('ageInterval.user_area') },
  ];
  const editAction = (val) => {
    navigate('/age-interval-update', { state: { id: val.id } });
  };
  const removeAction = async (id) => {
    await dispatch(Actions.ageIntervalActions.removeAction(id));
    getList();
  };

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags hSize={1} text={t('ageInterval.age_interval_list')} strong />
      </div>
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.ageInterval.create
      ) ? (
        <div className="row mb-3">
          <div className="d-flex justify-content-end">
            <Button
              type={ButtonType.Successbg}
              text={t('ageInterval.add_new_age_interval')}
              textColor={TextColors.Light}
              buttonCol={2}
              onClick={() => navigate('/age-interval-create')}
            />
          </div>
        </div>
      ) : undefined}
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={ageIntervalList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.ageInterval}
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.ageInterval.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.ageInterval.getAll,
    content
  );
};
export default List;
