import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonIcon,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Modal from '../../../components/Common/Modal/Modal';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import Table from '../../../components/Common/Table/Table';
import BasicTable from '../../../components/Common/Table/BasicTable';
import Schedule from '../../UI/AttendeePages/Schedule';
import MyOrders from '../../UI/OrderPages/MyOrders';
import ScheduleManager from './ScheduleManager';
import GiftOrder from '../Order/CreateGiftOrder';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

export default function OperationManagment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const goBack = () => navigate(-1);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const attendeeList = useSelector((state) => state.attendee.filteredAttendee);
  const [isOpen, setIsOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [selectedAttendee, setSelectedAttendee] = useState(null);
  const [index, setIndex] = useState(null);
  const canGiftOrder = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.createGift
  );

  useEffect(() => {
    if (params.size > 0) {
      const name = params.get('name');
      const email = params.get('email');
      const phone = params.get('phone');
      const id = parseInt(params.get('id'));

      if (id && email) {
        setSelectedAttendee({
          id,
          name,
          email,
          phone,
        });
      }
    }
  }, [location.search]);

  const [scheduleData, setScheduleData] = useState({});
  const modalTable = [
    { key: 'id', label: t('common.attendeeId') },
    { key: 'name', label: t('order.attendee_name') },
    { key: 'email', label: t('order.attendee_email_address') },
    { key: 'phone', label: t('order.attendee_phone_number') },
  ];
  const headers = [
    { key: 'id', label: t('common.attendeeId') },
    { key: 'name', label: t('order.attendee_name') },
    { key: 'email', label: t('order.attendee_email_address') },
    { key: 'phone', label: t('order.attendee_phone_number') },
    { key: '', label: '' },
  ];
  useEffect(() => {
    const onKeyPress = (e) => {
      if (isOpen && e.key === 'Enter') {
        dispatch(Actions.attendeeActions.searchAttendeeAction(filterData));
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };
  let htmlContent = null;
  switch (index) {
    case 0:
    case 1:
      htmlContent = (
        <Schedule
          attendeeId={selectedAttendee?.id}
          getMeIndex={(val) => {
            setScheduleData(val);
            setIndex(val?.index);
          }}
        />
      );
      break;
    case 2:
      htmlContent = (
        <MyOrders
          attendeeId={selectedAttendee?.id}
          setDate={true}
          attendeeName={selectedAttendee?.name}
          getMeIndex={(val) => {
            setScheduleData(val);
            setIndex(val?.index);
          }}
        />
      );
      break;
    case 3:
      htmlContent = (
        <MyOrders
          attendeeId={selectedAttendee?.id}
          setDate={false}
          attendeeName={selectedAttendee?.name}
        />
      );
      break;
    case 5:
      htmlContent = (
        <ScheduleManager
          getMeIndex={scheduleData?.state}
          attendeeId={selectedAttendee?.id}
          navigateMe={(val) => setIndex(val)}
        />
      );
      break;
    case 6:
      htmlContent = (
        <GiftOrder
          navigateMe={(val) => setIndex(val)}
          attendee={selectedAttendee}
        />
      );
      break;
    default:
      htmlContent = undefined;
      break;
  }

  const handleClose = (unmounting = false) => {
    dispatch(Actions.calendarActions.cleanState());
    dispatch(Actions.attendeeActions.cleanState());
    setSelectedAttendee(null);
    setIsOpen(false);
    setIndex(null);
    setFilterData({});
    if (!unmounting) {
      navigate('/operations');
    }
  };

  useEffect(() => {
    return () => {
      handleClose(true);
    };
  }, []);

  const content = (
    <React.Fragment>
      {selectedAttendee === null && (
        <div className="d-flex justify-content-center">
          <div className="col-md-4 col-sm-12 mt-2">
            <Modal
              isVisible={isOpen}
              styleProps={{ maxWidth: 850, minWidth: 300 }}
              content={
                <div className="d-flex justify-content-center">
                  <div className="col-lg-11 col-md-12">
                    <Card
                      body={
                        <div className="">
                          <div className="d-flex flex-wrap align-items-center justify-content-start mb-4 form-div-border">
                            <HeadTags
                              hSize={6}
                              strong
                              text={t('cardHeaderFilter.filter')}
                            />
                          </div>
                          <div className="row my-2 d-flex flex-wrap align-items-center justify-content-center">
                            <div className="col-lg-8 col-md-12">
                              <InputLabelOnBorder
                                key={'name_check'}
                                id={'name'}
                                labelText={t(
                                  'order.attendee_search_name_email_adress_or_phone_number'
                                )}
                                inputType={InputType.Text}
                                inputValue={filterData.name || ''}
                                onChange={onChangeText}
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                              <Button
                                text={t('cardHeaderFilter.search')}
                                icon={faSearch}
                                type={ButtonType.Successbg}
                                iconColor={TextColors.Light}
                                textColor={TextColors.Light}
                                iconSide
                                bold
                                buttonCol={12}
                                onClick={() =>
                                  Object.keys(filterData).length !== 0
                                    ? dispatch(
                                        Actions.attendeeActions.searchAttendeeAction(
                                          filterData
                                        )
                                      )
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                          <Table
                            data={attendeeList}
                            headers={modalTable}
                            striped
                            bordered
                            selectAll
                            selectButton={(value) => {
                              setSelectedAttendee(value);
                              setIsOpen(false);
                            }}
                            page={'order'}
                            currentUserClaim={currentUserClaim}
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              }
              footer={
                <Button
                  type={ButtonType.Successbg}
                  bold
                  buttonCol={2}
                  textColor={TextColors.Light}
                  text={t('order.button_close')}
                  onClick={() => handleClose()}
                />
              }
              onClose={() => handleClose()}
            />
            <Button
              icon={faSearch}
              textColor={TextColors.Light}
              iconColor={TextColors.Light}
              buttonCol={12}
              text={t('order.button_search_attendee')}
              type={ButtonType.Successbg}
              onClick={() => setIsOpen(true)}
            />
          </div>
        </div>
      )}
      {selectedAttendee && params.size === 0 && (
        <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
          <div className="col-2 ms-3">
            <Button
              type={ButtonType.Successbg}
              icon={ButtonIcon.ArrowLeft}
              iconColor={TextColors.Light}
              bold
              onClick={goBack}
              buttonCol={4}
            />
          </div>
        </div>
      )}
      {selectedAttendee && (
        <div className="d-flex justify-content-center col-md-6 col-sm-12 mt-3 offset-md-3">
          <BasicTable
            key={'selected_attendee_table'}
            data={selectedAttendee === null ? [] : [selectedAttendee]}
            headers={headers}
            setArrows
            labelFunc={() => {
              handleClose();
            }}
            labelProp={''}
            oncalendar={true}
            id={true}
          />
        </div>
      )}
      {selectedAttendee && (
        <div className="d-flex justify-content-center">
          <HeadTags
            hSize={6}
            strong
            text={t('operations.selected_attendee', {
              attendee: selectedAttendee?.name,
            })}
          />
        </div>
      )}
      {selectedAttendee && (
        <div className="d-flex justify-content-evenly flex-wrap mt-3 mb-2 responsive-flex">
          <Button
            textColor={TextColors.Light}
            type={ButtonType.Secondary}
            text={t('operations.recompense')}
            onClick={() => setIndex(0)}
          />
          <Button
            textColor={TextColors.Light}
            type={ButtonType.Info}
            text={t('operations.redate')}
            onClick={() => setIndex(1)}
          />
          <Button
            textColor={TextColors.Light}
            type={ButtonType.Successbg}
            text={t('operations.setdate')}
            onClick={() => setIndex(2)}
          />
          <Button
            textColor={TextColors.Light}
            type={ButtonType.Danger}
            text={t('operations.unsetdate')}
            onClick={() => setIndex(3)}
          />
          {canGiftOrder && (
            <Button
              textColor={TextColors.Light}
              type={ButtonType.LandingGreenBg}
              text={t('operations.giftOrder')}
              onClick={() => setIndex(6)}
            />
          )}
        </div>
      )}
      {htmlContent}
    </React.Fragment>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.attendee.get,
    content
  );
}
