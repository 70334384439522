import { postRequest, putRequest, getRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';

export const update = async (updateData, id) => {
  return await putRequest(`${endPoint.user.Update}/${id}`, updateData);
};
export const selfUpdate = async (updateData, requestType) => {
  let URL;
  switch (requestType) {
    case 'UserArea':
      URL = endPoint.user.UpdateUserArea;
      break;
    case 'Currency':
      URL = endPoint.user.UpdateLanguage;
      break;
    default:
      URL = endPoint.user.UpdateLanguage;
      break;
  }
  return await putRequest(`${URL}`, updateData);
};
export const getById = async (id) => {
  return await getRequest(`${endPoint.user.GetById}/${id}`);
};
export const getAll = async () => {
  return await getRequest(endPoint.user.GetAll);
};
export const phoneConfirmation = async (data) => {
  return await postRequest(endPoint.user.PhoneConfirmation, data);
};
export const confirmPhoneNumber = async (data) => {
  return await postRequest(`${endPoint.user.ConfirmPhoneNumber}`, data);
};
export const forgotPassword = async (data) => {
  return await postRequest(endPoint.user.ForgotPassword, data);
};
export const resetPassword = async (data) => {
  return await postRequest(endPoint.user.ResetPassword, data);
};
export const createEmailToken = async (data) => {
  return await postRequest(`${endPoint.user.CreateEmailToken}`, data);
};
export const verifyEmail = async (token) => {
  return await postRequest(`${endPoint.user.VerifyEmail}`, JSON.stringify(token));
};
export const changePassword = async (data) => {
  return await postRequest(`${endPoint.user.ChangePassword}`, data);
};
export const getCurrentUserInfos = async (id) => {
  return await getRequest(`${endPoint.user.CurrentUserInfos}/${id}`);
};
export const updatePreference = async (data) => {
  return await postRequest(`${endPoint.user.UpdatePreference}`, data);
};
export const getCurrencyByIPAddress = async () => {
  return await getRequest(`${endPoint.user.GetCurrencyByIPAdress}`);
};
export const setDefaultPassword = async (id) => {
  return await postRequest(`${endPoint.user.SetDefaultPassword}/${id}`);
};