import React, { useEffect, useRef, useState } from 'react';
import styles from '../../../assets/Styles.module.scss';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import Button from '../Button/Button';
import { ButtonIcon, TextColors } from '../../../utils/ComponentEnums';
import { useSelector } from 'react-redux';

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const ModalWithSearch = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  selectedProps,
  myValue,
  onBlur,
  onFocus,
  resetState,
  selectedValues,
  isCalendarLabel,
  setStateFromState,
  styleProps,
  searchWithChild,
  searchFromParent,
}) => {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const calendarLoading = useSelector((state) => state.calendar.showLoading);
  const closePopup = () => {
    setShowMenu(false);
  };
  const [selectedValue, setSelectedValue] = useState(
    setStateFromState?.SpeakerId !== undefined
      ? [setStateFromState]
      : isMulti
      ? []
      : null
  );
  const [searchValue, setSearchValue] = useState(searchFromParent ?? '');
  const searchRef = useRef();
  const inputRef = useRef();
  function filterSelectedValues(data, selectedValues) {
    const selectedDatas = selectedValues?.split(',');
    const filteredObjects = data?.filter((obj) =>
      selectedDatas?.includes(obj?.value)
    );
    return filteredObjects;
  }
  useEffect(() => {
    if (selectedValues) {
      setSelectedValue(filterSelectedValues(options, selectedValues));
    }
    if (selectedValue?.length > 0) {
      getDisplay();
    }
  }, [selectedValues]);

  useEffect(() => {
    if (resetState === true) {
      setSelectedValue(isMulti ? [] : null);
    }
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu, resetState, selectedValues]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });
  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return myValue ? myValue : placeHolder;
    }

    if (isMulti) {
      const maxDisplayedImages = 2;

      const selectedItems = selectedValue.slice(0, maxDisplayedImages);
      const remainingCount = Math.max(
        0,
        selectedValue.length - maxDisplayedImages
      );

      return (
        <div className={styles.dropdown_tags}>
          {selectedItems.map((option) => (
            <span
              key={option[selectedProps.value]}
              className={styles.dropdown_tag_item}
            >
              <img
                alt={option.SpeakerImage}
                className={styles.selected_mentor_image}
                src={option.SpeakerImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/empty-profile.png';
                }}
              />
              <span
                onClick={(e) => onTagRemove(e, option)}
                className={styles.dropdown_tag_close}
              >
                <CloseIcon />
              </span>
            </span>
          ))}
          {remainingCount > 0 && <span>+{remainingCount}</span>}
        </div>
      );
    }

    return selectedProps.supKey !== undefined
      ? selectedValue[selectedProps.key] +
          ' x ' +
          selectedValue[selectedProps.supKey]
      : selectedValue[selectedProps.key];
  };

  const removeOption = (option) => {
    return selectedValue.filter(
      (o) => o[selectedProps.value] !== option[selectedProps.value]
    );
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(option[selectedProps.value]);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (
        selectedValue.findIndex(
          (o) => o[selectedProps.value] === option[selectedProps.value]
        ) >= 0
      ) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(option[selectedProps.value]);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return (
        selectedValue.filter(
          (o) => o[selectedProps.value] === option[selectedProps.value]
        ).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }
    return selectedValue[selectedProps.value] === option[selectedProps.value];
  };

  const onKeyPress = (e) => {
    const { value } = e.target;
    if (e.key === 'Enter') {
      if (value !== '') {
        setSearchValue(value);
        searchWithChild(value);
      }
    }
  };
  const onSearch = (e) => {
    const { value } = e.target;
    if (value === '') {
      setSearchValue(null);
      searchWithChild(null);
    } else {
      setSearchValue(value);
    }
  };
  const onSearchClick = (e) => {
    const inputValue = document?.getElementById('FullName')?.value;
    if (inputValue !== '') {
      setSearchValue(inputValue);
      searchWithChild(inputValue);
    }
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option[selectedProps.key]
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div>
      <button
        ref={inputRef}
        onClick={handleInputClick}
        className={`d-inline-flex justify-content-center align-items-center text-nowrap border border-1 border-success bg-lightcustom btn btn-sm btn-default rounded-pill py-1 px-2`}
        style={{ maxHeight: '50px', overflowY: 'auto' }}
      >
        {getDisplay()?.props ||
        getDisplay()?.includes(t('dropdownWithSearch.select')) ? undefined : (
          <label className="">
            {!isCalendarLabel && (
              <span className="h6 small bg-white text-muted px-1">
                {placeHolder.replace(/\.\.\./g, '')}
              </span>
            )}
          </label>
        )}
        <div className="d-flex">{getDisplay()}</div>
      </button>
      {showMenu && (
        <div className={calendarLoading ? 'spinnerbg' : styles.modal}>
          <div
            className={styles.modal_dialog}
            style={{
              maxWidth: `${styleProps?.maxWidth}px`,
              maxHeight: '600px',
              overflowY: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.modal_header}>
              {placeHolder && (
                <>
                  <h4>
                    <strong>{placeHolder}</strong>
                  </h4>
                </>
              )}
              <button
                className="btn-close text-right position-absolute top-0 end-0 m-3"
                onClick={() => closePopup()}
              ></button>
            </div>

            <div className={styles.modal_body}>
              <div className={styles.modal_content}>
                {isSearchable && (
                  <div
                    className={`${styles.search_box} d-flex justify-content-evenly `}
                  >
                    <input
                      id="Fullname"
                      value={searchValue}
                      onChange={onSearch}
                      ref={searchRef}
                      placeholder={t('attendeeDashboard.modal_search_mentors')}
                      style={{ borderRadius: '5px' }}
                      className="p-1 col-11"
                      onKeyPress={onKeyPress}
                    />
                    <Button
                      icon={ButtonIcon.Search}
                      borderless
                      iconColor={TextColors.Success}
                      onClick={onSearchClick}
                      buttonCol={1}
                    />
                  </div>
                )}
                {getOptions().map((option, index) => (
                  <Card
                    key={index}
                    padding={0}
                    cardRounded
                    outerBackgroud={`border-2 ${
                      isSelected(option) ? ' border-success border-3' : ''
                    } `}
                    body={
                      <div
                        key={index}
                        role="button"
                        className=" d-flex justify-content-start align-items-center "
                        onClick={(e) => {
                          if (isMulti) {
                            e.stopPropagation();
                          }
                          onItemClick(option);
                        }}
                      >
                        <img
                          key={`pop_upcard_image_${index}`}
                          alt={option.SpeakerImage}
                          className="rounded-4  m-2 img   "
                          src={option.SpeakerImage}
                          width={75}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/images/empty-profile.png';
                          }}
                        />
                        <div
                          key={`${option[selectedProps.value]}_${
                            option[selectedProps.key]
                          }`}
                          className={` ${
                            isSelected(option) ? styles.selected : ''
                          }`}
                        >
                          <h6>
                            {option[selectedProps.supKey] !== undefined
                              ? option[selectedProps.key] +
                                ' x ' +
                                option[selectedProps.supKey]
                              : option[selectedProps.key]}
                          </h6>
                          <p className="p-0 m-0">{option.SpeakerTitle}</p>
                        </div>
                      </div>
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalWithSearch;
