import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import { formatPhone } from '../../../hooks/useFormatPhone';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { SelectWithSearch } from '../../../components/Common/Input/SelectWithSearch';

const Create = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.user);
  const staticDatas = useSelector((state) => state.staticData);
  const dispatch = useDispatch();
  const [createData, setCreateData] = useState(user);
  const [changeAsked, setChangeAsked] = useState(false);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
  };
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.userActions.createAction(createData));
  };

  async function GetLists() {
    // let requests = [5, 6, 7];
    // requests.forEach(async (element) => {
    //   await dispatch(Actions.staticDataActions.getFilteredData(element));
    // });
  }
  useEffect(() => {
    GetLists();
  }, []);
  const choices = [
    'Istanbul, Turkey',
    'Ankara, Turkey',
    'London, United Kingdom',
    'Tokyo, Japan',
  ];
  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags hSize={5} strong text={'Add New User Form'} />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={'Name'}
                      inputValue={createData.name || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'lastName'}
                      inputType={InputType.Text}
                      labelText={'Surname'}
                      inputValue={createData.lastName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Email}
                      labelText={'Professional Emaill Address'}
                      inputValue={createData.email || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <CustomFileInput
                      id={'userImage'}
                      hidden={true}
                      buttonText={t('fileUpload.upload_picture')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      func={(val) => {
                        setCreateData(
                          Object.assign({}, createData, { userImage: val })
                        );
                        dispatch(
                          Actions.speakerActions.holdValue(
                            Object.assign({}, createData, { userImage: val })
                          )
                        );
                      }}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'phone'}
                      inputType={InputType.Tel}
                      labelText={'Phone Number'}
                      inputValue={formatPhone(createData.phone) || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'password'}
                      inputType={InputType.Text}
                      labelText={'Password'}
                      inputValue={createData.password || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'dateOfBirth'}
                      inputType={InputType.Date}
                      labelText={'Birth Date'}
                      inputValue={createData.dateOfBirth || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    {changeAsked ? (
                      <SelectWithSearch
                        values={choices}
                        selectedValue
                        labelText={'Location'}
                        handleChange={(val) => {
                          setCreateData({ ...createData, country: val });
                          setChangeAsked(false);
                        }}
                      />
                    ) : (
                      <InputLabelOnBorder
                        id={'country'}
                        inputType={InputType.Text}
                        labelText={'Location'}
                        select={{
                          selectStyle: 'selectStyle',
                          option: [],
                          selectedValue: createData.country || '',
                        }}
                        handleSelectChange={(val) => {
                          setChangeAsked(true);
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(createData).length > 7
                          ? ButtonType.Successbg
                          : ButtonType.Secondary
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onCreate}
                      buttonCol={2}
                      disabled={
                        Object.keys(createData).length > 7 ? false : true
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Create;
