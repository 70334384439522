import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../Card/Card';
import Button from '../Button/Button';
import { ButtonType, ButtonSize } from '../../../utils/ComponentEnums';
import BasicPopUp from './BasicPopUp';

import useClickOutside from '../../../hooks/useClickOutside';
import Actions from '../../../store/redux/actions';
import { faOtter } from '@fortawesome/free-solid-svg-icons';

const Popup = ({
  isOpen,
  onClose,
  cardArray,
  type,
  position,
  addButton,
  fromNavigateWhere,
  fromRecompense,
  content,
}) => {
  const dispatch = useDispatch();
  const userPackageData = useSelector((state) => state.userPackage.packageData);
  const [createData, setCreateData] = useState(userPackageData);
  const [recompense, setRecompense] = useState({});
  const userPackage = useSelector((state) => state.userPackage.packageData);
  const [packageFilter, setPackageFilter] = useState(userPackage);
  const [timesPackage, setTimesPackage] = useState({});
  const { t } = useTranslation();
  //const popupRef = useRef(null);
  const popupRef = useClickOutside(() => {
    if (isOpen === true) {
      onClose();
    }
  });
  const auth = useSelector((state) => state.auth.auth);

  let btnText = '';

  //  this basic handler is used to open a popup dialog and get the permission of the user
  const [isBasicOpen, setIsBasicOpen] = useState(false);
  const handleClose = () => {
    setIsBasicOpen(false);
  };
  const navigate = useNavigate();
  const navigateTo = async () => {
    await dispatch(Actions.meetingAttendeeActions.recompenseAction(recompense));
    navigate('/calendar', {
      state: {
        fromNavigateWhere: 'schedule',
        freeSession: true,
      },
    });
  };

  switch (type) {
    case 'schedule':
      btnText = t('button.recompense');
      break;

    case 'calendar':
      btnText = t('button.select');
      break;

    default:
      break;
  }

  const onRecompense = (card) => {
    card.ContentIsActive === false
      ? navigate('/calendar', {
          state: {
            whichone: card.ContentCategory === 'Group' ? 1 : 2,
          },
        })
      : setRecompense({
          redatedMeetingAttendeeId: card.ContentId,
          orderItemId: card.ContentOrderId,
          redateFrom: card.date,
        });
  };

  const onSetdate = (card) => {
    const updateData = {
      speakerId: card.speakerid,
      meetingDate: card.date,
      orderItemId: 14,
    };
    dispatch(Actions.meetingAttendeeActions.setDateAction(updateData));
  };

  const onSelectMentor = (card) => {
    const timesPerWeek = packageFilter.options.TimesPerWeek;
    const selectedMentors = createData.orderItemDetails?.SpeakerId || [];

    // Check if the maximum number of selections has been reached
    if (selectedMentors.length >= timesPerWeek) {
      return; // Prevent further selections if the limit is reached
    }

    const updatedData = {
      ...createData,
      orderItemDetails: {
        ...(createData.orderItemDetails || {}),
        MeetingSchemaId: [
          ...(createData.orderItemDetails?.MeetingSchemaId || []),
          card.ContentId,
        ],
        SelectedDate: [
          ...(createData.orderItemDetails?.SelectedDate || []),
          card.date,
        ],
        SpeakerId: [...selectedMentors, card.speakerid],
      },
      UserArea: 0,
    };

    dispatch(Actions.userPackageActions.updateAction(updatedData));
  };

  return (
    <div
      ref={popupRef}
      className={
        'col-md-4 p-0 m-0   mx-auto ' + (isOpen ? 'd-flex' : ' d-none')
      }
      style={{
        zIndex: 11,
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        maxWidth: '550px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="modal-dialog col border-0 rounded-4 bg-white shadow-lg container">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close text-right position-absolute top-0 end-0 m-3"
            onClick={onClose}
          ></button>
          <div className="mt-5 mb-2 mx-3 modal-header ">
            <h6 className="modal-title">
              <strong style={{ fontSize: '0.9em' }}>{cardArray.title}</strong>
            </h6>
            {addButton ? (
              <h6 className="modal-title" style={{ fontSize: '0.9em' }}>
                {`${cardArray.Contents.length} Sessions`}
              </h6>
            ) : undefined}
          </div>
          <div className="modal-body p-0 m-0">
            {content ? (
              <div className="mx-2 col-sm container-fluid">{content}</div>
            ) : (
              cardArray?.Contents?.map((card, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  {type === 'calendar' ? (
                    <img
                      key={`pop_upcard_image_${index}`}
                      alt={card.CardImage}
                      className="rounded-4 mx-2 img-fluid col-sm-3 "
                      src={card.CardImage}
                    />
                  ) : undefined}

                  <div
                    key={`pop_up1_${index}`}
                    className="mx-2 col-sm container-fluid"
                  >
                    <Card
                      key={`pop_up_card_${index}`}
                      cardTitle={{
                        title: (
                          <strong style={{ fontSize: '0.9em' }}>
                            {card.ContentTitle}
                          </strong>
                        ),
                      }}
                      shadow={true}
                      scheduleBorder={card.ContentType}
                      cardRounded={false}
                      body={
                        <div>
                          <div className="row d-flex justify-content-around align-items-center">
                            <div
                              className="col-4 text-muted"
                              style={{ fontSize: '0.7em' }}
                            >
                              {card?.ContentText?.text1}
                            </div>
                            <div
                              className={`${
                                addButton ? 'col-5' : 'col-8'
                              } text-muted`}
                              style={{ fontSize: '0.7em' }}
                            >
                              {card?.ContentText?.text2}
                            </div>
                            {addButton && type === 'schedule' ? (
                              <div className="col-3 p-0 m-0">
                                <Button
                                  type={ButtonType.Light}
                                  size={ButtonSize.Small}
                                  onClick={() => {
                                    onRecompense(card);
                                    setIsBasicOpen(
                                      card.ContentIsActive === false
                                        ? false
                                        : true
                                    );
                                  }}
                                  text={
                                    <strong style={{ fontSize: '0.7em' }}>
                                      {card.ContentIsActive === false
                                        ? t('button.recompensed')
                                        : btnText}
                                    </strong>
                                  }
                                ></Button>
                              </div>
                            ) : type === 'calendar' ? (
                              <div className="col-3">
                                {' '}
                                <Button
                                  type={ButtonType.Light}
                                  size={ButtonSize.Small}
                                  onClick={() =>
                                    fromRecompense
                                      ? onSetdate(card)
                                      : onSelectMentor(card)
                                  }
                                  text={
                                    <strong style={{ fontSize: '0.7em' }}>
                                      {fromNavigateWhere === 'schedule'
                                        ? t('button.change_to_date')
                                        : btnText}
                                    </strong>
                                  }
                                ></Button>{' '}
                              </div>
                            ) : undefined}
                          </div>
                          <div className="row">
                            <div className="col-4">
                              {card.Images ? (
                                card.Images.slice(0, 3).map((cardImage) => (
                                  <img
                                    className="me-1 img-fluid rounded-pill"
                                    src={cardImage.image}
                                    key={`pop_upcard_image_${cardImage.id}_${card.Images.length}_${index}`}
                                    alt=""
                                    width="18rem"
                                  />
                                ))
                              ) : (
                                <img
                                  className=" img-fluid rounded "
                                  src={card?.ContentImage}
                                  alt=""
                                  width="50rem"
                                />
                              )}
                              {card.ImageCount > 3 ? (
                                <label
                                  style={{ fontSize: '0.8em' }}
                                  className="border border-light rounded-circle"
                                >
                                  + {card.ImageCount - 3}
                                </label>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className={`${
                                addButton ? 'col-5' : 'col-8'
                              } text-muted`}
                              style={{ fontSize: '0.7em' }}
                            >
                              {addButton && card.ContentType === 'group'
                                ? card?.ContentText?.text3 -
                                  card.Images.length +
                                  ' Yer kaldı'
                                : card.ContentType === 'one-to-one'
                                ? ' one-to-one'
                                : card?.ContentText?.text3}
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              ))
            )}
          </div>
          <BasicPopUp
            isOpen={isBasicOpen}
            onClose={handleClose}
            position={{ top: 50, left: 100 }}
            text={'Bu Oturumu Askıya almak istediğinizden Emin misiniz?'}
            button={{ text: 'Yes' }}
            buttonFunc={navigateTo}
            buttonType={ButtonType.Info}
          />
        </div>
      </div>
    </div>
  );
};
export default Popup;
