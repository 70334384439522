import React, { useState } from 'react';
import Card from '../../../components/Common/Card/Card';
import { useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserGroup,
  faCalendar,
  faBox,
  faMoneyBills,
  faBasketShopping,
  faCalendarDays,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Actions from '../../../store/redux/actions';
import findMyPath from '../../../hooks/useFindMyPath';
import useGTM from '../../../hooks/useGTM';
import { checkLogged } from '../../../hooks/useCheckLogged';

export default function Congratulations() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userInfo = useSelector((state) => state.user.getById);
  const { sendEventToDataLayer } = useGTM();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const isFreeTrial = location?.state?.isFreeTrial;
  const isBuying = location?.state?.isBuying;
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(Actions.orderActions.getBasketItemsReducer([], true));
    sendEventToDataLayer({
      event: 'DD Success',
      platfrom: isMobile ? 'Mobile' : 'Desktop',
      status: 'Success',
    });
  }, []);

  const generateICSContent = (event) => {
    const { title, description, start, end } = event;
    return `BEGIN:VCALENDAR\n
PRODID:-//Meet2Talk//TR\n
VERSION:2.0\n
CALSCALE:GREGORIAN\n
METHOD:PUBLISH\n
X-WR-CALNAME:${user.email}\n
X-WR-TIMEZONE:Europe/Istanbul\n
BEGIN:VEVENT\n
SUMMARY:${title}\n
DESCRIPTION:${description}\n
LOCATION:\n
DTSTART:${start}\n
DTEND:${end}\n
SEQUENCE:1\n
TRANSP:OPAQUE\n
BEGIN:VALARM\n
ACTION:DISPLAY\n
DESCRIPTION:This is an event reminder\n
TRIGGER:-P0DT0H5M0S\n
END:VALARM\n
BEGIN:VALARM\n
ACTION:DISPLAY\n
DESCRIPTION:This is an event reminder\n
TRIGGER:-P0DT3H15M0S\n
END:VALARM\n
END:VEVENT\n
END:VCALENDAR`;
  };
  const addToCalendarAction = (data) => {
    const eventDate = new Date(data.orderItemDetails.SelectedDate[0]);

    const startDateString = eventDate.toISOString().slice(0, -1) + 'Z'; // Add 'Z' manually

    const endDate = new Date(startDateString);
    endDate.setMinutes(endDate.getMinutes() + 30);

    const endDateString = endDate.toISOString().slice(0, -1) + 'Z'; // Add 'Z' manually

    const eventDetails = {
      title: data.speakerName,
      description:
        data.speakerName +
        ', ' +
        t('button.free_trial') +
        ' ' +
        t('onlineGroup.session'),
      start: startDateString,
      end: endDateString,
    };

    const icsContent = generateICSContent(eventDetails);
    const encodedICSContent = encodeURIComponent(icsContent);
    const googleCalendarURL = `data:text/calendar;charset=utf-8,${encodedICSContent}`;

    const a = document.createElement('a');
    a.href = googleCalendarURL;
    a.download = `Meet2Talk_${eventDetails.title}_event.ics`;
    a.click();
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
    };
  }, []);
  const content = (
    <div className="container-fluid">
      <div className="text-center text-lg-start">
        <div className="row mt-5">
          <div className="col">
            <Card
              body={
                <>
                  <div className="row d-flex mx-auto align-middle justify-content-center">
                    <div className="d-flex justify-content-center">
                      <h4 className="fw-bold mx-auto text-dark justify-content-center my-3">
                        {t('congratulations.thank_you_for_order')}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="mx-auto fw-bold text-dark">
                        {t(
                          isFreeTrial
                            ? 'congratulations.your_trial_session_has_been_successfully_created'
                            : 'congratulations.orderBuying'
                        )}
                      </p>
                    </div>
                    <div className=" d-sm-flex justify-content-center fs-6 fw-bold text-dark">
                      <h6>
                        {t(
                          'congratulations.contact_us_if_you_have_any_questions'
                        )}
                      </h6>
                      <a className="ms-3" href="tel:+908502123636">
                        0 (850) 212 36 36
                      </a>
                    </div>
                    <div className="d-flex my-2 justify-content-center">
                      <img
                        className="img"
                        src="/images/order_completed.svg"
                        alt="order_completed"
                        width={'600px'}
                      />
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row d-flex">
                      <h6 className="text-info">
                        {t('congratulations.process')}
                      </h6>
                      <h6 className="fw-bold ">
                        {t('congratulations.so_what_should_I_do_now')}
                      </h6>
                      <div className="d-sm-flex ">
                        <p className="p-0 m-0">
                          {t(
                            'congratulations.dont_worry_we_have_thought_of_everything_for_you'
                          )}
                        </p>
                        <img
                          className="img mx-2"
                          src="/images/smile.svg"
                          alt="smile"
                        />
                      </div>
                    </div>
                  </div>
                  {isFreeTrial && (
                    <div className="container-fluid">
                      <div className="row row-cols-xl-5 row-cols-1 mt-2">
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-online-green rounded-3  bg-opacity-25 display-5"
                                color="#4CBC9A"
                                icon={faBox}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.cancelleation_process')}
                            </h5>
                            <p>
                              {t(
                                'congratulations.free_trial_sessions_are_for_one_time_only'
                              )}
                            </p>
                            <p>
                              {t(
                                'congratulations.if_you_cannot_attend_the_free_trial_session'
                              )}
                              <br />
                              <a href="tel:+908502123636">0 (850) 212 36 36</a>
                            </p>
                          </div>
                        </div>
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center flex-grow-1 ">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-online-green rounded-3  bg-opacity-25 display-5"
                                color="#4CBC9A"
                                icon={faBox}
                              />
                            </div>
                            <h5 className="fw-bold ">
                              {t('congratulations.advices')}
                            </h5>
                            <p>
                              {t(
                                'congratulations.you_dont_need_to_worry_and_stress'
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center d-flex flex-column flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-t-mor rounded-3  bg-opacity-25 display-5"
                                color="#6244FF"
                                icon={faCalendar}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.add_to_your_calendar')}
                            </h5>
                            <p>
                              {t(
                                'congratulations.you_can_add_it_to_your_personal_calendar'
                              )}
                            </p>
                            <button
                              onClick={() =>
                                addToCalendarAction(
                                  location?.state?.packageData
                                )
                              }
                              className="btn btn-sm text-nowrap bg-t-mor bg-opacity-25  fw-bold text-t-mor  mx-auto mt-auto"
                            >
                              {t('table.actions_add_to_calendar')}
                            </button>
                          </div>
                        </div>
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center d-flex flex-column flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-t-mavi rounded-3  bg-opacity-25 display-5"
                                color="#2196F3"
                                icon={faMoneyBills}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.view_packages')}
                            </h5>
                            <p>
                              {t(
                                'congratulations.by_viewing_our_packages_you_can_view_our_prices'
                              )}
                            </p>
                            <button
                              onClick={() =>
                                navigate(
                                  userInfo.userArea === 0
                                    ? '/order/package'
                                    : userInfo.userArea === 1
                                    ? '/cafe/order/package'
                                    : userInfo.userArea === 2
                                    ? '/kids/order/package'
                                    : '/order/package'
                                )
                              }
                              className="btn btn-sm text-nowrap bg-t-mavi bg-opacity-25  fw-bold text-t-mavi mx-auto mt-auto"
                            >
                              {t('navbar.packages')}
                            </button>
                          </div>
                        </div>
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center d-flex flex-column flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-t-turuncu rounded-3  bg-opacity-25 display-5"
                                color="#FF8F80"
                                icon={faUserGroup}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.view_mentors')}
                            </h5>
                            <p>
                              {t(
                                'congratulations.you_can_review_our_mentors_by_applying_filters_suitable_for_you'
                              )}
                            </p>
                            <button
                              onClick={() => navigate(findMyPath('speakers'))}
                              className="btn btn-sm text-nowrap bg-t-turuncu bg-opacity-25 fw-bold text-t-turuncu  mx-auto mt-auto"
                            >
                              {t('sidebar.mentors')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isBuying && (
                    <div className="container-fluid">
                      <div className="row row-cols-xl-5 row-cols-1 mt-2 justify-content-center">
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center d-flex flex-column flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-t-sarı rounded-3  bg-opacity-25 display-5"
                                color="#CCCC00"
                                icon={faBasketShopping}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.order')}
                            </h5>
                            <p>{t('congratulations.orderExplain')}</p>
                            <button
                              onClick={() => navigate(findMyPath('orders'))}
                              className="btn btn-sm text-nowrap bg-t-sarı bg-opacity-25 fw-bold text-t-dark-yellow mx-auto mt-auto"
                            >
                              {t('congratulations.orders')}
                            </button>
                          </div>
                        </div>
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center d-flex flex-column flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-t-mor rounded-3  bg-opacity-25 display-5"
                                color="#6244FF"
                                icon={faCalendarDays}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.schedule')}
                            </h5>
                            <p>{t('congratulations.scheduleExplain')}</p>
                            <button
                              onClick={() => navigate(findMyPath('schedule'))}
                              className="btn btn-sm text-nowrap bg-t-mor bg-opacity-25 fw-bold text-t-mor mx-auto mt-auto"
                            >
                              {t('congratulations.schedulePage')}
                            </button>
                          </div>
                        </div>
                        <div className="py-2 py-lg-0 d-flex flex-column">
                          <div className="box border container text-center d-flex flex-column flex-grow-1">
                            <div className=" d-inline-flex m-3 justify-content-center ">
                              <FontAwesomeIcon
                                className=" p-3 bg-t-mavi rounded-3  bg-opacity-25 display-5"
                                color="#2196F3"
                                icon={faComments}
                              />
                            </div>
                            <h5 className="fw-bold">
                              {t('congratulations.chat')}
                            </h5>
                            <p>{t('congratulations.chatExplain')}</p>
                            <button
                              onClick={() => navigate(findMyPath('sessions'))}
                              className="btn btn-sm text-nowrap bg-t-mavi bg-opacity-25 fw-bold text-t-mavi mx-auto mt-auto"
                            >
                              {t('congratulations.sessions')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
  return content;
}
