import styles from '../Topbar/Topbar.module.scss';
import MyProfile from './Submenus/MyProfile';
import Notifications from './Submenus/Notifications';
import Languages from './Submenus/Languages';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Input from '../Input/Input';
import { InputType } from '../../../utils/ComponentEnums';
import { useEffect, useState } from 'react';
import { checkLogged } from '../../../hooks/useCheckLogged';
import Basket from './Submenus/Basket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { storageKeys, storageValues } from '../../../utils/Enum';

const Topbar = ({ userArea }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const nav = useSelector((state) => state.screen.size);
  const userInfo = useSelector((state) => state.user.getById);
  const selfUpdate = useSelector((state) => state.user.selfUpdate);
  const userPackage = useSelector((state) => state.userPackage);
  const [searchItem, setSearchItem] = useState('');
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const banner = useSelector((state) => state.banner.getActiveBannerByZone);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      dispatch(
        Actions.speakerActions.getFilteredAction({ uniqueKey: e.target.value })
      );
    }
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearchItem(e.target.value);
  };
  const onSearchClicked = (e) => {
    e.preventDefault();
    dispatch(
      Actions.speakerActions.getFilteredAction({ uniqueKey: searchItem })
    );
  };

  useEffect(() => {
    if (
      auth !== '' &&
      userInfo?.id === undefined &&
      sessionStorage.getItem(storageKeys.landingRule) !==
        storageValues.landingRule &&
      selfUpdate === ''
    ) {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
    } else if (
      auth !== '' &&
      userInfo?.id === undefined &&
      sessionStorage.getItem(storageKeys.landingRule) !==
        storageValues.landingRule &&
      Object.keys(userPackage?.packageData).length > 0 &&
      selfUpdate
    ) {
      dispatch(Actions.userActions.getByIdAction(checkLogged()));
    }
  }, [userInfo?.id, selfUpdate]);

  useEffect(() => {
    if (userInfo.userArea !== undefined && levelGroup === null) {
      dispatch(
        Actions.preferenceActions.getLevelGroupAction(userInfo?.userArea ?? 0)
      );
    }
  }, [userInfo.userArea]);

  return (
    <div
      className={`${styles.container} ${
        (banner?.pictureWeb || banner?.pictureMobile) && lengthCurrentUserClaim
          ? styles.specialContainer
          : ''
      }`}
    >
      {/* BURGER */}
      <div
        className={styles.burger_container}
        onClick={() => dispatch(Actions.screenActions.checkScreenSize(!nav))}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div className={`${styles.search}  `}>
        {userInfo.userArea === 3 ? undefined : (
          <Input
            type={InputType.Text}
            className={'form-control fa fa-Search d-none'}
            placeholder={' Search here..'}
            onKeyPress={onKeyPress}
            onChange={onChangeSearch}
          />
        )}
      </div>
      {/* ACTIONS */}
      <div className={`${styles.actions}`}>
        <div className={`d-flex align-items-center justify-content-evenly`}>
          <span className="me-lg-4 px-0 mt-1">
            <Languages />
          </span>
          <span className="me-lg-4 px-0 mt-1">
            <Notifications />
          </span>
          {currentUserClaim?.length !== 0 ? undefined : userInfo.userArea ===
            3 ? undefined : (
            <Basket />
          )}
        </div>
      </div>
      {/* My Profile */}
      <MyProfile />
    </div>
  );
};
export default Topbar;
