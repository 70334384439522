import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'moment-timezone';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  InputType,
  TextColors,
  ButtonIcon,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import addDay from '../../../hooks/useAddDay';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

const BackOfficeAttendeeFreezeCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationList = useSelector((state) => state.location.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const branchFreeze = useSelector((state) => state.branchFreeze.branchFreeze);
  const isSuccess = useSelector((state) => state.branchFreeze?.isSuccess);
  const validationMessage = useSelector(
    (state) => state.branchFreeze?.validationMessage
  );
  const [createData, setCreateData] = useState(branchFreeze);
  const [handleError, setHandleError] = useState('');
  const redirect = '/branch-holiday-list';

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.branchFreezeActions.cleanState());
  };

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          setCreateData({});
          setHandleError('');
          dispatch(Actions.branchFreezeActions.cleanState());
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.branchFreezeActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    if (
      !createData.locationId ||
      !createData.startDateView ||
      !createData.endDateView ||
      !createData.reason
    ) {
      setHandleError(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    } else await dispatch(Actions.branchFreezeActions.createAction(createData));
  };
  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setCreateData({
        ...createData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
    }
  };

  const handleDropdown = (propName, value) => {
    setCreateData({ ...createData, [propName]: value });
    dispatch(
      Actions.branchFreezeActions.holdValue({
        ...createData,
        [propName]: value,
      })
    );
  };
  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const getList = async () => {
    await dispatch(Actions.locationActions.getAllAction());
  };

  useEffect(() => {
    getList();
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <div>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <h6> Add New Branch Holiday Form</h6>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <DropdownWithSearch
                      isSearchable
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_location') + '**'}
                      options={locationList}
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) => handleDropdown('locationId', value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'reason'}
                      inputType={InputType.Text}
                      labelText={t('Reason**')}
                      inputValue={createData.reason || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-md-6 mt-md-4">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date') + '**'}
                      inputValue={createData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-md-6 mt-md-4">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date') + '**'}
                      inputValue={createData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <Button
                    type={
                      Object.keys(createData).length >= 1
                        ? ButtonType.Successbg
                        : ButtonType.Secondary
                    }
                    text={t('button.submit')}
                    textColor={TextColors.Light}
                    onClick={onCreate}
                    buttonCol={2}
                    disabled={
                      Object.keys(createData).length >= 1 ? false : true
                    }
                  />
                </div>
              </div>
            }
          ></Card>
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.branchFreeze.create,
    content
  );
};
export default BackOfficeAttendeeFreezeCreate;
