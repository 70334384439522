import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperclip,
  faEllipsisH,
  faUser,
  faArrowLeft,
  faXmark,
  faCalendar,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import * as signalR from '@microsoft/signalr';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import MySessionsHistory from './MySessionsHistory';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  InputSize,
  InputType,
  LabelTextType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Button from '../../../components/Common/Button/Button';
import config from '../../../config.json';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/Common/Loader/Loader';
import findMyPath from '../../../hooks/useFindMyPath';
import calculateUTC from '../../../hooks/useCalculateUTC';
import addDay from '../../../hooks/useAddDay';
import Modal from '../../../components/Common/Modal/Modal';
import Input from '../../../components/Common/Input/Input';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import getTimeZone from '../../../hooks/useGetTimeZone';

const MySessions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [connection, setConnection] = useState(null);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const isAttendee = currentUserClaim?.length === 0;
  const attendeeSchedule = useSelector(
    (state) => state.meetingSchema.getAllSchedule
  );
  const speakerSchedule = useSelector(
    (state) => state.meetingSchema.getSpeakerSchedule
  );
  const meetings = isAttendee ? attendeeSchedule : speakerSchedule;
  const currentUserId = useSelector((state) => state.auth.currentUser);
  const groupMessages = useSelector(
    (state) => state.classroom.getGroupMessages
  );
  const groupMembers = useSelector(
    (state) => state.classroom.getGroupRoomMembers
  );
  const classroomIsSuccess = useSelector((state) => state.classroom.isSuccess);
  const userInfo = useSelector((state) => state.user.getById);
  let currentUser = userInfo.firstName + ' ' + userInfo.lastName;
  const [messages, setMessages] = useState([]);
  const [lastMessages, setLastMessages] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [chat, setChat] = useState('');
  const [fileData, setFileData] = useState('');
  const [selectedContact, setSelectedContact] = useState(null);
  const [lastContact, setLastContact] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [showParticipants, setShowParticipants] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState('');
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [meetingDetails, setMeetingDetails] = useState({});
  const [sessionMinute, setSessionMinute] = useState({ is90: false });
  const [attendanceModalStatus, setAttendanceModalStatus] = useState(false);
  const meetingAttendees = useSelector(
    (state) => state.meetingSchema.getMeetingAttendees
  );
  const attendanceIsSuccess = useSelector(
    (state) => state.meetingSchema.isSuccess
  );
  const attendanceValidationMessage = useSelector(
    (state) => state.meetingSchema.validationMessage
  );
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    return () => {
      dispatch(Actions.meetingSchemaActions.cleanState());
      dispatch(Actions.classroomActions.cleanState(true));
    };
  }, []);

  useEffect(() => {
    if (classroomIsSuccess) {
      setSelectedContact(null);
      dispatch(Actions.classroomActions.getGroupRoomMembersAction());
    }
  }, [classroomIsSuccess]);

  useEffect(() => {
    if (handleError !== '' && !attendanceIsSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && attendanceIsSuccess) {
      toast.success(handleError, {
        onClose: () => {
          setHandleError('');
          dispatch(Actions.classroomActions.getGroupRoomMembersAction());
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (attendanceIsSuccess && attendanceValidationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{attendanceValidationMessage}</strong>
        </span>
      );
    } else if (!attendanceIsSuccess && attendanceValidationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{attendanceValidationMessage}</strong>
        </span>
      );
    }
  }, [attendanceIsSuccess, attendanceValidationMessage]);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isAttendee) {
        dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
      } else if (!isAttendee) {
        dispatch(Actions.meetingSchemaActions.getAllSpeakerScheduleAction());
      }
    }, 5 * 60 * 1000);
    return () => clearTimeout(timerId);
  }, [speakerSchedule, attendeeSchedule]);

  function handleNavigateCard(e, to, state) {
    e.preventDefault();
    dispatch(Actions.calendarActions.getFilteredReducer([]));
    return navigate(to, state);
  }

  const handleGroupChange = async (contact) => {
    if (selectedContact?.groupId !== contact.groupId) {
      setIsLoading(true);
      await dispatch(
        Actions.classroomActions.getGroupMessagesAction(contact.groupId)
      ).then(() => setIsLoading(false));
    }

    const currentTime = new Date();
    const utcTime = new Date(
      currentTime.getTime() + currentTime.getTimezoneOffset() * 60000
    );
    const date = `${currentTime.getFullYear()}-${(currentTime.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentTime.getDate().toString().padStart(2, '0')}`;

    let availableMeetings;
    let details;
    meetings?.forEach((meeting, i) => {
      let checked;
      if (
        meeting.groupId === contact.groupId &&
        meeting.speakerId === contact.speakerId
      ) {
        setLastContact(contact.groupId);
        checked = true;
        details = meetings[i];
        setMeetingDetails({
          ...details,
          type: details.type,
        });
      }
      const [y, mo, d] = meeting.date.split('-').map(Number);
      const nDate = addDay(meeting.interval, `${d}-${mo}-${y}`);
      const [dx, mox, yx] = nDate.split('-').map(Number);
      const cDate = `${yx}-${mox.toString().padStart(2, '0')}-${dx
        .toString()
        .padStart(2, '0')}`;
      const getCurrent = calculateUTC(
        `${utcTime.getHours()}:${utcTime.getMinutes()}`
      );
      const cTime = calculateUTC(
        `${meeting.interval.split('-')[0].split(':')[0]}:${
          meeting.interval.split('-')[0].split(':')[1]
        }`
      );

      const [hx, mx] = getCurrent.split(':').map(Number);
      const asMinx = hx * 60 + mx;
      const [h, m] = cTime.split(':').map(Number);
      const asMin = h * 60 + m - 15;
      if (checked && cDate === date.split('T')[0] && asMin <= asMinx) {
        meeting.meetingDetails.forEach((meetingDetail, index) => {
          const [parsedS, parsedE] = meeting?.interval.split('-').map(String);
          const [sH, sM] = parsedS?.split(':').map(Number);
          const [eH, eM] = parsedE?.split(':').map(Number);
          const is90 = eH * 60 + eM - (sH * 60 + sM) >= 90;
          setSessionMinute({ is90: is90 });
          let addMin;
          switch (index) {
            case 0:
              addMin = is90 ? 40 * 60000 : 30 * 60000;
              break;
            case 1:
              addMin = is90 ? 40 * 60000 : 30 * 60000;
              break;
            default:
              addMin = 40 * 60000;
              break;
          }
          const fixDate = handleDates(meetingDetail.startDate);
          const xDate = new Date(fixDate).getTime() + addMin;
          const meetingDateTime = new Date(xDate);
          if (
            meetingDateTime >= currentTime &&
            availableMeetings === undefined
          ) {
            availableMeetings = meetingDetail;
          }
        });
      }
    });
    setSelectedContact(contact);
    setFilteredMeetings(
      availableMeetings !== undefined ? [availableMeetings] : undefined
    );
    setChat('');
  };
  function handleDates(item) {
    const [year, month, day] = item?.split('T')[0].split('-').map(Number);
    const date = `${day}-${month}-${year}`;
    const timeArray = item?.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const time = `${h}:${m}`;
    const prosess = addDay(time, date);
    const fixedDate = `${prosess}T${time}:00.000Z`;

    const [xday, xmonth, xyear] = fixedDate
      ?.split('T')[0]
      .split('-')
      .map(Number);
    const fixDate = `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
      .toString()
      .padStart(2, '0')}T${calculateUTC(fixedDate?.split('T')[1])}`;
    return fixDate;
  }

  const findMeetingUrl = (item, sesMin) => {
    let meetingUrl = null;
    const fifteenMinutesBeforeStart = new Date(handleDates(item[0]?.startDate));
    fifteenMinutesBeforeStart.setMinutes(
      fifteenMinutesBeforeStart.getMinutes() - 15
    );
    const clickAbleLastFiveMins = fifteenMinutesBeforeStart;
    clickAbleLastFiveMins.setMinutes(clickAbleLastFiveMins.getMinutes() + 10);

    const currentDateTime = new Date();
    const firstDate = new Date(handleDates(item[0]?.startDate));
    const min =
      clickAbleLastFiveMins.getMinutes() - currentDateTime.getMinutes();

    if (currentDateTime < clickAbleLastFiveMins) {
      return setMeetingInfo(
        <div>
          {t('mySessions.join_session_info', { min })
            ?.split('{br}')
            ?.map((item, index) => (
              <span
                key={index}
                className="d-flex position-relative justify-content-start"
                style={{ maxWidth: '550px' }}
              >
                <strong>{item}</strong>
              </span>
            ))}
        </div>
      );
    } else if (
      currentDateTime > fifteenMinutesBeforeStart &&
      currentDateTime < firstDate
    ) {
      meetingUrl = item[0]?.meetingUrl;
      setMeetingInfo('');
    }
    const rule = item?.length === 1 ? item?.length : item?.length - 1;
    for (let i = 0; i < rule; i++) {
      const meeting = item[i];
      const meetingDateTime = new Date(handleDates(meeting?.startDate));
      if (item.length === 1) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }
      const nextMeeting = item[i + 1];
      let nextMeetingDateTime;
      if (nextMeeting !== undefined && sesMin) {
        const secondLink = new Date(handleDates(nextMeeting?.startDate));
        nextMeetingDateTime = sessionMinute.is90
          ? new Date(secondLink.setMinutes(secondLink.getMinutes() - 10))
          : secondLink;
      } else {
        nextMeetingDateTime = new Date(handleDates(nextMeeting?.startDate));
      }
      if (
        i === 0 &&
        currentDateTime >= meetingDateTime &&
        currentDateTime <= nextMeetingDateTime
      ) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }

      if (currentDateTime > nextMeetingDateTime) {
        meetingUrl = nextMeeting?.meetingUrl;
        break;
      }
    }

    return meetingUrl;
  };
  const handleClick = (item) => {
    const meetingUrl = findMeetingUrl(item, sessionMinute);
    if (meetingUrl) {
      window.open(meetingUrl, '_blank');
    } else {
      if (isAttendee) {
        dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
      } else if (!isAttendee) {
        dispatch(Actions.meetingSchemaActions.getAllSpeakerScheduleAction());
      }
    }
  };
  const handleAttendanceClick = async (item) => {
    const selectedContactGroupId = selectedContact?.groupId;

    const matchingItem = item.find(
      (item) => item.groupId === selectedContactGroupId
    );

    if (matchingItem) {
      const { id, date, interval } = matchingItem;

      const startTime = interval.split('-')[0];

      const meetingDateTime = `${date}T${startTime}:00.000Z`;

      await dispatch(
        Actions.meetingSchemaActions.getMeetingAttendeesAction({
          id: id,
          date: meetingDateTime,
        })
      );

      setAttendanceModalStatus(true);
    }
  };

  useEffect(() => {
    if (meetingInfo !== '') {
      toast.info(meetingInfo, {
        onClose: () => {
          setMeetingInfo('');
        },
      });
    }
  }, [meetingInfo]);

  const handleGroupChangeGroup = (data) => {
    const timeArray = data.lastMessage.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const s = timeArray[2];
    const result = addDay(
      `${h}:${m}`,
      `${data.lastMessage.split('T')[0]}`,
      false,
      false,
      false,
      true
    );

    const lastMessage = `${result.resultDate}T${result.nTime}:${s}`;
    setMessages({
      ...messages,
      [data.groupId]: data.messages,
      [`${data.groupId} lastMessage`]: lastMessage,
      [`${data.groupId} groupId`]: data.groupId,
    });
    setSelectedGroupName(data.groupName);
  };
  const handleContactListSorting = () => {
    const updatedMessages = {};

    groupMembers?.forEach((group) => {
      const selectedGroup = groupMembers.find(
        (item) => item.groupId === group.groupId
      );

      if (selectedGroup) {
        updatedMessages[`${group.groupId} lastMessage`] =
          selectedGroup.lastMessage;
        updatedMessages[`${group.groupId} groupId`] = group.groupId;
      }
    });
    setLastMessages(updatedMessages);
    setSelectedGroupName('');
  };

  const handleLogin = () => {
    connection
      ?.invoke('GetUserInHub', parseInt(currentUserId))
      .catch((error) => error);
  };

  connection?.on('receiveMessage', (groupId, chat, createdTime, name, file) => {
    const timeArray = createdTime.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const s = timeArray[2];
    const date = `${h}:${m}`;
    const newDate = `${createdTime.split('T')[0]}T${date}:${s}`;

    const newMessage = {
      sender: name,
      message: chat,
      createdTime: newDate,
      filePath: file,
    };

    const existingMessages = messages[groupId];

    if (existingMessages !== undefined) {
      if (showLoader && file !== '') {
        setShowLoader(false);
      }
      const updatedMessages = {
        ...messages,
        [groupId]: [...existingMessages.flat(), newMessage],
        [`${groupId} lastMessage`]: newDate,
        [`${groupId} groupId`]: groupId,
      };
      const afterUpdatedExisting = updatedMessages[groupId];
      if (existingMessages?.length < afterUpdatedExisting?.length) {
        setLastMessages({
          ...lastMessages,
          [`${groupId} lastMessage`]: newDate,
          [`${groupId} groupId`]: groupId,
        });
        setMessages(updatedMessages);
      }
    } else {
      const updatedMessages = {
        ...messages,
        [groupId]: [newMessage],
        [`${groupId} lastMessage`]: newDate,
        [`${groupId} groupId`]: groupId,
      };
      setLastMessages({
        ...lastMessages,
        [`${groupId} lastMessage`]: newDate,
        [`${groupId} groupId`]: groupId,
      });
      setMessages(updatedMessages);
    }
  });

  const sendMessageToGroup = async () => {
    if (chat !== '' && chat !== undefined && chat !== null) {
      if (fileData?.extension !== undefined) {
        setShowLoader(true);
      }
      try {
        await connection.invoke(
          'SendMessageToGroupAsync',
          selectedGroupName,
          chat,
          parseInt(currentUserId),
          fileData?.file,
          fileData?.extension
        );
        setChat('');
        setFileData('');
      } catch (error) {}
    }
  };
  const findElementsWithPrivateGroup = () => {
    const filteredData = groupMembers?.filter((item) =>
      item?.groupName?.includes('Private')
    );

    if (filteredData?.length > 0) {
      setSelectedTab('oneToOne');
    } else {
      setSelectedTab('group');
    }
  };
  const GetLists = async () => {
    await dispatch(Actions.classroomActions.getGroupRoomMembersAction());
  };
  useEffect(() => {
    GetLists().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    handleContactListSorting();
    findElementsWithPrivateGroup();
  }, [groupMembers]);

  useEffect(() => {
    if (groupMessages?.groupId !== undefined) {
      handleGroupChangeGroup(groupMessages);
    }
  }, [groupMessages]);
  const filterContacts = () => {
    if (selectedTab === 'group') {
      const sortedMembers = groupMembers
        ?.filter((contact) => contact?.groupName?.includes('Group'))
        ?.map((contact) => {
          const arr = [lastMessages];
          const lastMessage = arr.find(
            (message) =>
              message[`${contact.groupId} groupId`] === contact.groupId
          )?.[`${contact.groupId} lastMessage`];
          return {
            ...contact,
            lastMessage,
          };
        })
        ?.sort((a, b) => {
          if (!a?.lastMessage) return 1;
          if (!b?.lastMessage) return -1;
          return new Date(b.lastMessage) - new Date(a.lastMessage);
        });
      return sortedMembers?.map((contact) => ({ ...contact }));
    } else {
      const sortedMembers = groupMembers
        ?.filter((contact) => contact.groupName?.includes('Private'))
        ?.map((contact) => {
          const arr = [lastMessages];
          const lastMessage = arr.find(
            (message) =>
              message[`${contact.groupId} groupId`] === contact.groupId
          )?.[`${contact.groupId} lastMessage`];

          return {
            ...contact,
            lastMessage,
          };
        })
        ?.sort((a, b) => {
          if (!a?.lastMessage) return 1;
          if (!b?.lastMessage) return -1;
          return new Date(b.lastMessage) - new Date(a.lastMessage);
        });
      return sortedMembers?.map((contact) => ({ ...contact }));
    }
  };

  useEffect(() => {
    dispatch(Actions.userActions.getByIdAction(currentUserId));
    const startSignalRConnection = async () => {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${config.Connections.API.host}${config.Connections.API.chatHub}`
        )
        .withAutomaticReconnect(1000, 1000, 2000, 3000, 5000, 10000)
        .build();

      async function start() {
        try {
          await newConnection.start();
          setConnection(newConnection);
        } catch (error) {
          setTimeout(() => start(), 2000);
        }
      }
      start();
    };
    startSignalRConnection();
  }, []);

  useEffect(() => {
    handleLogin();
  }, [connection]);

  const getLastChatTime = (messages) => {
    if (messages === '0001-01-01T00:00:00') {
      return '';
    }
    if (messages) {
      const timeArray = messages.split('T')[1].split(':');
      const h = timeArray[0];
      const m = timeArray[1];
      const s = timeArray[2];

      const result = addDay(
        `${h}:${m}`,
        `${messages.split('T')[0]}`,
        false,
        false,
        false,
        false,
        false,
        true
      );

      return `${result.nTime.split(':')[0]}:${result.nTime.split(':')[1]}`;
    }
  };

  function formatMessageTime(createdTime) {
    if (!createdTime) return '';
    const timeArray = createdTime.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const s = timeArray[2];

    const result = addDay(
      `${h}:${m}`,
      `${createdTime.split('T')[0]}`,
      false,
      false,
      false,
      false,
      false,
      true
    );
    return `${result.nTime.split(':')[0]}:${result.nTime.split(':')[1]}`;
  }
  function formatDate(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dateTime.getFullYear();

    return `${hours}:${minutes}, ${day}-${month}-${year}`;
  }
  const chatMessagesRef = useRef(null);
  useEffect(() => {
    scrollToBottom();
    filterContacts();
  }, [selectedGroupName, lastMessages]);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  function shortenFileName(fileName, maxLength = 15) {
    if (fileName.length <= maxLength) return fileName;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
    const fileNameWithoutExtension = fileName.substring(
      0,
      fileName.lastIndexOf('.')
    );
    const shortenedFileName = fileNameWithoutExtension.substring(
      0,
      maxLength - 4
    );
    return `${shortenedFileName}...${fileExtension}`;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isAttendee) {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    } else if (!isAttendee) {
      dispatch(Actions.meetingSchemaActions.getAllSpeakerScheduleAction());
    }
  }, []);

  function formatDateForDayOfWeek(dayOfWeek) {
    const currentDate = new Date();
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const indexOfDay = weekdays.indexOf(dayOfWeek);
    const daysDifference = (indexOfDay - currentDate.getDay() + 7) % 7;

    currentDate.setDate(currentDate.getDate() + daysDifference);

    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  }

  function getDayOfWeek(dateString) {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return daysOfWeek[dayOfWeek];
  }

  const calculateFormattedDateTime = (interval, date) => {
    const result = addDay(
      `${interval}`,
      `${formatDateForDayOfWeek(date)}`,
      false,
      false,
      false,
      false,
      false,
      false,
      true
    );
    return `${result.resultDate}`;
  };

  const [attendanceData, setAttendanceData] = useState({
    meetingId: '',
    groupId: '',
    attendanceChecks: [],
  });

  const constructAttendanceData = () => {
    const newAttendanceChecks = meetingAttendees.map((attendee) => ({
      meetingAttendeeId: attendee.meetingAttendeeId,
      attendeeId: attendee.attendeeId,
      languageLevel: attendee?.languageLevel || '',
      attendanceCheck: false,
      note: '',
    }));
    const matchingItem = speakerSchedule.find(
      (item) => item.groupId === selectedContact?.groupId
    );

    setAttendanceData((prevState) => ({
      ...prevState,
      meetingId: matchingItem?.id,
      groupId: selectedContact?.groupId,
      attendanceChecks: newAttendanceChecks,
    }));
  };

  useEffect(() => {
    constructAttendanceData();
  }, [meetingAttendees]);

  const handleCheckboxChange = (meetingAttendeeId, level, note) => {
    setAttendanceData({
      ...attendanceData,
      attendanceChecks: attendanceData.attendanceChecks.map((check) => {
        if (check.meetingAttendeeId === meetingAttendeeId) {
          const updatedCheck = {
            ...check,
            attendanceCheck:
              level === undefined && note === undefined
                ? !check.attendanceCheck
                : check.attendanceCheck,
          };
          if (level !== undefined) {
            updatedCheck.languageLevel = level;
          }
          if (note !== undefined) {
            updatedCheck.note = note;
          }
          return updatedCheck;
        }
        return check;
      }),
    });
  };

  const hasInvalidFreeTrialAttendee = () => {
    return meetingAttendees.some((attendee) => {
      if (attendee.trialStatus === 'Free Trial') {
        const check = attendanceData?.attendanceChecks?.find(
          (check) => check?.meetingAttendeeId === attendee?.meetingAttendeeId
        );
        if (check?.attendanceCheck) {
          return !check?.note || check?.note?.length < 50;
        } else {
          return false;
        }
      }
      return false;
    });
  };

  const handleAttendanceSubmit = () => {
    if (hasInvalidFreeTrialAttendee()) {
      return;
    }
    dispatch(
      Actions.meetingSchemaActions.attendanceCheckAction(attendanceData)
    );
    setAttendanceModalStatus(false);
  };

  const staticDatas = useSelector((state) => state.staticData);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }
  const [isActive, setIsActive] = useState();

  function checkAttendeedanceButton() {
    const currentTime = new Date();
    const dayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const [startTime, endTime] = selectedContact?.interval
      ?.split('-')
      ?.map(String);
    const [sH, sM] = calculateUTC(startTime)?.split(':')?.map(Number);
    const [eH, eM] = calculateUTC(endTime)?.split(':')?.map(Number);

    if (
      // sH * 60 + sM > eH * 60 + eM &&
      selectedContact?.date !== dayNames[currentTime.getDay()]
    ) {
      currentTime.setDate(currentTime.getDate() - 1);
    }
    const currentDay = currentTime.getDay();
    const isSameDay = selectedContact?.date === dayNames[currentDay];
    setIsActive(isSameDay);
  }

  useEffect(() => {
    if (
      selectedContact !== null &&
      groupMembers.length > 0 &&
      attendanceIsSuccess
    ) {
      const filteredGroupMembers = groupMembers.filter(
        (member) => member.groupId === lastContact
      );
      setSelectedContact(filteredGroupMembers[0]);
    }
  }, [groupMembers]);

  function checkAttendeedanceButtonActive() {
    const utcSet = addDay(
      selectedContact?.meetingDate.split(' ')[1],
      selectedContact?.meetingDate.split(' ')[0]
    );
    const [h, min] = selectedContact?.meetingDate
      .split(' ')[1]
      .split(':')
      .map(Number);
    const [d, m, y] = utcSet.split('-').map(Number);
    const currentDate = new Date();
    const newDate = new Date(y, m - 1, d, 0, 0, 0, 0, 0);
    newDate.setMinutes(
      newDate.getMinutes() +
        min +
        h * 60 +
        getTimeZone(true) +
        selectedContact?.sessionDuration
    );
    return newDate < currentDate;
  }

  useEffect(() => {
    if (selectedContact !== null && selectedContact !== undefined) {
      checkAttendeedanceButton();
    }
  }, [selectedContact]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="container">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">{t('mySessions.my_sessions')}</h1>
      </div>
      {isAttendee && groupMembers && groupMembers?.length === 0 ? (
        <div className="container bg-white p-lg-3 rounded">
          <div className="container ">
            <div className="row">
              <div className="mx-auto my-5 align-items-center">
                <img
                  src="/images/sorry.png"
                  alt="Placeholder"
                  className="img-fluid mx-auto d-block mb-4"
                />
                <p className="text-center">
                  {t('mySessions.you_cant_see_it_because')}
                </p>
                <ul className="list-unstyled text-center ">
                  <li className="my-2">
                    <span className="fw-bold disc-list-style"></span>
                    {t('mySessions.you_can_communicate_with')}
                  </li>
                  <li className="my-2">
                    <span className="fw-bold disc-list-style"></span>
                    {t('mySessions.if_you_have_a_group_session')}
                  </li>
                  <li className="my-2">
                    <span className="fw-bold disc-list-style"></span>
                    {t('mySessions.both_your_mentor_and_you_will')}
                  </li>
                </ul>
                <div className="col-lg-3 col-sm-4 col-6 mx-auto text-center mt-4">
                  <Button
                    text={t('button.buy_sessions')}
                    type={ButtonType.Successbg}
                    textColor={TextColors.Light}
                    buttonCol={12}
                    bold
                    onClick={(e) =>
                      handleNavigateCard(e, findMyPath('speakers'), {
                        state: {
                          directlyBuyCalendar: true,
                          trialStatus: 2,
                          userArea: userInfo.userArea,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid chat-app p-lg-3 rounded">
          <div className="row bg-white chat-page">
            {/* Contacts */}
            <div
              className={`${
                isMobile && selectedContact
                  ? 'd-none'
                  : isMobile
                  ? 'col-12'
                  : 'col-lg-3'
              }  contacts bg-white border-end p-0`}
              style={{ height: '60vh', maxHeight: '60vh', overflow: 'auto' }}
            >
              <div className="tab position-sticky top-0 bg-light">
                <div className="d-flex justify-content-between tab-menu border-bottom">
                  <button
                    onClick={() => {
                      setSelectedTab('oneToOne');
                      setSelectedContact(null);
                    }}
                    className={`fs-6 fw-bolder text-nowrap me-5 ${
                      selectedTab === 'oneToOne' ? 'focus' : ' text-muted'
                    }`}
                  >
                    {t('mentor.onetoone')}
                  </button>
                  <button
                    onClick={() => {
                      setSelectedTab('group');
                      setSelectedContact(null);
                    }}
                    className={`fs-6 fw-bolder text-nowrap me-5 ${
                      selectedTab === 'group' ? 'focus' : ' text-muted'
                    }`}
                  >
                    {t('mentor.group')}
                  </button>
                </div>
              </div>
              {filterContacts()?.map((contact, i) => (
                <div
                  key={`${contact.speakerId}_${contact.groupId}`}
                  className={`contact-list overflow-auto border-bottom-subject ${
                    selectedContact?.speakerId === contact.speakerId &&
                    selectedContact?.groupId === contact.groupId
                      ? 'selected bg-light'
                      : ''
                  }`}
                  onClick={() => handleGroupChange(contact)}
                >
                  <div className="contact-details py-2 px-2 d-flex justify-content-between align-items-center">
                    <img
                      className="rounded-circle"
                      src={
                        contact?.person?.find((val) => val.isSpeaker)?.userImage
                      }
                      alt="avatar"
                      height="50"
                      width="50"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/empty-profile.png';
                      }}
                    />
                    <div className="about ms-1 me-auto">
                      {!isAttendee && selectedTab === 'oneToOne' ? (
                        <p className="p-0 m-0">
                          {contact?.person[1]?.personName}
                        </p>
                      ) : !isAttendee && selectedTab === 'group' ? (
                        <p className="p-0 m-0">{t(contact?.level)}</p>
                      ) : (
                        <p className="p-0 m-0">{`${contact?.speakerName}`}</p>
                      )}

                      <div className="d-flex flex-wrap text-wrap">
                        <p
                          className={`d-inline-block ms-1 bg-${
                            ButtonType[t(contact.sessionType, { lng: 'en' })]
                          } ${
                            TextColors[
                              t('Chat_' + contact.sessionType, { lng: 'en' })
                            ]
                          }  bg-opacity-25 rounded px-1 m-0 flex-shrink-0 fw-bolder`}
                          style={{ fontSize: '0.85em' }}
                        >
                          <FontAwesomeIcon icon={faCalendar} />
                          {contact.sessionType === 'Cafe'
                            ? contact.location
                            : null}{' '}
                          {contact.sessionType}{' '}
                          {`${getDayOfWeek(
                            calculateFormattedDateTime(
                              contact.interval,
                              contact.date
                            )
                          )} `}
                        </p>

                        <p
                          className={`d-inline-block ms-1 bg-${
                            ButtonType[t(contact.sessionType, { lng: 'en' })]
                          } ${
                            TextColors[
                              t('Chat_' + contact.sessionType, { lng: 'en' })
                            ]
                          }   bg-opacity-25 rounded px-1 m-0 flex-shrink-0 fw-bolder`}
                          style={{ fontSize: '0.85em' }}
                        >
                          <FontAwesomeIcon icon={faClock} />
                          {`${calculateUTC(
                            contact.interval.split('-')[0]
                          )} - ${calculateUTC(contact.interval.split('-')[1])}`}
                        </p>
                      </div>
                    </div>
                    <div className="last-chat-time">
                      <p className="m-0 p-0">
                        {getLastChatTime(
                          lastMessages[`${contact.groupId} lastMessage`]
                        )}
                      </p>
                      {contact?.groupName.includes('Group') && (
                        <p className="m-0 p-0 float-end">
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-secondary"
                          />
                          {contact?.capacity}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Chats */}
            <div
              className={` bg-white p-lg-3   chat ${
                isMobile && selectedContact && !showParticipants
                  ? 'col-lg-12'
                  : !isMobile && showParticipants
                  ? 'col-lg-6'
                  : isMobile && selectedContact && showParticipants
                  ? 'd-none'
                  : 'col-lg-9'
              }`}
            >
              {selectedContact ? (
                <div>
                  <div className="contact-profile chat-header pb-lg-2 border-bottom">
                    <div className="d-flex  align-items-center">
                      <div className="d-flex me-auto flex-grow-1 align-items-center">
                        {isMobile && selectedContact ? (
                          <div className=" align-items-center pe-2  flex-shrink-0   ">
                            <FontAwesomeIcon
                              role="button"
                              className="fs-5"
                              icon={faArrowLeft}
                              onClick={() => setSelectedContact(null)}
                            />
                          </div>
                        ) : null}
                        {!isMobile && (
                          <div className=" my-auto align-items-middle  flex-shrink-1 align-items-center">
                            <img
                              className="rounded-circle"
                              src={
                                selectedContact?.person?.find(
                                  (val) => val.isSpeaker
                                )?.userImage
                              }
                              alt="avatar"
                              height="50"
                              width="50"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/images/empty-profile.png';
                              }}
                            />
                          </div>
                        )}
                        {!isMobile && (
                          <div className=" my-auto ps-3 chat-about flex-grow-1 align-items-center ">
                            <h6 className="m-0">
                              {!isAttendee && selectedTab === 'oneToOne'
                                ? selectedContact?.person[1]?.personName
                                : !isAttendee && selectedTab === 'group'
                                ? t(selectedContact?.level)
                                : selectedContact?.person?.find(
                                    (val) => val.isSpeaker
                                  )?.personName}
                            </h6>
                          </div>
                        )}
                      </div>
                      {!isAttendee &&
                        !selectedContact.canHide &&
                        selectedContact?.person?.find(
                          (val) =>
                            val?.isSpeaker && val?.userId === userInfo?.id
                        ) && (
                          <div
                            className={
                              isMobile
                                ? ` ms-1`
                                : `ms-auto d-inline-flex px-2 flex-shrink-0 ms-1`
                            }
                          >
                            <Button
                              key={`${filteredMeetings?.[0]?.urlOrder}button1`}
                              type={
                                selectedContact.attendanceChecked && isActive
                                  ? ButtonType.Success
                                  : (!isActive &&
                                      selectedContact.attendanceChecked) ||
                                    !checkAttendeedanceButtonActive()
                                  ? ButtonType.ChatInitial
                                  : ButtonType.ChatDuring
                              }
                              buttonCol={12}
                              outline={
                                ((!isActive &&
                                  selectedContact.attendanceChecked) ||
                                  !checkAttendeedanceButtonActive()) &&
                                !(selectedContact.attendanceChecked && isActive)
                                  ? undefined
                                  : true
                              }
                              size={ButtonSize.Small}
                              textColor={
                                selectedContact.attendanceChecked && isActive
                                  ? ''
                                  : (!isActive &&
                                      selectedContact.attendanceChecked) ||
                                    !checkAttendeedanceButtonActive()
                                  ? TextColors.Muted
                                  : TextColors.Light
                              }
                              icon={
                                selectedContact.attendanceChecked && isActive
                                  ? ButtonIcon.Check
                                  : ButtonIcon.User
                              }
                              iconColor={
                                selectedContact.attendanceChecked && isActive
                                  ? ''
                                  : (!isActive &&
                                      selectedContact.attendanceChecked) ||
                                    !checkAttendeedanceButtonActive()
                                  ? TextColors.Muted
                                  : TextColors.Light
                              }
                              iconSide
                              bold={
                                ((!isActive &&
                                  selectedContact.attendanceChecked) ||
                                  !checkAttendeedanceButtonActive()) &&
                                !(selectedContact.attendanceChecked && isActive)
                              }
                              borderless
                              text={t('mySessions.attendance_list')}
                              onClick={() =>
                                handleAttendanceClick(speakerSchedule)
                              }
                              disabled={
                                selectedContact.attendanceChecked ||
                                !checkAttendeedanceButtonActive()
                              }
                            />
                          </div>
                        )}
                      {(meetingDetails?.type !== 'Cafe' ||
                        selectedContact.canHide) && (
                        <div
                          className={
                            isMobile
                              ? ` ms-1`
                              : `ms-auto d-inline-flex px-2 flex-shrink-0 ms-1`
                          }
                        >
                          <Button
                            key={filteredMeetings?.[0]?.urlOrder}
                            type={
                              !selectedContact.canHide &&
                              filteredMeetings === undefined
                                ? ButtonType.ChatInitial
                                : !selectedContact.canHide
                                ? ButtonType.ChatDuring
                                : ButtonType.Danger
                            }
                            icon={
                              selectedContact.canHide
                                ? ButtonIcon.TrashCan
                                : ButtonIcon.ArrowRightLong
                            }
                            iconColor={
                              !selectedContact.canHide &&
                              filteredMeetings === undefined
                                ? TextColors.Muted
                                : TextColors.Light
                            }
                            iconSide
                            borderless
                            buttonCol={12}
                            size={ButtonSize.Small}
                            bold={
                              !selectedContact.canHide &&
                              filteredMeetings === undefined
                            }
                            textColor={
                              !selectedContact.canHide &&
                              filteredMeetings === undefined
                                ? TextColors.Muted
                                : TextColors.Light
                            }
                            text={t(
                              !selectedContact.canHide
                                ? 'mySessions.join_session'
                                : 'mySessions.remove_classroom'
                            )}
                            onClick={() => {
                              if (!selectedContact.canHide) {
                                handleClick(meetingDetails?.meetingDetails);
                              } else {
                                const requestData = {
                                  groupId: selectedContact.groupId,
                                  userId: selectedContact.userId,
                                };
                                dispatch(
                                  Actions.classroomActions.hideClassroomGroupAction(
                                    requestData
                                  )
                                );
                              }
                            }}
                            disabled={
                              !selectedContact.canHide &&
                              filteredMeetings === undefined
                            }
                          />
                        </div>
                      )}{' '}
                      <div className="ms-auto flex-shrink-0 px-2 align-items-middle">
                        <FontAwesomeIcon
                          role="button"
                          icon={faEllipsisH}
                          className="text-secondary fs-5 justify-content-end float-end"
                          onClick={() => setShowParticipants(!showParticipants)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="position-relative my-2">
                    <div className="position-absolute top-0 start-50 translate-middle ">
                      <div className="d-flex mt-2">
                        <p
                          className={`d-inline-block bg-${
                            ButtonType[
                              t(selectedContact.sessionType, { lng: 'en' })
                            ]
                          } ${
                            TextColors[
                              t('Chat_' + selectedContact.sessionType, {
                                lng: 'en',
                              })
                            ]
                          }  bg-opacity-25 rounded  px-1 m-0 flex-shrink-0 fw-bolder`}
                          style={{ fontSize: '0.85em' }}
                        >
                          <FontAwesomeIcon icon={faCalendar} />
                          {selectedContact.sessionType === 'Cafe'
                            ? selectedContact.location
                            : null}{' '}
                          {selectedContact.sessionType}{' '}
                          {`${getDayOfWeek(
                            calculateFormattedDateTime(
                              selectedContact.interval,
                              selectedContact.date
                            )
                          )} `}
                        </p>{' '}
                        <p
                          className={`d-inline-block ms-2 bg-${
                            ButtonType[
                              t(selectedContact.sessionType, { lng: 'en' })
                            ]
                          } ${
                            TextColors[
                              t('Chat_' + selectedContact.sessionType, {
                                lng: 'en',
                              })
                            ]
                          }  bg-opacity-25 rounded px-1 m-0 flex-shrink-0 fw-bolder`}
                          style={{ fontSize: '0.85em' }}
                        >
                          <FontAwesomeIcon icon={faClock} />
                          {`${calculateUTC(
                            selectedContact.interval.split('-')[0]
                          )} - ${calculateUTC(
                            selectedContact.interval.split('-')[1]
                          )}`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="chat-messages   overflow-auto py-3 p-2"
                    ref={chatMessagesRef}
                    style={{ height: '50vh', maxHeight: '50vh' }}
                  >
                    <div
                      className="message-content "
                      style={{ wordWrap: 'break-word' }}
                    >
                      {messages[selectedContact?.groupId]?.map((message, i) => (
                        <div
                          key={`${message?.createdTime}-${i}`}
                          className={`message ${
                            message?.sender === currentUser
                              ? 'float-end bg-lightcustom'
                              : 'float-start bg-t-sarı'
                          }`}
                        >
                          <div>
                            <div className=" fw-bolder">{message?.sender}</div>
                            {message?.filePath !== '' ? (
                              <div
                                role="button"
                                onClick={
                                  () => window.open(message.filePath, '_blank')
                                  // downloadFile(message.filePath, message?.message)
                                }
                                className="message-text"
                              >
                                {message?.message}
                              </div>
                            ) : message?.sender === 'System Message' ? (
                              <div
                                className="message-text"
                                dangerouslySetInnerHTML={{
                                  __html: message?.message,
                                }}
                              />
                            ) : (
                              <div className="message-text">
                                {message?.message}
                              </div>
                            )}
                            <div className="message-timestamp opacity-50 float-end ">
                              {formatMessageTime(message?.createdTime)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="chat-input p-2 position-sticky  bottom-0 ">
                    <div className="input-group border">
                      <input
                        type="text"
                        className="form-control h6  m-0"
                        value={chat}
                        onChange={(e) => setChat(e.target.value)}
                        placeholder={t('classroom.type_your_message')}
                        disabled={
                          !selectedContact.person.find(
                            (val) => val.userId === userInfo.id
                          )?.isActive
                        }
                      />
                      <CustomFileInput
                        id={'userImage'}
                        hidden={true}
                        buttonText={t('classroom.type_your_message')}
                        textColor={TextColors.Light}
                        buttonColor={ButtonType.Light}
                        disabled={
                          !selectedContact.person.find(
                            (val) => val.userId === userInfo.id
                          )?.isActive
                        }
                        buttonIcon={<FontAwesomeIcon icon={faPaperclip} />}
                        func={(val) => {
                          setFileData({
                            file: val.base64String,
                            extension: val.extension,
                          });
                          setChat(val.fileName);
                        }}
                      />
                      <Button
                        type={
                          showLoader ||
                          !selectedContact.person.find(
                            (val) => val.userId === userInfo.id
                          )?.isActive
                            ? ButtonType.Secondary
                            : ButtonType.Successbg
                        }
                        bold
                        textColor={TextColors.Light}
                        buttonCol={2}
                        text={t('classroom.send')}
                        onClick={sendMessageToGroup}
                        loading={showLoader}
                        disabled={
                          !selectedContact.person.find(
                            (val) => val.userId === userInfo.id
                          )?.isActive
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="no-contact-selected  d-flex align-items-center justify-content-center
            "
                >
                  <p>{t('classroom.select_a_contact_to_start')}</p>
                </div>
              )}
            </div>

            {/* Details */}
            {showParticipants && (
              <div
                className={`${
                  isMobile && selectedContact && showParticipants
                    ? 'col-12'
                    : 'col-lg-3'
                }  participants border-start`}
                style={{ height: '60vh', maxHeight: '60vh', overflow: 'auto' }}
              >
                <div className="participants-header d-flex align-items-center py-2 px-3">
                  {isMobile && selectedContact && showParticipants ? (
                    <div className=" align-items-center pe-2  flex-shrink-0   ">
                      <FontAwesomeIcon
                        role="button"
                        className="fs-5"
                        icon={faArrowLeft}
                        onClick={() => setShowParticipants(!showParticipants)}
                      />
                    </div>
                  ) : null}
                  {!isAttendee ? (
                    <>
                      <h5 className="flex-grow-1">
                        {t('classroom.participants')}
                      </h5>
                      <FontAwesomeIcon
                        role="button"
                        icon={faXmark}
                        className="text-secondary fs-5"
                        onClick={() => setShowParticipants(!showParticipants)}
                      />{' '}
                    </>
                  ) : null}
                </div>
                <div className="participants-list p-3">
                  {/* Display participants here */}
                  <div className="d-flex align-items-center">
                    {!isAttendee &&
                      selectedContact?.person.map(
                        (person) =>
                          person.userImage !== null && (
                            <img
                              key={person.userId}
                              className="rounded-circle mr-2"
                              src={person.userImage}
                              alt="participant"
                              height="30"
                              width="30"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/images/empty-profile.png';
                              }}
                            />
                          )
                      )}
                  </div>
                </div>
                <div
                  className={`files-links-list  ${!isAttendee ? 'p-3' : ''}`}
                >
                  {/* Display files and links here */}
                  <h6 className="text-nowrap">{t('classroom.shared_files')}</h6>
                  {messages[selectedContact?.groupId]?.map(
                    (file, index) =>
                      file.filePath !== '' && (
                        <div
                          key={index}
                          className=" d-flex justify-content-between align-items-center py-1"
                        >
                          <div className="col-3 text-center">
                            <FontAwesomeIcon
                              icon={faPaperclip}
                            ></FontAwesomeIcon>
                          </div>
                          <div className="col-8 ">
                            <span
                              role="button"
                              className="fw-bold text"
                              onClick={() =>
                                window.open(file.filePath, '_blank')
                              }
                            >
                              {shortenFileName(file?.message)}
                            </span>
                            <p className="p-0 m-0">
                              {formatDate(file?.createdTime)}
                            </p>
                          </div>
                          <div className="col-1 ">
                            <span>{file.size}</span>
                          </div>
                        </div>
                      )
                  )}

                  {/* <h6>{t('classroom.shared_links')}</h6>

                {dummyLinks.map((link, index) => (
                  <div
                    key={index}
                    className=" d-flex justify-content-between align-items-center py-1"
                  >
                    <div className="col-3 text-center">
                      <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                    </div>
                    <div className="col-6 ">
                      <span className="fw-bold text">{link.name}</span>
                    </div>
                    <div className="col-3 ">
                      <p className="text-nowrap p-0 m-0">{link.date}</p>
                    </div>
                  </div>
                ))} */}
                </div>
              </div>
            )}
          </div>
          {meetingInfo !== '' && (
            <ToastContainer
              style={customToastStyle}
              limit={1}
              autoClose={2000}
              closeOnClick
              theme="light"
            />
          )}
          {handleError !== '' && (
            <ToastContainer
              style={customToastStyle}
              autoClose={false}
              closeOnClick
              theme="light"
            />
          )}
        </div>
      )}
      <Modal
        isVisible={attendanceModalStatus}
        styleProps={{ maxWidth: 600 }}
        title={t('mySessions.attendance_list')}
        content={
          <div
            className="container"
            style={{ maxHeight: '700px', height: '450px' }}
          >
            <span className="text-mute fs-6">
              {t('mySessions.you_can_check_the_attendance')}
            </span>
            <div className="mt-3">
              {meetingAttendees.map((attendee) => (
                <div
                  key={attendee.meetingAttendeeId}
                  className="fs-6 d-flex text-dark flex-column align-items-start mt-2"
                >
                  <div className="d-flex align-items-center">
                    <Input
                      key={attendee.meetingAttendeeId}
                      id={attendee.meetingAttendeeId}
                      labelValue={attendee.name}
                      checked={
                        attendanceData?.attendanceChecks.find(
                          (check) =>
                            check.meetingAttendeeId ===
                            attendee.meetingAttendeeId
                        )?.attendanceCheck
                      }
                      labelOnRight={true}
                      type={InputType.Checkbox}
                      onClick={() =>
                        handleCheckboxChange(attendee.meetingAttendeeId)
                      }
                    />
                    {attendee.trialStatus === 'Free Trial' && (
                      <div className="ms-2">
                        <span className="badge bg-custom fw-bold">
                          {t(`${attendee.trialStatus}`)}
                        </span>
                      </div>
                    )}
                  </div>
                  {attendee.trialStatus === 'Free Trial' &&
                    attendanceData?.attendanceChecks.find(
                      (check) =>
                        check.meetingAttendeeId === attendee.meetingAttendeeId
                    )?.attendanceCheck && (
                      <div className="d-sm-flex justify-content-evenly w-100 mt-3">
                        <div className="flex-grow-1 px-1 pt-2">
                          <DropdownWithSearch
                            isSearchable
                            placeHolder={t('dropdownSelect.select_level')}
                            options={selectedLanguageLevels}
                            selectedProps={{
                              key: 'key',
                              value: 'key',
                              searchKey: 'key',
                              search: 'search',
                            }}
                            selectedValues={
                              attendanceData?.attendanceChecks.find(
                                (check) =>
                                  check.meetingAttendeeId ===
                                  attendee.meetingAttendeeId
                              )?.languageLevel
                            }
                            onChange={(selectedLevel) =>
                              handleCheckboxChange(
                                attendee.meetingAttendeeId,
                                selectedLevel
                              )
                            }
                          />
                        </div>
                        <div className="flex-grow-1 px-1">
                          <Input
                            id={`message-${attendee.meetingAttendeeId}`}
                            className={InputSize.Medium}
                            inputType={InputType.Text}
                            placeholder={t(
                              'mySessions.please_ensure_all_selected_attendees'
                            )}
                            value={
                              attendanceData?.attendanceChecks.find(
                                (check) =>
                                  check.meetingAttendeeId ===
                                  attendee.meetingAttendeeId
                              )?.note
                            }
                            textArea={{ row: 5, col: 12 }}
                            labelFontType={LabelTextType.Bold}
                            onChange={(e) =>
                              handleCheckboxChange(
                                attendee.meetingAttendeeId,
                                undefined,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
        }
        footer={
          <Button
            type={
              hasInvalidFreeTrialAttendee()
                ? ButtonType.Secondary
                : ButtonType.Successbg
            }
            textColor={TextColors.Light}
            bold
            buttonCol={5}
            text={t('button.confirm')}
            onClick={() => handleAttendanceSubmit()}
            disabled={hasInvalidFreeTrialAttendee()}
          />
        }
        onClose={() => {
          setAttendanceModalStatus(false);
        }}
      />
      {isAttendee ? <MySessionsHistory /> : null}
    </div>
  );
};
export default MySessions;
