import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import addDay from '../../../hooks/useAddDay';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const branchId = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const isSuccess = useSelector((state) => state.branchFreeze.isSuccess);
  const validationMessage = useSelector(
    (state) => state.branchFreeze.validationMessage
  );
  const branchFreeze = useSelector((state) => state.branchFreeze.branchFreeze);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const locationList = useSelector((state) => state.location.getAll);

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.branchFreezeActions.cleanState());
  };
  const getList = async () => {
    await dispatch(Actions.locationActions.getAllAction());
  };

  useEffect(() => {
    getList();
  }, []);

  const redirect = '/branch-holiday-list';
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          setUpdateData({});
          setHandleError('');
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...updateData, [id]: value });
    if (value) {
      setDataChanged(false);
    }
  };

  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...branchFreeze });
    } else {
      dispatch(Actions.branchFreezeActions.holdValue({ ...updateData }));
    }
  };

  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...branchFreeze });
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length > 0) {
      await dispatch(
        Actions.branchFreezeActions.updateAction(updateData, branchId)
      );
    }
  };
  async function FetchById() {
    await dispatch(Actions.branchFreezeActions.getByIdAction(branchId));
  }

  useEffect(() => {
    FetchById();
    return () => {
      dispatch(Actions.branchFreezeActions.cleanState());
    };
  }, []);
  useEffect(() => {
    if (Object.keys(branchFreeze).length > 0) {
      setUpdateData({ ...branchFreeze });
    }
  }, [branchFreeze]);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setUpdateData({
        ...updateData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
      setDataChanged(false);
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDataChanged(false);
    }
  };

  const convertToLocalDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date - userTimezoneOffset);
  };

  useEffect(() => {
    if (Object.keys(branchFreeze).length > 0) {
      const localStartDate = convertToLocalDate(branchFreeze.startDate);
      const yearStart = localStartDate.getFullYear();
      const monthStart = ('0' + (localStartDate.getMonth() + 1)).slice(-2);
      const dayStart = ('0' + localStartDate.getDate()).slice(-2);
      const startDateView = `${yearStart}-${monthStart}-${dayStart}`;

      const localEndDate = convertToLocalDate(branchFreeze.endDate);
      const yearEnd = localEndDate.getFullYear();
      const monthEnd = ('0' + (localEndDate.getMonth() + 1)).slice(-2);
      const dayEnd = ('0' + localEndDate.getDate()).slice(-2);
      const endDateView = `${yearEnd}-${monthEnd}-${dayEnd}`;

      setUpdateData({
        ...branchFreeze,
        startDateView: startDateView,
        endDateView: endDateView,
      });
    }
  }, [branchFreeze]);
  const handleDropdown = (propName, value) => {
    setUpdateData({ ...updateData, [propName]: value });
    dispatch(
      Actions.branchFreezeActions.holdValue({
        ...updateData,
        [propName]: value,
      })
    );
    if (value) {
      setDataChanged(false);
    }
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={'Update Branch Freeze Form'}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedValues={
                        locationList.length > 0 ? updateData.locationId : ''
                      }
                      noTranslate={true}
                      selectedProps={{
                        key: 'locationName',
                        value: 'id',
                        searchKey: 'id',
                      }}
                      onChange={(value) => handleDropdown('locationId', value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'reason'}
                      inputType={InputType.Text}
                      labelText={t('Reason**')}
                      inputValue={updateData.reason}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-md-6 mt-md-4">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={updateData.startDateView || ''}
                      onChange={onChangeDate}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-md-6 mt-md-4">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={updateData.endDateView || ''}
                      onChange={onChangeDate}
                      onBlur={onBlur}
                    />
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        dataChanged
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={dataChanged}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.branchFreeze.update,
    content
  );
};
export default Update;
