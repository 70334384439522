import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import { Loader } from '../../../components/Common/Loader/Loader';
import useLoader from '../../../hooks/useLoader';
import { ToastContainer, toast } from 'react-toastify';
import findMyPath from '../../../hooks/useFindMyPath';
import Error404 from '../CommonPages/Error404';

export default function VerifyEmail() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const auth = useSelector((state) => state.auth.auth);
  const validationMessages = useSelector(
    (state) => state.user.validationMessage
  );
  const isSuccess = useSelector((state) => state.user.isSuccess);
  const navigate = useNavigate();
  const [notifyMessage, setNotifyMesage] = useState('');
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (token !== undefined && token !== null) {
      const decodedToken = JSON.parse(window?.atob(token?.split('.')[1]));
      const email = decodedToken.email;
      setEmail({ emailAdress: email });
      dispatch(Actions.userActions.verifyEmailTokenAction({ token: token }));
    }
  }, [token]);

  useEffect(() => {
    return () => {
      dispatch(Actions.userActions.cleanState(true));
    };
  }, []);

  useEffect(() => {
    if (isSuccess !== '' && validationMessages !== '') {
      setNotifyMesage(
        <span>
          <strong>{validationMessages}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessages]);

  useEffect(() => {
    if (isSuccess && notifyMessage !== '') {
      toast.success(notifyMessage, {
        onClose: () => {
          setNotifyMesage('');
          if (auth === '') {
            navigate('/account/login');
          } else if (auth !== '') {
            navigate(findMyPath('dashboard'));
          }
        },
      });
    } else if (!isSuccess && notifyMessage !== '') {
      toast.error(notifyMessage, {
        onClose: () => {
          dispatch(Actions.userActions.createEmailTokenAction(email));
          setNotifyMesage('');
          if (auth === '') {
            navigate('/account/login');
          } else if (auth !== '') {
            navigate(findMyPath('dashboard'));
          }
        },
      });
    }
  }, [notifyMessage]);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const content = (
    <div className="container my-5 text-start">
      <div className="justify-content-center col-md-6 mx-auto flex-column flex-sm-row mt-5">
        {notifyMessage !== '' && (
          <ToastContainer
            style={customToastStyle}
            limit={1}
            autoClose={false}
            closeOnClick
            theme="light"
          />
        )}
      </div>
    </div>
  );
  return useLoader() ? <Loader /> : token === null ? <Error404 /> : content;
}
