import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { Entities } from '../../../utils/Enum';

const AttendeeNotes = ({ data }) => {
  const { t } = useTranslation();
  const freeTrialNote = useSelector((state) => state.attendee.freeTrialNote);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();

  const headers = [
    { key: 'speakerName', label: t('attendeeNote.speakerName') },
    { key: 'meetingLevel', label: t('attendeeNote.meetingLevel') },
    { key: 'meetingDate', label: t('attendeeNote.meetingDate') },
    { key: 'note', label: t('attendeeNote.note') },
    { key: 'location', label: t('attendeeNote.location') },
  ];

  const cardHeader = [
    {
      key: data?.attendeeName.trim() === '' ? 'phone' : 'attendeeName',
      label: t('attendeeNote.attendeeName'),
    },
    { key: 'managerName', label: t('attendeeList.manager') },
  ];

  const editAction = (val) => {
    navigate('/attendee-update', { state: { id: val.id } });
  };

  const content = (
    <div className="d-flex justify-content-center">
      <div className="col-lg-12">
        <Card
          body={
            <div>
              <div className="col-lg-5">
                <Card
                  body={
                    <>
                      {cardHeader.map((val, i) => (
                        <div
                          key={i}
                          className={`d-flex justify-content-between border-bottom py-1 ${
                            i % 2 ? 'bg-light' : ''
                          }`}
                        >
                          <div className="px-1 fw-bold">{val.label}</div>
                          <div className="px-1">{data[val.key]}</div>
                        </div>
                      ))}
                    </>
                  }
                />
              </div>
              <Table
                data={freeTrialNote === null ? [] : [freeTrialNote]}
                headers={headers}
                striped
                bordered
                page={Entities.attendee}
                edit={(val) => editAction(val)}
                currentUserClaim={currentUserClaim}
                idSearchOnly={true}
                exportName={Entities.attendee.exportName}
              />
            </div>
          }
        />
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.attendee.getAll,
    content,
    Claims.actionClaims.companyAttendee
  );
};
export default AttendeeNotes;
