import React from 'react';
import {
  ButtonSize,
  ButtonType,
  ButtonWidth,
  TextColors,
} from '../../../utils/ComponentEnums';
import { LoaderButton } from '../Loader/LoaderButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SubmitButton = ({
  onClick,
  size,
  bold,
  width,
  id,
  stateName,
  objectLengthRule,
  text,
}) => {
  const { t } = useTranslation();
  const isSuccess = useSelector((state) => state[stateName].isSuccess);

  let className = `btn ${
    isSuccess === null || objectLengthRule
      ? `btn${ButtonType.Secondary}`
      : ButtonType.Successbg
  } ${bold ? 'fw-bold' : ''}`;

  switch (size) {
    case ButtonSize.Small:
      className += ButtonSize.Small;
      break;
    case ButtonSize.Large:
      className += ButtonSize.Large;
      break;
    default:
      break;
  }

  switch (width) {
    case ButtonWidth.S:
      className += ButtonWidth.S;
      break;
    case ButtonWidth.M:
      className += ButtonWidth.M;
      break;
    case ButtonWidth.L:
      className += ButtonWidth.L;
      break;
    default:
      className += ButtonWidth.XL;
      break;
  }

  return (
    <React.Fragment>
      <button
        id={id}
        type="button"
        className={`${className} d-flex align-items-center justify-content-center`}
        onClick={onClick}
        disabled={isSuccess === null || objectLengthRule}
      >
        <span className={TextColors.Light}>
          {objectLengthRule ? (
            t(`${text ? text : 'button.submit'}`)
          ) : isSuccess === null ? (
            <LoaderButton />
          ) : (
            t(`${text ? text : 'button.submit'}`)
          )}
        </span>
      </button>
    </React.Fragment>
  );
};

export default SubmitButton;
