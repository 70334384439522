import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import findMyPath from '../../../hooks/useFindMyPath';

const Result3D = ({ htmlContent }) => {
  const navigate = useNavigate();
  const callBackContent = useSelector((state) => state.payment.callBackContent);
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(decodeHTML(htmlContent));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const form = document.querySelector('form');
      if (form) {
        form.submit();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [content]);

  useEffect(() => {
    if (callBackContent === '') {
      navigate(findMyPath('dashboard'));
    }
  }, [callBackContent, navigate]);

  function decodeHTML(encodedString) {
    return encodedString
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&');
  }
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default Result3D;
