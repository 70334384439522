import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';

import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import addDay from '../../../hooks/useAddDay';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { Entities } from '../../../utils/Enum';

const BackOfficeSpeakerEffortReportDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    navigate(-1);
    dispatch(Actions.speakerEffortActions.cleanState());
  };
  const speakerEffort = useSelector((state) => state.speakerEffort.getById);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const dropdownListSpeaker = useSelector((state) => state.speaker.getAll);
  const speakerId = location?.state?.id;
  const dateFilter = location?.state?.dateFilter;
  const { startDate, endDate, startDateView, endDateView } = dateFilter || {};
  const [filterData, setFilterData] = useState({
    speakerId: speakerId,
    startDate: startDate,
    endDate: endDate,
    startDateView: startDateView,
    endDateView: endDateView,
    location: dateFilter.location,
  });
  const [dateChanged, setDateChanged] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);

  const isSpeaker =
    checkClaims(currentUserClaim, 'speakerAvailability.create') &&
    !checkClaims(currentUserClaim, 'order.get');
  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        dispatch(Actions.speakerEffortActions.getDetailAction(filterData));
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const getList = async () => {
    await dispatch(Actions.speakerActions.getAllAction('Dropdown'));
    await dispatch(Actions.locationActions.getAllAction());
  };
  const onSearch = (e) => {
    e.preventDefault();

    dispatch(Actions.speakerEffortActions.getDetailAction(filterData));
  };
  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };
  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,

        [key]: value,
      }));
      setDateChanged(true);
    }
  }
  const handleDropdown = (propName, value) => {
    resetMe(
      propName,
      propName === 'speakerId' || propName === 'location' ? value : `${value}`
    );
  };

  useEffect(() => {
    if (Object.keys(filterData).length > 4 && dateChanged === false) {
      dispatch(Actions.speakerEffortActions.getDetailAction(filterData));

      setDateChanged(false);
    }
  }, [filterData]);

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
    setDateChanged(true);
  };

  const headers = [
    { key: 'meetingSchemaId', label: t('common.meetingSchemaId') },
    { key: 'meetingId', label: t('common.meetingId') },
    { key: 'date', label: t('speakerEffort.date') },
    { key: 'interval', label: t('speakerEffort.interval') },
    { key: 'minutes', label: t('speakerEffort.minutes') },
    { key: 'amount', label: t('speakerEffort.amount') },
    { key: 'attendeeLimit', label: t('speakerEffort.attemdee_limit') },
    { key: 'area', label: t('speakerEffort.area') },
    { key: 'branch', label: t('speakerEffort.location') },
    { key: 'level', label: t('speakerEffort.level') },
  ];

  useEffect(() => {
    getList();
    return () => {
      dispatch(Actions.speakerEffortActions.cleanState());
    };
  }, []);
  const getSpeakerNameById = (speakerId, speakerList) => {
    const speaker = speakerList.find(
      (speaker) => speaker.speakerId === speakerId
    );
    return speaker ? speaker.speakerName : 'Speaker Not Found';
  };

  const unPayMeeting = async (data) => {
    const myData = { meetingId: data.meetingId, meetingStatus: 5 };
    await dispatch(Actions.speakerEffortActions.unPayMeetingAction(myData));
    getList();
    dispatch(Actions.speakerEffortActions.getDetailAction(filterData));
  };

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
        <div className="col-2 mt-sm-3">
          <Button
            type={ButtonType.Successbg}
            icon={ButtonIcon.ArrowLeft}
            iconColor={TextColors.Light}
            bold
            onClick={goBack}
            buttonCol={3}
          />
        </div>
      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <HeadTags
          hSize={1}
          text={t('speakerEffort.speaker_effort_etails')}
          strong
        />
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <Button
                    text={t('cardHeaderFilter.search')}
                    icon={faSearch}
                    type={
                      Object.keys(filterData).length >= 1
                        ? ButtonType.Successbg
                        : ButtonType.Secondary
                    }
                    iconColor={TextColors.Light}
                    textColor={TextColors.Light}
                    iconSide
                    bold
                    size={ButtonSize.Small}
                    buttonCol={!mobileView ? 1 : 3}
                    onClick={onSearch}
                    disabled={
                      Object.keys(filterData).length >= 1 ? false : true
                    }
                  />
                </div>
                <div className="row my-2">
                  {isSpeaker ? null : (
                    <div className="col-3">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_speaker')}
                        options={dropdownListSpeaker}
                        selectedProps={{
                          key: 'speakerName',
                          value: 'speakerId',
                          searchKey: 'speakerId',
                        }}
                        selectedValues={filterData.speakerId}
                        onChange={(value) => {
                          handleDropdown('speakerId', value);
                        }}
                        onReset={() => handleResetDropdown('speakerId')}
                        isfilter
                      />
                    </div>
                  )}

                  <div className={`${isSpeaker ? 'col-4' : 'col-3'}`}>
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className={`${isSpeaker ? 'col-4' : 'col-3'}`}>
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className={`${isSpeaker ? 'col-4' : 'col-3'}`}>
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedValues={filterData.location}
                      selectedProps={{
                        key: 'locationName',
                        value: 'id',
                        searchKey: 'id',
                      }}
                      onChange={(value) => handleDropdown('location', value)}
                      onReset={() => handleResetDropdown('location')}
                      isfilter
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={speakerEffort}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.speakerPayment}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.speakerPayment.exportName.effortDetials}
                  unPayMeeting={(val) => unPayMeeting(val)}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.effort,
    content
  );
};
export default BackOfficeSpeakerEffortReportDetails;
