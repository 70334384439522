import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import Actions from '../../../store/redux/actions';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Claims from '../../../utils/Claims';
import { ButtonType } from '../../../utils/ComponentEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faHeartPulse,
  faFilePen,
  faHeadphones,
  faGear,
  faPenToSquare,
  faMinus,
  faEllipsis,
  faCommenting,
  faUserFriends,
  faUserEdit,
  faCalendarWeek,
  faNetworkWired,
  faCalendarCheck,
  faGifts,
  faListOl,
  faCalendarDays,
  faPeopleGroup,
  faChalkboardTeacher,
  faSignOut,
  faChartLine,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { faJediOrder, faTiktok } from '@fortawesome/free-brands-svg-icons';
import dashboardSVG from '../../../assets/images/dashboard.svg';
import styles from '../Sidebar/Sidebar.module.scss';
import Feedback from '../../../pages/Landing/Feedback';
import useClickOutside from '../../../hooks/useClickOutside';
import checkClaims from '../../../hooks/useCheckClaims';
import checkGroupClaims from '../../../hooks/useCheckGroupClaims';
import findMyPath from '../../../hooks/useFindMyPath';

const NavUrl = ({ url, icon, description, target, func }) => {
  if (url === undefined) {
    return (
      <li className={styles.li_navlink}>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeMe : undefined)}
          onClick={func}
        >
          {icon}
          <span className={styles.description}>{description}</span>
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className={styles.li_navlink}>
        <NavLink
          to={`${url}`}
          className={({ isActive }) => (isActive ? styles.active : undefined)}
          target={target}
          onClick={func}
        >
          {icon}
          <span className={styles.description}>{description}</span>
        </NavLink>
      </li>
    );
  }
};
const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navbar = useSelector((state) => state.screen.size);
  const nav = useSelector((state) => state.screen.setted);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserCompanyId = userInfo.companyId;
  const [menuOpen, setMenuOpen] = useState({});
  const [feedbackOn, setFeedbackOn] = useState(false);
  const isM2TManager =
    checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) &&
    currentUserCompanyId === 1;
  let domNode = useClickOutside(() => {
    if (navbar === true) {
      dispatch(Actions.screenActions.checkScreenSize(!navbar));
    }
  });
  const setSize = async () => {
    if (currentUserClaim?.length === 0) {
      dispatch(Actions.screenActions.checkScreenSize(!navbar));
    }
  };

  async function handleMenus(e) {
    e.preventDefault();
    const { id } = e.currentTarget;
    setMenuOpen({ [id]: true });
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmation = (confirm) => {
    if (confirm) {
      signOut();
    } else {
      setIsOpen(false);
    }
  };

  const signOut = async () => {
    dispatch(Actions.authActions.logoutAction());
    setIsOpen(false);
    navigate('/');
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const UIContent = (
    <React.Fragment>
      {(userInfo.companyId !== 1 || userInfo.userArea !== 3) && (
        <React.Fragment>
          {userInfo.userArea !== 3 && (
            <NavUrl
              url={findMyPath('speakers')}
              icon={<FontAwesomeIcon icon={faPeopleGroup} />}
              description={t('sidebar.mentors')}
            />
          )}
          <NavUrl
            url={findMyPath('schedule')}
            icon={<FontAwesomeIcon icon={faCalendarDays} />}
            description={t('sidebar.schedule')}
          />
          <NavUrl
            url={findMyPath('sessions')}
            icon={<FontAwesomeIcon icon={faChalkboardTeacher} />}
            description={t('sidebar.sessions')}
          />
          {userInfo.userArea !== 3 && (
            <NavUrl
              url={findMyPath('order/package')}
              icon={<FontAwesomeIcon icon={faCalendarCheck} />}
              description={t('sidebar.subscription')}
            />
          )}
          <NavUrl
            url={findMyPath('orders')}
            icon={<FontAwesomeIcon icon={faListOl} />}
            description={t('sidebar.orders')}
          />
          {userInfo.userArea !== 3 && (
            <NavUrl
              url={'/gift-code'}
              icon={<FontAwesomeIcon icon={faGifts} />}
              description={t('headerMyProfile.gift_code')}
            />
          )}
        </React.Fragment>
      )}
      <NavUrl
        url={'https://blog.meet2talk.online/'}
        icon={<FontAwesomeIcon icon={faFilePen} />}
        description={t('sidebar.blog')}
        target={'_blank'}
      />
      <NavUrl
        icon={<FontAwesomeIcon icon={faCommenting} />}
        description={t('feedbackNavbar.feedback')}
        func={() => setFeedbackOn(true)}
      />
      <NavUrl
        url={findMyPath('tiktok')}
        icon={<FontAwesomeIcon icon={faTiktok} />}
        description={t('sidebar.tiktok')}
      />
      <NavUrl
        url="/support"
        icon={<FontAwesomeIcon icon={faHeadphones} />}
        description={t('sidebar.support')}
      />
    </React.Fragment>
  );
  const SpeakerContent = (
    <React.Fragment>
      <NavUrl
        url={'/speaker-update'}
        icon={<FontAwesomeIcon icon={faUserEdit} />}
        description={t('sidebar.speaker_information')}
      />
      <NavUrl
        url={'/availability-calendar'}
        icon={<FontAwesomeIcon icon={faCalendarDays} />}
        description={t('sidebar.availability_calendar')}
      />
      <NavUrl
        url="/backoofice-chatroom"
        icon={<FontAwesomeIcon icon={faChalkboardTeacher} />}
        description={t('Sessions Chat')}
      />
      <NavUrl
        url="/meeting-schedule"
        icon={<FontAwesomeIcon icon={faCalendarCheck} />}
        description={t('sidebar.active_meeting_schemas')}
      />
      <NavUrl
        url="/attendee-list"
        icon={<FontAwesomeIcon icon={faPeopleGroup} />}
        description={t('sidebar.attendees')}
      />
      {checkClaims(currentUserClaim, Claims.backofficeClaims.speaker.effort) ? (
        <NavUrl
          url="/speaker-effort-report"
          icon={<FontAwesomeIcon icon={faChartLine} />}
          description={t('sidebar.effort_report')}
        />
      ) : undefined}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.career.getAll) ? (
        <NavUrl
          url="/career-list"
          icon={<FontAwesomeIcon icon={faNetworkWired} />}
          description={t('sidebar.career')}
        />
      ) : undefined}
    </React.Fragment>
  );
  const BOContent = (
    <React.Fragment>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.speaker.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/speaker-list"
            icon={<FontAwesomeIcon icon={faPeopleGroup} />}
            description={t('sidebar.speakers')}
          />
        </button>
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.attendee.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/attendee-list"
            icon={<FontAwesomeIcon icon={faPeopleGroup} />}
            description={t('sidebar.attendees')}
          />
        </button>
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.manager.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/manager-list"
            icon={<FontAwesomeIcon icon={faPeopleGroup} />}
            description={t('sidebar.managers')}
          />
        </button>
      )}
      <div className="sidebar-headers backoffice-sidebar-headers">
        <h5 className="d-flex align-items-center my-2">
          {!nav ? (
            <FontAwesomeIcon icon={faEllipsis} />
          ) : (
            <strong>{t('sidebar.operation')}</strong>
          )}
        </h5>
      </div>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.calendar.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/meeting-schedule"
            icon={<FontAwesomeIcon icon={faCalendarCheck} />}
            description={t('sidebar.active_meeting_schemas')}
          />
        </button>
      )}
      {checkGroupClaims(currentUserClaim, Claims.headClaims.manager) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/operations"
            icon={<FontAwesomeIcon icon={faHeartPulse} />}
            description={t('sidebar.operations')}
          />
        </button>
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.calendar.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/monthly-schedule"
            icon={<FontAwesomeIcon icon={faCalendarWeek} />}
            description={t('sidebar.speaker_schedule')}
          />
        </button>
      )}
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.meetingSchema.get
      ) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/meeting-schema-list"
            icon={<FontAwesomeIcon icon={faCalendarWeek} />}
            description={t('meetingSchema.meeting_schema')}
          />
        </button>
      )}
      {checkGroupClaims(currentUserClaim, Claims.headClaims.order) && (
        <span
          id="orderMenu"
          data-bs-toggle="collapse"
          href="#orders"
          role="button"
          className="btn btn-sm text-start"
          aria-expanded="true"
          aria-controls="orders"
          onClick={handleMenus}
        >
          <NavUrl
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('order.order')}
          />
        </span>
      )}
      <div
        className={`collapse ${menuOpen.orderMenu ? '' : 'hide'}`}
        id="orders"
      >
        <div className="card card-body m-0 p-0">
          <NavUrl
            url="/order-list"
            icon={
              <FontAwesomeIcon style={{ fontSize: '0.5rem' }} icon={faMinus} />
            }
            description={t('order.order_list')}
          />
          {checkClaims(
            currentUserClaim,
            Claims.backofficeClaims.order.accountingDept
          ) ? (
            <NavUrl
              url="/order-payments"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.payments')}
            />
          ) : undefined}
          {checkClaims(
            currentUserClaim,
            Claims.backofficeClaims.order.accountingDept
          ) ? (
            <NavUrl
              url="/eft-payments"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.eft_control')}
            />
          ) : undefined}
          {checkClaims(currentUserClaim, Claims.actionClaims.orderReports) ? (
            <NavUrl
              url="/remaining-order-calculation"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.remaining_order_calculation')}
            />
          ) : undefined}
        </div>
      </div>
      {checkGroupClaims(currentUserClaim, Claims.headClaims.order) && (
        <span
          id="trialsMenu"
          data-bs-toggle="collapse"
          href="#trials"
          role="button"
          className="btn btn-sm text-start"
          aria-expanded="true"
          aria-controls="trials"
          onClick={handleMenus}
        >
          <NavUrl
            icon={<FontAwesomeIcon icon={faJediOrder} />}
            description={t('trials')}
          />
        </span>
      )}
      <div
        className={`collapse ${menuOpen.trialsMenu ? '' : 'hide'}`}
        id="trials"
      >
        <div className="card card-body m-0 p-0">
          {checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) ? (
            <NavUrl
              url="/created-today-trials"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('order.todaysTrials')}
            />
          ) : undefined}
          {checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) ? (
            <NavUrl
              url="/upcoming-trials"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('order.upcomingTrials')}
            />
          ) : undefined}
        </div>
      </div>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.company.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/company-list"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('company.companies')}
          />
        </button>
      )}
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.branchFreeze.get
      ) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/branch-holiday-list"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.branch_holiday')}
          />
        </button>
      )}
      {checkClaims(currentUserClaim, Claims.backofficeClaims.career.get) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/career-list"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.career')}
          />
        </button>
      )}
      {checkClaims(currentUserClaim, 'speaker.effort') && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/speaker-effort-report"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.effort_report')}
          />
        </button>
      )}
      {(checkClaims(currentUserClaim, Claims.backofficeClaims.banner.getAll) ||
        checkClaims(
          currentUserClaim,
          Claims.backofficeClaims.campaign.getAll
        ) ||
        checkClaims(
          currentUserClaim,
          Claims.backofficeClaims.couponCodes.getAll
        )) && (
        <span
          id="campaignMenu"
          data-bs-toggle="collapse"
          href="#campaigns"
          role="button"
          className="btn btn-sm text-start"
          aria-expanded="true"
          aria-controls="campaigns"
          onClick={handleMenus}
        >
          <NavUrl
            icon={<FontAwesomeIcon icon={faTags} />}
            description={t('sidebar.campaign')}
          />
        </span>
      )}
      <div
        className={`collapse ${menuOpen.campaignMenu ? '' : 'hide'}`}
        id="campaigns"
      >
        <div className="card card-body m-0 p-0">
          {checkClaims(
            currentUserClaim,
            Claims.backofficeClaims.banner.getAll
          ) && (
            <NavUrl
              url="/banner-list"
              icon={
                <FontAwesomeIcon
                  icon={faMinus}
                  style={{ fontSize: '0.5rem' }}
                />
              }
              description={t('sidebar.banner')}
            />
          )}
          {checkClaims(
            currentUserClaim,
            Claims.backofficeClaims.campaign.getAll
          ) && (
            <NavUrl
              url="/campaign-list"
              icon={
                <FontAwesomeIcon
                  icon={faMinus}
                  style={{ fontSize: '0.5rem' }}
                />
              }
              description={t('sidebar.campaign')}
            />
          )}
          {checkClaims(
            currentUserClaim,
            Claims.backofficeClaims.couponCodes.getAll
          ) && (
            <NavUrl
              url="/coupon-code-list"
              icon={
                <FontAwesomeIcon
                  icon={faMinus}
                  style={{ fontSize: '0.5rem' }}
                />
              }
              description={t('sidebar.coupon_codes')}
            />
          )}
        </div>
      </div>
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.mailSubscription.get
      ) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/call-them"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.call_them')}
          />
        </button>
      )}
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.mailSubscription.get
      ) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/lead-list"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('lead.list')}
          />
        </button>
      )}
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.feedback.getAll
      ) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/feedback-list"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.feedback')}
          />
        </button>
      )}
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.manager.create
      ) && (
        <button className="btn btn-sm text-start">
          <NavUrl
            url="/reporting-list"
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.reporting')}
          />
        </button>
      )}
      {checkGroupClaims(currentUserClaim, Claims.headClaims.ageInterval) && (
        <span
          id="preferenceMenu"
          data-bs-toggle="collapse"
          href="#preference"
          role="button"
          className="btn btn-sm text-start"
          aria-expanded="true"
          aria-controls="preference"
          onClick={handleMenus}
        >
          <NavUrl
            icon={<FontAwesomeIcon icon={faPenToSquare} />}
            description={t('sidebar.preferences')}
          />
        </span>
      )}
      <div
        className={`collapse ${menuOpen.preferenceMenu ? '' : 'hide'}`}
        id="preference"
      >
        <div className="card card-body m-0 p-0">
          {checkGroupClaims(
            currentUserClaim,
            Claims.headClaims.ageInterval
          ) && (
            <NavUrl
              url="/age-interval-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.age_interval_list')}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.location) && (
            <NavUrl
              url="/location-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.location_list')}
            />
          )}
          {checkGroupClaims(
            currentUserClaim,
            Claims.headClaims.sessionCategory
          ) && (
            <NavUrl
              url="/session-category-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.session_category_list')}
            />
          )}
          {checkGroupClaims(
            currentUserClaim,
            Claims.headClaims.sessionPackage
          ) && (
            <NavUrl
              url="/session-package-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.session_package_list')}
            />
          )}
          {checkGroupClaims(
            currentUserClaim,
            Claims.headClaims.timesPerMonth
          ) && (
            <NavUrl
              url="/times-per-month-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.times_per_month_list')}
            />
          )}
          {checkGroupClaims(
            currentUserClaim,
            Claims.headClaims.timesPerWeek
          ) && (
            <NavUrl
              url="/times-per-week-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.times_per_week_list')}
            />
          )}
          {checkGroupClaims(currentUserClaim, Claims.headClaims.zone) && (
            <NavUrl
              url="/zone-list"
              icon={
                <FontAwesomeIcon
                  style={{ fontSize: '0.5rem' }}
                  icon={faMinus}
                />
              }
              description={t('sidebar.preferences.zone_list')}
            />
          )}
        </div>
      </div>
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.package.create
      ) && (
        <div className="mt-3">
          <NavUrl
            url="/test-price"
            icon={<FontAwesomeIcon icon={faGear} />}
            description={'Price Test'}
          />
        </div>
      )}
    </React.Fragment>
  );
  const CompanyContent = (
    <React.Fragment>
      <NavUrl
        url="/attendee-list"
        icon={<FontAwesomeIcon icon={faUserFriends} />}
        description={t('sidebar.attendees')}
      />
      <NavUrl
        url="/meeting-schedule"
        icon={<FontAwesomeIcon icon={faCalendarWeek} />}
        description={t('sidebar.active_meeting_schemas')}
      />
    </React.Fragment>
  );
  const content = (
    <div className="sidebar">
      <div
        className={` ${styles.navbar_container} ${
          navbar === true ? styles.navbar_mobile_active : undefined
        }`}
        style={{
          height:
            currentUserClaim?.length === 0
              ? '100vh'
              : isMobile
              ? '100vh'
              : '200vh',
        }}
      >
        <nav ref={domNode} className={nav ? undefined : styles.nav_small}>
          {/* LOGO */}
          <Link className="logo mt-2" to={findMyPath('dashboard')}>
            {nav ? (
              <img
                className="img"
                src="/images/meet2talk-logo.svg"
                alt="Meet2Talk Logo"
                width="100%"
                style={{ maxWidth: '200px' }}
              />
            ) : (
              <img
                className="img"
                src="/images/meet2talk-icon.svg"
                alt="Alternate Logo"
                width="100%"
              />
            )}
          </Link>
          {/* MENU */}
          <ul className="mt-5" onClick={setSize}>
            <NavUrl
              url={findMyPath('dashboard')}
              icon={<img src={dashboardSVG} alt="dashboardSVG" />}
              description={t('sidebar.dashboard')}
            />
            {currentUserClaim?.length === 0
              ? UIContent
              : checkClaims(currentUserClaim, 'speakerAvailability.create') &&
                !checkClaims(currentUserClaim, 'order.get')
              ? SpeakerContent
              : isM2TManager
              ? BOContent
              : CompanyContent}

            <li
              role={'button'}
              className={styles.li_navlink}
              onClick={() => setIsOpen(true)}
            >
              <a
                id="logOut"
                href="#/"
                role="button"
                className="btn btn-default text-start"
              >
                <FontAwesomeIcon icon={faSignOut} />
                <label className={styles.description} role={'button'}>
                  {t('sidebar.logout')}
                </label>
              </a>
            </li>
          </ul>

          {/* LOGOUT BUTTON */}
          <div
            className={`${styles.btn_logout}`}
            style={{
              top:
                currentUserClaim?.length === 0 ? '8%' : isMobile ? '8%' : '4%',
            }}
            onClick={() => {
              isMobile && nav === true
                ? dispatch(Actions.screenActions.checkScreenSize(!navbar))
                : dispatch(Actions.screenActions.setScreenSize(!nav));
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </nav>
      </div>
      <Modal
        isVisible={isOpen === true ? true : false}
        styleProps={{ maxWidth: 550 }}
        title={t('sidebar.are_you_sure')}
        content={
          <div className="d-flex justify-content-evenly">
            <div className="mx-2 w-50">
              <Button
                text={t('button.yes')}
                type={ButtonType.Danger}
                outline
                bold
                onClick={() => handleConfirmation(true)}
                buttonCol={12}
              />
            </div>
            <div className="mx-2 w-50">
              <Button
                text={t('button.no')}
                type={ButtonType.Success}
                outline
                bold
                onClick={() => handleConfirmation(false)}
                buttonCol={12}
              />
            </div>
          </div>
        }
        onClose={() => handleConfirmation(false)}
      />
      <Modal
        isVisible={feedbackOn}
        title={t('feedback.form_header')}
        titleMS={2}
        styleProps={{ maxWidth: 450, borderRadius: 15 }}
        content={<Feedback handleModal={(val) => setFeedbackOn(val)} />}
        onClose={() => setFeedbackOn(false)}
      />
    </div>
  );

  return content;
};

export default Sidebar;
