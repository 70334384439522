import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useClickOutside from '../../../../hooks/useClickOutside';
import styles from './MyProfile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOut,
  faCogs,
  faUser,
  faGift,
  faExchange,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../store/redux/actions';
import Dropdown from '../../Dropdown/Dropdown';
import { BackGroundColors, TextColors } from '../../../../utils/ComponentEnums';
import meet2talkButton from '../../../../assets/images/meet2talk_button.svg';
import ButtonIconText from '../../Button/ButtonIconText';
import online from '../../../../assets/images/meet2talk_button.svg';
import cafe from '../../../../assets/images/cafe.svg';
import kids from '../../../../assets/images/kids.svg';
import company from '../../../../assets/images/marketing.svg';
import BasicConfirmModal from '../../Modal/BasicConfirmModal';
import { BasicConfirmModalActions } from '../../../../utils/Enum';

const MyProfile = () => {
  const confirmModalRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const userArea = useSelector((state) => state.staticData.UserArea);
  const userLang = useSelector((state) => state.lang.language);
  const basketStatus = useSelector((state) => state.order.isEmpty);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const landingOrder = useSelector(
    (state) => state.userPackage.isPackageComplete
  );
  const [firstSvg, setFirstSvg] = useState();
  const [firstBackgroundColor, setFirstBackgroundColor] = useState();

  const handleConfirmation = (confirm) => {
    if (confirm) {
      signOut();
    }
  };

  const signOut = async () => {
    navigate('/');
    await dispatch(Actions.authActions.logoutAction());
  };
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  let domNode = useClickOutside(() => {
    setIsProfileOpen(false);
  });

  const [first, setFirst] = useState();
  const [changeMenu, setChangeMenu] = useState({});
  const handleSelectChange = async (target) => {
    if (userInfo.userArea !== parseInt(target.value)) {
      setFirst(t(`${target.id}`));
      dispatch(Actions.resetStoreAction.resetStore());
      dispatch(Actions.orderActions.selectSessionReducer([], ''));
      dispatch(Actions.filterActions.resetStateAction());
      dispatch(
        Actions.userActions.selfUpdateAction(
          { userArea: parseInt(target.value) },
          'UserArea'
        )
      );
      dispatch(Actions.onboardActions.onboardType(parseInt(target.value)));
      const myPath = target.lang;
      await navigate(`${myPath}/dashboard`);
    }
  };
  useEffect(() => {
    switch (userInfo.userArea) {
      case 0:
        setFirst(t('UserArea.0'));
        setFirstSvg(online);
        setFirstBackgroundColor(BackGroundColors.DashboardOnline);
        break;
      case 1:
        setFirst(t('UserArea.1'));
        setFirstSvg(cafe);
        setFirstBackgroundColor(BackGroundColors.DashboardCafe);
        break;
      case 2:
        setFirst(t('UserArea.2'));
        setFirstSvg(kids);
        setFirstBackgroundColor(BackGroundColors.DashboardKids);
        break;
      case 3:
        setFirst(t('UserArea.3'));
        setFirstSvg(company);
        setFirstBackgroundColor(BackGroundColors.DashboardCompany);
        break;
      default:
        break;
    }
  }, [userInfo.userArea, userLang]);

  useEffect(() => {
    if (
      userInfo?.id !== undefined &&
      basketStatus === '' &&
      lengthCurrentUserClaim &&
      !landingOrder
    ) {
      dispatch(Actions.orderActions.getBasketItemsAction(userInfo.userArea));
    }
  }, [userInfo]);

  const handleMenuHover = (e) => {
    setChangeMenu({ [e.target.id]: true });
  };
  const handleMenuHoverMobile = (e, to = undefined) => {
    setChangeMenu({ [e.target.id]: true });
    if (mobileView) {
      if (to !== undefined) {
        setIsProfileOpen(false);
        navigate(to);
      }
    }
  };
  const handleSelectMenu = () => {
    setIsProfileOpen(false);
  };
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className={styles.avatar_container} role="button">
      <BasicConfirmModal
        ref={confirmModalRef}
        title={t('sidebar.are_you_sure')}
        text={t('sidebar.are_you_sure')}
        onClose={(e) => {
          handleConfirmation(false);
          setIsProfileOpen(false);
        }}
        onSave={(e) => handleConfirmation(true)}
        type={BasicConfirmModalActions.logOut}
      />
      {/* AVATAR ICON */}
      <div
        ref={domNode}
        className={styles.icon_avatar_container}
        onClick={() => {
          setIsProfileOpen(!isProfileOpen);
          setChangeMenu({});
        }}
      >
        {userInfo?.userImage ? (
          <img
            className={'rounded-circle me-3'}
            src={userInfo?.userImage}
            alt={userInfo?.userImage}
            width={32}
            height={32}
          />
        ) : (
          <FontAwesomeIcon className="fa-2xl me-3" icon={faCircleUser} />
        )}
      </div>
      {/* NAME */}
      {lengthCurrentUserClaim && (
        <Dropdown
          name={'Onboard_Type'}
          options={userArea}
          id={'Onboard_Type'}
          title={first}
          rounded={2}
          backGroundAll={firstBackgroundColor}
          propNames={{
            key: 'name',
            value: 'value',
            name: 'key',
            path: 'path',
          }}
          handleChange={(target) => handleSelectChange(target)}
          svgWidth={mobileView ? 25 : 40}
          svg={firstSvg}
          textColor={TextColors.Light}
          rightArrowUp
        />
      )}
      {/* AVATAR/SETTINGS SUBMENU */}
      <div
        ref={domNode}
        className={`${styles.menu} ${isProfileOpen ? styles.menu_active : ''}`}
      >
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0">
            <div className={styles.icon_avatar_container}>
              {userInfo?.userImage ? (
                <img
                  className={' rounded-circle'}
                  src={userInfo?.userImage}
                  alt={userInfo?.userImage}
                  width={32}
                  height={32}
                />
              ) : (
                <FontAwesomeIcon className="fa-2xl mt-2" icon={faCircleUser} />
              )}
            </div>
          </div>
          <div className="flex-grow-1 ms-3">
            <h6
              className={`fw-bold text-nowrap`}
              style={{ fontSize: '0.7rem' }}
            >
              {userInfo?.firstName + ' ' + userInfo?.lastName}
            </h6>
            <h6 className={`fw-bold`} style={{ fontSize: '0.7rem' }}>
              {userInfo?.phone}
            </h6>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-1"></div>
          <div className="col border-bottom border-1"></div>
          <div className="col-1"></div>
        </div>
        <div className="container" style={{ zIndex: '1000' }}>
          <div className={'row mt-3 text-muted'}>
            {changeMenu.personalInformation === undefined ? (
              <div
                id={'personalInformation'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) =>
                  handleMenuHoverMobile(e, 'profile/personal-information')
                }
                className=""
              >
                <FontAwesomeIcon
                  className="subMenuIcon subMenuIcon2"
                  icon={faUser}
                />
                <label
                  id={'personalInformation'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) =>
                    handleMenuHoverMobile(e, 'profile/personal-information')
                  }
                  className="subMenuText"
                >
                  {t('headerMyProfile.personal_information')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={faUser}
                rounded={2}
                title={t('headerMyProfile.personal_information')}
                to={'profile/personal-information'}
                onClick={() => handleSelectMenu()}
              />
            )}
            {/* {!lengthCurrentUserClaim ? undefined : changeMenu.giftCode ===
              undefined ? (
              <div
                id={'giftCode'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) => handleMenuHoverMobile(e, 'gift-code')}
                className="mt-2"
              >
                <FontAwesomeIcon
                  className="subMenuIcon subMenuIcon2"
                  icon={faGift}
                />
                <label
                  id={'giftCode'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) => handleMenuHoverMobile(e, 'gift-code')}
                  className="subMenuText"
                >
                  {t('headerMyProfile.gift_code')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={faGift}
                rounded={2}
                title={t('headerMyProfile.gift_code')}
                to={'gift-code'}
                onClick={() => handleSelectMenu()}
              />
            )} */}
            {!lengthCurrentUserClaim ? undefined : changeMenu.settings ===
              undefined ? (
              <div
                id={'settings'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) =>
                  handleMenuHoverMobile(e, 'profile/settings')
                }
                className="mt-2"
              >
                <FontAwesomeIcon
                  className="subMenuIcon subMenuIcon2"
                  icon={faCogs}
                />
                <label
                  id={'settings'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) =>
                    handleMenuHoverMobile(e, 'profile/settings')
                  }
                  className="subMenuText"
                >
                  {t('headerMyProfile.settings')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={faCogs}
                rounded={2}
                title={t('headerMyProfile.settings')}
                to={'profile/settings'}
                onClick={() => handleSelectMenu()}
              />
            )}
            {changeMenu.changePassword === undefined ? (
              <div
                id={'changePassword'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) =>
                  handleMenuHoverMobile(e, 'profile/change-password')
                }
                className="mt-2"
              >
                <FontAwesomeIcon
                  className="subMenuIcon subMenuIcon2	"
                  icon={faExchange}
                />
                <label
                  id={'changePassword'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) =>
                    handleMenuHoverMobile(e, 'profile/change-password')
                  }
                  className="subMenuText"
                >
                  {t('headerMyProfile.change_password')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={faExchange}
                rounded={2}
                title={t('headerMyProfile.change_password')}
                to={'profile/change-password'}
                onClick={() => handleSelectMenu()}
              />
            )}
            {changeMenu.logOut === undefined ? (
              <div
                id={'logOut'}
                onMouseEnter={(e) => handleMenuHover(e)}
                onTouchStart={(e) => {
                  e.stopPropagation();
                  confirmModalRef.current.open();
                }}
                className="mt-2"
              >
                <FontAwesomeIcon
                  className="subMenuIcon subMenuIcon2"
                  icon={faSignOut}
                />
                <label
                  id={'logOut'}
                  onMouseEnter={(e) => handleMenuHover(e)}
                  onTouchStart={(e) => {
                    e.stopPropagation();
                    confirmModalRef.current.open();
                  }}
                  className="subMenuText"
                >
                  {t('headerMyProfile.log_out')}
                </label>
              </div>
            ) : (
              <ButtonIconText
                icon={faSignOut}
                rounded={2}
                title={t('headerMyProfile.log_out')}
                onClick={() => confirmModalRef.current.open()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
