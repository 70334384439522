import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import Toast from '../../../components/Common/Popup/Toast';
import { Entities } from '../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const feedbackList = useSelector((state) => state.feedback.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.feedback.getAll)
    ) {
      dispatch(Actions.feedbackActions.getAllAction());
    }
  };

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'email', label: t('common.email') },
    { key: 'message', label: t('feedback.message') },
    { key: 'image', label: t('feedback.screenshot') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'deletedBy', label: t('common.deleted_by') },
    { key: 'deletedAt', label: t('common.deleted_at') },
  ];

  const removeAction = (id) => {
    dispatch(Actions.feedbackActions.removeAction(id));
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.feedbackActions.cleanState(true));
    };
  }, []);
  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags hSize={1} text={t('feedback.list')} strong />
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  data={feedbackList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.feedBack}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  idSearchOnly={true}
                  exportName={Entities.feedBack.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'feedback'}
        onClose={() => {
          getList();
        }}
      />
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.feedback.getAll,
    content
  );
};
export default List;
