import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  RegexType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPhone } from '../../../hooks/useFormatPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { countryListAllIsoData } from '../../../utils/Enum';
import { getNames } from 'i18n-iso-countries';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const managerId = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const lang = useSelector((state) => state.lang.language);
  const isSuccess = useSelector((state) => state.manager.isSuccess);
  const validationMessageManager = useSelector(
    (state) => state.manager.validationMessage
  );
  const managerData = useSelector((state) => state.manager.manager);
  const companyList = useSelector((state) => state.company.getAll);
  const locations = useSelector((state) => state.location.getAll);
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const [imgSrc, setImgSrc] = useState('');
  const goBack = () => {
    navigate(-1);
    dispatch(Actions.managerActions.cleanState());
  };
  const redirect = '/manager-list';
  const [handleError, setHandleError] = useState('');
  const [error, setError] = useState({});

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.managerActions.cleanState(true));
          setImgSrc('');
          setUpdateData({});
          setHandleError('');
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessageManager !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessageManager}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessageManager !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessageManager}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessageManager]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (value !== '') {
      setUpdateData({ ...updateData, [id]: value });
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
    if (value) {
      setDataChanged(false);
    }
  };
  const handleDropdown = (propName, value) => {
    setUpdateData({ ...updateData, [propName]: value });
    setDataChanged(false);
    dispatch(
      Actions.managerActions.holdValue({
        ...updateData,
        [propName]: value,
      })
    );
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...managerData });
    } else {
      dispatch(Actions.managerActions.holdValue({ ...updateData }));
    }
  };
  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...managerData });
    }
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phone);
      setUpdateData({
        ...updateData,
        phone: cleanValue,
      });
    }
  };
  useEffect(() => {
    if (updateData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, updateData?.phone);
      setUpdateData({
        ...updateData,
        phone: cleanValue,
      });
    }
  }, [updateData?.phone, error?.phone]);

  const onSave = async (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length > 0) {
      await dispatch(
        Actions.managerActions.updateAction(updateData, managerId)
      );
    }
  };

  async function FetchById() {
    await dispatch(Actions.managerActions.getByIdAction(managerId));
  }

  useEffect(() => {
    FetchById();
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.companyActions.dropdownListAction());
    return () => {
      dispatch(Actions.managerActions.cleanState(true));
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(managerData).length > 0 &&
      Object.keys(updateData).length === 0
    ) {
      setUpdateData({ ...managerData });
    }
  }, [managerData]);

  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });
  useEffect(() => {
    setCountryList(countryNames);
  }, []);

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const isUpdateAble =
    Object.values(error).every((e) => e !== undefined) &&
    Object.keys(error).length > 0;

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags hSize={5} strong text={t('manager.update_form')} />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {updateData.image || imgSrc ? (
                        <img
                          className={'img-fluid rounded me-1'}
                          src={imgSrc ? imgSrc : updateData.image}
                          alt={updateData.image}
                          height={200}
                          style={{ maxHeight: '200px' }}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCircleUser} size="10x" />
                      )}
                    </div>
                    <CustomFileInput
                      id={'image'}
                      key={'image'}
                      hidden={true}
                      buttonText={t('personalInformation.upload_picture')}
                      textColor={TextColors.Light}
                      removeState={imgSrc === '' ? true : false}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      dataFrom={'Profile'}
                      func={(val) => {
                        if (!val) {
                          setUpdateData({});
                          setImgSrc('');
                          setDataChanged(false);
                          const { extension, ...userDataWithoutExtension } =
                            managerData;
                          dispatch(
                            Actions.managerActions.holdValue({
                              ...userDataWithoutExtension,
                              image: '',
                            })
                          );
                        } else {
                          setUpdateData(
                            Object.assign({}, managerData, {
                              image: val.base64String,
                              imgExtension: val.extension,
                            })
                          );
                          setImgSrc(val.imgSrc);
                          setDataChanged(false);
                          dispatch(
                            Actions.managerActions.holdValue(
                              Object.assign({}, managerData, {
                                image: val.base64String,
                                imgExtension: val.extension,
                              })
                            )
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mt-4">
                    <div className="row">
                      <div className="col-md-6">
                        <InputLabelOnBorder
                          id={'firstName'}
                          inputType={InputType.Text}
                          labelText={t('common.name')}
                          inputValue={updateData.firstName}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.firstName}
                          regexType={RegexType.Name}
                        />
                      </div>
                      <div className="col-md-6">
                        <InputLabelOnBorder
                          id={'lastName'}
                          inputType={InputType.Text}
                          labelText={t('common.lastName')}
                          inputValue={updateData.lastName}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.lastName}
                          regexType={RegexType.Name}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          id={'email'}
                          inputType={InputType.Email}
                          labelText={t('common.email')}
                          inputValue={updateData.email}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.email}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('manager.table_branch')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: locations.map((item) => ({
                              key: item.locationName,
                              value: item.id,
                              name: 'locations',
                            })),
                            selectedValue: updateData.locations,
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              locations: val.value,
                            });
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('becomeAMentor.country_code')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: countryList.map((item) => ({
                              key: `${item.name} (${item.phone})`,
                              value: `${item.phone} ${item.isoName}`,
                              name: 'countryCode',
                            })),
                            selectedValue: updateData.countryCode,
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              [val.name]: val.value,
                            });
                            dispatch(
                              Actions.managerActions.holdValue({
                                ...updateData,
                                [val.name]: val.value,
                              })
                            );
                            setDataChanged(false);
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          id={'phone'}
                          inputType={InputType.Tel}
                          labelText={t('common.phone')}
                          inputValue={formatPhone(updateData.phone || '')}
                          onChange={onChangeText}
                          onBlur={onBlur}
                          onFocus={onFocus}
                          error={error?.phone}
                          placeholder={'(5xx) xxx xx xx'}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          id={'dateOfBirth'}
                          inputType={InputType.Date}
                          labelText={t('personalInformation.birth_date')}
                          inputValue={
                            moment(updateData.dateOfBirth).format(
                              'yyyy-MM-DD'
                            ) || ''
                          }
                          onChange={onChangeText}
                          onBlur={onBlur}
                        />
                      </div>
                      <div className="col-md-6 mt-4">
                        <InputLabelOnBorder
                          labelText={t('becomeAMentor.country')}
                          inputType={InputType.Text}
                          select={{
                            selectStyle: 'selectStyle',
                            option: countryList.map((item) => ({
                              key: item.name,
                              value: item.name,
                              name: 'country',
                            })),
                            selectedValue: updateData.country,
                          }}
                          handleSelectChange={(val) => {
                            setUpdateData({
                              ...updateData,
                              [val.name]: val.value,
                            });
                            dispatch(
                              Actions.managerActions.holdValue({
                                ...updateData,
                                [val.name]: val.value,
                              })
                            );
                            setDataChanged(false);
                          }}
                        />{' '}
                      </div>
                      <div className="col-md-6 mt-4">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_company')}
                          options={companyList}
                          selectedValues={updateData.companyId}
                          noTranslate={true}
                          selectedProps={{
                            key: 'companyName',
                            value: 'companyId',
                            searchKey: 'companyId',
                          }}
                          onChange={(value) =>
                            handleDropdown('companyId', value)
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        type={
                          dataChanged || isUpdateAble
                            ? ButtonType.Secondary
                            : ButtonType.Successbg
                        }
                        text={t('button.submit')}
                        textColor={TextColors.Light}
                        onClick={onSave}
                        buttonCol={3}
                        disabled={dataChanged || isUpdateAble ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.manager.update,
    content
  );
};
export default Update;
