import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import Button from '../../../../components/Common/Button/Button';
import Card from '../../../../components/Common/Card/Card';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../../utils/ComponentEnums';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const sessionPackageData = useSelector(
    (state) => state.sessionPackage.sessionPackage
  );
  const sessionPackageInfo = useSelector(
    (state) => state.sessionPackage.getById
  );
  const isSuccess = useSelector((state) => state.sessionPackage.isSuccess);
  const goBack = () => navigate(-1);
  const sessionPackageId = location?.state?.id;
  const [updateData, setUpdateData] = useState({});
  const checkData = Object.keys(updateData).length === 0;
  const redirect = '/session-package-list';

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...sessionPackageData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...sessionPackageData });
    } else {
      dispatch(Actions.sessionPackageActions.holdValue({ ...updateData }));
    }
  };
  const onFocus = () => {
    if (Object.keys(updateData).length === 0) {
      setUpdateData({ ...sessionPackageData });
    }
  };
  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.sessionPackageActions.updateAction(updateData, sessionPackageId)
    );
  };
  async function FetchById() {
    await dispatch(
      Actions.sessionPackageActions.getByIdAction(sessionPackageId)
    );
  }

  useEffect(() => {
    FetchById();
    if (isSuccess) {
      navigate(redirect);
      setUpdateData({});
      dispatch(Actions.sessionPackageActions.handleError(''));
    }
  }, [isSuccess]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-5 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('sessionPackage.update_session_package_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'person'}
                      inputType={InputType.Text}
                      labelText={t('sessionPackage.number_of_person')}
                      inputValue={
                        checkData
                          ? sessionPackageInfo.person
                          : updateData.person
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'minute'}
                      inputType={InputType.Number}
                      labelText={t('sessionPackage.minute')}
                      inputValue={
                        checkData
                          ? sessionPackageInfo.minute
                          : updateData.minute
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'sessionPackageSessionValue'}
                      inputType={InputType.Number}
                      labelText={t('sessionPackage.session_value')}
                      inputValue={
                        checkData
                          ? sessionPackageInfo.sessionPackageSessionValue
                          : updateData.sessionPackageSessionValue
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'sessionPackageMentorValue'}
                      inputType={InputType.Number}
                      labelText={t('sessionPackage.mentor_value')}
                      inputValue={
                        checkData
                          ? sessionPackageInfo.sessionPackageMentorValue
                          : updateData.sessionPackageMentorValue
                      }
                      onChange={onChangeText}
                      onBlur={onBlur}
                      onFocus={onFocus}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(updateData).length <= 1
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={
                        Object.keys(updateData).length <= 1 ? true : false
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.sessionPackage.update,
    content
  );
};
export default Update;
