import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import { ButtonSize, InputType } from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import { Entities, RemainingCalculationStatus } from '../../../utils/Enum';
import SearchButton from '../../../components/Common/Button/SearchButton';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const orderList = useSelector(
    (state) => state.order.calculationRemainingSessionFee
  );
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const userAreaList = staticDatas.UserArea;
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);
  const [mountStatus, setMountStatus] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(Actions.locationActions.getAllAction());
  }, []);

  useEffect(() => {
    getList();
  }, [filterData, currentUserClaim]);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(null, true);

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
      remainingCalculationAll: null,
    });
    setMountStatus(true);
  }, []);

  const getList = () => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) &&
      Object.keys(filterData).length > 0 &&
      dateChanged === false
    ) {
      dispatch(
        Actions.orderActions.calculationRemainingSessionFeeAction(filterData)
      );
    }
  };

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        dispatch(
          Actions.orderActions.calculationRemainingSessionFeeAction(filterData)
        );
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    if (checkClaims(currentUserClaim, Claims.actionClaims.orderReports)) {
      dispatch(
        Actions.orderActions.calculationRemainingSessionFeeAction(filterData)
      );
      setDateChanged(!dateChanged);
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value, bool = false) => {
    resetMe(propName, bool ? value : `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
    setDateChanged(true);
  };

  useEffect(() => {
    if (dateChanged) {
      const hasNullOrUndefined = Object.values(filterData).some(
        (value) => value !== null || value !== undefined
      );
      setMountStatus(!hasNullOrUndefined);
    }
  }, [filterData, dateChanged]);

  const headers = [
    { key: 'branchName', label: t('remainingOrderCalculation.branchName') },
    {
      key: 'totalActiveOrderCount',
      label: t('remainingOrderCalculation.totalActiveOrderCount'),
    },
    {
      key: 'totalNetAmount',
      label: t('remainingOrderCalculation.totalNetAmount'),
    },
  ];

  const cardHeader = [
    {
      key: 'totalActiveOrderCount',
      label: t('remainingOrderCalculation.totalActiveOrderCountAll'),
    },
    {
      key: 'totalNetAmount',
      label: t('remainingOrderCalculation.totalNetAmountAll'),
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags hSize={1} text={t('orderPayment.payment_list')} strong />
      </div> */}
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <SearchButton
                    stateName={'order'}
                    size={ButtonSize.Small}
                    bold
                    onClick={onSearch}
                    filterChanged={dateChanged}
                    mountingStatus={mountStatus}
                  />
                </div>
                <div className="row my-2">
                  <div className="col-lg-3">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) => handleDropdown('SType2', value)}
                      onReset={() => handleResetDropdown('SType2')}
                      isfilter
                    />
                  </div>
                  <div className="col-lg-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_user_area')}
                      options={userAreaList}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) => handleDropdown('SType', value)}
                      onReset={() => handleResetDropdown('SType')}
                      isfilter
                    />
                  </div>
                  <div className="col-lg-3 mt-lg-4 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_filter')}
                      options={RemainingCalculationStatus}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      onChange={(value) =>
                        handleDropdown('remainingCalculationAll', value, true)
                      }
                      onReset={() =>
                        handleResetDropdown('remainingCalculationAll')
                      }
                      isfilter
                    />
                  </div>
                  <div className="col-lg-3 mt-3">
                    <InputLabelOnBorder
                      id={'id'}
                      inputType={InputType.Number}
                      labelText={t('common.orderId')}
                      inputValue={filterData.id || ''}
                      onChange={onChangeText}
                    />
                  </div>
                </div>
              </>
            }
          />
          <Card
            body={
              <div>
                <div className="col-lg-4 ms-auto">
                  <Card
                    body={
                      <>
                        {cardHeader.map((val, i) => (
                          <div
                            key={i}
                            className={`d-flex justify-content-between border-bottom py-1 ${
                              i % 2 ? 'bg-light' : ''
                            }`}
                          >
                            <div className="fw-bold px-1">{t(val.label)}</div>
                            <div className="px-1">{orderList[val.key]}</div>
                          </div>
                        ))}
                      </>
                    }
                  />
                </div>
                <Table
                  // searchAble
                  data={orderList?.branchList ?? []}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.order}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.order.exportName.payments}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.accountingDept,
    content
  );
};
export default List;
