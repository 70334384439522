import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import { InputType, TextColors } from '../../../utils/ComponentEnums';
import PhoneConfirmation from '../AttendeePages/PhoneConfirmation';
import findMyPath from '../../../hooks/useFindMyPath';
import { useNavigate } from 'react-router-dom';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import { ModalTitles } from '../../../utils/Enum';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import { checkLogged } from '../../../hooks/useCheckLogged';

export default function RedeemCode({ redeemCode = {} }) {
  const phoneConfirmationModal = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userHasPhone = useSelector(
    (state) => state.user.getById.phoneIsVerified
  );
  const [createData, setCreateData] = useState(
    redeemCode !== null ? { redeemCode: redeemCode } : {}
  );
  const [phoneConfirmationInfo, setPhoneConfirmationInfo] = useState('');
  const redirect = findMyPath('orders');

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };
  const onSave = (e) => {
    e.preventDefault();
    if (userHasPhone) {
      dispatch(Actions.orderActions.redeemGiftCodeAction(createData));
    } else {
      phoneConfirmationModal.current.open();
    }
  };
  const onBlur = (e) => {
    const { id, value } = e.target;
    if (value === '') {
      const { [id]: deletedValue, ...updatedCreateData } = createData;
      setCreateData(updatedCreateData);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-3">
        <div className="col-12 col-md-6">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-center mt-3">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('redeemCode.header')}
                    textColor={TextColors.Dark}
                  />
                </div>
                <div className="container-fluid mt-4">
                  <div className="d-flex justify-content-center">
                    <div className="col-12 col-md-6">
                      <InputLabelOnBorder
                        id={'redeemCode'}
                        inputType={InputType.Text}
                        labelText={t('redeemCode.input_label_text')}
                        inputValue={createData.redeemCode || ''}
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'order'}
                        onClick={onSave}
                        objectLengthRule={
                          Object.keys(createData).length >= 1 ? false : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <ModalInsideComponent
        ref={phoneConfirmationModal}
        title={ModalTitles.phoneConfirmation}
        child={
          phoneConfirmationInfo === '' ? (
            <PhoneConfirmation
              phoneConfirmInfo={(val) => setPhoneConfirmationInfo(val)}
            />
          ) : (
            phoneConfirmationInfo
          )
        }
        onClose={() => {
          if (userHasPhone) {
            phoneConfirmationModal.current.close();
            setPhoneConfirmationInfo('');
            dispatch(Actions.orderActions.redeemGiftCodeAction(createData));
          }
        }}
      />
      <Toast
        stateName={'order'}
        onClose={() => {
          setCreateData({});
          dispatch(Actions.userActions.getByIdAction(checkLogged()));
          dispatch(Actions.orderActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );
}
