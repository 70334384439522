import {
  create,
  update,
  remove,
  getById,
  getAll,
  getEftControl,
  updateEft,
  getMySubscriptionPeriod,
  getOrders,
  getBasketItems,
  getOrderHistory,
  selectLaterOrder,
  getEarnedFreeSessions,
  getGiftSessions,
  redeemGiftCode,
  senfGiftSessionToFriend,
  cancelGift,
  selectSession,
  orderSetDate,
  unSetDate,
  getMeetingsByOrder,
  payments,
  updateOrderItem,
  getOrderDetails,
  getOrderItemDetails,
  orderItemUpdate,
  todaysTrialList,
  upcomingTrialList,
  removeOrder,
  renewOrderItem,
  renewOrderItemCalculation,
  calculationRemainingSessionFee,
  getOrdersByAttendeeId,
} from '../../../services/apiServices/orderServices';
import ActionTypes from './actionTypes';
import config from '../../../config.json';
import {
  formActions,
  reponseStatus,
  responseErrorStatus,
  storageKeys,
} from '../../../utils/Enum';
import utmDataSender from '../../../hooks/useUtmDataSender';
import Actions from '.';

const createAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await create(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        //TODO obje buradan redux a geçicek..
        await dispatch(createReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.order.CREATE_ACTION_ORDER };
};
const selectSessionAction = (createData, noCalendar = false) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await selectSession(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        let state =
          createData?.packageOptions !== undefined
            ? false
            : createData?.selectionOptions?.length === 0 ||
            createData?.selectionOptions?.length === undefined;
        await dispatch(
          selectSessionReducer(res?.data?.data, state ? true : false)
        );
        await dispatch(handleError(true));
        if (noCalendar) {
          await dispatch(handleValidationReducer(res?.data?.message));
        }
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const selectSessionReducer = (data, isEmpty) => {
  return {
    type: ActionTypes.order.SET_CALENDAR_TABLE,
    payload: { data, isEmpty },
  };
};
const orderSetDateAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await orderSetDate(createData)
      .then(async (res) => {
        if (res?.data?.isSuccess) {
          dispatch(selectSessionReducer([], ''));
          await dispatch(
            handleValidationReducer('order.select_later_set_date_success')
          );
          await dispatch(handleError(res?.data?.isSuccess));
          const storageKey = sessionStorage.getItem(storageKeys.utmData);
          if (
            createData?.FromNavigateWhere === 'freeTrial' &&
            storageKey !== null &&
            storageKey !== '' &&
            storageKey !== undefined
          ) {
            utmDataSender(createData.OrderItemId, formActions.freeTrial);
          }
        } else {
          await dispatch(handleValidationReducer(res?.data?.message));
          await dispatch(handleError(res?.data?.isSuccess));
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const unSetDateAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await unSetDate(id)
      .then(async (res) => {
        if (res?.data?.isSuccess) {
          await dispatch(handleValidationReducer('order.unset_date'));
        } else {
          await dispatch(handleValidationReducer(res?.data?.message));
        }
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    dispatch(handleValidationReducer(''));
    dispatch(handleError(''));
    await updateOrderItem(updateData, id).then(async (res) => {
      if (res?.data?.isSuccess) {
        dispatch(createReducer());
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(true));
        await dispatch(updateReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const updateReducer = () => {
  return { type: ActionTypes.order.UPDATE_ACTION_ORDER_ITEM };
};

const updateOrderAction = (updateData) => {
  return async (dispatch) => {
    dispatch(handleValidationReducer(''));
    dispatch(handleError(''));
    await update(updateData).then(async (res) => {
      if (res?.data?.isSuccess) {
        dispatch(createReducer());
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(true));
        await dispatch(updateOrderReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const updateOrderReducer = () => {
  return { type: ActionTypes.order.UpdateOrder };
};

const removeAction = (id) => {
  return async (dispatch) => {
    await remove(id).then(async (res) => {
      //TODO controller düzeyinde gelen response değişmeli
      await dispatch(selectSessionReducer([], true));
    });
  };
};

const removeOrderAction = (id) => {
  return async (dispatch) => {
    await removeOrder(id).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.order.GETBYID_ACTION_ORDER,
    payload: { data },
  };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return {
    type: ActionTypes.order.GETALL_ACTION_ORDER,
    payload: { data },
  };
};
const getAllEftOrdersAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllEftOrdersReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getEftControl(data);
        let response = res?.data?.data;
        await dispatch(getAllEftOrdersReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllEftOrdersReducer = (data) => {
  return {
    type: ActionTypes.order.EFT_CONTROL_ACTION_ORDER,
    payload: { data },
  };
};
const updateEftAction = (id) => {
  return async (dispatch) => {
    await updateEft(id);
  };
};
const getMySubscriptionPeriodAction = () => {
  return async (dispatch) => {
    await getMySubscriptionPeriod().then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getMySubscriptionPeriodReducer(response));
    });
  };
};
const getMySubscriptionPeriodReducer = (data) => {
  return {
    type: ActionTypes.order.GET_MYSUBSCRIPTION_PERIOD,
    payload: { data },
  };
};
const getOrdersAction = (id) => {
  return async (dispatch) => {
    await getOrders(id).then(async (res) => {
      let response = res?.data;
      await dispatch(getOrdersReducer(response?.data));
    });
  };
};
const getOrdersReducer = (data) => {
  return {
    type: ActionTypes.order.GET_ORDERS,
    payload: { data },
  };
};
const getOrderHistorysAction = () => {
  return async (dispatch) => {
    await getOrderHistory().then(async (res) => {
      let response = res?.data;
      await dispatch(getOrderHistoryReducer(response?.data));
    });
  };
};
const getOrderHistoryReducer = (data) => {
  return {
    type: ActionTypes.order.GET_ORDER_HISTORY,
    payload: { data },
  };
};
const getBasketItemsAction = (id) => {
  return async (dispatch) => {
    await getBasketItems(id).then(async (res) => {
      let response = res?.data?.data;
      if (response.orderId === 0) {
        dispatch(getBasketItemsReducer([], true));
      } else {
        dispatch(getBasketItemsReducer(response, false));
      }
    });
  };
};
const getBasketItemsReducer = (data, isEmpty) => {
  return {
    type: ActionTypes.order.GET_BASKET_ITEMS,
    payload: { data, isEmpty },
  };
};
const setSelectLaterOrder = (data) => {
  return async (dispatch) => {
    await selectLaterOrder(data).then(async (res) => {
      let response = res?.data;
    });
  };
};
const getEarnedFreeSessionsAction = (requestType) => {
  return async (dispatch) => {
    await getEarnedFreeSessions(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getEarnedFreeSessionsReducer(response));
    });
  };
};
const getEarnedFreeSessionsReducer = (data) => {
  return {
    type: ActionTypes.order.GET_EARNED_FREESESSIONS,
    payload: { data },
  };
};
const getGiftSessionsAction = (requestType) => {
  return async (dispatch) => {
    await getGiftSessions(requestType).then(async (res) => {
      let response = res?.data?.data;
      await dispatch(getGiftSessionsReducer(response));
    });
  };
};
const getGiftSessionsReducer = (data) => {
  return {
    type: ActionTypes.order.GET_GIFT_SESSIONS,
    payload: { data },
  };
};

const senfGiftSessionToFriendAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await senfGiftSessionToFriend(data).then(async (res) => {
      if (res?.data?.isSuccess !== undefined) {
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data.message));
      } else {
        await dispatch(handleValidationReducer(res?.data.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const redeemGiftCodeAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await redeemGiftCode(createData)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
        if (res?.data?.isSuccess) {
          const area = res?.data?.data.userArea;
          dispatch(
            Actions.userActions.selfUpdateAction({ userArea: area }, 'UserArea')
          );
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const cancelGiftAction = (id) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await cancelGift(id).then(async (res) => {
      if (res?.data?.isSuccess !== undefined) {
        await dispatch(handleError(true));
      } else {
        await dispatch(handleValidationReducer(res?.data));
      }
    });
  };
};

const getMeetingsByOrderAction = (filter) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getMeetingsByOrderReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getMeetingsByOrder(filter);
        let response = res?.data?.data;
        await dispatch(getMeetingsByOrderReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const getMeetingsByOrderReducer = (data) => {
  return {
    type: ActionTypes.order.GetMeetingsByOrder,
    payload: data,
  };
};
const paymentsAction = (filter) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(paymentsReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await payments(filter);
        let response = res?.data?.data;
        await dispatch(paymentsReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const paymentsReducer = (data) => {
  return {
    type: ActionTypes.order.GetPayments,
    payload: data,
  };
};

const getOrderDetailsAction = (id) => {
  return async (dispatch) => {
    await getOrderDetails(id).then(async (res) => {
      let response = res?.data;
      await dispatch(getOrderDetailsReducer(response?.data));
    });
  };
};
const getOrderDetailsReducer = (data) => {
  return {
    type: ActionTypes.order.GetOrderDetails,
    payload: { data },
  };
};

const getOrderItemDetailsAction = (id) => {
  return async (dispatch) => {
    await getOrderItemDetails(id).then(async (res) => {
      let response = res?.data;
      await dispatch(getOrderItemDetailsReducer(response?.data));
    });
  };
};
const getOrderItemDetailsReducer = (data) => {
  return {
    type: ActionTypes.order.GetOrderItemDetails,
    payload: { data },
  };
};

const orderItemUpdateAction = (data) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await orderItemUpdate(data).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(true));
        await dispatch(orderItemUpdateReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};

const orderItemUpdateReducer = () => {
  return { type: ActionTypes.order.UpdateOrder };
};

const todaysTrialListAction = (id) => {
  return async (dispatch) => {
    await todaysTrialList(id).then(async (res) => {
      let response = res?.data;
      await dispatch(todaysTrialListReducer(response?.data));
    });
  };
};
const todaysTrialListReducer = (data) => {
  return {
    type: ActionTypes.order.TodaysTrialList,
    payload: data,
  };
};

const upcomingTrialListAction = (id) => {
  return async (dispatch) => {
    await upcomingTrialList(id).then(async (res) => {
      let response = res?.data;
      await dispatch(upcomingTrialListReducer(response?.data));
    });
  };
};
const upcomingTrialListReducer = (data) => {
  return {
    type: ActionTypes.order.UpcomingTrialList,
    payload: data,
  };
};

const renewOrderItemCalculationAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await renewOrderItemCalculation(data)
      .then(async (res) => {
        if (res?.data?.isSuccess) {
          await dispatch(renewOrderItemCalculationReducer(res?.data?.data));
        }
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const renewOrderItemCalculationReducer = (data) => {
  return {
    type: ActionTypes.order.RenewOrderItemCalculation,
    payload: data,
  };
};

const renewOrderItemAction = (data) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await renewOrderItem(data)
      .then(async (res) => {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(res?.data?.isSuccess));
      })
      .catch(async (error) => {
        if (error?.response?.status === 400) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.BadRequest)
          );
          await dispatch(handleError(reponseStatus.fail));
        } else {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RequestTimedOut)
          );
          await dispatch(handleError(reponseStatus.fail));
        }
      });
  };
};

const calculationRemainingSessionFeeAction = (filter) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await calculationRemainingSessionFee(filter);
        let response = res?.data?.data;
        await dispatch(calculationRemainingSessionFeeReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const calculationRemainingSessionFeeReducer = (data) => {
  return {
    type: ActionTypes.order.CalculationRemainingSessionFee,
    payload: data,
  };
};

const getOrdersByAttendeeIdAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getOrdersByAttendeeIdReducer([]));
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      try {
        const res = await getOrdersByAttendeeId(id);
        let response = res?.data?.data;
        await dispatch(getOrdersByAttendeeIdReducer(response));
        await dispatch(handleError(''));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(
            handleValidationReducer(responseErrorStatus.RetryTimeOut)
          );
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const getOrdersByAttendeeIdReducer = (data) => {
  return {
    type: ActionTypes.order.GetOrdersByAttendeeId,
    payload: data,
  };
};

const holdValue = (data) => {
  return {
    type: ActionTypes.order.HOLD_VALUE_ORDER,
    payload: data,
  };
};
const handleError = (data) => {
  return {
    type: ActionTypes.order.HANDLE_ERROR_ORDER,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.order.HANDLE_VALIDATION_ORDER,
    payload: data,
  };
};
const cleanListReducer = () => {
  return { type: ActionTypes.order.CleanList };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(
        handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default)
      );
    } else {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(''));
      dispatch(cleanListReducer());
    }
  };
};

const orderActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  holdValue,
  handleError,
  getAllEftOrdersAction,
  updateEftAction,
  getMySubscriptionPeriodAction,
  getOrdersAction,
  getOrderHistorysAction,
  getBasketItemsAction,
  updateOrderAction,
  setSelectLaterOrder,
  getEarnedFreeSessionsAction,
  getGiftSessionsAction,
  senfGiftSessionToFriendAction,
  redeemGiftCodeAction,
  cancelGiftAction,
  selectSessionAction,
  selectSessionReducer,
  orderSetDateAction,
  unSetDateAction,
  getBasketItemsReducer,
  getMeetingsByOrderAction,
  paymentsAction,
  cleanState,
  getOrderDetailsAction,
  getOrderItemDetailsAction,
  orderItemUpdateAction,
  todaysTrialListAction,
  upcomingTrialListAction,
  removeOrderAction,
  handleValidationReducer,
  renewOrderItemCalculationAction,
  renewOrderItemAction,
  calculationRemainingSessionFeeAction,
  getOrdersByAttendeeIdAction,
};
export default orderActions;
