import React, { useState, useEffect } from 'react';

import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';

import {
  TextColors,
  ButtonType,
  ButtonSize,
} from '../../../utils/ComponentEnums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCalendarWeek,
  faCalendarDay,
  faCalendar,
  faCircle,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons';
import MonthlyScheduler from './Scheduler/MonthlyScheduler';
import WeeklyScheduler from './Scheduler/WeeklyScheduler';
import UpcomingScheduler from './Scheduler/UpcomingScheduler';
import DailyScheduler from './Scheduler/DailyScheduler';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CalendarModal from '../Modal/CalendarModal';
import Card from '../Card/Card';
import Actions from '../../../store/redux/actions';
import { ToastContainer, toast } from 'react-toastify';
import addDay from '../../../hooks/useAddDay';
import calculateUTC from '../../../hooks/useCalculateUTC';
import getTimeZone from '../../../hooks/useGetTimeZone';
import EnumBase from '../../../utils/EnumBase';

const Scheduler = ({
  sideOn,
  view,
  flatData,
  onlineDatas,
  cafeDatas,
  kidsDatas,
  companyDatas,
  weeks,
  reportDates,
  reportUpcomingDates,
  currentDate,
  currentView,
  onCurrentViewChange,
  selectedDay,
  onSelectedDayChange,
  attendeeId = undefined,
  getMeIndex,
}) => {
  const { t } = useTranslation();
  const meetingSchedule = useSelector(
    (state) => state.meetingSchema.getAllSchedule
  );
  const userInfo = useSelector((state) => state.user.getById);
  const newData = attendeeId === undefined ? flatData : meetingSchedule;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const timeZone = getTimeZone();
  const [isOpen, setIsOpen] = useState(false);
  const [recompensePopup, setRecompensePopup] = useState('');
  const redateIsSuccess = useSelector(
    (state) => state.meetingAttendee.isSuccess
  );
  const redateMessage = useSelector(
    (state) => state.meetingAttendee.validationMessage
  );
  const [recompenseMessage, setRecompenseMessage] = useState('');

  useEffect(() => {
    return () => {
      dispatch(Actions.meetingAttendeeActions.cleanState());
    };
  }, []);
  useEffect(() => {
    if (redateIsSuccess && redateMessage === '') {
      setIsOpen(false);
    }
  }, [redateIsSuccess]);

  useEffect(() => {
    if (!redateIsSuccess && redateMessage !== '') {
      setRecompenseMessage(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{redateMessage}</strong>
        </span>
      );
    }
  }, [redateIsSuccess]);

  useEffect(() => {
    if (recompenseMessage !== '' && redateMessage !== '') {
      toast.warning(recompenseMessage, {
        onClose: () => {
          setRecompenseMessage('');
        },
      });
    }
  }, [recompenseMessage]);

  useEffect(() => {
    if (recompensePopup !== '') {
      toast.info(recompensePopup, {
        onClose: () => {
          setRecompensePopup('');
        },
      });
    }
  }, [recompensePopup]);
  const customToastStyle = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const customToastStyleJoin = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const renderView = () => {
    switch (currentView) {
      case 1:
        return (
          <MonthlyScheduler
            weeks={weeks}
            onlineDatas={onlineDatas}
            cafeDatas={cafeDatas}
            kidsDatas={kidsDatas}
            companyDatas={companyDatas}
            handleCellClick={handleCellClick}
          />
        );
      case 2:
        return (
          <WeeklyScheduler
            reportDates={reportDates}
            sideOn={sideOn}
            onlineDatas={onlineDatas}
            cafeDatas={cafeDatas}
            kidsDatas={kidsDatas}
            companyDatas={companyDatas}
            handleCellClick={handleCellClick}
          />
        );
      case 3:
        return (
          <UpcomingScheduler
            reportUpcomingDates={reportUpcomingDates}
            sideOn={sideOn}
            onlineDatas={onlineDatas}
            cafeDatas={cafeDatas}
            kidsDatas={kidsDatas}
            companyDatas={companyDatas}
            handleCellClick={handleCellClick}
          />
        );
      case 4:
        return (
          <DailyScheduler
            currentDate={currentDate}
            reportDates={reportDates}
            sideOn={sideOn}
            onlineDatas={onlineDatas}
            cafeDatas={cafeDatas}
            kidsDatas={kidsDatas}
            companyDatas={companyDatas}
            handleCellClick={handleCellClick}
            selectedDay={selectedDay}
            onSelectedDayChange={onSelectedDayChange}
            flatData={newData}
          />
        );
      default:
        return (
          <MonthlyScheduler
            weeks={weeks}
            onlineDatas={onlineDatas}
            cafeDatas={cafeDatas}
            kidsDatas={kidsDatas}
            companyDatas={companyDatas}
            handleCellClick={handleCellClick}
          />
        );
    }
  };

  const options = [
    {
      key: 1,
      name: (
        <span className="d-flex justify-content-center  align-items-center text-nowrap  p-0 m-0">
          <FontAwesomeIcon
            className="p-1"
            style={{ height: '20px', width: '20px' }}
            icon={faCalendarAlt}
          />
          {t('calendar.monthly')}
        </span>
      ),
      value: 1,
    },
    {
      key: 2,
      name: (
        <span className="d-flex justify-content-center  align-items-center text-nowrap  p-0 m-0">
          <FontAwesomeIcon
            className="p-1"
            style={{ height: '20px', width: '20px' }}
            icon={faCalendarWeek}
          />{' '}
          {t('calendar.weekly')}
        </span>
      ),
      value: 2,
    },
    {
      key: 3,
      name: (
        <span className="d-flex justify-content-center  align-items-center text-nowrap  p-0 m-0">
          <FontAwesomeIcon
            className="p-1"
            style={{ height: '20px', width: '20px ' }}
            icon={faCalendarDay}
          />{' '}
          {t('calendar.3_days')}
        </span>
      ),
      value: 3,
    },
    {
      key: 4,
      name: (
        <span className="d-flex justify-content-center  align-items-center text-nowrap  p-0 m-0">
          <FontAwesomeIcon
            className="p-1"
            style={{ height: '20px', width: '20px' }}
            icon={faCalendar}
          />{' '}
          {t('calendar.daily')}
        </span>
      ),
      value: 4,
    },
  ];

  const [selectedDropdown, setSelectedDropdown] = useState(
    options.at(currentView - 1).name
  );

  const handleViewChange = (target) => {
    setSelectedDropdown(options.at(parseInt(target.value - 1)).name);

    onCurrentViewChange(target.value);
  };

  const closePopup = () => {
    setIsOpen(false);
    setSelectedDate(null);
  };

  const handleCellClick = (date, time, event, day) => {
    setSelectedDate(date);
    setSelectedTime(time);
    setIsOpen(true);
  };
  function handleDates(item) {
    const [year, month, day] = item?.split('T')[0].split('-').map(Number);
    const date = `${day}-${month}-${year}`;
    const timeArray = item?.split('T')[1].split(':');
    const h = timeArray[0];
    const m = timeArray[1];
    const time = `${h}:${m}`;
    const prosess = addDay(time, date);
    const fixedDate = `${prosess}T${time}:00.000Z`;

    const [xday, xmonth, xyear] = fixedDate
      ?.split('T')[0]
      .split('-')
      .map(Number);
    const fixDate = `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
      .toString()
      .padStart(2, '0')}T${calculateUTC(fixedDate?.split('T')[1])}`;
    return fixDate;
  }
  const [meetingInfo, setMeetingInfo] = useState('');
  const findMeetingUrl = (item, data) => {
    let meetingUrl = null;

    const fifteenMinutesBeforeStart = new Date(handleDates(item[0]?.startDate));
    fifteenMinutesBeforeStart.setMinutes(
      fifteenMinutesBeforeStart.getMinutes() - 15
    );
    const clickAbleLastFiveMins = fifteenMinutesBeforeStart;
    clickAbleLastFiveMins.setMinutes(clickAbleLastFiveMins.getMinutes() + 10);

    const currentDateTime = new Date();
    const firstDate = new Date(handleDates(item[0]?.startDate));
    const min =
      clickAbleLastFiveMins.getMinutes() - currentDateTime.getMinutes();

    if (currentDateTime < clickAbleLastFiveMins) {
      return setMeetingInfo(
        <div>
          {t('mySessions.join_session_info', { min })
            ?.split('{br}')
            ?.map((item, index) => (
              <span
                key={index}
                className="d-flex position-relative justify-content-start"
                style={{ maxWidth: '550px' }}
              >
                <strong>{item}</strong>
              </span>
            ))}
        </div>
      );
    } else if (
      currentDateTime > fifteenMinutesBeforeStart &&
      currentDateTime < firstDate
    ) {
      meetingUrl = item[0]?.meetingUrl;
    }

    const sesMin = findSessionMinute(data?.ContentText?.text2);
    const rule = item?.length === 1 ? item?.length : item?.length - 1;
    for (let i = 0; i < rule; i++) {
      const meeting = item[i];
      const meetingDateTime = new Date(handleDates(meeting?.startDate));
      if (item.length === 1) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }
      const nextMeeting = item[i + 1];
      let nextMeetingDateTime;
      if (nextMeeting !== undefined && sesMin) {
        const secondLink = new Date(handleDates(nextMeeting?.startDate));
        nextMeetingDateTime = new Date(
          secondLink.setMinutes(secondLink.getMinutes() - 10)
        );
      } else {
        nextMeetingDateTime = new Date(handleDates(nextMeeting?.startDate));
      }
      if (
        i === 0 &&
        currentDateTime >= meetingDateTime &&
        currentDateTime <= nextMeetingDateTime
      ) {
        meetingUrl = meeting?.meetingUrl;
        break;
      }

      if (currentDateTime > nextMeetingDateTime) {
        meetingUrl = nextMeeting?.meetingUrl;
        break;
      }
    }
    return meetingUrl;
  };
  const handleClick = (item, data) => {
    const meetingUrl = findMeetingUrl(item, data);
    if (meetingUrl) {
      window.open(meetingUrl, '_blank');
    } else {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    }
  };

  function findSessionMinute(meeting) {
    const [parsedS, parsedE] = meeting?.split('-').map(String);
    const [sH, sM] = parsedS?.split(':').map(Number);
    const [eH, eM] = parsedE?.split(':').map(Number);
    return eH * 60 + eM - (sH * 60 + sM) >= 90;
  }

  const handleJoinSession = (meeting) => {
    const currentDate = new Date();
    const forCheck = new Date();
    currentDate.setHours(currentDate.getHours() + getTimeZone(true) / 60);
    const [year, month, day] = currentDate
      .toISOString()
      .split('T')[0]
      .split('-')
      .map(Number);
    const currentDateString = `${day.toString().padStart(2, '0')}-${month
      .toString()
      .padStart(2, '0')}-${year}`;
    let availableMeetings;
    const getCurrent = calculateUTC(
      `${currentDate.getHours()}:${currentDate.getMinutes()}`,
      true
    );

    const [hx, mx] = getCurrent.split(':').map(Number);
    const asMinx = hx * 60 + mx;
    const [h, m] = meeting.time.split(':').map(Number);
    const asMin = h * 60 + m - 15;

    if (meeting.date === currentDateString && asMin <= asMinx) {
      meeting.meetingDetails.forEach((meetingDetail, index) => {
        const is90 = findSessionMinute(meeting?.ContentText?.text2);
        let addMin;
        switch (index) {
          case 0:
            addMin = is90 ? 40 * 60000 : 30 * 60000;
            break;
          case 1:
            addMin = is90 ? 40 * 60000 : 30 * 60000;
            break;
          default:
            addMin = 40 * 60000;
            break;
        }
        const fixDate = handleDates(meetingDetail.startDate);
        const xDate = new Date(fixDate).getTime() + addMin;
        const meetingDateTime = new Date(xDate);
        if (meetingDateTime >= forCheck && availableMeetings === undefined) {
          availableMeetings = meetingDetail;
        }
      });
    }
    return availableMeetings === undefined;
  };

  useEffect(() => {
    if (meetingInfo !== '') {
      toast.info(meetingInfo, {
        onClose: () => {
          setMeetingInfo('');
        },
      });
    }
  }, [meetingInfo]);

  const popupArray = {
    ...(newData && {
      Contents: newData
        .filter((event) =>
          selectedTime
            ? event.date === selectedDate?.format('DD-MM-YYYY') &&
              event.interval.split('-')[0] === selectedTime
            : event.date === selectedDate?.format('DD-MM-YYYY')
        )
        .map((speakerAvaible) => ({
          ContentId: speakerAvaible.id,
          ContentType: speakerAvaible.type,
          ContentTitle: speakerAvaible.speakerName,
          ContentIsActive: speakerAvaible.isActive,
          date: speakerAvaible.date,
          time: speakerAvaible.interval.split('-')[0],
          ContentText: {
            text1: speakerAvaible.date,
            text2: speakerAvaible.interval,
            text3: speakerAvaible.speakerName,
          },
          ContentImage: speakerAvaible.speakerImage,
          ContentOrderId: speakerAvaible.orderItemId,
          ContentCategory: speakerAvaible.category,
          ContentArea: speakerAvaible.userArea,
          ContentServiceType: speakerAvaible.typeOfService,
          isRecompensedSession: speakerAvaible.recompensedSession,
          meetingAttendeeStatus: speakerAvaible.meetingAttendeeStatus,
          isSingleSession: speakerAvaible.singleSession,
          location: speakerAvaible.locationId,
          meetingLevel: speakerAvaible.meetingLevel,
          title: speakerAvaible.title,
          trialStatus: speakerAvaible.trialStatus,
          meetingDetails: speakerAvaible.meetingDetails,
          meetingSchemaId: speakerAvaible.meetingSchemaId,
          isActive: speakerAvaible.isActive,
          redated: speakerAvaible.redatedSession,
          recompenseTimeCheck: speakerAvaible.recompenseTimeAvailability,
          attendeeCount: speakerAvaible.attendeeCount,
          packageId: speakerAvaible.packageId,
          redateRight: speakerAvaible.redateRight,
          capacity: speakerAvaible.capacity,
          recompenseExpired: speakerAvaible.recompenseTimeExpired,
          ageId: speakerAvaible.ageId,
          ageInterval: speakerAvaible.ageInterval,
        })),
    }),
  };

  const onRecompense = async (card) => {
    dispatch(Actions.resetStoreAction.resetStore());

    if (
      userInfo?.userArea !== card.ContentArea &&
      card.ContentIsActive &&
      card.isRecompensedSession &&
      attendeeId === undefined
    ) {
      await dispatch(
        Actions.onboardActions.onboardType(parseInt(card.ContentArea))
      );
      await dispatch(
        Actions.userActions.selfUpdateAction(
          { userArea: parseInt(card.ContentArea) },
          'UserArea',
          true
        )
      );
    }

    const [day, month, year] = card.date.split('-').map(Number);
    const date = new Date(
      `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`
    );
    date.setHours(0, 0, 0, 0);

    const filterData = {
      StartDateTime: date,
      TypeOfService: [card.ContentServiceType],
      UserArea: card.ContentArea,
      FullName: null,
      Native: null,
      LearningPurpose: null,
      LanguageLevels: card.meetingLevel !== null ? card.meetingLevel : null,
      Locations: [card.location],
      PackageId: card.packageId,
      CompanyId: userInfo?.userArea === 3 ? userInfo?.companyId : 1,
      AgeId: card.ageId,
      DateDetails: {
        dayOfWeek: null,
        timeInterval: null,
        selectedDate: null,
        selectedTime: null,
      },
    };
    if (
      attendeeId !== undefined &&
      card.ContentIsActive &&
      card.isRecompensedSession
    ) {
      await getMeIndex({
        index: 5,
        state: {
          fromNavigateWhere: 'recompense',
          whichone: card.ContentCategory === 'Group' ? 'Group' : 'Private',
          orderItemId: card.ContentOrderId,
          filterData: filterData,
          meetingAttendeeId: card.ContentId,
        },
      });
    } else {
      if (card.ContentIsActive && card.isRecompensedSession) {
        navigate('/calendar', {
          state: {
            fromNavigateWhere: 'recompense',
            whichone: card.ContentCategory === 'Group' ? 'Group' : 'Private',
            orderItemId: card.ContentOrderId,
            filterData: filterData,
            meetingAttendeeId: card.ContentId,
          },
        });
      }
    }
    await setRecompensePopup('');
  };

  const navigateTo = async (card) => {
    const date = addDay(calculateUTC(card.time, true), card.date);
    const [xday, xmonth, xyear] = date.split('-').map(Number);
    const recompenseDate = `${xyear}-${xmonth
      .toString()
      .padStart(2, '0')}-${xday.toString().padStart(2, '0')}T${calculateUTC(
      card.time,
      true
    )}:00.000Z`;

    const myData = {
      redatedMeetingAttendeeId: card.ContentId,
      orderItemId: card.ContentOrderId,
      redateFrom: recompenseDate,
    };
    const recompenseArgs =
      attendeeId === undefined ? [myData] : [myData, attendeeId];
    await dispatch(
      Actions.meetingAttendeeActions.recompenseAction(...recompenseArgs)
    );
    await dispatch(
      Actions.meetingSchemaActions.getAllAttendeeScheduleAction(
        attendeeId !== undefined ? attendeeId : ''
      )
    );
  };
  const locations = useSelector((state) => state.location.branches);
  const getLocationKey = (id) => {
    const location = locations.find((loc) => loc.value === id);
    return location ? location.key : null;
  };

  const IconDisplay = ({ iconCount, capacity }) => {
    const renderExtraIcon = iconCount > 3;
    return (
      <div className="icon-container">
        {Array.from({ length: Math.min(iconCount, 3) }).map((_, index) => (
          <FontAwesomeIcon
            key={`${index}_${iconCount + 1}`}
            icon={faCircleUser}
            size="2x"
            className={`icon ${index !== iconCount - 1 ? 'hidden' : ''}`}
            style={{ zIndex: index + 1 }}
          />
        ))}
        <div
          className={`icon-container position-relative`}
          style={{ left: '-1px' }}
        >
          {renderExtraIcon && (
            <>
              <FontAwesomeIcon
                className="text-secondary icon"
                icon={faCircle}
                size="2x"
                style={{ zIndex: 20 }}
              />
              <span
                className="position-absolute ms-2 top-50 start-90 translate-middle badge rounded-pill"
                style={{ zIndex: 20 }}
              >
                {renderExtraIcon && `+${iconCount - 3}`}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  function titleFix(date) {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    if (date !== undefined && date !== null) {
      const parts = date?.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day);
      const nextDay = new Date(currentDate);
      const formattedDate = nextDay?.toLocaleDateString('en-US', options);
      return formattedDate;
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
    }, 5 * 60 * 1000);
    return () => clearTimeout(timerId);
  }, [meetingSchedule]);

  const [mobileView, setMobileView] = useState(window.innerWidth < 577);
  const handleResize = () => {
    setMobileView(window.innerWidth < 577);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="calendar container ">
        <div className={`${sideOn ? 'd-none' : ''} header `}>
          <div className="row">
            <div className="col-7 mb-lg-4">
              {/* {recompense && (
                <div className="d-flex align-middle align-items-center ">
                  <FontAwesomeIcon className="p-1" icon={faCircleExclamation} />

                  <p className="p-1 m-0 align-middle  bg-light rounded-3  text-muted">
                    Askıya Alınmış 3 dersiniz bulunmaktadır.
                  </p>
                </div>
              )} */}
            </div>
            <div className="d-flex flex-column flex-lg-row justify-content-between mb-2 mb-lg-4">
              <div className="d-flex justify-content-center justify-content-lg-start text-nowrap mb-2 mb-lg-0">
                <span>{t('utc.calculation', { timeZone })}</span>
              </div>
              <div className="d-flex justify-content-center justify-content-lg-end text-nowrap">
                <Dropdown
                  name={'ScheduleDropdown'}
                  options={options}
                  id={'ScheduleDropdown'}
                  title={selectedDropdown}
                  propNames={{
                    id: 'id',
                    key: 'key',
                    value: 'value',
                    name: 'name',
                  }}
                  handleChange={(target) => handleViewChange(target)}
                  bordered
                  backGroundAll={'bg-lightcustom'}
                  forCalendar
                  isFilter
                  noEffect={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{renderView()}</div>
          <CalendarModal
            isVisible={isOpen}
            styleProps={{ maxWidth: 550 }}
            title={
              <strong style={{ fontSize: '0.9em' }}>
                {titleFix(popupArray?.Contents?.[0]?.ContentText?.text1)}
              </strong>
            }
            content={
              <div className="row d-flex justify-content-center">
                {popupArray?.Contents?.filter(
                  (item) => item.meetingStatus !== t('MeetingStatus.3')
                ).map((card, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div
                      key={`pop_up1_${index}`}
                      className="p-0 m-0 col-sm container-fluid align-items-center"
                      style={{ marginBottom: '-30px' }}
                    >
                      <Card
                        padding={0}
                        key={`pop_up_card_${index}`}
                        cardTitle={{
                          title: (
                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center m-2">
                              {mobileView && (
                                <img
                                  className="img-fluid rounded"
                                  src={card?.ContentImage}
                                  alt="contentImage"
                                  style={{
                                    height: '50px',
                                    width: '80px',
                                    borderRadius: '0.5rem',
                                  }}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/images/empty-profile.png';
                                  }}
                                />
                              )}
                              <span className="ms-2 text-center text-md-start">
                                <strong>{card.ContentTitle}</strong>
                              </span>
                              {
                                <div
                                  className={`${
                                    ButtonType[card.ContentType]
                                  } col-12 col-lg-6 p-2 d-flex justify-content-center text-white fw-bold rounded mt-2 mt-md-0`}
                                >
                                  <strong
                                    className="text-nowrap"
                                    style={{
                                      fontSize: '0.7rem',
                                    }}
                                  >
                                    {getLocationKey(card?.location)}{' '}
                                    {card.ContentType}{' '}
                                    {card.trialStatus === 'FreeTrial'
                                      ? t('schedule.session_free_trial')
                                      : card.trialStatus === 'PaidTrial'
                                      ? t('schedule.session_paid_trial')
                                      : null}{' '}
                                    {card.trialStatus === 'NonTrial' &&
                                    card.ContentCategory === 'OneToOne'
                                      ? t('mentor.onetoone')
                                      : t('mentor.group')}{' '}
                                    {card?.ContentCategory === 'Group' &&
                                      t('onlineGroup.person', {
                                        person: card?.capacity,
                                      })}
                                  </strong>
                                </div>
                              }
                            </div>
                          ),
                        }}
                        shadow={true}
                        scheduleBorder={card.ContentType}
                        cardRounded={false}
                        body={
                          <div>
                            <div className="d-flex justify-content-between align-items-center m-1">
                              {!mobileView && (
                                <div className="flex-shrink-0">
                                  <img
                                    className="img-fluid rounded "
                                    src={card?.ContentImage}
                                    alt="conttentImage"
                                    style={{
                                      height: '90px',
                                      width: '80px',
                                      borderRadius: '0.5rem',
                                    }}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        '/images/empty-profile.png';
                                    }}
                                  />
                                </div>
                              )}
                              <div className={`flex-grow-1 me-1 ms-md-3`}>
                                <div className="text-nowrap px-auto">
                                  {card?.ContentText?.text2}
                                </div>
                                {/* TODO Age gösterilmiyor UI da */}
                                {/* {card.ageInterval && (
                                  <span>
                                    {t('weeklyCalendarModal.age')}
                                    {card.ageInterval}
                                  </span>
                                )} */}
                                <div className="text-nowrap">
                                  {card.ContentCategory === 'Group' && (
                                    <div className="row d-flex justify-content-around align-items-center">
                                      <div className="text-nowrap">
                                        {t(`${card?.meetingLevel}`)}
                                      </div>
                                      <span className="ms-1 py-2">
                                        <strong>
                                          <IconDisplay
                                            iconCount={card?.attendeeCount}
                                          />
                                        </strong>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {(card.meetingAttendeeStatus ===
                                t(EnumBase.MeetingAttendeeStatus.Recompense) ||
                                card.meetingAttendeeStatus ===
                                  t(EnumBase.MeetingAttendeeStatus.Planned)) &&
                              card.ContentIsActive &&
                              !card.isSingleSession &&
                              !card.redated ? (
                                <div className="d-flex row flex-grow-0 mx-0">
                                  {card.ContentIsActive &&
                                    !card.isRecompensedSession &&
                                    card.ContentType !== 'Cafe' && (
                                      <div className="py-1">
                                        <Button
                                          key={`${card.date}_${card.intervaş}`}
                                          type={
                                            handleJoinSession(card)
                                              ? ButtonType.Secondary
                                              : ButtonType.Info
                                          }
                                          buttonCol={12}
                                          size={ButtonSize.Small}
                                          textColor={TextColors.Light}
                                          text={
                                            <strong className="small">
                                              {t('mySessions.join_session')}
                                            </strong>
                                          }
                                          disabled={handleJoinSession(card)}
                                          onClick={() =>
                                            handleClick(
                                              card?.meetingDetails,
                                              card
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  <div className="py-1">
                                    {!card.redated && (
                                      <Button
                                        type={
                                          card.ContentIsActive &&
                                          !card.isRecompensedSession
                                            ? ButtonType.Successbg
                                            : ButtonType.Secondary
                                        }
                                        textColor={TextColors.Light}
                                        size={ButtonSize.Small}
                                        onClick={() => {
                                          onRecompense(card);
                                          setRecompensePopup(
                                            card.ContentIsActive &&
                                              card.isRecompensedSession &&
                                              card.meetingAttendeeStatus ===
                                                t(
                                                  EnumBase.MeetingAttendeeStatus
                                                    .Planned
                                                ) ? (
                                              ''
                                            ) : (
                                              <>
                                                <span
                                                  className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
                                                  style={{
                                                    maxWidth: '550px',
                                                  }}
                                                >
                                                  <strong>
                                                    {t(
                                                      'button.recompense_question'
                                                    )}
                                                  </strong>
                                                </span>
                                                <div
                                                  className="text-muted text-center py-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html: t(
                                                      'button.redate_right',
                                                      {
                                                        count:
                                                          card?.redateRight,
                                                      }
                                                    ),
                                                  }}
                                                />

                                                <div className="d-flex justify-content-around">
                                                  <div className="col-4">
                                                    <Button
                                                      text={t('button.yes')}
                                                      type={ButtonType.Success}
                                                      textColor={
                                                        TextColors.Light
                                                      }
                                                      onClick={() => {
                                                        navigateTo(card);
                                                        setRecompensePopup('');
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col-4">
                                                    <Button
                                                      text={t('button.no')}
                                                      type={ButtonType.Danger}
                                                      textColor={
                                                        TextColors.Light
                                                      }
                                                      onClick={() =>
                                                        setRecompensePopup('')
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          );
                                        }}
                                        text={
                                          <strong>
                                            {card.ContentIsActive &&
                                            card.isRecompensedSession &&
                                            card.meetingAttendeeStatus ===
                                              t(
                                                EnumBase.MeetingAttendeeStatus
                                                  .Recompense
                                              )
                                              ? t('button.recompensed')
                                              : t('button.recompense')}
                                          </strong>
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex flex-column">
                                  {!card.isRecompensedSession &&
                                    card.meetingAttendeeStatus !==
                                      t(
                                        EnumBase.MeetingAttendeeStatus.Completed
                                      ) &&
                                    card.ContentType !== 'Cafe' && (
                                      <div className="d-flex justify-content-center">
                                        <div className="col-12 col-lg-10">
                                          <Button
                                            type={
                                              handleJoinSession(card)
                                                ? ButtonType.Secondary
                                                : ButtonType.Info
                                            }
                                            buttonCol={12}
                                            size={ButtonSize.Small}
                                            textColor={TextColors.Light}
                                            text={
                                              <strong>
                                                {t('mySessions.join_session')}
                                              </strong>
                                            }
                                            disabled={handleJoinSession(card)}
                                            onClick={() =>
                                              handleClick(
                                                card?.meetingDetails,
                                                card
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  <p>
                                    {card.meetingAttendeeStatus ===
                                    t(EnumBase.MeetingAttendeeStatus.Canceled)
                                      ? t('schedule.session_canceled')
                                      : card.meetingAttendeeStatus ===
                                        t(
                                          EnumBase.MeetingAttendeeStatus
                                            .BranchFreeze
                                        )
                                      ? t('schedule.session_frozen')
                                      : card.meetingAttendeeStatus ===
                                          t(
                                            EnumBase.MeetingAttendeeStatus
                                              .Completed
                                          ) && card.recompenseExpired !== ''
                                      ? card.recompenseExpired
                                      : card.meetingAttendeeStatus ===
                                        t(
                                          EnumBase.MeetingAttendeeStatus
                                            .Completed
                                        )
                                      ? t('schedule.session_completed')
                                      : card.redated
                                      ? t('schedule.session_recompensed')
                                      : card.isSingleSession
                                      ? t(
                                          'schedule.one_time_sessions_cannot_be_recompensed'
                                        )
                                      : card.redated
                                      ? t('schedule.redated_session')
                                      : t('schedule.recompensedandredated')}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            }
            onClose={() => closePopup()}
          />
        </div>
      </div>
      {(recompensePopup !== '' ||
        recompenseMessage !== '' ||
        meetingInfo !== '') && (
        <ToastContainer
          limit={1}
          style={meetingInfo !== '' ? customToastStyleJoin : customToastStyle}
          autoClose={meetingInfo !== '' ? 2000 : false}
          closeOnClick
          theme="light"
        />
      )}
      <div className="d-flex justify-content-center my-2 justify-content-md-start">
        <div className="align-self-center px-2">
          <h6>Online</h6>
          <Button
            type={ButtonType.Online}
            buttonCol={12}
            style={{ width: '100%' }}
            disableHover={true}
          />
        </div>
        <div className="align-self-center px-2">
          <h6>Cafe</h6>

          <Button
            type={ButtonType.Cafe}
            buttonCol={12}
            style={{ width: '100%' }}
            disableHover={true}
          />
        </div>
        <div className="align-self-center px-2">
          <h6>Kids</h6>

          <Button
            style={{ width: '100%' }}
            type={ButtonType.Kids}
            buttonCol={12}
            disableHover={true}
          />
        </div>
        <div className="align-self-center px-2">
          <h6>Company</h6>

          <Button
            style={{ width: '100%' }}
            type={ButtonType.Company}
            buttonCol={12}
            disableHover={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Scheduler;
