import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import Button from '../../../components/Common/Button/Button';
import Modal from '../../../components/Common/Modal/Modal';
import Table from '../../../components/Common/Table/Table';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../utils/ComponentEnums';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

const Create = () => {
  const { t } = useTranslation();
  const order = useSelector((state) => state.order.order);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const attendeeList = useSelector((state) => state.attendee.filteredAttendee);
  const staticDatas = useSelector((state) => state.staticData);
  const isSuccess = useSelector((state) => state.order.isSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [createData, setCreateData] = useState(order);
  const [isOpen, setIsOpen] = useState(false);
  const [searchAttendee, setSearchAttendee] = useState({});
  const redirect = '/order-list';
  const orderStatusList = staticDatas.OrderStatus;
  const paymentStatusList = staticDatas.PaymentStatus;
  const paymentTypeList = staticDatas.PaymentType;
  const pageNumber = 1;
  const pageSize = 10;

  const onChangeText = (e, modal) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (modal) {
      return setSearchAttendee(value === '' ? {} : { name: value });
    }
    setCreateData({
      ...createData,
      [id]: type === 'number' ? parseInt(value) : value,
    });
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.orderActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.orderActions.createAction(createData));
  };
  const getList = async () => {
    await dispatch(Actions.locationActions.getAllAction());
    const requests = [10, 11, 12];
    requests.forEach(async (element) => {
      await dispatch(Actions.staticDataActions.getFilteredData(element));
    });
  };

  useEffect(() => {
    getList();
    if (isSuccess) {
      navigate(redirect);
      dispatch(Actions.orderActions.handleError(''));
    }
  }, [isSuccess]);

  const handleDropdown = (propName, value) => {
    const parsedValue = parseInt(value);
    setCreateData({ ...createData, [propName]: parsedValue });
    dispatch(
      Actions.orderActions.holdValue({
        ...createData,
        [propName]: parsedValue,
      })
    );
  };
  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'name', label: t('order.attendee_name') },
    { key: 'email', label: t('order.attendee_email_address') },
    { key: 'phone', label: t('order.attendee_phone_number') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('order.adding_new_order_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex row justify-content-between">
                      <div className="col-8">
                        <InputLabelOnBorder
                          id={'attendeeEmail'}
                          inputType={InputType.Email}
                          labelText={t('order.attendee_email_address')}
                          inputValue={createData.attendeeEmail || ''}
                          onChange={onChangeText}
                          onBlur={onBlur}
                        />
                      </div>
                      <Modal
                        isVisible={isOpen}
                        styleProps={{ maxWidth: 850 }}
                        content={
                          <div className="d-flex justify-content-center">
                            <div className="col-lg-11">
                              <Card
                                body={
                                  <>
                                    <div className="d-sm-flex align-items-center justify-content-start mb-4 form-div-border">
                                      <HeadTags
                                        hSize={6}
                                        strong
                                        text={t('cardHeaderFilter.filter')}
                                      />
                                    </div>
                                    <div className="row my-2 d-sm-flex align-items-center justify-content-start">
                                      <div className="col-8">
                                        <InputLabelOnBorder
                                          id={'name'}
                                          inputType={InputType.Text}
                                          labelText={t(
                                            'order.attendee_search_name_email_adress_or_phone_number'
                                          )}
                                          inputValue={searchAttendee.name || ''}
                                          onChange={(e) =>
                                            onChangeText(e, 'modal')
                                          }
                                        />
                                      </div>
                                      <div className="col-2 mt-2">
                                        <Button
                                          text={t('cardHeaderFilter.search')}
                                          icon={faSearch}
                                          type={ButtonType.Successbg}
                                          iconColor={TextColors.Light}
                                          textColor={TextColors.Light}
                                          iconSide
                                          bold
                                          buttonCol={12}
                                          onClick={() =>
                                            Object.keys(searchAttendee)
                                              .length === 1
                                              ? dispatch(
                                                  Actions.attendeeActions.searchAttendeeAction(
                                                    searchAttendee
                                                  )
                                                )
                                              : undefined
                                          }
                                        />
                                      </div>
                                    </div>
                                    <Table
                                      data={attendeeList}
                                      headers={headers}
                                      striped
                                      bordered
                                      selectButton={(value) => {
                                        setCreateData({
                                          ...createData,
                                          attendeeEmail: value,
                                        });
                                        setIsOpen(false);
                                      }}
                                      page={'order'}
                                      currentUserClaim={currentUserClaim}
                                      // searchAble
                                    />
                                  </>
                                }
                              />
                            </div>
                          </div>
                        }
                        footer={
                          <Button
                            type={ButtonType.Successbg}
                            bold
                            buttonCol={2}
                            textColor={TextColors.Light}
                            text={t('order.button_close')}
                            onClick={() => setIsOpen(false)}
                          />
                        }
                        onClose={() => {
                          setIsOpen(false);
                        }}
                      />
                      <div className="col-4 mt-2">
                        <Button
                          icon={faSearch}
                          textColor={TextColors.Light}
                          iconColor={TextColors.Light}
                          buttonCol={12}
                          text={t('order.button_search_attendee')}
                          type={ButtonType.Successbg}
                          onClick={() => setIsOpen(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'meetingSchemaId'}
                      inputType={InputType.Number}
                      labelText={t('order.meeting_schema_id')}
                      inputValue={createData.meetingSchemaId || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) => handleDropdown('locationId', value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_order_status')}
                      options={orderStatusList}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) => handleDropdown('status', value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_payment_status')}
                      options={paymentStatusList}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) =>
                        handleDropdown('paymentStatus', value)
                      }
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_payment_type')}
                      options={paymentTypeList}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) => handleDropdown('paymentType', value)}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'paymentDate'}
                      inputType={InputType.Date}
                      labelText={t('order.payment_date')}
                      inputValue={createData.paymentDate || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'recompenseCount'}
                      inputType={InputType.Number}
                      labelText={t('order.recompense_count')}
                      inputValue={createData.recompenseCount || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'undatedPackageCount'}
                      inputType={InputType.Number}
                      labelText={t('order.undated_package_count')}
                      inputValue={createData.undatedPackageCount || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'datedMeetingCount'}
                      inputType={InputType.Number}
                      labelText={t('order.dated_meeting_count')}
                      inputValue={createData.datedMeetingCount || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'totalAmount'}
                      inputType={InputType.Number}
                      labelText={t('order.total_amount')}
                      inputValue={createData.totalAmount || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'netAmount'}
                      inputType={InputType.Number}
                      labelText={t('order.net_amount')}
                      inputValue={createData.netAmount || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'installmentCount'}
                      inputType={InputType.Number}
                      labelText={t('order.installment_count')}
                      inputValue={createData.installmentCount || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      minNumber={0}
                      maxNumber={12}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(createData).length > 12
                          ? ButtonType.Successbg
                          : ButtonType.Secondary
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onCreate}
                      buttonCol={2}
                      disabled={
                        Object.keys(createData).length > 12 ? false : true
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.create,
    content
  );
};
export default Create;
