import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import useLoader from '../../../hooks/useLoader';
import Card from '../../../components/Common/Card/Card';
import Button from '../../../components/Common/Button/Button';
import WeeklyCalendar from '../../../components/Common/Calendar/WeeklyCalendar';
import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import ProgressLine from '../../../components/Common/ProgressLine/ProgressLine';
import BasicTable from '../../../components/Common/Table/BasicTable';
import { Loader } from '../../../components/Common/Loader/Loader';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import BasicPopUp from '../../../components/Common/Popup/BasicPopUp';
import Modal from '../../../components/Common/Modal/Modal';

const options = [
  { key: 1, name: 'All Sessions', value: 1 },
  { key: 2, name: 'One to Sessions', value: 2 },
  { key: 3, name: 'Group Sessions', value: 3 },
];

export default function MentorDetailCalendar() {
  const location = useLocation();
  const navigate = useNavigate();
  // const goBack = () =>
  //   navigate('/mentors/mentor-detail/' + location?.state?.id, {
  //     state: {
  //       id: location?.state?.id,
  //       specialUrl: location?.state?.specialUrl,
  //     },
  //   });
  const goBack = () => navigate(-1);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const mentors = useSelector((state) => [state.calendar.getBySpeakerId]);
  const [color, setColor] = useState(location?.state?.selected);
  const [selectedDropdown, setSelectedDropdown] = useState(options.at(0).name);
  const [speakerDates, setSpeakerDates] = useState({
    groupedGroup: [],
    groupedOne: [],
  });

  const propertyNames = ['Pack', 'Order', 'Undated'];
  const speaker = useSelector((state) => [state.speaker.getById]);
  const [speakerId, setSpeakerId] = useState(
    location.state
      ? parseInt(location.state?.id)
      : parseInt(location.pathname.split('/mentors/mentor-detail/')[1])
  );
  const [date, setDate] = useState(null);

  const userPackage = useSelector((state) => state.userPackage.packageData);
  const [packageFilter, setPackageFilter] = useState(userPackage);
  const freeSession = location.state?.freeSession; // check for free session
  const [isBasicOpen, setIsBasicOpen] = useState(false);

  const handleSelectChange = (target) => {
    setSelectedDropdown(target.outerText);
    onClickColor(target.value);
  };
  const onClickColor = (set) => {
    set === 1
      ? setColor(undefined)
      : set === 2
      ? setColor('bg-success')
      : setColor('bg-warning');
    set === 2 && color === 'bg-success'
      ? setColor(undefined)
      : set === 3 && color === 'bg-warning'
      ? setColor(undefined)
      : (set = set);
  };
  function transformDataArray(dataArray) {
    const transformedArray = [];

    dataArray.forEach((item) => {
      const { speakerId, speakerAvailabilities } = item;
      const groupAvailabilities = [];
      const oneToOneAvailabilities = [];

      speakerAvailabilities?.forEach((availability) => {
        const { date, startTime, endTime, capacity, sessionType } =
          availability;

        const interval = `${startTime}-${endTime}`;
        const transformedAvailability = {
          date,
          capacity,
          interval,
        };

        if (sessionType === 'Group') {
          groupAvailabilities.push(transformedAvailability);
        } else if (sessionType === 'One to One') {
          oneToOneAvailabilities.push(transformedAvailability);
        }
      });

      transformedArray.push({
        speakerId,
        group: groupAvailabilities,
        one: oneToOneAvailabilities,
      });
    });
    const groupedGroup = transformedArray.reduce((acc, curr) => {
      curr.group.forEach(({ date, interval, capacity }) => {
        const existingGroup = acc.find(
          (group) => group.date === date && group.interval === interval
        );

        if (existingGroup) {
          existingGroup.speakerIds.push(curr.speakerId);
          existingGroup.capacities.push(capacity);
        } else {
          acc.push({
            date,
            interval,
            speakerIds: [curr.speakerId],
            capacities: [capacity],
          });
        }
      });
      return acc;
    }, []);
    const groupedOne = transformedArray.reduce((acc, curr) => {
      curr.one.forEach(({ date, interval }) => {
        const existingGroup = acc.find(
          (one) => one.date === date && one.interval === interval
        );

        if (existingGroup) {
          existingGroup.speakerIds.push(curr.speakerId);
        } else {
          acc.push({
            date,
            interval,
            speakerIds: [curr.speakerId],
          });
        }
      });
      return acc;
    }, []);
    setSpeakerDates({ groupedGroup: groupedGroup, groupedOne: groupedOne });
    return transformedArray;
  }
  useEffect(() => {
    dispatch(Actions.calendarActions.getByIdAction(speakerId));
    if (location.state !== null) {
      dispatch(Actions.speakerActions.getByIdAction(speakerId));
    }
  }, []);
  useEffect(() => {
    transformDataArray(mentors);
  }, [mentors]);

  const onClickFuncSetDate = () => {
    dispatch(Actions.orderActions.setSelectLaterOrder(userPackage));
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  const onDiscardItem = (e) => {
    e.preventDefault();
    setData([]);
    setIsOpen(false);
  };

  const handleModalClose = () => {
    setIsBasicOpen(false);
  };

  const [selectedCell, setSelectedCell] = useState(null);
  const handleCellSelect = (cellValue) => {
    setSelectedCell(cellValue);
  };

  const headers = [
    { key: 'pack', label: 'Pack' },
    {
      key: 'typeofSession',
      label: 'Type of Session',
    },
    { key: 'order', label: 'Order' },
    { key: 'undated', label: 'Undated' },
  ];
  return useLoader() ? (
    <Loader />
  ) : (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center mb-5 mt-5">
        <ProgressLine
          col={2}
          marginLeft={5}
          text={'Select Your Session'}
          textMarginLeft={2}
          icon={ButtonIcon.CheckCircle}
          iconColor={TextColors.Success}
        />
        <ProgressLine
          col={2}
          marginLeft={5}
          text={'Select Your Subscription'}
          textMarginLeft={4}
          icon={ButtonIcon.CheckCircle}
          iconColor={TextColors.Success}
        />
        <ProgressLine
          col={2}
          marginLeft={5}
          text={'Buy'}
          noLine
          textMarginLeft={5}
          icon={ButtonIcon.CheckCircle}
          iconColor={TextColors.Custom}
        />
      </div>
      <div className="row">
        <div className="col-11">
          <Card
            body={
              <div>
                <div className="row ms-3 mt-5 mb-2">
                  <div>
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={1}
                    />
                  </div>
                  <div className="col-md-6 col-12 ms-auto">
                    {data.length !== 0 ? (
                      <Card
                        body={
                          <>
                            {freeSession === undefined ? (
                              <div className="row">
                                {date === null ? (
                                  <div className="col-12">
                                    <BasicTable
                                      data={data}
                                      headers={headers}
                                      onClickFuncSetDate={onClickFuncSetDate}
                                      matchValues={{
                                        actionButtonProps: {
                                          key: 'pack',
                                          value: 'string',
                                        },
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="col-12">
                                    <BasicTable data={data} headers={headers} />
                                  </div>
                                )}
                                <BasicPopUp
                                  isOpen={isOpen}
                                  onClose={handleClose}
                                  position={{ top: 50, left: 100 }}
                                  text={'Are you sure discard this item?'}
                                  button={{ text: 'Yes' }}
                                  buttonFunc={onDiscardItem}
                                  buttonType={ButtonType.Danger}
                                />
                              </div>
                            ) : data.length !== 0 && freeSession === true ? (
                              <div className="row">
                                <p>
                                  Askıya alınmış oturumunuz bulunmaktadır.
                                  Dilerseniz seçimi şimdi dilerseniz de daha
                                  sonra yapabilirsiniz
                                </p>
                                <div className="col d-flex justify-content-start">
                                  <Button
                                    bold
                                    type={ButtonType.Successbg}
                                    text={'Select Later'}
                                    textColor={TextColors.Light}
                                    onClick={() => setIsBasicOpen(true)}
                                  />
                                </div>
                                <Modal
                                  isVisible={isBasicOpen}
                                  content={
                                    <p>{`Şu an askıya alıyoruz fakat X gün içerisinde yeni oturumunu seçmelisiniz`}</p>
                                  }
                                  footer={
                                    <Button
                                      type={ButtonType.Successbg}
                                      bold
                                      buttonCol={6}
                                      text={'Kapat'}
                                      onClick={handleModalClose}
                                    />
                                  }
                                  onClose={handleModalClose}
                                />
                                {/* <div className='col d-flex justify-content-end'>
                          <Button
                            bold
                            type={ButtonType.Successbg}
                            text={"Askıya al"}
                            textColor={TextColors.Light}
                            onClick={() => alert("Oturum Askıya alındı")}
                          />
                        </div> */}
                              </div>
                            ) : data.length !== 0 ? (
                              <div className="col d-flex justify-content-end">
                                <Button
                                  bold
                                  type={ButtonType.Successbg}
                                  text={'Continue'}
                                  textColor={TextColors.Light}
                                  buttonCol={4}
                                  onClick={() => navigate('/buy')}
                                />
                              </div>
                            ) : undefined}
                          </>
                        }
                      />
                    ) : undefined}
                  </div>
                </div>
                <WeeklyCalendar
                  color={color}
                  groupDatas={speakerDates?.groupedGroup}
                  oneDatas={speakerDates?.groupedOne}
                  dropDown={
                    <Dropdown
                      name={'ScheduleDropdown'}
                      options={options}
                      id={'ScheduleDropdown'}
                      title={selectedDropdown}
                      // backGroundAll={BackGroundColors.Custom}
                      handleChange={(target) => handleSelectChange(target)}
                      textColor={TextColors.Dark}
                      arrowUp
                      bordered
                    />
                  }
                />
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col">
                    <Button
                      type={ButtonType.Success}
                      textColor={TextColors.Light}
                      text={'One to One Sessions'}
                      bold
                      buttonCol={6}
                      onClick={() => {
                        onClickColor(2);
                      }}
                    />
                  </div>
                  <div className="col">
                    <Button
                      type={ButtonType.Warning}
                      text={'Group Sessions'}
                      bold
                      buttonCol={6}
                      onClick={() => {
                        onClickColor(3);
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
