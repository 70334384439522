import {
  create,
  getAll,
  remove,
} from '../../../services/apiServices/feedbackServices';
import ActionTypes from './actionTypes';
import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const createAction = (createData) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await create(createData).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      if (error?.response?.status === 400) {
        await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
        await dispatch(handleError(reponseStatus.fail));
      }
      else {
        await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
        await dispatch(handleError(reponseStatus.fail));
      }
    });
  };
};
const removeAction = (id) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    await remove(id).then(async (res) => {
      await dispatch(handleValidationReducer(res?.data?.message));
      await dispatch(handleError(res?.data?.isSuccess));
    }).catch(async error => {
      await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
      await dispatch(handleError(reponseStatus.fail));
    });;
  };
};
const getAllAction = () => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]))
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll();
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};

const getAllReducer = (data) => {
  return { type: ActionTypes.feedback.GetAll, payload: data };
};

const handleError = (data) => {
  return {
    type: ActionTypes.feedback.HandleError,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.feedback.HandleValidation,
    payload: data,
  };
};

const cleanState = (validation = false, ongoing) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
    }
  };
};

const feedbackActions = {
  createAction,
  getAllAction,
  removeAction,
  cleanState,
};
export default feedbackActions;
