import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faChevronRight,
  faChevronCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import Actions from '../../../store/redux/actions';
import useLoader from '../../../hooks/useLoader';
import truncateString from '../../../hooks/useTruncateString';
import { TabItem, Tabs } from '../../../components/Common/Tabs/Tabs';
import Card from '../../../components/Common/Card/Card';
import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import Input from '../../../components/Common/Input/Input';
import Button from '../../../components/Common/Button/Button';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Iframe from '../../../components/Common/Iframe/Iframe';
import WeeklyCalendar from '../../../components/Common/Calendar/WeeklyCalendar';
import { Loader } from '../../../components/Common/Loader/Loader';
import {
  InputType,
  ButtonIcon,
  TextColors,
  ButtonType,
  ButtonSize,
} from '../../../utils/ComponentEnums';
import learningPurposeSVG from '../../../assets/images/learning_purpose.svg';
import typeOfServiceSVG from '../../../assets/images/typeofservice.svg';
import sessionLevelSVG from '../../../assets/images/session_level.svg';
import meet2talkButton from '../../../assets/images/meet2talk_button.svg';
import dateSVG from '../../../assets/images/date.svg';
import servicesSVG from '../../../assets/images/services.svg';
import Calendar from '../AttendeePages/Calendar';
import getCookieItem from '../../../hooks/useGetCookieItem';
import CalendarDropdown from '../../../components/Common/Dropdown/CalendarDropdown';
import ProgressLine from '../../../components/Common/ProgressLine/ProgressLine';
import Tooltip from '../../../components/Common/ToolTip/Tooltip';
import Modal from '../../../components/Common/Modal/Modal';
import {
  serviceOptions,
  storageKeys,
  storageValues,
} from '../../../utils/Enum';
import findMyPath from '../../../hooks/useFindMyPath';
import filterTimeSet from '../../../hooks/useFilterTimeSet';

export default function Mentors() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const userInfo = useSelector((state) => state.user.getById);
  const locations = useSelector((state) => state.location.branches);
  const calendarData = useSelector((state) => state.calendar.getAllFiltered);
  const speakers = useSelector((state) => state.speaker.filteredSpeakers);
  const userPackage = useSelector((state) => state.userPackage);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const totalItem = useSelector((state) => state.speaker.pagination.TotalCount);
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const holdFilter = useSelector((state) => state.holdFilter.filter);
  const holdCreateData = useSelector((state) => state.holdFilter.createData);
  const staticDatas = useSelector((state) => state.staticData);
  const learningPurpose = staticDatas.LearningPurpose;
  const typeOfService = staticDatas.TypeOfService;
  const native = staticDatas.Native;
  const selectLaterItems = location.state?.selectLaterItems ?? {};
  const fromNavigateWhere = location.state?.fromNavigateWhere;
  const isFreeTrial = fromNavigateWhere === 'freeTrial';
  const isSetDate = fromNavigateWhere === 'setDate';
  const filterFromState = location.state?.createData;
  const isDirectlyGoingCalendar = location.state?.directlyCalendar;
  const stateFilter = { filterData: location.state?.filterData };
  const directlyBuyCalendar = location.state?.directlyBuyCalendar;
  const pageSize = 10;
  const pageNumber = 1;
  const freeTrialAble =
    speakers?.[0]?.[0]?.trialStatus === 'FreeTrial' ||
    speakers?.[0]?.[0]?.trialStatus === undefined
      ? true
      : false;
  const checkHasItems = userPackage.isPackageComplete;
  const firstSpeaker =
    speakers.length > 0 ? speakers[0].id : getCookieItem('sId');
  const isLoggedIn = auth !== '';
  const [filter, setFilter] = useState({});
  const isChild = isLoggedIn
    ? userInfo?.isChild ?? null
    : filter.LanguageLevels?.includes('Kids') ?? false;
  const isOnline = filter?.UserArea === 0 || userInfo?.userArea === 0;
  const isCafe = filter?.UserArea === 1 || userInfo?.userArea === 1;
  const isKids = filter?.UserArea === 2 || userInfo?.userArea === 2;
  const [createState, setCreateState] = useState({
    directlyCalendarData: {
      trialStatus:
        location.state?.trialStatus || location.state?.createData?.trialStatus,
      speakerId:
        location.state?.speakerId || location.state?.createData?.speakerId,
      speakerName:
        location.state?.speakerName || location.state?.createData?.speakerName,
      userArea:
        location.state?.userArea || location.state?.createData?.userArea,
      speakerImage:
        location.state?.speakerImage ||
        location.state?.createData?.speakerImage,
    },
  });
  const [online, setOnline] = useState(undefined);
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(undefined);
  const [showLoader, setShowLoader] = useState(false);
  const [menu, setMenu] = useState(
    filterFromState?.productType !== undefined ||
      checkHasItems ||
      selectLaterItems?.productType !== undefined ||
      isDirectlyGoingCalendar ||
      directlyBuyCalendar
      ? storageValues.currentMenu.schedule
      : Object.keys(userPackage.packageData).length > 0
      ? storageValues.currentMenu.schedule
      : location?.state?.menu
      ? storageValues.currentMenu.schedule
      : storageValues.currentMenu.normal
  );
  const [calendar, setCalendar] = useState(false);
  const [sideSpeaker, setSideSpeaker] = useState({});

  useEffect(() => {
    setCalendar(
      menu === storageValues.currentMenu.schedule &&
        filterFromState?.productType === undefined &&
        selectLaterItems?.productType === undefined &&
        !isDirectlyGoingCalendar
        ? true
        : false
    );
  }, [menu]);

  useEffect(() => {
    const storedCurrentMenu = sessionStorage.getItem(storageKeys.currentMenu);
    if (storedCurrentMenu) {
      setMenu(storedCurrentMenu);
    }
    return () => {
      dispatch(Actions.calendarActions.getFilteredReducer([]));
      sessionStorage.removeItem(storageKeys.currentMenu);
      sessionStorage.removeItem(storageKeys.resetMentors);
    };
  }, []);

  const handleMenuChange = (newMenu) => {
    setMenu(newMenu);
    sessionStorage.setItem(storageKeys.currentMenu, newMenu);
    if (newMenu === storageValues.currentMenu.schedule) {
      setCreateState({
        directlyCalendarData: {
          trialStatus: 2,
        },
      });
    }
  };

  useEffect(() => {
    if (auth !== undefined) {
      if (isOnline) {
        setOnline(directlyBuyCalendar);
      } else if (filter?.UserArea !== undefined) {
        setOnline(undefined);
      }
    }
  }, [auth, filter]);

  useEffect(() => {
    if (isVisible?.id !== undefined && filter.UserArea !== undefined) {
      const { strTime, endTime } = filterTimeSet();
      setSideSpeaker({
        TypeOfService: isCafe ? [1] : null,
        UserArea: filter.UserArea,
        SelectedIds: [isVisible.id],
        StartDateTime: strTime,
        EndDateTime: endTime,
      });
    }
  }, [isVisible]);

  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [currentSize, setCurrentSize] = useState(pageSize);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(0);
  const [isHoveredLastIndex, setIsHoveredLastIndex] = useState(false);

  useEffect(() => {
    if (
      calendarData?.groupSessions?.length !== 0 ||
      calendarData?.oneToOneSessions?.length !== 0
    ) {
      sessionStorage.setItem(storageKeys.resetMentors, storageValues.reset);
      sessionStorage.setItem(storageKeys.currentMenu, menu);
    }
  }, [calendarData]);

  useEffect(() => {
    if (window.performance) {
      if (
        performance.getEntriesByType('navigation')[0].type === 'reload' &&
        JSON.parse(sessionStorage.getItem(storageKeys.resetMentors)) === true
      ) {
        resetMe(true);
      }
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(!isMobile);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const resetMe = (val) => {
    if (calendar) {
      if (val) {
        location.state = {};
        setCreateState({});
        window.history.replaceState({}, '');
      }
    }
  };
  const [apiCall, setApiCall] = useState(false);

  const handleSelectChange = (target, isMulti = false, takeKey = false) => {
    setCreateState({});
    let name = target.name;
    let value = target.value;
    const optionValue = takeKey ? target.key : parseInt(value);

    if (isMulti) {
      const updatedArray = [...(filter[name] || [])];
      const valueIndex = updatedArray.indexOf(optionValue);

      if (valueIndex !== -1) {
        updatedArray.splice(valueIndex, 1);
      } else {
        updatedArray.push(optionValue);
      }
      setFilter({
        ...filter,
        [name]: updatedArray.length > 0 ? updatedArray : null,
      });
      setApiCall(true);
    }
    if (!isMulti) {
      if (name === 'UserArea') {
        if (filter[name] !== optionValue) {
          setFilter({
            ...filter,
            [name]: optionValue,
            Locations: optionValue === 0 || optionValue === 2 ? [1] : null,
            TypeOfService: optionValue === 0 || optionValue === 2 ? null : [1],
            AgeId: optionValue === 0 || optionValue === 1 ? 4 : null,
          });
        }
      } else {
        if (filter[name] === optionValue) {
          setFilter({
            ...filter,
            [name]: null,
          });
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
          });
        }
        setApiCall(true);
      }
    }
    setCurrentPage(pageNumber);
    setCurrentSize(pageSize);
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      const { value } = e.target;
      const newValue = value.trim() === '' ? null : value;
      const updatedFilter = { ...filter, FullName: newValue };
      setFilter(updatedFilter);
      dispatch(
        Actions.speakerActions.getFilteredAction(
          pageNumber,
          pageSize,
          updatedFilter
        )
      ).then(() => setShowLoader(false));
      setCurrentPage(1);
    }
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const newValue = value.trim() === '' ? null : value;
    setFilter({ ...filter, FullName: newValue });
  };
  const onSearchClick = (e) => {
    setShowLoader(true);
    e.preventDefault();
    const inputValue = document.getElementById('FullName').value;
    const newValue = inputValue.trim() === '' ? null : inputValue;
    const updatedFilter = { ...filter, FullName: newValue };
    setFilter(updatedFilter);
    dispatch(
      Actions.speakerActions.getFilteredAction(
        pageNumber,
        pageSize,
        updatedFilter
      )
    ).then(() => setShowLoader(false));
    setCurrentPage(1);
  };

  function handleNavigateCard(e, to, state) {
    e.preventDefault();
    setMenu('schedule');
    dispatch(Actions.calendarActions.getFilteredReducer([]));
    return navigate(to, state);
  }
  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (firstSpeaker !== 'undefined') {
      setIsVisible({ id: parseInt(firstSpeaker), call: true });
    }
  }, [firstSpeaker]);

  useEffect(() => {
    if (
      menu === storageValues.currentMenu.normal &&
      sideSpeaker.SelectedIds !== undefined &&
      isVisible?.call
    ) {
      setIsVisible({ ...isVisible, call: false });
      dispatch(
        Actions.calendarActions.getSelectedSpeakerDataAction(sideSpeaker)
      );
    }
  }, [sideSpeaker]);

  const handleMouseEnter = (index) => {
    setIsVisible({
      id: speakers[index]?.id,
      call: isVisible.id !== speakers[index]?.id,
    });
    setHoveredCardIndex(index);
    if (index === speakers.length - 1) {
      setIsHoveredLastIndex(true);
    } else {
      setIsHoveredLastIndex(false);
    }
  };

  const handleDateTime = (id, date) => {
    setShowLoader(true);
    const updatedFilter = { ...filter };
    const today = new Date();
    const dateOnly = today.toISOString().split('T')[0];

    if (id === 'dayOfWeek') {
      updatedFilter.DateDetails.selectedDate = null;
      if (updatedFilter.DateDetails.dayOfWeek === null) {
        if (updatedFilter.DateDetails.timeInterval !== null) {
          timeIntervalSet(date, true);
        }
        if (updatedFilter.DateDetails.selectedTime !== null) {
          selectedTimeSet(date, true);
        }
        updatedFilter.DateDetails.dayOfWeek = [`${date}T00:00:00.000Z`];
      } else {
        const selectedDate = `${date}T00:00:00.000Z`;
        const index = updatedFilter.DateDetails.dayOfWeek.indexOf(selectedDate);
        if (index === -1) {
          updatedFilter.DateDetails.dayOfWeek.push(`${date}T00:00:00.000Z`);
          if (updatedFilter.DateDetails.timeInterval !== null) {
            timeIntervalSet(date, false);
          }
          if (updatedFilter.DateDetails.selectedTime !== null) {
            selectedTimeSet(date, false);
          }
        } else {
          updatedFilter.DateDetails.dayOfWeek.splice(index, 1);
          if (updatedFilter.DateDetails.dayOfWeek.length === 0) {
            updatedFilter.DateDetails.dayOfWeek = null;
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(dateOnly, undefined, 'dayOfWeek');
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(dateOnly, undefined, 'dayOfWeek');
            }
          } else {
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(date, null);
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(date, null);
            }
          }
        }
      }
    }
    function timeIntervalSet(value, check) {
      updatedFilter.DateDetails.selectedTime = null;
      const dateTimePairs = updatedFilter.DateDetails.timeInterval.map(
        (interval) => {
          const [startDateTime, endDateTime] = interval.split('Z-');
          return [startDateTime, endDateTime];
        }
      );
      const updatedDateTimePairs = dateTimePairs.map((pair) => {
        const startHour = pair[0].split('T')[1].split(':')[0];
        const endHour = pair[1].split('T')[1].split(':')[0];
        const selectedDate = value;
        return `${selectedDate}T${startHour}:00:00.000Z-${selectedDate}T${endHour}:00:00.000Z`;
      });

      if (updatedFilter.DateDetails.timeInterval.length > 0) {
        const mergedTimeInterval = [
          ...updatedFilter.DateDetails.timeInterval,
          ...updatedDateTimePairs,
        ];
        let filteredDates = [];
        const checkStatus =
          (updatedFilter.DateDetails.dayOfWeek === null ||
            updatedFilter.DateDetails.selectedDate === null) &&
          !check;
        if (checkStatus) {
          filteredDates = updatedDateTimePairs.filter((date) =>
            date.includes(value)
          );
        }

        updatedFilter.DateDetails.timeInterval = check
          ? updatedDateTimePairs
          : checkStatus
          ? filteredDates
          : mergedTimeInterval;
      } else {
        updatedFilter.DateDetails.timeInterval = updatedDateTimePairs;
      }

      return updatedFilter.DateDetails.timeInterval;
    }
    function selectedTimeSet(value, check, parameter) {
      updatedFilter.DateDetails.timeInterval = null;
      const checkStatusEmpty =
        updatedFilter.DateDetails[parameter] === null && check === undefined;
      const checkStatusRemoved =
        updatedFilter.DateDetails[parameter] !== null && check === null;
      const hours = updatedFilter.DateDetails.selectedTime.map((interval) => {
        const [hour] = interval.split('T')[1].split(':');
        return [hour];
      });
      let updatedHours = [];
      if (!checkStatusRemoved) {
        updatedHours = hours.map((item) => {
          const selectedDate = value;
          return `${selectedDate}T${item}:00:00.000Z`;
        });
      }

      if (updatedFilter.DateDetails.selectedTime.length > 0) {
        const mergedTimeInterval = [
          ...new Set([
            ...updatedFilter.DateDetails.selectedTime,
            ...updatedHours,
          ]),
        ];
        let filteredDates = [];

        if (checkStatusEmpty) {
          filteredDates = updatedHours.filter((date) => date.includes(value));
        }
        if (checkStatusRemoved) {
          filteredDates = mergedTimeInterval.filter(
            (date) => !date.includes(value)
          );
        }

        updatedFilter.DateDetails.selectedTime = check
          ? updatedHours
          : checkStatusEmpty || checkStatusRemoved
          ? filteredDates
          : mergedTimeInterval;
      } else {
        updatedFilter.DateDetails.selectedTime = updatedHours;
      }

      return updatedFilter.DateDetails.selectedTime;
    }
    if (id === 'timeInterval') {
      updatedFilter.DateDetails.selectedTime = null;
      const cleanedDate = date.replace(/\s+/g, '');
      if (
        filter.DateDetails.dayOfWeek !== null ||
        filter.DateDetails.selectedDate !== null
      ) {
        updatedFilter.DateDetails.timeInterval =
          updatedFilter.DateDetails.timeInterval || [];

        const datesToProcess = [
          ...(filter.DateDetails.dayOfWeek || []),
          ...(filter.DateDetails.selectedDate || []),
        ];
        datesToProcess.forEach((dates) => {
          const selectedDate = dates.split('T')[0];
          updatedFilter.DateDetails.timeInterval.push(
            `${selectedDate}T${
              cleanedDate.split('-')[0]
            }:00:00.000Z-${selectedDate}T${
              cleanedDate.split('-')[1]
            }:00:00.000Z`
          );
        });
      } else if (filter.DateDetails.timeInterval === null) {
        updatedFilter.DateDetails.timeInterval = [
          `${dateOnly}T${cleanedDate.split('-')[0]}:00:00.000Z-${dateOnly}T${
            cleanedDate.split('-')[1]
          }:00:00.000Z`,
        ];
      } else if (filter.DateDetails.timeInterval !== null) {
        const newTimeSlot = `${dateOnly}T${
          cleanedDate.split('-')[0]
        }:00:00.000Z-${dateOnly}T${cleanedDate.split('-')[1]}:00:00.000Z`;
        const index =
          updatedFilter.DateDetails.timeInterval.indexOf(newTimeSlot);
        if (index === -1) {
          updatedFilter.DateDetails.timeInterval.push(newTimeSlot);
        } else {
          updatedFilter.DateDetails.timeInterval.splice(index, 1);
          if (updatedFilter.DateDetails.timeInterval.length === 0) {
            updatedFilter.DateDetails.timeInterval = null;
          }
        }
      }
    }
    if (id === 'selectedDate') {
      updatedFilter.DateDetails.dayOfWeek = null;
      if (updatedFilter.DateDetails.selectedDate === null) {
        if (updatedFilter.DateDetails.timeInterval !== null) {
          timeIntervalSet(date, true);
        }
        if (updatedFilter.DateDetails.selectedTime !== null) {
          selectedTimeSet(date, true);
        }
        updatedFilter.DateDetails.selectedDate = [`${date}T00:00:00.000Z`];
      } else {
        const selectedDate = `${date}T00:00:00.000Z`;
        const index =
          updatedFilter.DateDetails.selectedDate.indexOf(selectedDate);
        if (index === -1) {
          updatedFilter.DateDetails.selectedDate.push(`${date}T00:00:00.000Z`);
          if (updatedFilter.DateDetails.timeInterval !== null) {
            timeIntervalSet(date, false);
          }
          if (updatedFilter.DateDetails.selectedTime !== null) {
            selectedTimeSet(date, false);
          }
        } else {
          updatedFilter.DateDetails.selectedDate.splice(index, 1);
          if (updatedFilter.DateDetails.selectedDate.length === 0) {
            updatedFilter.DateDetails.selectedDate = null;
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(dateOnly, undefined, 'selectedDate');
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(dateOnly, undefined, 'selectedDate');
            }
          } else {
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(date, null);
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(date, null);
            }
          }
        }
      }
    }
    if (id === 'selectedTime') {
      updatedFilter.DateDetails.timeInterval = null;
      if (
        filter.DateDetails.dayOfWeek !== null ||
        filter.DateDetails.selectedDate !== null
      ) {
        updatedFilter.DateDetails.selectedTime =
          updatedFilter.DateDetails.selectedTime || [];

        const datesToProcess = [
          ...(filter.DateDetails.dayOfWeek || []),
          ...(filter.DateDetails.selectedDate || []),
        ];
        datesToProcess.forEach((dates) => {
          const selectedDate = dates.split('T')[0];
          updatedFilter.DateDetails.selectedTime.push(
            `${selectedDate}T${date}:00.000Z`
          );
        });
      } else if (filter.DateDetails.selectedTime === null) {
        updatedFilter.DateDetails.selectedTime = [
          `${dateOnly}T${date}:00.000Z`,
        ];
      } else if (filter.DateDetails.selectedTime !== null) {
        const newTimeSlot = `${dateOnly}T${date}:00.000Z`;
        const index =
          updatedFilter.DateDetails.selectedTime.indexOf(newTimeSlot);
        if (index === -1) {
          updatedFilter.DateDetails.selectedTime.push(
            `${dateOnly}T${date}:00.000Z`
          );
        } else {
          updatedFilter.DateDetails.selectedTime.splice(index, 1);
          if (updatedFilter.DateDetails.selectedTime.length === 0) {
            updatedFilter.DateDetails.selectedTime = null;
          }
        }
      }
    }
    setFilter(updatedFilter);
  };

  const combinedArray = [
    ...native.map((item) => ({
      key: item.key,
      name: item.name,
      value: item.value,
      group: item.name,
    })),
    ...learningPurpose.map((item) => ({
      key: item.key,
      name: item.name,
      value: item.value,
      group: item.name,
    })),
  ];

  function removeHTMLTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  const [isFetching, setIsFetching] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const totalPages = Math.ceil(totalItem / pageSize);
    const increaseFactor = Math.floor(currentPage / 2) * 0.4;
    const threshold = (1 + increaseFactor) * windowHeight * currentPage;

    if (
      scrollY >= threshold &&
      scrollY > prevScrollY &&
      currentPage < totalPages &&
      menu === storageValues.currentMenu.normal &&
      !isFetching
    ) {
      setIsFetching(true);
    }

    setPrevScrollY(scrollY);
    setScrollToTopVisible(scrollY > 2000);
  }, [window.scrollY]);

  useEffect(() => {
    if (isFetching) {
      const nextPage = currentPage + 1;
      const newCurrentSize = currentSize + pageSize;

      setCurrentPage(nextPage);
      setCurrentSize(newCurrentSize);
      dispatch(
        Actions.speakerActions.getFilteredAction(
          nextPage,
          pageSize,
          filter,
          speakers
        )
      ).then(() => {
        setIsFetching(false);
      });
    }
  }, [isFetching]);

  const debouncedHandleScroll = useCallback(debounce(handleScroll, 100), [
    handleScroll,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  function extractVideoId(url) {
    const patterns = [
      /(?:youtube\.com\/embed\/|youtu.be\/|youtube.com\/watch\?v=)([\w-]{11})/,
      /^([\w-]{11})$/,
    ];

    for (const pattern of patterns) {
      const match = url?.match(pattern);
      if (match) {
        return match[1];
      }
    }

    return null;
  }

  useEffect(() => {
    if (!isLoggedIn && !calendar && filter.UserArea !== undefined) {
      dispatch(
        Actions.userPackageActions.createAction({
          ...filterFromState,
          UserArea: filter.UserArea,
        })
      );
    }
  }, [filterFromState, menu]);

  const [isOpenAsk, setIsOpenAsk] = useState(false);

  function formatLearningPurposes(learningPurposes) {
    if (!learningPurposes || learningPurposes.length === 0) {
      return '2';
    }

    return learningPurposes.join(',');
  }

  const handleContinueParent = async () => {
    if (
      filterFromState?.trialStatus === 2 &&
      filterFromState?.timesPerWeek !== undefined
    ) {
      const productType =
        filterFromState?.productType === 0 ? 'Group' : 'Private';
      const myData = {
        productType: userPackage?.packageData?.productType || productType,
        languageLevel:
          userPackage?.packageData?.languageLevel || filter?.LanguageLevels,
        learningPurposes:
          userPackage?.packageData?.learningPurposes !== undefined
            ? formatLearningPurposes(userPackage?.packageData?.learningPurposes)
            : formatLearningPurposes(filter?.LearningPurpose),
        attendeeId: userInfo?.attendeeId,
        trialStatus: filterFromState?.trialStatus,
        userArea: filterFromState?.userArea,
        ageId: filterFromState.ageInterval,
        packageOptions: {
          type: 1,
          location: filterFromState?.location,
          monthDuration: filterFromState?.monthDuration,
          timesPerWeek: filterFromState?.timesPerWeek,
          sessionPackage: filterFromState?.sessionPackage,
          currency: filterFromState?.currency,
          couponCode: filterFromState?.couponCode,
        },
      };

      if (!isLoggedIn) {
        await dispatch(Actions.orderActions.selectSessionAction(myData));
        setIsOpenAsk(true);
      } else {
        await dispatch(Actions.orderActions.selectSessionAction(myData));
        if (menu === storageValues.currentMenu.normal) {
          await dispatch(Actions.orderActions.getBasketItemsAction());
        }
        await navigate(findMyPath('order-basket'));
      }
    }
  };

  const handleSelectedSpeakers = (value, sDate, eDate) => {
    if (filter.SelectedIds === null) {
      setFilter({
        ...filter,
        SelectedIds: [value],
        StartDateTime: sDate,
        EndDateTime: eDate,
      });
    } else {
      const isSelected = filter?.SelectedIds?.includes(value);
      const updatedSelectedIds = isSelected
        ? filter?.SelectedIds?.filter((id) => id !== value)
        : [...filter.SelectedIds, value];
      if (updatedSelectedIds?.length === 0) {
        setFilter({ ...filter, SelectedIds: null });
      } else {
        setFilter({
          ...filter,
          SelectedIds: updatedSelectedIds,
          StartDateTime: sDate,
          EndDateTime: eDate,
        });
        dispatch(
          Actions.calendarActions.getFilteredAction({
            ...filter,
            SelectedIds: updatedSelectedIds,
            StartDateTime: sDate,
            EndDateTime: eDate,
          })
        );
      }
    }
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    if (isKids) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (!isKids) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }

  const [initialState, setInitialState] = useState(true);
  // component mounted apiCall

  useEffect(() => {
    if (
      initialState &&
      ((!isLoggedIn && userInfo?.userArea === undefined) ||
        (isLoggedIn && userInfo?.userArea !== undefined))
    ) {
      const initialFilter = {
        LearningPurpose:
          filterFromState?.learningPurpose !== undefined
            ? filterFromState?.learningPurpose
            : holdFilter?.LearningPurpose?.map((item) => item) || null,
        Native: holdFilter?.Native?.map((item) => item) || null,
        FullName: null,
        UserArea:
          filterFromState?.userArea !== undefined
            ? filterFromState?.userArea
            : selectLaterItems?.userArea !== undefined
            ? selectLaterItems?.userArea
            : isLoggedIn
            ? userInfo?.userArea || holdFilter?.UserArea
            : location.state !== null
            ? holdCreateData?.userArea || holdFilter?.UserArea || 0
            : holdFilter?.UserArea || 0,
        Locations:
          filterFromState?.location !== null &&
          filterFromState?.location !== undefined
            ? filterFromState?.location
            : isSetDate
            ? [selectLaterItems?.location]
            : isCafe || holdFilter?.UserArea === 1
            ? holdFilter?.Locations?.map((item) => item) || null
            : [1],
        SelectedIds:
          createState?.directlyCalendarData?.speakerId !== undefined
            ? [createState?.directlyCalendarData?.speakerId]
            : null,
        PackageId:
          filterFromState?.trialStatus === 1
            ? null
            : filterFromState?.sessionPackage ||
              selectLaterItems?.packageId ||
              null,
        TrialStatus:
          selectLaterItems?.trialStatus ?? filterFromState?.trialStatus ?? 2,
        CompanyId: userInfo?.userArea === 3 ? userInfo?.companyId : 1,
        DateDetails: {
          dayOfWeek: holdFilter?.DateDetails?.dayOfWeek || null,
          timeInterval: holdFilter?.DateDetails?.timeInterval || null,
          selectedDate: holdFilter?.DateDetails?.selectedDate || null,
          selectedTime: holdFilter?.DateDetails?.selectedTime || null,
        },
      };

      initialFilter.LanguageLevels =
        filterFromState?.languageLevels !== undefined
          ? filterFromState?.languageLevels
          : selectLaterItems?.languageLevel !== undefined
          ? selectLaterItems?.languageLevel
          : (isChild && !isKids) || (!isChild && isChild !== null && isKids)
          ? null
          : userInfo?.languageLevel !== '' &&
            userInfo?.languageLevel !== undefined
          ? userInfo?.languageLevel
          : holdFilter?.LanguageLevels || null;

      initialFilter.TypeOfService =
        filterFromState?.productType !== undefined
          ? [filterFromState?.productType === 0 ? 1 : 0]
          : selectLaterItems?.productType !== undefined
          ? selectLaterItems?.productType === 'Private'
            ? [0]
            : [1]
          : holdFilter?.TypeOfService?.map((item) => item) || null;

      initialFilter.AgeId =
        filterFromState?.ageInterval !== undefined
          ? filterFromState?.ageInterval
          : selectLaterItems?.ageId !== undefined
          ? selectLaterItems?.ageId
          : initialFilter.UserArea !== 2
          ? 4
          : holdFilter?.AgeId || null;

      setInitialState(false);
      setFilter(initialFilter);
      setApiCall(true);
    }
  }, [isChild, auth, filter?.UserArea, userInfo]);

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(
        Actions.preferenceActions.getLevelGroupAction(filter?.UserArea ?? 0)
      );
    }
    if (filter.UserArea === 2 && ageInterval.length === 0) {
      dispatch(Actions.ageIntervalActions.getAllAction(2));
    }
    if (filter.UserArea === 1 && locations.length === 0) {
      dispatch(
        Actions.locationActions.getLocationsByUserAreaAction({
          userArea: 1,
        })
      );
    }
  }, [filter.UserArea]);

  // store update
  useEffect(() => {
    if (
      filter.TypeOfService !== undefined &&
      filter.Locations !== undefined &&
      filter.LearningPurpose !== undefined &&
      filter.Native !== undefined
    ) {
      const filterObject = {
        LearningPurpose: filter.LearningPurpose,
        LanguageLevels: filter.LanguageLevels,
        TypeOfService: filter.TypeOfService,
        Native: filter.Native,
        UserArea: filter.UserArea,
        TrialStatus: filter.TrialStatus,
        FullName: filter.FullName,
        Locations: filter.Locations,
        SelectedIds: filter.SelectedIds,
        PackageId: filter.PackageId,
        CompanyId: 1,
        AgeId: filter.AgeId,
        DateDetails: filter.DateDetails,
      };
      dispatch(Actions.filterActions.holdFilter(filterObject));
    }
  }, [filter]);

  useEffect(() => {
    if (
      apiCall &&
      !showLoader &&
      menu === storageValues.currentMenu.normal &&
      !initialState
    ) {
      setShowLoader(true);
      dispatch(
        Actions.speakerActions.getFilteredAction(pageNumber, pageSize, filter)
      ).then(() => {
        setShowLoader(false);
        setApiCall(false);
      });
    }
  }, [apiCall, initialState]);

  function setAreaName() {
    let txt = '';
    switch (filter?.UserArea) {
      case 0:
        txt = 'UserArea.0';
        break;
      case 1:
        txt = 'UserArea.1';
        break;
      case 2:
        txt = 'UserArea.2';
        break;
      case 3:
        txt = 'UserArea.3';
        break;

      default:
        txt = 'landing.filter_session_type';
        break;
    }
    return txt;
  }

  const mobileFilter = () => {
    return (
      !isSetDate && (
        <div className={`d-md-none flex-row`}>
          <div className="row d-flex align-items-center justify-content-center text-nowrap">
            {!isLoggedIn && (
              <div className="col-4 flex-fill">
                <Dropdown
                  key={'mentor_mobile_userArea'}
                  id={'userArea'}
                  title={t(setAreaName(), {
                    lng: 'en',
                  })}
                  propNames={{
                    key: 'name',
                    value: 'value',
                    name: 'key',
                  }}
                  options={
                    filterFromState?.userArea === undefined
                      ? serviceOptions
                      : undefined
                  }
                  selectedOptions={serviceOptions?.filter(
                    (val) =>
                      filter?.UserArea === val.value ||
                      holdFilter?.UserArea === val.value
                  )}
                  handleChange={(target) => {
                    handleSelectChange(target);
                  }}
                  svg={meet2talkButton}
                  rounded={2}
                  svgWidth={25}
                  arrowUp={filterFromState?.userArea === undefined}
                  noClickAble={filterFromState?.userArea !== undefined}
                  isFilter
                  keepState
                />
              </div>
            )}
            <div className="col-4 flex-fill">
              {!isCafe ? (
                <Dropdown
                  key={`main_typeOfService`}
                  id={'typeofService'}
                  title={
                    filterFromState?.productType === undefined
                      ? t('attendeeDashboard.type_of_service')
                      : filterFromState?.productType !== undefined &&
                        filterFromState?.productType === 0
                      ? t('mentor.group')
                      : t('mentor.onetoone')
                  }
                  propNames={{
                    key: 'name',
                    value: 'value',
                    name: 'key',
                  }}
                  options={typeOfService}
                  selectedOptions={typeOfService.filter((service) =>
                    filter?.TypeOfService?.includes(
                      parseInt(service.value) ||
                        holdFilter?.TypeOfService?.includes(
                          parseInt(service.value)
                        )
                    )
                  )}
                  handleChange={(target) => handleSelectChange(target, true)}
                  svg={typeOfServiceSVG}
                  rounded={2}
                  svgWidth={25}
                  arrowUp={filterFromState?.productType === undefined}
                  noClickAble={filterFromState?.productType !== undefined}
                  isFilter={filterFromState?.productType === undefined}
                  isMulti={filterFromState?.productType === undefined}
                />
              ) : (
                <Dropdown
                  key={`main_locations`}
                  id={'locations'}
                  title={
                    filterFromState?.location === undefined ||
                    filter?.TrialStatus === 0
                      ? t('attendeeDashboard.locations')
                      : locations?.filter((service) =>
                          filter?.Locations?.includes(parseInt(service.value))
                        )
                  }
                  propNames={{
                    key: 'key',
                    value: 'value',
                    name: 'key',
                  }}
                  options={locations}
                  selectedOptions={locations.filter((service) =>
                    filter?.Locations?.includes(parseInt(service.value))
                  )}
                  handleChange={(target) => handleSelectChange(target, true)}
                  svg={typeOfServiceSVG}
                  rounded={2}
                  svgWidth={25}
                  arrowUp={
                    filterFromState?.location === undefined ||
                    filter?.TrialStatus === 0
                  }
                  noClickAble={
                    filterFromState?.location !== undefined &&
                    filter?.TrialStatus !== 0
                  }
                  isFilter={
                    filterFromState?.location === undefined ||
                    filter?.TrialStatus === 0
                  }
                  isMulti={
                    filterFromState?.location === undefined ||
                    filter?.TrialStatus === 0
                  }
                />
              )}
            </div>
            {isLoggedIn && (
              <div className="col-5 flex-fill">
                {userInfo?.languageLevel !== undefined &&
                userInfo?.languageLevel !== null &&
                userInfo?.languageLevel !== '' &&
                ((isChild && isKids) || (!isChild && !isKids)) ? (
                  <div className="d-flex align-items-center justify-content-start">
                    <label className="text-nowrap">
                      <Tooltip
                        key={'main_mobile_languageLevel_tooltip'}
                        bottom={9}
                        id={'mentor_desmentor_mobile_filterktop_filter'}
                        text={
                          <span className="text-dark">
                            {t('personalInformation.tooltip')}
                          </span>
                        }
                      >
                        <Dropdown
                          key={'main_mobile_languageLevel'}
                          id={'languageLevels'}
                          noClickAble
                          rounded={2}
                          svgWidth={25}
                          title={t(`${filter?.LanguageLevels}`)}
                          svg={sessionLevelSVG}
                        />
                      </Tooltip>
                    </label>
                  </div>
                ) : (
                  <Dropdown
                    key={'main_mobile_languageLevel'}
                    id={'languageLevels'}
                    title={t(
                      filter?.LanguageLevels === null ||
                        filter?.LanguageLevels === undefined
                        ? 'attendeeDashboard.language_level'
                        : filter?.LanguageLevels
                    )}
                    propNames={{
                      key: 'key',
                      value: 'value',
                      name: 'key',
                    }}
                    options={
                      filterFromState?.languageLevels === undefined
                        ? selectedLanguageLevels
                        : []
                    }
                    selectedOptions={selectedLanguageLevels?.filter(
                      (val) => filter?.LanguageLevels === val.key
                    )}
                    handleChange={(target) =>
                      handleSelectChange(target, false, true)
                    }
                    svg={sessionLevelSVG}
                    rounded={2}
                    svgWidth={25}
                    resetState={filter.LanguageLevels === null}
                    arrowUp={filterFromState?.languageLevels === undefined}
                    noClickAble={filterFromState?.languageLevels !== undefined}
                    isFilter
                    addIndex
                  />
                )}
              </div>
            )}
            <div className="col-3 flex-fill">
              <div
                className="dropdown-header d-flex align-items-center justify-content-center"
                role="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasFilter"
                aria-controls="offcanvasFilter"
                style={{ borderColor: '#00c905' }}
              >
                <div style={{ position: 'relative' }}>
                  <img
                    src={learningPurposeSVG}
                    width={25}
                    alt={learningPurposeSVG}
                  />
                </div>
                <span className="dropdown-title">
                  {t('cardHeaderFilter.filter')}
                </span>
              </div>
            </div>
          </div>
          <div
            className="filter-dropdowns offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasFilter"
            aria-labelledby="offcanvasFilterLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasFilterLabel">
                {t('cardHeaderFilter.filter')}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body flex-row align-items-center justify-content-start py-2">
              {!isLoggedIn && (
                <div className="flex-fill">
                  <Dropdown
                    key={'main_mobile_languageLevel'}
                    id={'languageLevels'}
                    title={t(
                      filter?.LanguageLevels === null ||
                        filter?.LanguageLevels === undefined
                        ? 'attendeeDashboard.language_level'
                        : filter?.LanguageLevels
                    )}
                    propNames={{
                      key: 'key',
                      value: 'value',
                      name: 'key',
                    }}
                    options={
                      filterFromState?.languageLevels === undefined
                        ? selectedLanguageLevels
                        : []
                    }
                    selectedOptions={selectedLanguageLevels?.filter(
                      (val) => filter?.LanguageLevels === val.key
                    )}
                    handleChange={(target) =>
                      handleSelectChange(target, false, true)
                    }
                    rounded={2}
                    svgWidth={25}
                    svg={sessionLevelSVG}
                    addIndex
                    resetState={filter.LanguageLevels === null}
                    arrowUp={filterFromState?.languageLevels === undefined}
                    isFilter={filterFromState?.languageLevels === undefined}
                    noClickAble={filterFromState?.languageLevels !== undefined}
                  />
                </div>
              )}
              {isKids && (
                <div className="flex-fill">
                  <Dropdown
                    key={`main_ageInterval`}
                    id={'ageInterval'}
                    title={
                      filter?.AgeId === null ||
                      filter?.AgeId === undefined ||
                      ageInterval?.filter((f) => f.value === filter?.AgeId)?.[0]
                        ?.key === undefined
                        ? t('attendeeDashboard.ageInterval')
                        : `${
                            ageInterval?.filter(
                              (f) => f.value === filter?.AgeId
                            )?.[0]?.key
                          } ${t('attendeeDashboard.age')}`
                    }
                    propNames={{
                      key: 'name',
                      value: 'value',
                      name: 'key',
                    }}
                    options={ageInterval}
                    selectedOptions={ageInterval?.filter(
                      (val) => filter?.AgeId === val.value
                    )}
                    handleChange={(target) => handleSelectChange(target)}
                    rounded={2}
                    svgWidth={25}
                    svg={typeOfServiceSVG}
                    arrowUp={filterFromState?.ageInterval === undefined}
                    noClickAble={filterFromState?.ageInterval !== undefined}
                    isFilter={filterFromState?.ageInterval === undefined}
                  />
                </div>
              )}
              <div className="flex-fill">
                <Dropdown
                  key={'main_mobile_LearningPurpose'}
                  id={'learningPurpose'}
                  options={learningPurpose}
                  selectedOptions={learningPurpose.filter((service) =>
                    filter?.LearningPurpose?.includes(parseInt(service.value))
                  )}
                  title={t('attendeeDashboard.learning_purpose')}
                  propNames={{
                    key: 'name',
                    value: 'value',
                    name: 'key',
                  }}
                  handleChange={(target) => handleSelectChange(target, true)}
                  arrowUp
                  rounded={2}
                  svgWidth={25}
                  svg={learningPurposeSVG}
                  isMulti
                  isFilter
                />
              </div>
              <div className="flex-fill">
                <Dropdown
                  key={`main_mobile_Native`}
                  id={'native'}
                  title={t('attendeeDashboard.native')}
                  handleChange={(target) => handleSelectChange(target, true)}
                  options={native}
                  propNames={{
                    key: 'name',
                    value: 'value',
                    name: 'key',
                  }}
                  rounded={2}
                  svgWidth={25}
                  svg={servicesSVG}
                  selectedOptions={native.filter((service) =>
                    filter?.Native?.includes(parseInt(service.value))
                  )}
                  arrowUp
                  isFilter
                  isMulti
                />
              </div>
              {/* {menu === 'normal' && (
              <div className="flex-fill">
                <CalendarDropdown
                  title={t('attendeeDashboard.date_time')}
                  id={'dateTime'}
                  svg={dateSVG}
                  func={(val) => handleDateTime(val.id, val.date)}
                  selectedOptions={holdFilter?.DateDetails}
                />
              </div>
            )} */}
              {menu === storageValues.currentMenu.normal && (
                <div className="flex-fill my-3 me-1">
                  <div className="d-flex justify-content-evenly">
                    <Input
                      key={`main_fullName`}
                      type={InputType.Text}
                      id="FullName"
                      className={
                        'col-12 me-1 bg-m2t border-0 medium border-bottom border-secondary'
                      }
                      placeholder={t('attendeeDashboard.search_mentors')}
                      onKeyPress={onKeyPress}
                      onChange={onChangeSearch}
                      style={{ fontSize: '.86rem' }}
                    />
                    <Button
                      icon={ButtonIcon.Search}
                      borderless
                      iconColor={TextColors.Success}
                      onClick={onSearchClick}
                      buttonCol={1}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  };

  const content = (
    <div
      className={`container-fluid ${
        !isLoggedIn ? 'px-sm-5 py-sm-5 pt-3 bg-light' : ''
      }`}
    >
      <div className={` ${!isLoggedIn ? '' : 'container'}`}>
        <div className="d-flex container px-md-5 align-items-center justify-content-evenly">
          {isLoggedIn ? (
            <>
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.selection')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Success}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.summarize')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.pay')}
                textMarginLeft={0}
                lastItem
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
            </>
          ) : // : isLoggedIn && !userHasPhone ? (
          //   <>
          //     <ProgressLine
          //       marginLeft={0}
          //       text={t('progressLine.selection')}
          //       textMarginLeft={0}
          //       icon={ButtonIcon.CheckCircle}
          //       iconColor={TextColors.Success}
          //     />
          //     <ProgressLine
          //       marginLeft={0}
          //       text={t('progressLine.phone_confirmation')}
          //       textMarginLeft={0}
          //       icon={ButtonIcon.CheckCircle}
          //       iconColor={TextColors.Secondary}
          //     />
          //     <ProgressLine
          //       marginLeft={0}
          //       text={t('progressLine.summarize')}
          //       textMarginLeft={0}
          //       icon={ButtonIcon.CheckCircle}
          //       iconColor={TextColors.Secondary}
          //     />
          //     <ProgressLine
          //       marginLeft={0}
          //       text={t('progressLine.pay')}
          //       lastItem
          //       textMarginLeft={0}
          //       icon={ButtonIcon.CheckCircle}
          //       iconColor={TextColors.Secondary}
          //     />
          //   </>
          // )
          !isLoggedIn ? (
            <>
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.selection')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Success}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.login_register')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.summarize')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.pay')}
                lastItem
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
            </>
          ) : null}
        </div>
      </div>
      {menu === storageValues.currentMenu.normal && (
        <div className="d-inline-block py-2">
          <Button
            type={ButtonType.Successbg}
            icon={ButtonIcon.ArrowLeft}
            iconColor={TextColors.Light}
            bold
            onClick={goBack}
          />
        </div>
      )}
      {!isSetDate && !isFreeTrial && (
        <div className={`my-2 col`}>
          <div className={`row d-flex justify-content-between`}>
            {menu === storageValues.currentMenu.normal ? (
              <div className="col align-self-center my-auto">
                <HeadTags hSize={3} text={t('mentor.find_mentor')} strong />
              </div>
            ) : (
              <div className="d-inline-block py-2 col-1">
                <Button
                  type={ButtonType.Successbg}
                  icon={ButtonIcon.ArrowLeft}
                  iconColor={TextColors.Light}
                  bold
                  buttonCol={6}
                  onClick={goBack}
                />
              </div>
            )}
            <div
              className="col align-self-center me-2 p-0 m-0"
              style={{ maxWidth: '200px' }}
            >
              <div className="switch-selector-container text-nowrap me-auto">
                <div
                  className={`option-container fw-bold ${
                    menu === storageValues.currentMenu.normal ? 'active' : ''
                  }`}
                  onClick={() =>
                    handleMenuChange(storageValues.currentMenu.normal)
                  }
                  style={{ maxWidth: '100px' }}
                >
                  {t('mentor.listView')}
                </div>
                <div
                  className={`option-container fw-bold  ${
                    menu === storageValues.currentMenu.schedule ? 'active' : ''
                  }`}
                  onClick={() =>
                    handleMenuChange(storageValues.currentMenu.schedule)
                  }
                  style={{ maxWidth: '100px' }}
                >
                  {t('mentor.calendar')}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isSetDate && (
        <div className="row p-0 m-0">
          <div
            className={`p-0 m-0  ${
              menu === storageValues.currentMenu.normal
                ? !isLoggedIn
                  ? "col-12 col-lg-8'"
                  : 'col-12 col-lg-8'
                : 'col-12'
            }`}
          >
            <Card
              padding={8}
              body={
                <React.Fragment>
                  {/* Desktop Filter Section */}
                  {!isMobile && (
                    <div
                      className={`d-none d-md-flex flex-row align-items-center filter-section`}
                    >
                      {!isLoggedIn && (
                        <div className="flex-fill">
                          <Dropdown
                            key={'mentor_userArea'}
                            id={'userArea'}
                            title={t(setAreaName(), {
                              lng: 'en',
                            })}
                            propNames={{
                              key: 'name',
                              value: 'value',
                              name: 'key',
                            }}
                            options={
                              filterFromState?.userArea === undefined
                                ? serviceOptions
                                : []
                            }
                            selectedOptions={serviceOptions?.filter(
                              (val) =>
                                filter?.UserArea === val.value ||
                                holdFilter?.UserArea === val.value
                            )}
                            handleChange={(target) => {
                              handleSelectChange(target);
                            }}
                            svg={meet2talkButton}
                            rounded={2}
                            svgWidth={35}
                            keepState
                            arrowUp={filterFromState?.userArea === undefined}
                            isFilter={filterFromState?.userArea === undefined}
                            noClickAble={
                              filterFromState?.userArea !== undefined
                            }
                          />
                        </div>
                      )}
                      <div className="flex-fill">
                        {userInfo?.languageLevel !== undefined &&
                        userInfo?.languageLevel !== null &&
                        userInfo?.languageLevel !== '' &&
                        ((isChild && isKids) || (!isChild && !isKids)) ? (
                          <div className="d-flex align-items-center justify-content-start">
                            <label className="text-nowrap">
                              <Tooltip
                                bottom={9}
                                key={'main_languageLevel_tooltip'}
                                id={'mentor_desmentor_mobile_filterktop_filter'}
                                text={
                                  <span className="text-dark">
                                    {t('personalInformation.tooltip')}
                                  </span>
                                }
                              >
                                <Dropdown
                                  key={'main_languageLevel'}
                                  id={'languageLevels'}
                                  noClickAble
                                  title={t(`${filter?.LanguageLevels}`)}
                                  svg={sessionLevelSVG}
                                />
                              </Tooltip>
                            </label>
                          </div>
                        ) : (
                          <Dropdown
                            id={'languageLevels'}
                            key={'main_languageLevel'}
                            title={t(
                              filter?.LanguageLevels === null ||
                                filter?.LanguageLevels === undefined
                                ? 'attendeeDashboard.language_level'
                                : filter?.LanguageLevels
                            )}
                            options={
                              filterFromState?.languageLevels === undefined
                                ? selectedLanguageLevels
                                : undefined
                            }
                            propNames={{
                              key: 'key',
                              value: 'value',
                              name: 'key',
                            }}
                            arrowUp={
                              filterFromState?.languageLevels === undefined
                            }
                            isFilter
                            addIndex
                            handleChange={(target) =>
                              handleSelectChange(target, false, true)
                            }
                            svg={sessionLevelSVG}
                            selectedOptions={selectedLanguageLevels?.filter(
                              (val) => filter?.LanguageLevels === val.key
                            )}
                            resetState={filter.LanguageLevels === null}
                            noClickAble={
                              filterFromState?.languageLevels !== undefined
                            }
                          />
                        )}
                      </div>
                      {!isCafe &&
                        (filterFromState?.productType === undefined ? (
                          <div className="flex-fill text-nowrap">
                            <Dropdown
                              key={`main_typeOfService`}
                              id={'typeofService'}
                              title={t('attendeeDashboard.type_of_service')}
                              options={typeOfService}
                              propNames={{
                                key: 'name',
                                value: 'value',
                                name: 'key',
                              }}
                              handleChange={(target) =>
                                handleSelectChange(target, true)
                              }
                              svg={typeOfServiceSVG}
                              selectedOptions={typeOfService.filter((service) =>
                                filter?.TypeOfService?.includes(
                                  parseInt(service.value)
                                )
                              )}
                              arrowUp
                              isFilter
                              isMulti
                            />
                          </div>
                        ) : (
                          <div className="flex-fill">
                            <Dropdown
                              key={`main_typeOfService`}
                              id={'typeofService'}
                              title={
                                filterFromState?.productType !== undefined &&
                                filterFromState?.productType === 0
                                  ? t('mentor.group')
                                  : t('mentor.onetoone')
                              }
                              svg={typeOfServiceSVG}
                              noClickAble
                            />
                          </div>
                        ))}
                      {isCafe &&
                        (filterFromState?.location === null ||
                        filterFromState?.location === undefined ||
                        filter?.TrialStatus === 0 ? (
                          <div className="flex-fill">
                            <Dropdown
                              key={`main_locations`}
                              title={t('attendeeDashboard.locations')}
                              options={locations}
                              propNames={{
                                key: 'key',
                                value: 'value',
                                name: 'key',
                              }}
                              handleChange={(target) =>
                                handleSelectChange(target, true)
                              }
                              id={'locations'}
                              svg={typeOfServiceSVG}
                              arrowUp
                              isFilter
                              isMulti
                              selectedOptions={locations?.filter((service) =>
                                filter?.Locations?.includes(
                                  parseInt(service.value)
                                )
                              )}
                            />
                          </div>
                        ) : (
                          <Dropdown
                            key={`main_locations`}
                            id={'locations'}
                            title={
                              locations?.filter(
                                (service) =>
                                  filter?.Locations?.[0] ===
                                  parseInt(service.value)
                              )?.[0]?.key
                            }
                            svg={typeOfServiceSVG}
                            noClickAble
                          />
                        ))}
                      {isKids && (
                        <div className="flex-fill">
                          <Dropdown
                            key={`main_ageInterval`}
                            id={'ageInterval'}
                            title={
                              filter?.AgeId === null ||
                              filter?.AgeId === undefined
                                ? t('attendeeDashboard.ageInterval')
                                : `${
                                    ageInterval?.filter(
                                      (f) => f.value === filter?.AgeId
                                    )?.[0]?.key
                                  } ${t('attendeeDashboard.age')}`
                            }
                            options={ageInterval}
                            propNames={{
                              key: 'name',
                              value: 'value',
                              name: 'key',
                            }}
                            handleChange={(target) =>
                              handleSelectChange(target)
                            }
                            svg={typeOfServiceSVG}
                            selectedOptions={ageInterval?.filter(
                              (opt) => filter?.AgeId === opt.value
                            )}
                            arrowUp={filterFromState?.ageInterval === undefined}
                            noClickAble={
                              filterFromState?.ageInterval !== undefined
                            }
                            isFilter
                          />
                        </div>
                      )}
                      {/* {menu === 'normal' && (
                        <div className="flex-fill">
                          <CalendarDropdown
                            title={t('attendeeDashboard.date_time')}
                            id={'dateTime'}
                            svg={dateSVG}
                            func={(val) => handleDateTime(val.id, val.date)}
                            selectedOptions={holdFilter?.DateDetails}
                          />
                        </div>
                      )} */}
                      <div className="flex-fill">
                        <Dropdown
                          key={'main_SelectArea'}
                          options={combinedArray}
                          name={'Select Area'}
                          title={t('attendeeDashboard.more')}
                          svg={servicesSVG}
                          id={'More'}
                          propNames={{
                            key: 'name',
                            value: 'value',
                            name: 'key',
                          }}
                          handleChange={(target) =>
                            handleSelectChange(target, true, false)
                          }
                          arrowUp
                          selectedOptions={combinedArray.filter(
                            (item) =>
                              (filter?.Native?.includes(parseInt(item.value)) &&
                                item.name === 'Native') ||
                              (filter?.LearningPurpose?.includes(
                                parseInt(item.value)
                              ) &&
                                item.name === 'LearningPurpose')
                          )}
                          isFilter
                          isMulti
                          group
                        />
                      </div>
                      {menu === storageValues.currentMenu.normal && (
                        <div className="flex-fill py-2 pe-1">
                          <div className="d-flex justify-content-evenly">
                            <Input
                              key={'main_FullName'}
                              type={InputType.Text}
                              id="FullName"
                              className={
                                'col-12 bg-m2t border-0 medium border-bottom border-secondary'
                              }
                              placeholder={t(
                                'attendeeDashboard.search_mentors'
                              )}
                              onKeyPress={onKeyPress}
                              onChange={onChangeSearch}
                            />
                            <Button
                              icon={ButtonIcon.Search}
                              borderless
                              iconColor={TextColors.Success}
                              onClick={onSearchClick}
                              buttonCol={1}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* Mobile Filter Section */}
                  {isMobile && mobileFilter()}
                </React.Fragment>
              }
            />
          </div>
        </div>
      )}
      {menu === storageValues.currentMenu.normal ? (
        <div className="position-relative d-flex flex-wrap">
          {!isSetDate &&
            menu === storageValues.currentMenu.normal &&
            filterFromState?.productType === undefined &&
            !checkHasItems &&
            selectLaterItems?.productType === undefined && (
              <div className="d-flex d-lg-none flex-column text-nowrap fixed-bottom-container align-items-center mt-5">
                <div className="container d-flex bg-light justify-content-between p-2">
                  {freeTrialAble ? (
                    <div className="col px-1">
                      <Button
                        type={ButtonType.Success}
                        text={t('button.get_free_session')}
                        buttonCol={12}
                        size={ButtonSize.Small}
                        outline
                        bold
                        onClick={(e) =>
                          handleNavigateCard(
                            e,
                            isOnline
                              ? '/order/triallesson'
                              : isCafe
                              ? '/cafe/order/triallesson'
                              : isKids
                              ? '/kids/order/triallesson'
                              : '/order/triallesson',
                            {
                              state: {
                                fromNavigateWhere: 'freeTrial',
                                trialStatus: 0,
                              },
                            }
                          )
                        }
                      />
                    </div>
                  ) : null}

                  <div className="col px-1">
                    <Button
                      text={t('button.buy')}
                      type={ButtonType.Successbg}
                      textColor={TextColors.Light}
                      buttonCol={12}
                      size={ButtonSize.Small}
                      bold
                      onClick={(e) => {
                        setMenu('schedule');
                        setCreateState({
                          directlyCalendarData: {
                            trialStatus: 2,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

          {showLoader ? (
            <div className="d-flex mx-auto vh-100 align-items-center py-3 justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : speakers.length === 0 ? (
            <div className="mx-auto d-flex justify-content-center text-nowrap">
              {t('mentor.no_speaker_found')}
            </div>
          ) : (
            speakers.map((option, index) => (
              <div
                key={`s_d_${index}`}
                className="d-flex flex-column rounded-5 col-12 col-lg-8"
                onMouseEnter={() => handleMouseEnter(index)}
                style={{
                  overflow: 'hidden',
                  maxHeight: '275px',
                  height: '275px',
                }}
              >
                <Card
                  body={
                    <div className="container-fluid">
                      <div className="row">
                        <Link
                          className="text-decoration-none"
                          id={option?.id}
                          title={option?.metaDescription}
                          to={findMyPath(
                            `speakers/speaker-detail/${option?.id}`
                          )}
                          state={{
                            userArea: filter?.UserArea,
                            id: option?.id,
                          }}
                        >
                          <div className="col-12 d-flex align-items-center justify-content-center ms-3 mt-3">
                            {option?.speakerImage ? (
                              <img
                                src={option?.speakerImage}
                                className=" rounded-circle"
                                width={80}
                                height={80}
                                alt=""
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = '/images/empty-profile.png';
                                }}
                              />
                            ) : (
                              <img
                                src="/images/empty-profile.png"
                                className="img-fluid rounded-circle"
                                width={80}
                                height={80}
                                alt=""
                              />
                            )}
                            <div className="col mt-1 align-items-center">
                              <HeadTags
                                hSize={4}
                                marginLeft={2}
                                strong
                                text={option?.speakerName}
                                textColor={TextColors.Dark}
                              />
                              <div className="row">
                                <div className="col d-flex align-items-center justify-content-start">
                                  {/* <span className="ps-2 text-muted">4.</span>
                                <div>
                                  <FontAwesomeIcon
                                    className="text-warning ms-1"
                                    icon={faStar}
                                  />
                                  <FontAwesomeIcon
                                    className="text-warning ms-1"
                                    icon={faStar}
                                  />
                                  <FontAwesomeIcon
                                    className="text-warning ms-1"
                                    icon={faStar}
                                  />
                                  <FontAwesomeIcon
                                    className="text-warning ms-1"
                                    icon={faStar}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className="text-dark ms-1"
                                  />
                                </div> */}
                                  <div>
                                    <span className="ms-2 text-muted">
                                      {option.sessions}
                                    </span>
                                    <span className="ms-1 text-muted">
                                      {t('mentor.session_completed', {
                                        s: option.sessions > 1 ? 's' : '',
                                      })}
                                    </span>
                                  </div>
                                  {/* <div className="borderLeft ms-5">
                                  <FontAwesomeIcon
                                    className="text-danger ms-2"
                                    icon={faHeart}
                                  ></FontAwesomeIcon>
                                  <span className="ms-1 text-muted">1254</span>
                                </div> */}
                                </div>
                              </div>
                            </div>
                            {/* <div
                            className="col-1"
                            onClick={(e) => stopPropagation(e)}
                          >
                            <img src={mentorLike} alt=""></img>
                          </div> */}
                          </div>
                          <div>
                            <Tabs
                              stopBubbling={stopPropagation}
                              defaultIndex="1"
                              noAnimation={true}
                            >
                              {/* Bu kısımda iyileştirmeler gerekiyor. set inner HTML kullanmak istediğimiz bir çözüm değil burası geçici */}
                              <TabItem label={t('mentor.about')} index="1">
                                <div
                                  className={`card-text d-inline-block`}
                                  style={{
                                    fontSize: '1.25em',
                                    overflowY: 'hidden',
                                    height: '65px',
                                    maxHeight: '65px',
                                  }}
                                >
                                  {removeHTMLTags(
                                    truncateString(option?.htmlContent, 300)
                                  )}
                                </div>
                              </TabItem>
                              {/* <TabItem
                                label={t('mentor.as_a_mentor')}
                                index="2"
                              >
                                {option?.metaDescription}
                              </TabItem>
                              <TabItem label={t('mentor.style')} index="3">
                                {option?.urlName}
                              </TabItem>
                              <TabItem label={t('mentor.materials')} index="4">
                                {option?.metaTitle}
                              </TabItem> */}
                            </Tabs>
                          </div>
                        </Link>
                      </div>
                    </div>
                  }
                  key={`card_mentors_${index}`}
                />
              </div>
            ))
          )}
          {!showLoader && isVisible?.id && speakers.length !== 0 && (
            <div
              className={`container col-lg-4 d-none d-lg-block position-absolute rounded-3 ${
                isVisible?.id ? 'overflow-hidden' : ''
              }`}
              style={{
                top:
                  isHoveredLastIndex && speakers.length > 1
                    ? Math.max((hoveredCardIndex - 1) * 275, 0)
                    : hoveredCardIndex * 275,
                right: 0,
                transition: 'transform 0.4s ease 0s',
                willChange: 'transform',
              }}
            >
              {speakers.map((option, index) => (
                <Card
                  id={option?.id}
                  body={
                    <>
                      <div className="row">
                        <Iframe
                          videoId={extractVideoId(option?.specialUrl)}
                          width={'100%'}
                          height={210}
                        />
                      </div>
                      <div key={`side_div_calendar${index}`} className="row">
                        <WeeklyCalendar
                          isSmallCalendar
                          sideOn
                          key={`calendar_mentors_side${index}`}
                        />
                      </div>
                      <span
                        className="ms-2"
                        role={'button'}
                        onClick={(e) => {
                          setMenu('schedule');
                          setCreateState({
                            directlyCalendarData: {
                              trialStatus: 2,
                              speakerId: isVisible?.id,
                              speakerName:
                                isVisible?.id === option.id &&
                                option.speakerName,
                              speakerImage:
                                isVisible?.id === option.id &&
                                option.speakerImage,
                              userArea: filter.UserArea,
                            },
                          });
                        }}
                      >
                        <strong>{t('mentor.see_full_schedule')}</strong>
                        <FontAwesomeIcon
                          className="ms-2"
                          icon={faChevronRight}
                        />
                      </span>
                      <div className="d-flex justify-content-evenly align-items-center text-nowrap">
                        {option.trialStatus !== 'NonTrial' && (
                          <div className="col px-3 py-2">
                            <Button
                              text={
                                option.trialStatus === 'PaidTrial'
                                  ? t('button.paid_trial')
                                  : t('button.free_trial')
                              }
                              type={ButtonType.Success}
                              size={ButtonSize.Small}
                              buttonCol={12}
                              outline
                              bold
                              onClick={(e) => {
                                option?.trialStatus === 'FreeTrial' ||
                                option.trialStatus === 'PaidTrial'
                                  ? handleNavigateCard(
                                      e,
                                      isOnline
                                        ? '/order/triallesson'
                                        : isCafe
                                        ? '/cafe/order/triallesson'
                                        : isKids
                                        ? '/kids/order/triallesson'
                                        : '/order/triallesson',
                                      {
                                        state: {
                                          fromNavigateWhere:
                                            option?.trialStatus === 'FreeTrial'
                                              ? 'freeTrial'
                                              : 'paidTrial',
                                          trialStatus:
                                            option?.trialStatus === 'FreeTrial'
                                              ? 0
                                              : 1,
                                          speakerId: isVisible?.id,
                                          speakerName:
                                            isVisible?.id === option.id &&
                                            option.speakerName,
                                          speakerImage:
                                            isVisible?.id === option.id &&
                                            option.speakerImage,
                                          userArea: filter.UserArea,
                                        },
                                      }
                                    )
                                  : setMenu('schedule');
                                setCreateState({
                                  directlyCalendarData: {
                                    trialStatus: 1,
                                    speakerId: isVisible?.id,
                                    speakerName:
                                      isVisible?.id === option.id &&
                                      option.speakerName,
                                    speakerImage:
                                      isVisible?.id === option.id &&
                                      option?.speakerImage,
                                    userArea: filter.UserArea,
                                  },
                                });
                                setFilter({
                                  ...filter,
                                  SelectedIds: [isVisible?.id],
                                });
                              }}
                            />
                          </div>
                        )}
                        <div className="col px-3 py-2">
                          <Button
                            text={t('button.buy')}
                            type={ButtonType.Successbg}
                            textColor={TextColors.Light}
                            size={ButtonSize.Small}
                            buttonCol={12}
                            bold
                            onClick={(e) => {
                              setMenu('schedule');
                              setCreateState({
                                directlyCalendarData: {
                                  trialStatus: 2,
                                  speakerId: isVisible?.id,
                                  speakerName:
                                    isVisible?.id === option.id &&
                                    option.speakerName,
                                  speakerImage:
                                    isVisible?.id === option.id &&
                                    option?.speakerImage,
                                  userArea: filter.UserArea,
                                },
                              });
                              setFilter({
                                ...filter,
                                SelectedIds: [isVisible?.id],
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                  visible={isVisible?.id}
                  cardRounded={4}
                  padding={0}
                  key={`side_card_mentors_${index}`}
                />
              ))}
            </div>
          )}

          {/* {isFetching ? (
            <div
              style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 9999,
                backgroundColor: 'lightgray',
                opacity: 0.5,
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="spinner-border " role="status"></div>
            </div>
          ) : null} */}
          <Modal
            key={`login_or_register_question`}
            isVisible={isOpenAsk}
            styleProps={{ maxWidth: 500 }}
            title={t('calendar.please_log_in_to_buy_sessions')}
            titleMS={0}
            content={
              <div className="d-flex justify-content-evenly">
                <div className=" ">
                  <Button
                    text={t('navbar.log_in')}
                    type={ButtonType.Success}
                    outline
                    bold
                    onClick={() => {
                      navigate('/account/login');
                      setIsOpenAsk(false);
                    }}
                    buttonCol={12}
                  />
                </div>
                <div className="">
                  <Button
                    text={t('navbar.register')}
                    type={ButtonType.Successbg}
                    bold
                    textColor={TextColors.Light}
                    onClick={() => {
                      navigate('/account/register');
                      setIsOpenAsk(false);
                    }}
                    buttonCol={12}
                  />
                </div>
              </div>
            }
            onClose={() => setIsOpenAsk(false)}
          />
          {!isSetDate && (
            <div
              style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                marginBottom: '2rem',
                marginRight: '2rem',
                zIndex: 50,
              }}
            >
              {menu === storageValues.currentMenu.normal &&
              filterFromState?.productType !== undefined &&
              selectLaterItems?.productType === undefined ? (
                <Button
                  bold
                  type={ButtonType.Successbg}
                  outline
                  hidden={
                    filterFromState?.trialStatus === 0 ||
                    filterFromState?.trialStatus === 1 ||
                    (filterFromState?.orderItemDetails === undefined &&
                      filterFromState?.TimesPerWeek === undefined)
                      ? true
                      : filterFromState?.trialStatus === 0 &&
                        filterFromState?.orderItemDetail !== undefined
                      ? false
                      : false
                  }
                  disabled={
                    filterFromState?.timesPerWeek !== undefined ? false : true
                    // selectedSpeaker?.selectedIds === null
                    // ? false
                    // : handleButton
                    // ? true
                    // : false
                  }
                  text={
                    (filterFromState?.orderItemDetails?.SelectedDate?.length ===
                      undefined ||
                      filterFromState?.orderItemDetails?.SelectedDate
                        ?.length === 0) &&
                    t('button.select_later')
                  }
                  textColor={TextColors.Light}
                  onClick={handleContinueParent}
                />
              ) : (
                <div
                  className={`scroll-to-top m-3 ${
                    scrollToTopVisible ? 'd-block' : 'd-none'
                  }`}
                >
                  <Button
                    bold
                    type={ButtonType.Successbg}
                    text={<FontAwesomeIcon icon={faChevronCircleUp} />}
                    textColor={TextColors.Light}
                    onClick={scrollToTop}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <Calendar
          filterData={filter}
          packageModel={filterFromState}
          fromWhere={fromNavigateWhere}
          selectLaterItems={selectLaterItems}
          stateFilter={stateFilter}
          fromMentorCalendarButton={calendar}
          menuStatus={
            menu === storageValues.currentMenu.schedule ? true : false
          }
          createState={createState}
          getMeSpeaker={(val, date) => {
            handleSelectedSpeakers(val, date);
            resetMe(true);
          }}
          searchFromChild={(val) => {
            let text = val === '' ? null : val;
            setFilter({ ...filter, FullName: text });
          }}
          handleContinueParent={handleContinueParent}
          locationReset={(val) => resetMe(val)}
        />
      )}
    </div>
  );
  return useLoader() ? <Loader /> : content;
}
