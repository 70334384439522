import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../utils/ComponentEnums';
import calculateUTC from '../../../hooks/useCalculateUTC';
import Tooltip from '../../../components/Common/ToolTip/Tooltip';
import getTimeZone from '../../../hooks/useGetTimeZone';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';

const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const meetingSchema = useSelector(
    (state) => state.meetingSchema.meetingSchema
  );
  const companyList = useSelector((state) => state.company.getAll);
  const managerList = useSelector((state) => state.manager.getAll);
  const locationList = useSelector((state) => state.location.getAll);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const sessionPackageList = useSelector(
    (state) => state.sessionPackage.getAll
  );
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const dayList = staticDatas.DayOfWeeks;
  const dropdownListSpeaker = useSelector(
    (state) => state.meetingSchema.dropdownList
  );
  const goBack = () => navigate(-1);
  const redirect = '/meeting-schema-list';
  const [createData, setCreateData] = useState(meetingSchema);
  const serviceType = staticDatas.UserArea;
  const [resetState, setResetState] = useState(null);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    setCreateData({
      ...createData,
      [id]: type === 'number' ? parseInt(value) : value,
    });
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.meetingSchemaActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.meetingSchemaActions.createAction(createData));
  };
  const getList = async () => {
    await dispatch(Actions.managerActions.getAllAction('Dropdown'));
    await dispatch(Actions.companyActions.dropdownListAction());
    await dispatch(Actions.locationActions.getAllAction());
    await dispatch(Actions.sessionPackageActions.getAllAction());
    await dispatch(Actions.ageIntervalActions.getAllAction());
  };

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  const getSpeakerDropdownlist = async () => {
    await dispatch(
      Actions.meetingSchemaActions.getSpeakerDropdownListAction(createData)
    );
  };
  useEffect(() => {
    if (
      createData?.languageLevels !== undefined &&
      createData?.startTime !== undefined &&
      createData?.dayOfWeek !== undefined &&
      createData?.locationId !== undefined &&
      createData?.sessionPackageId !== undefined &&
      dropdownListSpeaker.length === 0
    ) {
      getSpeakerDropdownlist();
    }
  }, [createData]);

  useEffect(() => {
    getList();
    return () => {
      dispatch(Actions.meetingSchemaActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (resetState) {
      getList();
      dispatch(Actions.meetingSchemaActions.cleanState());
      setResetState(null);
    }
  }, [resetState]);

  const handleSelectedDays = (value) => {
    // Save for future logic about multiple selected day insert logic.
    const numbsArray = createData?.dayOfWeeks?.split(',');
    if (numbsArray?.length === undefined) {
      const updatedData = { ...createData, dayOfWeeks: value };
      setCreateData(updatedData);
      dispatch(Actions.meetingSchemaActions.holdValue(updatedData));
    }
    if (numbsArray?.includes(value)) {
      const index = numbsArray.indexOf(value);
      if (index !== -1) {
        const newDayOfWeeks = numbsArray;
        newDayOfWeeks.splice(index, 1);
        const updatedData = {
          ...createData,
          dayOfWeeks: newDayOfWeeks.join(','),
        };
        setCreateData(updatedData);
        if (newDayOfWeeks?.length === 0) {
          const newData = { ...createData };
          delete newData.dayOfWeeks;
          setCreateData(newData);
          dispatch(Actions.meetingSchemaActions.holdValue(newData));
        } else {
          dispatch(Actions.meetingSchemaActions.holdValue(updatedData));
        }
      }
    }
    if (
      numbsArray?.length !== undefined &&
      !createData.dayOfWeeks?.includes(value)
    ) {
      const updatedData = {
        ...createData,
        dayOfWeeks: createData.dayOfWeeks + ',' + value,
      };
      setCreateData(updatedData);
      dispatch(Actions.meetingSchemaActions.holdValue(updatedData));
    }
  };

  const timeSlotsNormal = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const val = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;
      timeSlotsNormal.push({
        key: val,
      });
    }
  }

  function calculateNewDate(time, date, convert = false, options = {}) {
    if (date !== undefined && date !== null) {
      const valueFromInterval = time?.split('-')[0];
      const [hours, minutes] = valueFromInterval.split(':').map(Number);

      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day, hours, minutes);
      let adjustedDate = new Date(
        currentDate.getTime() - getTimeZone(true) * 60000
      );
      const resultDate = `${adjustedDate
        .getDate()
        .toString()
        .padStart(2, '0')}-${(adjustedDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${adjustedDate.getFullYear()}`;

      if (convert) {
        const formattedDate = adjustedDate?.toLocaleDateString(
          'en-US',
          options
        );
        return formattedDate;
      }

      return resultDate;
    }
  }

  const fixTime = (time) => {
    const dayOfWeekMap = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };
    const [h, m] = time.split(':').map(Number);
    const today = new Date();
    today.setHours(h, m);
    today.setMinutes(today.getMinutes() - getTimeZone(true));
    today.setDate(
      today.getDate() +
        ((dayOfWeekMap[createData?.dayOfWeekView] + 7 - today.getDay()) % 7)
    );
    const result = calculateNewDate(
      `${time}-`,
      `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getFullYear()}`
    );

    const [d, mo, y] = result.split('-').map(Number);
    const resultDayOfWeek = new Date(y, mo - 1, d);
    const dayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    handleDropdown(
      'startTime',
      `${y}-${mo.toString().padStart(2, '0')}-${d
        .toString()
        .padStart(2, '0')}T${calculateUTC(time, true)}:00.000Z`,
      dayNames[resultDayOfWeek.getDay()]
    );
  };

  const handleDropdown = (propName, value, extraValue) => {
    if (extraValue) {
      setCreateData({
        ...createData,
        [propName]: value,
        dayOfWeek: extraValue,
      });
      dispatch(
        Actions.meetingSchemaActions.holdValue({
          ...createData,
          [propName]: value,
          dayOfWeek: extraValue,
        })
      );
    } else {
      setCreateData({ ...createData, [propName]: value });
      dispatch(
        Actions.meetingSchemaActions.holdValue({
          ...createData,
          [propName]: value,
        })
      );
    }
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    if (createData?.userArea === 2) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (
      createData?.userArea !== 2 &&
      createData?.userArea !== undefined
    ) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }

  const ageIntervalWithOptions = ageInterval?.map((item) => ({
    ...item,
    name: `${item.minAge} - ${item.maxAge}`,
    value: item.id,
    userArea: item.userArea,
  }));

  const formattedTime = (startTime) => {
    return startTime
      ? new Date(startTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : '';
  };
  useEffect(() => {
    if (createData.startTime !== undefined) {
      fixTime(formattedTime(createData.startTime));
    }
  }, [createData?.dayOfWeekView]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-12 col-md-1 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('meetingSchema.adding_new_meeting_schema_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <DropdownWithSearch
                      placeHolder={t('dropdownSelect.select_user_area')}
                      options={serviceType}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      onChange={(value) => {
                        if (
                          parseInt(value) !== createData?.userArea &&
                          createData?.userArea !== undefined
                        ) {
                          const data = {
                            userArea: parseInt(value),
                            userAreaString: serviceType
                              .find((val) => val.value === value)
                              ?.path.split('/')[1],
                          };
                          setCreateData(data);
                          setResetState(true);
                        } else {
                          setCreateData({
                            ...createData,
                            userArea: parseInt(value),
                            userAreaString: serviceType
                              .find((val) => val.value === value)
                              ?.path.split('/')[1],
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2 mt-md-0">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('dropdownSelect.select_age_interval')}
                      options={ageIntervalWithOptions.filter(
                        (area) =>
                          area.userArea === createData?.userArea ||
                          (area.userArea === 4 &&
                            createData?.userArea !== 2 &&
                            createData?.userArea !== undefined)
                      )}
                      selectedProps={{ key: 'name', value: 'value' }}
                      onChange={(value) =>
                        handleDropdown('ageIntervalId', value)
                      }
                      selectRule={t('dropdownWithSearch.select_rule_area')}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('dropdownSelect.select_location')}
                      options={
                        createData?.userArea === 3
                          ? locationList.filter(
                              (area) =>
                                area.locationName.includes('Company') ||
                                area.locationName.includes('Online')
                            )
                          : locationList.filter(
                              (area) =>
                                area.locationArea === createData?.userArea ||
                                (area.locationArea === 0 &&
                                  createData?.userArea === 2)
                            )
                      }
                      selectedProps={{ key: 'locationName', value: 'id' }}
                      onChange={(value) => handleDropdown('locationId', value)}
                      selectRule={t('dropdownWithSearch.select_rule_area')}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('dropdownSelect.select_level')}
                      options={selectedLanguageLevels}
                      selectedProps={{ key: 'key', value: 'key' }}
                      onChange={(value) =>
                        handleDropdown('languageLevels', value)
                      }
                      selectRule={t('dropdownWithSearch.select_rule_area')}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      resetState={resetState}
                      placeHolder={t('dropdownSelect.select_session_package')}
                      options={
                        createData?.userAreaString === 'company'
                          ? sessionPackageList
                          : sessionPackageList.filter((item) =>
                              item.displayName
                                .toLowerCase()
                                .includes(
                                  createData?.userAreaString?.toLowerCase()
                                )
                            )
                      }
                      selectedProps={{
                        key: 'person',
                        supKey: 'minute',
                        value: 'id',
                        display: 'displayName',
                      }}
                      onChange={(value) =>
                        handleDropdown('sessionPackageId', value)
                      }
                      selectRule={t('dropdownWithSearch.select_rule_area')}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      resetState={resetState}
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_days')}
                      options={dayList}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) => {
                        setCreateData({
                          ...createData,
                          dayOfWeekView: value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    {createData?.dayOfWeekView === undefined ? (
                      <label className="col-12 text-nowrap">
                        <Tooltip
                          bottom={2}
                          id={'meetingScheame_tooltip_2'}
                          text={
                            <span className="text-danger fw-bold">
                              {t('meetingSchema.select_date')}
                            </span>
                          }
                        >
                          <DropdownWithSearch
                            isSearchable
                            resetState={resetState}
                            onBlur={onBlur}
                            placeHolder={t('dropdownSelect.start_time')}
                            options={timeSlotsNormal}
                            selectedProps={{ key: 'key', value: 'key' }}
                            onChange={(value) => {
                              fixTime(value);
                            }}
                            noTranslate={true}
                            disable={createData?.dayOfWeekView === undefined}
                          />
                        </Tooltip>
                      </label>
                    ) : (
                      <DropdownWithSearch
                        isSearchable
                        resetState={resetState}
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.start_time')}
                        options={timeSlotsNormal}
                        selectedProps={{ key: 'key', value: 'key' }}
                        onChange={(value) => {
                          fixTime(value);
                        }}
                        noTranslate={true}
                        disable={createData?.dayOfWeekView === undefined}
                      />
                    )}
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    {createData?.languageLevels === undefined ||
                    createData?.startTime === undefined ||
                    createData?.dayOfWeek === undefined ||
                    createData?.locationId === undefined ||
                    createData?.sessionPackageId === undefined ? (
                      <label className="col-12 text-nowrap">
                        <Tooltip
                          bottom={2}
                          id={'meetingScheame_tooltip'}
                          text={
                            <span className="text-danger fw-bold">
                              {t('meetingSchema.tooltip')}
                            </span>
                          }
                        >
                          <DropdownWithSearch
                            isSearchable
                            onBlur={onBlur}
                            placeHolder={t('dropdownSelect.select_speaker')}
                            options={dropdownListSpeaker}
                            selectedProps={{
                              key: 'speakerName',
                              value: 'speakerId',
                            }}
                            onChange={(value) => {
                              handleDropdown('speakerId', value);
                            }}
                            disable={
                              createData?.languageLevels === undefined ||
                              createData?.startTime === undefined ||
                              createData?.dayOfWeek === undefined ||
                              createData?.locationId === undefined ||
                              createData?.sessionPackageId === undefined
                                ? true
                                : false
                            }
                          />
                        </Tooltip>
                      </label>
                    ) : (
                      <DropdownWithSearch
                        isSearchable
                        key={'main_dropdown'}
                        onBlur={onBlur}
                        placeHolder={t('dropdownSelect.select_speaker')}
                        options={dropdownListSpeaker}
                        selectedProps={{
                          key: 'speakerName',
                          value: 'speakerId',
                        }}
                        onChange={(value) => {
                          handleDropdown('speakerId', value);
                        }}
                        disable={
                          createData?.languageLevels === undefined &&
                          createData?.startTime === undefined &&
                          createData?.dayOfWeek === undefined &&
                          createData?.locationId === undefined &&
                          createData?.sessionPackageId === undefined
                            ? true
                            : false
                        }
                      />
                    )}
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      resetState={resetState}
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_manager')}
                      options={managerList}
                      selectedProps={{ key: 'managerName', value: 'managerId' }}
                      onChange={(value) => handleDropdown('managerId', value)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      resetState={resetState}
                      onBlur={onBlur}
                      placeHolder={t('dropdownSelect.select_company')}
                      options={companyList}
                      selectedProps={{
                        key: 'companyName',
                        value: 'companyId',
                      }}
                      onChange={(value) => handleDropdown('companyId', value)}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <InputLabelOnBorder
                      id={'startDate'}
                      onBlur={onBlur}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={createData.startDate || ''}
                      onChange={onChangeText}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={createData.endDate || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-3 mt-2">
                    <InputLabelOnBorder
                      id={'privateCapacity'}
                      inputType={InputType.Number}
                      labelText={t('meetingSchema.private_capacity')}
                      inputValue={createData.privateCapacity || 0}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      minNumber={0}
                      maxNumber={5}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'meetingSchema'}
                        onClick={onCreate}
                        objectLengthRule={
                          Object.keys(createData).length >= 12 ? false : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'meetingSchema'}
        onClose={() => {
          if (dropdownListSpeaker?.length !== 0) {
            setCreateData({});
            dispatch(Actions.meetingSchemaActions.cleanState(true));
            navigate(redirect);
          }
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.meetingSchema.create,
    content
  );
};
export default Create;
