import { checkLogged } from '../../hooks/useCheckLogged';
import { storageKeys, storageValues } from '../../utils/Enum';
import axios from '../axios';
import { postRequest } from '../axiosRequest';
import endPoint from './endPointAddresses';
import refreshPage from '../../hooks/useReload';
import removeCookieItem from '../../hooks/useRemoveCookie.js';

export const register = async (registerData) => {
  return await postRequest(endPoint.auth.REGISTER_URL, registerData);
};

export const login = async (loginData) => {
  return await postRequest(endPoint.auth.LOGIN_URL, loginData);
};

export const logout = async () => {
  const userId = checkLogged();
  return await postRequest(`${endPoint.auth.Logout}/${userId}`).then(() => {
    removeCookieItem([
      storageKeys.firstRegister,
      storageKeys.firstRegisterLanding
    ]);
    sessionStorage.removeItem(storageKeys.utmData);
    sessionStorage.removeItem(storageKeys.utmDataInsert);
    sessionStorage.removeItem(storageKeys.currentMenu);
    sessionStorage.removeItem(storageKeys.resetMentors);
    sessionStorage.removeItem(storageKeys.landingRule);
    sessionStorage.removeItem(storageKeys.levelGroup);
    sessionStorage.removeItem(storageKeys.currency);
    sessionStorage.removeItem(storageKeys.userArea);
    sessionStorage.removeItem(storageKeys.tiktok);
    localStorage.removeItem(storageKeys.token);
    refreshPage();
  });
};

export function setCookie(cname, cvalue) {
  document.cookie = `${cname}=${cvalue}; secure; SameSite=Lax; path=/;`;
}
export function setAreaDetails(userArea) {
  let myBoard;
  let enumValue;
  let path;

  switch (parseInt(userArea)) {
    case 0:
      myBoard = 'Online';
      path = '/online';
      enumValue = 0;
      break;
    case 1:
      myBoard = 'Cafe';
      path = '/cafe';
      enumValue = 1;
      break;
    case 2:
      myBoard = 'Kids';
      path = '/kids';
      enumValue = 2;
      break;
    case 3:
      myBoard = 'Company';
      path = '/company';
      enumValue = 3;
      break;
    default:
      myBoard = 'None';
      path = '';
      enumValue = 4;
      break;
  }
  sessionStorage.setItem(storageKeys.path, path);
  sessionStorage.setItem(storageKeys.userArea, myBoard)
  return { path, myBoard, enumValue };
}
export const refreshToken = async () => {
  const userId = checkLogged();
  const data = { token: '', isSuccess: false };
  await axios
    .post(endPoint.auth.REFRESH_URL + userId)
    .then(async (response) => {
      if (response?.data?.isSuccess) {
        const userArea = response?.data?.data?.userArea;
        setAreaDetails(userArea);
        const token = response?.data?.data?.token;
        const parts = token?.split('.');
        const payload = JSON.parse(window?.atob(parts[1]));
        const version = payload.website;
        const currentVersion = localStorage.getItem(storageKeys.UIVersion);
        if (
          parseInt(currentVersion) < parseInt(version) ||
          currentVersion === null
        ) {
          localStorage.setItem(storageKeys.hardReset, storageValues.hardReset);
        }
        localStorage.setItem(storageKeys.token, token);
        localStorage.setItem(storageKeys.UIVersion, version);
        data.token = token;
        data.isSuccess = true;
        return data;
      } else {
        await logout();
      }
    })
    .catch(async (error) => {
      await logout();
    });
  return data;
};

export const oauthGoogleRegister = async (
  googleToken,
  userLanguage,
  currency,
  refId
) => {
  return await postRequest(endPoint.googleAuth.REGISTER_URL, {
    token: googleToken,
    userLanguage: userLanguage,
    currency: currency,
    refId: refId,
  });
};

export const oauthGoogleLogin = async (googleToken, userLanguage) => {
  return await postRequest(endPoint.googleAuth.LOGIN_URL, {
    token: googleToken,
    userLanguage: userLanguage,
  });
};

export const oauthFacebookRegister = async (
  facebookToken,
  userLanguage,
  currency,
  refId
) => {
  return await postRequest(endPoint.facebookAuth.REGISTER_URL, {
    token: facebookToken,
    userLanguage: userLanguage,
    currency: currency,
    refId: refId,
  });
};

export const oauthFacebookLogin = async (facebookToken, userLanguage) => {
  return await postRequest(endPoint.facebookAuth.LOGIN_URL, {
    token: facebookToken,
    userLanguage: userLanguage,
  });
};
