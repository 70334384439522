import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import Card from '../../../../components/Common/Card/Card';
import Button from '../../../../components/Common/Button/Button';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../../utils/ComponentEnums';
import Claims from '../../../../utils/Claims';
import checkClaims from '../../../../hooks/useCheckClaims';

const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const timesPerMonth = useSelector(
    (state) => state.timesPerMonth.timesPerMonth
  );
  const isSuccess = useSelector((state) => state.timesPerMonth.isSuccess);
  const goBack = () => navigate(-1);
  const [createData, setCreateData] = useState(timesPerMonth);
  const redirect = '/times-per-month-list';
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.timesPerMonthActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.timesPerMonthActions.createAction(createData));
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(redirect);
      dispatch(Actions.timesPerMonthActions.handleError(''));
    }
  }, [isSuccess]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('timesPerMonth.adding_new_times_per_month_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'perMonth'}
                      inputType={InputType.Number}
                      labelText={t('timesPerMonth.per_month')}
                      inputValue={createData?.perMonth || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'monthOptionSessionValue'}
                      inputType={InputType.Number}
                      labelText={t('timesPerMonth.session_value')}
                      inputValue={createData?.monthOptionSessionValue || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(createData).length > 1
                          ? ButtonType.Successbg
                          : ButtonType.Secondary
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onCreate}
                      buttonCol={2}
                      disabled={
                        Object.keys(createData).length > 1 ? false : true
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.timesPerMonth.create,
    content
  );
};
export default Create;
