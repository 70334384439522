import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import Card from '../../../../components/Common/Card/Card';
import { InputType } from '../../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../store/redux/actions';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import { useLocation, useNavigate } from 'react-router-dom';
import { TimesPerWeek } from '../../../../utils/Enum';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import SubmitButton from '../../../../components/Common/Button/SubmitButton';
import Toast from '../../../../components/Common/Popup/Toast';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = location?.state?.id;
  const companyData = useSelector((state) => state.company.companyOrders);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const sessionPackageList = useSelector((state) => state.sessionPackage.getAll);
  const [createData, setCreateData] = useState({});
  const redirect = '/company-list';

  async function FetchById() {
    await dispatch(Actions.companyActions.getCompanyOrdersAction(companyId));
  }

  useEffect(() => {
    FetchById();
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.sessionPackageActions.getAllAction());
    return () => {
      dispatch(Actions.companyActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(companyData).length > 0 &&
      Object.keys(createData).length === 0
    ) {
      setCreateData({ ...companyData });
    }
  }, [companyData]);

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.companyActions.cleanState());
  };

  const onChangeDetails = (index, field, value) => {
    const updatedDetails = [...createData.companyPackageOptions];
    updatedDetails[index][field] = value;
    setCreateData({ ...createData, companyPackageOptions: updatedDetails });
  };

  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.companyActions.createCompanyOrdersAction(createData)
    );
  };

  const handleDropdown = (index, propName, value) => {
    const updatedDetails = [...createData.companyPackageOptions];
    updatedDetails[index][propName] = value;

    setCreateData({ ...createData, companyPackageOptions: updatedDetails });
  };

  const addDetailRow = () => {
    setCreateData({
      ...createData,
      companyPackageOptions: [
        ...createData.companyPackageOptions,
        {
          userArea: 3,
          location: '',
          sessionPackage: '',
          timesPerWeek: '',
          totalSessiosessionQuantitynAmount: '',
          orderQuantity: '',
          userAreaString: undefined,
        },
      ],
    });
  };

  const deleteDetailRow = (index) => {
    const updatedDetails = [...createData.companyPackageOptions];
    updatedDetails.splice(index, 1);
    setCreateData({ ...createData, companyPackageOptions: updatedDetails });
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-12">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('company.companyOrderUpdateFrom')}
                  />
                </div>
                <div className="row">
                  {createData?.companyPackageOptions?.map((detail, index) => (
                    <React.Fragment key={index}>
                      <div className="d-flex align-items-center justify-content-evenly mt-md-4">
                        <div className="col-lg-2 me-1">
                          <DropdownWithSearch
                            id={`details[${index}].location`}
                            placeHolder={t('dropdownSelect.select_location')}
                            selectedValues={
                              createData?.companyPackageOptions[index]?.location
                            }
                            options={locationList.filter(
                              (location) =>
                                location.locationName === 'Online' ||
                                location.locationName === 'Company' ||
                                location.locationName === 'CompanyOnline'
                            )}
                            selectedProps={{
                              key: 'locationName',
                              value: 'id',
                              searchKey: 'id',
                            }}
                            onChange={(value) =>
                              handleDropdown(index, 'location', parseInt(value))
                            }
                            lockSelection={true}
                          />
                        </div>
                        <div className="col-lg-2">
                          <DropdownWithSearch
                            id={`details[${index}].sessionPackage`}
                            placeHolder={t(
                              'dropdownSelect.select_session_package'
                            )}
                            options={sessionPackageList.filter(
                              (item) =>
                                !item.displayName.toLowerCase().includes('kids')
                            )}
                            selectedValues={
                              createData?.companyPackageOptions[index]
                                ?.sessionPackage
                            }
                            selectedProps={{
                              key: 'person',
                              supKey: 'minute',
                              value: 'id',
                              display: 'displayName',
                              searchKey: 'id',
                            }}
                            onChange={(value) =>
                              handleDropdown(index, 'sessionPackage', value)
                            }
                            lockSelection={true}
                          />
                        </div>
                        <div className="col-lg-2">
                          <DropdownWithSearch
                            id={`details[${index}].timesPerWeek`}
                            placeHolder={t('giftOrder.timesPerWeek')}
                            options={TimesPerWeek}
                            selectedValues={
                              createData?.companyPackageOptions[index]
                                ?.timesPerWeek
                            }
                            selectedProps={{
                              key: 'perWeek',
                              value: 'perWeek',
                              searchKey: 'perWeek',
                            }}
                            onChange={(value) =>
                              handleDropdown(index, 'timesPerWeek', value)
                            }
                            lockSelection={true}
                          />
                        </div>
                        <div className="col-lg-2">
                          <InputLabelOnBorder
                            id={`details[${index}].sessionQuantity`}
                            inputType={InputType.Number}
                            labelText={t('company.sessionQuantity')}
                            selectedValues={
                              createData?.companyPackageOptions[index]
                                ?.sessionQuantity
                            }
                            inputValue={detail.sessionQuantity}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'sessionQuantity',
                                e.target.value
                              )
                            }
                            disabled={true}
                          />
                        </div>
                        <div className="col-lg-2">
                          <InputLabelOnBorder
                            id={`details[${index}].orderQuantity`}
                            inputType={InputType.Number}
                            labelText={t('company.orderQuantity')}
                            selectedValues={
                              createData?.companyPackageOptions[index]
                                ?.orderQuantity
                            }
                            inputValue={detail.orderQuantity}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'orderQuantity',
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="justify-content-end px-4">
                          <Button
                            type={ButtonType.Danger}
                            size={ButtonSize.Small}
                            icon={ButtonIcon.TrashCan}
                            onClick={() => deleteDetailRow(index)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="col-3 mt-3">
                    <Button
                      type={ButtonType.Primary}
                      buttonCol={12}
                      text={t('company.addMoreOrderType')}
                      onClick={addDetailRow}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <SubmitButton
                      stateName={'company'}
                      onClick={onCreate}
                      objectLengthRule={
                        Object.keys(createData).length >= 2 ? false : true
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'company'}
        onClose={() => {
          dispatch(Actions.companyActions.cleanState(true));
          setCreateData({});
          navigate(redirect);
        }}
      />
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.company.create,
    content
  );
};
export default Update;
