import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.user.Update:
      return {
        ...state,
        user: {},
        getById: [],
      };
    case ActionTypes.user.GetById:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.user.GetAll:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.user.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.user.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.user.SelfUpdate:
      return {
        ...state,
        selfUpdate: action.payload,
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        getById: [],
      };
    default:
      return state;
  }
};

export default userReducer;
