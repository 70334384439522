import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Actions from '../../../store/redux/actions';
import useLoader from '../../../hooks/useLoader';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { Loader } from '../../../components/Common/Loader/Loader';

export default function Campaigns() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.campaign.getAll);
  const navigate = useNavigate();

  const handleCampaignClick = (e, val) => {
    e.preventDefault();
    const { name, id } = val;
    navigate('/campaign/detail/' + name, {
      state: { name: name, id: id },
    });
  };
  useEffect(() => {
    dispatch(Actions.campaignActions.getActiveCampaignsAction());
    window.scrollTo(0, 0);
  }, [dispatch]);

  const content = (
    <div className="container">
      <div className="col-12 m-3">
        <div className="row align-items-center">
          <div className="col me-auto align-self-center">
            <HeadTags
              hSize={2}
              marginLeft={4}
              text={t('campaing.campaigns')}
              strong
            />
          </div>
        </div>
      </div>
      <div className="position-relative d-flex align-items-start flex-wrap w-100">
        {campaigns.length === 0 && <p>{t('campaing.noCampaign')}</p>}
        {campaigns?.map((val, i) => (
          <div
            key={`s_d_${i}`}
            className="d-flex flex-column px-3 col-12 col-md-4 mb-4"
          >
            <Link
              className="text-decoration-none"
              onClick={(e) => handleCampaignClick(e, val)}
            >
              <div className="col d-flex align-items-center justify-content-start">
                <img
                  src={val?.image}
                  className="img-fluid rounded"
                  alt={val?.name}
                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
              </div>
              <div className="col text-start mt-2">
                <p className="text-muted">{val?.title}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );

  return useLoader() ? <Loader /> : content;
}
