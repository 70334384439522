import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import Button from '../../../components/Common/Button/Button';
import { TabItem, Tabs } from '../../../components/Common/Tabs/Tabs';
import WeeklyCalendar from '../../../components/Common/Calendar/WeeklyCalendar';
import Iframe from '../../../components/Common/Iframe/Iframe';
import { Loader } from '../../../components/Common/Loader/Loader';
import { useTranslation } from 'react-i18next';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import ProgressLine from '../../../components/Common/ProgressLine/ProgressLine';
import findMyPath from '../../../hooks/useFindMyPath';
import filterTimeSet from '../../../hooks/useFilterTimeSet';

const MentorDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const holdFilter = useSelector((state) => state.holdFilter.filter);
  const auth = useSelector((state) => state.auth.auth);
  const speaker = useSelector((state) => state.speaker.getById);
  const [showLoader, setShowLoader] = useState(true);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    return () => {
      dispatch(Actions.speakerActions.getByIdReducer([]));
      dispatch(Actions.calendarActions.getSelectedSpeakerDataReducer([]));
    };
  }, []);

  const [isAttendee, setIsAttendee] = useState(undefined);
  useEffect(() => {
    if (auth !== undefined) {
      if (auth === '') {
        setIsAttendee(false);
      } else {
        setIsAttendee(true);
      }
    }
  }, [auth]);
  const [speakerId, setSpeakerId] = useState(undefined);
  useEffect(() => {
    setSpeakerId(
      location.state !== null
        ? parseInt(location.state?.id)
        : parseInt(location.pathname.split('/speakers/speaker-detail/')[1])
    );
    setTimeout(() => {
      setShowLoader(false);
    }, 1500);
  }, []);
  useEffect(() => {
    if (speakerId !== undefined) {
      const { strTime, endTime } = filterTimeSet();
      setFilter({
        TypeOfService:
          holdFilter?.UserArea === 1
            ? [1]
            : holdFilter?.TypeOfService?.map((item) => item) || null,
        UserArea: holdFilter?.UserArea || 0,
        SelectedIds: [speakerId],
        StartDateTime: strTime,
        EndDateTime: endTime,
      });
    }
  }, [speakerId]);
  useEffect(() => {
    if (filter?.UserArea !== undefined) {
      dispatch(Actions.calendarActions.getSelectedSpeakerDataAction(filter));
    }
  }, [filter]);
  useEffect(() => {
    if (speakerId !== undefined) {
      dispatch(Actions.speakerActions.getByIdAction(speakerId));
    }
  }, [speakerId]);

  function handleNavigateCard(e, to, state) {
    e.preventDefault();
    dispatch(Actions.calendarActions.getFilteredReducer([]));
    return navigate(to, state);
  }

  function extractVideoId(url) {
    const patterns = [
      /(?:youtube\.com\/embed\/|youtu.be\/|youtube.com\/watch\?v=)([\w-]{11})/,
      /^([\w-]{11})$/,
    ];

    for (const pattern of patterns) {
      const match = url?.match(pattern);
      if (match) {
        return match[1];
      }
    }

    return null;
  }

  return showLoader ? (
    <Loader />
  ) : (
    <div
      className={`container-fluid pb-5 ${
        auth === '' ? 'px-sm-5 py-sm-5 py-3 bg-light' : ''
      }`}
    >
      <div className={` ${auth === '' ? '' : 'container'}`}>
        <div className="d-flex container px-md-5 align-items-center justify-content-evenly">
          {auth !== '' ? (
            <>
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.selection')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Success}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.summarize')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.pay')}
                textMarginLeft={0}
                lastItem
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
            </>
          ) : auth === '' ? (
            <>
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.selection')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Success}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.login_register')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.summarize')}
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
              <ProgressLine
                marginLeft={0}
                text={t('progressLine.pay')}
                lastItem
                textMarginLeft={0}
                icon={ButtonIcon.CheckCircle}
                iconColor={TextColors.Secondary}
              />
            </>
          ) : null}
        </div>
      </div>
      <div className="d-inline-block py-2">
        <Button
          type={ButtonType.Successbg}
          icon={ButtonIcon.ArrowLeft}
          iconColor={TextColors.Light}
          bold
          onClick={goBack}
        />
      </div>
      <div className="row">
        <div className={`d-lg-none`}>
          <Card
            padding={5}
            body={
              <>
                <div className="row">
                  <Iframe
                    videoId={extractVideoId(speaker?.specialUrl)}
                    width={'100%'}
                    height={210}
                  />
                </div>
              </>
            }
            key={`mentor_detail_card2_${speaker?.id}`}
          />
          <div className="d-flex flex-column text-nowrap fixed-bottom-container align-items-center mt-3">
            <div className="container d-flex bg-light justify-content-between p-2">
              {speaker?.trialStatus !== 'NonTrial' && (
                <div className="col-6 px-1">
                  <Button
                    text={
                      speaker?.trialStatus === 'PaidTrial'
                        ? t('button.paid_trial')
                        : t('button.free_trial')
                    }
                    type={ButtonType.Success}
                    buttonCol={12}
                    size={ButtonSize.Small}
                    outline
                    bold
                    onClick={(e) =>
                      speaker?.trialStatus === 'FreeTrial' &&
                      filter?.UserArea !== 2
                        ? handleNavigateCard(
                            e,
                            filter?.UserArea === 0
                              ? '/order/triallesson'
                              : filter?.UserArea === 1
                              ? '/cafe/order/triallesson'
                              : filter?.UserArea === 2
                              ? '/kids/order/triallesson'
                              : '/order/triallesson',
                            {
                              state: {
                                fromNavigateWhere: 'freeTrial',
                                trialStatus: 0,
                                userArea: filter?.UserArea,
                                speakerId: speaker?.id,
                                speakerImage: speaker?.speakerImage,
                                speakerName: speaker?.speakerName,
                              },
                            }
                          )
                        : speaker?.trialStatus === 'PaidTrial'
                        ? handleNavigateCard(
                            e,
                            filter?.UserArea === 0
                              ? '/order/triallesson'
                              : filter?.UserArea === 1
                              ? '/cafe/order/triallesson'
                              : filter?.UserArea === 2
                              ? '/kids/order/triallesson'
                              : '/order/triallesson',
                            {
                              state: {
                                fromNavigateWhere: 'paidTrial',
                                trialStatus: 1,
                                userArea: filter?.UserArea,
                                speakerId: speaker?.id,
                                speakerName: speaker?.speakerName,
                                speakerImage: speaker?.speakerImage,
                              },
                            }
                          )
                        : null
                    }
                  />
                </div>
              )}
              <div className="col-6 px-1">
                <Button
                  text={t('button.buy')}
                  type={ButtonType.Successbg}
                  textColor={TextColors.Light}
                  size={ButtonSize.Small}
                  buttonCol={12}
                  bold
                  onClick={(e) =>
                    handleNavigateCard(e, findMyPath('speakers'), {
                      state: {
                        directlyBuyCalendar: true,
                        trialStatus: 2,
                        userArea: filter?.UserArea,
                        speakerId: speaker?.id,
                        speakerName: speaker?.speakerName,
                        speakerImage: speaker?.speakerImage,
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column rounded-5 col-12 col-lg-8">
          <Card
            body={
              <div id={speaker?.userId} className="container-fluid">
                <div className="row mt-sm-3">
                  <div className="d-flex flex-row">
                    <div className=" d-flex align-items-center justify-content-center ms-3 mt-3">
                      {speaker?.speakerImage ? (
                        <img
                          src={speaker?.speakerImage}
                          className=" rounded-circle"
                          width={80}
                          height={80}
                          alt=""
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/images/empty-profile.png';
                          }}
                        />
                      ) : (
                        <img
                          src="/images/empty-profile.png"
                          className="img-fluid rounded-circle"
                          width={80}
                          alt=""
                        />
                      )}
                      <div className="col mt-1 align-items-center">
                        <h4 className="ms-2 fw-bolder text-dark">
                          {speaker?.speakerName}
                        </h4>
                        <div className="row">
                          <div className="col d-flex align-items-center justify-content-start">
                            {/* <span className="ps-2 text-muted">4.</span>
                              <div>
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                />
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                />
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                />
                                <FontAwesomeIcon
                                  className="text-warning ms-1"
                                  icon={faStar}
                                />
                                <FontAwesomeIcon
                                  icon={faStar}
                                  className="text-dark ms-1"
                                />
                              </div> */}
                            <div>
                              <span className="ms-2 text-muted">
                                {speaker?.sessions}
                              </span>
                              <span className="ms-1 text-muted">
                                {t('mentor.session_completed', {
                                  s: speaker?.sessions > 1 ? 's' : '',
                                })}
                              </span>
                            </div>
                            {/* <div className="borderLeft ms-5">
                                <FontAwesomeIcon
                                  className="text-danger ms-2"
                                  icon={faHeart}
                                ></FontAwesomeIcon>
                                <span className="ms-1 text-muted">1254</span>
                              </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-1">
                          <img src={mentorLike} alt=""></img>
                        </div> */}
                    </div>
                  </div>
                  <div className="row mt-5">
                    <Tabs defaultIndex="1" noAnimation={true}>
                      <TabItem label={t('mentor.about')} index="1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: speaker?.htmlContent,
                          }}
                        ></div>
                      </TabItem>
                      {/* <TabItem label={t('mentor.as_a_mentor')} index="2">
                          {option.metaDescription}
                        </TabItem>
                        <TabItem label={t('mentor.style')} index="3">
                          {option.urlName}
                        </TabItem>
                        <TabItem label={t('mentor.materials')} index="4">
                          {option.metaTitle}
                        </TabItem> */}
                    </Tabs>
                  </div>
                  {/* <div className="container">
                      <div className="row mt-3 mt-lg-5 d-flex justify-content-center text-center">
                        <div className="col-6 col-sm-3 mb-3 mb-sm-0">
                          <h6 className="text-warning">
                            <strong>
                              <FontAwesomeIcon icon={faStar} />
                              <span className="ms-2">4.0</span>
                            </strong>
                          </h6>
                          <h6 className="text-muted">
                            <strong>{t('mentorDetail.rating')}</strong>
                          </h6>
                        </div>
                        <div className="col-6 col-sm-3 mb-3 mb-sm-0">
                          <h4 className="text-dark">
                            <strong>252</strong>
                          </h4>
                          <h6 className="text-muted">
                            <strong>{t('mentorDetail.rating')}</strong>
                          </h6>
                        </div>
                        <div className="col-6 col-sm-3">
                          <h4 className="text-dark">
                            <strong>{option.sessions}</strong>
                          </h4>
                          <h6 className="text-muted">
                            <strong> {t('calendar.session')}</strong>
                          </h6>
                        </div>
                        <div className="col-6 col-sm-3">
                          <h4 className="text-dark">
                            <strong>%100</strong>
                          </h4>
                          <h6 className="text-muted">
                            <strong> {t('mentorDetail.answer')}</strong>
                          </h6>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            }
            key={`mentor_detail_card1_${speaker?.id}`}
          />
        </div>
        <div className={`col-lg-4 d-none d-lg-block rounded-3`}>
          <Card
            cardRounded={4}
            padding={0}
            body={
              <div>
                <div className="row">
                  <Iframe
                    videoId={extractVideoId(speaker?.specialUrl)}
                    width={'100%'}
                    height={210}
                  />
                </div>
                <div className="row mt-3 ms-2 fw-bolder fs-4">
                  {t('mentorDetail.get_a_free_trial_lesson')}
                </div>
                <div className="row ms-2 my-1 text-muted fst-italic">
                  {speaker?.sessions}{' '}
                  {t('mentorDetail.session_completed', {
                    s: speaker?.sessions > 1 ? 's' : '',
                  })}
                </div>
                <hr />
                <div className="row ms-2 mt-2 text-muted fst-italic">
                  {t('mentorDetail.guarantee')}
                </div>
                {/* <div
                    className="row ms-3  text-muted fst-italic "
                    style={{ fontSize: '0.5em' }}
                  >
                    {t('mentorDetail.confirm_your_email_address_to_access')}
                  </div> */}
                <div className="d-flex flex-column text-nowrap align-items-center">
                  {speaker?.trialStatus !== 'NonTrial' && (
                    <div className="col-6 mt-1">
                      <Button
                        text={
                          speaker?.trialStatus === 'PaidTrial'
                            ? t('button.paid_trial')
                            : t('button.free_trial')
                        }
                        type={ButtonType.Success}
                        buttonCol={12}
                        outline
                        bold
                        onClick={(e) =>
                          speaker?.trialStatus === 'FreeTrial' &&
                          filter?.UserArea !== 2
                            ? handleNavigateCard(
                                e,
                                filter?.UserArea === 0
                                  ? '/order/triallesson'
                                  : filter?.UserArea === 1
                                  ? '/cafe/order/triallesson'
                                  : filter?.UserArea === 2
                                  ? '/kids/order/triallesson'
                                  : '/order/triallesson',
                                {
                                  state: {
                                    fromNavigateWhere: 'freeTrial',
                                    trialStatus: 0,
                                    userArea: filter?.UserArea,
                                    speakerId: speaker?.id,
                                    speakerImage: speaker?.speakerImage,
                                    speakerName: speaker?.speakerName,
                                  },
                                }
                              )
                            : speaker?.trialStatus === 'PaidTrial'
                            ? handleNavigateCard(
                                e,
                                filter?.UserArea === 0
                                  ? '/order/triallesson'
                                  : filter?.UserArea === 1
                                  ? '/cafe/order/triallesson'
                                  : filter?.UserArea === 2
                                  ? '/kids/order/triallesson'
                                  : '/order/triallesson',
                                {
                                  state: {
                                    fromNavigateWhere: 'paidTrial',
                                    trialStatus: 1,
                                    userArea: filter?.UserArea,
                                    speakerId: speaker?.id,
                                    speakerName: speaker?.speakerName,
                                    speakerImage: speaker?.speakerImage,
                                  },
                                }
                              )
                            : null
                        }
                      />
                    </div>
                  )}
                  <div className="col-6 my-1">
                    <Button
                      text={t('button.buy')}
                      type={ButtonType.Successbg}
                      textColor={TextColors.Light}
                      buttonCol={12}
                      bold
                      onClick={(e) =>
                        handleNavigateCard(e, findMyPath('speakers'), {
                          state: {
                            directlyBuyCalendar: true,
                            trialStatus: 2,
                            userArea: filter?.UserArea,
                            speakerId: speaker?.id,
                            speakerName: speaker?.speakerName,
                            speakerImage: speaker?.speakerImage,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            }
            key={`mentor_detail_card2_${speaker?.id}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8">
          <Card
            body={
              <div>
                <WeeklyCalendar
                  isSmallCalendar
                  getSelectedDate={(val) =>
                    setSpeakerId({
                      ...speakerId,
                      DateTime: val,
                    })
                  }
                  dropDown={
                    <div className="text-nowrap">
                      <Button
                        type={ButtonType.Info}
                        text={t('button.all_sessions')}
                        size={ButtonSize.Small}
                        bold
                        buttonCol={12}
                        onClick={(e) =>
                          handleNavigateCard(e, findMyPath('speakers'), {
                            state: {
                              directlyBuyCalendar: true,
                              trialStatus: 2,
                              userArea: filter?.UserArea,
                              speakerId: speaker?.id,
                              speakerName: speaker?.speakerName,
                              speakerImage: speaker?.speakerImage,
                            },
                          })
                        }
                      />
                    </div>
                  }
                />
              </div>
            }
          />
          {filter?.UserArea === 1 ? null : (
            <div
              className="d-flex text-nowrap justify-content-center justify-content-md-start mt-3"
              style={{ fontSize: '1em' }}
            >
              <div
                className="align-self-center p-1"
                style={{ fontSize: '1em' }}
              >
                <Button
                  type={ButtonType.Success}
                  textColor={TextColors.Light}
                  text={t('button.one_to_one')}
                  size={ButtonSize.Small}
                  bold
                  disableHover={true}
                />
              </div>
              <div className="align-self-center p-1">
                <Button
                  type={ButtonType.Warning}
                  textColor={TextColors.Light}
                  text={t('button.group')}
                  bold
                  size={ButtonSize.Small}
                  disableHover={true}
                />
              </div>
            </div>
          )}
        </div>
        {/* <div className={`col-4 ${!isVisible ? 'd-none' : ''}`}>
          {speaker.map((option, index) => (
            <Card
              cardRounded={4}
              padding={0}
              body={
                <>
                  <div className="row">
                    <Iframe
                      videoId={location.state.specialUrl
                        .split('https://youtu.be/')
                        .at(1)}
                      width={'100%'}
                      height={210}
                    />
                  </div>
                  <div className="row mt-3 ms-2 fw-bolder fs-4">
                    Get a free trial lesson
                  </div>
                  <div className="row ms-2 text-muted fst-italic">
                    164 Lessons Complated
                  </div>
                  <div className="row mt-3 ms-2 me-3 border border-bottom border-2"></div>
                  <div className="row ms-2 mt-2 text-muted fst-italic">
                    100% Satisfaction Guarantee
                  </div>
                  <div
                    className="row ms-3  text-muted fst-italic "
                    style={{ fontSize: '0.5em' }}
                  >
                    Confrim your email address to access Meet2Talk features.
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    {option.trialStatus !== 'NonTrial' && (
                      <div className="col-6 mt-3">
                        <Button
                          text={
                            option.trialStatus === 'PaidTrial'
                              ? 'Paid Trial'
                              : 'Free Trial'
                          }
                          type={ButtonType.Success}
                          buttonCol={12}
                          outline
                          bold
                          onClick={() => navigate('/trial')}
                        />
                      </div>
                    )}
                    <div className="col-6 mt-3">
                      <Button
                        text={t('button.buy')}
                        type={ButtonType.Successbg}
                        textColor={TextColors.Light}
                        buttonCol={12}
                        bold
                        onClick={() => navigate('/buy')}
                      />
                    </div>
                  </div>
                </>
              }
              key={`mentor_detail_card3_${index}`}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};
export default MentorDetails;
