import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import checkGroupClaims from '../../../hooks/useCheckGroupClaims';
import {
  ActionFilterRules,
  Entities,
  ModalTitles,
  UserStatus,
} from '../../../utils/Enum';
import ModalInsideComponent from '../../../components/Common/Modal/ModalInsideComponent';
import AttendeeNotes from './AttendeeNotes';
import Toast from '../../../components/Common/Popup/Toast';
import SearchButton from '../../../components/Common/Button/SearchButton';

const List = () => {
  const freeTrialNoteModal = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const attendeeList = useSelector((state) => state.attendee.getAll);
  const managerList = useSelector((state) => state.manager.getAll);
  const companyList = useSelector((state) => state.company.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const staticDatas = useSelector((state) => state.staticData);
  const locationList = useSelector((state) => state.location.getAll);
  const userArea = useSelector((state) => state.staticData.UserArea);
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserCompanyId = userInfo.companyId;
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [attendeeInfo, setAttendeeInfo] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const isCompanyManager = currentUserCompanyId !== 1;

  useEffect(() => {
    if (userInfo.companyId !== undefined) {
      const { strTime, endTime, strView, endView } = !isCompanyManager
        ? filterTimeSet(null, true)
        : { strTime: null, endTime: null, strView: null, endView: null };

      setFilterData({
        ...filterData,
        startDate: strTime,
        endDate: endTime,
        startDateView: strView,
        endDateView: endView,
        sType5: !isCompanyManager ? null : `${currentUserCompanyId}`,
      });
      setMountStatus(false);
    }
  }, [userInfo]);

  useEffect(() => {
    getList();
  }, [mountStatus]);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  useEffect(() => {
    if (attendeeInfo?.attendeeName !== undefined) {
      freeTrialNoteModal.current?.open();
    }
  }, [attendeeInfo]);

  const getList = (func = false) => {
    if (Object.keys(filterData).length > 0 && mountStatus !== null) {
      const action = Actions.attendeeActions.getAllAction(filterData);
      setShowLoader(true);
      if (dateChanged && func) {
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };
  const editAction = (val) => {
    navigate('/attendee-update', { state: { id: val.id } });
  };

  const freeTrialNoteAction = async (val) => {
    await dispatch(
      Actions.attendeeActions.getNotesByMeetingAttendeeAction(val.id)
    ).then(() => {
      setAttendeeInfo({
        managerName: val.managerName,
        attendeeName: val.attendeeName,
        phone: val.phone,
      });
    });
  };

  const phoneConfirmationAction = (val) => {
    const object = { userId: val.userId, phoneNumber: val.phone };
    dispatch(Actions.userActions.confirmPhoneNumberAction(object));
  };

  const toOperationAction = (val) => {
    const url = new URL('/operations', window.location.origin);
    const state = {
      attendee: {
        id: val.id,
        name: val.name ?? '',
        email: val.email,
        phone: val.phone ?? '',
      },
    };
    url.search = new URLSearchParams(state.attendee).toString();
    window.open(url.toString(), '_blank');
  };

  const forwardAction = (val) => {
    navigate(`/order-history/${val.id}`, { state: { attendeeId: val.id } });
  };

  const setDefaultPasswordAction = (val) => {
    dispatch(Actions.userActions.setDefaultPasswordAction(val.userId));
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        setDateChanged(true);
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      setDateChanged(true);
      return rest;
    });
  };

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch(Actions.locationActions.getAllAction());
    }
    if (managerList.length === 0) {
      dispatch(Actions.managerActions.getAllAction('Dropdown'));
    }
    if (companyList.length === 0) {
      dispatch(Actions.companyActions.dropdownListAction());
    }
    return () => {
      dispatch(Actions.attendeeActions.cleanState());
      dispatch(Actions.userActions.cleanState(true));
    };
  }, []);

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const headers = [
    { key: 'attendeeName', label: t('common.name') },
    { key: 'level', label: t('attendeeList.language_level') },
    ...(!isCompanyManager
      ? [{ key: 'managerName', label: t('attendeeList.manager') }]
      : [
          { key: 'email', label: t('common.email') },
          { key: 'phone', label: t('common.phone') },
        ]),

    ...(checkGroupClaims(currentUserClaim, Claims.headClaims.manager)
      ? [
          { key: 'id', label: t('common.attendeeId') },
          { key: 'email', label: t('common.email') },
          { key: 'phone', label: t('common.phone') },
          { key: 'phoneConfirmed', label: t('attendeeList.phoneConfirmed') },
          { key: 'freeTrialUsed', label: t('attendeeList.freeTrialUsed') },
          {
            key: 'userStatus',
            label: t('attendeeList.activityStatus'),
          },
          { key: 'lastOrderDate', label: t('attendeeList.order_date') },
          { key: 'lastMeetingDate', label: t('attendeeList.lastMeetingDate') },
          { key: 'companyName', label: t('company.companyName') },
          { key: 'createdAt', label: t('common.created_at') },
          { key: 'updatedBy', label: t('common.updated_by') },
        ]
      : []),
  ];
  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('attendee.list')} strong />
      </div>
      <div className="col">
        <Card
          body={
            <React.Fragment>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4 form-div-border">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('cardHeaderFilter.filter')}
                />
                <div className="col-12 col-md-2 d-flex justify-content-center mb-1">
                  {checkClaims(
                    currentUserClaim,
                    Claims.backofficeClaims.attendee.create
                  ) && (
                    <Button
                      type={ButtonType.Successbg}
                      text={t('attendee.add_new_attendee')}
                      textColor={TextColors.Light}
                      onClick={() => navigate('/attendee-create')}
                      buttonCol={12}
                    />
                  )}
                </div>
              </div>
              <div className="d-sm-flex align-items-center justify-content-end">
                <SearchButton
                  stateName={'attendee'}
                  size={ButtonSize.Small}
                  bold
                  onClick={onSearch}
                  filterChanged={dateChanged}
                  mountingStatus={mountStatus}
                />
              </div>
              <div className="row my-2">
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'name'}
                    inputType={InputType.Text}
                    labelText={t('common.name')}
                    inputValue={filterData.name || ''}
                    onChange={onChangeText}
                  />
                </div>
                {!isCompanyManager && (
                  <React.Fragment>
                    <div className="col-lg-3">
                      <InputLabelOnBorder
                        id={'startDate'}
                        inputType={InputType.Date}
                        labelText={t('common.start_date')}
                        inputValue={filterData.startDateView || ''}
                        onChange={onChangeDate}
                      />
                    </div>
                    <div className="col-lg-3">
                      <InputLabelOnBorder
                        id={'endDate'}
                        inputType={InputType.Date}
                        labelText={t('common.end_date')}
                        inputValue={filterData.endDateView || ''}
                        onChange={onChangeDate}
                      />
                    </div>
                    <div className="col-lg-3 mt-2">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_manager')}
                        options={managerList}
                        selectedProps={{
                          key: 'managerName',
                          value: 'managerId',
                        }}
                        onChange={(value) => handleDropdown('sType', value)}
                        onReset={() => handleResetDropdown('sType')}
                        isfilter
                      />
                    </div>
                    <div className="col-lg-3 mt-lg-4 mt-2">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_location')}
                        options={locationList}
                        selectedProps={{ key: 'locationName', value: 'id' }}
                        onChange={(value) => handleDropdown('sType2', value)}
                        onReset={() => handleResetDropdown('sType2')}
                        isfilter
                      />
                    </div>
                    <div className="col-lg-3 mt-lg-4 mt-2">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_user_area')}
                        options={userArea}
                        selectedProps={{
                          key: 'key',
                          value: 'value',
                        }}
                        onChange={(value) =>
                          handleDropdown('sType3', parseInt(value))
                        }
                        onReset={() => handleResetDropdown('sType3')}
                        isfilter
                      />
                    </div>
                    <div className="col-lg-3 mt-lg-4 mt-2">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_activity_status')}
                        options={UserStatus}
                        selectedProps={{
                          key: 'key',
                          value: 'value',
                        }}
                        onChange={(value) =>
                          handleDropdown('sType4', parseInt(value))
                        }
                        onReset={() => handleResetDropdown('sType4')}
                        isfilter
                      />
                    </div>
                    <div className="col-lg-3 mt-lg-4 mt-2">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_company')}
                        options={companyList}
                        selectedProps={{
                          key: 'companyName',
                          value: 'companyId',
                        }}
                        onChange={(value) => handleDropdown('sType5', value)}
                        onReset={() => handleResetDropdown('sType5')}
                        isfilter
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          }
        />
        <Card
          body={
            <Table
              // searchAble
              data={attendeeList}
              headers={headers}
              showLoading={showLoader}
              striped
              bordered
              page={Entities.attendee}
              edit={(val) => editAction(val)}
              freeTrialNote={(val) => freeTrialNoteAction(val)}
              phoneConfirmation={(val) => phoneConfirmationAction(val)}
              toOperation={(val) => toOperationAction(val)}
              setDefaultPassword={(val) => setDefaultPasswordAction(val)}
              forwardPage={Entities.order}
              editForwardTxt={'pageActions.attendeeOrderDetails'}
              forward={(val) => forwardAction(val)}
              currentUserClaim={currentUserClaim}
              idSearchOnly={true}
              exportName={Entities.attendee.exportName}
              filterActionsRule={true}
              filterRule={ActionFilterRules.attendee}
            />
          }
        />
      </div>
      <Toast
        key={'phoneConfirmation'}
        id={'phoneConfirmation'}
        stateName={'user'}
        onClose={() => {
          dispatch(Actions.userActions.cleanState());
          dispatch(Actions.attendeeActions.getAllAction(filterData));
        }}
      />
      <ModalInsideComponent
        ref={freeTrialNoteModal}
        title={ModalTitles.freeTrialNote}
        child={<AttendeeNotes data={attendeeInfo} />}
        onClose={() => {
          dispatch(Actions.attendeeActions.cleanState());
          dispatch(Actions.attendeeActions.getAllAction(filterData));
          freeTrialNoteModal.current?.close();
        }}
        maxWidth={800}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.attendee.getAll,
    content,
    Claims.actionClaims.companyAttendee
  );
};
export default List;
