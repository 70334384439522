import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import { ToastContainer, toast } from 'react-toastify';
import { Entities } from '../../../utils/Enum';
import Button from '../../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const careerList = useSelector((state) => state.career.getAll);
  const isSuccess = useSelector((state) => state.career.isSuccess);
  const validationMessage = useSelector((state) => state.career.validationMessage);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();
  const [handleError, setHandleError] = useState('');

  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.career.getAll)) {
      await dispatch(Actions.careerActions.getAllAction());
    }
  };

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.careerActions.cleanState(true));
          setHandleError('');
          getList();
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'fullName', label: t('career.fullName') },
    { key: 'email', label: t('common.email') },
    { key: 'phoneNumber', label: t('common.phone') },
    { key: 'image', label: t('career.image') },
    { key: 'cv', label: t('career.cv') },
    { key: 'socialMedia', label: t('career.socialMedia') },
    { key: 'interviewed', label: t('career.interviewed') },
    { key: 'interviewer', label: t('career.interviewer') },
  ];

  const createAction = (id) => {
    navigate('/speaker-create', { state: { id: id } });
  };
  const removeAction = async (id) => {
    await dispatch(Actions.careerActions.removeAction(id));
  };
  const setInterviewedAction = async (id) => {
    await dispatch(Actions.careerActions.updateAction(id));
    setTimeout(() => {
      dispatch(Actions.careerActions.getAllAction());
    }, 500);
  };

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
  };

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-2">
        <HeadTags hSize={1} text={t('career.list')} strong />
      </div>
      <div className="col-lg-12">
        <Card
          body={
            <div>
              <div className="d-sm-flex justify-content-end">
                {checkClaims(
                  currentUserClaim,
                  Claims.backofficeClaims.speaker.create
                ) && (
                  <Button
                    type={ButtonType.Successbg}
                    text={t('speaker.add_new_speaker')}
                    textColor={TextColors.Light}
                    buttonCol={2}
                    onClick={() => navigate('/speaker-create')}
                  />
                )}
              </div>
              <Table
                // searchAble
                data={careerList}
                headers={headers}
                striped
                bordered
                page={Entities.career}
                changePageName={Entities.speaker}
                remove={(val) => removeAction(val)}
                create={(val) => createAction(val)}
                interviewed={(val) => setInterviewedAction(val)}
                currentUserClaim={currentUserClaim}
                idSearchOnly={true}
                exportName={Entities.career.exportName}
              />
            </div>
          }
        />
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.career.getAll,
    content
  );
};
export default List;
