import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import { Entities } from '../../../utils/Enum';
import Toast from '../../../components/Common/Popup/Toast';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaignList = useSelector((state) => state.campaign.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.campaign.getAll)
    ) {
      dispatch(Actions.campaignActions.getAllAction());
    }
  };

  const editAction = (val) => {
    navigate('/campaign-update', { state: { id: val.id } });
  };
  const removeAction = (id) => {
    dispatch(Actions.campaignActions.removeAction(id));
  };

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'name', label: t('common.name') },
    { key: 'title', label: t('campaign.title') },
    { key: 'startDate', label: t('common.start_date') },
    { key: 'endDate', label: t('common.end_date') },
    { key: 'image', label: t('common.image') },
    { key: 'zones', label: t('common.zones') },
    { key: 'createdAt', label: t('common.created_at') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('campaign.list')} strong />
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-end mb-4 form-div-border">
                  <div className="col-12 col-md-2 d-flex justify-content-center mb-1">
                    {checkClaims(
                      currentUserClaim,
                      Claims.backofficeClaims.campaign.create
                    ) && (
                      <Button
                        type={ButtonType.Successbg}
                        text={t('campaign.add')}
                        textColor={TextColors.Light}
                        onClick={() => navigate('/campaign-create')}
                        buttonCol={12}
                      />
                    )}
                  </div>
                </div>
                <Table
                  // searchAble
                  data={campaignList}
                  headers={headers}
                  page={Entities.campaign}
                  striped
                  bordered
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  idSearchOnly={true}
                />
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'campaign'}
        onClose={() => {
          getList();
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.banner.getAll,
    content
  );
};
export default List;
