import { combineReducers } from 'redux';
import authReducer from './authReducer';
import langReducer from './langReducer';
import screenReducer from './screenReducer';
import careerReducer from './careerReducer';
import companyPriceRequestReducer from './companyPriceRequestReducer';
import faqGroupReducer from './faqGroupReducer';
import faqReducer from './faqReducer';
import userReducer from './userReducer';
import staticDataReducer from './staticDataReducer';
import speakerReducer from './speakerReducer';
import onboardReducer from './onboardReducer';
import couponCodesReducer from './couponCodesReducer';
import timesPerWeekReducer from './timesPerWeekReducer';
import sessionPackageReducer from './sessionPackageReducer';
import zoneReducer from './zoneReducer';
import attendeeReducer from './attendeeReducer';
import attendeeFreezeReducer from './attendeeFreezeReducer';
import branchFreezeReducer from './branchFreezeReducer';
import managerReducer from './managerReducer';
import locationReducer from './locationReducer';
import ageIntervalReducer from './ageIntervalReducer';
import timesPerMonthReducer from './timesPerMonthReducer';
import sessionCategoryReducer from './sessionCategoryReducer';
import meetingSchemaReducer from './meetingSchemaReducer';
import orderReducer from './orderReducer';
import becomeAPartnerReducer from './becomeAPartnerReducer';
import bannerReducer from './bannerReducer';
import userPackageReducer from './userPackageReducer';
import calendarReducer from './calendarReducer';
import speakerAvailabilityReducer from './speakerAvailabilityReducer';
import classroomReducer from './classroomReducer';
import preferenceReducer from './preferenceReducer';
import subscriptionReducer from './subscriptionReducer';
import userOperationClaimReducer from './userOperationClaimReducer';
import paymentReducer from './paymentReducer';
import filterReducer from './filterReducer';
import ActionTypes from '../actions/actionTypes';
import meetingAttendeeReducer from './meetingAttendeeReducer';
import feedbackReducer from './feedbackReducer';
import speakerEffortReducer from './speakerEffortReducer';
import leadReducer from './leadReducer';
import priceReducer from './priceReducer';
import companyReducer from './companyReducer';
import campaignReducer from './campaignReducer';
import reportingReducer from './reportingReducer';

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.reset_store) {
    state = undefined;
  }
  return combineReducers({
    auth: authReducer,
    screen: screenReducer,
    lang: langReducer,
    career: careerReducer,
    companyPriceRequest: companyPriceRequestReducer,
    faqGroup: faqGroupReducer,
    faq: faqReducer,
    user: userReducer,
    staticData: staticDataReducer,
    speaker: speakerReducer,
    onboard: onboardReducer,
    couponCode: couponCodesReducer,
    timesPerWeek: timesPerWeekReducer,
    sessionPackage: sessionPackageReducer,
    zone: zoneReducer,
    attendee: attendeeReducer,
    attendeeFreeze: attendeeFreezeReducer,
    branchFreeze: branchFreezeReducer,
    manager: managerReducer,
    location: locationReducer,
    ageInterval: ageIntervalReducer,
    timesPerMonth: timesPerMonthReducer,
    sessionCategory: sessionCategoryReducer,
    meetingSchema: meetingSchemaReducer,
    order: orderReducer,
    becomeAPartner: becomeAPartnerReducer,
    banner: bannerReducer,
    userPackage: userPackageReducer,
    calendar: calendarReducer,
    speakerAvailability: speakerAvailabilityReducer,
    classroom: classroomReducer,
    preferenceOptions: preferenceReducer,
    subscription: subscriptionReducer,
    userOperationClaim: userOperationClaimReducer,
    payment: paymentReducer,
    holdFilter: filterReducer,
    meetingAttendee: meetingAttendeeReducer,
    feedback: feedbackReducer,
    speakerEffort: speakerEffortReducer,
    lead: leadReducer,
    price: priceReducer,
    company: companyReducer,
    campaign: campaignReducer,
    reporting: reportingReducer,
  })(state, action);
};
// const reducers = combineReducers({
//   auth: authReducer,
//   screen: screenReducer,
//   lang: langReducer,
//   bank: bankReducer,
//   career: careerReducer,
//   companyPriceRequest: companyPriceRequestReducer,
//   faqGroup: faqGroupReducer,
//   faq: faqReducer,
//   user: userReducer,
//   staticData: staticDataReducer,
//   speaker: speakerReducer,
//   onboard: onboardReducer,
//   basket: basketReducer,
//   couponCode: couponCodesReducer,
//   timesPerWeek: timesPerWeekReducer,
//   sessionPackage: sessionPackageReducer,
//   zone: zoneReducer,
//   attendee: attendeeReducer,
//   attendeeFreeze: attendeeFreezeReducer,
//   branchFreeze: branchFreezeReducer,
//   manager: managerReducer,
//   location: locationReducer,
//   ageInterval: ageIntervalReducer,
//   timesPerMonth: timesPerMonthReducer,
//   sessionCategory: sessionCategoryReducer,
//   meetingSchema: meetingSchemaReducer,
//   order: orderReducer,
//   becomeAPartner: becomeAPartnerReducer,
//   campaign: campaignReducer,
//   userPackage: userPackageReducer,
//   calendar: calendarReducer,
//   speakerAvailability: speakerAvailabilityReducer,
//   classroom: classroomReducer,
//   choosenCurrency: preferenceCurrencyReducer,
//   subscription: subscriptionReducer,
//   userOperationClaim: userOperationClaimReducer,
//   payment: paymentReducer,
//   holdFilter: filterReducer
// });

export default rootReducer;
