import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import Button from '../../../../components/Common/Button/Button';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import { ButtonType, TextColors } from '../../../../utils/ComponentEnums';
import { Entities } from '../../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const zoneList = useSelector((state) => state.zone.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();

  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.zone.getAll)) {
      await dispatch(Actions.zoneActions.getAllAction());
    }
  };

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'zoneName', label: t('zone.zone_name') },
    { key: 'zoneSessionValue', label: t('zone.session_value') },
    { key: 'zoneMentorValue', label: t('zone.mentor_value') },
  ];
  const editAction = (val) => {
    navigate('/zone-update', { state: { id: val.id } });
  };
  const removeAction = async (id) => {
    await dispatch(Actions.zoneActions.removeAction(id));
    getList();
  };

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags hSize={1} text={t('zone.zone_list')} strong />
      </div>
      {checkClaims(currentUserClaim, Claims.backofficeClaims.zone.create) ? (
        <div className="row mb-3">
          <div className="d-flex justify-content-end">
            <Button
              type={ButtonType.Successbg}
              text={t('zone.add_new_zone')}
              textColor={TextColors.Light}
              buttonCol={2}
              onClick={() => navigate('/zone-create')}
            />
          </div>
        </div>
      ) : undefined}
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={zoneList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.zone}
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.zone.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.zone.getAll,
    content
  );
};
export default List;
