import React from 'react';
import List from '../../../BackOffice/Company/CompanyOrder/List';
import { useTranslation } from 'react-i18next';
import Reporting from '../../../BackOffice/Company/Reproting';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import { useSelector } from 'react-redux';

const CompanyDashboard = () => {
  const { t } = useTranslation();
  const currentUserCompanyId = useSelector(
    (state) => state.user.getById.companyId
  );

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-1 mb-md-2">
        <HeadTags hSize={2} strong text={t('company.dashboard')} />
      </div>
      <Reporting currentUserCompanyId={currentUserCompanyId} />
      <List dashboard={true} />
    </div>
  );
  return content;
};
export default CompanyDashboard;
