import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Notifications.module.scss';
import Actions from '../../../../store/redux/actions';
import findMyPath from '../../../../hooks/useFindMyPath';

const Basket = () => {
  const orderItems = useSelector((state) => state.order.basketItems);
  const isSuccess = useSelector((state) => state.order.isSuccess);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calendarTable = useSelector((state) => state.order.calendarTable);
  const lengthBasket = orderItems?.unpayedOrderItemList?.length;
  const lengthCalendar = calendarTable.length > 0 ? 1 : undefined;
  const checkBadgeCount = lengthCalendar || lengthBasket;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const lengthCurrentUserClaim = currentUserClaim?.length === 0;
  const basketStatus = useSelector((state) => state.order.isEmpty);
  const landingOrder = useSelector(
    (state) => state.userPackage.isPackageComplete
  );

  useEffect(() => {
    if (
      !landingOrder &&
      isSuccess &&
      lengthCurrentUserClaim &&
      !basketStatus &&
      calendarTable?.length > 0 &&
      calendarTable?.[0]?.sessionCount === 1
    ) {
      dispatch(Actions.orderActions.getBasketItemsAction());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      !landingOrder &&
      isSuccess &&
      lengthCurrentUserClaim &&
      basketStatus &&
      calendarTable?.length === 0 &&
      orderItems?.unpayedOrderItemList?.length > 0
    ) {
      dispatch(Actions.orderActions.getBasketItemsAction());
    }
  }, [isSuccess]);

  return (
    <div
      className={`${styles.icon_container} mt-1 position-relative  mx-1 me-md-4`}
      onClick={() => navigate(findMyPath('order-basket'))}
    >
      <FontAwesomeIcon className="fa-xl" icon={faCartShopping} />
      <span className="badge badge-danger badge-counter">
        {checkBadgeCount || ''}
      </span>
    </div>
  );
};

export default Basket;
