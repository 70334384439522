import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faEllipsis,
  faFile,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import Dropdown from '../Dropdown/Dropdown';
import checkTableActionClaims from '../../../hooks/useCheckTableActionClaims';
import Button from '../Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import Tooltip from '../ToolTip/Tooltip';
import Card from '../Card/Card';
import Pagination from '../Paginate/Pagination';
import calculateUTC from '../../../hooks/useCalculateUTC';
import addDay from '../../../hooks/useAddDay';
import getTimeZone from '../../../hooks/useGetTimeZone';
import { CSVLink } from 'react-csv';
import checkClaims from '../../../hooks/useCheckClaims';
import EnumBase from '../../../utils/EnumBase';
import ConfirmModal from '../Modal/ConfirmModal';
import { ActionFilterRules, Entities } from '../../../utils/Enum';

const Table = ({
  data,
  striped,
  bordered,
  headers,
  edit,
  addToCalendar,
  remove,
  page,
  selectButton,
  selectAll,
  currentUserClaim,
  searchAble,
  setPayed,
  create,
  recompense,
  redate,
  editSup,
  supPage,
  forward,
  forwardPage,
  availability,
  idSearchOnly = false,
  interviewed,
  supForward = undefined,
  goDetails,
  noUtc = true,
  exportName,
  unPayMeeting,
  renewOrderItem,
  hidePagination = false,
  unRecompense,
  filterActionsRule = false,
  filterRule = '',
  changePageName = undefined,
  freeTrialNote,
  phoneConfirmation,
  toOperation,
  createTxt,
  editSupTxt,
  showLoading = false,
  setRegister,
  tdfontSize,
  editForwardTxt,
  setDefaultPassword,
}) => {
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);
  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let className = 'table align-middle table-sm';
  let isStriped = striped ? (className += ' table-striped') : '';
  let isBordered = bordered
    ? (className += ' table-bordered')
    : 'table-borderless';
  const { t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [tableActions, setTableActions] = useState([]);
  const checkLengthActions = tableActions.length !== 0;
  const pageName = t(
    createTxt === undefined
      ? `pageActions.${
          changePageName === undefined ? page?.claim : changePageName?.claim
        }`
      : createTxt
  );
  const supPageName = t(
    editSupTxt === undefined ? `pageActions.${supPage?.claim}` : editSupTxt
  );
  const forwardPageName = t(
    editForwardTxt === undefined
      ? `pageActions.${
          supForward !== undefined ? supForward : forwardPage?.claim
        }`
      : editForwardTxt
  );
  const timeZone = getTimeZone();
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData?.slice(startIndex, endIndex);

  //#region Searching
  useEffect(() => {
    setFilteredData(data);
    setCurrentPage(1);
  }, [data]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const lowerCaseSearchTerm = event.target.value.toLowerCase();
    const newData = data.filter((item) => {
      if (idSearchOnly) {
        return item.id.toString().toLowerCase().includes(lowerCaseSearchTerm);
      } else {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
      }
    });
    setFilteredData(newData);
  };
  //#endregion
  //#region Table Sorting

  const handleSortingChange = (accessor) => {
    setSortField(accessor);
    const newSortOrder = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortOrder);
    handleSorting(sortField, newSortOrder);
  };

  const handleSorting = (sortField, sortDirection) => {
    data = data.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });
  };
  //#endregion

  const isManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.attendee.create
  );

  const editAction = {
    key: 1,
    name: t('table.actions_edit'),
    value: 0,
    onClick: () => edit(),
  };
  const removeAction = {
    key: 3,
    name: t('table.actions_remove'),
    value: 2,
    onClick: () => remove(),
  };
  const addToCalendarAction = {
    key: 2,
    name: t('table.actions_add_to_calendar'),
    value: 1,
    onClick: () => addToCalendar(),
  };
  const changeOrderPaymentStatus = {
    key: 4,
    name: t('table.set_payed'),
    value: 3,
    onClick: () => setPayed(),
  };
  const createAction = {
    key: 5,
    name: t('table.actions_create', { pageName }),
    value: 4,
    onClick: () => create(),
  };
  const recompenseAction = {
    key: 6,
    name: t('table.recompense'),
    value: 5,
    onClick: () => recompense(),
  };
  const redateAction = {
    key: 7,
    name: t('table.redate'),
    value: 6,
    onClick: () => redate(),
  };
  const editSupAction = {
    key: 8,
    name: t('table.edit_sup', { supPageName }),
    value: 7,
    onClick: () => editSup(),
  };
  const forwardAction = {
    key: 9,
    name: t(forwardPageName),
    value: 8,
    onClick: () => forward(),
  };
  const availabilityAction = {
    key: 10,
    name: t('table.availability'),
    value: 9,
    onClick: () => availability(),
  };
  const interviewedAction = {
    key: 11,
    name: t('table.interviewed'),
    value: 10,
    onClick: () => interviewed(),
  };
  const goDetailsAction = {
    key: 12,
    name: t('table.go_details'),
    value: 11,
    onClick: () => goDetails(),
  };
  const unPayMeetingAction = {
    key: 13,
    name: t('table.un_pay_meeting'),
    value: 12,
    onClick: () => unPayMeeting(),
  };
  const renewOrderItemAction = {
    key: 14,
    name: t('table.renew_order_item'),
    value: 13,
    onClick: () => renewOrderItem(),
  };
  const unRecompenseAction = {
    key: 15,
    name: t('table.unrecompense'),
    value: 14,
    onClick: () => unRecompense(),
  };
  const freeTrialNoteAction = {
    key: 16,
    name: t('table.freeTrialNote'),
    value: 15,
    onClick: () => freeTrialNote(),
  };
  const phoneConfirmationAction = {
    key: 17,
    name: t('table.phoneConfirmation'),
    value: 16,
    onClick: () => phoneConfirmation(),
  };
  const toOperationAction = {
    key: 18,
    name: t('pageActions.operations'),
    value: 17,
    onClick: () => toOperation(),
  };
  const setRegisterAction = {
    key: 19,
    name: t('table.setRegister'),
    value: 18,
    onClick: () => setRegister(),
  };
  const setDefaultPasswordAction = {
    key: 20,
    name: t('table.setDefaultPassword'),
    value: 19,
    onClick: () => setDefaultPassword(),
  };
  useEffect(() => {
    const newTableActions = [];
    if (create !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.create
        )
      ) {
        newTableActions.push(createAction);
      }
    }
    if (edit !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.update
        )
      ) {
        newTableActions.push(editAction);
      }
    }
    if (remove !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.delete
        )
      ) {
        newTableActions.push(removeAction);
      }
    }
    if (setPayed !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.eftConfirm
        )
      ) {
        newTableActions.push(changeOrderPaymentStatus);
      }
    }
    if (recompense !== undefined) {
      if (
        checkTableActionClaims(currentUserClaim, page?.claim, '') ||
        checkTableActionClaims(
          currentUserClaim,
          undefined,
          Claims.actionClaims.adminRecompense
        )
      ) {
        newTableActions.push(recompenseAction);
      }
    }
    if (redate !== undefined) {
      if (checkTableActionClaims(currentUserClaim, page?.claim, '')) {
        newTableActions.push(redateAction);
      }
    }
    if (addToCalendar !== undefined) {
      if (checkTableActionClaims(currentUserClaim, page?.claim, '')) {
        newTableActions.push(addToCalendarAction);
      }
    }
    if (supPage !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          supPage?.claim,
          Claims.actionClaims.update
        )
      ) {
        newTableActions.push(editSupAction);
      }
    }
    if (forwardPage !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          forwardPage?.claim,
          Claims.actionClaims.get
        ) ||
        currentUserClaim.includes('company.attendee')
      ) {
        newTableActions.push(forwardAction);
      }
    }
    if (availability !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.availabilityCreate
        )
      ) {
        newTableActions.push(availabilityAction);
      }
    }
    if (interviewed !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.update
        )
      ) {
        newTableActions.push(interviewedAction);
      }
    }
    if (goDetails !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.update
        )
      ) {
        newTableActions.push(goDetailsAction);
      }
    }
    if (unPayMeeting !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.update
        )
      ) {
        newTableActions.push(unPayMeetingAction);
      }
    }
    if (renewOrderItem !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          page?.claim,
          Claims.actionClaims.renewOrderItem
        )
      ) {
        newTableActions.push(renewOrderItemAction);
      }
    }
    if (unRecompense !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          undefined,
          Claims.actionClaims.unRecompenseAttendee
        )
      ) {
        newTableActions.push(unRecompenseAction);
      }
    }
    if (freeTrialNote !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          Entities.attendee.claim,
          Claims.actionClaims.get
        ) ||
        checkTableActionClaims(
          currentUserClaim,
          undefined,
          Claims.actionClaims.companyAttendee
        )
      ) {
        newTableActions.push(freeTrialNoteAction);
      }
    }
    if (phoneConfirmation !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          Entities.attendee.claim,
          Claims.actionClaims.update
        )
      ) {
        newTableActions.push(phoneConfirmationAction);
      }
    }
    if (toOperation !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          Entities.attendee.claim,
          Claims.actionClaims.create
        )
      ) {
        newTableActions.push(toOperationAction);
      }
    }
    if (setRegister !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          Entities.attendee.claim,
          Claims.actionClaims.create
        )
      ) {
        newTableActions.push(setRegisterAction);
      }
    }
    if (setDefaultPassword !== undefined) {
      if (
        checkTableActionClaims(
          currentUserClaim,
          undefined,
          Claims.actionClaims.changePassword
        )
      ) {
        newTableActions.push(setDefaultPasswordAction);
      }
    }
    setTableActions(newTableActions);
  }, [page?.claim]);

  const confirmModalRef = useRef();
  const [confirmModal, setConfirmModal] = useState({
    active: false,
    type: undefined,
    page: page?.claim,
    data: {},
  });
  const resetConfirmModalState = () => {
    setConfirmModal({
      active: false,
      type: undefined,
      page: page?.claim,
      data: {},
    });
  };

  const needConfirmationActions = [2, 3, 5, 10, 12, 14, 16, 19];
  useEffect(() => {
    if (confirmModal?.active) {
      return confirmModalRef.current?.open();
    }
    return () => {};
  }, [confirmModal?.active]);

  const handleSelectChange = (target, data) => {
    if (
      !confirmModal.active &&
      needConfirmationActions.includes(target?.value)
    ) {
      return setConfirmModal({
        ...confirmModal,
        active: true,
        type: target.value,
        page: page?.claim,
        data: data,
      });
    }
    const newValue = parseInt(target?.value ?? target);
    const selectedOption = tableActions.find(
      (option) => option.value === newValue
    );

    if (selectedOption.onClick) {
      switch (newValue) {
        case 0:
          return edit(data);
        case 2:
          return remove(data?.id);
        case 1:
          return addToCalendar(data);
        case 3:
          return setPayed(data?.id);
        case 4:
          return create(data?.id);
        case 5:
          return recompense(data);
        case 6:
          return redate(data);
        case 7:
          return editSup(data);
        case 8:
          return forward({
            id: data?.id,
            supId: data.attendeeId,
            email: data.email,
            phone: data.phone,
            name: data.name,
            orderId: data.orderId,
            orderItemId: data.orderItemId,
          });
        case 9:
          return availability(data?.id);
        case 10:
          return interviewed(data?.id);
        case 11:
          return goDetails(data);
        case 12:
          return unPayMeeting(data);
        case 13:
          return renewOrderItem(data);
        case 14:
          return unRecompense(data);
        case 15:
          return freeTrialNote(data);
        case 16:
          return phoneConfirmation(data);
        case 17:
          return toOperation({
            id: data?.id,
            supId: data.attendeeId,
            email: data.email,
            phone: data.phone,
            name: data.name || data.attendeeName,
          });
        case 18:
          return setRegister(data);
        case 19:
          return setDefaultPassword(data);
        default:
          break;
      }
    }
  };
  function customCellRenderer(row, header) {
    if (header.key === 'mentor') {
      return (
        <div key={row.id}>
          <div className="mx-1 d-flex align-items-center">
            <img
              src="../images/letuscallyouwp.webp"
              alt="letuscallyouwp.webp"
              style={{ width: 45, height: 45 }}
              className="rounded-3"
            />
            <div className="ms-3">
              <p className="my-1 ">{row[header.key]}</p>
            </div>
          </div>
        </div>
      );
    }
    if (
      header.key === 'meetingLevel' ||
      header.key === 'level' ||
      header.key === 'languageLevel'
    ) {
      return <span>{t(`${row[header.key]}`)}</span>;
    }
    if (header.key === 'levelGroup') {
      return <span>{t(row[header.key])}</span>;
    }
    if (header.key === 'note' || header.key === 'socialMedia') {
      return (
        <textarea
          cols={`${!mobileView ? '40' : '35'}`}
          rows="2"
          disabled={true}
        >
          {row[header.key]}
        </textarea>
      );
    }

    if (
      header.key === 'image' ||
      header.key === 'pictureMobile' ||
      header.key === 'pictureWeb'
    ) {
      return (
        <div
          role="button"
          onClick={() => window.open(row[header.key], '_blank')}
          className="d-flex justify-content-center"
        >
          <img
            className={'img-fluid'}
            src={row[header.key]}
            alt={row[header.key]}
            width={60}
            height={100}
            style={{ maxHeight: '150px', maxWidth: '80px' }}
          />
        </div>
      );
    }
    if (header.key === 'dayOfWeekMeetingSchema') {
      function calculateNewDate(time, date, convert = false, options = {}) {
        if (date !== undefined && date !== null) {
          const valueFromInterval = time?.split('-')[0];
          const [hours, minutes] = valueFromInterval.split(':').map(Number);
          const checkMe = hours * 60 + minutes;
          const calculte = checkMe + getTimeZone(true);

          const parts = date.split('-');
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1;
          const year = parseInt(parts[2], 10);
          const currentDate = new Date(year, month, day, hours, minutes);

          let adjustedDate = new Date(
            currentDate.getTime() - getTimeZone(true) * 60000
          ); // Adjust date based on UTC offset

          if (calculte >= 1440) {
            adjustedDate.setDate(adjustedDate.getDate() + 1);
          } else if (calculte < 0) {
            adjustedDate.setDate(adjustedDate.getDate() - 1);
          }

          const resultDate = `${adjustedDate
            .getDate()
            .toString()
            .padStart(2, '0')}-${(adjustedDate.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${adjustedDate.getFullYear()}`;

          if (convert) {
            const formattedDate = adjustedDate?.toLocaleDateString(
              'en-US',
              options
            );
            return formattedDate;
          }

          return resultDate;
        }
      }

      const fixTime = (time) => {
        const dayOfWeekMap = {
          Sunday: 0,
          Monday: 1,
          Tuesday: 2,
          Wednesday: 3,
          Thursday: 4,
          Friday: 5,
          Saturday: 6,
        };
        const [h, m] = time.split(':').map(Number);
        const today = new Date();
        today.setHours(h, m);
        today.setMinutes(today.getMinutes() - getTimeZone(true));
        today.setDate(
          today.getDate() +
            ((dayOfWeekMap[row['dayOfWeekMeetingSchema']] +
              7 -
              today.getDay()) %
              7)
        );
        const result = calculateNewDate(
          `${time}-`,
          `${today.getDate().toString().padStart(2, '0')}-${(
            today.getMonth() + 1
          )
            .toString()
            .padStart(2, '0')}-${today.getFullYear()}`
        );

        const [d, mo, y] = result.split('-').map(Number);
        const resultDayOfWeek = new Date(y, mo - 1, d);
        const dayNames = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];
        return dayNames[resultDayOfWeek.getDay()];
      };

      return <span>{fixTime(row['interval'].split('-')[0])}</span>;
    }
    if (
      header.key === 'createdAt' ||
      header.key === 'deletedAt' ||
      header.key === 'updatedAt' ||
      header.key === 'orderDate' ||
      header.key === 'startDate' ||
      header.key === 'endDate' ||
      header.key === 'lastOrderDate' ||
      header.key === 'lastMeetingDate' ||
      header.key === 'date' ||
      header.key === 'paymentDate' ||
      header.key === 'startTime' ||
      header.key === 'endTime' ||
      header.key === 'meetingDate'
    ) {
      if (row[header.key] === '') {
        return <span>{row[header.key]}</span>;
      }
      const date = new Date(row[header.key]);

      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      const result = addDay(
        `${hours}:${minutes}`,
        `${year}-${month}-${day}`,
        false,
        false,
        false,
        false,
        false,
        true
      );

      const formattedDateTime =
        header.key === 'date'
          ? `${result.resultDate}`
          : `${result.resultDate} ${result.nTime}`;

      return <span>{formattedDateTime}</span>;
    }
    if (header.key === 'dateRange') {
      if (row[header.key] === '') {
        return <span>{row[header.key]}</span>;
      }
      const [startDateStr, endDateStr] = row[header.key].split(' - ');
      const parseDate = (dateStr) => {
        const [day, month, year, time] = dateStr.split(/\.|T/);
        const [hours, minutes] = time.split(':');
        return new Date(year, month - 1, day, hours, minutes);
      };
      const startDate = parseDate(startDateStr);
      const endDate = parseDate(endDateStr);

      const formatDateTime = (date) => {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const result = addDay(
          `${hours}:${minutes}`,
          `${year}-${month}-${day}`,
          false,
          false,
          false,
          false,
          false,
          true
        );
        return `${result.resultDate} ${result.nTime}`;
      };

      const formattedStartDate = formatDateTime(startDate);
      const formattedEndDate = formatDateTime(endDate);
      return <span>{`${formattedStartDate} - ${formattedEndDate}`}</span>;
    }

    // if (header.key === 'video') {
    //   if (value === 'Not Played') {
    //     return (
    //       <span key={row.id}>
    //         <h5>
    //           <span className="badge bg-lightcustom text-custom border border-custom rounded-3 d-inline">
    //             {' '}
    //             <FontAwesomeIcon icon={faPlay} />
    //           </span>
    //         </h5>
    //       </span>
    //     );
    //   }

    //   if (value === 'played') {
    //     return (
    //       <span key={row.id}>
    //         <h5>
    //           <span className="badge bg-custom text-white border border-custom rounded-3 d-inline">
    //             {' '}
    //             <FontAwesomeIcon icon={faPlay} />
    //           </span>
    //         </h5>
    //       </span>
    //     );
    //   }
    // }

    if (
      header.key === 'id' &&
      row['errorMessage'] !== '' &&
      row['errorMessage'] !== undefined
    ) {
      return (
        <Tooltip
          bottom={2}
          id={'mentor_desktop_filter'}
          text={
            <Card
              body={
                <span className="text-dark bg-light">
                  {row['errorMessage']}
                </span>
              }
            />
          }
        >
          <span
            className={`${
              row['errorMessage'] !== ''
                ? 'text-light fw-bold'
                : 'text-dark fw-bold'
            }`}
          >
            {row['errorMessage']}
          </span>
        </Tooltip>
      );
    }
    if (header.key === 'interval') {
      if (row[header.key] === '') {
        return <span>{row[header.key]}</span>;
      }
      return (
        <span>
          {`${calculateUTC(row[header.key].split('-')[0])} - ${calculateUTC(
            row[header.key].split('-')[1]
          )}`}{' '}
        </span>
      );
    }

    if (header.key === 'cv') {
      return (
        <FontAwesomeIcon
          role="button"
          onClick={() => window.open(row[header.key], '_blank')}
          icon={faFile}
          size="2x"
        />
      );
    }

    if (header.key === 'attendeeName') {
      return (
        <span>{row[header.key] === ' ' ? 'unknown' : row[header.key]}</span>
      );
    } else {
      return <span>{t(`${row[header.key]}`)}</span>;
    }
  }

  function fixDate(valDate, valInterval) {
    const [hours, minutes] = valInterval
      ?.split('-')[0]
      ?.split(':')
      ?.map(Number);
    const [y, m, d] = valDate?.split('-')?.map(Number);
    const result = addDay(
      `${hours}:${minutes}`,
      `${y}-${m}-${d}`,
      false,
      false,
      false,
      false,
      false,
      true
    );

    const formattedDateTime = {
      date: `${result.resultDate}`,
      interval: `${result.nTime}`,
    };

    return formattedDateTime;
  }

  function filteredOptions(row, filterRule) {
    if (filterRule === ActionFilterRules.attendee) {
      return row.phoneConfirmed || row.phone === ""
        ? tableActions.filter((option) => option.key !== 17)
        : !row.phoneConfirmed && row.phoneConfirmed !== undefined
        ? tableActions
        : row.meetingAttendeeStatus ===
            t(EnumBase.MeetingAttendeeStatus.Canceled) ||
          row.meetingAttendeeStatus ===
            t(EnumBase.MeetingAttendeeStatus.Completed) ||
          row.meetingAttendeeStatus ===
            t(EnumBase.MeetingAttendeeStatus.BranchFreeze) ||
          row.meetingAttendeeStatus ===
            t(EnumBase.MeetingAttendeeStatus.Unattended) ||
          !row.isActive
        ? []
        : row.redatedSession || row.singleSession
        ? tableActions.filter((option) => option.key === 2)
        : row.meetingAttendeeStatus ===
            t(EnumBase.MeetingAttendeeStatus.Recompense) &&
          !row.redatedSession &&
          row.isActive
        ? tableActions.filter((option) => option.key === 7)
        : row.meetingAttendeeStatus ===
          t(EnumBase.MeetingAttendeeStatus.Planned)
        ? tableActions.filter((option) => option.key === 2 || option.key === 6)
        : tableActions;
    }
    if (filterRule === ActionFilterRules.order) {
      return row.paymentStatus === t(EnumBase.PaymentStatus.Payed) &&
        row.trialStatus !== t(EnumBase.TrialStatus.FreeTrial) &&
        row.trialStatus !== t(EnumBase.TrialStatus.Gift)
        ? tableActions.filter((option) => option.key !== 3)
        : row.orderItemStatus === EnumBase.OrderItemStatus.Completed ||
          row.orderItemStatus === EnumBase.OrderItemStatus.Renew
        ? tableActions.filter((option) => option.key !== 1 && option.key !== 14)
        : row.paymentStatus !== t(EnumBase.PaymentStatus.Payed)
        ? tableActions.filter(
            (option) =>
              option.key !== 12 && option.key !== 9 && option.key !== 18
          )
        : row.trialStatus === t(EnumBase.TrialStatus.FreeTrial)
        ? tableActions
        : row.orderStatus === t(EnumBase.OrderStatus.Completed)
        ? tableActions.filter(
            (option) =>
              option.key !== 12 &&
              option.key !== 1 &&
              option.key !== 3 &&
              option.key !== 18
          )
        : tableActions;
    }
    if (filterRule === ActionFilterRules.meetingDetails) {
      const today = new Date();
      const meetingdDate = fixDate(row?.date, row?.interval);
      const [day, month, year] = meetingdDate.date.split('/');
      const [hour, minute] = meetingdDate.interval.split(':');
      const datetime = new Date(year, month - 1, day, hour, minute);
      const canRecompense =
        row.meetingType !== EnumBase.MeetingAttendeeCreationType.Recomponse &&
        row.status !== EnumBase.MeetingAttendeeStatus.Recompense &&
        row?.trialStatus !== EnumBase.TrialStatus.FreeTrial &&
        datetime > today;
      const canUnrecompense =
        row.meetingType === EnumBase.MeetingAttendeeCreationType.Recomponse &&
        row?.trialStatus !== EnumBase.TrialStatus.FreeTrial &&
        datetime > today;
      return canRecompense
        ? tableActions.filter((option) => option.key !== 15)
        : canUnrecompense
        ? tableActions.filter((option) => option.key !== 6)
        : !canRecompense && !canUnrecompense
        ? tableActions.filter((option) => option.key !== 15 && option.key !== 6)
        : tableActions;
    }
    if (filterRule === ActionFilterRules.company) {
      return row.status === t(EnumBase.UserStatus.Passive)
        ? tableActions.filter(
            (option) => option.key !== 5 || option.key !== 6 || option.key !== 9
          ):
        row.id === 1 ? 
        tableActions.filter(
          (option) => option.key !== 12
        ): tableActions;
    }
    if (filterRule === ActionFilterRules.register) {
      return !row.isRegistered && !row.interviewed
        ? tableActions
        : row.interviewed && row.isRegistered
        ? tableActions.filter(
            (option) => option.key !== 11 && option.key !== 19
          )
        : row.isRegistered
        ? tableActions.filter((option) => option.key !== 19)
        : row.interviewed
        ? tableActions.filter((option) => option.key !== 11)
        : tableActions;
    }
  }

  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleRow = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };
  return (
    <React.Fragment>
      <div>
        {searchAble && !mobileView && (
          <div className="float-end position-absolute mt-3 mb-2 me-3 top-0 end-0">
            <input
              type="text"
              placeholder={t('table.search')}
              value={searchTerm}
              onChange={handleSearch}
              className="form-control mb-3"
            />
          </div>
        )}
        {!mobileView ? (
          <div className="table-responsive mt-5">
            <div
              className={`d-flex justify-content-${noUtc ? 'between' : 'end'}`}
            >
              {noUtc && <span> {t('utc.calculation', { timeZone })}</span>}
              {isManager && data?.length > 0 && (
                <CSVLink
                  data={data || []}
                  headers={headers}
                  filename={`${exportName}-data.csv`}
                  role="button"
                  separator=";"
                  className="btn btn-primary"
                  style={{ fontSize: '14px', padding: '5px 10px' }}
                >
                  {t('exportData')}
                </CSVLink>
              )}
            </div>
            <table className={className}>
              <thead>
                <tr>
                  {headers.map(
                    (header, index) =>
                      (header.perm === undefined || header.perm) &&
                      (header.hide === undefined || !header.hide) && (
                        <th
                          className="w-auto"
                          key={`${index}${header.value}`}
                          onClick={() => handleSortingChange(header.key)}
                        >
                          {header.label}
                          {sortField === header.key && (
                            <span>{sortDirection === 'asc' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                      )
                  )}
                  {checkLengthActions && <th>{t('table.actions')}</th>}
                </tr>
              </thead>
              <tbody>
                {currentData?.map((row, rowIndex) => (
                  <tr key={`${rowIndex}${row.id}`}>
                    {headers.map(
                      (header, headerIndex) =>
                        (header.perm === undefined || header.perm) &&
                        (header.hide === undefined || !header.hide) && (
                          <td
                            key={`${headerIndex}${row.id}`}
                            className={`${
                              row.errorMessage !== undefined &&
                              row.errorMessage !== '' &&
                              header.key === 'id'
                                ? 'bg-danger'
                                : ''
                            }`}
                            style={{
                              fontSize:
                                tdfontSize === undefined
                                  ? '12px'
                                  : `${tdfontSize}px`,
                            }}
                          >
                            {customCellRenderer(row, header)}
                          </td>
                        )
                    )}
                    {selectButton && (
                      <td className="d-flex align-items-center align-middle justify-content-center">
                        <Button
                          text={t('table.select')}
                          textColor={TextColors.Light}
                          type={ButtonType.Successbg}
                          onClick={() =>
                            selectButton(
                              selectAll === undefined ? row.email : row
                            )
                          }
                          buttonCol={12}
                        />
                      </td>
                    )}
                    {checkLengthActions && (
                      <td>
                        {(filterActionsRule
                          ? filteredOptions(row, filterRule)?.length > 0
                          : true) && (
                          <span key={`${rowIndex}${row.id}${row.userId}`}>
                            <Dropdown
                              name={'Table Actions'}
                              options={
                                filterActionsRule
                                  ? filteredOptions(row, filterRule)
                                  : tableActions
                              }
                              id={'tableActions'}
                              propNames={{
                                id: 'id',
                                key: 'key',
                                value: 'value',
                                name: 'name',
                              }}
                              handleChange={(target) =>
                                handleSelectChange(target, row)
                              }
                              rightIcon={faEllipsis}
                            />
                          </span>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="mobile-table card-header">
            {searchAble && (
              <div className="my-2 top-0 end-0">
                <input
                  type="text"
                  placeholder={t('table.search')}
                  value={searchTerm}
                  onChange={handleSearch}
                  className="form-control mb-3"
                />
              </div>
            )}
            <div className="d-flex flex-column text-nowrap">
              {noUtc && (
                <span style={{ fontSize: '11px' }}>
                  {t('utc.calculation', { timeZone })}
                </span>
              )}
              {isManager && data?.length > 0 && (
                <CSVLink
                  data={data || []}
                  headers={headers}
                  filename={`${exportName}-data.csv`}
                  role="button"
                  separator=";"
                  className="btn btn-primary"
                  style={{ fontSize: '14px', padding: '5px 10px' }}
                >
                  {t('exportData')}
                </CSVLink>
              )}
            </div>
            {currentData?.map((item, index) => (
              <div key={index} className={`mobile-table-row border my-2`}>
                <div className="mobile-table-row-header card-header align-items-center d-flex justify-content-between px-2">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleRow(index)}
                    className="mobile-table-header-text flex-grow-1 fw-bold"
                  >
                    <FontAwesomeIcon
                      className="pe-1"
                      icon={expandedIndex === index ? faArrowUp : faArrowDown}
                    />
                    {headers.slice(0, 3).map((header, i) => (
                      <span key={i} className="ps-1">
                        {header.key === 'interval'
                          ? item[header.key]
                            ? `${calculateUTC(
                                item[header.key].split('-')[0]
                              )} - ${calculateUTC(
                                item[header.key].split('-')[1]
                              )}`
                            : ''
                          : header.key === 'createdAt' ||
                            header.key === 'deletedAt' ||
                            header.key === 'updatedAt' ||
                            header.key === 'orderDate' ||
                            header.key === 'startDate' ||
                            header.key === 'endDate' ||
                            header.key === 'lastOrderDate' ||
                            header.key === 'lastMeetingDate' ||
                            header.key === 'date' ||
                            header.key === 'paymentDate' ||
                            header.key === 'startTime' ||
                            header.key === 'endTime' ||
                            header.key === 'meetingDate'
                          ? item[header.key]
                            ? (() => {
                                const date = new Date(item[header.key]);
                                const month = (date.getMonth() + 1)
                                  .toString()
                                  .padStart(2, '0');
                                const day = date
                                  .getDate()
                                  .toString()
                                  .padStart(2, '0');
                                const year = date.getFullYear();
                                const hours = date
                                  .getHours()
                                  .toString()
                                  .padStart(2, '0');
                                const minutes = date
                                  .getMinutes()
                                  .toString()
                                  .padStart(2, '0');

                                const result = addDay(
                                  `${hours}:${minutes}`,
                                  `${year}-${month}-${day}`,
                                  false,
                                  false,
                                  false,
                                  false,
                                  false,
                                  true
                                );

                                const formattedDateTime =
                                  header.key === 'date'
                                    ? `${result.resultDate}`
                                    : `${result.resultDate} ${result.nTime}`;

                                return formattedDateTime;
                              })()
                            : ''
                          : item[header.key]}
                        {i !== 2 && ','}{' '}
                      </span>
                    ))}
                  </div>
                  {selectButton && !checkLengthActions && (
                    <div className="d-flex align-items-center align-middle justify-content-center">
                      <Button
                        text={t('table.select')}
                        textColor={TextColors.Light}
                        type={ButtonType.Successbg}
                        onClick={() =>
                          selectButton(
                            selectAll === undefined ? item.email : item
                          )
                        }
                        buttonCol={2}
                      />
                    </div>
                  )}
                  {checkLengthActions && (
                    <React.Fragment>
                      {(filterActionsRule
                        ? filteredOptions(item, filterRule)?.length > 0
                        : true) && (
                        <div className="mobile-table-header-icon">
                          <Dropdown
                            key={`${item.id} ${index}`}
                            name={'Table Actions'}
                            options={
                              filterActionsRule
                                ? filteredOptions(item, filterRule)
                                : tableActions
                            }
                            id={'tableActions'}
                            propNames={{
                              id: 'id',
                              key: 'key',
                              value: 'value',
                              name: 'name',
                            }}
                            handleChange={(target) =>
                              handleSelectChange(target, item)
                            }
                            rightIcon={faEllipsis}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
                {expandedIndex === index && (
                  <div className="card-body">
                    {headers.map((header, columnIndex) => (
                      <div
                        key={columnIndex}
                        className="d-flex justify-content-between border-bottom py-1"
                      >
                        {(header.perm === undefined || header.perm) &&
                          (header.hide === undefined || !header.hide) && (
                            <div className="fw-bold">{header.label}</div>
                          )}
                        {(header.perm === undefined || header.perm) &&
                          (header.hide === undefined || !header.hide) && (
                            <div className="mobile-table-column-data">
                              {customCellRenderer(item, header)}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {showLoading && filteredData?.length === 0 ? (
          <div className="d-flex mx-auto align-items-center py-3 justify-content-center">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : filteredData?.length === 0 ? (
          <div className="fw-bold d-flex justify-content-center">
            {t('basicTable.no_data')}
          </div>
        ) : undefined}
      </div>
      {!hidePagination && (
        <div className="my-2">
          {data?.length > 0 && (
            <Pagination
              totalItems={data?.length}
              itemsPerPage={10}
              paginate={(val) => setCurrentPage(val)}
            />
          )}
        </div>
      )}
      {confirmModal?.active && (
        <ConfirmModal
          ref={confirmModalRef}
          type={confirmModal?.type}
          page={confirmModal?.page}
          data={confirmModal?.data}
          onClose={() => {
            resetConfirmModalState();
          }}
          onSave={() => {
            handleSelectChange(confirmModal?.type, confirmModal?.data);
            confirmModalRef.current?.close();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Table;
