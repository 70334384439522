import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
  ButtonSize,
} from '../../../utils/ComponentEnums';
import calculateUTC from '../../../hooks/useCalculateUTC';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../../../components/Common/Modal/Modal';
import Input from '../../../components/Common/Input/Input';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import getTimeZone from '../../../hooks/useGetTimeZone';
import Claims from '../../../utils/Claims';
import checkClaims from '../../../hooks/useCheckClaims';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const meetingSchemaId = searchParams.get('meetingSchemaId');
  const interval = searchParams.get('interval');
  const dayOfWeekMeetingSchema = searchParams.get('dayOfWeekMeetingSchema');
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const companyList = useSelector((state) => state.company.getAll);
  const meetingSchemaData = useSelector((state) => state.meetingSchema.getById);
  const isSuccess = useSelector((state) => state.meetingSchema.isSuccess);
  const validationMessage = useSelector(
    (state) => state.meetingSchema.validationMessage
  );
  const managerList = useSelector((state) => state.manager.getAll);
  const locationList = useSelector((state) => state.location.getAll);
  const sessionPackageList = useSelector(
    (state) => state.sessionPackage.getAll
  );
  const staticDatas = useSelector((state) => state.staticData);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const serviceType = staticDatas.UserArea;
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const dropdownListSpeaker = useSelector(
    (state) => state.meetingSchema.dropdownList
  );
  const dayList = staticDatas.DayOfWeeks;
  const [updateData, setUpdateData] = useState({});
  const [dataChanged, setDataChanged] = useState(true);
  const redirect = '/meeting-schema-list';
  const [handleError, setHandleError] = useState('');
  const [error, setError] = useState(false);

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.meetingSchemaActions.cleanState());
  };

  useEffect(() => {
    if (handleError !== '' && !isSuccess) {
      toast.warning(handleError, {
        onClose: () => {
          setHandleError('');
        },
      });
    } else if (handleError !== '' && isSuccess) {
      toast.success(handleError, {
        onClose: () => {
          dispatch(Actions.meetingSchemaActions.cleanState(true));
          setUpdateData({});
          setHandleError('');
          navigate(redirect);
        },
      });
    }
  }, [handleError]);

  useEffect(() => {
    if (isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    } else if (!isSuccess && validationMessage !== '') {
      setHandleError(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{validationMessage}</strong>
        </span>
      );
    }
  }, [isSuccess, validationMessage]);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    setUpdateData({
      ...updateData,
      [id]: type === 'number' ? parseInt(value) : value,
    });
    if (value) {
      setDataChanged(false);
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.meetingSchemaActions.updateAction(updateData, meetingSchemaId)
    );
  };

  function FetchById() {
    dispatch(Actions.meetingSchemaActions.getByIdAction(meetingSchemaId));
  }
  const getList = () => {
    dispatch(Actions.companyActions.dropdownListAction());
    dispatch(Actions.managerActions.getAllAction('Dropdown'));
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.sessionPackageActions.getAllAction());
    dispatch(Actions.ageIntervalActions.getAllAction());
  };
  const getSpeakerDropdownlist = () => {
    dispatch(
      Actions.meetingSchemaActions.getSpeakerDropdownListAction(updateData)
    );
  };

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  useEffect(() => {
    if (
      updateData.dayOfWeekView !== undefined &&
      updateData.speakerId !== undefined
    ) {
      getSpeakerDropdownlist();
    }
  }, [updateData]);

  const timeSlotsNormal = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const val = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;
      timeSlotsNormal.push({
        key: val,
      });
    }
  }

  useEffect(() => {
    if (meetingSchemaId) {
      FetchById();
      getList();
    }

    return () => {
      dispatch(Actions.meetingSchemaActions.cleanState());
    };
  }, [meetingSchemaId]);

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const ageIntervalWithOptions = ageInterval?.map((item) => ({
    ...item,
    name: `${item.minAge} - ${item.maxAge}`,
  }));
  function calculateNewDate(time, date) {
    if (date !== undefined && date !== null) {
      const valueFromInterval = time?.split('-')[0];
      const [hours, minutes] = valueFromInterval.split(':').map(Number);
      const parts = date.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      const currentDate = new Date(year, month, day, hours, minutes);

      let adjustedDate = new Date(
        currentDate.getTime() - getTimeZone(true) * 60000
      );

      const resultDate = `${adjustedDate
        .getDate()
        .toString()
        .padStart(2, '0')}-${(adjustedDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${adjustedDate.getFullYear()}`;

      return resultDate;
    }
  }

  const fixTime = (time, anotherDay, initial) => {
    const dayOfWeekMap = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };
    const [h, m] = time.split(':').map(Number);
    const today = new Date();
    today.setHours(h, m);
    today.setDate(
      today.getDate() +
        ((dayOfWeekMap[
          anotherDay !== undefined ? anotherDay : updateData?.dayOfWeekView
        ] +
          7 -
          today.getDay()) %
          7)
    );
    today.setMinutes(!initial ? 0 : today.getMinutes() + getTimeZone(true));

    const result = calculateNewDate(
      `${time}-`,
      `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${today.getFullYear()}`
    );

    const [d, mo, y] = result.split('-').map(Number);
    const resultDayOfWeek = new Date(y, mo - 1, d);
    const dayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    if (!initial) {
      handleDropdown(
        'startTime',
        `${y}-${mo.toString().padStart(2, '0')}-${d
          .toString()
          .padStart(2, '0')}T${calculateUTC(time, true)}:00.000Z`,
        dayNames[resultDayOfWeek.getDay()],
        anotherDay
      );
    }
    if (initial) {
      setUpdateData({
        ...meetingSchemaData,
        startTime: `${y}-${mo.toString().padStart(2, '0')}-${d
          .toString()
          .padStart(2, '0')}T${time}:00.000Z`,
        dayOfWeek: anotherDay,
        dayOfWeekView: dayNames[resultDayOfWeek.getDay()],
      });
    }
  };

  const formattedTime = (startTime) => {
    return startTime
      ? new Date(startTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : '';
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      setUpdateData({
        ...updateData,
        [id]: `${value}T00:00:00Z`,
        [`${id}View`]: value,
      });
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }

    setDataChanged(false);
  };
  const [showSpeakerChangePopup, setShowSpeakerChangePopup] = useState(false);
  const [isOneTimeEvent, setIsOneTimeEvent] = useState(true);
  const [prevSpeakerId, setPrevSpeakerId] = useState(null);

  useEffect(() => {
    setPrevSpeakerId(updateData.speakerId);
    setShowSpeakerChangePopup(false);
  }, [updateData.speakerId]);

  useEffect(() => {
    if (
      prevSpeakerId !== null &&
      prevSpeakerId !== undefined &&
      updateData.speakerId !== prevSpeakerId
    ) {
      setShowSpeakerChangePopup(true);
    }
  }, [prevSpeakerId, updateData.speakerId]);

  const handlePopupSubmit = () => {
    setShowSpeakerChangePopup(false);
    setUpdateData((prevData) => ({
      ...prevData,
      isSpeakerEditCover: isOneTimeEvent,
    }));
  };

  const handleDropdown = (
    propName,
    value,
    extraValue,
    anotherDay = updateData.dayOfWeekView
  ) => {
    if (extraValue) {
      setUpdateData({
        ...updateData,
        [propName]: value,
        dayOfWeek: extraValue,
        dayOfWeekView: anotherDay,
      });
      setDataChanged(false);
    } else if (propName === 'userArea') {
      setUpdateData({ ...updateData, [propName]: parseInt(value) });
      setDataChanged(false);
    } else {
      setUpdateData({ ...updateData, [propName]: value });
      setDataChanged(false);
    }
  };

  useEffect(() => {
    if (
      Object.keys(meetingSchemaData).length > 0 &&
      Object.keys(updateData).length === 0 &&
      !error
    ) {
      setError(true);
      fixTime(interval?.split(' -')[0], dayOfWeekMeetingSchema, true);
    }
  }, [meetingSchemaData]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-5 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('meetingSchema.update_meeting_schema_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_user_area')}
                      options={serviceType.map((item) => ({
                        key: item.key,
                        value: item.value,
                        name: 'userArea',
                      }))}
                      selectedValues={String(updateData.userArea)}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                        searchKey: 'value',
                      }}
                      onChange={(value) => {
                        handleDropdown('userArea', value);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedValues={
                        locationList.length > 0 ? updateData.locationId : ''
                      }
                      noTranslate={true}
                      selectedProps={{
                        key: 'locationName',
                        value: 'id',
                        searchKey: 'id',
                      }}
                      onChange={(value) => handleDropdown('locationId', value)}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_level')}
                      options={selectedLanguageLevels}
                      selectedProps={{
                        key: 'key',
                        value: 'key',
                        searchKey: 'key',
                      }}
                      selectedValues={updateData.languageLevels}
                      onChange={(value) =>
                        handleDropdown('languageLevels', value)
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_session_package')}
                      options={sessionPackageList}
                      selectedValues={
                        sessionPackageList.length > 0
                          ? updateData.sessionPackageId
                          : ''
                      }
                      noTranslate={true}
                      selectedProps={{
                        key: 'person',
                        supKey: 'minute',
                        value: 'id',
                        searchKey: 'id',
                        display: 'displayName',
                      }}
                      onChange={(value) =>
                        handleDropdown('sessionPackageId', value)
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_days')}
                      options={dayList}
                      selectedValues={updateData.dayOfWeekView}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                        searchKey: 'value',
                      }}
                      onChange={(value) => {
                        fixTime(formattedTime(updateData.startTime), value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.start_time')}
                      options={timeSlotsNormal}
                      selectedProps={{
                        key: 'key',
                        value: 'key',
                        searchKey: 'key',
                      }}
                      selectedValues={formattedTime(updateData.startTime)}
                      onChange={(value) => {
                        fixTime(value, undefined, false);
                      }}
                      noTranslate={true}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_speaker')}
                      options={dropdownListSpeaker}
                      selectedProps={{
                        key: 'speakerName',
                        value: 'speakerId',
                        searchKey: 'speakerId',
                      }}
                      noTranslate={true}
                      selectedValues={
                        dropdownListSpeaker.length > 0
                          ? updateData.speakerId
                          : ''
                      }
                      onChange={(value) => handleDropdown('speakerId', value)}
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_manager')}
                      options={managerList}
                      selectedValues={
                        managerList.length > 0 ? updateData.managerId : ''
                      }
                      noTranslate={true}
                      selectedProps={{
                        key: 'managerName',
                        value: 'managerId',
                        searchKey: 'managerId',
                      }}
                      onChange={(value) => handleDropdown('managerId', value)}
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={
                        updateData.startDateView ||
                        updateData?.startDate?.split('T')[0]
                      }
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={
                        updateData.endDateView ||
                        updateData?.endDate?.split('T')[0]
                      }
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('dropdownSelect.select_age_interval')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: ageIntervalWithOptions.map((item) => ({
                          key: item.name,
                          value: item.id,
                          name: 'ageIntervalId',
                        })),

                        selectedValue: updateData.ageIntervalId || '',
                      }}
                      handleSelectChange={(val) => {
                        setUpdateData({
                          ...updateData,
                          ageIntervalId: val.value,
                        });
                        setDataChanged(false);
                      }}
                      noTranslate={true}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'privateCapacity'}
                      inputType={InputType.Number}
                      labelText={t('meetingSchema.private_capacity')}
                      inputValue={updateData.privateCapacity || 0}
                      onChange={onChangeText}
                      minNumber={0}
                      maxNumber={5}
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_company')}
                      options={companyList}
                      selectedValues={updateData.companyId || ''}
                      noTranslate={true}
                      selectedProps={{
                        key: 'companyName',
                        value: 'companyId',
                        searchKey: 'companyId',
                      }}
                      onChange={(value) => handleDropdown('companyId', value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        dataChanged
                          ? ButtonType.Secondary
                          : ButtonType.Successbg
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onSave}
                      buttonCol={2}
                      disabled={dataChanged ? true : false}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      {handleError !== '' && (
        <ToastContainer
          style={customToastStyle}
          autoClose={isSuccess ? 1000 : false}
          closeOnClick
          theme="light"
        />
      )}
      <Modal
        isVisible={showSpeakerChangePopup === true ? true : false}
        styleProps={{ maxWidth: 550 }}
        content={
          <div>
            <div className="fs-6 d-flex align-items-center">
              <Input
                key={'isSpeakerEditCover'}
                id={'isSpeakerEditCover'}
                value={isOneTimeEvent}
                labelValue={t('meetingSchema.is_this_change_for_one_week')}
                checked={isOneTimeEvent}
                type={InputType.Checkbox}
                onClick={(e) => {
                  setIsOneTimeEvent(e.target.checked);
                  setDataChanged(false);
                }}
              />
            </div>
            <div className="col-3 d-flex justify-content-end ms-auto flex-shrink-0">
              <Button
                type={ButtonType.Successbg}
                size={ButtonSize.Small}
                textColor={TextColors.Light}
                text={t('button.submit')}
                onClick={handlePopupSubmit}
              />
            </div>
          </div>
        }
        onClose={() => handlePopupSubmit()}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.meetingSchema.update,
    content
  );
};
export default Update;
