import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faCheck,
  faCircleCheck,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import Actions from '../../../store/redux/actions';
import VideoModal from '../../../components/Common/Modal/VideoModal';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import truncateString from '../../../hooks/useTruncateString';
import {
  TextColors,
  ButtonIcon,
  InputType,
  ButtonType,
  ButtonSize,
  LabelTextType,
  LabelFontSize,
  BackGroundColors,
} from '../../../utils/ComponentEnums';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Faq from '../Faq';
import findMyPath from '../../../hooks/useFindMyPath';
import online from '../../../assets/images/meet2talk_button.svg';
import kids from '../../../assets/images/kids.svg';
import cafe from '../../../assets/images/cafe.svg';
import company from '../../../assets/images/marketing.svg';
import landingCafeBackGround from '../../../assets/images/kadikoy-landingpage-banner-resim.png';
import landingBackGround from '../../../assets/images/landingBackGroundImage.png';
import landingCalendar from '../../../assets/images/landingcalendar.svg';
import landingPeople from '../../../assets/images/landingpeople.svg';
import landingClown from '../../../assets/images/landingclown.svg';
import landingFormBehind from '../../../assets/images/landingFormBehind.svg';
import landingSeparator from '../../../assets/images/landingSeparator.svg';
import landingCardCover from '../../../assets/images/landingCardCover.svg';
import companyBanner from '../../../assets/images/companyBanner.png';
import googleRating from '../../../assets/images/online-landingpage-google-rank.svg';
import Label from '../../../components/Common/TextLabel/Label';
import NewLetUsCallYou from './MainSubPages/NewLetUsCallYou';
import Carousel from '../../../components/Common/Carousel/Carousel';
import LandingCard from '../../../components/Common/Card/LandingCard';
import style from '../../../assets/Styles.module.scss';

const NewKadikoy = () => {
  const { t } = useTranslation();
  const pageSize = 4;
  const pageNumber = 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const userAreaEnum = useSelector((state) => state.onboard.enum);
  const staticDatas = useSelector((state) => state.staticData);
  const speakers = useSelector((state) => [state.speaker.filteredSpeakers]);
  const locations = useSelector((state) => state.location.branches);
  const arrMap = speakers.flatMap((m) => m);
  const learningPurpose = staticDatas.LearningPurpose;
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const languageLevels = staticDatas.LanguageLevels?.[levelGroup.adult];
  const kidsLangLevel = staticDatas.KidsLangLevel;
  const typeOfService = staticDatas.TypeOfService;
  const native = staticDatas.Native;
  const totalItem = useSelector((state) => state.speaker.pagination.TotalCount);
  const holdFilter = useSelector((state) => state.holdFilter.filter);
  const [showLoader, setShowLoader] = useState(false);

  const stopPropagation = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageClick(value);
  };

  const isMobile = window.innerWidth <= 768;

  const [filter, setFilter] = useState({
    LearningPurpose: isMobile
      ? holdFilter?.LearningPurpose?.map((item) => parseInt(item.value))
      : holdFilter?.LearningPurpose?.map((item) =>
          parseInt(item?.value?.split('_')[1])
        ) || null,
    LanguageLevels: holdFilter?.LanguageLevels?.value || null,
    TypeOfService:
      holdFilter?.UserArea?.value === 1
        ? [1]
        : holdFilter?.TypeOfService?.map((item) => parseInt(item.value)) ||
          null,
    Native: isMobile
      ? holdFilter?.Native?.map((item) => parseInt(item.value))
      : holdFilter?.Native?.map((item) =>
          parseInt(item?.value?.split('_')[1])
        ) || null,
    FullName: null,
    UserArea: holdFilter?.UserArea?.value || 0,
    Locations:
      holdFilter?.UserArea?.value !== 1
        ? [1]
        : holdFilter?.Locations?.map((item) => parseInt(item.value)) || null,
    DateDetails: {
      dayOfWeek: holdFilter?.DateDetails?.dayOfWeek || null,
      timeInterval: holdFilter?.DateDetails?.timeInterval || null,
      selectedDate: holdFilter?.DateDetails?.selectedDate || null,
      selectedTime: holdFilter?.DateDetails?.selectedTime || null,
    },
  });
  const onlineArea = { key: 'UserArea.0', name: 'UserArea', value: 0 };
  const [selectedBefore, setSelectedBefore] = useState(undefined);

  useEffect(() => {
    if (selectedBefore !== null) {
      if (
        holdFilter?.TypeOfService?.length === 1 &&
        holdFilter?.TypeOfService?.[0].value === '1'
      ) {
        setSelectedBefore(undefined);
      } else if (filter?.TypeOfService?.length === undefined) {
        setSelectedBefore(undefined);
      }
    }
  }, [filter?.TypeOfService]);

  useEffect(() => {
    dispatch(
      Actions.speakerActions.getFilteredAction(pageNumber, pageSize, filter)
    ).then(() => {
      setShowLoader(false);
    });
  }, []);

  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  function extractVideoId(url) {
    const patterns = [
      /(?:youtube\.com\/embed\/|youtu.be\/|youtube.com\/watch\?v=)([\w-]{11})/,
      /^([\w-]{11})$/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match) {
        return match[1];
      }
    }

    return null;
  }

  const handleImageClick = (url) => {
    url = extractVideoId(url);
    if (url) {
      setVideoModalVisible(true);
      setVideoUrl(url);
    }
  };
  const closeVideoModal = () => {
    setVideoModalVisible(false);
  };

  const handleFreeTrialClick = () => {
    navigate('/order/triallesson', {
      state: {
        fromNavigateWhere: 'freeTrial',
        trialStatus: 0,
      },
    });
  };

  function removeHTMLTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  function handleNavigateCard(e, to, state) {
    e.preventDefault();
    dispatch(Actions.calendarActions.getFilteredReducer([]));
    return navigate(to, state);
  }
  const onboardingData = [
    {
      type: 'Online',
      labelValue: 'Online',
      imgSrc: online,
      selfUpdateActionIndex: 0,
      to: '/online',
    },
    {
      type: 'Cafe',
      labelValue: 'Cafe',
      imgSrc: cafe,
      selfUpdateActionIndex: 1,
      to: '/cafe',
    },
    {
      type: 'Kids',
      labelValue: 'Kids',
      imgSrc: kids,
      selfUpdateActionIndex: 2,
      to: '/kids',
    },
    {
      type: 'Company',
      labelValue: 'Company',
      imgSrc: company,
      selfUpdateActionIndex: 3,
      to: '/company',
    },
  ];

  const handleClick = (link, area = undefined) => {
    navigate(link);
    if (area !== undefined) {
      dispatch(Actions.onboardActions.onboardType(area));
    }
  };
  useEffect(() => {
    dispatch(Actions.onboardActions.onboardType(0));
  }, []);
  function editContent(text, boldWords = [], colorMe = false) {
    const regexPattern = /(.+?)\s*%([^%]+)%\s*(.+)/;
    const matchResult = text.match(regexPattern);
    if (colorMe) {
      const regex = /%([^%]*)%/;
      const match = text.match(regex);
      const extractedText = match ? match[1] : '';
      return (
        <p>
          {matchResult?.[1]}
          <span className={`text-custom-danger`}>
            <strong>{` ${extractedText} `}</strong>
          </span>
          {matchResult?.[3]}
        </p>
      );
    }
    const boldText = matchResult ? matchResult[2] : '';

    let additionalWord = '';
    if (boldWords.includes(boldText)) {
      additionalWord = boldText;
    }

    if (matchResult) {
      const beforeText = matchResult[1];
      const afterText = matchResult[3];

      return (
        <p>
          {beforeText}
          <strong className="fw-bold text-landing-green">
            {' '}
            {additionalWord}{' '}
          </strong>
          {afterText}
        </p>
      );
    } else {
      return <p>{text}</p>;
    }
  }

  const carouselContents = [
    { name: 'İrem K.', videoSrc: 'https://www.youtube.com/embed/5bHAIApa1Do' },
    { name: 'Gülru', videoSrc: 'https://www.youtube.com/embed/HPFJ9bD93mE' },
    { name: 'Fatih', videoSrc: 'https://www.youtube.com/embed/XBj8ujsPTcs' },
    { name: 'Tuğçe', videoSrc: 'https://www.youtube.com/embed/8pqsMA833WU' },
    { name: 'Cansu', videoSrc: 'https://www.youtube.com/embed/sqQgV5xLuek' },
    { name: 'Oğulcan', videoSrc: 'https://www.youtube.com/embed/YP0vJJfPes0' },
  ];

  const [mobileView, setMobileView] = useState();
  const handleResize = () => {
    const currentWidth = window.innerWidth;
    if (currentWidth === 663 || currentWidth < 577) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const content = (
    <div className="container-fluid align-items-center bg-light">
      {!mobileView && (
        <React.Fragment>
          <div className="rounded-3 py-md-5">
            <div className="container flex-md-column">
              <div className="d-flex justify-content-center">
                <div className="col-sm-12 col-md-8 mt-md-5 my-auto">
                  <p className="pb-md-4 pb-4 pt-3 text-start display-6">
                    <span
                      role="button"
                      className="bg-custom text-white px-2 fw-bold border rounded-1"
                      onClick={() => navigate('/order/triallesson')}
                    >
                      {t('landing.freeTrial')}
                    </span>
                  </p>
                  <p className="pb-md-4 pb-4 pt-3 text-start display-5">
                    {editContent(t('newMain.socializing_with_our_native'), [
                      'sosyalleşerek',
                      'socializing',
                    ])}
                  </p>
                  <img src={googleRating} alt={googleRating} />
                </div>
                {!mobileView && (
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundImage: `url(${landingFormBehind})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      maxWidth: '440px',
                      minHeight: '500px',
                      position: 'relative',
                      zIndex: 999,
                    }}
                  >
                    <div
                      className="mb-5"
                      style={{
                        position: 'absolute',
                        top: '-30px',
                        left: '20px',
                        width: '400px',
                        height: '100%',
                      }}
                    >
                      <NewLetUsCallYou area={0} location={'Online'} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {mobileView && (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundImage: `url(${landingFormBehind})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  maxWidth: '370px',
                  minHeight: '500px',
                  position: 'relative',
                }}
              >
                <div
                  className="mb-5"
                  style={{
                    position: 'absolute',
                    top: '-30px',
                    left: '10px',
                    width: '380px',
                    height: '100%',
                  }}
                >
                  <NewLetUsCallYou area={0} location={'Online'} />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              backgroundImage: `url(${landingSeparator})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              minHeight: '500px',
              position: 'relative',
              top: '-150px',
            }}
          >
            <div
              className="ms-5 me-5 mentors"
              style={{
                position: 'absolute',
                top: '170px',
              }}
            >
              <div className="row justify-content-center">
                <div className="d-block content-wrapper">
                  <div
                    id="speakersContent"
                    className={
                      'row d-flex justify-content-center row-cols-1 row-cols-md-3 row-cols-lg-5'
                    }
                  >
                    {showLoader ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="spinner-border " role="status"></div>
                      </div>
                    ) : arrMap.length === 0 ? (
                      <div className="d-flex justify-content-center text-nowrap">
                        {t('mentor.no_speaker_found')}
                      </div>
                    ) : (
                      arrMap.map((speakerItem, index) => {
                        return (
                          <div
                            className="col-3"
                            key={`${speakerItem.specialUrl}_${index}`}
                          >
                            <Link
                              className="text-reset text-decoration-none"
                              id={speakerItem?.id}
                              title={speakerItem?.specialUrl}
                              to={findMyPath(
                                `speakers/speaker-detail/${speakerItem?.id}`
                              )}
                              state={{
                                userArea: filter?.UserArea,
                                id: speakerItem?.id,
                              }}
                            >
                              <Card
                                padding={8}
                                cardStyle={{
                                  maxWidth: '250px',
                                  maxHeight: '400px',
                                }}
                                body={
                                  <div>
                                    <div style={{ position: 'relative' }}>
                                      {speakerItem?.speakerImage ? (
                                        <>
                                          <img
                                            src={speakerItem?.speakerImage}
                                            className={`card-img-top img-fluid rounded-4`}
                                            alt={speakerItem?.speakerName}
                                            onClick={(e) =>
                                              stopPropagation(
                                                e,
                                                speakerItem.specialUrl
                                              )
                                            }
                                            style={{
                                              maxWidth: '100%',
                                              height: '200px',
                                              objectFit: 'cover', //cover, contain
                                            }}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                '/images/empty-profile.png';
                                            }}
                                            loading="lazy"
                                          />
                                          {speakerItem.specialUrl && (
                                            <div className="video-icon-container">
                                              <FontAwesomeIcon
                                                className="video-player-icon"
                                                icon={faPlayCircle}
                                                onClick={(e) =>
                                                  stopPropagation(
                                                    e,
                                                    speakerItem.specialUrl
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src="/images/empty-profile.png"
                                          className={`card-img-top img-fluid rounded-4`}
                                          alt={speakerItem?.speakerName}
                                          onClick={(e) =>
                                            stopPropagation(
                                              e,
                                              speakerItem.specialUrl
                                            )
                                          }
                                          style={{
                                            maxWidth: '100%',
                                            height: '200px',
                                            maxHeight: '50%',
                                            objectFit: 'cover', //cover, contain
                                          }}
                                          loading="lazy"
                                        />
                                      )}
                                    </div>

                                    <p
                                      className={`card-title fs-6 my-2 fw-bold`}
                                    >
                                      {speakerItem?.firstName +
                                        ' ' +
                                        speakerItem?.lastName}
                                    </p>

                                    <div
                                      className={`card-text d-inline-block`}
                                      style={{
                                        overflowY: 'hidden',
                                        height: '85px',
                                        maxHeight: '85px',
                                      }}
                                    >
                                      {removeHTMLTags(
                                        truncateString(
                                          speakerItem?.htmlContent,
                                          350
                                        )
                                      )}
                                    </div>
                                    <div className="d-flex justify-content-around">
                                      <div className="px-1 col text-nowrap">
                                        <Button
                                          text={t(
                                            'landingMainBanner.get_trial'
                                          )}
                                          type={ButtonType.Success}
                                          size={ButtonSize.Small}
                                          outline
                                          bold
                                          onClick={(e) =>
                                            handleNavigateCard(
                                              e,
                                              userAreaEnum === 0
                                                ? '/order/triallesson'
                                                : userAreaEnum === 1
                                                ? '/cafe/order/triallesson'
                                                : userAreaEnum === 2
                                                ? '/kids/order/triallesson'
                                                : '/order/triallesson',
                                              {
                                                state: {
                                                  fromNavigateWhere:
                                                    'freeTrial',
                                                  trialStatus: 0,
                                                  userArea:
                                                    filter?.UserArea || 0,
                                                  speakerId: speakerItem.id,
                                                  speakerName:
                                                    speakerItem.speakerName,
                                                  speakerImage:
                                                    speakerItem.speakerImage,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="px-1 col text-nowrap">
                                        <Button
                                          text={t('button.buy')}
                                          type={ButtonType.Successbg}
                                          textColor={TextColors.Light}
                                          size={ButtonSize.Small}
                                          bold
                                          onClick={(e) =>
                                            handleNavigateCard(
                                              e,
                                              findMyPath('speakers'),
                                              {
                                                state: {
                                                  directlyBuyCalendar: true,
                                                  trialStatus: 2,
                                                  userArea:
                                                    filter?.UserArea || 0,
                                                  speakerId: speakerItem.id,
                                                  speakerName:
                                                    speakerItem.speakerName,
                                                  speakerImage:
                                                    speakerItem.speakerImage,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                }
                              />
                            </Link>
                          </div>
                        );
                      })
                    )}
                  </div>
                  {isVideoModalVisible && (
                    <VideoModal
                      videoUrl={`https://www.youtube.com/embed/${videoUrl}`}
                      styleProps={{ maxWidth: 550, height: 500 }}
                      onClose={closeVideoModal}
                    />
                  )}
                </div>
                <div
                  className="col-md-12 col-lg-9 offset-lg-1 mx-md-5 pt-2 text-center mt-5"
                  id="landing.startToLearning"
                >
                  <h1 className="fw-bold">{t('startToLearning.text1')}</h1>
                  <p className="pt-3 font-weight-light fs-6">
                    {t('startToLearning.text2')}
                  </p>
                </div>
                <div className="col-lg-12">
                  <div className="mx-auto d-flex justify-content-center py-2">
                    <div className="d-inline-flex ">
                      <Button
                        text={t('startToLearning.button')}
                        textColor={TextColors.Light}
                        type={ButtonType.Successbg}
                        size={ButtonSize.Large}
                        icon={ButtonIcon.ArrowRightLong}
                        iconColor={TextColors.Light}
                        bold
                        border
                        onClick={() => navigate(findMyPath('speakers'))}
                        buttonCol={12}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="freeTrialBanner"
            style={{ position: 'relative', top: '190px' }}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center mx-auto mt-5"
              style={{
                backgroundImage: `url(${landingCafeBackGround})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '15px',
                width: '100%',
                maxWidth: '1200px',
                height: '250px',
              }}
            >
              <div className="row text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.freeTrialCardText1')}
                </span>
              </div>
              <div className="row mt-3 text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.freeTrialCardText2')}
                </span>
              </div>
              <div className="row mt-3">
                <div className="mx-auto d-flex justify-content-center py-2">
                  <div className="d-inline-flex">
                    <Button
                      text={t('landing.freeTrialCardButton')}
                      textColor={TextColors.Custom}
                      type={ButtonType.Light}
                      size={ButtonSize.Large}
                      icon={ButtonIcon.ArrowRightLong}
                      iconColor={TextColors.Custom}
                      bold
                      border
                      onClick={() => navigate('/account/register')}
                      buttonCol={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="commentsVideos"
            className="comments text-center ms-5"
            style={{ position: 'relative', top: '240px' }}
          >
            <div className="container">
              <div className="d-flex justify-content-center">
                {carouselContents.slice(0, 4).map((item, i) => (
                  <div
                    className={`col-sm-6 col-md-4 col-lg-2 me-5 mb-3 ms-lg-0 ms-md-0 ms-5`}
                    key={i}
                  >
                    <LandingCard
                      name={item.name}
                      videoSrc={item.videoSrc}
                      buttonText={t('landing.commentsVideo')}
                      buttonIcon={ButtonIcon.ArrowRightLong}
                      roundedPill
                      thumbnail
                      frameOn={i === 0}
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-5 text-center">
                <div className="col">
                  <span className="fw-bold fs-1">
                    {t('landing.commentsText1')}
                  </span>
                </div>
              </div>
              <div className="row mt-4 text-center">
                <div className="col">
                  <span className="fs-6">{t('landing.commentsText2')}</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col d-flex justify-content-center py-2">
                  <Button
                    text={t('landing.commentsButton')}
                    textColor={TextColors.Light}
                    type={ButtonType.Successbg}
                    size={ButtonSize.Large}
                    icon={ButtonIcon.ArrowRightLong}
                    iconColor={TextColors.Light}
                    bold
                    border
                    onClick={() => navigate(findMyPath('speakers'))}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div id="companyBanner" className="bg-light mt-5">
        <img
          className={style.imgLanding}
          src={companyBanner}
          alt="companyBanner"
        />
      </div> */}
          <div
            id="comperingBanner"
            className="container"
            style={{ position: 'relative', top: '280px' }}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center mx-auto"
              style={{
                backgroundImage: `url(${landingBackGround})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '15px',
                width: '100%',
                maxWidth: '1200px',
                height: '200px',
              }}
            >
              <div className="row text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.bannerExplainText1')}
                </span>
              </div>
              <div className="row mt-3 text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.bannerExplainText2')}
                </span>
              </div>
            </div>
          </div>
          <div
            id="compering"
            className="container"
            style={{ position: 'relative', top: '310px' }}
          >
            <div className="row justify-content-center text-start">
              <div className="col-12 col-md-5 col-lg-4 mb-4">
                <div
                  className="card border bg-light"
                  style={{ borderRadius: '30px' }}
                >
                  <ul className="list-group list-group-flush ms-3 me-3">
                    <li className="list-group-item d-flex justify-content-center mt-2">
                      <img
                        className="img"
                        src="/images/meet2talk-logo.svg"
                        alt="Meet2Talk Logo"
                        width="100%"
                        style={{ maxWidth: '200px' }}
                      />
                    </li>
                    {[
                      t('landing.comparingText1'),
                      t('landing.comparingText2'),
                      t('landing.comparingText3'),
                      t('landing.comparingText4'),
                      t('landing.comparingText5'),
                    ].map((text, index) => (
                      <li
                        className="list-group-item d-flex align-items-center"
                        key={index}
                      >
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="2x"
                          className="text-landing-green me-3"
                        />
                        <span>{text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-4 mb-4">
                <div
                  className="card border bg-light"
                  style={{ borderRadius: '30px' }}
                >
                  <ul className="list-group list-group-flush ms-3 me-3">
                    <h4 className="list-group-item fw-bold text-center mt-2">
                      {t('landing.comparingText6')}
                    </h4>
                    {[
                      t('landing.comparingText7'),
                      t('landing.comparingText8'),
                      t('landing.comparingText9'),
                      t('landing.comparingText10'),
                      t('landing.comparingText11'),
                    ].map((text, index) => (
                      <li
                        className="list-group-item d-flex align-items-center"
                        key={index}
                      >
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          size="2x"
                          className="text-custom-danger me-3"
                        />
                        <span>{text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            id="comperingDetailBanner"
            className="container"
            style={{ position: 'relative', top: '340px', zIndex: 99 }}
          >
            <div className="row text-center">
              <span className="fw-bold fs-1">
                {t('landing.comparingBannerHeader')}
              </span>
            </div>
            <div className="row mt-4 text-center">
              <span className="fs-6">{t('landing.commentsText2')}</span>
            </div>
            <div className="row mt-3">
              <div className="mx-auto d-flex justify-content-center py-2">
                <div className="d-inline-flex">
                  <Button
                    text={t('landing.commentsButton')}
                    textColor={TextColors.Light}
                    type={ButtonType.Successbg}
                    size={ButtonSize.Large}
                    icon={ButtonIcon.ArrowRightLong}
                    iconColor={TextColors.Light}
                    bold
                    border
                    onClick={() => navigate(findMyPath('speakers'))}
                    buttonCol={12}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${landingSeparator})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              minHeight: '500px',
              position: 'relative',
              top: '150px',
            }}
          >
            <div
              className="ms-5 me-5 mentors"
              style={{
                position: 'absolute',
              }}
            >
              <div className="row justify-content-center">
                <div
                  id="servicesBanner"
                  className="container"
                  style={{ position: 'relative', top: '370px' }}
                >
                  <div className="row text-center">
                    <span className="fw-bold fs-1">
                      {t('landing.servicesHeader')}
                    </span>
                  </div>
                  <div className="row mt-4 text-center">
                    <span className="fs-6">{t('landing.servicesText')}</span>
                  </div>
                  <div className="row mt-5">
                    <div
                      className="col-12 col-md-4 mb-3"
                      role="button"
                      onClick={() => navigate('/order/triallesson')}
                    >
                      <div
                        className="card border bg-custom d-flex justify-content-center p-1"
                        style={{
                          borderRadius: '60px',
                          flexDirection: 'row',
                          alignItems: 'center',
                          minHeight: '100px',
                        }}
                      >
                        <img
                          className="img ms-1"
                          src={landingCalendar}
                          alt="landingCard1"
                          style={{ maxWidth: '60px' }}
                        />
                        <h4 className="fw-bold text-white ms-3">
                          {t('landing.card1')}
                        </h4>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-4 mb-3"
                      role="button"
                      onClick={() => navigate('/online/speakers')}
                    >
                      <div
                        className="card border bg-custom d-flex justify-content-center p-1"
                        style={{
                          borderRadius: '60px',
                          flexDirection: 'row',
                          alignItems: 'center',
                          minHeight: '100px',
                        }}
                      >
                        <img
                          className="img ms-1"
                          src={landingPeople}
                          alt="landingCard2"
                          style={{ maxWidth: '60px' }}
                        />
                        <h4 className="fw-bold text-white ms-3">
                          {t('landing.card2')}
                        </h4>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-4 mb-3"
                      role="button"
                      onClick={() => navigate('/order/package')}
                    >
                      <div
                        className="card border bg-custom d-flex justify-content-center p-1"
                        style={{
                          borderRadius: '60px',
                          flexDirection: 'row',
                          alignItems: 'center',
                          minHeight: '100px',
                        }}
                      >
                        <img
                          className="img ms-1"
                          src={landingClown}
                          alt="landingCard3"
                          style={{ maxWidth: '60px' }}
                        />
                        <h4 className="fw-bold text-white ms-3">
                          {t('landing.card3')}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="services p-lg-5 py-2 text-center"
                  style={{ position: 'relative', top: '370px' }}
                >
                  <div className="container">
                    <h1 className="text-black mb-lg-5 py-2 fw-bold">
                      {t('MainPart4PresentationHeader.Header')}
                    </h1>
                    <div className="row mt-5">
                      {onboardingData?.map((item) => (
                        <div
                          className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5 d-flex flex-column"
                          key={item.to}
                        >
                          <Link
                            to={item.to}
                            onClick={() =>
                              handleClick(item.to, item.selfUpdateActionIndex)
                            }
                            className="d-flex justify-content-center align-items-start"
                          >
                            <Card
                              cardStyle={{
                                maxWidth: '135px',
                                maxHeight: '115px',
                              }}
                              imgSrc={{
                                src: item.imgSrc,
                                round: 'img-fluid',
                                alt: item.imgSrc,
                                maxWidth: '90px',
                                maxHeight: '70px',
                              }}
                              backGround={BackGroundColors[item?.type]}
                            />
                          </Link>
                          <Label
                            fontType={LabelTextType.Bold}
                            fontSize={LabelFontSize.fs5}
                            value={item.labelValue}
                            textColor={TextColors[item?.type]}
                          />
                          <p className="text-muted mx-sm-5 my-1 flex-grow-1">
                            {t(
                              `MainPart4PresentationHeader${item.labelValue}.Description`
                            )}
                          </p>
                          <Button
                            text={t('MainPart4PresentationHeader.More')}
                            textColor={TextColors.Dark}
                            bold
                            onClick={() =>
                              handleClick(item.to, item.selfUpdateActionIndex)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  id="freeTrialBanner"
                  className="container"
                  style={{ position: 'relative', top: '300px' }}
                >
                  <div
                    className="d-flex flex-column justify-content-center align-items-center mx-auto"
                    style={{
                      backgroundImage: `url(${landingCafeBackGround})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      borderRadius: '15px',
                      width: '100%',
                      maxWidth: '1200px',
                      height: '250px',
                    }}
                  >
                    <div className="row text-center">
                      <span className="fw-bold fs-2 text-white">
                        {t('landing.freeTrialCardText1')}
                      </span>
                    </div>
                    <div className="row mt-3 text-center">
                      <span className="fw-bold fs-2 text-white">
                        {t('landing.freeTrialCardText2')}
                      </span>
                    </div>
                    <div className="row mt-3">
                      <div className="mx-auto d-flex justify-content-center py-2">
                        <div className="d-inline-flex">
                          <Button
                            text={t('landing.freeTrialCardButton')}
                            textColor={TextColors.Custom}
                            type={ButtonType.Light}
                            size={ButtonSize.Large}
                            icon={ButtonIcon.ArrowRightLong}
                            iconColor={TextColors.Custom}
                            bold
                            border
                            onClick={() => navigate('/account/register')}
                            buttonCol={12}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ minHeight: '1000px' }}>{''}</div>
        </React.Fragment>
      )}
      {mobileView && (
        <React.Fragment>
          <div className="rounded-3 py-md-5">
            <div className="container flex-md-column">
              <div className="d-flex justify-content-center">
                <div className="col-sm-12 col-md-8 mt-md-5 my-auto">
                  <p className="pb-md-4 pb-4 pt-3 text-start display-6">
                    <span
                      role="button"
                      className="bg-custom text-white px-2 fw-bold border rounded-1"
                      onClick={() => navigate('/order/triallesson')}
                    >
                      {t('landing.freeTrial')}
                    </span>
                  </p>
                  <p className="pb-md-4 pb-4 pt-3 text-start display-5">
                    {editContent(t('newMain.socializing_with_our_native'), [
                      'sosyalleşerek',
                      'socializing',
                    ])}
                    <img src={googleRating} alt={googleRating} width={'80%'} />
                  </p>
                </div>
                {!mobileView && (
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundImage: `url(${landingFormBehind})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      maxWidth: '440px',
                      minHeight: '500px',
                      position: 'relative',
                    }}
                  >
                    <div
                      className="mb-5"
                      style={{
                        position: 'absolute',
                        top: '-30px',
                        left: '20px',
                        width: '400px',
                        height: '100%',
                      }}
                    >
                      <NewLetUsCallYou area={0} location={'Online'} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {mobileView && (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundImage: `url(${landingFormBehind})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  minHeight: '500px',
                  position: 'relative',
                }}
              >
                <div
                  className="mb-5"
                  style={{
                    position: 'absolute',
                    top: '-30px',
                    left: '10px',
                    width: '95%',
                    height: '100%',
                  }}
                >
                  <NewLetUsCallYou area={0} location={'Online'} />
                </div>
              </div>
            )}
          </div>
          <div className="mentors mt-5">
            <div className="container">
              <div className="row  py-3 justify-content-center">
                <div className="d-block content-wrapper">
                  <div
                    id="speakersContent"
                    className={'row row-cols-1 row-cols-md-2 row-cols-lg-4'}
                  >
                    {showLoader ? (
                      <div className="d-flex align-items-center py-3 justify-content-center">
                        <div className="spinner-border " role="status"></div>
                      </div>
                    ) : arrMap.length === 0 ? (
                      <div className="mx-auto d-flex justify-content-center text-nowrap">
                        {t('mentor.no_speaker_found')}
                      </div>
                    ) : (
                      arrMap.map((speakerItem, index) => {
                        return (
                          <div
                            className="col-12"
                            key={`${speakerItem.specialUrl}_${index}`}
                          >
                            <Link
                              className="text-reset text-decoration-none"
                              id={speakerItem?.id}
                              title={speakerItem?.specialUrl}
                              to={findMyPath(
                                `speakers/speaker-detail/${speakerItem?.id}`
                              )}
                              state={{
                                userArea: filter?.UserArea,
                                id: speakerItem?.id,
                              }}
                            >
                              <Card
                                padding={8}
                                body={
                                  <div>
                                    <div style={{ position: 'relative' }}>
                                      {speakerItem?.speakerImage ? (
                                        <>
                                          <img
                                            src={speakerItem?.speakerImage}
                                            className={`card-img-top img-fluid rounded-4`}
                                            alt={speakerItem?.speakerName}
                                            onClick={(e) =>
                                              stopPropagation(
                                                e,
                                                speakerItem.specialUrl
                                              )
                                            }
                                            style={{
                                              maxWidth: '100%',
                                              height: '200px',
                                              objectFit: 'cover', //cover, contain
                                            }}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                '/images/empty-profile.png';
                                            }}
                                            loading="lazy"
                                          />
                                          {speakerItem.specialUrl && (
                                            <div className="video-icon-container">
                                              <FontAwesomeIcon
                                                className="video-player-icon"
                                                icon={faPlayCircle}
                                                onClick={(e) =>
                                                  stopPropagation(
                                                    e,
                                                    speakerItem.specialUrl
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src="/images/empty-profile.png"
                                          className={`card-img-top img-fluid rounded-4`}
                                          alt={speakerItem?.speakerName}
                                          onClick={(e) =>
                                            stopPropagation(
                                              e,
                                              speakerItem.specialUrl
                                            )
                                          }
                                          style={{
                                            maxWidth: '100%',
                                            height: '200px',
                                            maxHeight: '50%',
                                            objectFit: 'cover', //cover, contain
                                          }}
                                          loading="lazy"
                                        />
                                      )}
                                    </div>

                                    <p
                                      className={`card-title fs-6 my-2 fw-bold`}
                                    >
                                      {speakerItem?.firstName +
                                        ' ' +
                                        speakerItem?.lastName}
                                    </p>

                                    <div
                                      className={`card-text d-inline-block`}
                                      style={{
                                        overflowY: 'hidden',
                                        height: '85px',
                                        maxHeight: '85px',
                                      }}
                                    >
                                      {removeHTMLTags(
                                        truncateString(
                                          speakerItem?.htmlContent,
                                          350
                                        )
                                      )}
                                    </div>
                                    <div className="d-flex justify-content-around">
                                      <div className="px-1 col text-nowrap">
                                        <Button
                                          text={t(
                                            'landingMainBanner.get_trial'
                                          )}
                                          type={ButtonType.Success}
                                          size={ButtonSize.Small}
                                          outline
                                          bold
                                          onClick={(e) =>
                                            handleNavigateCard(
                                              e,
                                              userAreaEnum === 0
                                                ? '/order/triallesson'
                                                : userAreaEnum === 1
                                                ? '/cafe/order/triallesson'
                                                : userAreaEnum === 2
                                                ? '/kids/order/triallesson'
                                                : '/order/triallesson',
                                              {
                                                state: {
                                                  fromNavigateWhere:
                                                    'freeTrial',
                                                  trialStatus: 0,
                                                  userArea:
                                                    filter?.UserArea || 0,
                                                  speakerId: speakerItem.id,
                                                  speakerName:
                                                    speakerItem.speakerName,
                                                  speakerImage:
                                                    speakerItem.speakerImage,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="px-1 col text-nowrap">
                                        <Button
                                          text={t('button.buy')}
                                          type={ButtonType.Successbg}
                                          textColor={TextColors.Light}
                                          size={ButtonSize.Small}
                                          bold
                                          onClick={(e) =>
                                            handleNavigateCard(
                                              e,
                                              findMyPath('speakers'),
                                              {
                                                state: {
                                                  directlyBuyCalendar: true,
                                                  trialStatus: 2,
                                                  userArea:
                                                    filter?.UserArea || 0,
                                                  speakerId: speakerItem.id,
                                                  speakerName:
                                                    speakerItem.speakerName,
                                                  speakerImage:
                                                    speakerItem.speakerImage,
                                                },
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                }
                              />
                            </Link>
                          </div>
                        );
                      })
                    )}
                  </div>
                  {isVideoModalVisible && (
                    <VideoModal
                      videoUrl={`https://www.youtube.com/embed/${videoUrl}`}
                      styleProps={{ maxWidth: 550, height: 500 }}
                      onClose={closeVideoModal}
                    />
                  )}
                </div>
                <div
                  className="col-md-12 col-lg-9 offset-lg-1 mx-md-5 pt-2 text-center mt-5"
                  id="landing.startToLearning"
                >
                  <h1 className="fw-bold">{t('startToLearning.text1')}</h1>
                  <p className="pt-3 font-weight-light fs-6">
                    {t('startToLearning.text2')}
                  </p>
                </div>
                <div className="col-lg-12 ">
                  <div className="mx-auto d-flex justify-content-center py-2">
                    <div className="d-inline-flex ">
                      <Button
                        text={t('startToLearning.button')}
                        textColor={TextColors.Light}
                        type={ButtonType.Successbg}
                        size={ButtonSize.Large}
                        icon={ButtonIcon.ArrowRightLong}
                        iconColor={TextColors.Light}
                        bold
                        border
                        onClick={() => navigate(findMyPath('speakers'))}
                        buttonCol={12}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="freeTrialBanner" className="container mt-5">
            <div
              className="d-flex flex-column justify-content-center align-items-center mx-auto"
              style={{
                backgroundImage: `url(${landingCafeBackGround})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '15px',
                width: '100%',
                maxWidth: '1200px',
                height: '250px',
              }}
            >
              <div className="row text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.freeTrialCardText1')}
                </span>
              </div>
              <div className="row mt-3 text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.freeTrialCardText2')}
                </span>
              </div>
              <div className="row mt-3">
                <div className="mx-auto d-flex justify-content-center py-2">
                  <div className="d-inline-flex">
                    <Button
                      text={t('landing.freeTrialCardButton')}
                      textColor={TextColors.Custom}
                      type={ButtonType.Light}
                      size={ButtonSize.Large}
                      icon={ButtonIcon.ArrowRightLong}
                      iconColor={TextColors.Custom}
                      bold
                      border
                      onClick={() => navigate('/account/register')}
                      buttonCol={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="commentsVideos" className="text-center mt-5">
            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                {carouselContents.slice(0, 4).map((item, i) => (
                  <div
                    className="col-12 mb-3 d-flex justify-content-center"
                    key={i}
                  >
                    <LandingCard
                      name={item.name}
                      videoSrc={item.videoSrc}
                      buttonText={t('landing.commentsVideo')}
                      buttonIcon={ButtonIcon.ArrowRightLong}
                      roundedPill
                      thumbnail
                      frameOn={i === 0}
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-5 text-center">
                <div className="col">
                  <span className="fw-bold fs-1">
                    {t('landing.commentsText1')}
                  </span>
                </div>
              </div>
              <div className="row mt-4 text-center">
                <div className="col">
                  <span className="fs-6">{t('landing.commentsText2')}</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col d-flex justify-content-center py-2">
                  <Button
                    text={t('landing.commentsButton')}
                    textColor={TextColors.Light}
                    type={ButtonType.Successbg}
                    size={ButtonSize.Large}
                    icon={ButtonIcon.ArrowRightLong}
                    iconColor={TextColors.Light}
                    bold
                    border
                    onClick={() => navigate(findMyPath('speakers'))}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div id="companyBanner" className="bg-light mt-5">
        <img
          className={style.imgLanding}
          src={companyBanner}
          alt="companyBanner"
        />
      </div> */}
          <div id="comperingBanner" className="container mt-5">
            <div
              className="d-flex flex-column justify-content-center align-items-center mx-auto"
              style={{
                backgroundImage: `url(${landingBackGround})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '15px',
                width: '100%',
                maxWidth: '1200px',
                height: '200px',
              }}
            >
              <div className="row text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.bannerExplainText1')}
                </span>
              </div>
              <div className="row mt-3 text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.bannerExplainText2')}
                </span>
              </div>
            </div>
          </div>
          <div id="compering" className="container mt-5">
            <div className="row justify-content-center text-start">
              <div className="col-12 col-md-5 col-lg-4 mb-4">
                <div
                  className="card border bg-light"
                  style={{ borderRadius: '30px' }}
                >
                  <ul className="list-group list-group-flush ms-3 me-3">
                    <li className="list-group-item d-flex justify-content-center mt-2">
                      <img
                        className="img"
                        src="/images/meet2talk-logo.svg"
                        alt="Meet2Talk Logo"
                        width="100%"
                        style={{ maxWidth: '200px' }}
                      />
                    </li>
                    {[
                      t('landing.comparingText1'),
                      t('landing.comparingText2'),
                      t('landing.comparingText3'),
                      t('landing.comparingText4'),
                      t('landing.comparingText5'),
                    ].map((text, index) => (
                      <li
                        className="list-group-item d-flex align-items-center"
                        key={index}
                      >
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="2x"
                          className="text-landing-green me-3"
                        />
                        <span>{text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-4 mb-4">
                <div
                  className="card border bg-light"
                  style={{ borderRadius: '30px' }}
                >
                  <ul className="list-group list-group-flush ms-3 me-3">
                    <h4 className="list-group-item fw-bold text-center mt-2">
                      {t('landing.comparingText6')}
                    </h4>
                    {[
                      t('landing.comparingText7'),
                      t('landing.comparingText8'),
                      t('landing.comparingText9'),
                      t('landing.comparingText10'),
                      t('landing.comparingText11'),
                    ].map((text, index) => (
                      <li
                        className="list-group-item d-flex align-items-center"
                        key={index}
                      >
                        <FontAwesomeIcon
                          icon={faXmarkCircle}
                          size="2x"
                          className="text-custom-danger me-3"
                        />
                        <span>{text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            id="comperingDetailBanner"
            className="container mt-5"
            style={{ zIndex: 99 }}
          >
            <h1 className="fw-bold text-center">
              {t('landing.comparingBannerHeader')}
            </h1>
            <div className="row mt-4 text-center">
              <span className="fs-6">{t('landing.commentsText2')}</span>
            </div>
            <div className="row mt-3">
              <div className="mx-auto d-flex justify-content-center py-2">
                <div className="d-inline-flex">
                  <Button
                    text={t('landing.commentsButton')}
                    textColor={TextColors.Light}
                    type={ButtonType.Successbg}
                    size={ButtonSize.Large}
                    icon={ButtonIcon.ArrowRightLong}
                    iconColor={TextColors.Light}
                    bold
                    border
                    onClick={() => navigate(findMyPath('speakers'))}
                    buttonCol={12}
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="servicesBanner" className="container mt-5">
            <h1 className="fw-bold text-center">
              {t('landing.servicesHeader')}
            </h1>
            <div className="row mt-4 text-center">
              <span className="fs-6">{t('landing.servicesText')}</span>
            </div>
            <div className="row mt-3">
              <div
                className="col-12 col-md-4 mb-3"
                role="button"
                onClick={() => navigate('/order/triallesson')}
              >
                <div
                  className="card border bg-custom d-flex justify-content-center p-1"
                  style={{
                    borderRadius: '60px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minHeight: '100px',
                  }}
                >
                  <img
                    className="img ms-1"
                    src={landingCalendar}
                    alt="landingCard1"
                    style={{ maxWidth: '60px' }}
                  />
                  <h5 className="fw-bold text-white ms-2">
                    {t('landing.card1')}
                  </h5>
                </div>
              </div>
              <div
                className="col-12 col-md-4 mb-3"
                role="button"
                onClick={() => navigate('/online/speakers')}
              >
                <div
                  className="card border bg-custom d-flex justify-content-center p-1"
                  style={{
                    borderRadius: '60px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minHeight: '100px',
                  }}
                >
                  <img
                    className="img ms-1"
                    src={landingPeople}
                    alt="landingCard2"
                    style={{ maxWidth: '60px' }}
                  />
                  <h6 className="fw-bold text-white ms-2">
                    {t('landing.card2')}
                  </h6>
                </div>
              </div>
              <div
                className="col-12 col-md-4 mb-3"
                role="button"
                onClick={() => navigate('/order/package')}
              >
                <div
                  className="card border bg-custom d-flex justify-content-center p-1"
                  style={{
                    borderRadius: '60px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minHeight: '100px',
                  }}
                >
                  <img
                    className="img ms-1"
                    src={landingClown}
                    alt="landingCard3"
                    style={{ maxWidth: '60px' }}
                  />
                  <h5 className="fw-bold text-white ms-2">
                    {t('landing.card3')}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="services p-lg-5 py-2 text-center mt-5">
            <div className="container">
              <h1 className="text-black mb-lg-5 py-2 fw-bold">
                {t('MainPart4PresentationHeader.Header')}
              </h1>
              <div className="row mt-5">
                {onboardingData?.map((item) => (
                  <div
                    className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5 d-flex flex-column"
                    key={item.to}
                  >
                    <Link
                      to={item.to}
                      onClick={() =>
                        handleClick(item.to, item.selfUpdateActionIndex)
                      }
                      className="d-flex justify-content-center align-items-start"
                    >
                      <Card
                        cardStyle={{ maxWidth: '135px', maxHeight: '115px' }}
                        imgSrc={{
                          src: item.imgSrc,
                          round: 'img-fluid',
                          alt: item.imgSrc,
                          maxWidth: '90px',
                          maxHeight: '70px',
                        }}
                        backGround={BackGroundColors[item?.type]}
                      />
                    </Link>
                    <Label
                      fontType={LabelTextType.Bold}
                      fontSize={LabelFontSize.fs5}
                      value={item.labelValue}
                      textColor={TextColors[item?.type]}
                    />
                    <p className="text-muted mx-sm-5 my-1 flex-grow-1">
                      {t(
                        `MainPart4PresentationHeader${item.labelValue}.Description`
                      )}
                    </p>
                    <Button
                      text={t('MainPart4PresentationHeader.More')}
                      textColor={TextColors.Dark}
                      bold
                      onClick={() =>
                        handleClick(item.to, item.selfUpdateActionIndex)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div id="freeTrialBanner" className="container mt-5">
            <div
              className="d-flex flex-column justify-content-center align-items-center mx-auto"
              style={{
                backgroundImage: `url(${landingCafeBackGround})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '15px',
                width: '100%',
                maxWidth: '1200px',
                height: '250px',
              }}
            >
              <div className="row text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.freeTrialCardText1')}
                </span>
              </div>
              <div className="row mt-3 text-center">
                <span className="fw-bold fs-2 text-white">
                  {t('landing.freeTrialCardText2')}
                </span>
              </div>
              <div className="row mt-3">
                <div className="mx-auto d-flex justify-content-center py-2">
                  <div className="d-inline-flex">
                    <Button
                      text={t('landing.freeTrialCardButton')}
                      textColor={TextColors.Custom}
                      type={ButtonType.Light}
                      size={ButtonSize.Large}
                      icon={ButtonIcon.ArrowRightLong}
                      iconColor={TextColors.Custom}
                      bold
                      border
                      onClick={() => navigate('/account/register')}
                      buttonCol={12}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {/* <div className="faq-area py-4">
        <Faq />
      </div> */}
    </div>
  );
  return useLoader() ? (
    <Loader />
  ) : auth === '' ? (
    content
  ) : (
    <Navigate to={findMyPath('dashboard')} replace />
  );
};
export default NewKadikoy;
