import {
  create,
  update,
  remove,
  getById,
  getAll,
} from '../../../services/apiServices/managerServices';
import { reponseStatus, responseErrorStatus, storageKeys } from '../../../utils/Enum';
import ActionTypes from './actionTypes';
import config from '../../../config.json';

const createAction = (createData) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await create(createData).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(true));
        await dispatch(createReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const createReducer = () => {
  return { type: ActionTypes.manager.CREATE_ACTION_MANAGER };
};
const updateAction = (updateData, id) => {
  return async (dispatch) => {
    await dispatch(handleValidationReducer(''));
    await dispatch(handleError(''));
    await update(updateData, id).then(async (res) => {
      if (res?.data?.isSuccess) {
        dispatch(createReducer());
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(true));
        await dispatch(updateReducer());
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const updateReducer = () => {
  return { type: ActionTypes.manager.UPDATE_ACTION_MANAGER };
};
const removeAction = (id) => {
  return async (dispatch) => {
    await remove(id).then(async (res) => {
      if (res?.data?.isSuccess) {
        await dispatch(handleError(true));
        await dispatch(handleValidationReducer(res?.data?.message));
      } else {
        await dispatch(handleValidationReducer(res?.data?.message));
        await dispatch(handleError(false));
      }
    });
  };
};
const getByIdAction = (id) => {
  return async (dispatch) => {
    await getById(id).then(async (res) => {
      let response = res.data.data;
      await dispatch(getByIdReducer(response));
      await dispatch(holdValue(response));
    });
  };
};
const getByIdReducer = (data) => {
  return {
    type: ActionTypes.manager.GETBYID_ACTION_MANAGER,
    payload: { data },
  };
};
const getAllAction = (requestType) => {
  return async (dispatch) => {
    dispatch(cleanState(true, true));
    dispatch(getAllReducer([]))
    let maxRetries = config.RequestRule.retryCount;
    let isFirstRequest = true;
    while (maxRetries > 0) {
      if (!isFirstRequest) {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      try {
        const res = await getAll(requestType);
        let response = res?.data?.data;
        await dispatch(getAllReducer(response));
        await dispatch(handleError(""));
        isFirstRequest = false;
        break;
      } catch (error) {
        isFirstRequest = false;
        maxRetries--;
        if (maxRetries === 0) {
          await dispatch(handleValidationReducer(responseErrorStatus.RetryTimeOut));
          await dispatch(handleError(false));
          break;
        }
      }
    }
  };
};
const getAllReducer = (data) => {
  return { type: ActionTypes.manager.GETALL_ACTION_MANAGER, payload: { data } };
};
const holdValue = (data) => {
  return { type: ActionTypes.manager.HOLD_VALUE_MANAGER, payload: data };
};
const handleError = (data) => {
  return {
    type: ActionTypes.manager.HANDLE_ERROR_MANAGER,
    payload: data,
  };
};
const handleValidationReducer = (data) => {
  return {
    type: ActionTypes.manager.HANDLE_VALIDATION_MANAGER,
    payload: data,
  };
};
const cleanState = (validation = false, ongoing = false) => {
  return async (dispatch) => {
    if (validation) {
      dispatch(handleValidationReducer(''));
      dispatch(handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default));
    } else {
      await dispatch(handleValidationReducer(''));
      await dispatch(handleError(''));
      await dispatch(updateReducer());
    }
  };
};
const managerActions = {
  createAction,
  updateAction,
  removeAction,
  getByIdAction,
  getAllAction,
  holdValue,
  handleError,
  handleValidationReducer,
  cleanState,
};
export default managerActions;
