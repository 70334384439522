const auth = {
  REGISTER_URL: 'Auth/Register',
  LOGIN_URL: 'Auth/Login',
  REFRESH_URL: 'Auth/Refresh/',
  Logout: 'Auth/Logout',
};
const user = {
  GetAll: 'User/GetAll',
  GetById: 'User/GetById',
  Update: 'User/Update',
  ForgotPassword: 'User/ForgotPassword',
  ResetPassword: 'User/ResetPassword',
  PhoneConfirmation: 'User/PhoneConfirmation',
  ConfirmPhoneNumber: 'User/ConfirmPhoneNumber',
  CreateEmailToken: 'User/CreateEmailToken',
  VerifyEmail: 'User/VerifyEmail',
  ChangePassword: 'User/ChangePassword',
  CurrentUserInfos: 'User/CurrentUserInfos',
  UpdateUserArea: 'User/UpdateUserArea',
  UpdateLanguage: 'User/UpdateLanguage',
  UpdatePreference: 'User/UpdatePreference',
  GetCurrencyByIPAdress: 'User/GetCurrencyByIPAdress',
  SetDefaultPassword: 'User/SetDefaultPassword',
};
const companyPriceRequest = {
  CREATE_COMPANYPRICEREQUEST: 'CompanyPriceRequest/Create',
  UPDATE_COMPANYPRICEREQUEST: 'CompanyPriceRequest/Update',
  REMOVE_COMPANYPRICEREQUEST: 'CompanyPriceRequest/Delete',
  GETBYID_COMPANYPRICEREQUEST: 'CompanyPriceRequest/GetById',
  GETALL_COMPANYPRICEREQUEST: 'CompanyPriceRequest/GetAll',
};
const career = {
  CREATE_CAREER: 'Career/Create',
  UPDATE_CAREER: 'Career/Update',
  REMOVE_CAREER: 'Career/Delete',
  GETBYID_CAREER: 'Career/GetById',
  GETALL_CAREER: 'Career/GetAll',
};
const faq = {
  CREATE_FAQ: 'FAQ/Create',
  UPDATE_FAQ: 'FAQ/Update',
  REMOVE_FAQ: 'FAQ/Delete',
  GETBYID_FAQ: 'FAQ/GetById',
  GETALL_FAQ: 'FAQ/GetAll',
};
const faqGroup = {
  CREATE_FAQGROUP: 'FAQGroup/Create',
  UPDATE_FAQGROUP: 'FAQGroup/Update',
  REMOVE_FAQGROUP: 'FAQGroup/Delete',
  GETBYID_FAQGROUP: 'FAQGroup/GetById',
  GETALL_FAQGROUP: 'FAQGroup/GetAll',
};
const payment = {
  PAYMENT_INSTALLMENT: 'Payment/GetPrePaymentInfo',
  PAYMENT_PAYMENT: 'Payment/Payment',
  PAYMENT_CALLBACK: 'Payment/Callback',
};
const staticData = {
  CREATE_STATIC: 'StaticData/Create',
  UPDATE_STATIC: 'StaticData/Update',
  REMOVE_STATIC: 'StaticData/Delete',
  GETBYID_STATIC: 'StaticData/GetById',
  GETALL_STATIC: 'StaticData/GetAll',
  GETALLBYDATATYPE_STATIC: 'StaticData/GetAllByDataType',
};
const speaker = {
  CREATE_SPEAKER: 'Speaker/Create',
  UPDATE_SPEAKER: 'Speaker/Update',
  REMOVE_SPEAKER: 'Speaker/Delete',
  GETBYID_SPEAKER: 'Speaker/GetById',
  GETALL_SPEAKER: 'Speaker/GetAll',
  GETFILTRED_SPEAKER: 'Speaker/GetAll',
  SPEAKER_PAYMENT: 'Speaker/SpeakerPaymentBySpeaker',
};
const googleAuth = {
  LOGIN_URL: 'oauth/google/login',
  REGISTER_URL: 'oauth/google/register',
};
const facebookAuth = {
  LOGIN_URL: 'oauth/facebook/login',
  REGISTER_URL: 'oauth/facebook/register',
};
const couponCodes = {
  CREATE_COUPONCODES: 'Discount/Create',
  UPDATE_COUPONCODES: 'Discount/Update',
  REMOVE_COUPONCODES: 'Discount/Delete',
  GETBYID_COUPONCODES: 'Discount/GetById',
  GETBYNAME_COUPONCODES: 'CouponCodes/GetByName',
  GETALL_COUPONCODES: 'Discount/GetAll',
};
const timesPerWeek = {
  CREATE_TIMESPERWEEK: 'TimesPerWeek/Create',
  UPDATE_TIMESPERWEEK: 'TimesPerWeek/Update',
  REMOVE_TIMESPERWEEK: 'TimesPerWeek/Delete',
  GETBYID_TIMESPERWEEK: 'TimesPerWeek/GetById',
  GETALL_TIMESPERWEEK: 'TimesPerWeek/GetAll',
};
const sessionPackage = {
  CREATE_SESSIONPACKAGES: 'SessionPackage/Create',
  UPDATE_SESSIONPACKAGES: 'SessionPackage/Update',
  REMOVE_SESSIONPACKAGES: 'SessionPackage/Delete',
  GETBYID_SESSIONPACKAGES: 'SessionPackage/GetById',
  GETALL_SESSIONPACKAGES: 'SessionPackage/GetAll',
};
const zone = {
  CREATE_ZONE: 'Zone/Create',
  UPDATE_ZONE: 'Zone/Update',
  REMOVE_ZONE: 'Zone/Delete',
  GETBYID_ZONE: 'Zone/GetById',
  GETALL_ZONE: 'Zone/GetAll',
};
const attendee = {
  CREATE_ATTENDEE: 'Attendee/Create',
  UPDATE_ATTENDEE: 'Attendee/Update',
  REMOVE_ATTENDEE: 'Attendee/Delete',
  GETBYID_ATTENDEE: 'Attendee/GetById',
  GETALL_ATTENDEE: 'Attendee/GetAll',
  GETFILTRED_ATTENDEE: 'Attendee/GetAll',
  GET_REF_CODE: 'Attendee/GetRefCode',
  SEND_REF_CODE: 'Attendee/SendRefCodeEmail',
  SEARCH_ATTENDEE: 'Attendee/GetAttendeeBySearchKeyword',
  GetNotesByMeetingAttendee: 'Attendee/GetNotesByMeetingAttendee',
};
const attendeeFreeze = {
  CREATE_ATTENDEEFREEZE: 'AttendeeFreeze/Create',
  UPDATE_ATTENDEEFREEZE: 'AttendeeFreeze/Update',
  REMOVE_ATTENDEEFREEZE: 'AttendeeFreeze/Delete',
  GETBYID_ATTENDEEFREEZE: 'AttendeeFreeze/GetById',
  GETALL_ATTENDEEFREEZE: 'AttendeeFreeze/GetAll',
};
const branchFreeze = {
  CREATE_BRANCHFREEZE: 'LocationFreeze/Create',
  UPDATE_BRANCHFREEZE: 'LocationFreeze/Update',
  REMOVE_BRANCHFREEZE: 'LocationFreeze/Delete',
  GETBYID_BRANCHFREEZE: 'LocationFreeze/GetById',
  GETALL_BRANCHFREEZE: 'LocationFreeze/GetAll',
};
const manager = {
  CREATE_MANAGER: 'Manager/Create',
  UPDATE_MANAGER: 'Manager/Update',
  REMOVE_MANAGER: 'Manager/Delete',
  GETBYID_MANAGER: 'Manager/GetById',
  GETALL_MANAGER: 'Manager/GetAll',
};
const location = {
  CREATE_LOCATION: 'Location/Create',
  UPDATE_LOCATION: 'Location/Update',
  REMOVE_LOCATION: 'Location/Delete',
  GETBYID_LOCATION: 'Location/GetById',
  GETALL_LOCATION: 'Location/GetAll',
  GET_LOCATION_BY_USER_AREA: 'Location/GetLocationsByUserArea',
};
const ageInterval = {
  CREATE_AGEINTERVAL: 'AgeInterval/Create',
  UPDATE_AGEINTERVAL: 'AgeInterval/Update',
  REMOVE_AGEINTERVAL: 'AgeInterval/Delete',
  GETBYID_AGEINTERVAL: 'AgeInterval/GetById',
  GETALL_AGEINTERVAL: 'AgeInterval/GetAll',
};
const timesPerMonth = {
  CREATE_TIMESPERMONTH: 'TimesPerMonth/Create',
  UPDATE_TIMESPERMONTH: 'TimesPerMonth/Update',
  REMOVE_TIMESPERMONTH: 'TimesPerMonth/Delete',
  GETBYID_TIMESPERMONTH: 'TimesPerMonth/GetById',
  GETALL_TIMESPERMONTH: 'TimesPerMonth/GetAll',
};
const sessionCategory = {
  CREATE_SESSIONCATEGORY: 'SessionCategory/Create',
  UPDATE_SESSIONCATEGORY: 'SessionCategory/Update',
  REMOVE_SESSIONCATEGORY: 'SessionCategory/Delete',
  GETBYID_SESSIONCATEGORY: 'SessionCategory/GetById',
  GETALL_SESSIONCATEGORY: 'SessionCategory/GetAll',
};
const meetingSchema = {
  CREATE_MEETINGSCHEMA: 'MeetingSchema/Create',
  UPDATE_MEETINGSCHEMA: 'MeetingSchema/Update',
  REMOVE_MEETINGSCHEMA: 'MeetingSchema/Delete',
  GETBYID_MEETINGSCHEMA: 'MeetingSchema/GetById',
  GETALL_MEETINGSCHEMA: 'MeetingSchema/GetAll',
  GET_MEETING_ATTENDEE_SCHEDULE: 'Meeting/GetMeetingAttendeeSchedule',
  GET_ALL_SESSION_COUNT: 'Meeting/GetUserAllSessionsCount',
  GET_SPEAKER_DROPDOWN_LIST: 'MeetingSchema/GetSpeakerDropdownList',
  GET_MEETING_BY_SPEAKER_MEETINGSCHEMA: 'Meeting/GetTodaysMeetingBySpeaker',
  GetMeetingSpeakerSchedule: 'Meeting/GetMeetingSpeakerSchedule',
  GET_MEETING_ATTENDEES: 'Meeting/GetMeetingAttendees',
  ATTENDANCE_CHECK: 'Meeting/AttendanceCheck',
};
const order = {
  CREATE_ORDER: 'Order/Create',
  UPDATE_ORDER: 'Order/Update',
  UPDATE_ORDER_ITEM: 'Order/UpdateOrderItem',
  REMOVE_ORDER_ITEM: 'Order/DeleteOrderItem',
  REMOVE_ORDER: 'Order/Delete',
  GETBYID_ORDER: 'Order/GetById',
  GETALL_ORDER: 'Order/GetAll',
  EFT_CONTROL: 'Order/TotalAmountForEFTControl',
  EFT_UPDATE: 'Order/EditPaymentStatus',
  GET_MYSUBSCRIPTION_PERIOD: 'Order/GetMySubscriptionPeriod',
  GET_ORDER_HISTORY: 'Order/GetOrderHistory',
  GET_ORDERS: 'Order/GetOrders',
  GET_BASKET_ITEMS: 'Order/BasketItems',
  SET_DATE_ORDER_ITEM: 'Order/SetDate',
  GET_EARNED_FREESESSIONS: 'Order/GetEarnedFreeSessions',
  GET_GIFT_SESSIONS: 'Order/GetGiftSessions',
  SEND_GIFT_SESSION_TO_FRIEND: 'Order/SendGiftSessionToFriend',
  REEDEM_GIFT_CODE: 'Order/RedeemGiftCode',
  CANCEL_GIFT: 'Order/CancelGift',
  SELECT_SESSION: 'Order/SelectSession',
  ORDER_SET_DATE: 'Order/SetDate',
  UNSET_DATE: 'Order/UnSetDate',
  GetMeetingsByOrder: 'Order/GetMeetingsByOrder',
  Payments: 'Order/Payments',
  GetOrderDetails: 'Order/GetOrderDetails',
  GetOrderItemDetails: 'Order/GetOrderItemDetails',
  OrderItemUpdate: 'Order/UpdateOrderItem',
  TodaysTrialList: 'Order/TodaysTrialList',
  UpcomingTrialList: 'Order/UpcomingTrialList',
  RenewOrderItemCalculation: 'Order/RenewOrderItemCalculation',
  RenewOrderItem: 'Order/RenewOrderItem',
  CalculationRemainingSessionFee: 'Order/CalculationRemainingSessionFee',
  GetOrdersByAttendeeId: 'Order/GetOrdersByAttendeeId',
};
const becomeAPartner = {
  CREATE_BECOMEAPARTNER: 'BecomeAPartner/Create',
  UPDATE_BECOMEAPARTNER: 'BecomeAPartner/Update',
  REMOVE_BECOMEAPARTNER: 'BecomeAPartner/Delete',
  GETBYID_BECOMEAPARTNER: 'BecomeAPartner/GetById',
  GETALL_BECOMEAPARTNER: 'BecomeAPartner/GetAll',
};

const banner = {
  Create: 'Banner/Create',
  Update: 'Banner/Update',
  Remove: 'Banner/Delete',
  GetById: 'Banner/GetById',
  GetAll: 'Banner/GetAll',
  GetActiveBannerByZone: 'Banner/GetActiveBannerByZone',
};

const price = {
  PromoCodeCalculate: 'Price/PromoCodeCalculate',
  PRICE_CALCULATE: 'Price/PackageCalculate',
};

const calendar = {
  GetCalendarData: 'Calendar/GetCalendarData',
  GetAllCalendarData: 'Calendar/GetAllCalendarData',
  GetAllCalendarDataWithFilter: 'Calendar/GetAllCalendarDataWithFilter',
  GetAllSpeakersWithFilter: 'Calendar/GetAllSpeakersWithFilter',
  GetSelectedSpeakerCalenderData: 'Calendar/GetSelectedSpeakerCalenderData',
  GetCellDetails: 'Calendar/GetCellDetails',
  GetAllCalendarDataWithFilterBO: 'Calendar/GetAllCalendarDataWithFilterBO',
  GetCellDetailsBO: 'Calendar/GetCellDetailsBO',
  GetMeetingAttendees: 'Calendar/GetMeetingAttendees',
};
const meetingRedate = {
  Recompense: 'MeetingRedate/Recompense',
  SetDate: 'MeetingRedate/SetDate',
  UnRecompense: 'MeetingRedate/UnRecompense',
  RecompenseMeeting: 'MeetingRedate/RecompenseMeeting',
  CancelMeeting: 'MeetingRedate/CancelMeeting',
};
const speakerAvailability = {
  CREATE_AVAILABILITY: 'SpeakerAvailability/Create',
  UPDATE_AVAILABILITY: 'SpeakerAvailability/Update',
  REMOVE_AVAILABILITY: 'SpeakerAvailability/Delete',
  GETALL_AVAILABILITY: 'SpeakerAvailability/GetAll',
  GET_SELECTED_AVAILABILITY: 'SpeakerAvailability/GetSelectedSpeakersAndDates',
};

const classroom = {
  GET_PRIVATE_ROOM_MEMBERS: 'Classroom/GetPrivateRoomMembers',
  GET_PRIVATE_MESSAGES: 'Classroom/GetPrivateMessages',
  GET_GROUP_MESSAGES: 'Classroom/GetGroupMessages',
  GET_GROUP_ROOM_MEMBERS: 'Classroom/GetGroupRoomMembers',
  COMMIT_MESSAGE: 'Classroom/CommitMessage',
  HideClassroomGroup: 'Classroom/HideClassroomGroup',
};

const preference = {
  GETBYID_PREFERENCE: 'Preference/GetById',
  GETALL_PREFERENCE: 'Preference/GetById',
  GET_LEVEL_GROUP: 'Preference/GetLevelGroup',
};
const subscription = {
  CREATE_SUBSCRIPTION: 'Subscription/LetUsCallYou',
  GET_ALL_SUBSCRIPTION: 'Subscription/GetAll',
  REMOVE_SUBSCRIPTION: 'Subscription/Delete',
};
const userOperationClaim = {
  ADD_CLAIM_TO_USER: 'UserOperationClaim/Create',
  GET_CLAIMS: 'UserOperationClaim/',
};
const feedback = {
  Create: 'Feedback/Create',
  Remove: 'Feedback/Delete',
  GetAll: 'Feedback/GetAll',
};
const speakerEffort = {
  GET_ALL_SPEAKEREFFORT: 'SpeakerEffort/GetAll',
  GET_DETAIL_SPEAKEREFFORT: 'SpeakerEffort/GetBySpeaker',
  UN_PAY_MEETING_SPEAKEREFFORT: 'SpeakerEffort/UnpayMeeting',
};
const lead = {
  Create: 'Lead/Create',
  Update: 'Lead/Update',
  GetById: 'Lead/GetById',
  GetAll: 'Lead/GetAll',
};
const company = {
  Create: 'Company/Create',
  Update: 'Company/Update',
  Remove: 'Company/Delete',
  GetById: 'Company/GetById',
  GetAll: 'Company/GetAll',
  GetCompanyAttendeesDropdown: 'Company/GetCompanyAttendeesDropdown',
  GetCompanyAttendees: 'Company/GetCompanyAttendees',
  GetCompanyManagers: 'Company/GetCompanyManagers',
  CreateCompanyOrders: 'Company/CreateCompanyOrders',
  GetCompanyOrders: 'Company/GetCompanyOrders',
  AssignCompanyOrders: 'Company/AssignCompanyOrders',
  CompanyReporting: 'Company/CompanyReporting',
};
const attendeeMarketingSource = {
  Create: 'AttendeeMarketingSource/Create',
};
const campaign = {
  Create: 'Campaign/Create',
  Update: 'Campaign/Update',
  Remove: 'Campaign/Delete',
  GetById: 'Campaign/GetById',
  GetAll: 'Campaign/GetAll',
  GetActiveCampaigns: 'Campaign/GetActiveCampaigns',
  GetSelectedCampaignDetails: 'Campaign/GetSelectedCampaignDetails',
};
const reporting = {
  SelectedDateBeforeCreatedAndCompletedOrdersAttendeeDetails:
    'Reporting/SelectedDateBeforeCreatedAndCompletedOrdersAttendeeDetails',
};

const endPoint = {
  auth,
  companyPriceRequest,
  career,
  faq,
  faqGroup,
  payment,
  googleAuth,
  facebookAuth,
  user,
  staticData,
  speaker,
  couponCodes,
  timesPerWeek,
  sessionPackage,
  zone,
  attendee,
  attendeeFreeze,
  branchFreeze,
  manager,
  location,
  ageInterval,
  timesPerMonth,
  sessionCategory,
  meetingSchema,
  order,
  becomeAPartner,
  banner,
  price,
  calendar,
  meetingRedate,
  speakerAvailability,
  classroom,
  preference,
  subscription,
  userOperationClaim,
  feedback,
  speakerEffort,
  lead,
  company,
  attendeeMarketingSource,
  campaign,
  reporting,
};

export default endPoint;
