import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import addDay from '../../../hooks/useAddDay';
import { Entities } from '../../../utils/Enum';
import SearchButton from '../../../components/Common/Button/SearchButton';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => navigate(-1);
  const meetingList = useSelector((state) => state.order.getMeetingsByOrder);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const staticDatas = useSelector((state) => state.staticData);
  const meetingStatus = staticDatas.MeetingAttendeeStatus;
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    getList();
  }, [mountStatus]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      id: location.state?.id,
    });
    setMountStatus(false);
  }, []);

  const getList = (func = false) => {
    if (Object.keys(filterData).length > 0 && mountStatus !== null) {
      const action = Actions.orderActions.getMeetingsByOrderAction(filterData);
      setShowLoader(true);
      if (dateChanged && func) {
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      setDateChanged(true);
      return rest;
    });
  };

  const cardHeader = [
    { key: 'attendeeName', label: t('common.name') },
    { key: 'phoneNumber', label: t('common.phone') },
  ];

  const headers = [
    { key: 'id', label: t('common.meetingAttendeeId') },
    { key: 'date', label: t('meeting.table_meetingDate') },
    { key: 'interval', label: t('meeting.table_interval') },
    { key: 'orderItemId', label: t('common.orderItemId') },
    { key: 'meetingStatus', label: t('meeting.table_status') },
    { key: 'trialStatus', label: t('orderItem.trialStatus') },
    { key: 'speaker', label: t('meeting.table_speaker') },
    { key: 'level', label: t('meeting.table_level') },
    { key: 'location', label: t('meeting.table_location') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('meeting.meeting_list')} strong />
      </div>
      <div className="row">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="align-items-center justify-content-start ms-sm-2">
                  <div className="col-12 col-md-1 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <SearchButton
                    stateName={'attendee'}
                    size={ButtonSize.Small}
                    bold
                    onClick={onSearch}
                    filterChanged={dateChanged}
                    mountingStatus={mountStatus}
                  />
                </div>
                <div className="row my-2">
                  <div className="col-12 col-md-3">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-12 col-md-3 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      isfilter
                      placeHolder={t('dropdownSelect.select_meetingStatus')}
                      options={meetingStatus}
                      selectedProps={{ key: 'key', value: 'value' }}
                      onChange={(value) => handleDropdown('SType', value)}
                      onReset={() => handleResetDropdown('sType')}
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <Card
            body={
              <div>
                <div className="col-12 col-md-3">
                  <Card
                    body={
                      <React.Fragment>
                        {cardHeader.map((val, i) => (
                          <div
                            key={i}
                            className={`d-flex justify-content-between border-bottom py-1 ${
                              i % 2 ? 'bg-light' : ''
                            }`}
                          >
                            <div className="px-1 fw-bold">{val.label}</div>
                            <div className="px-1">{meetingList[val.key]}</div>
                          </div>
                        ))}
                      </React.Fragment>
                    }
                  />
                </div>
                <Table
                  // searchAble
                  data={meetingList?.meetingList ?? []}
                  headers={headers}
                  showLoading={showLoader}
                  striped
                  bordered
                  page={Entities.meeting}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.meeting.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.meeting.getAll,
    content,
    Claims.actionClaims.companyMeeting
  );
};
export default List;
