import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/Common/Card/Card';
import PieChart from '../../../components/Common/Charts/PieChart';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { useLocation } from 'react-router-dom';

const Reporting = ({ currentUserCompanyId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.company.companyReporting);
  const companyAttendees = useSelector(
    (state) => state.company.getCompanyAttendeesDropdown
  );
  const companyId =
    parseInt(location.search.split('?id=')[1]) || currentUserCompanyId;
  const [plannedCompletedChartData, setPlannedCompletedChartData] =
    useState(null);
  const [successfulUnattendedChartData, setSuccessfulUnattendedChartData] =
    useState(null);
  const [selectedAttendee, setSelectedAttendee] = useState(null);

  const chartLabels = [
    t('chartLabels.totalSessions'),
    t('chartLabels.planned'),
    t('chartLabels.totalCompleted'),
    t('chartLabels.successfullyCompleted'),
    t('chartLabels.unattended'),
    t('chartLabels.recompensed'),
    t('chartLabels.redated'),
  ];

  const chartColors = [
    '#ecf0f1',
    'rgba(75,192,192,1)',
    '#1f77b4',
    '#4cbc9a',
    '#d62728',
    '#9467bd',
    '#e377c2',
  ];

  const defaultBorderColor = 'black';
  const defaultBorderWidth = 2;

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(Actions.companyActions.companyReportingAction(companyId));
      dispatch(
        Actions.companyActions.getCompanyAttendeesDropdownAction(companyId)
      );
    }

    return () => {
      dispatch(Actions.companyActions.cleanState());
    };
  }, [companyId]);

  useEffect(() => {
    if (data) {
      const totalRecompensedData =
        data.totalRecompensed - data.totalRedated || 0;
      setPlannedCompletedChartData({
        labels: [
          t('chartLabels.planned'),
          t('chartLabels.totalCompleted'),
          t('chartLabels.recompensed'),
        ],
        datasets: [
          {
            label: t('company.overview'),
            data: [
              data.totalPlanned || 0,
              data.totalCompleted || 0,
              totalRecompensedData,
            ],
            backgroundColor: [chartColors[1], chartColors[2], chartColors[4]],
            borderColor: defaultBorderColor,
            borderWidth: defaultBorderWidth,
          },
        ],
        text: `${t('chartLabels.totalSessions')}:${data.totalMeeting}`,
      });

      setSuccessfulUnattendedChartData({
        labels: [
          t('chartLabels.successfullyCompleted'),
          t('chartLabels.unattended'),
        ],
        datasets: [
          {
            label: t('company.overview'),
            data: [
              data.totalSuccessfullyComplete || 0,
              data.totalUnattendeed || 0,
            ],
            backgroundColor: [chartColors[3], chartColors[4]],
            borderColor: defaultBorderColor,
            borderWidth: defaultBorderWidth,
          },
        ],
        text: `${t('chartLabels.totalCompleted')}:${data.totalCompleted}`,
      });
    }
  }, [data]);
  
  const handleDropdown = (value) => {
    const attendee = data.attendeeDetails.find(
      (attendee) => attendee.attendeeId === value
    );
    const totalRecompensedData =
      attendee.totalRecompensed - attendee.totalRedated || 0;
    const firstChart = {
      labels: [
        t('chartLabels.planned'),
        t('chartLabels.totalCompleted'),
        t('chartLabels.recompensed'),
      ],
      datasets: [
        {
          label: t('company.overview'),
          data: [
            attendee.totalPlanned || 0,
            attendee.totalCompleted || 0,
            totalRecompensedData,
          ],
          backgroundColor: [chartColors[1], chartColors[2], chartColors[4]],
          borderColor: defaultBorderColor,
          borderWidth: defaultBorderWidth,
        },
      ],
      text: `${t('chartLabels.totalSessions')}: ${
        attendee.totalMeeting || 0
      }`,
    };
    const secondChart = {
      labels: [
        t('chartLabels.successfullyCompleted'),
        t('chartLabels.unattended'),
      ],
      datasets: [
        {
          label: t('company.overview'),
          data: [
            attendee.totalSuccessfullyComplete || 0,
            attendee.totalUnattendeed || 0,
          ],
          backgroundColor: [chartColors[3], chartColors[4]],
          borderColor: defaultBorderColor,
          borderWidth: defaultBorderWidth,
        },
      ],
      text: `${t('chartLabels.totalCompleted')}: ${attendee.totalCompleted || 0}`,
    };
    setSelectedAttendee({ firstChart: firstChart, secondChart: secondChart });
  };

  const handleResetDropdown = () => {
    setSelectedAttendee(null);
  };

  const areAllValuesZero = (data) => {
    return data.every((value) => value === 0);
  };

  const renderPieChart = (chartData, message, marginTop) => {
    if (chartData && !areAllValuesZero(chartData.datasets[0].data)) {
      return <PieChart chartData={chartData} marginTop={marginTop} />;
    }
    return <p className="mt-5 d-flex justify-content-center">{message}</p>;
  };

  return (
    <div className="container-fluid">
      <Card
        body={
          <React.Fragment>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center mb-4 form-div-border">
              <HeadTags hSize={6} strong text={t('company.reporting')} />
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-around">
              <div className="d-flex flex-column col-12 col-md-4">
                <div className="d-flex justify-content-center form-div-border mb-2">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('company.reportingGeneral')}
                  />
                </div>
                {renderPieChart(plannedCompletedChartData, t('company.noData'))}
                {renderPieChart(
                  successfulUnattendedChartData,
                  t('company.noData'),
                  5
                )}
              </div>
              <div className="col-12 col-md-3 mt-2 mt-md-0">
                <DropdownWithSearch
                  isSearchable={true}
                  id="companyAttendees"
                  placeHolder={t('company.companyAttendees')}
                  options={companyAttendees}
                  selectedProps={{ key: 'attendeeName', value: 'attendeeId' }}
                  onChange={handleDropdown}
                  onReset={handleResetDropdown}
                />
                <div className="d-flex flex-column mt-1 mt-md-5 ms-2">
                  <span>
                    <strong className="me-2">
                      {t('company.totalAttendee')}:
                    </strong>
                    {data?.totalAttendee}
                  </span>
                  <span>
                    <strong className="me-2">{t('company.totalOrder')}:</strong>
                    {data?.totalOrder}
                  </span>
                  <span>
                    <strong className="me-2">
                      {t('company.totalSetDatedOrders')}:
                    </strong>
                    {data?.totalSetDatedOrders}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-4 mt-2 mt-md-0">
                <div className="d-flex justify-content-center form-div-border mb-2">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('company.reportingIndividual')}
                  />
                </div>
                {renderPieChart(
                  selectedAttendee?.firstChart,
                  t(
                    selectedAttendee === null
                      ? 'company.selectAttendee'
                      : 'company.noData'
                  )
                )}
                {renderPieChart(selectedAttendee?.secondChart, undefined, 5)}
              </div>
            </div>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default Reporting;
