import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import Card from '../../../../components/Common/Card/Card';
import Button from '../../../../components/Common/Button/Button';
import {
  ButtonType,
  TextColors,
  InputType,
  ButtonIcon,
} from '../../../../utils/ComponentEnums';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';

const Create = () => {
  const { t } = useTranslation();
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const ageInterval = useSelector((state) => state.ageInterval.ageInterval);
  const isSuccess = useSelector((state) => state.ageInterval.isSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [createData, setCreateData] = useState(ageInterval);
  const redirect = '/age-interval-list';

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.ageIntervalActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.ageIntervalActions.createAction(createData));
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(redirect);
      dispatch(Actions.ageIntervalActions.handleError(''));
    }
  }, [isSuccess]);

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-5">
        <div className="col-11">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                  <div className="col-2 mt-sm-3">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={3}
                    />
                  </div>
                </div>
                <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('ageInterval.adding_new_age_interval_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'minAge'}
                      inputType={InputType.Number}
                      labelText={t('ageInterval.minimum_age')}
                      inputValue={createData.minAge || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6">
                    <InputLabelOnBorder
                      id={'maxAge'}
                      inputType={InputType.Number}
                      labelText={t('ageInterval.maximum_age')}
                      inputValue={createData.maxAge || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'ageSessionValue'}
                      inputType={InputType.Number}
                      labelText={t('ageInterval.session_value')}
                      inputValue={createData.ageSessionValue || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <InputLabelOnBorder
                      id={'ageMentorValue'}
                      inputType={InputType.Number}
                      labelText={t('ageInterval.mentor_value')}
                      inputValue={createData.ageMentorValue || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type={
                        Object.keys(createData).length > 3
                          ? ButtonType.Successbg
                          : ButtonType.Secondary
                      }
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      onClick={onCreate}
                      buttonCol={2}
                      disabled={
                        Object.keys(createData).length > 3 ? false : true
                      }
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.ageInterval.create,
    content
  );
};
export default Create;
