import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import Actions from '../../../store/redux/actions';
import learningPurposeSVG from '../../../assets/images/learning_purpose.svg';
import typeOfServiceSVG from '../../../assets/images/typeofservice.svg';
import sessionLevelSVG from '../../../assets/images/session_level.svg';
import dateSVG from '../../../assets/images/date.svg';
import servicesSVG from '../../../assets/images/services.svg';
import VideoModal from '../../../components/Common/Modal/VideoModal';
import Button from '../../../components/Common/Button/Button';
import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import Input from '../../../components/Common/Input/Input';
import Card from '../../../components/Common/Card/Card';
import truncateString from '../../../hooks/useTruncateString';
import {
  TextColors,
  ButtonIcon,
  InputType,
  ButtonType,
  ButtonSize,
  LabelTextType,
  LabelFontSize,
  BackGroundColors,
} from '../../../utils/ComponentEnums';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Faq from '../Faq';
import Pagination from '../../../components/Common/Paginate/Pagination';
import CalendarDropdown from '../../../components/Common/Dropdown/CalendarDropdown';
import meet2talkButton from '../../../assets/images/meet2talk_button.svg';
import LetUsCallYou from './MainSubPages/LetUsCallYou';
import { serviceOptions } from '../../../utils/Enum';
import findMyPath from '../../../hooks/useFindMyPath';
import online from '../../../assets/images/meet2talk_button.svg';
import kids from '../../../assets/images/kids.svg';
import cafe from '../../../assets/images/cafe.svg';
import company from '../../../assets/images/marketing.svg';
import Label from '../../../components/Common/TextLabel/Label';

const Main = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const staticDatas = useSelector((state) => state.staticData);
  const speakers = useSelector((state) => state.speaker.filteredSpeakers);
  const locations = useSelector((state) => state.location.branches);
  const totalItem = useSelector((state) => state.speaker.pagination.TotalCount);
  const holdFilter = useSelector((state) => state.holdFilter.filter);
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const learningPurpose = staticDatas.LearningPurpose;
  const typeOfService = staticDatas.TypeOfService;
  const native = staticDatas.Native;
  const pageSize = 8;
  const pageNumber = 1;

  const [showLoader, setShowLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [filter, setFilter] = useState({
    LearningPurpose: holdFilter?.LearningPurpose?.map((item) => item) || null,
    LanguageLevels: holdFilter?.LanguageLevels || null,
    TypeOfService:
      holdFilter?.UserArea === 1
        ? [1]
        : holdFilter?.TypeOfService?.map((item) => item) || null,
    Native: holdFilter?.Native?.map((item) => item) || null,
    FullName: holdFilter?.FullName || null,
    UserArea: holdFilter?.UserArea || 0,
    Locations:
      holdFilter?.UserArea !== 1
        ? [1]
        : holdFilter?.Locations?.map((item) => item) || null,
    SelectedIds: holdFilter?.SelectedIds || null,
    PackageId: holdFilter?.PackageId || null,
    TrialStatus: holdFilter?.TrialStatus || 2,
    CompanyId: 1,
    AgeId: holdFilter?.AgeId || holdFilter?.UserArea !== 2 ? 4 : null,
    DateDetails: {
      dayOfWeek: holdFilter?.DateDetails?.dayOfWeek || null,
      timeInterval: holdFilter?.DateDetails?.timeInterval || null,
      selectedDate: holdFilter?.DateDetails?.selectedDate || null,
      selectedTime: holdFilter?.DateDetails?.selectedTime || null,
    },
  });
  const isChild = filter.LanguageLevels?.includes('Kids') ?? false;
  const isOnline = filter?.UserArea === 0;
  const isCafe = filter?.UserArea === 1;
  const isKids = filter?.UserArea === 2;

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(!isMobile);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const combinedArray = [
    ...native.map((item) => ({
      key: item.key,
      name: item.name,
      value: item.value,
      group: item.name,
    })),
    ...learningPurpose.map((item) => ({
      key: item.key,
      name: item.name,
      value: item.value,
      group: item.name,
    })),
  ];

  const stopPropagation = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageClick(value);
  };

  const handleSelectChange = (target, isMulti = false, takeKey = false) => {
    let name = target.name;
    let value = target.value;
    const optionValue = takeKey ? target.key : parseInt(value);

    if (isMulti) {
      const updatedArray = [...(filter[name] || [])];
      const valueIndex = updatedArray.indexOf(optionValue);

      if (valueIndex !== -1) {
        updatedArray.splice(valueIndex, 1);
      } else {
        updatedArray.push(optionValue);
      }
      setFilter({
        ...filter,
        [name]: updatedArray.length > 0 ? updatedArray : null,
      });
      setApiCall(true);
    }
    if (!isMulti) {
      if (name === 'UserArea') {
        if (filter[name] === optionValue) {
          setFilter({
            ...filter,
            [name]: 0,
            Locations: [1],
            TypeOfService: null,
          });
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
            Locations: optionValue === 0 || optionValue === 2 ? [1] : null,
            TypeOfService: optionValue === 0 || optionValue === 2 ? null : [1],
          });
        }
      } else {
        if (filter[name] === optionValue) {
          setFilter({
            ...filter,
            [name]: null,
          });
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
          });
        }
        setApiCall(true);
      }
    }
  };
  const [apiCall, setApiCall] = useState(false);
  useEffect(() => {
    if (filter.UserArea !== undefined) {
      const updatedFilter = { ...filter };

      if (isKids) {
        if (!isChild) {
          updatedFilter.LanguageLevels = null;
        }
        updatedFilter.AgeId = null;
      }
      if (!isKids) {
        if (isChild) {
          updatedFilter.LanguageLevels = null;
        }
        updatedFilter.AgeId = 4;
      }
      setFilter(updatedFilter);
    }
    setApiCall(true);
    if (isKids && ageInterval.length === 0) {
      dispatch(Actions.ageIntervalActions.getAllAction(2));
    }
    if (isCafe && locations.length === 0) {
      dispatch(
        Actions.locationActions.getLocationsByUserAreaAction({
          userArea: 1,
        })
      );
    }
  }, [filter?.UserArea]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      const { value } = e.target;
      const newValue = value.trim() === '' ? null : value;
      const updatedFilter = { ...filter, FullName: newValue };
      setFilter(updatedFilter);
      dispatch(
        Actions.speakerActions.getFilteredAction(
          pageNumber,
          pageSize,
          updatedFilter
        )
      ).then(() => setShowLoader(false));
    }
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const newValue = value.trim() === '' ? null : value;
    setFilter({ ...filter, FullName: newValue });
  };
  const onSearchClick = (e) => {
    setShowLoader(true);
    e.preventDefault();
    const inputValue = document.getElementById('FullName').value;
    const newValue = inputValue.trim() === '' ? null : inputValue;
    const updatedFilter = { ...filter, FullName: newValue };
    setFilter(updatedFilter);
    dispatch(
      Actions.speakerActions.getFilteredAction(
        pageNumber,
        pageSize,
        updatedFilter
      )
    ).then(() => setShowLoader(false));
  };

  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  function extractVideoId(url) {
    const patterns = [
      /(?:youtube\.com\/embed\/|youtu.be\/|youtube.com\/watch\?v=)([\w-]{11})/,
      /^([\w-]{11})$/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match) {
        return match[1];
      }
    }

    return null;
  }

  const handleImageClick = (url) => {
    url = extractVideoId(url);
    if (url) {
      setVideoModalVisible(true);
      setVideoUrl(url);
    }
  };
  const closeVideoModal = () => {
    setVideoModalVisible(false);
  };

  const handleDateTime = (id, date) => {
    const updatedFilter = { ...filter };
    const today = new Date();
    const dateOnly = today.toISOString().split('T')[0];
    setShowLoader(true);

    if (id === 'dayOfWeek') {
      updatedFilter.DateDetails.selectedDate = null;
      if (updatedFilter.DateDetails.dayOfWeek === null) {
        if (updatedFilter.DateDetails.timeInterval !== null) {
          timeIntervalSet(date, true);
        }
        if (updatedFilter.DateDetails.selectedTime !== null) {
          selectedTimeSet(date, true);
        }
        updatedFilter.DateDetails.dayOfWeek = [`${date}T00:00:00.000Z`];
      } else {
        const selectedDate = `${date}T00:00:00.000Z`;
        const index = updatedFilter.DateDetails.dayOfWeek.indexOf(selectedDate);
        if (index === -1) {
          updatedFilter.DateDetails.dayOfWeek.push(`${date}T00:00:00.000Z`);
          if (updatedFilter.DateDetails.timeInterval !== null) {
            timeIntervalSet(date, false);
          }
          if (updatedFilter.DateDetails.selectedTime !== null) {
            selectedTimeSet(date, false);
          }
        } else {
          updatedFilter.DateDetails.dayOfWeek.splice(index, 1);
          if (updatedFilter.DateDetails.dayOfWeek.length === 0) {
            updatedFilter.DateDetails.dayOfWeek = null;
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(dateOnly, undefined, 'dayOfWeek');
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(dateOnly, undefined, 'dayOfWeek');
            }
          } else {
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(date, null);
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(date, null);
            }
          }
        }
      }
    }
    function timeIntervalSet(value, check) {
      updatedFilter.DateDetails.selectedTime = null;
      const dateTimePairs = updatedFilter.DateDetails.timeInterval.map(
        (interval) => {
          const [startDateTime, endDateTime] = interval.split('Z-');
          return [startDateTime, endDateTime];
        }
      );
      const updatedDateTimePairs = dateTimePairs.map((pair) => {
        const startHour = pair[0].split('T')[1].split(':')[0];
        const endHour = pair[1].split('T')[1].split(':')[0];
        const selectedDate = value;
        return `${selectedDate}T${startHour}:00:00.000Z-${selectedDate}T${endHour}:00:00.000Z`;
      });

      if (updatedFilter.DateDetails.timeInterval.length > 0) {
        const mergedTimeInterval = [
          ...updatedFilter.DateDetails.timeInterval,
          ...updatedDateTimePairs,
        ];
        let filteredDates = [];
        const checkStatus =
          (updatedFilter.DateDetails.dayOfWeek === null ||
            updatedFilter.DateDetails.selectedDate === null) &&
          !check;
        if (checkStatus) {
          filteredDates = updatedDateTimePairs.filter((date) =>
            date.includes(value)
          );
        }

        updatedFilter.DateDetails.timeInterval = check
          ? updatedDateTimePairs
          : checkStatus
          ? filteredDates
          : mergedTimeInterval;
      } else {
        updatedFilter.DateDetails.timeInterval = updatedDateTimePairs;
      }

      return updatedFilter.DateDetails.timeInterval;
    }
    function selectedTimeSet(value, check, parameter) {
      updatedFilter.DateDetails.timeInterval = null;
      const checkStatusEmpty =
        updatedFilter.DateDetails[parameter] === null && check === undefined;
      const checkStatusRemoved =
        updatedFilter.DateDetails[parameter] !== null && check === null;
      const hours = updatedFilter.DateDetails.selectedTime.map((interval) => {
        const [hour] = interval.split('T')[1].split(':');
        return [hour];
      });
      let updatedHours = [];
      if (!checkStatusRemoved) {
        updatedHours = hours.map((item) => {
          const selectedDate = value;
          return `${selectedDate}T${item}:00:00.000Z`;
        });
      }

      if (updatedFilter.DateDetails.selectedTime.length > 0) {
        const mergedTimeInterval = [
          ...new Set([
            ...updatedFilter.DateDetails.selectedTime,
            ...updatedHours,
          ]),
        ];
        let filteredDates = [];

        if (checkStatusEmpty) {
          filteredDates = updatedHours.filter((date) => date.includes(value));
        }
        if (checkStatusRemoved) {
          filteredDates = mergedTimeInterval.filter(
            (date) => !date.includes(value)
          );
        }

        updatedFilter.DateDetails.selectedTime = check
          ? updatedHours
          : checkStatusEmpty || checkStatusRemoved
          ? filteredDates
          : mergedTimeInterval;
      } else {
        updatedFilter.DateDetails.selectedTime = updatedHours;
      }

      return updatedFilter.DateDetails.selectedTime;
    }
    if (id === 'timeInterval') {
      updatedFilter.DateDetails.selectedTime = null;
      const cleanedDate = date.replace(/\s+/g, '');
      if (
        filter.DateDetails.dayOfWeek !== null ||
        filter.DateDetails.selectedDate !== null
      ) {
        updatedFilter.DateDetails.timeInterval =
          updatedFilter.DateDetails.timeInterval || [];

        const datesToProcess = [
          ...(filter.DateDetails.dayOfWeek || []),
          ...(filter.DateDetails.selectedDate || []),
        ];
        datesToProcess.forEach((dates) => {
          const selectedDate = dates.split('T')[0];
          updatedFilter.DateDetails.timeInterval.push(
            `${selectedDate}T${
              cleanedDate.split('-')[0]
            }:00:00.000Z-${selectedDate}T${
              cleanedDate.split('-')[1]
            }:00:00.000Z`
          );
        });
      } else if (filter.DateDetails.timeInterval === null) {
        updatedFilter.DateDetails.timeInterval = [
          `${dateOnly}T${cleanedDate.split('-')[0]}:00:00.000Z-${dateOnly}T${
            cleanedDate.split('-')[1]
          }:00:00.000Z`,
        ];
      } else if (filter.DateDetails.timeInterval !== null) {
        const newTimeSlot = `${dateOnly}T${
          cleanedDate.split('-')[0]
        }:00:00.000Z-${dateOnly}T${cleanedDate.split('-')[1]}:00:00.000Z`;
        const index =
          updatedFilter.DateDetails.timeInterval.indexOf(newTimeSlot);
        if (index === -1) {
          updatedFilter.DateDetails.timeInterval.push(newTimeSlot);
        } else {
          updatedFilter.DateDetails.timeInterval.splice(index, 1);
          if (updatedFilter.DateDetails.timeInterval.length === 0) {
            updatedFilter.DateDetails.timeInterval = null;
          }
        }
      }
    }
    if (id === 'selectedDate') {
      updatedFilter.DateDetails.dayOfWeek = null;
      if (updatedFilter.DateDetails.selectedDate === null) {
        if (updatedFilter.DateDetails.timeInterval !== null) {
          timeIntervalSet(date, true);
        }
        if (updatedFilter.DateDetails.selectedTime !== null) {
          selectedTimeSet(date, true);
        }
        updatedFilter.DateDetails.selectedDate = [`${date}T00:00:00.000Z`];
      } else {
        const selectedDate = `${date}T00:00:00.000Z`;
        const index =
          updatedFilter.DateDetails.selectedDate.indexOf(selectedDate);
        if (index === -1) {
          updatedFilter.DateDetails.selectedDate.push(`${date}T00:00:00.000Z`);
          if (updatedFilter.DateDetails.timeInterval !== null) {
            timeIntervalSet(date, false);
          }
          if (updatedFilter.DateDetails.selectedTime !== null) {
            selectedTimeSet(date, false);
          }
        } else {
          updatedFilter.DateDetails.selectedDate.splice(index, 1);
          if (updatedFilter.DateDetails.selectedDate.length === 0) {
            updatedFilter.DateDetails.selectedDate = null;
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(dateOnly, undefined, 'selectedDate');
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(dateOnly, undefined, 'selectedDate');
            }
          } else {
            if (updatedFilter.DateDetails.timeInterval !== null) {
              timeIntervalSet(date, null);
            }
            if (updatedFilter.DateDetails.selectedTime !== null) {
              selectedTimeSet(date, null);
            }
          }
        }
      }
    }
    if (id === 'selectedTime') {
      updatedFilter.DateDetails.timeInterval = null;
      if (
        filter.DateDetails.dayOfWeek !== null ||
        filter.DateDetails.selectedDate !== null
      ) {
        updatedFilter.DateDetails.selectedTime =
          updatedFilter.DateDetails.selectedTime || [];

        const datesToProcess = [
          ...(filter.DateDetails.dayOfWeek || []),
          ...(filter.DateDetails.selectedDate || []),
        ];
        datesToProcess.forEach((dates) => {
          const selectedDate = dates.split('T')[0];
          updatedFilter.DateDetails.selectedTime.push(
            `${selectedDate}T${date}:00.000Z`
          );
        });
      } else if (filter.DateDetails.selectedTime === null) {
        updatedFilter.DateDetails.selectedTime = [
          `${dateOnly}T${date}:00.000Z`,
        ];
      } else if (filter.DateDetails.selectedTime !== null) {
        const newTimeSlot = `${dateOnly}T${date}:00.000Z`;
        const index =
          updatedFilter.DateDetails.selectedTime.indexOf(newTimeSlot);
        if (index === -1) {
          updatedFilter.DateDetails.selectedTime.push(
            `${dateOnly}T${date}:00.000Z`
          );
        } else {
          updatedFilter.DateDetails.selectedTime.splice(index, 1);
          if (updatedFilter.DateDetails.selectedTime.length === 0) {
            updatedFilter.DateDetails.selectedTime = null;
          }
        }
      }
    }
    setFilter(updatedFilter);
  };

  function removeHTMLTags(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  function handleNavigateCard(e, to, state) {
    e.preventDefault();
    dispatch(Actions.calendarActions.getFilteredReducer([]));
    return navigate(to, state);
  }
  const onboardingData = [
    {
      type: 'Online',
      labelValue: 'Online',
      imgSrc: online,
      selfUpdateActionIndex: 0,
      to: '/online',
    },
    {
      type: 'Cafe',
      labelValue: 'Cafe',
      imgSrc: cafe,
      selfUpdateActionIndex: 1,
      to: '/cafe',
    },
    {
      type: 'Kids',
      labelValue: 'Kids',
      imgSrc: kids,
      selfUpdateActionIndex: 2,
      to: '/kids',
    },
    {
      type: 'Company',
      labelValue: 'Company',
      imgSrc: company,
      selfUpdateActionIndex: 3,
      to: '/company',
    },
  ];

  const handleClick = (link, area = undefined) => {
    navigate(link);
    if (area !== undefined) {
      dispatch(Actions.onboardActions.onboardType(area));
    }
  };
  useEffect(() => {
    dispatch(Actions.onboardActions.onboardType(0));
  }, []);

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    if (isKids) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (!isKids) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    }
  }

  useEffect(() => {
    if (
      filter.TypeOfService !== undefined &&
      filter.Locations !== undefined &&
      filter.LearningPurpose !== undefined &&
      filter.Native !== undefined
    ) {
      const filterObject = {
        LearningPurpose: filter.LearningPurpose,
        LanguageLevels: filter.LanguageLevels,
        TypeOfService: filter.TypeOfService,
        Native: filter.Native,
        UserArea: filter.UserArea,
        TrialStatus: filter.TrialStatus,
        FullName: filter.FullName,
        Locations: filter.Locations,
        SelectedIds: filter.SelectedIds,
        PackageId: filter.PackageId,
        CompanyId: 1,
        AgeId: filter.AgeId,
        DateDetails: filter.DateDetails,
      };
      dispatch(Actions.filterActions.holdFilter(filterObject));
    }
  }, [filter]);

  useEffect(() => {
    if (apiCall && !showLoader) {
      setApiCall(false);
      setShowLoader(true);
      dispatch(
        Actions.speakerActions.getFilteredAction(pageNumber, pageSize, filter)
      ).then(() => {
        setShowLoader(false);
      });
    }
  }, [apiCall]);

  function setAreaName() {
    let txt = '';
    switch (filter?.UserArea) {
      case 0:
        txt = 'UserArea.0';
        break;
      case 1:
        txt = 'UserArea.1';
        break;
      case 2:
        txt = 'UserArea.2';
        break;
      case 3:
        txt = 'UserArea.3';
        break;

      default:
        txt = 'landing.filter_session_type';
        break;
    }
    return txt;
  }

  const content = (
    <div className="container-fluid align-items-center">
      <div className="banner bg-light rounded-3 py-md-5">
        <div className="container flex-md-column-reverse">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 mt-md-5 mt-3 order-md-1 order-2">
              <p className="pb-md-4 pb-4 pt-3 text-start fw-bold display-md-4 display-5">
                {t('landingMainBanner.everyone_understands_english')}
                <span className="text-custom ">
                  {t('landingMainBanner.you_speak')}
                </span>
              </p>
              {/* <h5>
          {t(
            'landingMainBanner.join_the_free_trial_session_now_learn_your_english_level_and_meet_the_meet2Talk_experience'
          )}
        </h5> */}
              <ul
                className="mx-auto py-md-2 mx-2 banner-custom-list"
                style={{ listStyleType: 'none' }}
              >
                <li className="   py-md-1">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="custom-checkmark-icon text-custom mx-2 fa-2xl"
                  />
                  {t(
                    'landingMainBanner.mentors_whose_native_language_is_english'
                  )}
                </li>
                <li className="py-md-1">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="custom-checkmark-icon text-custom mx-2 fa-2xl"
                  />
                  {t('landingMainBanner.fixed_group_sessions_fixed_mentors')}
                </li>
                <li className="py-md-1">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="custom-checkmark-icon text-custom mx-2 fa-2xl"
                  />
                  {t(
                    'landingMainBanner.personal_advisor_who_tracks_your_progress'
                  )}
                </li>
              </ul>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start py-4">
                <div className="col-12 col-md-5 mb-3 mb-md-0 me-md-5">
                  <Button
                    text={t('landingMainBanner.get_trial')}
                    textColor="text-white"
                    type={ButtonType.LandingGreenBg}
                    border
                    onClick={() =>
                      navigate('/order/triallesson', {
                        state: {
                          fromNavigateWhere: 'freeTrial',
                          trialStatus: 0,
                        },
                      })
                    }
                  />
                </div>
                <div className="col-12 col-md-5 ms-md-5">
                  <Button
                    text={t('landingMainBanner.buy_package')}
                    textColor="text-landing-green"
                    border
                    borderColor
                    onClick={() => {
                      navigate('/order/package', { state: { trialStatus: 2 } });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 mt-2 order-md-2 order-1">
              <div className="ratio ratio-custom">
                <iframe
                  className="embed-responsive-item rounded-4"
                  src="https://www.youtube.com/embed/xPkiZQ0POP0?autoplay=1&mute=1&playlist=xPkiZQ0POP0&loop=1"
                  title="Meet2Talk"
                  allowFullScreen
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  style={{ boxShadow: ' 9px 9px #00c905' }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services p-lg-5 py-2 text-center">
        <div className="container">
          <h1 className="text-black mb-lg-5 py-2 fw-bold">
            {t('MainPart4PresentationHeader.Header')}
          </h1>
          <div className="row mt-5">
            {onboardingData?.map((item) => (
              <div
                className="col-6 col-xl-3 mb-5 d-flex flex-column"
                key={item.to}
              >
                <Link
                  to={item.to}
                  onClick={() =>
                    handleClick(item.to, item.selfUpdateActionIndex)
                  }
                  className="d-flex justify-content-center align-items-start"
                >
                  <Card
                    cardStyle={{ maxWidth: '135px', maxHeight: '115px' }}
                    imgSrc={{
                      src: item.imgSrc,
                      round: 'img-fluid',
                      alt: item.imgSrc,
                      maxWidth: '90px',
                      maxHeight: '70px',
                    }}
                    backGround={BackGroundColors[item?.type]}
                  />
                </Link>
                <Label
                  fontType={LabelTextType.Bold}
                  fontSize={LabelFontSize.fs5}
                  value={item.labelValue}
                  textColor={TextColors[item?.type]}
                />
                <p className="text-mute mx-sm-5 my-1 flex-grow-1">
                  {t(
                    `MainPart4PresentationHeader${item.labelValue}.Description`
                  )}
                </p>
                <Button
                  text={t('MainPart4PresentationHeader.More')}
                  textColor={TextColors.Dark}
                  bold
                  onClick={() =>
                    handleClick(item.to, item.selfUpdateActionIndex)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mentors bg-light">
        <div className="container">
          <div className="row py-3 justify-content-center">
            <div className="col-md-12 col-lg-9 offset-lg-1 mx-md-5 pt-2 text-center">
              <h2 className="fw-bold">
                {t(
                  'MainPart1Header.Learn_English_with_the_instructor_of_your_choice'
                )}
              </h2>
              <p className="pt-3 font-weight-light">
                {t(
                  'MainPart1Header.Have_fun_by_practicing_your_English_speaking_or_improving_your_English_grammar'
                )}
              </p>
            </div>

          </div>
        </div>
      </div>
      {/* Section3 */}
      <div className="our_story py-lg-5 py-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-5 mt-lg-5 mt-2">
              <div className="ratio ratio-custom ">
                <iframe
                  className="embed-responsive-item rounded-4"
                  src="https://www.youtube.com/embed/YQcWBGE3aCI"
                  title="Meet2Talk"
                  allowFullScreen
                  loading="lazy"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  style={{ boxShadow: '-9px 9px 0 0 #00c905 ' }}
                ></iframe>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 mt-3 ms-4">
              <h2 className="display-4 py-2 fw-bold  text-start">
                {t('MainPart2OurStory.Our_Story')}
              </h2>
              <span className="text-muted">
                {t('MainPart2OurStory.Our_Story_Description')}
              </span>
            </div>
          </div>
        </div>
      </div>
      .{/* Section4 */}
      <div className="partners bg-light">
        <div className="container col-lg-12">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="cards-wrapper d-flex align-items-center ">
                  <div className="carousel-card m-2 ">
                    <div
                      className="image-wrapper d-flex align-items-center h-100"
                      style={{ maxWidth: '300px' }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/9/9e/FORTUNE-LOGO-2016.png"
                        alt="..."
                        className="img-fluid px-2"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="carousel-card m-2">
                    <div
                      className="image-wrapper d-flex align-items-center h-100 "
                      style={{ maxWidth: '300px' }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Bloomberg_logo.svg/2560px-Bloomberg_logo.svg.png"
                        alt="..."
                        className="img-fluid px-2"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="carousel-card m-2">
                    <div className="image-wrapper d-flex align-items-center h-100">
                      <img
                        src="https://onedio.com/images/logo/onedio-new2x-new.png"
                        alt="..."
                        className="img-fluid px-2"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="carousel-card m-2">
                    <div className="image-wrapper d-flex align-items-center h-100">
                      <img
                        src="https://seeklogo.com/images/H/harvard-business-review-logo-FD07ED9958-seeklogo.com.png"
                        alt="..."
                        className="img-fluid px-2"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="comments py-3 text-center">
        <div className="container">
          <div className="mb-lg-5">
            <h2 className="text-black m-3 fw-bold">
              {t('MainPart5Testimonial.Header')}
            </h2>
            <span className="text-muted m-3 fs-6 text-wrap">
              {t('MainPart5Testimonial.Description')}
            </span>
          </div>

          <div
            id="carouselExampleDark"
            className="carousel carousel-dark slide"
            data-bs-ride="false"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4 ">
                    <div className="card border">
                      <div className="card-body">
                        <p className="card-title fs-6">İrem K.</p>
                      </div>
                      <div className="ratio ratio-4x3">
                        <iframe
                          className="embed-responsive-item rounded-4 py-2 px-4"
                          src="https://www.youtube.com/embed/5bHAIApa1Do"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4">
                    <div className="card border">
                      <div className="card-body">
                        <p className="card-title fs-6">Gülru</p>
                      </div>
                      <div className="ratio ratio-4x3">
                        <iframe
                          className="embed-responsive-item rounded-4 py-2 px-4"
                          src="https://www.youtube.com/embed/HPFJ9bD93mE"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4">
                    <div className="card border bg-transparent">
                      <div className="card-body">
                        <p className="card-title fs-6">Fatih</p>
                      </div>
                      <div className="ratio ratio-4x3">
                        <iframe
                          className="embed-responsive-item rounded-4 py-2 px-4"
                          src="https://www.youtube.com/embed/XBj8ujsPTcs"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4">
                    <div className="card border bg-transparent">
                      <div className="card-body">
                        <p className="card-title fs-6">Tuğçe</p>
                      </div>
                      <div className="ratio ratio-4x3">
                        <iframe
                          className="embed-responsive-item rounded-4 py-2 px-4"
                          src="https://www.youtube.com/embed/8pqsMA833WU"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4">
                    <div className="card border bg-transparent">
                      <div className="card-body">
                        <p className="card-title fs-6">Cansu</p>
                      </div>
                      <div className="ratio ratio-4x3">
                        <iframe
                          className="embed-responsive-item rounded-4 py-2 px-4"
                          src="https://www.youtube.com/embed/sqQgV5xLuek"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-4">
                    <div className="card border bg-transparent">
                      <div className="card-body">
                        <p className="card-title fs-6">Oğulcan</p>
                      </div>
                      <div className="ratio ratio-4x3">
                        <iframe
                          className="embed-responsive-item rounded-4 py-2 px-4"
                          src="https://www.youtube.com/embed/YP0vJJfPes0"
                          title="Meet2Talk"
                          allowFullScreen
                          loading="lazy"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
      <div className="subscribe-area bg-lightcustom py-lg-5 py-sm-3">
        <div className="container">
          <div className="row p-4 rounded-4">
            <div className="col-md-9">
              <div className="subscribe-text mb-15">
                <h2>{t('MainPart6NewsTeller.Header')}</h2>
                <span>{t('MainPart6NewsTeller.Description')}</span>
              </div>
            </div>
            <div className="col-md-3 d-flex justify-content-md-end justify-content-center align-items-center mt-3 mt-md-0">
              <div className="subscribe-form">
                <button
                  className="col mx-2 btn btn-outline-default btn-sm px-4 text-landing-green rounded-pill"
                  style={{ borderColor: '#00c905' }}
                >
                  <Link
                    className="nav-link px-2 text-nowrap"
                    to="/account/register"
                  >
                    {t('MainPart6NewsTeller.Start_Now')}
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetUsCallYou image area={0} location={'Online'} />
      {/* <div className="faq-area py-4">
        <Faq />
      </div> */}
    </div>
  );
  return useLoader() ? (
    <Loader />
  ) : auth === '' ? (
    content
  ) : (
    <Navigate to={findMyPath('dashboard')} replace />
  );
};
export default Main;
