import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import Card from '../../../components/Common/Card/Card';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import { MonthOptions, SessionCapacity, UserArea } from '../../../utils/Enum';
import checkClaims from '../../../hooks/useCheckClaims';
import addDay from '../../../hooks/useAddDay';

const Update = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const couponCodeId = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const couponCode = useSelector((state) => state.couponCode.couponCode);
  const locationList = useSelector((state) => state.location.getAll);
  const redirect = '/coupon-code-list';
  const [updateData, setUpdateData] = useState({
    details: [
      {
        month: 1,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
      {
        month: 3,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
      {
        month: 6,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
      {
        month: 12,
        capacity: [],
        userArea: [],
        location: [],
        discount: 0,
        isPercent: true,
      },
    ],
    hasSub: true,
    isPercent: true,
  });
  const [dataChanged, setDataChanged] = useState(true);

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.couponCodesActions.cleanState(true));
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setUpdateData({ ...updateData, [id]: value === '' ? undefined : value });
    setDataChanged(false);
  };

  const onChangeDetails = (index, e) => {
    const { id, value } = e.target;
    const updatedDetails = [...updateData.details];
    updatedDetails[index][id] = value === '' ? 0 : parseInt(value);
    setUpdateData({ ...updateData, details: updatedDetails });
    setDataChanged(false);
  };

  const onSave = async (e) => {
    e.preventDefault();
    const filteredDetails = updateData.details.filter(
      (detail) => detail.discount !== 0
    );
    const filteredData = {
      ...updateData,
      details: filteredDetails,
    };
    dispatch(Actions.couponCodesActions.updateAction(filteredData));
  };

  function getDatas() {
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.couponCodesActions.getByIdAction(couponCodeId));
  }

  useEffect(() => {
    getDatas();
    return () => {
      dispatch(Actions.couponCodesActions.cleanState(true));
    };
  }, []);

  useEffect(() => {
    if (Object.keys(couponCode).length > 0) {
      const localStartDate = convertToLocalDate(couponCode.startTime);
      const yearStart = localStartDate.getFullYear();
      const monthStart = ('0' + (localStartDate.getMonth() + 1)).slice(-2);
      const dayStart = ('0' + localStartDate.getDate()).slice(-2);
      const startDateView = `${yearStart}-${monthStart}-${dayStart}`;
      const localEndDate = convertToLocalDate(couponCode.endTime);
      const yearEnd = localEndDate.getFullYear();
      const monthEnd = ('0' + (localEndDate.getMonth() + 1)).slice(-2);
      const dayEnd = ('0' + localEndDate.getDate()).slice(-2);
      const endDateView = `${yearEnd}-${monthEnd}-${dayEnd}`;

      const merged = [...updateData.details];

      couponCode.details.forEach((newDetail) => {
        const existingIndex = merged.findIndex(
          (detail) => detail.month === newDetail.month
        );

        if (existingIndex !== -1) {
          merged[existingIndex] = { ...merged[existingIndex], ...newDetail };
        } else {
          merged.push(newDetail);
        }
      });

      setUpdateData({
        ...updateData,
        ...couponCode,
        details: merged,
        startTimeView: startDateView,
        endTimeView: endDateView,
      });
    }
  }, [couponCode]);

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setUpdateData({
        ...updateData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
      setDataChanged(false);
    } else {
      setUpdateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDataChanged(false);
    }
  };

  const convertToLocalDate = (dateString) => {
    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date - userTimezoneOffset);
  };

  const handleDropdown = (monthIndex, field, value) => {
    const currentDetails = [...updateData.details];
    const currentDetail = currentDetails[monthIndex];

    let updatedArray = [...(currentDetail[field] || [])];
    const valueIndex = updatedArray.indexOf(value);

    if (valueIndex !== -1) {
      updatedArray.splice(valueIndex, 1);
    } else {
      updatedArray.push(value);
    }
    currentDetails[monthIndex] = {
      ...currentDetail,
      [field]: updatedArray,
    };
    setUpdateData({
      ...updateData,
      details: currentDetails,
    });
    setDataChanged(false);
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mt-sm-3">
                  <div className="col-12 col-md-1">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('couponCodes.update_coupon_code_form')}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={updateData.name}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-0 mt-1">
                    <InputLabelOnBorder
                      id={'codes'}
                      inputType={InputType.Text}
                      labelText={t('couponCodes.codes')}
                      inputValue={updateData.codes}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-4 mt-11">
                    <InputLabelOnBorder
                      id={'startTime'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={updateData.startTimeView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-4 mt-1">
                    <InputLabelOnBorder
                      id={'endTime'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={updateData.endTimeView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  {updateData?.details?.map((detail, index) => (
                    <div
                      key={`${detail.month}-${index}`}
                      className="d-flex flex-column flex-sm-row align-items-center mt-md-4"
                    >
                      <div className="col-12 col-md-1 me-md-1 mt-md-0 mt-1">
                        <InputLabelOnBorder
                          id={`details[${index}].month`}
                          labelText={t('couponCodes.month')}
                          inputValue={
                            MonthOptions.filter(
                              (val) => val?.key === detail?.month
                            )?.[0]?.key
                          }
                          disabled={true}
                        />
                      </div>
                      <div className="col-12 col-md-2 me-md-1 mt-md-1 mt-2">
                        <DropdownWithSearch
                          id={`details[${index}].capacity`}
                          options={SessionCapacity}
                          selectedProps={{
                            key: 'capacity',
                            value: 'capacity',
                          }}
                          placeHolder={t('dropdownSelect.select_capacity')}
                          selectedValues={detail.capacity}
                          onChange={(value) =>
                            handleDropdown(index, 'capacity', value)
                          }
                          isfilter={false}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12 col-md-3 me-md-1 mt-md-1 mt-2">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_user_area')}
                          options={UserArea}
                          selectedProps={{ key: 'key', value: 'value' }}
                          selectedValues={detail.userArea.map(
                            (val) => `${val}`
                          )}
                          onChange={(value) =>
                            handleDropdown(index, 'userArea', value)
                          }
                          isfilter={false}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12 col-md-5 me-md-1 mt-md-1 mt-2">
                        <DropdownWithSearch
                          isSearchable
                          placeHolder={t('dropdownSelect.select_location')}
                          options={locationList}
                          selectedProps={{ key: 'locationName', value: 'id' }}
                          selectedValues={detail.location}
                          onChange={(value) =>
                            handleDropdown(index, 'location', value)
                          }
                          isfilter={false}
                          isMulti={true}
                        />
                      </div>
                      <div className="col-12 col-md-1">
                        <InputLabelOnBorder
                          key={`details[${index}].discount`}
                          id={'discount'}
                          inputType={InputType.Number}
                          labelText={t('couponCodes.discount')}
                          inputValue={detail.discount}
                          minNumber={1}
                          maxNumber={100}
                          onChange={(e) => onChangeDetails(index, e)}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'couponCode'}
                        onClick={onSave}
                        objectLengthRule={dataChanged}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'couponCode'}
        onClose={() => {
          setUpdateData({});
          dispatch(Actions.couponCodesActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.couponCodes.update,
    content
  );
};
export default Update;
