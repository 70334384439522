import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import { useNavigate } from 'react-router-dom';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { Entities } from '../../../utils/Enum';

const BackOfficeBranchHolidayList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const branchFreezeList = useSelector((state) => state.branchFreeze.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);

  const getList = async () => {
    await dispatch(Actions.branchFreezeActions.getAllAction());
  };

  useEffect(() => {
    getList();
  }, []);
  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'reason', label: 'Reason' },
    { key: 'locationName', label: 'Location' },
    { key: 'startDate', label: 'StartDate' },
    { key: 'endDate', label: 'EndDate' },
    { key: 'status', label: 'IsCompleted' },
  ];

  const editAction = (val) => {
    navigate('/branch-holiday-update', { state: { id: val.id } });
  };
  const removeAction = async (id) => {
    await dispatch(Actions.branchFreezeActions.removeAction(id));
    getList();
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h1 className="d-flex mb-0 text-gray-800">Branch Holiday List</h1>
      </div>
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.branchFreeze.create
      ) ? (
        <div className="row mb-3">
          <div className="d-flex justify-content-end">
            <Button
              type={ButtonType.Successbg}
              text={'Freeze New Branch'}
              textColor={TextColors.Light}
              buttonCol={3}
              onClick={() => navigate('/branch-holiday-create')}
            />
          </div>
        </div>
      ) : undefined}

      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  data={branchFreezeList}
                  headers={headers}
                  page={Entities.branchFreeze}
                  striped
                  bordered
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  idSearchOnly={true}
                />
              </div>
            }
          ></Card>
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.branchFreeze.getAll,
    content
  );
};
export default BackOfficeBranchHolidayList;
