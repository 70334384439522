import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import Card from '../../../../components/Common/Card/Card';
import { InputType } from '../../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../store/redux/actions';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import { useLocation, useNavigate } from 'react-router-dom';
import { TimesPerWeek } from '../../../../utils/Enum';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import SubmitButton from '../../../../components/Common/Button/SubmitButton';
import Toast from '../../../../components/Common/Popup/Toast';

const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const companyId = location?.state?.id;
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const sessionPackageList = useSelector((state) => state.sessionPackage.getAll);
  const [createData, setCreateData] = useState({
    companyId: companyId,
    companyPackageOptions: [
      {
        id: 1,
        userArea: 3,
        location: '',
        sessionPackage: '',
        timesPerWeek: '',
        sessionQuantity: '',
        orderQuantity: '',
        productType: '',
        userAreaString: undefined,
      },
    ],
  });
  const redirect = '/company-list';

  useEffect(() => {
    dispatch(Actions.locationActions.getAllAction());
    dispatch(Actions.sessionPackageActions.getAllAction());
    return () => {
      dispatch(Actions.companyActions.cleanState());
    };
  }, []);

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.companyActions.cleanState());
  };

  const onChangeDetails = (index, field, value) => {
    const updatedDetails = [...createData.companyPackageOptions];
    updatedDetails[index][field] = value;
    setCreateData({ ...createData, companyPackageOptions: updatedDetails });
  };

  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(
      Actions.companyActions.createCompanyOrdersAction(createData)
    );
  };

  const handleDropdown = (index, propName, value) => {
    const updatedDetails = [...createData.companyPackageOptions];
    updatedDetails[index][propName] = value;
    if (propName === 'sessionPackage') {
      updatedDetails[index]['productType'] =
        sessionPackageList.filter((item) => item.id === value)[0].person > 1
          ? 0
          : 1;
    }

    setCreateData({ ...createData, companyPackageOptions: updatedDetails });
  };

  const addDetailRow = () => {
    setCreateData((prevData) => {
      const newId = prevData.companyPackageOptions.length + 1;
      return {
        ...prevData,
        companyPackageOptions: [
          ...prevData.companyPackageOptions,
          {
            id: newId,
            userArea: 3,
            location: '',
            sessionPackage: '',
            timesPerWeek: '',
            sessionQuantity: '',
            orderQuantity: '',
            productType: '',
            userAreaString: undefined,
          },
        ],
      };
    });
  };

  const deleteDetailRow = (index) => {
    const updatedDetails = [...createData.companyPackageOptions];
    updatedDetails.splice(index, 1);
    setCreateData({ ...createData, companyPackageOptions: updatedDetails });
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mt-sm-3 mt-0">
                  <div className="col-12 col-md-1">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags
                    hSize={5}
                    strong
                    text={t('company.companyOrderCreateFrom')}
                  />
                </div>
                <div className="row">
                  {createData?.companyPackageOptions?.map((detail, index) => (
                    <React.Fragment key={index}>
                      <div className="d-md-flex align-items-center justify-content-md-evenly mt-md-4">
                        <div className="col-12 col-md-2 me-0 me-md-1">
                          <DropdownWithSearch
                            id={`details[${index}].location`}
                            placeHolder={t('dropdownSelect.select_location')}
                            options={locationList.filter(
                              (location) =>
                                location.locationName === 'Online' ||
                                location.locationName === 'Company' ||
                                location.locationName === 'CompanyOnline'
                            )}
                            selectedProps={{ key: 'locationName', value: 'id' }}
                            onChange={(value) =>
                              handleDropdown(index, 'location', parseInt(value))
                            }
                          />
                        </div>
                        <div className="col-12 col-md-2 mt-2 mt-md-0">
                          <DropdownWithSearch
                            id={`details[${index}].sessionPackage`}
                            placeHolder={t(
                              'dropdownSelect.select_session_package'
                            )}
                            options={sessionPackageList.filter((item) =>
                              createData?.companyPackageOptions[index]
                                ?.location === 1 ||
                              createData?.companyPackageOptions[index]
                                ?.location === 9
                                ? !item.displayName
                                    .toLowerCase()
                                    .includes('cafe') &&
                                  !item.displayName
                                    .toLowerCase()
                                    .includes('kids')
                                : !item.displayName
                                    .toLowerCase()
                                    .includes('online') &&
                                  !item.displayName
                                    .toLowerCase()
                                    .includes('kids')
                            )}
                            selectedProps={{
                              key: 'person',
                              supKey: 'minute',
                              value: 'id',
                              display: 'displayName',
                            }}
                            onChange={(value) =>
                              handleDropdown(index, 'sessionPackage', value)
                            }
                          />
                        </div>
                        <div className="col-12 col-md-2 mt-2 mt-md-0">
                          <DropdownWithSearch
                            id={`details[${index}].timesPerWeek`}
                            placeHolder={t('giftOrder.timesPerWeek')}
                            options={TimesPerWeek}
                            selectedProps={{ key: 'perWeek', value: 'perWeek' }}
                            onChange={(value) =>
                              handleDropdown(index, 'timesPerWeek', value)
                            }
                          />
                        </div>
                        <div className="col-12 col-md-1 mt-2 mt-md-0">
                          <InputLabelOnBorder
                            id={`details[${index}].sessionQuantity`}
                            inputType={InputType.Number}
                            labelText={t('company.sessionQuantity')}
                            inputValue={detail.sessionQuantity}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'sessionQuantity',
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-md-1 mt-2 mt-md-0">
                          <InputLabelOnBorder
                            id={`details[${index}].orderQuantity`}
                            inputType={InputType.Number}
                            labelText={t('company.orderQuantity')}
                            inputValue={detail.orderQuantity}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'orderQuantity',
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-md-2 mt-2 mt-md-0">
                          <InputLabelOnBorder
                            id={`details[${index}].netAmount`}
                            inputType={InputType.Number}
                            labelText={t('company.netAmount')}
                            inputValue={detail.netAmount}
                            onChange={(e) =>
                              onChangeDetails(
                                index,
                                'netAmount',
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-md-1 mt-2 mt-md-0">
                          <Button
                            buttonCol={12}
                            type={ButtonType.Danger}
                            size={ButtonSize.Small}
                            icon={ButtonIcon.TrashCan}
                            onClick={() => deleteDetailRow(index)}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="col-12 col-md-3 mt-3">
                    <Button
                      type={ButtonType.Primary}
                      buttonCol={12}
                      text={t('company.addMoreOrderType')}
                      onClick={addDetailRow}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'company'}
                        onClick={onCreate}
                        objectLengthRule={
                          Object.keys(createData).length >= 2 ? false : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'company'}
        onClose={() => {
          dispatch(Actions.companyActions.cleanState(true));
          setCreateData({});
          navigate(redirect);
        }}
      />
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.company.create,
    content
  );
};
export default Create;
