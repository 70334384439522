import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HeadTags from '../../components/Common/HeadTags/HeadTags';
import Card from '../../components/Common/Card/Card';
import Table from '../../components/Common/Table/Table';
import DropdownWithSearch from '../../components/Common/Dropdown/DropdownWithSearch';
import InputLabelOnBorder from '../../components/Common/Input/InputLabelOnBorder';
import Toast from '../../components/Common/Popup/Toast';
import SearchButton from '../../components/Common/Button/SearchButton';
import { Entities, LeadStatus, UserArea } from '../../utils/Enum';
import { ButtonSize, InputType } from '../../utils/ComponentEnums';
import Actions from '../../store/redux/actions';
import Claims from '../../utils/Claims';
import addDay from '../../hooks/useAddDay';
import checkClaims from '../../hooks/useCheckClaims';

const CallThem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const callList = useSelector((state) => state.subscription.getAll);
  const managerList = useSelector((state) => state.manager.getAll);
  const locationList = useSelector((state) => state.location.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const isAdmin = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.manager.create
  );

  useEffect(() => {
    getList();
  }, [mountStatus]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      startDate: null,
      endDate: null,
      startDateView: null,
      endDateView: null,
    });
    setMountStatus(false);
  }, []);

  const getList = (func = false) => {
    if (
      checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.mailSubscription.getAll
      ) &&
      Object.keys(filterData).length > 0 &&
      mountStatus !== null
    ) {
      const action = Actions.subscriptionActions.getAllAction(filterData);
      setShowLoader(true);
      if (dateChanged && func) {
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const removeAction = (id) => {
    dispatch(Actions.subscriptionActions.removeAction(id));
  };

  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;

      setDateChanged(true);
      return rest;
    });
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const headers = [
    { key: 'id', label: t('mailSubscription.table_id') },
    { key: 'name', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
    { key: 'note', label: t('mailSubscription.table_note') },
    { key: 'area', label: t('mailSubscription.table_area') },
    { key: 'location', label: t('mailSubscription.table_location') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'deletedBy', label: t('common.deleted_by') },
    { key: 'deletedAt', label: t('common.deleted_at') },
  ];

  useEffect(() => {
    if (managerList.length === 0) {
      dispatch(Actions.managerActions.getAllAction('Dropdown'));
    }
    if (locationList.length === 0) {
      dispatch(Actions.locationActions.getAllAction());
    }
    return () => {
      dispatch(Actions.subscriptionActions.cleanState());
    };
  }, []);

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('mailSubscription.header_perm')} strong />
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-between mb-4 form-div-border">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('cardHeaderFilter.filter')}
                  />
                </div>
                <div className="d-sm-flex align-items-center justify-content-end">
                  <SearchButton
                    stateName={'subscription'}
                    size={ButtonSize.Small}
                    bold
                    onClick={onSearch}
                    filterChanged={dateChanged}
                    mountingStatus={mountStatus}
                  />
                </div>
                <div className="row my-2">
                  <div className="col-lg-3 mt-lg-2 mt-0">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_manager')}
                      options={managerList}
                      selectedProps={{
                        key: 'managerName',
                        value: 'userId',
                      }}
                      onChange={(value) => handleDropdown('sType', value)}
                      onReset={() => handleResetDropdown('sType')}
                      isfilter
                    />
                  </div>
                  <div className="col-lg-3 mt-lg-2 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_location')}
                      options={locationList}
                      selectedProps={{
                        key: 'locationName',
                        value: 'locationName',
                      }}
                      onChange={(value) => handleDropdown('sType2', value)}
                      onReset={() => handleResetDropdown('sType2')}
                      isfilter
                    />
                  </div>
                  <div className="col-lg-3 mt-md-0 mt-1">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={filterData.startDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="col-lg-3 mt-md-0 mt-1">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={filterData.endDateView || ''}
                      onChange={onChangeDate}
                    />
                  </div>
                  {isAdmin && (
                    <div className="col-lg-3 mt-lg-4 mt-2">
                      <DropdownWithSearch
                        isSearchable
                        placeHolder={t('dropdownSelect.select_activity_status')}
                        options={LeadStatus}
                        selectedProps={{
                          key: 'key',
                          value: 'value',
                        }}
                        onChange={(value) =>
                          handleDropdown('sType3', parseInt(value))
                        }
                        onReset={() => handleResetDropdown('sType3')}
                        isfilter
                      />
                    </div>
                  )}
                  <div className="col-lg-3 mt-lg-4 mt-2">
                    <DropdownWithSearch
                      isSearchable
                      placeHolder={t('dropdownSelect.select_user_area')}
                      options={UserArea}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      onChange={(value) =>
                        handleDropdown('sType4', parseInt(value))
                      }
                      onReset={() => handleResetDropdown('sType4')}
                      isfilter
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          />
          <Card
            body={
              <Table
                // searchAble
                data={callList}
                headers={headers}
                showLoading={showLoader}
                striped
                bordered
                page={Entities.mailSubscription}
                remove={(val) => removeAction(val)}
                currentUserClaim={currentUserClaim}
                exportName={Entities.mailSubscription.exportName}
              />
            }
          />
        </div>
      </div>
      <Toast
        key={'subscription'}
        id={'subscription'}
        stateName={'subscription'}
        onClose={() => {
          dispatch(Actions.subscriptionActions.cleanState());
          dispatch(Actions.subscriptionActions.getAllAction(filterData));
        }}
      />
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.mailSubscription.getAll,
    content
  );
};
export default CallThem;
