import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const PieChart = ({ chartData, marginTop }) => {
  return (
    <div
      className={`d-flex flex-column justify-content-center${
        marginTop !== undefined ? ` mt-${marginTop}` : ''
      }`}
    >
      {chartData?.text && (
        <span className="text-center">
          <strong className="me-2">{chartData.text.split(':')[0]}:</strong>
          {chartData.text.split(':')[1]}
        </span>
      )}
      <Pie data={chartData} />
    </div>
  );
};

export default PieChart;
