import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import Actions from '../../../store/redux/actions';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import DropdownWithSearch from '../../../components/Common/Dropdown/DropdownWithSearch';
import addDay from '../../../hooks/useAddDay';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import Toast from '../../../components/Common/Popup/Toast';
import SearchButton from '../../../components/Common/Button/SearchButton';
import { Entities } from '../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const speakerList = useSelector((state) => state.speaker.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const locationList = useSelector((state) => state.location.getAll);
  const staticDatas = useSelector((state) => state.staticData);
  const serviceType = staticDatas.UserArea;
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [mountStatus, setMountStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    getList();
  }, [mountStatus]);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(null, true);

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
    setMountStatus(false);
  }, []);

  const getList = (func = false) => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.speaker.getAll) &&
      Object.keys(filterData).length > 0 &&
      mountStatus !== null
    ) {
      const action = Actions.speakerActions.getAllAction(filterData);
      setShowLoader(true);
      if (dateChanged && func) {
        dispatch(action).then(() => setShowLoader(false));
        setDateChanged(!dateChanged);
      } else if (dateChanged === false) {
        dispatch(action).then(() => setShowLoader(false));
      }
    }
  };

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Enter') {
        getList(true);
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [filterData]);

  const editAction = (val) => {
    navigate('/speaker-update', { state: { id: val.id } });
  };
  const availabilityAction = (id) => {
    navigate('/availability-calendar', { state: { id: id } });
  };
  const removeAction = (id) => {
    dispatch(Actions.speakerActions.removeAction(id));
  };
  const setDefaultPasswordAction = (val) => {
    dispatch(Actions.userActions.setDefaultPasswordAction(val.userId));
  };

  const onSearch = (e) => {
    e.preventDefault();
    getList(true);
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (value !== '') {
      resetMe(id, type === 'number' ? parseInt(value) : value);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        setDateChanged(true);
        return rest;
      });
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    if (!dateChanged && key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        startDate: null,
        startDateView: null,
        [key]: value,
      }));
      setDateChanged(true);
    }
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;

      setDateChanged(true);
      return rest;
    });
  };

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch(Actions.locationActions.getAllAction());
    }
    return () => {
      dispatch(Actions.speakerActions.cleanState(true));
    };
  }, [dispatch, locationList.length]);

  const headers = [
    { key: 'id', label: t('common.speakerId') },
    { key: 'name', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
    { key: 'image', label: t('speaker.table_image') },
    { key: 'country', label: t('speaker.table_country') },
    { key: 'urlName', label: t('speaker.table_url_name') },
    { key: 'displayOrder', label: t('speaker.table_display_order') },
    { key: 'isFullTimer', label: t('speaker.table_is_fulltimer') },
    { key: 'isActive', label: t('speaker.is_active') },
    { key: 'createdAt', label: t('common.created_at') },
    { key: 'createdBy', label: t('common.created_by') },
    { key: 'updatedAt', label: t('common.updated_at') },
    { key: 'updatedBy', label: t('common.updated_by') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-2">
        <HeadTags hSize={1} text={t('speaker.speaker_list')} strong />
      </div>
      <div className="col">
        <Card
          body={
            <React.Fragment>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-4 form-div-border">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('cardHeaderFilter.filter')}
                />
                <div className="col-12 col-md-2 d-flex justify-content-center mb-1">
                  {checkClaims(
                    currentUserClaim,
                    Claims.backofficeClaims.speaker.create
                  ) && (
                    <Button
                      type={ButtonType.Successbg}
                      text={t('speaker.add_new_speaker')}
                      textColor={TextColors.Light}
                      onClick={() => navigate('/speaker-create')}
                      buttonCol={12}
                    />
                  )}
                </div>
              </div>
              <div className="d-sm-flex align-items-center justify-content-end">
                <SearchButton
                  stateName={'speaker'}
                  size={ButtonSize.Small}
                  bold
                  onClick={onSearch}
                  filterChanged={dateChanged}
                  mountingStatus={mountStatus}
                />
              </div>
              <div className="row my-2">
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'name'}
                    inputType={InputType.Text}
                    labelText={t('speaker.filter_speaker_name')}
                    inputValue={filterData.name || ''}
                    onChange={onChangeText}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'startDate'}
                    inputType={InputType.Date}
                    labelText={t('common.start_date')}
                    inputValue={filterData.startDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'endDate'}
                    inputType={InputType.Date}
                    labelText={t('common.end_date')}
                    inputValue={filterData.endDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_location')}
                    options={locationList}
                    selectedProps={{ key: 'locationName', value: 'id' }}
                    onChange={(value) => handleDropdown('sType', `${value}`)}
                    onReset={() => handleResetDropdown('sType')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_user_area')}
                    options={serviceType}
                    selectedProps={{
                      key: 'key',
                      value: 'value',
                    }}
                    onChange={(value) => handleDropdown('sType2', value)}
                    onReset={() => handleResetDropdown('sType2')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-lg-4 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_native_status')}
                    options={staticDatas.Native}
                    selectedProps={{
                      key: 'key',
                      value: 'value',
                    }}
                    onChange={(value) => handleDropdown('sType3', value)}
                    onReset={() => handleResetDropdown('sType3')}
                    isfilter
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card
          body={
            <div>
              <Table
                // searchAble
                data={speakerList}
                showLoading={showLoader}
                headers={headers}
                page={Entities.speaker}
                striped
                bordered
                edit={(val) => editAction(val)}
                availability={(val) => availabilityAction(val)}
                remove={(val) => removeAction(val)}
                setDefaultPassword={(val) => setDefaultPasswordAction(val)}
                currentUserClaim={currentUserClaim}
                idSearchOnly={true}
                exportName={Entities.speaker.exportName.speaker}
              />
            </div>
          }
        />
      </div>
      <Toast
        key={'speaker'}
        id={'speaker'}
        stateName={'speaker'}
        onClose={() => {
          dispatch(Actions.speakerActions.cleanState());
          dispatch(Actions.speakerActions.getAllAction(filterData));
        }}
      />
      <Toast
        key={'user'}
        id={'user'}
        stateName={'user'}
        onClose={() => {
          dispatch(Actions.userActions.cleanState());
          dispatch(Actions.speakerActions.getAllAction(filterData));
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.speaker.getAll,
    content
  );
};
export default List;
