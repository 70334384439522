import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import SubmitButton from '../../../components/Common/Button/SubmitButton';
import Toast from '../../../components/Common/Popup/Toast';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Claims from '../../../utils/Claims';
import addDay from '../../../hooks/useAddDay';
import checkClaims from '../../../hooks/useCheckClaims';

const Create = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const banner = useSelector((state) => state.banner.banner);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const [createData, setCreateData] = useState(banner);
  const [imgDesktopSrc, setImgDesktopSrc] = useState('');
  const [imgMobileSrc, setImgMobileSrc] = useState('');
  const redirect = '/banner-list';

  const goBack = () => {
    navigate(-1);
    dispatch(Actions.bannerActions.cleanState());
  };

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
  };

  const onCreate = (e) => {
    e.preventDefault();
    dispatch(Actions.bannerActions.createAction(createData));
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      setCreateData({
        ...createData,
        [id]: normalDateTime,
        [`${id}View`]: value,
      });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
    }
  };

  const content = (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mt-0 mt-md-5">
        <div className="col-12">
          <Card
            body={
              <React.Fragment>
                <div className="d-sm-flex align-items-center justify-content-start ms-sm-2 mt-sm-3">
                  <div className="col-12 col-md-1">
                    <Button
                      type={ButtonType.Successbg}
                      icon={ButtonIcon.ArrowLeft}
                      iconColor={TextColors.Light}
                      bold
                      onClick={goBack}
                      buttonCol={12}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                  <HeadTags hSize={5} strong text={t('banner.add_new_form')} />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputLabelOnBorder
                      id={'name'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={createData.name || ''}
                      onChange={onChangeText}
                      required
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <InputLabelOnBorder
                      id={'zones'}
                      inputType={InputType.Text}
                      labelText={'Zones'}
                      inputValue={createData.zones || '1'}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      disabled
                    />
                  </div> */}
                  <div className="col-0 col-md-6"></div>
                  <div className="col-12 col-md-6 mt-md-2 mt-1">
                    <InputLabelOnBorder
                      id={'startDate'}
                      inputType={InputType.Date}
                      labelText={t('common.start_date')}
                      inputValue={createData.startDateView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-2 mt-1">
                    <InputLabelOnBorder
                      id={'endDate'}
                      inputType={InputType.Date}
                      labelText={t('common.end_date')}
                      inputValue={createData.endDateView || ''}
                      onChange={onChangeDate}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-md-2 mt-1">
                    <div className="col-12 d-flex justify-content-center">
                      {imgDesktopSrc ? (
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={imgDesktopSrc}
                          alt={imgDesktopSrc}
                          height={90}
                          style={{ maxHeight: '90px' }}
                        />
                      ) : null}
                    </div>
                    <CustomFileInput
                      id={'pictureWeb'}
                      key={'pictureWeb'}
                      hidden={true}
                      buttonText={t('fileUpload.web')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      func={(val) => {
                        setCreateData(
                          Object.assign({}, createData, {
                            pictureWeb: val.base64String,
                            extensionWeb: val.extension,
                            url: val.fileName,
                          })
                        );
                        setImgDesktopSrc(val.imgSrc);
                      }}
                    />
                  </div>
                  <div className="ccol-12 col-md-6 mt-md-2 mt-1">
                    <div className="col-12 d-flex justify-content-center">
                      {imgMobileSrc ? (
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={imgMobileSrc}
                          alt={imgMobileSrc}
                          height={90}
                          style={{ maxHeight: '90px' }}
                        />
                      ) : null}
                    </div>
                    <CustomFileInput
                      id={'pictureMobile'}
                      hidden={true}
                      buttonText={t('fileUpload.mobile')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      func={(val) => {
                        setCreateData(
                          Object.assign({}, createData, {
                            pictureMobile: val.base64String,
                            extensionMobile: val.extension,
                          })
                        );
                        setImgMobileSrc(val.imgSrc);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'banner'}
                        onClick={onCreate}
                        objectLengthRule={
                          Object.keys(createData).length > 3 ? false : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
      <Toast
        stateName={'banner'}
        onClose={() => {
          setImgDesktopSrc('');
          setImgMobileSrc('');
          setCreateData({});
          dispatch(Actions.bannerActions.cleanState());
          navigate(redirect);
        }}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.banner.create,
    content
  );
};
export default Create;
