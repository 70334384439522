import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonType,
  TextColors,
  InputType,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { useDispatch, useSelector } from 'react-redux';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Actions from '../../../store/redux/actions';
import { Loader } from '../../../components/Common/Loader/Loader';
import useLoader from '../../../hooks/useLoader';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import Input from '../../../components/Common/Input/Input';
import { ToastContainer, toast } from 'react-toastify';

export default function ForgotPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get('resetCode');
  const validationMessages = useSelector(
    (state) => state.user.validationMessage
  );
  const isSuccess = useSelector((state) => state.user.isSuccess);
  const [handleErrorMessage, setHandleErrorMessage] =
    useState(validationMessages);

  const navigate = useNavigate();
  const [matchErr, setMatchErr] = useState(null);
  const [error, setError] = useState({});
  const [notifyMessage, setNotifyMesage] = useState('');
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    return () => {
      dispatch(Actions.userActions.cleanState(true));
    };
  }, []);

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    setUpdateData({ ...updateData, [id]: value });
    const validationResult = regexValidator(type, value);
    if (!validationResult & (value !== '')) {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };

  const onFocus = (e) => {
    e.preventDefault();
    setHandleErrorMessage(null);
  };
  const onBlur = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value === '') {
      setUpdateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }

    (updateData.password !== updateData.confirmPassword) &
    (updateData.confirmPassword !== '') &
    (error.confirmPassword === undefined)
      ? setMatchErr(
          <span className="text-danger">
            {' '}
            {t('validationMessages.passwords_must_match')}
          </span>
        )
      : setMatchErr(null);
  };
  const onForgotMyPassword = async (e) => {
    e.preventDefault();
    if (updateData.email !== undefined) {
      await dispatch(Actions.userActions.forgotPasswordAction(updateData));
    } else if (
      updateData.email === undefined &&
      updateData.password === undefined
    ) {
      setHandleErrorMessage(
        <span className="text-danger">
          {t('validationMessages.please_fill_the_required_inputs')}
        </span>
      );
    }
  };

  const onChangePassword = async (e) => {
    e.preventDefault();
    if (updateData.password === updateData.confirmPassword) {
      await dispatch(Actions.userActions.resetPasswordAction(updateData));
    }
  };

  useEffect(() => {
    setHandleErrorMessage(validationMessages);
  }, [validationMessages]);

  useEffect(() => {
    if (isSuccess && updateData.email !== undefined) {
      setUpdateData({});
      setHandleErrorMessage(null);
    }
    if (
      isSuccess &&
      updateData.password === updateData.confirmPassword &&
      updateData.email === undefined
    ) {
      setUpdateData({});
      setNotifyMesage(
        <span>
          <strong>{t('changePassword.success')}</strong>
        </span>
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (key !== null) {
      setUpdateData({ ...updateData, code: key });
    }
  }, [key]);
  const notify = () => {
    toast.info(notifyMessage, {
      onClose: () => {
        setNotifyMesage('');
        navigate('/account/login');
      },
    });
  };
  const customToastStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const content = (
    <div className="container my-5 text-start">
      <div className="justify-content-center col-md-6 mx-auto flex-column flex-sm-row mt-5">
        <Card
          padding={0}
          body={
            <>
              <div className="d-sm-flex align-items-center container border-bottom mb-4">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('changePassword.forgot_my_password')}
                />
              </div>
              <div className="row d-flex">
                <div className="col-12 ">
                  {!isSuccess && key === null && (
                    <div className=" py-1 px-2 my-2 mt-md-2">
                      <InputLabelOnBorder
                        id={'email'}
                        inputType={InputType.Email}
                        labelText={t('common.email')}
                        inputValue={updateData.email || ''}
                        onChange={onChangeText}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        error={error.email}
                        required
                      />
                    </div>
                  )}
                  {isSuccess &&
                    updateData.email === undefined &&
                    key === null && (
                      <div className=" text-center py-1 mt-4">
                        <span>{t('changePassword.reset_info')}</span>
                      </div>
                    )}
                </div>
                {handleErrorMessage !== '' && key === null && (
                  <div className="text-center mt-1">
                    <span className="text-danger">{handleErrorMessage}</span>
                  </div>
                )}
                {key !== null && (
                  <div className="d-flex align-items-center flex-column">
                    <div className="col-12 mt-2 justify-content-center">
                      <Input
                        id={'password'}
                        className={
                          'form-control mt-2 border border-secodary border-3'
                        }
                        placeholder={t('changePassword.new_password')}
                        type={InputType.Password}
                        value={updateData.password || ''}
                        error={error.password}
                        onChange={onChangeText}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        required
                      />
                    </div>
                    <div className="col-12 mt-2 justify-content-center">
                      <Input
                        id={'confirmPassword'}
                        className={
                          'form-control mt-2 border border-secodary border-3'
                        }
                        placeholder={t('changePassword.re_enter_new_password')}
                        type={InputType.Password}
                        value={updateData.confirmPassword || ''}
                        error={error.confirmPassword}
                        onChange={onChangeText}
                        onFocus={onFocus}
                        onBlur={onBlur}
                      />
                    </div>
                    <div className="text-center mt-1">
                      {updateData.password !== '' ? matchErr : undefined}
                    </div>
                    <div className="col-3 mt-3 ms-auto   d-flex justify-content-end">
                      <Button
                        type={ButtonType.Successbg}
                        text={t('button.submit')}
                        textColor={TextColors.Light}
                        buttonCol={12}
                        onClick={onChangePassword}
                      />
                    </div>
                  </div>
                )}
                <div className="col-4 ms-auto  d-flex justify-content-end">
                  {!isSuccess && key === null && (
                    <Button
                      type={ButtonType.Successbg}
                      text={t('button.submit')}
                      textColor={TextColors.Light}
                      buttonCol={12}
                      onClick={onForgotMyPassword}
                    />
                  )}
                  {notifyMessage && notify()}
                  <ToastContainer
                    style={customToastStyle}
                    limit={1}
                    autoClose={2000}
                    closeOnClick
                    theme="light"
                  />
                </div>
              </div>
            </>
          }
        />
      </div>
    </div>
  );
  return useLoader() ? <Loader /> : content;
}
