import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import Button from '../../../../components/Common/Button/Button';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import { ButtonType, TextColors } from '../../../../utils/ComponentEnums';
import { Entities } from '../../../../utils/Enum';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timesPerMonthList = useSelector((state) => state.timesPerMonth.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const navigate = useNavigate();

  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    if (
      checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.timesPerMonth.getAll
      )
    ) {
      await dispatch(Actions.timesPerMonthActions.getAllAction());
    }
  };

  const headers = [
    { key: 'id', label: 'Id' },
    { key: 'monthDuration', label: t('timesPerMonth.per_month') },
    { key: 'monthOptionSessionValue', label: t('timesPerMonth.session_value') },
  ];
  const editAction = (val) => {
    navigate('/times-per-month-update', { state: { id: val.id } });
  };
  const removeAction = async (id) => {
    await dispatch(Actions.timesPerMonthActions.removeAction(id));
    getList();
  };

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <HeadTags
          hSize={1}
          text={t('timesPerMonth.times_per_month_list')}
          strong
        />
      </div>
      {checkClaims(
        currentUserClaim,
        Claims.backofficeClaims.timesPerMonth.create
      ) ? (
        <div className="row mb-3">
          <div className="d-flex justify-content-end">
            <Button
              type={ButtonType.Successbg}
              text={t('timesPerMonth.add_new_times_per_month_form')}
              textColor={TextColors.Light}
              buttonCol={3}
              onClick={() => navigate('/times-per-month-create')}
            />
          </div>
        </div>
      ) : undefined}
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div>
                <Table
                  // searchAble
                  data={timesPerMonthList}
                  headers={headers}
                  striped
                  bordered
                  page={Entities.timesPerMonth}
                  edit={(val) => editAction(val)}
                  remove={(val) => removeAction(val)}
                  currentUserClaim={currentUserClaim}
                  exportName={Entities.timesPerMonth.exportName}
                />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.timesPerMonth.getAll,
    content
  );
};
export default List;
