import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../../store/redux/actions';
import Card from '../../../../components/Common/Card/Card';
import Table from '../../../../components/Common/Table/Table';
import HeadTags from '../../../../components/Common/HeadTags/HeadTags';
import checkClaims from '../../../../hooks/useCheckClaims';
import Claims from '../../../../utils/Claims';
import filterTimeSet from '../../../../hooks/useFilterTimeSet';
import { useNavigate } from 'react-router-dom';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';
import Button from '../../../../components/Common/Button/Button';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import addDay from '../../../../hooks/useAddDay';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import { Entities, ModalTitles } from '../../../../utils/Enum';
import ModalInsideComponent from '../../../../components/Common/Modal/ModalInsideComponent';
import AttendeeNotes from '../../Attendee/AttendeeNotes';

const List = () => {
  const freeTrialNoteModal = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trialList = useSelector((state) => state.order.trialList);
  const locationList = useSelector((state) => state.location.getAll);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const managerList = useSelector((state) => state.manager.getAll);
  const [filterData, setFilterData] = useState({});
  const [dateChanged, setDateChanged] = useState(false);
  const [attendeeInfo, setAttendeeInfo] = useState({});
  const [mobileView, setMobileView] = useState(window.innerWidth < 577);

  useEffect(() => {
    const handleResize = () => {
      setMobileView(window.innerWidth < 577);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(Actions.managerActions.getAllAction('Dropdown'));
    dispatch(Actions.locationActions.getAllAction());
  }, []);

  useEffect(() => {
    if (
      checkClaims(currentUserClaim, Claims.backofficeClaims.order.get) &&
      filterData.startDate !== undefined &&
      !dateChanged
    ) {
      dispatch(Actions.orderActions.todaysTrialListAction(filterData));
    }
  }, [filterData.startDate]);

  useEffect(() => {
    const { strTime, endTime, strView, endView } = filterTimeSet(
      null,
      true,
      false,
      true
    );

    setFilterData({
      ...filterData,
      startDate: strTime,
      endDate: endTime,
      startDateView: strView,
      endDateView: endView,
    });
  }, []);

  const editSupAction = (id) => {
    navigate('/attendee-update', { state: { id: id.attendeeId } });
  };

  const forwardAction = (val) => {
    navigate(`/meetings/${val.orderItemId}/${val.supId}`, {
      state: { id: val.orderItemId, supId: val.supId },
    });
  };

  const freeTrialNoteAction = async (val) => {
    await dispatch(
      Actions.attendeeActions.getNotesByMeetingAttendeeAction(val.attendeeId)
    ).then(() => {
      setAttendeeInfo({
        managerName: val.managerName,
        attendeeName: val.attendeeName,
        phone: val.phone,
      });
    });
  };

  useEffect(() => {
    if (attendeeInfo?.attendeeName !== undefined) {
      freeTrialNoteModal.current?.open();
    }
  }, [attendeeInfo]);

  const onSearch = (e) => {
    e.preventDefault();
    if (checkClaims(currentUserClaim, Claims.backofficeClaims.order.get)) {
      dispatch(Actions.orderActions.todaysTrialListAction(filterData));
    }
  };

  const onChangeDate = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (value !== '') {
      const [year, month, day] = value.split('-').map(Number);
      const normalDateTime = addDay(
        `00:00`,
        `${day}-${month}-${year}`,
        false,
        false,
        true
      );
      const today = new Date();
      const selectedDate = new Date(value);
      const utcTime = new Date(
        selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
      );
      if (id === 'startDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime > today || utcTime > new Date(prevState.endDate)) {
            const { endDate, endDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      } else if (id === 'endDate') {
        setFilterData((prevState) => {
          const newFilterData = {
            ...prevState,
            [id]: normalDateTime,
            [`${id}View`]: value,
          };

          if (utcTime < new Date(prevState.startDate)) {
            const { startDate, startDateView, ...rest } = newFilterData;
            return rest;
          }

          return newFilterData;
        });
      }
      setDateChanged(true);
    } else {
      setFilterData((prevState) => {
        const { [id]: _, [`${id}View`]: __, ...rest } = prevState;
        return rest;
      });
      setDateChanged(true);
    }
  };

  function resetMe(key, value) {
    if (key !== undefined) {
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        [key]: value,
      }));
    }
    setDateChanged(true);
  }

  const handleDropdown = (propName, value) => {
    resetMe(propName, `${value}`);
  };

  const handleResetDropdown = (propName) => {
    setFilterData((prevState) => {
      const { [propName]: _, ...rest } = prevState;
      return rest;
    });
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.orderActions.cleanState());
    };
  }, []);

  const headers = [
    { key: 'attendeeId', label: t('common.attendeeId') },
    { key: 'attendeeName', label: t('common.name') },
    { key: 'phone', label: t('common.phone') },
    { key: 'languageLevel', label: t('meeting.table_level') },
    { key: 'learningPurpose', label: t('LearningPurpose') },
    { key: 'productType', label: t('orderItem.productType') },
    { key: 'locationName', label: t('location.location_name') },
    { key: 'managerName', label: t('manager.filter_manager_name') },
    { key: 'createdAt', label: t('common.created_at') },
  ];

  const content = (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-center mb-4">
        <HeadTags hSize={1} text={t('order.todays_trials')} strong />
      </div>
      <div className="col-12">
        <Card
          body={
            <React.Fragment>
              <div className="d-sm-flex align-items-center justify-content-center mb-4 form-div-border">
                <HeadTags
                  hSize={6}
                  strong
                  text={t('cardHeaderFilter.filter')}
                />
              </div>
              <div className="d-sm-flex align-items-center justify-content-end">
                <Button
                  text={t('cardHeaderFilter.search')}
                  icon={faSearch}
                  type={
                    dateChanged ? ButtonType.Successbg : ButtonType.Secondary
                  }
                  iconColor={TextColors.Light}
                  textColor={TextColors.Light}
                  iconSide
                  bold
                  size={ButtonSize.Small}
                  buttonCol={!mobileView ? 1 : 3}
                  onClick={onSearch}
                  disabled={!dateChanged}
                />
              </div>
              <div className="row my-2">
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'startDate'}
                    inputType={InputType.Date}
                    labelText={t('common.start_date')}
                    inputValue={filterData.startDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3">
                  <InputLabelOnBorder
                    id={'endDate'}
                    inputType={InputType.Date}
                    labelText={t('common.end_date')}
                    inputValue={filterData.endDateView || ''}
                    onChange={onChangeDate}
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_manager')}
                    options={managerList}
                    selectedProps={{ key: 'managerName', value: 'managerId' }}
                    onChange={(value) => handleDropdown('sType', value)}
                    onReset={() => handleResetDropdown('sType')}
                    isfilter
                  />
                </div>
                <div className="col-lg-3 mt-2">
                  <DropdownWithSearch
                    isSearchable
                    placeHolder={t('dropdownSelect.select_location')}
                    options={locationList}
                    selectedProps={{ key: 'locationName', value: 'id' }}
                    onChange={(value) => handleDropdown('SType2', value)}
                    onReset={() => handleResetDropdown('SType2')}
                    isfilter
                  />
                </div>
              </div>
            </React.Fragment>
          }
        />
        <Card
          body={
            <Table
              // searchAble
              data={trialList}
              headers={headers}
              striped
              bordered
              page={Entities.order}
              supPage={Entities.attendee}
              forwardPage={Entities.meeting}
              freeTrialNote={(val) => freeTrialNoteAction(val)}
              editSup={(val) => editSupAction(val)}
              forward={(val) => forwardAction(val)}
              currentUserClaim={currentUserClaim}
              noUtc={false}
              exportName={Entities.order.exportName.todaysTrial}
            />
          }
        />
      </div>
      <ModalInsideComponent
        ref={freeTrialNoteModal}
        title={ModalTitles.freeTrialNote}
        child={<AttendeeNotes data={attendeeInfo} />}
        onClose={() => {
          dispatch(Actions.attendeeActions.cleanState());
          freeTrialNoteModal.current?.close();
        }}
        maxWidth={800}
      />
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.getAll,
    content
  );
};
export default List;
