import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import findMyPath from '../../../hooks/useFindMyPath';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import { useSelector } from 'react-redux';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  ButtonWidth,
  TextColors,
} from '../../../utils/ComponentEnums';
import Dashboard from '../../UI/Dashboard/Dashboard';

const Error404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.auth);
  const [changeComponent, setChangeComponent] = useState(undefined);
  const page = t(auth !== '' ? 'sidebar.dashboard' : 'errorPage.main');
  const goBack = () => {
    navigate(-1);
  };

  const navigateMe = (e) => {
    e.preventDefault();
    if (auth !== '') {
      navigate(findMyPath('dashboard'));
      setChangeComponent(true);
    } else {
      navigate('/main');
    }
  };

  useEffect(() => {
    return () => {
      setChangeComponent(undefined);
    };
  }, []);

  return changeComponent ? (
    <Dashboard />
  ) : (
    <div className="container-fluid d-flex flex-column  Error my-5 py-5">
      <div className="text-center">
        <div className="d-flex justify-content-center mx-auto">
          <HeadTags hSize={2} strong text={'404'} />
        </div>
        <p className="lead text-gray mb-5">{t('errorPage.pageNotFound')}</p>
        <p className="text-gray-500 mb-0">{t('errorPage.excuseMessage')}</p>
        <div className="d-flex justify-content-center mt-2">
          <div className="d-flex align-items-center">
            <Button
              type={ButtonType.Successbg}
              icon={ButtonIcon.ArrowLeft}
              iconColor={TextColors.Light}
              bold
              onClick={goBack}
              buttonCol={1}
              size={ButtonSize.Small}
              width={ButtonWidth.S}
            />
            <span className="ml-2">{t('errorPage.backMessage')}</span>
          </div>
        </div>
        <p className="text-gray-500 mt-2">
          {t('errorPage.anotherBackPath', { page })}
        </p>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <Button
          text={t(auth !== '' ? 'sidebar.dashboard' : 'errorPage.main')}
          buttonCol={1}
          type={ButtonType.Successbg}
          textColor={TextColors.Light}
          onClick={(e) => navigateMe(e)}
        />
      </div>
    </div>
  );
};
export default Error404;
