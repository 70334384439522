import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import Card from '../../../components/Common/Card/Card';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import BasicTable from '../../../components/Common/Table/BasicTable';
import {
  ButtonType,
  TextColors,
  ButtonIcon,
  InputType,
} from '../../../utils/ComponentEnums';
import {
  faCircle,
  faComputerMouse,
  faShareFromSquare,
  faShoppingCart,
  faSquareCheck,
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../components/Common/Modal/Modal';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import MobileTable from '../../../components/Common/Table/MobileTable';

const icons = [
  faComputerMouse,
  faShoppingCart,
  faShareFromSquare,
  faSquareCheck,
];

export default function SendGiftSession() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createData, setCreateData] = useState({});
  const giftSessions = useSelector((state) => state.order.giftSessions);
  const userInfo = useSelector((state) => state.user.getById);
  const userName = `${userInfo?.firstName} ${userInfo?.lastName}`;
  const validationMessages = useSelector(
    (state) => state.order.validationMessage
  );
  const successCheck = useSelector((state) => state.order.isSuccess);
  const [isBasicOpen, setIsBasicOpen] = useState(false);
  const [error, setError] = useState({});
  const [handleValidationMessages, setHandleValidationMessage] =
    useState(validationMessages);

  const textArr = [
    t('sendGiftSession.first_explanation'),
    t('sendGiftSession.second_explanation'),
    t('sendGiftSession.third_explanation'),
    t('sendGiftSession.forth_explanation'),
  ];

  const headers = [
    { key: 'orderNumber', label: t('sendGiftSessionTable.order_number') },
    { key: 'packageDetail', label: t('sendGiftSessionTable.package_detail') },
    { key: 'totalAmount', label: t('sendGiftSessionTable.total_amount') },
    { key: 'sentTo', label: t('sendGiftSessionTable.sent_to') },
    { key: 'isUsed', label: t('sendGiftSessionTable.is_used') },
  ];
  useEffect(() => {
    setHandleValidationMessage(validationMessages);
  }, [validationMessages]);
  useEffect(() => {
    if (successCheck) {
      setCreateData({});
      setIsBasicOpen(false);
      getList();
    }
  }, [successCheck]);

  const setDateButton = (val) => {
    setCreateData({
      ...createData,
      orderId: parseInt(giftSessions?.[val]?.orderNumber),
      senderFullName: userName,
      Id: userInfo?.attendeeId,
    });
    setIsBasicOpen(true);
  };
  const cancelButton = async (val) => {
    await dispatch(
      Actions.orderActions.cancelGiftAction(
        parseInt(giftSessions?.[val]?.orderNumber)
      )
    );
    getList();
  };
  const handleModalClose = () => {
    setIsBasicOpen(false);
    setCreateData({});
  };
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setCreateData({ ...createData, [id]: value });
    const validationResult = regexValidator(e.target.type, e.target.value);

    if (!validationResult & (e.target.value !== '')) {
      setError({
        ...error,
        [e.target.id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(e.target.type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [e.target.id]: undefined });
    }
  };
  const onBlur = (e) => {
    const { id, value } = e.target;
    if (value === '') {
      const { [id]: deletedValue, ...updatedCreateData } = createData;
      setCreateData(updatedCreateData);
    }
    if (id === 'email' && value === userInfo?.email) {
      const { [id]: deletedValue, ...updatedCreateData } = createData;
      setCreateData(updatedCreateData);
    }
  };

  const onSend = (e) => {
    e.preventDefault();
    dispatch(Actions.orderActions.senfGiftSessionToFriendAction(createData));
  };
  async function getList() {
    await dispatch(Actions.orderActions.getGiftSessionsAction());
  }
  useEffect(() => {
    getList();
  }, []);

  const styleText = (text, color) => {
    const parts = text.split(/\|([^|]+)\|/g);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span key={index} style={{ color }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="container-fluid">
      <div className="row mt-2">
        <div className="col-12 container">
          <React.Fragment>
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-start mt-3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '10px',
                    minHeight: '60px',
                    backgroundColor: '#4CBC9A',
                    borderRadius: '3.5px',
                    marginRight: '10px',
                  }}
                />
                <span className="fs-4" style={{ lineHeight: '1.1' }}>
                  <strong>
                    {styleText(
                      t('sendGiftSession.card_header_explanation'),
                      '#4CBC9A'
                    )}
                  </strong>
                </span>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="row g-4">
                {textArr.map((value, index) => (
                  <div
                    key={`first_div_earngift${index}`}
                    className="col col-md-2 d-flex align-items-stretch me-md-5 me-0"
                  >
                    <div className="square text-center d-flex flex-column align-items-center">
                      <FontAwesomeIcon
                        className="fa-4x text-info mb-3"
                        icon={icons[index]}
                      />
                      <div className="fw-bold text-dark mb-3">{value}</div>
                      <div className="billiard-ball d-flex align-items-center justify-content-center">
                        <div className="ball-number">{index + 1}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row mt-4 d-flex justify-content-start">
                <div className="col-sm-3">
                  <Button
                    type={ButtonType.Successbg}
                    textColor={TextColors.Light}
                    text={t('sendGiftSessionButton.get_gift_session')}
                    buttonCol={12}
                    icon={ButtonIcon.ArrowRight}
                    iconColor={TextColors.Light}
                    iconSide
                    onClick={() =>
                      navigate(
                        userInfo.userArea === 0
                          ? '/order/package'
                          : userInfo.userArea === 1
                          ? '/cafe/order/package'
                          : userInfo.userArea === 2
                          ? '/kids/order/package'
                          : '/order/package',
                        {
                          state: { fromNavigateWhere: 'giftSession' },
                        }
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>

        <div className="col-12 container">
          <Card
            body={
              <>
                <div className="d-sm-flex align-items-center justify-content-between mt-3">
                  <HeadTags
                    hSize={6}
                    strong
                    text={t('sendGiftSession.send_gift_history')}
                    textColor={TextColors.Dark}
                  />
                </div>
                <div className="container-fluid mt-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="col-sm-12 d-none d-sm-block">
                        <BasicTable
                          data={giftSessions}
                          headers={headers}
                          actionButtonProps={{
                            text: t('sendGiftSessionTable.send'),
                            type: ButtonType.Successbg,
                            textColor: TextColors.Light,
                          }}
                          action2ndButtonProps={{
                            text: t('sendGiftSessionTable.cancel'),
                            type: ButtonType.Danger,
                            textColor: TextColors.Light,
                          }}
                          infoButtonProps={{
                            text: t('sendGiftSessionTable.info'),
                            type: ButtonType.Info,
                            textColor: TextColors.Light,
                          }}
                          matchValues={{
                            actionButtonProps: { key: 'isUsed', value: false },
                            action2ndButtonProps: {
                              key: 'sentTo',
                              value: '',
                            },
                            infoButtonProps: { key: 'isUsed', value: true },
                          }}
                          onClickFuncSetDate={(val) => setDateButton(val)}
                          onClickFuncRemove={(val) => cancelButton(val)}
                        />
                      </div>
                      <div className="d-block d-sm-none">
                        <MobileTable
                          searchAble
                          data={giftSessions}
                          headers={headers}
                          tableType={'basic'}
                          actionButtonProps={{
                            text: t('sendGiftSessionTable.send'),
                            type: ButtonType.Successbg,
                            textColor: TextColors.Light,
                          }}
                          action2ndButtonProps={{
                            text: t('sendGiftSessionTable.cancel'),
                            type: ButtonType.Danger,
                            textColor: TextColors.Light,
                          }}
                          infoButtonProps={{
                            text: t('sendGiftSessionTable.info'),
                            type: ButtonType.Info,
                            textColor: TextColors.Light,
                          }}
                          matchValues={{
                            actionButtonProps: { key: 'isUsed', value: false },
                            action2ndButtonProps: {
                              key: 'sentTo',
                              value: null,
                            },
                            infoButtonProps: { key: 'isUsed', value: true },
                          }}
                          onClickFuncSetDate={(val) => setDateButton(val)}
                          onClickFuncRemove={(val) => cancelButton(val)}
                        />
                      </div>
                      <Modal
                        isVisible={isBasicOpen}
                        styleProps={{ maxWidth: 550 }}
                        content={
                          <div className="d-flex justify-content-evenly">
                            <div className="mx-2 w-50">
                              <InputLabelOnBorder
                                id={'email'}
                                inputType={InputType.Email}
                                labelText={t('sendGiftSession.email')}
                                inputValue={createData.email || ''}
                                onChange={onChangeText}
                                onBlur={onBlur}
                                error={error.email}
                              />
                            </div>
                            <div className="mx-2 w-50">
                              <InputLabelOnBorder
                                id={'note'}
                                inputType={InputType.Text}
                                labelText={t('sendGiftSession.note')}
                                inputValue={createData.note || ''}
                                onChange={onChangeText}
                                onBlur={onBlur}
                                textArea={{ row: 5, col: 20 }}
                              />
                              {handleValidationMessages !== '' && (
                                <div className="text-center mt-1">
                                  <span className="text-danger">
                                    {handleValidationMessages}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        footer={
                          <Button
                            type={
                              Object.keys(createData).length >= 4
                                ? ButtonType.Successbg
                                : ButtonType.Secondary
                            }
                            bold
                            textColor={TextColors.Light}
                            buttonCol={6}
                            text={t('sendGiftSessionTable.send')}
                            onClick={onSend}
                            disabled={
                              Object.keys(createData).length >= 4 ? false : true
                            }
                          />
                        }
                        onClose={handleModalClose}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
