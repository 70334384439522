import React, { useState } from 'react';
import Card from '../../../components/Common/Card/Card';
import WeeklyCalendar from '../../../components/Common/Calendar/WeeklyCalendar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../utils/ComponentEnums';
import filterTimeSet from '../../../hooks/useFilterTimeSet';
import addDay from '../../../hooks/useAddDay';
import Input from '../../../components/Common/Input/Input';
import Dropdown from '../../../components/Common/Dropdown/Dropdown';
import typeOfServiceSVG from '../../../assets/images/typeofservice.svg';
import sessionLevelSVG from '../../../assets/images/session_level.svg';
import meet2talkButton from '../../../assets/images/meet2talk_button.svg';
import { People } from '../../../utils/Enum';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';

export default function SchedulePlan() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.getById);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const onlyMentors = useSelector(
    (state) => state.calendar.getCalendarSpeakers
  );
  const staticDatas = useSelector((state) => state.staticData);
  const locations = useSelector((state) => state.location.branches);
  const companyList = useSelector((state) => state.company.getAll);
  const ageInterval = useSelector((state) => state.ageInterval.getAll);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const userArea = staticDatas.UserArea;
  const typeOfService = staticDatas.TypeOfService;
  const currentUserCompanyId = userInfo.companyId;
  const isCompanyManager =
    currentUserCompanyId !== 1 && currentUserCompanyId !== undefined;
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState({
    StartDateTime: null,
    EndDateTime: null,
    LearningPurpose: null,
    LanguageLevels: null,
    TypeOfService: null,
    Native: null,
    FullName: null,
    UserArea: null,
    People: null,
    Locations: null,
    SelectedIds: null,
    PackageId: null,
    CompanyId: null,
    DateDetails: {
      dayOfWeek: null,
      timeInterval: null,
      selectedDate: null,
      selectedTime: null,
    },
  });
  const isOnline = filter?.UserArea === 0;
  const isCafe = filter?.UserArea === 1;
  const isKids = filter?.UserArea === 2;
  const isCompany = filter?.UserArea === 3;
  const isSpeaker = userInfo?.speakerId !== 0;
  const [showLoader, setShowLoader] = useState(false);
  const [color, setColor] = useState(undefined);

  const isManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.get
  );

  useEffect(() => {
    if (userInfo.companyId !== undefined) {
      const { strTime, endTime } = filterTimeSet();
      setFilter((prevFilter) => ({
        ...prevFilter,
        StartDateTime: strTime,
        EndDateTime: endTime,
        UserArea: isSpeaker || isCompanyManager ? null : 0,
        CompanyId: isCompanyManager ? userInfo.companyId : isSpeaker ? null : 1,
      }));
    }
  }, [userInfo]);

  async function getAllSpeakersWithFilter() {
    await dispatch(Actions.calendarActions.getAllBOCalendarAction(filter));
    // await dispatch(Actions.calendarActions.getFiltredSpeakersAction(filter));
  }

  useEffect(() => {
    if (filter.StartDateTime !== null) {
      getAllSpeakersWithFilter();
    }
  }, [filter]);

  function extractSpeakerDetails(list) {
    return (
      list?.map((item) => ({
        SpeakerName: item.speakerName,
        SpeakerId: item.speakerId,
        SpeakerImage: item.speakerImage,
        SpeakerTitle: item.speakerTitle,
      })) || []
    );
  }

  useEffect(() => {
    extractSpeakerDetails(onlyMentors);
  }, [onlyMentors]);

  useEffect(() => {
    if (filter.UserArea === 1 || filter.UserArea === null) {
      dispatch(
        Actions.locationActions.getLocationsByUserAreaAction({
          userArea: 1,
        })
      );
    }

    if (isKids && ageInterval.length === 0) {
      dispatch(Actions.ageIntervalActions.getAllAction(2));
    }
  }, [filter.UserArea]);

  const [selectedCell, setSelectedCell] = useState(null);
  const handleCellSelect = (cellValue) => {
    setSelectedCell(cellValue);
  };

  const handleSelectChange = (
    target,
    isMulti = false,
    takeKey = false,
    company = false
  ) => {
    setShowLoader(true);
    let name = !company ? target.name : 'CompanyId';
    let value = !company ? target.value : target.companyId;
    const optionValue = takeKey ? target.key : parseInt(value);

    if (isMulti) {
      const updatedArray = [...(filter[name] || [])];
      const valueIndex = updatedArray.indexOf(optionValue);

      if (valueIndex !== -1) {
        updatedArray.splice(valueIndex, 1);
      } else {
        updatedArray.push(optionValue);
      }
      setFilter({
        ...filter,
        [name]: updatedArray.length > 0 ? updatedArray : null,
      });
    }
    if (!isMulti) {
      if (name === 'UserArea') {
        if (filter[name] !== optionValue) {
          setFilter({
            ...filter,
            [name]: optionValue,
            TypeOfService: null,
            People: null,
            LanguageLevels: null,
            Locations: null,
            AgeId: null,
          });
        }
      } else {
        if (filter[name] === optionValue) {
          setFilter({
            ...filter,
            [name]: null,
          });
        } else {
          setFilter({
            ...filter,
            [name]: optionValue,
          });
        }
      }
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setFilter({
        ...filter,
        FullName: e.target.value,
        UserArea: null,
      });
    }
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    const { value } = e.target;
    if (value === '') {
      setFilter({ ...filter, FullName: null });
    }
  };
  const onSearchClick = (e) => {
    e.preventDefault();
    const inputValue = document.getElementById('FullName').value;
    if (inputValue !== '') {
      setFilter({
        ...filter,
        FullName: inputValue,
        UserArea: null,
      });
    }
  };

  useEffect(() => {
    dispatch(Actions.companyActions.dropdownListAction());
    return () => {
      dispatch(Actions.calendarActions.cleanState(true));
    };
  }, []);

  const selectedLanguageLevels = [];
  if (
    levelGroup &&
    levelGroup?.adult &&
    levelGroup?.kids &&
    filter.UserArea !== undefined
  ) {
    if (filter?.UserArea === 2) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.kids]
      );
    } else if (
      (filter?.UserArea !== 2 &&
        filter.UserArea !== undefined &&
        filter.UserArea !== null) ||
      isCompanyManager
    ) {
      selectedLanguageLevels.push(
        ...staticDatas.LanguageLevels[levelGroup?.adult]
      );
    } else {
      Object.values(levelGroup).forEach((lang) => {
        if (staticDatas.LanguageLevels[lang]) {
          selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
        }
      });
    }
  }

  function setAreaName() {
    let txt = '';
    switch (filter?.UserArea) {
      case 0:
        txt = 'UserArea.0';
        break;
      case 1:
        txt = 'UserArea.1';
        break;
      case 2:
        txt = 'UserArea.2';
        break;
      case 3:
        txt = 'UserArea.3';
        break;

      default:
        txt = 'landing.filter_session_type';
        break;
    }
    return txt;
  }

  const personOptions = filter.UserArea === 1 ? People.Cafe : People.Online;

  const content = (
    <div className="container-fluid mb-5">
      <div className="row">
        <div className="col-lg-12">
          <Card
            body={
              <div className="col-12">
                <div
                  className={`d-lg-flex flex-row align-items-center filter-section`}
                >
                  <div className="flex-fill">
                    <Dropdown
                      key={'activeMeetingSchema_userArea'}
                      id={'userArea'}
                      title={t(setAreaName(), {
                        lng: 'en',
                      })}
                      propNames={{
                        key: 'name',
                        value: 'value',
                        name: 'key',
                      }}
                      options={userArea?.filter((val, i) => {
                        if (isCompanyManager) {
                          return i === 0 || i === 3;
                        }
                        return userArea;
                      })}
                      selectedOptions={userArea?.filter(
                        (val) => filter?.UserArea === parseInt(val.value)
                      )}
                      handleChange={(target) => handleSelectChange(target)}
                      rounded={2}
                      svgWidth={30}
                      svg={meet2talkButton}
                      arrowUp
                      isFilter
                      resetState={filter.UserArea === null}
                    />
                  </div>
                  {!isCafe && (
                    <div className="flex-fill">
                      <Dropdown
                        key={'activeMeetingSchema_typeOfService'}
                        id={'typeofService'}
                        title={t('attendeeDashboard.type_of_service')}
                        propNames={{
                          key: 'name',
                          value: 'value',
                          name: 'key',
                        }}
                        options={typeOfService}
                        selectedOptions={typeOfService.filter((service) =>
                          filter?.TypeOfService?.includes(
                            parseInt(service.value)
                          )
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, true)
                        }
                        rounded={2}
                        svgWidth={30}
                        svg={typeOfServiceSVG}
                        arrowUp
                        isFilter
                        isMulti
                        resetState={filter.TypeOfService === null}
                      />
                    </div>
                  )}
                  {(isCafe || isCompany) && (
                    <div className="flex-fill">
                      <Dropdown
                        key={'activeMeetingSchema_locations'}
                        id={'locations'}
                        title={t('attendeeDashboard.locations')}
                        propNames={{
                          key: 'key',
                          value: 'value',
                          name: 'key',
                        }}
                        options={locations}
                        selectedOptions={locations.filter((service) =>
                          filter?.Locations?.includes(parseInt(service.value))
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, true)
                        }
                        rounded={2}
                        svgWidth={30}
                        svg={typeOfServiceSVG}
                        arrowUp
                        isFilter
                        isMulti
                        resetState={filter.Locations === null}
                      />
                    </div>
                  )}
                  <div className="flex-fill">
                    <Dropdown
                      key={'activeMeetingSchema_languageLevel'}
                      id={'languageLevels'}
                      title={t(
                        filter?.LanguageLevels === null ||
                          filter?.LanguageLevels === undefined
                          ? 'attendeeDashboard.language_level'
                          : filter?.LanguageLevels
                      )}
                      propNames={{
                        key: 'key',
                        value: 'key',
                        name: 'key',
                      }}
                      options={selectedLanguageLevels}
                      handleChange={(target) =>
                        handleSelectChange(target, false, true)
                      }
                      rounded={2}
                      svgWidth={30}
                      svg={sessionLevelSVG}
                      arrowUp
                      isFilter
                      addIndex
                      resetState={filter.LanguageLevels === null}
                    />
                  </div>
                  {!isKids && (
                    <div className="flex-fill">
                      <Dropdown
                        key={'activeMeetingSchema_person'}
                        id={'people'}
                        title={t('attendeeDashboard.people')}
                        propNames={{
                          key: 'name',
                          value: 'value',
                          name: 'key',
                        }}
                        options={personOptions}
                        selectedOptions={personOptions.filter((service) =>
                          filter?.People?.includes(parseInt(service.value))
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, true)
                        }
                        rounded={2}
                        svgWidth={30}
                        svg={typeOfServiceSVG}
                        arrowUp
                        isFilter
                        isMulti
                        resetState={filter.People === null}
                      />
                    </div>
                  )}
                  {isKids && (
                    <div className="flex-fill">
                      <Dropdown
                        key={'activeMeetingSchema_ageInterval'}
                        id={'ageInterval'}
                        title={
                          filter?.AgeId === null ||
                          filter?.AgeId === undefined ||
                          ageInterval?.filter(
                            (f) => f.value === filter?.AgeId
                          )?.[0]?.key === undefined
                            ? t('attendeeDashboard.ageInterval')
                            : `${
                                ageInterval?.filter(
                                  (f) => f.value === filter?.AgeId
                                )?.[0]?.key
                              } ${t('attendeeDashboard.age')}`
                        }
                        propNames={{
                          key: 'name',
                          value: 'value',
                          name: 'key',
                        }}
                        options={ageInterval}
                        handleChange={(target) => handleSelectChange(target)}
                        rounded={2}
                        svgWidth={30}
                        svg={typeOfServiceSVG}
                        arrowUp
                        isFilter
                        resetState={filter.AgeId === null}
                      />
                    </div>
                  )}
                  {!isCompanyManager && !isSpeaker && (
                    <div className="flex-fill">
                      <Dropdown
                        key={'activeMeetingSchema_company'}
                        id={'companyId'}
                        title={
                          companyList?.filter(
                            (val) => filter?.CompanyId === val.companyId
                          )?.[0]?.companyName ?? t('attendeeDashboard.company')
                        }
                        propNames={{
                          key: 'companyName',
                          value: 'companyId',
                          name: 'companyName',
                        }}
                        options={companyList}
                        selectedOptions={companyList?.filter(
                          (val) => filter?.CompanyId === val.companyId
                        )}
                        handleChange={(target) =>
                          handleSelectChange(target, false, false, true)
                        }
                        rounded={2}
                        svgWidth={30}
                        svg={typeOfServiceSVG}
                        arrowUp
                        isFilter
                        resetState={filter.CompanyId === null}
                      />
                    </div>
                  )}
                  {!isCompanyManager && isManager && (
                    <div className="flex-fill">
                      <div className="d-flex justify-content-evenly">
                        <Input
                          key={'activeMeetingSchema_fullName'}
                          id="FullName"
                          className={
                            'col-12 me-1 bg-m2t border-0 medium border-bottom border-secondary'
                          }
                          type={InputType.Text}
                          value={searchValue}
                          placeholder={t('attendeeDashboard.search_mentors')}
                          onKeyPress={onKeyPress}
                          onChange={onChangeSearch}
                          style={{ fontSize: '.86rem' }}
                        />
                        <Button
                          icon={ButtonIcon.Search}
                          borderless
                          iconColor={TextColors.Success}
                          onClick={onSearchClick}
                          buttonCol={1}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            }
          />
        </div>
        <div className="col-12">
          <Card
            padding={0}
            body={
              <>
                <div
                  className="d-flex text-nowrap justify-content-center justify-content-md-end mt-3"
                  style={{ fontSize: '1em' }}
                >
                  {filter.UserArea !== 1 && (
                    <div className="align-self-center px-2">
                      <span>
                        <strong>{t('button.one_to_one')}</strong>
                      </span>
                      <Button
                        type={ButtonType.Success}
                        buttonCol={12}
                        style={{ width: '100%' }}
                        disableHover={true}
                      />
                    </div>
                  )}
                  <div className="align-self-center px-2">
                    <span>
                      <strong>{t('button.group')}</strong>
                    </span>
                    <Button
                      type={ButtonType.Warning}
                      buttonCol={12}
                      style={{ width: '100%' }}
                      disableHover={true}
                    />
                  </div>
                </div>
                <WeeklyCalendar
                  area={filter.UserArea}
                  controlPage={true}
                  color={color}
                  onCellSelect={handleCellSelect}
                  getSelectedDate={(val) => {
                    if (val.popup) {
                      const date = addDay(val.time, val.filter, true);
                      const [year, month, day] = date
                        .split('T')[0]
                        .split('-')
                        .map(Number);
                      const nDate = `${year}-${month
                        .toString()
                        .padStart(2, '0')}-${day.toString().padStart(2, '0')}T${
                        date.split('T')[1]
                      }`;
                      const changes = {
                        ...filter,
                        StartDateTime: nDate,
                      };
                      if (filter.SelectedIds === null) {
                        const arr = [];
                        val.ids
                          .split(',')
                          .forEach((el) => arr.push(parseInt(el.trim())));
                        changes.SelectedIds = arr;
                      }
                      dispatch(
                        Actions.calendarActions.getCellDetailsBOAction(changes)
                      );
                    } else if (!val.popup) {
                      const { strTime, endTime } = filterTimeSet(
                        val.filter.split('T')[0]
                      );
                      const changes = {
                        ...filter,
                        StartDateTime: strTime,
                        EndDateTime: endTime,
                      };
                      setFilter(changes);
                    }
                  }}
                  filter={filter}
                />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.calendar.update,
    content,
    Claims.actionClaims.companyMeeting
  );
}
