import ActionTypes from '../actions/actionTypes';
import { initialState } from './initialState';

const subscriptionReducer = (state = initialState.subscription, action) => {
  switch (action.type) {
    case ActionTypes.subscription.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.subscription.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.subscription.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.subscription.CleanList:
      return {
        ...state,
        getById: [],
        getAll: [],
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
