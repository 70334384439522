import {
    create,
    update,
    remove,
    getById,
    getAll,
    dropdownList,
    getCompanyAttendees,
    getCompanyManagers,
    getCompanyAttendeesDropdown,
    createCompanyOrders,
    getCompanyOrders,
    assignCompanyOrders,
    companyReporting,
} from '../../../services/apiServices/companyServices';
import ActionTypes from './actionTypes';
import config from '../../../config.json';
import { reponseStatus, responseErrorStatus } from '../../../utils/Enum';

const createAction = (createData) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        await create(createData)
            .then(async (res) => {
                await dispatch(handleValidationReducer(res?.data?.message));
                await dispatch(handleError(res?.data?.isSuccess));
                if (res?.data?.isSuccess) {
                    await dispatch(createReducer());
                }
            })
            .catch(async (error) => {
                if (error?.response?.status === 400) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.BadRequest)
                    );
                    await dispatch(handleError(reponseStatus.fail));
                } else {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RequestTimedOut)
                    );
                    await dispatch(handleError(reponseStatus.fail));
                }
            });
    };
};
const createReducer = () => {
    return { type: ActionTypes.company.Create };
};
const updateAction = (updateData, id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        await update(updateData, id).then(async (res) => {
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleError(res?.data?.isSuccess));
            if (res?.data?.isSuccess) {
                await dispatch(createReducer());
            }
        }).catch(async error => {
            if (error?.response?.status === 400) {
                await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
                await dispatch(handleError(reponseStatus.fail));
            }
            else {
                await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
                await dispatch(handleError(reponseStatus.fail));
            }
        });
    };
};
const updateReducer = () => {
    return { type: ActionTypes.company.Update };
};
const removeAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        await remove(id)
            .then(async (res) => {
                await dispatch(handleValidationReducer(res?.data?.message));
                await dispatch(handleError(res?.data?.isSuccess));
            })
            .catch(async (error) => {
                await dispatch(
                    handleValidationReducer(responseErrorStatus.RequestTimedOut)
                );
                await dispatch(handleError(reponseStatus.fail));
            });
    };
};
const getByIdAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await getById(id);
                let response = res?.data?.data;
                await dispatch(getByIdReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const getByIdReducer = (data) => {
    return {
        type: ActionTypes.company.GetById,
        payload: data,
    };
};
const getAllAction = (filterData) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await getAll(filterData);
                let response = res?.data?.data;
                await dispatch(getAllReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const dropdownListAction = () => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await dropdownList();
                let response = res?.data?.data;
                await dispatch(getAllReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const getAllReducer = (data) => {
    return { type: ActionTypes.company.GetAll, payload: data };
};
const getCompanyAttendeesDropdownAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await getCompanyAttendeesDropdown(id);
                let response = res?.data?.data;
                await dispatch(getCompanyAttendeesDropdownReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const getCompanyAttendeesDropdownReducer = (data) => {
    return {
        type: ActionTypes.company.GetCompanyAttendeesDropdown,
        payload: data,
    };
};
const getCompanyAttendeesAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await getCompanyAttendees(id);
                let response = res?.data?.data;
                await dispatch(getCompanyAttendeesReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const getCompanyAttendeesReducer = (data) => {
    return {
        type: ActionTypes.company.GetCompanyAttendees,
        payload: data,
    };
};
const getCompanyManagersAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await getCompanyManagers(id);
                let response = res?.data?.data;
                await dispatch(getCompanyManagersReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const createCompanyOrdersAction = (updateData) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        await createCompanyOrders(updateData).then(async (res) => {
            await dispatch(handleValidationReducer(res?.data?.message));
            await dispatch(handleError(res?.data?.isSuccess));
        }).catch(async error => {
            if (error?.response?.status === 400) {
                await dispatch(handleValidationReducer(responseErrorStatus.BadRequest));
                await dispatch(handleError(reponseStatus.fail));
            }
            else {
                await dispatch(handleValidationReducer(responseErrorStatus.RequestTimedOut));
                await dispatch(handleError(reponseStatus.fail));
            }
        });
    };
};

const getCompanyManagersReducer = (data) => {
    return {
        type: ActionTypes.company.GetCompanyManagers,
        payload: data,
    };
};
const getCompanyOrdersAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await getCompanyOrders(id);
                let response = res?.data?.data;
                await dispatch(getCompanyOrdersReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const getCompanyOrdersReducer = (data) => {
    return {
        type: ActionTypes.company.GetCompanyOrders,
        payload: data,
    };
};
const assignCompanyOrdersAction = (createData) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        await assignCompanyOrders(createData)
            .then(async (res) => {
                await dispatch(handleValidationReducer(res?.data?.message));
                await dispatch(handleError(res?.data?.isSuccess));
            })
            .catch(async (error) => {
                if (error?.response?.status === 400) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.BadRequest)
                    );
                    await dispatch(handleError(reponseStatus.fail));
                } else {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RequestTimedOut)
                    );
                    await dispatch(handleError(reponseStatus.fail));
                }
            });
    };
};
const companyReportingAction = (id) => {
    return async (dispatch) => {
        dispatch(cleanState(true, true));
        let maxRetries = config.RequestRule.retryCount;
        let isFirstRequest = true;
        while (maxRetries > 0) {
            if (!isFirstRequest) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
            try {
                const res = await companyReporting(id);
                let response = res?.data?.data;
                await dispatch(companyReportingReducer(response));
                await dispatch(handleError(''));
                isFirstRequest = false;
                break;
            } catch (error) {
                isFirstRequest = false;
                maxRetries--;
                if (maxRetries === 0) {
                    await dispatch(
                        handleValidationReducer(responseErrorStatus.RetryTimeOut)
                    );
                    await dispatch(handleError(false));
                    break;
                }
            }
        }
    };
};
const companyReportingReducer = (data) => {
    return {
        type: ActionTypes.company.CompanyReporting,
        payload: data,
    };
};
const handleError = (data) => {
    return {
        type: ActionTypes.company.HandleError,
        payload: data,
    };
};
const handleValidationReducer = (data) => {
    return {
        type: ActionTypes.company.HandleValidation,
        payload: data,
    };
};
const cleanListReducer = () => {
    return { type: ActionTypes.company.CleanList };
};

const cleanState = (validation = false, ongoing) => {
    return async (dispatch) => {
        if (validation) {
            dispatch(handleValidationReducer(''));
            dispatch(
                handleError(ongoing ? reponseStatus.ongoing : reponseStatus.default)
            );
        } else {
            dispatch(handleValidationReducer(''));
            dispatch(handleError(''));
            dispatch(cleanListReducer());
        }
    };
};

const companyActions = {
    createAction,
    updateAction,
    removeAction,
    getByIdAction,
    getAllAction,
    dropdownListAction,
    getCompanyAttendeesDropdownAction,
    getCompanyAttendeesAction,
    getCompanyManagersAction,
    createCompanyOrdersAction,
    getCompanyOrdersAction,
    assignCompanyOrdersAction,
    companyReportingAction,
    cleanState,
};
export default companyActions;
