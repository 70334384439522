import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';
import Card from '../../../components/Common/Card/Card';
import { InputType } from '../../../utils/ComponentEnums';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../store/redux/actions';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import InputLabelOnBorder from '../../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../../components/Common/FileInput/FileUpload';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Claims from '../../../utils/Claims';
import checkClaims from '../../../hooks/useCheckClaims';
import regexValidator from '../../../hooks/useRegexValidator';
import validationMessage from '../../../hooks/useValidationMessage';
import Toast from '../../../components/Common/Popup/Toast';
import SubmitButton from '../../../components/Common/Button/SubmitButton';

const Create = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => {
    dispatch(Actions.companyActions.cleanState());
    dispatch(Actions.companyPriceRequestActions.cleanState(true));
    navigate(-1);
  };
  const companyId = location?.state?.id;
  const company = useSelector((state) => state.company.company);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const companyPriceRequest = useSelector((state) => state.companyPriceRequest.getById);
  const redirect = '/company-list';
  const [createData, setCreateData] = useState(company);
  const [imgSrc, setImgSrc] = useState('');
  const [error, setError] = useState({});

  const isManager = checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.company.getAll
  );

  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, name } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value, name);
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };

  const onCreate = async (e) => {
    e.preventDefault();
    await dispatch(Actions.companyActions.createAction(createData));
  };

  useEffect(() => {
    if (companyId) {
      dispatch(Actions.companyPriceRequestActions.getByIdAction(companyId));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(companyPriceRequest).length > 0) {
      setCreateData({
        ...createData,
        name: companyPriceRequest.name,
        companyRepresentativeRequestId: companyPriceRequest.id,
      });
    }
  }, [companyPriceRequest]);

  useEffect(() => {
    return () => {
      dispatch(Actions.companyActions.cleanState());
      dispatch(Actions.companyPriceRequestActions.cleanState(true));
    };
  }, []);

  const content =
    companyId === undefined && !isManager ? (
      <Navigate to="/unauthorized" replace />
    ) : (
      <div className="container-fluid">
        <div className="d-flex justify-content-center mt-0 mt-md-5">
          <div className="col-12">
            <Card
              body={
                <React.Fragment>
                  <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                    <div className="col-12 col-md-1 mt-sm-3">
                      <Button
                        type={ButtonType.Successbg}
                        icon={ButtonIcon.ArrowLeft}
                        iconColor={TextColors.Light}
                        bold
                        onClick={goBack}
                        buttonCol={12}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center mb-4 mt-2 mt-md-0 form-div-border">
                    <HeadTags
                      hSize={5}
                      strong
                      text={t('company.add_new_company_form')}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <InputLabelOnBorder
                        id={'name'}
                        labelText={t('company.companyName')}
                        inputValue={createData.name || ''}
                        onChange={onChangeText}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-2 mt-md-0">
                      <InputLabelOnBorder
                        id={'email'}
                        inputType={InputType.Email}
                        labelText={t('company.companyEmail')}
                        inputValue={createData.email || ''}
                        onChange={onChangeText}
                        error={error?.email}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                      <div className="col-12 d-flex justify-content-center">
                        {imgSrc ? (
                          <img
                            className={'img-fluid rounded-2 me-3'}
                            src={imgSrc}
                            alt={imgSrc}
                            width="100px"
                          />
                        ) : undefined}
                      </div>
                      <CustomFileInput
                        id={'logo'}
                        key={'logo'}
                        hidden={true}
                        buttonText={t('company.uploadLogo')}
                        textColor={TextColors.Light}
                        removeState={
                          createData.logo !== undefined ? false : true
                        }
                        buttonColor={ButtonType.Successbg}
                        fileType={'Logo'}
                        dataFrom={'Profile'}
                        func={(val) => {
                          setCreateData(
                            Object.assign({}, createData, {
                              logo: val.base64String,
                              imgExtension: val.extension,
                              url: val.fileName,
                            })
                          );
                          setImgSrc(val.imgSrc);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <InputLabelOnBorder
                        id={'taxNo'}
                        inputType={InputType.Number}
                        labelText={t('company.taxNo')}
                        inputValue={createData.taxNo || ''}
                        onChange={onChangeText}
                      />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                      <InputLabelOnBorder
                        id={'address'}
                        inputType={InputType.Text}
                        labelText={t('company.address')}
                        inputValue={createData?.address || ''}
                        onChange={onChangeText}
                        textArea={{ row: 2, col: 20 }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-end mt-3">
                    <div className="col-12 col-md-2">
                      <SubmitButton
                        stateName={'company'}
                        onClick={onCreate}
                        objectLengthRule={
                          Object.keys(createData).length >= 2 ? false : true
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </div>
        </div>
        <Toast
          stateName={'company'}
          onClose={() => {
            dispatch(Actions.companyActions.cleanState(true));
            setImgSrc('');
            setCreateData({});
            navigate(redirect);
          }}
        />
      </div>
    );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.company.create,
    content
  );
};
export default Create;
