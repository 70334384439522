import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Actions from '../../../store/redux/actions';
import Card from '../../../components/Common/Card/Card';
import Table from '../../../components/Common/Table/Table';
import HeadTags from '../../../components/Common/HeadTags/HeadTags';
import checkClaims from '../../../hooks/useCheckClaims';
import Claims from '../../../utils/Claims';
import { useLocation, useNavigate } from 'react-router-dom';
import { Entities } from '../../../utils/Enum';
import Button from '../../../components/Common/Button/Button';
import {
  ButtonIcon,
  ButtonType,
  TextColors,
} from '../../../utils/ComponentEnums';

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => navigate(-1);
  const orderList = useSelector((state) => state.order.attendeeOrders);
  const currentUserClaim = useSelector((state) => state.auth.claim);
  const [showLoader, setShowLoader] = useState(true);
  const attendeeId = location.state.attendeeId;

  useEffect(() => {
    dispatch(Actions.orderActions.getOrdersByAttendeeIdAction(attendeeId)).then(
      () => {
        setShowLoader(false);
      }
    );
  }, []);

  const forwardAction = (val) => {
    navigate(`/meetings/${orderList.attendeeId}/${val.orderItemId}`, {
      state: {
        id: val.orderItemId,
      },
    });
  };

  const cardHeader = [
    { key: 'name', label: t('common.name') },
    { key: 'email', label: t('common.email') },
    { key: 'phone', label: t('common.phone') },
  ];

  const headers = [
    { key: 'orderId', label: t('common.orderId') },
    { key: 'orderItemId', label: t('common.orderItemId') },
    { key: 'orderItemDescription', label: t('order.order_item_description') },
    { key: 'orderStatus', label: t('myOrdersTable.order_status') },
    {
      key: 'unattendedSessionCount',
      label: t('myOrdersTable.unattenndeedSessionCount'),
    },
    {
      key: 'undatedSessionCount',
      label: t('myOrdersTable.undated_package_count'),
    },
    {
      key: 'waitingSessionCount',
      label: t('myOrdersTable.waiting_package_count'),
    },
  ];

  const content = (
    <div className="container-fluid">
      <div className="col-12 mt-0 mt-md-5">
        <Card
          body={
            <React.Fragment>
              <div className="d-sm-flex align-items-center justify-content-start ms-sm-2">
                <div className="col-12 col-md-1 mt-sm-3">
                  <Button
                    type={ButtonType.Successbg}
                    icon={ButtonIcon.ArrowLeft}
                    iconColor={TextColors.Light}
                    bold
                    onClick={goBack}
                    buttonCol={12}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2 mt-md-0 form-div-border">
                <HeadTags
                  hSize={3}
                  strong
                  text={t('order.orderDetailsAtteendee')}
                />
              </div>
              <div className="col-12 col-md-3">
                <Card
                  body={
                    <React.Fragment>
                      {cardHeader.map((val, i) => (
                        <div
                          key={i}
                          className={`d-flex justify-content-between border-bottom py-1 ${
                            i % 2 ? 'bg-light' : ''
                          }`}
                        >
                          <div className="px-1 fw-bold">{val.label}</div>
                          <div className="px-1">{orderList[val.key]}</div>
                        </div>
                      ))}
                    </React.Fragment>
                  }
                />
              </div>
              <Table
                // searchAble
                data={orderList?.attendeeOrderItemsList ?? []}
                headers={headers}
                showLoading={showLoader}
                striped
                bordered
                forwardPage={Entities.meeting}
                forward={(val) => forwardAction(val)}
                page={Entities.meeting}
                currentUserClaim={currentUserClaim}
                exportName={Entities.meeting.exportName}
              />
            </React.Fragment>
          }
        />
      </div>
    </div>
  );

  return checkClaims(
    currentUserClaim,
    Claims.backofficeClaims.order.getAll,
    content,
    Claims.actionClaims.companyMeeting
  );
};
export default List;
