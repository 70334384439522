import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/Common/Table/Table';
import Card from '../../../components/Common/Card/Card';
import '../../../components/Common/Button/Button.Module.scss';
import useLoader from '../../../hooks/useLoader';
import { Loader } from '../../../components/Common/Loader/Loader';
import Actions from '../../../store/redux/actions';
import { useNavigate } from 'react-router-dom';
import { ButtonType, TextColors } from '../../../utils/ComponentEnums';
import Button from '../../../components/Common/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import addDay from '../../../hooks/useAddDay';
import { ActionFilterRules, Entities } from '../../../utils/Enum';

const MySessionsHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const meetingSchedule = useSelector(
    (state) => state.meetingSchema.getAllSchedule
  );
  const redateIsSuccess = useSelector(
    (state) => state.meetingAttendee.isSuccess
  );
  const redateMessage = useSelector(
    (state) => state.meetingAttendee.validationMessage
  );
  const [recompenseMessage, setRecompenseMessage] = useState('');
  const [recompensePopup, setRecompensePopup] = useState('');

  useEffect(() => {
    return () => {
      dispatch(Actions.meetingAttendeeActions.cleanState());
    };
  }, []);

  useEffect(() => {
    if (recompensePopup !== '') {
      toast.info(recompensePopup, {
        onClose: () => {
          setRecompensePopup('');
        },
      });
    }
  }, [recompensePopup]);

  useEffect(() => {
    if (!redateIsSuccess && redateMessage !== '') {
      setRecompenseMessage(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{redateMessage}</strong>
        </span>
      );
    }
    if (redateIsSuccess && redateMessage !== '') {
      setRecompenseMessage(
        <span
          className="d-flex position-relative mx-auto justify-content-center fs-6 my-2"
          style={{ maxWidth: '550px' }}
        >
          <strong>{t('operations.recompense_success')}</strong>
        </span>
      );
    }
  }, [redateIsSuccess, redateMessage]);

  useEffect(() => {
    if (recompenseMessage !== '' && !redateIsSuccess) {
      toast.warning(recompenseMessage, {
        onClose: () => {
          setRecompenseMessage('');
          setRecompensePopup('');
        },
      });
    }
    if (recompenseMessage !== '' && redateIsSuccess) {
      toast.info(recompenseMessage, {
        onClose: () => {
          dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
          setRecompenseMessage('');
          setRecompensePopup('');
        },
      });
    }
  }, [recompenseMessage]);

  const customToastStyle = {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  useEffect(() => {
    dispatch(Actions.meetingSchemaActions.getAllAttendeeScheduleAction());
  }, []);

  const headers = [
    { key: 'speakerName', label: t('mySessionHistoryTable.mentor') },
    { key: 'date', label: t('mySessionHistoryTable.date') },
    { key: 'interval', label: t('mySessionHistoryTable.interval') },
    { key: 'meetingLevel', label: t('mySessionHistoryTable.level') },
    { key: 'meetingAttendeeStatus', label: t('mySessionHistoryTable.status') },
  ];

  const recompenseAction = async (val) => {
    const recompenseData = {
      redatedMeetingAttendeeId: val.id,
      orderItemId: val.orderItemId,
      redateFrom: `${val.date}T${val.interval.split('-')[0]}:00.000Z`,
    };
    await dispatch(
      Actions.meetingAttendeeActions.recompenseAction(recompenseData)
    );
  };
  const redateAction = (val) => {
    dispatch(Actions.resetStoreAction.resetStore());
    const [year, month, day] = val.date.split('-').map(Number);
    const nDate = addDay(
      val.interval,
      `${day.toString().padStart(2, '0')}-${month
        .toString()
        .padStart(2, '0')}-${year}`
    );
    const [xday, xmonth, xyear] = nDate.split('-').map(Number);

    const date = new Date(
      `${xyear}-${xmonth.toString().padStart(2, '0')}-${xday
        .toString()
        .padStart(2, '0')}`
    );
    date.setHours(0, 0, 0, 0);

    const filterData = {
      StartDateTime: date,
      TypeOfService: [val.typeOfService],
      UserArea: val.userArea,
      FullName: null,
      Native: null,
      LearningPurpose: null,
      LanguageLevels: val.meetingLevel !== null ? val.meetingLevel : null,
      Locations: [val.locationId],
      PackageId: val.packageId,
      AgeId: val.ageId,
      DateDetails: {
        dayOfWeek: null,
        timeInterval: null,
        selectedDate: null,
        selectedTime: null,
      },
    };
    navigate('/calendar', {
      state: {
        fromNavigateWhere: 'recompense',
        whichone: val.category === 'Group' ? 'Group' : 'Private',
        orderItemId: val.orderItemId,
        filterData: filterData,
        meetingAttendeeId: val.id,
      },
    });
  };
  const generateICSContent = (event) => {
    const { title, description, start, end } = event;
    return `BEGIN:VCALENDAR\n
PRODID:-//Meet2Talk//TR\n
VERSION:2.0\n
CALSCALE:GREGORIAN\n
METHOD:PUBLISH\n
X-WR-CALNAME:${user.email}\n
X-WR-TIMEZONE:Europe/Istanbul\n
BEGIN:VEVENT\n
SUMMARY:${title}\n
DESCRIPTION:${description}\n
LOCATION:\n
DTSTART:${start}\n
DTEND:${end}\n
SEQUENCE:1\n
TRANSP:OPAQUE\n
BEGIN:VALARM\n
ACTION:DISPLAY\n
DESCRIPTION:This is an event reminder\n
TRIGGER:-P0DT0H5M0S\n
END:VALARM\n
BEGIN:VALARM\n
ACTION:DISPLAY\n
DESCRIPTION:This is an event reminder\n
TRIGGER:-P0DT3H15M0S\n
END:VALARM\n
END:VEVENT\n
END:VCALENDAR`;
  };
  const addToCalendarAction = (val) => {
    const parseTime = (timeStr) => {
      const [hours, minutes] = timeStr.split(':');
      return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
    };

    const eventDate = new Date(val.date);
    const startTime = parseTime(val.interval.split('-')[0]);
    const endTime = parseTime(val.interval.split('-')[1]);

    eventDate.setUTCHours(startTime.hours);
    eventDate.setMinutes(startTime.minutes);

    const startDateString =
      eventDate.toISOString().replace(/[-:]/g, '').slice(0, 13) + '00';

    eventDate.setUTCHours(endTime.hours);
    eventDate.setMinutes(endTime.minutes);

    const endDateString =
      eventDate.toISOString().replace(/[-:]/g, '').slice(0, 13) + '00';

    const eventDetails = {
      title: val.speakerName + ', ' + val.type + ', ' + val.category,
      description: val.date + ', ' + val.interval + ', ' + val.meetingStatus,
      start: startDateString, // Format: YYYYMMDDTHHMMSS
      end: endDateString, // Format: YYYYMMDDTHHMMSS
    };

    const icsContent = generateICSContent(eventDetails);
    const encodedICSContent = encodeURIComponent(icsContent);
    const googleCalendarURL = `data:text/calendar;charset=utf-8,${encodedICSContent}`;

    const a = document.createElement('a');
    a.href = googleCalendarURL;
    a.download = `Meet2Talk_${val.type}_${val.category}_event.ics`;
    a.click();
  };

  const mergeDateWithStartTime = (data) => {
    const currentDate = Date.now();
    const mergedData = data?.map((item) => {
      return {
        ...item,
        mergedDate: `${item.date}T${item.interval.split('-')[0]}:00.000Z`,
      };
    });
    const upcomingEvents = mergedData?.filter(
      (item) => new Date(item.mergedDate) > currentDate
    );

    upcomingEvents?.sort((a, b) => {
      const timeDiffA = Math.abs(currentDate - new Date(a.mergedDate));
      const timeDiffB = Math.abs(currentDate - new Date(b.mergedDate));
      return timeDiffA - timeDiffB;
    });

    const filteredEvents = mergedData?.filter(
      (item) => new Date(item.mergedDate) <= currentDate
    );
    const sortedEvents = upcomingEvents?.concat(filteredEvents);

    return sortedEvents;
  };
  const mergedData = mergeDateWithStartTime(meetingSchedule);
  return useLoader() ? (
    <Loader />
  ) : (
    <div id="wrapper">
      <div id="content-wrapper" className="d-flex flex-column ">
        <Card
          padding={0}
          body={
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className=" d-sm-flex align-items-center justify-content-start my-2">
                    <h1 className="h3 my-auto text-gray-800 text-nowrap">
                      {t('mySessions.my_sessions_history')}
                    </h1>
                  </div>
                  <div className="">
                    <Table
                      searchAble
                      data={mergedData}
                      headers={headers}
                      striped
                      bordered
                      page={Entities.attendee}
                      recompense={(val) => {
                        setRecompensePopup(
                          <>
                            <span
                              className="d-flex position-relative mx-auto  justify-content-center fs-6 my-2"
                              style={{ maxWidth: '550px' }}
                            >
                              <strong>{t('button.recompense_question')}</strong>
                            </span>
                            <div
                              className="text-muted text-center py-2"
                              dangerouslySetInnerHTML={{
                                __html: t('button.redate_right', {
                                  count: val?.redateRight,
                                }),
                              }}
                            />
                            <div className="d-flex justify-content-around">
                              <div className="col-4">
                                <Button
                                  text={t('button.yes')}
                                  type={ButtonType.Success}
                                  textColor={TextColors.Light}
                                  onClick={() => {
                                    recompenseAction(val);
                                    setRecompensePopup('');
                                  }}
                                />
                              </div>
                              <div className="col-4">
                                <Button
                                  text={t('button.no')}
                                  type={ButtonType.Danger}
                                  textColor={TextColors.Light}
                                  onClick={() => setRecompensePopup('')}
                                />
                              </div>
                            </div>
                          </>
                        );
                      }}
                      redate={(val) => redateAction(val)}
                      addToCalendar={(val) => addToCalendarAction(val)}
                      currentUserClaim={[]}
                      filterActionsRule={true}
                      filterRule={ActionFilterRules.attendee}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
      {(recompenseMessage !== '' || recompensePopup !== '') && (
        <ToastContainer
          style={customToastStyle}
          autoClose={false}
          closeOnClick
          theme="light"
        />
      )}
    </div>
  );
};
export default MySessionsHistory;
