import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../store/redux/actions';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  InputType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import { formatPhone } from '../../../../hooks/useFormatPhone';
import regexValidator from '../../../../hooks/useRegexValidator';
import validationMessage from '../../../../hooks/useValidationMessage';
import TermsOFUse from '../../../UI/CommonPages/TermsOFUse';
import Modal from '../../../../components/Common/Modal/Modal';
import Card from '../../../../components/Common/Card/Card';
import Input from '../../../../components/Common/Input/Input';
import DropdownWithSearch from '../../../../components/Common/Dropdown/DropdownWithSearch';

export default function NewLetUsCallYou({ location, area }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validationMessages = useSelector(
    (state) => state.subscription.validationMessage
  );
  const staticDatas = useSelector((state) => state.staticData);
  const successCheck = useSelector((state) => state.subscription.isSuccess);
  const [createData, setCreateData] = useState({});
  const [handleErrorMessage, setHandleErrorMessage] = useState(null);
  const [handleValidationMessages, setHandleValidationMessage] =
    useState(validationMessages);
  const [error, setError] = useState({});
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  const showTermsModalHandler = () => {
    if (!termsChecked) {
      setTermsModalVisible(true);
    }
  };
  const handleTermsCheckboxChange = () => {
    setTermsChecked(!termsChecked);
    setTermsModalVisible(false);
  };
  useEffect(() => {
    if (termsChecked) {
      setCreateData({
        ...createData,
        privacyConsent: true,
      });
      setTermsChecked(true);
    }
  }, [termsChecked]);

  useEffect(() => {
    setCreateData({ ...createData, area: area, location: location });
  }, []);

  useEffect(() => {
    setHandleValidationMessage(validationMessages);
  }, [validationMessages]);

  useEffect(() => {
    if (successCheck) {
      setCreateData({});
      setHandleErrorMessage(
        <span className="text-success">
          {t('validationMessages.your_request_has_been_sent_successfully')}
        </span>
      );
    }
  }, [successCheck]);
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, required } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else if (required && validationResult && value !== '') {
      setError({ ...error, [id]: undefined });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };
  const onFocus = (e) => {
    e.preventDefault();
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
    setHandleErrorMessage(null);
    setHandleValidationMessage('');
  };

  useEffect(() => {
    if (createData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  }, [createData?.phone, error?.phone]);
  const onBlur = (e) => {
    const { id, value } = e.target;
    if (value === '') {
      const { [id]: deletedValue, ...updatedCreateData } = createData;
      setCreateData(updatedCreateData);
    }
  };
  const onCreate = async (e) => {
    e.preventDefault();

    let hasErrors = false;
    if (!createData.name) {
      setError({
        ...error,
        name: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
      hasErrors = true;
    }

    if (!createData.email) {
      setError({
        ...error,
        email: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
      hasErrors = true;
    } else {
      const validationResult = regexValidator(
        InputType.Email,
        createData.email
      );
      if (!validationResult) {
        setError({
          ...error,
          email: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(InputType.Email))}
            </span>
          ),
        });
        hasErrors = true;
      }
    }

    if (!createData.phone) {
      setError({
        ...error,
        phone: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
      hasErrors = true;
    } else {
      const validationResult = regexValidator(InputType.Tel, createData.phone);
      if (!validationResult) {
        setError({
          ...error,
          phone: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(InputType.Tel))}
            </span>
          ),
        });
        hasErrors = true;
      }
    }

    if (hasErrors) {
      return;
    }

    await dispatch(Actions.subscriptionActions.createAction(createData));
  };

  const selectedLanguageLevels = [];
  if (levelGroup && levelGroup?.adult) {
    let languageLevels = staticDatas.LanguageLevels?.[levelGroup.adult];

    selectedLanguageLevels.push(...languageLevels);
  }

  const text = t('newMain.terms_of_use');
  const parts = text.split(/(\{\{privacy_policy\}\}|\{\{terms_of_use\}\})/);

  const content = (
    <div className="contact-form-area py-3">
      <div className="container">
        <Card
          cardStyle={{
            borderRadius: '6px',
            border: '6px solid #4cbc9a',
            filter: 'drop-shadow(0px 0px 8px rgba(76, 188, 154, 0.5))',
            rounded: 0,
          }}
          body={
            <div className="justify-content-center align-items-center py-2">
              <div className="text-center">
                <h2 className="align-self-center">
                  <strong>{t('landingForm.header')}</strong>
                </h2>
              </div>
              <div className="row mt-2">
                <div className="py-1">
                  <Input
                    id={'name'}
                    className={'form-control border'}
                    type={InputType.Text}
                    placeholder={t('common.name')}
                    value={createData.name || ''}
                    onChange={onChangeText}
                    onFocus={onFocus}
                    error={error.name}
                    onBlur={onBlur}
                    style={{ backgroundColor: '#f8f9fa' }}
                  />
                </div>
                <div className="py-1">
                  <Input
                    id={'email'}
                    className={'form-control border'}
                    type={InputType.Email}
                    placeholder={t('common.email')}
                    value={createData.email || ''}
                    onChange={onChangeText}
                    onFocus={onFocus}
                    error={error?.email}
                    onBlur={onBlur}
                    style={{ backgroundColor: '#f8f9fa' }}
                  />
                </div>
                <div className="py-1">
                  <Input
                    id={'phone'}
                    className={'form-control border'}
                    type={InputType.Tel}
                    placeholder={t('common.phone')}
                    value={createData.phone || ''}
                    onChange={onChangeText}
                    onFocus={onFocus}
                    error={error?.phone}
                    onBlur={onBlur}
                    style={{ backgroundColor: '#f8f9fa' }}
                  />
                </div>
                <div className="py-1">
                  <DropdownWithSearch
                    onBlur={onBlur}
                    placeHolder={t('landingForm.level')}
                    style={{
                      minHeight: '37px',
                      backgroundColor: '#f8f9fa',
                      border: '2px solid #e9ecef',
                      borderColor: '#e9ecef',
                      fontSize: '1rem',
                      color: '#6c717d',
                    }}
                    options={selectedLanguageLevels.map((item, index) => ({
                      key: ` ${t(item.key, { lng: 'en' })}`,
                      value: item.key,
                      name: 'languageLevel',
                    }))}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => {
                      setCreateData({
                        ...createData,
                        languageLevel: value,
                      });
                    }}
                  />
                </div>
                <div className="py-1">
                  <DropdownWithSearch
                    onBlur={onBlur}
                    placeHolder={t('landingForm.purpose')}
                    style={{
                      minHeight: '37px',
                      backgroundColor: '#f8f9fa',
                      border: '2px solid #e9ecef',
                      borderColor: '#e9ecef',
                      fontSize: '1rem',
                      color: '#6c717d',
                    }}
                    options={staticDatas.LearningPurpose.map((item) => ({
                      key: `${t(item.key)}`,
                      value: item.value,
                      name: 'learningPurpose',
                    }))}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => {
                      setCreateData({
                        ...createData,
                        learningPurpose: value,
                      });
                    }}
                  />
                </div>
                <div className="py-1">
                  <DropdownWithSearch
                    onBlur={onBlur}
                    placeHolder={t('landingForm.sessionType')}
                    style={{
                      minHeight: '37px',
                      backgroundColor: '#f8f9fa',
                      border: '2px solid #e9ecef',
                      borderColor: '#e9ecef',
                      fontSize: '1rem',
                      color: '#6c717d',
                    }}
                    options={staticDatas.TypeOfService.map((item) => ({
                      key: `${t(item.key)}`,
                      value: item.value,
                      name: 'typeOfService',
                    }))}
                    selectedProps={{ key: 'key', value: 'value' }}
                    onChange={(value) => {
                      setCreateData({
                        ...createData,
                        typeOfService: value,
                      });
                    }}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <input
                    className="mx-2 align-middle"
                    type="checkbox"
                    value={termsChecked}
                    id="termsChecked"
                    defaultChecked={termsChecked}
                    required
                    checked={termsChecked}
                    onClick={() => {
                      showTermsModalHandler();
                      setHandleValidationMessage('');
                    }}
                  />
                  <label
                    className="form-check-label text-gray-700 mt-1"
                    style={{ fontSize: '.7rem' }}
                  >
                    {parts.map((part, index) => {
                      if (index === 0) {
                        return <span key={index}>{part}</span>;
                      }
                      const key = part.includes('terms_of_use')
                        ? 'terms_of_use'
                        : 'privacy_policy';
                      return (
                        <span key={index}>
                          {part.startsWith('{{') ? (
                            <span
                              onClick={() => {
                                if (key === 'terms_of_use') {
                                  setTermsModalVisible(!termsModalVisible);
                                }

                                setHandleValidationMessage('');
                              }}
                              style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 'bold',
                              }}
                            >
                              {key === 'terms_of_use'
                                ? t('register.terms_of_use')
                                : t('register.privacy_policy')}
                            </span>
                          ) : (
                            <span>{part}</span>
                          )}
                        </span>
                      );
                    })}
                  </label>
                </div>
                <div className="text-dark text-start align-items-center d-flex justify-content-start">
                  <input
                    key={'isReminder'}
                    className="mx-1 align-middle"
                    readOnly
                    id={'isReminder'}
                    type="checkbox"
                    value={createData.isReminder}
                    checked={createData.isReminder || false}
                    onClick={(e) =>
                      setCreateData({
                        ...createData,
                        [e.target.id]: !createData.isReminder,
                      })
                    }
                  />
                  <label
                    className="form-check-label text-gray-700 mt-1 ms-1"
                    style={{ fontSize: '.7rem' }}
                  >
                    <span>{t('newMain.subscribe_email')}</span>
                  </label>
                </div>
                {handleValidationMessages !== '' && (
                  <div className="text-center mt-1">
                    <span className="text-danger">
                      {handleValidationMessages}
                    </span>
                  </div>
                )}
                {handleErrorMessage && (
                  <div className="text-center mt-1">{handleErrorMessage}</div>
                )}
                <div className="mx-auto d-flex justify-content-center py-2">
                  <Button
                    text={t('landingForm.button')}
                    textColor={TextColors.Light}
                    type={ButtonType.Successbg}
                    size={ButtonSize.Small}
                    icon={ButtonIcon.ArrowRightLong}
                    iconColor={TextColors.Light}
                    bold
                    border
                    onClick={onCreate}
                    buttonCol={6}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>

      <Modal
        isVisible={termsModalVisible}
        content={<TermsOFUse />}
        styleProps={{ maxWidth: 900, borderRadius: 15 }}
        footer={
          <Button
            type={ButtonType.Successbg}
            textColor={TextColors.Light}
            bold
            buttonCol={3}
            text={t('button.accept')}
            onClick={handleTermsCheckboxChange}
          />
        }
        onClose={() => {
          setTermsModalVisible(false);
        }}
      />
    </div>
  );
  return content;
}
