import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getNames } from 'i18n-iso-countries';
import InputLabelOnBorder from '../../components/Common/Input/InputLabelOnBorder';
import CustomFileInput from '../../components/Common/FileInput/FileUpload';
import Input from '../../components/Common/Input/Input';
import DropdownWithSearch from '../../components/Common/Dropdown/DropdownWithSearch';
import Card from '../../components/Common/Card/Card';
import { InputType, TextColors, ButtonType } from '../../utils/ComponentEnums';
import Actions from '../../store/redux/actions';
import { formatPhone } from '../../hooks/useFormatPhone';
import {
  AllLanguages,
  countryListAllIsoData,
  storageKeys,
} from '../../utils/Enum';
import refreshPage from '../../hooks/useReload';
import regexValidator from '../../hooks/useRegexValidator';
import validationMessage from '../../hooks/useValidationMessage';
import Toast from '../../components/Common/Popup/Toast';
import SubmitButton from '../../components/Common/Button/SubmitButton';

const BecomeASpeaker = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang.language);
  const career = useSelector((state) => state.career.career);
  const staticDatas = useSelector((state) => state.staticData);
  const levelGroup = useSelector(
    (state) => state.preferenceOptions.preferenceLevelGroup
  );
  const [createData, setCreateData] = useState(career);
  const [imgSrc, setImgSrc] = useState('');
  const [fileSrc, setFileSrc] = useState('');
  const [error, setError] = useState({});

  const onChangeText = (e, special = undefined) => {
    e.preventDefault();
    const { id, value, type } = e.target;
    if (special === undefined) {
      if (value !== '') {
        setCreateData({ ...createData, [id]: value });
      } else {
        setCreateData((prevState) => {
          const { [id]: _, ...rest } = prevState;
          return rest;
        });
      }
      const validationResult = regexValidator(type, value);
      if (!validationResult && value !== '') {
        setError({
          ...error,
          [id]: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(type))}
            </span>
          ),
        });
      } else {
        setError({ ...error, [id]: undefined });
      }
    } else {
      setCreateData({
        ...createData,
        [special]: { ...createData[special], [id]: value },
      });
    }
  };
  const onFocus = () => {
    if (error?.phoneNumber !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phoneNumber);
      setCreateData({
        ...createData,
        phoneNumber: cleanValue,
      });
    }
  };
  useEffect(() => {
    if (
      createData?.phoneNumber?.length > 15 ||
      error?.phoneNumber !== undefined
    ) {
      const cleanValue = formatPhone(undefined, createData?.phoneNumber);
      setCreateData({
        ...createData,
        phoneNumber: cleanValue,
      });
    }
  }, [createData?.phoneNumber, error?.phoneNumber]);
  const onBlur = (e) => {
    e.preventDefault();
    dispatch(Actions.careerActions.holdValue({ ...createData }));
  };
  const onCreate = async (e) => {
    e.preventDefault();
    const apiObject = {
      ...createData,
      socialMedia: JSON.stringify(createData.socialMedia),
      detailInfo: JSON.stringify(createData.detailInfo),
    };

    await dispatch(Actions.careerActions.createAction(apiObject));
  };

  const handleSelectedData = (value, key) => {
    const dataArray = createData?.[key]?.split(',');

    if (dataArray?.length === undefined) {
      const updatedData = { ...createData, [key]: value };
      setCreateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }

    if (dataArray?.includes(value)) {
      const index = dataArray.indexOf(value);
      if (index !== -1) {
        const newDataArray = dataArray.slice();
        newDataArray.splice(index, 1);
        const updatedData = {
          ...createData,
          [key]: newDataArray.join(','),
        };
        setCreateData(updatedData);
        if (newDataArray?.length === 0) {
          const newData = { ...createData };
          delete newData[key];
          setCreateData(newData);
          dispatch(Actions.speakerActions.holdValue(newData));
        } else {
          dispatch(Actions.speakerActions.holdValue(updatedData));
        }
      }
    }

    if (dataArray?.length !== undefined && !createData[key]?.includes(value)) {
      const updatedData = {
        ...createData,
        [key]: createData[key] + ',' + value,
      };
      setCreateData(updatedData);
      dispatch(Actions.speakerActions.holdValue(updatedData));
    }
  };

  useEffect(() => {
    if (levelGroup === null) {
      dispatch(Actions.preferenceActions.getLevelGroupAction(4));
    }
  }, [levelGroup]);

  const selectedLanguageLevels = [];

  if (levelGroup && levelGroup?.adult && levelGroup?.kids) {
    Object.values(levelGroup).forEach((lang) => {
      if (staticDatas.LanguageLevels[lang]) {
        selectedLanguageLevels.push(...staticDatas.LanguageLevels[lang]);
      }
    });
  }

  const [countryList, setCountryList] = useState([]);
  const countryNames = countryListAllIsoData.map((item) => {
    const name = getNames(lang?.split(',')[0].split('-')[0])[item.code];
    const isoName = item.name;
    const phone = item.phone;

    return { name: name, phone: phone, isoName: isoName };
  });
  useEffect(() => {
    setCountryList(countryNames);
  }, []);
  return (
    <div className="container-fluid mb-5">
      <Card
        body={
          <div className="container mt-4">
            <div className="row">
              <form className="justify-content-center">
                <div>
                  <h2 className="d-flex align-items-center justify-content-start">
                    {t('becomeAMentor.who_we_are')}
                  </h2>
                  <span
                    className="form-control text-justify h-100 mb-3"
                    placeholder="Introduction"
                  >
                    <p>
                      {t('becomeAMentor.meet2talk_is_an_english_speaking')}
                      <br />
                      {t('becomeAMentor.we_have_online_cafe_kids_sessions')}

                      <br />
                      {t('becomeAMentor.for_instance_social_sciences_fashion')}
                    </p>
                    <p>
                      {t('becomeAMentor.beyond_the_sessions')}
                      <br />
                      {t(
                        'becomeAMentor.if_you_would_like_to_be_a_part_of_meet2talk'
                      )}
                    </p>
                  </span>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-md-6 py-1">
                    <InputLabelOnBorder
                      id={'firstName'}
                      inputType={InputType.Text}
                      labelText={t('common.name')}
                      inputValue={createData.firstName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 py-1">
                    <InputLabelOnBorder
                      id={'lastName'}
                      inputType={InputType.Text}
                      labelText={t('common.lastName')}
                      inputValue={createData.lastName || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'email'}
                      inputType={InputType.Email}
                      labelText={t('becomeAMentor.professional_email_address')}
                      inputValue={createData.email || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.email}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'birthday'}
                      inputType={InputType.Date}
                      labelText={t('becomeAMentor.birth_date')}
                      inputValue={createData.birthday || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.country_code')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: `${item.name} (${item.phone})`,
                          value: `${item.phone} ${item.isoName}`,
                          name: 'countryCode',
                        })),
                        selectedValue: createData?.countryCode || '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.careerActions.holdValue({
                            ...createData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'phoneNumber'}
                      inputType={InputType.Tel}
                      labelText={t('common.phone')}
                      inputValue={formatPhone(createData.phoneNumber) || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      error={error?.phoneNumber}
                      placeholder={'(5xx) xxx xx xx'}
                      onFocus={onFocus}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'address'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.address')}
                      inputValue={createData.address || ''}
                      onChange={onChangeText}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.gender')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: staticDatas.Gender,
                        selectedValue:
                          (createData.gender === 0 ? '0' : createData.gender) ||
                          '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: parseInt(val.value),
                        });
                        dispatch(
                          Actions.careerActions.holdValue({
                            ...createData,
                            [val.name]: parseInt(val.value),
                          })
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.accent')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: staticDatas.Native,
                        selectedValue: createData.native || '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.careerActions.holdValue({
                            ...createData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.country')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: countryList.map((item) => ({
                          key: item.name,
                          value: item.name,
                          name: 'country',
                        })),
                        selectedValue: createData?.country || '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.careerActions.holdValue({
                            ...createData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      labelText={t('becomeAMentor.language')}
                      inputType={InputType.Text}
                      select={{
                        selectStyle: 'selectStyle',
                        option: AllLanguages,
                        selectedValue: createData.language || '',
                      }}
                      handleSelectChange={(val) => {
                        setCreateData({
                          ...createData,
                          [val.name]: val.value,
                        });
                        dispatch(
                          Actions.careerActions.holdValue({
                            ...createData,
                            [val.name]: val.value,
                          })
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'linkedIn'}
                      inputType={InputType.Text}
                      labelText={'LinkedIn'}
                      inputValue={createData?.socialMedia?.linkedIn || ''}
                      onChange={(e) => onChangeText(e, 'socialMedia')}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'instagram'}
                      inputType={InputType.Text}
                      labelText={'Instagram'}
                      inputValue={createData?.socialMedia?.instagram || ''}
                      onChange={(e) => onChangeText(e, 'socialMedia')}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'facebook'}
                      inputType={InputType.Text}
                      labelText={'Facebook'}
                      inputValue={createData?.socialMedia?.facebook || ''}
                      onChange={(e) => onChangeText(e, 'socialMedia')}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'twitter'}
                      inputType={InputType.Text}
                      labelText={'Twitter'}
                      inputValue={createData?.socialMedia?.twitter || ''}
                      onChange={(e) => onChangeText(e, 'socialMedia')}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      isMulti
                      onBlur={onBlur}
                      placeHolder={t('becomeAMentor.learningPurposes')}
                      options={staticDatas.LearningPurpose}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      selectedValues={createData.learningPurposes || ''}
                      onChange={(value) =>
                        handleSelectedData(value, 'learningPurposes')
                      }
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <DropdownWithSearch
                      isSearchable
                      isMulti
                      onBlur={onBlur}
                      placeHolder={t('becomeAMentor.display_areas')}
                      options={staticDatas.UserArea}
                      selectedValues={createData.displayAreas || ''}
                      selectedProps={{
                        key: 'key',
                        value: 'value',
                      }}
                      onChange={(value) =>
                        handleSelectedData(value, 'displayAreas')
                      }
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {fileSrc ? fileSrc : undefined}
                    </div>
                    <CustomFileInput
                      id={'cv'}
                      key={'file'}
                      hidden={true}
                      buttonText={t('becomeAMentor.upload_cv')}
                      textColor={TextColors.Light}
                      buttonColor={ButtonType.Successbg}
                      removeState={createData.cv !== undefined ? false : true}
                      fileType={'File'}
                      dataFrom={'Career'}
                      func={(val) => {
                        setCreateData(
                          Object.assign({}, createData, {
                            cv: val.base64String,
                            cvExtension: val.extension,
                          })
                        );
                        setFileSrc(val.fileSrc);
                        dispatch(
                          Actions.careerActions.holdValue(
                            Object.assign({}, createData, {
                              cv: val.base64String,
                              cvExtension: val.extension,
                            })
                          )
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div className="col-12 d-flex justify-content-center">
                      {imgSrc ? (
                        <img
                          className={'img-fluid rounded-2 me-3'}
                          src={imgSrc}
                          alt={imgSrc}
                          width="20"
                        />
                      ) : undefined}
                    </div>
                    <CustomFileInput
                      id={'image'}
                      key={'image'}
                      hidden={true}
                      buttonText={t('becomeAMentor.upload_picture')}
                      textColor={TextColors.Light}
                      removeState={
                        createData.image !== undefined ? false : true
                      }
                      buttonColor={ButtonType.Successbg}
                      fileType={'Image'}
                      dataFrom={'Career'}
                      func={(val) => {
                        setCreateData(
                          Object.assign({}, createData, {
                            image: val.base64String,
                            imgExtension: val.extension,
                            url: val.fileName,
                          })
                        );
                        setImgSrc(val.imgSrc);
                        dispatch(
                          Actions.careerActions.holdValue(
                            Object.assign({}, createData, {
                              image: val.base64String,
                              imgExtension: val.extension,
                            })
                          )
                        );
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label
                      className="ms-2 position-absolute"
                      style={{
                        marginTop: '-1.25rem',
                        fontSize: '.9rem',
                      }}
                    >
                      <span className="h6 small bg-white text-muted px-1">
                        {t('becomeAMentor.introduction')}
                      </span>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={createData.introduction || ''}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setCreateData({
                          ...createData,
                          introduction: data,
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'education'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.education')}
                      inputValue={createData?.detailInfo?.education || ''}
                      onChange={(e) => onChangeText(e, 'detailInfo')}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'profession'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.profession')}
                      inputValue={createData.detailInfo?.profession || ''}
                      onChange={(e) => onChangeText(e, 'detailInfo')}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <InputLabelOnBorder
                      id={'certificates'}
                      inputType={InputType.Text}
                      labelText={t('becomeAMentor.certificates')}
                      inputValue={createData?.detailInfo?.certificates || ''}
                      onChange={(e) => onChangeText(e, 'detailInfo')}
                      onBlur={onBlur}
                      textArea={{ row: 3, col: 20 }}
                    />
                  </div>
                  <div className="col-6 mt-4">
                    <Input
                      key={'isFullTimer'}
                      id={'isFullTimer'}
                      value={createData.isFullTimer}
                      labelValue={t('becomeAMentor.is_full_timer')}
                      checked={createData.isFullTimer || false}
                      type={InputType.Checkbox}
                      onChange={(e) =>
                        setCreateData({
                          ...createData,
                          [e.target.id]: !createData.isFullTimer,
                        })
                      }
                    />
                  </div>
                </div>
              </form>
              <div className="d-flex justify-content-center justify-content-md-end mt-3">
                <div className="col-12 col-md-2">
                  <SubmitButton
                    stateName={'career'}
                    onClick={onCreate}
                    objectLengthRule={
                      Object.keys(createData).length > 5 &&
                      Object.values(error).every((e) => e === undefined)
                        ? false
                        : true
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
      {
        <Toast
          stateName={'career'}
          onClose={() => {
            setCreateData({});
            setFileSrc('');
            setImgSrc('');
            refreshPage();
          }}
        />
      }
    </div>
  );
};
export default BecomeASpeaker;
