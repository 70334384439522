import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../../store/redux/actions';
import InputLabelOnBorder from '../../../../components/Common/Input/InputLabelOnBorder';
import {
  ButtonType,
  InputType,
  TextColors,
} from '../../../../utils/ComponentEnums';
import { formatPhone } from '../../../../hooks/useFormatPhone';
import regexValidator from '../../../../hooks/useRegexValidator';
import validationMessage from '../../../../hooks/useValidationMessage';
import Label from '../../../../components/Common/TextLabel/Label';

export default function LetUsCallYou({ image, iframe, location, area }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validationMessages = useSelector(
    (state) => state.subscription.validationMessage
  );
  const successCheck = useSelector((state) => state.subscription.isSuccess);
  const [createData, setCreateData] = useState({});
  const [handleErrorMessage, setHandleErrorMessage] = useState(null);
  const [handleValidationMessages, setHandleValidationMessage] =
    useState(validationMessages);
  const [error, setError] = useState({});

  useEffect(() => {
    setCreateData({ ...createData, area: area, location: location });
  }, []);

  useEffect(() => {
    setHandleValidationMessage(validationMessages);
  }, [validationMessages]);

  useEffect(() => {
    if (successCheck) {
      setCreateData({});
      setHandleErrorMessage(
        <span className="text-success">
          {t('validationMessages.your_request_has_been_sent_successfully')}
        </span>
      );
    }
  }, [successCheck]);
  const onChangeText = (e) => {
    e.preventDefault();
    const { id, value, type, required } = e.target;
    if (value !== '') {
      setCreateData({ ...createData, [id]: value });
    } else {
      setCreateData((prevState) => {
        const { [id]: _, ...rest } = prevState;
        return rest;
      });
    }
    const validationResult = regexValidator(type, value);
    if (required && !validationResult && value === '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
    } else if (required && validationResult && value !== '') {
      setError({ ...error, [id]: undefined });
    }
    if (!validationResult && value !== '') {
      setError({
        ...error,
        [id]: (
          <span className="text-danger">
            {t('validationMessages.' + validationMessage(type))}
          </span>
        ),
      });
    } else {
      setError({ ...error, [id]: undefined });
    }
  };
  const onFocus = (e) => {
    e.preventDefault();
    if (error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
    setHandleErrorMessage(null);
    setHandleValidationMessage('');
  };

  useEffect(() => {
    if (createData?.phone?.length > 15 || error?.phone !== undefined) {
      const cleanValue = formatPhone(undefined, createData?.phone);
      setCreateData({
        ...createData,
        phone: cleanValue,
      });
    }
  }, [createData?.phone, error?.phone]);
  const onBlur = (e) => {
    const { id, value } = e.target;
    if (value === '') {
      const { [id]: deletedValue, ...updatedCreateData } = createData;
      setCreateData(updatedCreateData);
    }
  };
  const onCreate = async (e) => {
    e.preventDefault();

    let hasErrors = false;
    if (!createData.name) {
      setError({
        ...error,
        name: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
      hasErrors = true;
    }

    if (!createData.email) {
      setError({
        ...error,
        email: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
      hasErrors = true;
    } else {
      const validationResult = regexValidator(
        InputType.Email,
        createData.email
      );
      if (!validationResult) {
        setError({
          ...error,
          email: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(InputType.Email))}
            </span>
          ),
        });
        hasErrors = true;
      }
    }

    if (!createData.phone) {
      setError({
        ...error,
        phone: (
          <span className="text-danger">
            {t('validationMessages.this_field_is_required')}
          </span>
        ),
      });
      hasErrors = true;
    } else {
      const validationResult = regexValidator(InputType.Tel, createData.phone);
      if (!validationResult) {
        setError({
          ...error,
          phone: (
            <span className="text-danger">
              {t('validationMessages.' + validationMessage(InputType.Tel))}
            </span>
          ),
        });
        hasErrors = true;
      }
    }

    if (hasErrors) {
      return;
    }

    await dispatch(Actions.subscriptionActions.createAction(createData));
  };

  const content = (
    <div className="contact-form-area bg-light py-3">
      <div className="container">
        <div>
          <div className="row justify-content-between">
            <div className="col-md-6">
              {image && (
                <img
                  src={`../images/letuscallyouwp.webp`}
                  className="rounded-5"
                  width={'100%'}
                  alt="letuscallyouwp.webp"
                />
              )}
              {iframe && (
                <iframe
                  className="embed-responsive-item rounded-4 border-0"
                  src={`${iframe}`}
                  title="Meet2Talk"
                  allowFullScreen
                  width={'100%'}
                  height={'100%'}
                ></iframe>
              )}
            </div>
            <div className="col-md-6 row justify-content-center align-items-center py-2">
              <div className="text-center">
                <h2 className="align-self-center">
                  {t('MainPart7Contact.Header')}
                </h2>
                <span className="text-muted align-self-center">
                  {t('MainPart7Contact.Description')}
                </span>
              </div>
              <div className="row mt-2">
                <div className="col-md-6 py-1">
                  <InputLabelOnBorder
                    id={'name'}
                    inputType={InputType.Text}
                    labelText={t('common.name')}
                    inputValue={createData.name || ''}
                    onChange={onChangeText}
                    required
                    onFocus={onFocus}
                    error={error.name}
                    onBlur={onBlur}
                  />
                </div>
                <div className="col-md-6 py-1">
                  <InputLabelOnBorder
                    id={'email'}
                    inputType={InputType.Email}
                    labelText={t('common.email')}
                    inputValue={createData.email || ''}
                    onChange={onChangeText}
                    onFocus={onFocus}
                    required
                    error={error?.email}
                    onBlur={onBlur}
                  />
                </div>
                <div className="col-md-6 mt-md-4 py-1">
                  <InputLabelOnBorder
                    id={'phone'}
                    inputType={InputType.Tel}
                    labelText={t('common.phone')}
                    inputValue={formatPhone(createData.phone) || ''}
                    onChange={onChangeText}
                    onFocus={onFocus}
                    required
                    error={error?.phone}
                    placeholder={'(5xx) xxx xx xx'}
                    onBlur={onBlur}
                  />
                </div>
                <div className="col-md-6 mt-md-4 py-1 align-items-center">
                  <span className="">{t('letUsCallYou.subscribe_email')}</span>
                  <label>
                    <input
                      key={'isReminder'}
                      className="mx-2 align-middle"
                      readOnly
                      id={'isReminder'}
                      type="checkbox"
                      value={createData.isReminder}
                      checked={createData.isReminder || false}
                      onClick={(e) =>
                        setCreateData({
                          ...createData,
                          [e.target.id]: !createData.isReminder,
                        })
                      }
                    />
                    {t('letUsCallYou.input_yes')}
                  </label>
                  {/* <Input
                    key={'isReminder'}
                    id={'isReminder'}
                    value={createData.isReminder}
                    labelValue={t('letUsCallYou.input_yes')}
                    checked={createData.isReminder || false}
                    type={InputType.Checkbox}
                    onFocus={onFocus}
                    onClick={(e) =>
                      setCreateData({
                        ...createData,
                        [e.target.id]: !createData.isReminder,
                      })
                    }
                  /> */}
                </div>
                <div className="col-12 mt-md-4 py-1">
                  <InputLabelOnBorder
                    id={'note'}
                    inputType={InputType.Text}
                    labelText={t('letUsCallYou.input_note')}
                    inputValue={createData.note || ''}
                    onChange={onChangeText}
                    onFocus={onFocus}
                    textArea={{ row: 3, col: 12 }}
                    onBlur={onBlur}
                  />
                </div>
                {handleValidationMessages !== '' && (
                  <div className="text-center mt-1">
                    <span className="text-danger">
                      {handleValidationMessages}
                    </span>
                  </div>
                )}
                {handleErrorMessage && (
                  <div className="text-center mt-1">{handleErrorMessage}</div>
                )}
                <div className="col-4 my-3 d-flex ms-auto justify-content-end">
                  <Button
                    type={ButtonType.Successbg}
                    text={t('button.submit')}
                    textColor={TextColors.Light}
                    onClick={onCreate}
                    buttonCol={12}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return content;
}
